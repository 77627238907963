import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, NgZone, ChangeDetectorRef, ElementRef, Optional, Inject } from '@angular/core';
import { MdcDrawer } from '@angular-mdc/web/drawer';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FocusMonitor, FocusOrigin, FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Location } from "@angular/common";

@Component({
  selector: 'ic-sidebar-panel',
  exportAs: 'icSidebarPanel',
  host: {
    class: 'ic-sidebar-panel'
  },
  templateUrl: './sidebar-panel.component.html',
  styleUrls: ['./sidebar-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SidebarPanelComponent extends MdcDrawer {
  isActiveMenu: string;

  constructor(
    _platform: Platform,
    _ngZone: NgZone,
    _changeDetectorRef: ChangeDetectorRef,
    _focusTrapFactory: FocusTrapFactory,
    _focusMonitor: FocusMonitor,
    @Optional() @Inject(DOCUMENT) _document: any,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    elementRef: ElementRef<HTMLElement>) {
    super(
      _platform,
      _ngZone,
      _changeDetectorRef,
      _focusTrapFactory,
      _focusMonitor,
      _document,
      elementRef)
  }

  groups = [

    {

      items: [
        {
          icon: 'send',
          title: 'Sản phẩm',
          url: '/products',
          exact: true,
          class: "img_product",
          backgroudImage: '/assets/datahub/product.png',
          backgroudImageActive: '/assets/datahub/product_focus.png',
          isActive: this.location.path().indexOf('products') !== -1
        },
        {
          icon: 'clear_all',
          title: 'Quản lý doanh nghiệp',
          url: '/gln',
          exact: false,
          backgroudImage: '/assets/datahub/gln.png',
          backgroudImageActive: '/assets/datahub/gln_focus.png',
          class: "img_gln",
          isActive: this.location.path().indexOf('gln') !== -1
        },
        {
          icon: 'clear_all',
          title: 'Cảnh báo sản phẩm',
          url: '/alert',
          exact: false,
          backgroudImage: '/assets/datahub/permission1.png',
          backgroudImageActive: '/assets/datahub/permission_focus_1.png',
          class: "img_warning",
          isActive: this.location.path().indexOf('alert') !== -1
        },
        {
          icon: 'format_list_numbered',
          title: 'Danh mục',
          url: '/category',
          exact: false,
          backgroudImage: '/assets/datahub/category.png',
          backgroudImageActive: '/assets/datahub/category_focus.png',
          class: "img_category",
          isActive: this.location.path().indexOf('category') !== -1
        },
        {
          icon: 'smoke',
          title: 'Sản phẩm bị cảnh báo',
          url: '/product-warning',
          exact: false,
          backgroudImage: '/assets/datahub/permision_group.png',
          backgroudImageActive: '/assets/datahub/category_focus.png',
          class: "img_setting",
          isActive: this.location.path().indexOf('attribute') !== -1
        },
        {
          icon: 'storage',
          title: 'Thuộc tính',
          url: '/attribute',
          exact: false,
          backgroudImage: '/assets/datahub/attribute.png',
          backgroudImageActive: '/assets/datahub/attribute_focus.png',
          class: "img_attribute",
          isActive: this.location.path().indexOf('attribute') !== -1
        },
        {
          icon: 'storage',
          title: 'Bộ thuộc tính',
          url: '/attribute-group-product',
          exact: false,
          backgroudImage: '/assets/datahub/attribute_set.png',
          backgroudImageActive: '/assets/datahub/attribute_set_focus.png',
          class: "img_attribute_set",
          isActive: this.location.path().indexOf('attribute-group-product') !== -1
        },
        {
          icon: 'supervisor_account',
          title: 'Quản lý người dùng',
          url: '/user-list-cms',
          exact: false,
          backgroudImage: '/assets/datahub/user.png',
          backgroudImageActive: '/assets/datahub/user_focus.png',
          class: "img_user",
          isActive: this.location.path().indexOf('user-list-cms') !== -1
        },
        {
          icon: 'aaa',
          title: 'Log Import',
          url: '/log-import',
          exact: false,
          backgroudImage: '/assets/datahub/permission.png',
          backgroudImageActive: '/assets/datahub/permission_focus.png',
          class: "img_log",
          isActive: this.location.path().indexOf('log-import') !== -1
        },
        {
          title: 'Cấu hình',
          url: '/setting-configuration',
          exact: false,
          backgroudImage: '/assets/datahub/permission.png',
          backgroudImageActive: '/assets/datahub/permission_focus.png',
          class: "img_setting",
          isActive: this.location.path().indexOf('setting-configuration') !== -1
        },
        {
          title: 'Cấu hình Quốc gia',
          url: '/national',
          exact: false,
          backgroudImage: '/assets/datahub/permission.png',
          backgroudImageActive: '/assets/datahub/permission_focus.png',
          class: "img_setting",
          isActive: this.location.path().indexOf('national') !== -1
        },

      ],
    },
  ];

  links = [
    { label: 'Danh sách sản phẩm', icon: 'send', href: '/products', value: 'product' },
    { label: 'Danh sách danh mục', icon: 'format_list_numbered', href: '/category', value: 'category' },
    { label: 'Danh sách thông báo', icon: 'format_list_numbered', href: '/alert', value: 'alert' },
    { label: 'Danh sách thuộc tính sản phẩm', icon: 'storage', href: '/attribute', value: 'attribute' },
    { label: 'Danh sách thuộc tính review sản phẩm', icon: 'storage', href: '/attribute-review', value: 'attribute-review' },
    { label: 'Danh sách bộ thuộc tính review ', icon: 'storage', href: '/attribute-review-group', value: 'attribute-review-group' },
    { label: 'Danh sách bộ thuộc tính sản phẩm ', icon: 'storage', href: '/attribute-group-product', value: 'attribute-group-product' },
    { label: 'Danh sách nhóm quyền ', icon: 'storage', href: '/role-group', value: 'role-group' },
    { label: 'Danh sách quyền ', icon: 'storage', href: '/role-list', value: 'role-list' },
    { label: 'Danh sách người dùng CMS ', icon: 'storage', href: '/user-list-cms', value: 'user-list-cms' },
    { label: 'Danh sách GLN', icon: 'clear_all', href: '/gln', value: 'gln' },
    { label: 'Sản phẩm cảnh báo', icon: 'warning', href: '/warning-product', value: 'warning-product' },
  ];

  ngAfterContentInit() {
    this.groups.map((item: any) => {
      item.isActive = this.location.path().indexOf(item.url) !== -1
    })

    // console.log(this.groups);

  }

}
