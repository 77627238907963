<a (click) = "collapseRowButton()" *ngIf="isExpanRowButton">Thu gọn <mat-icon>expand_less</mat-icon></a>
<div style="display: flex;flex-wrap: wrap">
  <button mdc-button raised class="button-table" [routerLink]="['create']">Thêm mới</button>
  <button mdc-button raised class="button-table" (click)="importExcel()">Import </button>
  <button mdc-button raised class="button-table"  (click)="exportExcel()">Export </button>
  <button mdc-button raised class="button-table"   (click)="assignGln()">Gán GLN </button>
  <button mdc-button raised class="button-table" (click)="addCategory()">Gán danh mục </button>
  <button mdc-button raised class="button-table" (click)="updateScanStatus()">Cập nhật trạng thái quét </button>
  <button mdc-button raised class="button-table" (click)="assignWarningProduct()">Gán cảnh báo </button>
  <button mdc-button raised class="button-table" (click)="removeInfoProduct()">Xóa nhanh trường thông tin </button>
  <button mdc-button raised class="button-table" (click)="importExcel('update')">Import update </button>
  <button mdc-button raised class="button-table" style="background: orange" (click)="allowOrDenyContribute()">Cập nhật trạng thái đóng góp nhanh </button>
  <button mdc-button raised class="button-table" style="background: orange" (click)="updateStatusContribute()">Cập nhật trạng thái đóng góp </button>
</div>

