<div class="row-button">
    <button mat-raised-button class="green" >Thêm sản phẩm</button>
    <button mat-raised-button class="primary">Import sản phẩm từ file excel</button>
    <button mat-raised-button class="primary">Cập nhật cho quét</button>
    <button mat-raised-button class="gray">Cập nhật không cho quét</button>
    <button mat-raised-button class="orange">Đã ẩn bởi DNSH</button>
    <button mat-raised-button class="primary">Xuất Excel</button>
    <a (click) = "expanRowButton()" *ngIf="!isExpanRowButton">Mở rộng <mat-icon>expand_more</mat-icon></a>
  </div>
  <div class="row-button" *ngIf="isExpanRowButton">
    <button mat-raised-button class="primary">Gán GLN cho sản phẩm</button>
    <button mat-raised-button class="primary">Ẩn/hiện theo vùng dữ liệu</button>
    <button mat-raised-button class="primary">Tạo bộ sưu tập</button>
    <a (click) = "collapseRowButton()" *ngIf="isExpanRowButton">Thu gọn <mat-icon>expand_less</mat-icon></a>
  </div>