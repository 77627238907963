import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { CategoryListPageActions } from '@category/actions';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { checkFormatBarcode } from '@src/app/shared/validate/validate';
import { ProductListPageActions, ProductActions } from '@products/actions';
import { onlyUnique } from '@src/app/shared/common';
import { Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MatSelect } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fromCategory from '@category/reducers';
import * as fromProduct from '@products/reducers';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {BarcodeBase} from '@src/app/shared/common/barcode-base';
import {ProductService} from '@products/services/product.services';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-cancel-verify-distributor',
  templateUrl: './cancel-verify-distributor.component.html',
  styleUrls: ['./cancel-verify-distributor.component.scss']
})
export class CancelVerifyDistributorComponent  implements OnInit {

  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory: any;
  currentUser: any;


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CancelVerifyDistributorComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    public productService: ProductService,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
  ) {

    this.data = data;
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
    console.log(data)
  }

  async ngOnInit() {

    const url: string = window.location.href;
    if(url.includes('gln')) {

    }


    this.formGroup = this.formBuilder.group({
      reason: ['', Validators.required],
    });
  }

  onFormSubmit() {
    const barcode = this.productService.currentBarcode;

    const url: string = window.location.href;
    if(url.includes('products')){
      const click = new ClickTekoDto('product_document', 'product_document_cancel_verify',
        'http://datahub.icheck.vn/products/update/' + this.data.productId , {
          product_id: this.data.productId,
          barcode
        });
      clickTeko(click);
    }
    this.dialogRef.close(this.formGroup.value);

  }

  clearInputSearch() {
    this.formGroup.patchValue({inputSearch: ''});

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }

  loadListProduct() {
    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: {},
        page: 1,
        pageSize: 10,
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  changeInputSearch(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event.target.value },
        page: 1,
        pageSize: 50
      })
    );

  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    });
    this.formGroup.updateValueAndValidity();
  }

}
