import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, Host, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web/dialog';
import { MdcListItem } from '@angular-mdc/web/list';
import { MdcMenuSelectedEvent } from '@angular-mdc/web/menu';

import { DateRangeDisplayComponent } from './date-range-display.component';
import { DateRangeSettingComponent } from './date-range-setting.component';
import { SelectBoxDisplayComponent, SelectBoxSettingComponent } from '../filter-bar/select-box';
import { SelectSearchBoxDisplayComponent, SelectSearchBoxSettingComponent } from '../filter-bar/select-search-box';
import { TextDisplayComponent, TextSettingComponent } from '../filter-bar/text'
import { DateDisplayComponent } from './date/date-display.component';
import { DateSettingComponent } from './date/date-setting.component';
import { isNull } from 'util';
import { NumberRangeSettingComponent, NumberRangeDisplayComponent } from './number-range';

export type FilterType = 'daterange' | 'custom' | 'select-box' | 'text' | 'number-range' | 'select-search-box' | 'date';

export type DashFilter = {
  key: string;
  name: string;
  type: FilterType;
  settingComponent?: any;
  displayComponent?: any;
  isMutipleSelect?: boolean;
  settingComponentConfig?: any;
  displayComponentConfig?: any;
}

export type DashAppliedFilter<T> = {
  key: string;
  value: T;
  definition?: DashFilter;
}

export type DashFilterSettingData<T> = {
  filter: DashFilter;
  value?: T;
}

@Component({
  selector: 'dash-filter-bar',
  templateUrl: './filter-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FilterBarComponent implements OnInit {
  filter = [];
  @HostBinding('class')
  get classes(): string {
    return 'dash-filter-bar';
  }

  @Output()
  filterChange = new EventEmitter<DashAppliedFilter<any>[]>();

  private _availableFilters: DashFilter[] = [];
  private _appliedFilters: DashAppliedFilter<any>[] = [];

  constructor(public dialog2: MdcDialog) { }

  ngOnInit(): void {
  }

  onChipRemoved(e: any) {
  }

  openFilterSetting(event: MdcMenuSelectedEvent) {
    let filter = this.addableFilters[event.index];

    const dialogRef = this.dialog2.open(filter.settingComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { filter },
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value && value != 'close') {
        this._appliedFilters.push(Object.assign({}, filter, { value }));
        this.filter = this._appliedFilters;
        this.filterChange.emit(this._appliedFilters);
      }
    });
  }

  editFilterSetting(index: number) {
    let filter = this.appliedFilters[index];
    const dialogRef = this.dialog2.open(filter.definition?.settingComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: { filter: filter.definition, value: filter.value },
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value && value != 'close') {
        this._appliedFilters[index] = Object.assign({}, this._appliedFilters[index], { value });
        this.filter = this._appliedFilters;
        this.filterChange.emit(this._appliedFilters);
      }
    });
  }

  onFilterRemoved(index: number) {
    this._appliedFilters.splice(index, 1);
    this.filterChange.emit(this._appliedFilters);
  }

  removeAllFilter() {
    this._appliedFilters = [];
    this.filter = [];
    this.filterChange.emit(this._appliedFilters);
  }

  @Input()
  set appliedFilters(filters: DashAppliedFilter<any>[]) {
    this._appliedFilters = filters;
  }

  get appliedFilters(): DashAppliedFilter<any>[] {
    const availableFilters = this._availableFilters;
    const filters = this._appliedFilters.map(filter => {
      const definition = availableFilters.find(f => f.key == filter.key);

      if (!definition) {
        return null;
      }

      filter.definition = definition;

      return filter;
    }).filter(filter => !isNull(filter));

    return filters;
  }

  @Input()
  set availableFilters(filters: DashFilter[]) {
    this._availableFilters = filters;
  }

  get availableFilters(): DashFilter[] {
    const filters = this._availableFilters.map(filter => {
      switch (filter.type) {
        case "daterange":
          filter.displayComponent = DateRangeDisplayComponent;
          filter.settingComponent = DateRangeSettingComponent;
          break;
        case "select-box":
          filter.displayComponent = SelectBoxDisplayComponent;
          filter.settingComponent = SelectBoxSettingComponent;
          break;
        case "text":
          filter.displayComponent = TextDisplayComponent;
          filter.settingComponent = TextSettingComponent;
          break;
        case "number-range":
          filter.displayComponent = NumberRangeDisplayComponent;
          filter.settingComponent = NumberRangeSettingComponent;
          break;
        case "select-search-box":
          filter.displayComponent = SelectSearchBoxDisplayComponent;
          filter.settingComponent = SelectSearchBoxSettingComponent;
          break;
        case "date":
          filter.displayComponent = DateDisplayComponent;
          filter.settingComponent = DateSettingComponent;
          break;
      }
      return filter;
    });

    return filters;
  }

  get addableFilters(): DashFilter[] {
    const filters = this.availableFilters.filter(a => {
      return this.appliedFilters.findIndex(b => b.key === a.key) === -1;
    });

    return filters;
  }

}
