<div>
  <!-- <dash-title [title]="title"></dash-title> -->
  <ic-product-list
    [pageSize]="pageSize"
    [total]="total$ | async"
    [rows]="products$ | async"
    [displayedColumns]="displayedColumns$ | async"
    (load)="loadProduct($event)">
  </ic-product-list>
</div>
