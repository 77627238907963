import { createAction, props } from '@ngrx/store';

export const loadGlnsSuccess = createAction(
  '[Glns Api] Load Glns Success',
  props<{ data: any }>()
);

export const loadGlnsFailure = createAction(
  '[Glns Api] Load Glns Failure',
  props<{ error: any }>()
);

