
<div>
  <form [formGroup]="createDocumentForm" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group">
            <div class="label-form">Tên giấy tờ (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <input matInput placeholder="Nhập tên giấy tờ" formControlName="title">
            </mat-form-field>
          </div>
          <div class="form-group">
            <div class="label-form">File giấy tờ (*)</div>
            <ic-file-list style="display: inline-flex;"  [files]="documents" (addFile)="openUploadDialog('avatar')"
            (removeFile)="onRemoveDocument('document', $event)"></ic-file-list>
            <div>
              <div *ngFor="let item of listFileImport; index as i" class="div-file">
                <span class="file-label" [title]="item" (click)="openNewTab(item.url)">{{item.name}}</span>
                <mat-icon class="icon-delete" (click) = "deleteFileImport(i)">clear</mat-icon>
              </div>
            </div>
      <!-- <ic-file-list style="display: inline-flex;"  [files]="file" (addFile)="openUploadDialog('logo')"
      (removeFile)="onRemoveFile('logo', $event)"></ic-file-list> -->
            <!-- <div class="div-import">
              <div class="btn-upload">
                <label class="typeLable">
                  <mat-icon style="margin-right: 0.5rem;">cloud_upload</mat-icon>
                  <span>Click to upload</span>
                  <input type="file" #inputUpload (input)="importFile($event.target.files[0])">
                </label>
              </div>

            </div> -->
          </div>
          <div class="form-group">
            <div class="label-form">Ngày hết hạn</div>
            <mat-form-field  appearance="outline">
              <mat-label>Choose a date</mat-label>
              <input matInput  readonly (click)="picker.open()" [matDatepicker]="picker" formControlName="expireAt"  >
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

        <div class="form-group" *ngIf="data.type === 'createDocumentProduct' && data.action === 'create'">
          <div class="label-form">Chọn loại giấy tờ sản phẩm (*)</div>
          <mat-radio-group class="box-radio-group"  formControlName="docProductType">
            <mat-radio-button class="content" [value]="document.value" *ngFor="let document of listProductDocument">{{document.label}}</mat-radio-button>
          </mat-radio-group>
        </div>
        </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!createDocumentForm.valid">Lưu</button>
      </mat-toolbar>
