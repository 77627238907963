import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AttributeRoutingModule } from './attribute.routing';
import {
  AttributeProductListComponent,
  AttributeProductModalComponent,
  AlertConfirmComponent,
 } from './components';
import { AttributeProductPageComponent, AttributeListPageComponent } from './containers';
import { MaterialModule } from '../shared/material.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';
import { TitleModule } from '../dash/title';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { AttributeProductEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { MDCDataTableModule, MdcCheckboxModule, MdcCard, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule } from '@angular-mdc/web';
import { DashCheckboxModule } from '@dash/checkbox';
import { FeatureBarModule } from '@dash/feature-bar';


export const COMPONENTS = [
  AttributeProductListComponent,
  AttributeProductModalComponent,
  AlertConfirmComponent,
];

export const CONTAINERS = [
  AttributeProductPageComponent,
  AttributeListPageComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    CommonModule,
    AttributeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MDCDataTableModule,
    MdcCheckboxModule,
    MdcCardModule,
    FeatureBarModule,
    DashCheckboxModule,
    MdcIconModule,
    MdcButtonModule,
    MdcIconButtonModule,
    MdcDialogModule,
    MaterialModule,
    DashFilterBarModule,
    TitleModule,
    StoreModule.forFeature(fromEffects.attributeProductFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([AttributeProductEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: [],
})
export class AttributeModule { }
