import { Directive } from '@angular/core';

@Directive({
  selector: '[dashFeatureBarTitle]',
  host: {
    'role': 'heading',
    'class': 'dash-feature-bar__title'
  }
})
export class TitleDirective {

  constructor() { }

}
