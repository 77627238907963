import { ProductAttributeGroupContainer } from './containers/produc-attribute-group.container';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [{
  path: '', component: ProductAttributeGroupContainer
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductAttributeGroupRoutingModule { }
