<mat-card>
  <form [formGroup]="glnForm" (ngSubmit)="onFormSubmit()">
  <div class="content-modal">
    <div class="form-group">
      <div class="column" style="width: 90%;">
         <div class="label-form">Tên doanh nghiệp (*)</div>
            <mat-form-field appearance="outline" class="form-field">
                <input matInput placeholder="Nhập tên doanh nghiệp" type="text" formControlName="name" maxlength="255">
            </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <div class="column">
        <div class="col-1">
          <div class="label-form">Prefix</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập prefix" formControlName="prefixCode" maxlength="255">
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Mã địa chỉ toàn cầu GLN</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập Mã địa chỉ toàn cầu GLN" formControlName="glnCode" maxlength="255">
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Loại mã</div>
          <mat-form-field appearance="outline" class="form-field">
              <mat-label>Chọn loại mã</mat-label>


              <mat-select formControlName="glnType">
                  <mat-option value="gs1_official_code">Mã GS1 chính thống</mat-option>
                  <mat-option value="gs1_unofficial_code">Mã GS1 không chính thống</mat-option>
                  <mat-option value="icheck_internal_code">Mã nội bộ iCheck</mat-option>
                  <mat-option value="enterprise_internal_code">Mã nội bộ doanh nghiệp</mat-option>
              </mat-select>
              <span matSurfix style="margin-right: 8px;" *ngIf="glnForm.value.glnType" (click)="clearGlnType()"><mat-icon>close</mat-icon></span>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Quốc gia (*)</div>
          <mat-form-field appearance="outline" class="form-field">
              <mat-select formControlName="nationalCode" #matSelectNation (selectionChange)="changeNation($event)" placeholder="Chọn quốc gia">
                <mat-form-field appearance="outline" class="form-field-search" style="max-width: 100%;">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput
                      type="text"
                      name="filter-options"
                      id="filter-options"
                      placeholder="Search"
                      (keyup)= "changeInputSearchNation($event)"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="inputNation" [ngModelOptions]="{standalone: true}">
                      <mat-icon matSurfix *ngIf="inputNation" (click) = "clearInputNationSearch()">clear</mat-icon>
                </mat-form-field>
                <mat-option [value]="nation.code" *ngFor="let nation of listNation">
                    {{nation.name}}
                </mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Tỉnh/Thành phố (*)</div>
          <mat-form-field appearance="outline" class="form-field">
              <mat-select formControlName="provinceCode" #matSelectCity (selectionChange)="changeCity($event)" placeholder="Chọn tỉnh/thành phố">
                <mat-form-field appearance="outline" class="form-field-search" style="max-width: 100%;">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput
                      type="text"
                      name="filter-options"
                      id="filter-options"
                      placeholder="Search"
                      (keyup)= "changeInputSearchCity($event)"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="inputCity" [ngModelOptions]="{standalone: true}">
                      <mat-icon matSurfix *ngIf="inputCity" (click) = "clearInputCitySearch()">clear</mat-icon>
                </mat-form-field>
                <mat-option [value]="city.code" *ngFor="let city of listCity">{{city.name}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form" disabled="true">Quận/Huyện (*)</div>
          <mat-form-field appearance="outline" class="form-field">
              <mat-select formControlName="districtId" #matSelectDistrict (selectionChange)="changeDistrict($event)" placeholder="Chọn quận/huyện">
                <mat-form-field appearance="outline" class="form-field-search" style="max-width: 100%;">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput
                      type="text"
                      name="filter-options"
                      id="filter-options"
                      placeholder="Search"
                      (keyup)= "changeInputSearchDistrict($event)"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="inputDistrict" [ngModelOptions]="{standalone: true}">
                      <mat-icon matSurfix *ngIf="inputDistrict" (click) = "clearInputDistrictSearch()">clear</mat-icon>
                </mat-form-field>
                <mat-option [value]="district.id" *ngFor="let district of listDistrict">{{district.name}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Phường/ Xã (*)</div>
          <mat-form-field appearance="outline" class="form-field">
              <mat-select formControlName="wardId" #matSelectWard placeholder="Chọn phường/xã">
                <mat-form-field appearance="outline" class="form-field-search" style="max-width: 100%;">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput
                      type="text"
                      name="filter-options"
                      id="filter-options"
                      placeholder="Search"
                      (keyup)= "changeInputSearchWard($event)"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="inputWard" [ngModelOptions]="{standalone: true}">
                      <mat-icon matSurfix *ngIf="inputWard" (click) = "clearInputWardSearch()">clear</mat-icon>
                </mat-form-field>
                <mat-option [value]="ward.id" *ngFor="let ward of listWard">{{ward.name}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Địa chỉ (*)</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập địa chỉ" formControlName="address" maxlength="255">
          </mat-form-field>
        </div>
      </div>
      <div class="column">
        <div class="col-1">
          <div class="label-form">Mã số thuế</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập mã số thuế" formControlName="taxCode" maxlength="255">
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="label-form">Điện thoại</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập số điện thoại" type="text" formControlName="phone" maxlength="255">
          </mat-form-field>
          <mat-error *ngIf="!glnForm.controls.phone.valid" class="label_error">
              Số điện thoại sai định dạng
          </mat-error>
        </div>
        <div class="col-1">
          <div class="label-form">Email</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập email" type="email" formControlName="email" maxlength="255">
          </mat-form-field>
          <mat-error *ngIf="!glnForm.controls.email.valid" class="label_error">
              Email sai định dạng
          </mat-error>
        </div>
        <div class="col-1">
          <div class="label-form">Website</div>
          <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập website" formControlName="website" maxlength="255">
          </mat-form-field>
        </div>
        <div class="col-1">
          <div class="modal-avatar">
            <div class="label-form">Ảnh đại diện</div>
            <ic-file-list   [files]="avatar" (addFile)="openUploadDialog('avatar')"
                       (removeFile)="onRemoveAvatar('avatar', $event)"></ic-file-list>
          </div>
        </div>
        <div class="col-1">
          <div class="modal-background">
            <div class="label-form">Ảnh bìa</div>
            <ic-file-list   [files]="backgroundImage" (addFile)="openUploadDialog('backgroundImage')"
                       (removeFile)="onRemoveBackground('backgroundImage', $event)"></ic-file-list>
          </div>
        </div>
      </div>

    </div>
    <div class="form-group">
      <div class="column">

      </div>
      <div class="column">

      </div>




    </div>
    <div class="form-group">
      <div class="column">

      </div>
      <div class="column">

      </div>

    </div>
    <div class="form-group">


      <div class="column">

      </div>
      <div class="column">

      </div>
    </div>
    <div class="form-group" style="display: flex;">
        <div class="column">

        </div>
        <div class="column">

        </div>

    </div>

    <div class="form-group">
      <div class="column">

    </div>
    <div class="column">

    </div>



    </div>
    <div class="form-group">
      <div class="column">

      </div>
      <div class="column">

      </div>
    </div>
    <div class="form-group">

    </div>


</div>
<div class="list-button">
  <button mat-raised-button class="primary btn-create" type="submit" *ngIf="!this.isUpdate; else updateButton" [disabled] = "!glnForm.valid || isLoading">Thêm mới</button>
  <ng-template #updateButton><button mat-raised-button class="primary btn-update" type="submit" [disabled] = "!glnForm.valid "><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
  </mat-spinner></mat-icon>Cập nhật</button></ng-template>
  <button mat-raised-button type="button" class="btn-cancel" (click)="backToListProduct()">Hủy bỏ</button>
</div>
</form>
</mat-card>
