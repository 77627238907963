import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AlertProductsModalComponent } from '../alert-products-modal/alert-products-modal.component';
import { Store, select } from '@ngrx/store';
import * as fromProducts from '@products/reducers';

import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as fromAlert from '@alert/reducers';
import { AlertActions } from '@alert/actions';
import { ProductPageActions } from '@products/actions';

import { AlertConfirmComponent } from '@dash/alert-confirm';

import { MdcDialog } from '@angular-mdc/web';
import { takeUntil } from 'rxjs/operators';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ProductService} from '@products/services/product.services';
declare function enterScreenView (screenName, contentType)

@Component({
  selector: 'ic-alert-product',
  templateUrl: './alert-product.component.html',
  styleUrls: ['./alert-product.component.scss']
})
export class AlertProductComponent extends ModalScroll implements OnInit {

  productId;
  productCurrent$: Observable<any>;
  currentProduct;
  destroy$ = new Subject();
  currentAlert$: Observable<any>;
  currentAlert: any;
  barcode: string
  constructor(private dialog: MatDialog,
              private dialog2: MdcDialog,
              private route: ActivatedRoute,
              private storeProduct: Store<fromProducts.State>,
              private productService: ProductService,
              private storeAlert: Store<fromAlert.State>) {
    super();
    this.productCurrent$ = storeProduct.pipe(select(fromProducts.selectProductCurrent));
    this.currentAlert$ = storeAlert.pipe(select(fromAlert.selectCurrentAlert));
    }

  async ngOnInit() {
    enterScreenView('product_alert' , 'product')
    this.productId = this.route.snapshot.paramMap.get('id');
    // if (this.productId) {
    //   this.storeProduct.dispatch(ProductPageActions.getProduct({ id: this.productId }))
    // }
    const data: any = await this.productService.getDetailProduct(this.productId).toPromise();
    this.barcode = data.data.barcode;
    this.productCurrent$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      // console.log(it);
      if (it?.alert?.id) {
        this.currentAlert = it.alert;
      } else {
        this.currentAlert = {};
      }
      // console.log(this.currentAlert);
    });

    this.currentAlert$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(val => {
      // this.currentAlert = val
    });
  }


  openDialogAlertProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Gán sản phẩm cảnh báo',
      action: 'create',
      productId : this.productId,
      barcode: this.barcode
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AlertProductsModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
        // console.log(this.productId);

        setTimeout(() => {
          this.storeProduct.dispatch(ProductPageActions.getProduct({ id: this.productId }));
        }, 500);
        console.log('The dialog was closed');
    });
  }
  updateAlertProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật cảnh báo cho sản phẩm',
      action: 'update',
      productId : this.productId,
      barcode: this.barcode
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AlertProductsModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.storeProduct.dispatch(ProductPageActions.getProduct({ id: this.productId }));
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

  deleteAlertProduct() {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc muốn gỡ cảnh báo đối với sản phẩm này không?'
    });
    // console.log(this.currentProduct);

    const object = {
      alertId : this.currentAlert.id,
      productId: this.productId
    };
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.storeAlert.dispatch(AlertActions.removeAlertForProduct({ object }));
        setTimeout(() => {
          this.storeProduct.dispatch(ProductPageActions.getProduct({ id: this.productId }));
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

  showDocument(imageLink) {
    window.open(imageLink, '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
