import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashFilter } from '@dash/filter-bar/filter-bar.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { MdcDialog } from '@angular-mdc/web';
import { Store, select } from '@ngrx/store';
import * as fromGln from '../reducers';
import { GlnListPageActions } from '@gln/actions';
import { formatDate, clean } from 'src/app/shared/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'ic-gln-page',
  templateUrl: './gln-list-page.component.html',
  styles: []
})
export class GlnPageComponent implements OnInit {
 
  displayedColumns: string[] = ['select', 'id', 'image', 'name',  'status', 'nation', 'area', 'address', 'info', 'type','enterprise_special','updated_at', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  availableFilters: DashFilter[] = [];
  title = "Quản lý Gln"

  selection = new SelectionModel<any>(true, []);

  filter$: Observable<number>;
  loading$: Observable<boolean>;
  total$: Observable<number>;
  glns$: Observable<any>;
  displayedColumns$: Observable<string[]>;


  constructor(
    private dialog: MdcDialog,
    // private store2: NgxsStore,
    private store: Store<fromGln.State>,
    private router: Router) {
      this.glns$ = store.pipe(select(fromGln.selectGlnListPageResults));
      this.total$ = store.pipe(select(fromGln.selectGlnListPageTotal));
      this.filter$ = store.pipe(select(fromGln.selectGlnListPageFilter));
      this.loading$ = store.pipe(select(fromGln.selectGlnListPageLoading));
      this.displayedColumns$ = store.pipe(select(fromGln.selectGlnListPageDisplayedColumns));
    }

  ngOnInit(): void {
  }

  loadGln([pageData, filterData, page]: any) {
    // console.log(pageData, filterData, page);
    
    const limit = pageData ? pageData.pageSize : this.pageSize;
    const offset = limit * (pageData ? pageData.pageIndex : 0);
    console.log('34434', pageData);
    console.log('34434', filterData);
    
    // this.store.dispatch(
    //   GlnListPageActions.loadCategories({
    //     filter,
    //     offset,
    //     limit
    //   })
    // );
    const filter = clean(filterData);
    this.store.dispatch(
      GlnListPageActions.loadGlns({
        page: pageData.pageIndex + 1,
        pageSize: pageData.pageSize,
        filter
      })
    );
  }

  // openDeleteAlert(items: any) {
  //   const dialogRef = this.dialog.open(DeleteAlertComponent, {
  //     escapeToClose: false,
  //     clickOutsideToClose: false,
  //     buttonsStacked: false
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(result);
  //   });
  // }
}
