import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AlertActions } from '../actions';

export const alertListFeatureKey = 'alertList';

export interface State {
  ids: number[];
  filter: any;
  total: number;
  loading: boolean;
  currentAlert: any;
}

export const initialState: State = {
  ids: [],
  filter: {},
  total: 0,
  loading: false,
  currentAlert: {}
};

export const alertListReducer = createReducer(
  initialState,
  on(
    AlertActions.loadAction,
    (state, { filter }) => {
      return {
        ...state,
        filter,
        loading: true,
      }
    }
  ),
  on(
    AlertActions.loadActionSuccess,
    (state, { alert, total }) => {
      return {
        ...state,
        ids: alert.map(item => item.id),
        total: total,
        loading: false,
        currentAlert: {}
      }
    }
  ),
  on(
    AlertActions.getDetailAlertSuccess,
    (state, { alert }) => {
      return {
        ...state,
        currentAlert: alert
      }
    }
  ),
  on(
    AlertActions.getDetailAlertFailure,
    (state, { error }) => {
      return {
        ...state,
        error
      }
    }
  ),

  on(
    AlertActions.addAlertForProductSuccess,
    (state, { alert }) => {
      return {
        ...state,
      }
    }
  ),
  on(
    AlertActions.addAlertForProductFailure,
    (state, { error }) => {
      return {
        ...state,
        error
      }
    }
  ),
  on(
    AlertActions.removeAlertForProductSuccess,
    (state, { alert }) => {
      return {
        ...state,
      }
    }
  ),
  on(
    AlertActions.removeAlertForProductFailure,
    (state, { error }) => {
      return {
        ...state,
        error
      }
    }
  ),
//   on(
//     AlertActions.loadActionFailure,
//     (state, { alert }) => {
//       return {
//         ...state,
//         ids: alert.list.map(item => item.id),
//         total: alert.total,
//         loading: false,
//       }
//     }
//   ),
);

export function reducer(state: State | undefined, action: Action) {
  return alertListReducer(state, action);
}

export const getIds = (state: State) => state.ids;
export const getTotalAlert = (state: State) => state.total;

export const getObjectFilter = (state: State) => state.filter;

export const getLoadingData = (state: State) => state.loading;

export const getCurrentAlert = (state: State) => state.currentAlert;
