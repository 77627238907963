import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GlnActions, GlnPageActions, GlnApiActions } from '../actions';

export const glnsFeatureKey = 'glns';

export interface State extends EntityState<any> {
  selectedGlnId: string | null;
  total: number;
  location: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (gln: any) =>  {
    // console.log(gln);
    return gln.id || gln
  }
  // sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedGlnId: null,
  total: 0,
  location: []
});

export const glnReducer = createReducer(
  initialState,
  on(
    GlnActions.loadGlnsSuccess,
    (state, { rows }) => {
      return adapter.upsertMany(rows, state)
    }
  ),
  on(GlnActions.updateGln, (state, { glnObject }) => {
    // console.log(glnObject);
    return adapter.updateOne({ id: glnObject.id, changes: glnObject }, state);
  }),
  on(GlnActions.updateGlnFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(GlnActions.addGln, (state, { glnObject }) => {
    // console.log(glnObject);

    return adapter.addOne(glnObject , state);
  }),
  on(GlnActions.addGlnFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(GlnActions.updateListGln, (state, { glns, gln }) => {
    return adapter.upsertMany(glns, state);
  }),
  on(GlnActions.updateListGlnFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(GlnActions.searchGlnSuccess, (state, { rows }) => {
    // console.log(glns);

    return adapter.upsertMany(rows, state);
  }),
  on(GlnActions.searchGlnFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(GlnActions.deleteGlnSuccess, (state, { id }) => {
    // console.log(id);

    return adapter.removeOne(id, state);
  }),
  on(GlnActions.deleteGlnFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(GlnPageActions.loadGlnsSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  on(GlnApiActions.loadGlnsSuccess, (state, { data }) => {
    // console.log(data);

    return adapter.upsertMany(data, state);
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return glnReducer(state, action);
}

export const getSelectedGlnId = (state: State) => state.selectedGlnId;

export const getLocation = (state: State) => state.location;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectGlnIds = selectIds;

// select the dictionary of user entities
export const selectGlnEntities = selectEntities;

// select the array of users
export const selectAllGlns = selectAll;

// select the total user count
export const selectGlnTotal = selectTotal;
