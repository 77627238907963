import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of, Observable, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, concatMap, withLatestFrom, tap, take, takeUntil, debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromGln from '../reducers';
import {
  GlnActions, GlnListPageActions, GlnApiActions, GlnPageActions,
} from '../actions';

import { GlnService } from '../services/gln.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlnEffects {
  constructor(
    private actions$: Actions,
    private glnService: GlnService,
    private store: Store<fromGln.State>,
    private _snackBar: MatSnackBar,
  ) {}
  loadGln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlnActions.loadGln),
      switchMap(action => {
        // console.log(action);
        return this.glnService.getListEnterprise(action.filter).pipe(
          map((res) => {
            // console.log(res);

            // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
            return GlnActions.loadGlnsSuccess({ rows: [], total: 0 })
          }),
          catchError(error =>
            of(GlnActions.loadGlnsFailure({ error }))
          )
        )
          })
    )
  });

  getDetailGln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlnPageActions.getDetailGln),
      switchMap(action => {
        // console.log(action);
        return this.glnService.getDetailEnterprise(action.id).pipe(
          map((gln: any) => {

            return GlnPageActions.getDetailGlnSuccess({ gln: gln.data })
          }),
          catchError(error =>
            of(GlnPageActions.getDetailGlnFailure({ error }))
          )
        )
          })
    )
  });

  loadListGln$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlnListPageActions.loadGlns),
      switchMap(action => {
        // console.log(action);

        return this.glnService.getListEnterprise(action).pipe(
          switchMap((res: any) => {
            // console.log(res);

            return [
            GlnApiActions.loadGlnsSuccess({ data: res.data.items }),
            GlnListPageActions.loadGlnsSuccess({
              total: res.data.totalElement,
              ids: res.data.items.map(item => item.id)
            }),
            GlnPageActions.loadGlnsSuccess({
              total: res.data.totalElement,
              rows: res.data.items.map(item => item.id)
            })
          ]}),
          catchError(error => [
            GlnApiActions.loadGlnsFailure({ error }),
            GlnListPageActions.loadGlnsFailure({ error })
          ])
        );
      })
    )
  });

  updateGln$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlnActions.updateGln),
      switchMap((actions) =>
      {
        return this.glnService.updateEnterprise(actions.glnObject).pipe(
          map((gln: any) =>
           {
            //  console.log(gln);

            this._snackBar.open('Cập nhật thông tin GLN thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return GlnActions.updateGlnSuccess({ gln })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(GlnActions.updateGlnFailure({ error }))
          }
          )
        )
          })
    )
  );

  addGln$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlnActions.addGln),
      switchMap((actions) =>
      {
        return this.glnService.createEnterprise(actions.glnObject).pipe(
          map((gln: any) =>
           {
            // console.log(gln);

              this._snackBar.open('Thêm mới GLN thành công', 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              return GlnActions.addGlnSuccess({ gln })
           }
            )
           ,
          catchError(error => {
            // console.log(error);

            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return  of(GlnActions.addGlnFailure({ error }))
          }
          )
        )
          })
    )
  );

  deleteAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GlnActions.deleteGln),
      switchMap((actions) =>
      {
        return this.glnService.deleteEnterprise(actions.id).pipe(
          map((success) =>
           {
            //  console.log(success);

            this._snackBar.open('Xóa GLN thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return GlnActions.deleteGlnSuccess({ id: actions.id })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(GlnActions.deleteGlnFailure({ error }))
          }
          )
        )
          })
    )
  );

//   subscribeAction = createEffect(() =>
//     this.actions$.pipe(
//       ofType(GlnActions.deleteGlnSuccess, GlnActions.updateGlnSuccess, GlnActions.addGlnSuccess),
//       concatMap(action => of(action).pipe(
//         withLatestFrom(this.store.pipe(select(fromGln.selectObjectFilterGln)))
//       )),
//       debounceTime(200),
//       switchMap(([action, filters]) => {
//         console.log('aaaa');

//         return [GlnActions.loadGln({ filter: filters })];
//       })
//     /*
//     switchMap(([actions, filters]) =>
//     {
//       // console.log('aaaaaaaaaaaaaaaaaaa', this.store);
//       // const filter: Observable<Object> = this.store.pipe(select(fromGln.selectObjectFilterGln)).withLatestFrom(it => it);
//       const filter = {
//         limit: 5,
//         offset: 0
//       }
//       return this.glnService.getGlns(filter).pipe(
//         map(({rows, total}) =>
//          {
//           return GlnActions.loadGlnsSuccess({ rows, total })
//          }
//           )
//          ,
//         catchError(error =>
//           of(GlnActions.loadGlnsFailure({ error }))
//         )
//       )
//     })
//     */
//   ),
//   { dispatch: true }
// );

loadNation$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(GlnActions.loadNation),
    switchMap(action => {
      // console.log(action);
      return this.glnService.getLocation(action.request).pipe(
        map((res: any) => {
          // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
          return GlnActions.loadNationSuccess({ rows: res.data.items })
        }),
        catchError(error =>
          of(GlnActions.loadNationFailure({ error }))
        )
      )
        })
  )
});

loadCity$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(GlnActions.loadCity),
    switchMap(action => {
      // console.log(action);
      return this.glnService.getLocation(action.request).pipe(
        map((res: any) => {
          // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
          return GlnActions.loadCitySuccess({ rows: res.data.items })
        }),
        catchError(error =>
          of(GlnActions.loadCityFailure({ error }))
        )
      )
        })
  )
});

loadDistrict$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(GlnActions.loadDistrict),
    switchMap(action => {
      // console.log(action);
      return this.glnService.getLocation(action.request).pipe(
        map((res: any) => {
          // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
          return GlnActions.loadDistrictSuccess({ rows: res.data.items })
        }),
        catchError(error =>
          of(GlnActions.loadDistrictFailure({ error }))
        )
      )
        })
  )
});

loadWard$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(GlnActions.loadWard),
    switchMap(action => {
      // console.log(action);
      return this.glnService.getLocation(action.request).pipe(
        map((res: any) => {
          // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
          return GlnActions.loadWardSuccess({ rows: res.data.items })
        }),
        catchError(error =>
          of(GlnActions.loadWardFailure({ error }))
        )
      )
        })
  )
});

//   updateListGln$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(GlnActions.updateListGln),
//       switchMap((actions) =>
//       {
//         return this.glnService.updateListGln(actions.glns, actions.gln).pipe(
//           map((gln) =>
//            {
//             return GlnActions.updateListGlnSuccess({ gln })
//            }
//             )
//            ,
//           catchError(error =>
//             of(GlnActions.updateListGlnFailure({ error }))
//           )
//         )
//           })
//     )
//   );

  // searchGln$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(GlnActions.searchGln),
  //     switchMap((actions) => {
  //       // console.log(actions);
  //       return this.glnService.searchGln(actions.objectSearch).pipe(
  //         map((glns) => {
  //           // console.log(glns);
  //           return GlnActions.searchGlnSuccess({ glns })
  //         }),
  //         catchError(error =>
  //           of(GlnActions.searchGlnFailure({ error }))
  //         )
  //       );
  //     })
  //   )
  // );
}
