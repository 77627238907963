import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web/dialog';
import { startOfToday } from 'date-fns';
import { DashFilterSettingData } from '../filter-bar.component';

export type DateRangeFilterValue = {
  startDate: Date,
};

@Component({
  selector: 'ic-date-setting',
  templateUrl: './date-setting.component.html',
  styleUrls: ['./date-setting.component.scss']
})
export class DateSettingComponent implements OnInit {
  today = startOfToday();
  date: Date;
  form: FormGroup;

  constructor(
    public dialogRef: MdcDialogRef<any>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<DateRangeFilterValue>,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: this.fb.control(null, [Validators.required]),
    });
    if (this.data.value) {
      this.date = this.data.value.startDate;
      this.form.updateValueAndValidity();
    }
  }

  onDateChanged(date: string, event: MatDatepickerInputEvent<Date>) {
    this.form.get(date).setValue(event.value);
  }

  onApply() {
    this.dialogRef.close(this.form.value);
  }

}