import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, concatMap, withLatestFrom, debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromAttribute from '../reducers';
import {
  AttributeProductActions, AttributeListPageActions, AttributeApiActions, AttributePageActions,
} from '../actions';

import { AttributeProductService } from '../services/attribute-product.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AttributeProductEffects {
  constructor(
    private actions$: Actions,
    private attributeProductService: AttributeProductService,
    private store: Store<fromAttribute.State>,
    private _snackBar: MatSnackBar,
  ) {}
  // loadAttributeProduct$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(AttributeProductActions.loadAttributeProduct),
  //     switchMap(action => {
  //       // console.log(action);
  //       return this.attributeProductService.getAttributeProducts(action.filter).pipe(
  //         map((attributeProducts: any) => {
  //           // console.log(attributeProductss);
  //           return AttributeProductActions.loadAttributeProductsSuccess({ attributeProducts: attributeProducts.list, total: attributeProducts.total  })
  //         }),
  //         catchError(error =>
  //           of(AttributeProductActions.loadAttributeProductsFailure({ error }))
  //         )
  //       )
  //         })
  //   )
  // });


  loadAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttributeListPageActions.loadAttributes),
      switchMap(action => {
        // console.log(action);
        
        return this.attributeProductService.getListAttribute(action).pipe(
          switchMap((res: any) => {
            // console.log(rows, total);
            // console.error(res);
            
            return [
            AttributeApiActions.loadAttributesSuccess({ data: res.data.items }),
            AttributeListPageActions.loadAttributesSuccess({
              total: res.data.totalElement,
              ids: res.data.items.map(c => c.attributeId)
            }),
            AttributePageActions.loadAttributesSuccess({
              total: res.data.totalElement,
              rows: res.data.items
            })
          ]}),
          catchError(error => [
            AttributeApiActions.loadAttributesFailure({ error }),
            AttributeListPageActions.loadAttributesFailure({ error })
          ])
        );
      })
    )
  });

  updateAttributeProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributeProductActions.updateAttributeProduct),
      switchMap((actions) =>
      {
        return this.attributeProductService.updateAttribute(actions.attributeProductObject).pipe(
          map((res: any) =>
           {
            this._snackBar.open('Cập nhật thuộc tính sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AttributeProductActions.updateAttributeProductSuccess({ attributeProduct: res })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(`${error}`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AttributeProductActions.updateAttributeProductFailure({ error }))
          }
            
          )
        )
          })
    )
  );

  getDetailAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributePageActions.getDetailAttribute),
      switchMap((actions) =>
      {
        // console.log(actions);
        
        return this.attributeProductService.getDetailAttribute(actions.id).pipe(
          map((res: any) =>
            {
              // console.log(res);
              return AttributePageActions.getDetailAttributeSuccess({ attribute: res.data })
            }
            )
           ,
          catchError(error => {
            return of(AttributePageActions.getDetailAttributeFailure({ error }))
          }
            
          )
        )
          })
    )
  );

  addAttributeProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributeProductActions.addAttributeProduct),
      switchMap((actions) =>
      {
        return this.attributeProductService.createAttribute(actions.attributeProductObject).pipe(
          map((attributeProduct: any) =>
           {
            this._snackBar.open('Thêm thuộc tính sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AttributeProductActions.addAttributeProductSuccess({ attributeProduct })
           }
            )
           ,
          catchError(error => {
            // console.log(error);
            
            this._snackBar.open(`${error}`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AttributeProductActions.addAttributeProductFailure({ error }))
          }
            
          )
        )
          })
    )
  );

  deleteAttributeProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttributeProductActions.deleteAttributeProduct),
      switchMap((actions) =>
      {
        // console.log(actions);
        
        return this.attributeProductService.deleteAttribute(actions.id).pipe(
          map((attributeProduct: any) =>
           {
            
            return AttributeProductActions.deleteAttributeProductSuccess({ attributeProduct })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AttributeProductActions.deleteAttributeProductFailure({ error }))
          }
          )
        )
          })
    )
  );
  
  // subscribeAction = createEffect(() =>
  // this.actions$.pipe(
  //   ofType(AttributeProductActions.addAttributeProduct, AttributeProductActions.updateAttributeProduct, AttributeProductActions.deleteAttributeProduct),
  //   concatMap(action => of(action).pipe(
  //     withLatestFrom(this.store.pipe(select(fromAttribute.selectObjectFilterAttributeProduct)))
  //   )),
  //   debounceTime(300),
  //   switchMap(([action, filters]) => {
  //     // console.log('aaaa');
      
  //     return [AttributeListPageActions.loadAttributes({ filter: filters.filter, page: filters.limit, offset: filters.offset })];
  //   })
  // ),
  // { dispatch: true }
  // );

//   updateListAttribute$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(AttributeActions.updateListAttribute),
//       switchMap((actions) =>
//       {
//         return this.attributeService.updateListAttribute(actions.attributeProductss, actions.attribute).pipe(
//           map((attribute) =>
//            {
//             return AttributeActions.updateListAttributeSuccess({ attribute })
//            }
//             )
//            ,
//           catchError(error =>
//             of(AttributeActions.updateListAttributeFailure({ error }))
//           )
//         )
//           })
//     )
//   );

  // searchAttribute$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AttributeActions.searchAttribute),
  //     switchMap((actions) => {
  //       // console.log(actions);
  //       return this.attributeService.searchAttribute(actions.objectSearch).pipe(
  //         map((attributeProductss) => {
  //           // console.log(attributeProductss);
  //           return AttributeActions.searchAttributeSuccess({ attributeProductss })
  //         }),
  //         catchError(error =>
  //           of(AttributeActions.searchAttributeFailure({ error }))
  //         )
  //       );
  //     })
  //   )
  // );
}
