import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { DashFilterSettingData } from '../filter-bar.component';

@Component({
  selector: 'ic-number-range-setting',
  templateUrl: './number-range-setting.component.html',
  styleUrls: ['./number-range-setting.component.scss']
})
export class NumberRangeSettingComponent implements OnInit {


  fromNumber: number;
  toNumber: number;
  form: FormGroup;

  constructor(
    public dialogRef: MdcDialogRef<NumberRangeSettingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<any>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      fromNumber: this.fb.control(0, [Validators.required, Validators.pattern("[0-9]*")]),
      toNumber: this.fb.control(0, [Validators.required, Validators.pattern("[0-9]*")]),
    });
    if (this.data.value) {
      this.fromNumber = this.data.value.fromNumber;
      this.toNumber = this.data.value.toNumber;
      this.form.updateValueAndValidity();
    }
  }

  onApply() {
    if(this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

}
