import { ProductActions } from '@products/actions';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, concatMap, withLatestFrom, debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {
  ProductPageActions, ProductListPageActions,
} from '../actions';

import { Product } from '../models/product.model';
import { ProductService } from '../services/product.services';
// import { BookStorageService } from '@example-app/core/services';
import * as fromProducts from '../reducers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsAPIActions } from '@product/actions';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
@Injectable()
export class ProductEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService, private store: Store<fromProducts.State>,
    private _snackBar: MatSnackBar,
  ) { }


  loadListProduct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductListPageActions.loadProducts),
      switchMap(action => {
        return this.productService.getListProduct(action).pipe(
          switchMap((res: any) => {
            // console.log(res);
            const newData = res.data.items.map(item => {
              const result = item.categories ? item.categories.map(it => it.name) : [];
              item.categories = result;
              return item;
            })

            // console.log('newData', newData);

            return [
              ProductsAPIActions.loadProductsSuccess({ data: res }),
              ProductListPageActions.loadProductsSuccess({
                total: res.data.totalElement,
                ids: res.data.items.map(c => c.id)
              }),
              ProductPageActions.loadProductsSuccess({
                total: res.data.totalElement,
                rows: newData
              })
            ]
          }),
          catchError(error => [
            ProductsAPIActions.loadProductsFailure({ error }),
            ProductListPageActions.loadProductsFailure({ error })
          ])
        );
      })
    )
  });

  addProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addProduct),
      mergeMap((actions) => {
        return this.productService.createProduct(actions.productObject).pipe(
          map((product) => {

            this._snackBar.open('Thêm mới sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductPageActions.addProductSuccess({ product })
          }),
          catchError((error) => {
            this._snackBar.open('Thêm mới sản phẩm thất bại', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(ProductPageActions.addProductFailure({ error }))
          })
        )
      })
    )
  );

  checkBarCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.checkBarCodeExist),
      mergeMap(({ bar_code }) => {
        return this.productService.checkBarCode(bar_code).pipe(
          map((res: any) => {
            console.log(res);

            return ProductPageActions.checkBarCodeExistSuccess({ existed: res.data.exist })
          }),
          catchError((error) => of(ProductPageActions.checkBarCodeExistFailure({ error })))
        )
      })
    )
  );


  updateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.updateProduct),
      mergeMap((actions) => {
        return this.productService.updateProduct(actions.productObject).pipe(
          map((product) => {
            this._snackBar.open('Cập nhật sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductPageActions.updateProductSuccess({ product })
          }),
          catchError((error) => {
            this._snackBar.open('Cập nhật sản phẩm thất bại', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(ProductPageActions.updateProductFailure({ error }))
          })
        )
      })
    )
  );

  getProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.getProduct),
      mergeMap(({id}) => {
        // console.log('ids', id);

        return this.productService.getDetailProduct(id).pipe(
          map((res: any) => {
            // console.log(res);

            return ProductPageActions.getProductSuccess({ product: res.data })
          }),
          catchError((error) => of(ProductPageActions.getProductFailure({ error })))
        )
      })
    )
  );

  subscribeAction = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addProductSuccess, ProductPageActions.updateProductSuccess),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProducts.selectProductPageFilter)))
      )),
      debounceTime(200),
      switchMap(([action, filters]) => {
        console.log('aaaa');

        return [ProductPageActions.loadProducts({ filter: filters })];
      })
    ),
    { dispatch: true }
  );

  $assignEnterprise = createEffect((): any => this.actions$.pipe(
    ofType(ProductActions.assignEnterprise),
    switchMap((action) => {
      return this.productService.assignEnterprise(action.object).pipe(
        map((res: any) => {
          // console.log(res);
          if (res.code === 1104) {
            const request = action.object
            request.params= {
              requestType: 1
            };
            this.productService.assignEnterprise(request).subscribe((res: any) => {
              this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              return ProductPageActions.loadProducts({ filter: {} })
            })
          } else {
            this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductPageActions.loadProducts({ filter: {} })
          }

        }),
        catchError((err) => {
          this._snackBar.open('Gán Gln cho list sản phẩm thất bại', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          return of(ProductActions.assignEnterpriseFailure({ error: err }))
        })
      )
    })
  ))

  $assignCategory = createEffect((): any => this.actions$.pipe(
    ofType(ProductActions.assignCategory),
    switchMap((action) => {
      return this.productService.assignCategory(action.object).pipe(
        map((res: any) => {
          // console.log(res);
          if (res.code === 1104) {
            const request = action.object
            request.params= {
              requestType: 1
            };
            this.productService.assignCategory(request).subscribe((res: any) => {
              this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              return ProductPageActions.loadProducts({ filter: {} })
            })
          } else {
            this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductPageActions.loadProducts({ filter: {} })
          }
        }),
        catchError((err) => {
          // console.log(err);
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          return of(ProductActions.assignCategoryFailure({ error: err }))
        })
      )
    })
  ))
  // removeProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProductActions.removeProduct),
  //     mergeMap(({ product }) =>
  //       this.productService.deleteProduct([product.id]).pipe(
  //         map(() => ProductActions.removeProductSuccess({ product })),
  //         catchError(() => of(ProductActions.removeProductFailure({ product })))
  //       )
  //     )
  //   )
  // );

}
