import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { convertObjectToQueryParams, _delete } from '../../shared/common';
import { environment } from 'src/environments/environment';
import { of, from, Observable } from 'rxjs';
import {distinctUntilChanged, exhaustMap} from 'rxjs/operators';
import {BaseService} from '@src/app/services/base.service';



const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GlnService  extends BaseService{


  constructor(public http: HttpClient) {
    super(http)
  }

  getPDF(object): Observable<Blob> {
    const objectFilter = convertObjectToQueryParams(object.filter);
    const uri = `${this.baseUrl}/enterprise/export?ids=${object.ids}&${objectFilter}`;
    return this.http.post(uri, { },  { responseType: 'blob', headers: new HttpHeaders({
          Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
          'Content-Type' : 'application/json'
        }) });
    }



  getListEnterprise(object) {
    // console.log(object);
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);

    return this.get(`/enterprise?${objectPage}&${objectFilter}`);
  }

  deleteEnterprise(id, object?) {
    let objectPage = {};
    if (object?.params) {
      objectPage = convertObjectToQueryParams(object.params);
      return this.delete(`/enterprise/${id}?${objectPage}`);
    }
    return this.delete(`/enterprise/${id}`);
  }

  getDetailEnterprise(id) {
    return this.get(`/enterprise/${id}`);
  }

  createEnterprise(enterprise) {
    return this.post(`/enterprise`, enterprise).pipe(distinctUntilChanged(), exhaustMap(z => of(z)));
  }

  updateEnterprise(enterprise) {
    // console.log(enterprise);
    const objectPage = convertObjectToQueryParams(enterprise.params);
    return this.put(`/enterprise/${enterprise.id}?${objectPage}`, enterprise);
  }

  getLocation(request) {
    const objectPage = convertObjectToQueryParams(request);
    return this.get(`/location?${objectPage}`);
  }

  getListProductDistributorOfEnterprise(enterpriseId, request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/enterprise/${enterpriseId}/product?${objectPage}&${objectFilter}`);
  }

  createProductDistributorOfEnterprise(enterpriseId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.post(`/enterprise/${enterpriseId}/product?${objectParam}`, body);
  }


  removeProductDistributorOfEnterprise(enterpriseId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.http.request('delete', `/enterprise/${enterpriseId}/product?${objectParam}`, { body });
  }

  toggleProductDistributorStatusOfEnterprise(object) {
    const objectParam = convertObjectToQueryParams(object.params);
    return this.put(`/product-document?ids=${object.ids}&&visible=${object.visible}&${objectParam}`, {});
  }

  appoveProductDistributorOfEnterprise(productId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${productId}/product/approve?${objectParam}`, body);
  }

  unappoveProductDistributorOfEnterprise(productId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${productId}/product/unapprove?${objectParam}`, body);
  }

  hiddenProductDistributorOfEnterprise(productId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${productId}/product/hide?${objectParam}`, body);
  }

  showProductDistributorOfEnterprise(productId, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${productId}/product/visible?${objectParam}`, body);
  }


  getListDocumentsOfEnterprise(enterpriseId, request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/enterprise/${enterpriseId}/document?enterpriseId=${enterpriseId}&${objectPage}&${objectFilter}`);
  }

  createDocumentOfEnterprise(enterpriseId, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.post(`/enterprise/${enterpriseId}/document?${objectParams}`, body);
  }

  removeDocumentOfEnterprise(enterpriseId, request) {
    const objectParams = convertObjectToQueryParams(request.params);
    return this.delete(`/enterprise/${enterpriseId}/document?${objectParams}`);
  }

  toggleDocumentStatusOfEnterprise(enterpriseId, object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.put(`/enterprise/${enterpriseId}/document/visible?ids=${object.ids}&&visible=${object.visible}&&${objectParams}`, {});
  }

  appoveDocumentOfProduct(enterpriseId, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${enterpriseId}/document/approve?${objectParams}`, body);
  }

  unAppoveDocumentOfProduct(enterpriseId, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise/${enterpriseId}/document/unapproved?${objectParams}`, body);
  }

  getListProductOwned(enterpriseId, request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/enterprise/${enterpriseId}/product-owner?${objectPage}&${objectFilter}`);
  }

  downloadFileImportGln() {
        const uri = `${this.baseUrl}/enterprise/import/template`;
        return this.http.get(uri, { responseType: 'blob' });

  }

  importGln(object) {
    const fd = new FormData();
    const payload = new FormData();
    // console.log(object);
    const objectParams = convertObjectToQueryParams(object.params);
    payload.append('file', object.file);
    return this.post(`/enterprise/import?${objectParams}`, payload);
  }


}
