import { createAction, props } from '@ngrx/store';
export const loadGln = createAction(
  '[Gln Page] Load Gln',
  props<{ filter: any }>()
);

export const loadGlnsSuccess = createAction(
  '[Gln Page] Load Glns Success',
  props<{ rows: any, total: number }>()
);

export const loadGlnsFailure = createAction(
  '[Gln Page] Load Glns Failure',
  props<{ error: any }>()
);

export const updateGln = createAction(
  '[Gln Page] Update Gln',
  props<{ glnObject: any }>()
);

export const updateGlnSuccess = createAction(
  '[Gln Page] Update Gln Success',
  props<{ gln: any }>()
);

export const updateGlnFailure = createAction(
  '[Gln Page] Update Gln Failure',
  props<{ error: any }>()
);

export const addGln = createAction(
  '[Gln Page] Add Gln',
  props<{ glnObject: any }>()
);

export const addGlnSuccess = createAction(
  '[Gln Page] Add Gln Success',
  props<{ gln: any }>()
);

export const addGlnFailure = createAction(
  '[Gln Page] Add Gln Failure',
  props<{ error: any }>()
);

export const updateListGln = createAction(
  '[Gln Page] Update List Gln',
  props<{ glns: any, gln: any }>()
);

export const updateListGlnSuccess = createAction(
  '[Gln Page] Update List Gln Success',
  props<{ gln: any }>()
);

export const updateListGlnFailure = createAction(
  '[Gln Page] Update List Gln Failure',
  props<{ error: any }>()
);

export const searchGln = createAction(
  '[Gln Page] Search Gln',
  props<{ objectSearch: any }>()
);

export const searchGlnSuccess = createAction(
  '[Gln Page] Search Glns Success',
  props<{ rows: any, total: number }>()
);

export const searchGlnFailure = createAction(
  '[Gln Page] Search Glns Failure',
  props<{ error: any }>()
);

export const deleteGln = createAction(
  '[Gln Page] Delete Gln',
  props<{ id: number }>()
);

export const deleteGlnSuccess = createAction(
  '[Gln Page] Delete Glns Success',
  props<{ id: number }>()
);

export const deleteGlnFailure = createAction(
  '[Gln Page] Delete Glns Failure',
  props<{ error: any }>()
);

export const loadNation = createAction(
  '[Gln Page] Load Nation',
  props<{ request: any }>()
);

export const loadNationSuccess = createAction(
  '[Gln Page] Load Nation Success',
  props<{ rows: any }>()
);

export const loadNationFailure = createAction(
  '[Gln Page] Load Nation Failure',
  props<{ error: any }>()
);


export const loadCity = createAction(
  '[Gln Page] Load City',
  props<{ request: any }>()
);

export const loadCitySuccess = createAction(
  '[Gln Page] Load City Success',
  props<{ rows: any }>()
);

export const loadCityFailure = createAction(
  '[Gln Page] Load City Failure',
  props<{ error: any }>()
);

export const loadDistrict = createAction(
  '[Gln Page] Load District',
  props<{ request: any }>()
);

export const loadDistrictSuccess = createAction(
  '[Gln Page] Load District Success',
  props<{ rows: any }>()
);

export const loadDistrictFailure = createAction(
  '[Gln Page] Load District Failure',
  props<{ error: any }>()
);

export const loadWard = createAction(
  '[Gln Page] Load Warn',
  props<{ request: any }>()
);

export const loadWardSuccess = createAction(
  '[Gln Page] Load Warn Success',
  props<{ rows: any }>()
);

export const loadWardFailure = createAction(
  '[Gln Page] Load District Failure',
  props<{ error: any }>()
);
