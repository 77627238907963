import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private baseUrl = environment.baseBuildImageUrl;
  private urlPdf = environment.baseBuildPdfUrl;

  constructor(public http: HttpClient) { }
  header = new HttpHeaders({
    Authorization: 'bearer 0f0b98b5-cd84-4989-b229-5253513dc36b.9zR6EZzq',
    'Content-Type': 'application/json'
  });
  upload(body) {
    return this.http.post<any>(`${this.baseUrl}/raw`, body, {headers: this.header});
  }
  uploadUrl(body) {
    return this.http.post<any>(`${this.baseUrl}/url`, body, {headers: this.header});
  }

  uploadPdf(body): Observable<any> {
    return this.http.post(`${this.urlPdf}`, body);
  }

  public createFlash(body): Observable<any> {
    return this.http.post(`${this.baseUrl}/raw`, body);
  }


  uploadFile(body): Observable<any> {
    return this.http.post(`${environment.uploadStream}`, body);
  }

}
