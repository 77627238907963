import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MdcDialogRef } from '@angular-mdc/web';

@Component({
  selector: 'ic-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss']
})
export class AlertConfirmComponent implements OnInit {

  data: any
  constructor(public dialogRef: MdcDialogRef<AlertConfirmComponent>) {
  }
  ngOnInit(): void {

  }

}
