<div>
    <form [formGroup]="alertProductForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
          <div class="form-group">
            <div class="label-form">Chọn nội dung cảnh báo (*)</div>
            <mat-form-field appearance="outline" class="form-field">
                <mat-select formControlName="alertId" msInfiniteScroll (infiniteScroll)="lazyLoadAlerts()" placeholder="Chọn nội dung cảnh báo" #matSelect>
                    <mat-form-field appearance="outline" class="form-field-search">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput
                            type="text"
                            name="filter-options"
                            id="filter-options"
                            placeholder="Search"
                            (keyup)= "changeInputSearch($event)"
                            (keydown)="$event.stopPropagation()"
                            formControlName="inputSearch">
                            <mat-icon matSurfix *ngIf="this.alertProductForm.value.inputSearch" (click) = "clearInputSearch()">clear</mat-icon>
                      </mat-form-field>
                    <mat-option *ngFor="let alert of listAlert" [value]="alert.id">
                        {{alert.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
              <div class="label-form">Thêm giấy tờ (*)</div>
              <ic-file-list  [files]="documents" (addFile)="openUploadDialog('logo')"
                         (removeFile)="onRemoveFile('logo', $event)"></ic-file-list>
                         <div>
                          <div *ngFor="let item of files; index as i" class="div-file">
                            <span class="file-label" [title]="item" (click)="openNewTab(item.url)">{{item.name}}</span>
                            <mat-icon class="icon-delete" (click) = "onRemoveFile('logo', i)">clear</mat-icon>
                          </div>
                        </div>
          </div>
          
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'create'" [disabled]="!alertProductForm.valid">Thực hiện</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'update'" [disabled]="!alertProductForm.valid">Cập nhật</button>
        </mat-toolbar>
    </form>
</div>
