import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { ProductAttributeListPageActions } from '@attribute-set/actions';
import { UploadComponent } from '@src/app/shared/upload/upload.component';
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CategoryListPageActions } from '@category/actions';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { AuthService } from '@src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '@products/services/product.services';
import { MatSelect } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import * as fromCategory from '@category/reducers';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import { GlnService } from '@gln/services/gln.service';
import { takeUntil } from 'rxjs/operators';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-import-gln',
  templateUrl: './import-gln.component.html',
  styleUrls: ['./import-gln.component.scss']
})
export class ImportGlnComponent implements OnInit {

  data: any;
  importForm: FormGroup;
  file = [];
  importName = '';
  isExpand = false;
  categories$: Observable<any>;
  attributeSet$: Observable<any>;
  searchChangeObserver;
  isLoading = false;
  inputSearch = '';
  listAttributeSet: any;
  currentUser: any;
  destroy$ = new Subject();

  @ViewChild('inputUpload', { read: ElementRef, static: false }) inputUpload: ElementRef;

  @ViewChild(MatSelect) child: MatSelect;

  constructor(public dialogRef: MatDialogRef<ImportGlnComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data,
              private fb: FormBuilder,
              private storeCategory: Store<fromCategory.State>,
              private storeAttributeSet: Store<fromProductAttribute.State>,
              private productService: ProductService,
              private _snackBar: MatSnackBar,
              private authService: AuthService,
              private glnService: GlnService) {
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListCategory));
    this.attributeSet$ = storeAttributeSet.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
    this.data = data;
  }

  ngOnInit(): void {
    enterScreenView(TekoValueDto.ENTERPRISE_IMPORT.SCREEN_NAME, TekoValueDto.ENTERPRISE_IMPORT.CONTENT_TYPE);

    this.importForm = this.fb.group({
      fileImport: this.fb.control(null, [Validators.required]),
      replace : this.fb.control(false),
      replace_business : this.fb.control(false),
    });

    this.getListCategory();

    this.getListAttributeSet();

    this.attributeSet$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listAttributeSet = item;
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // console.log(JSON.parse(localStorage.getItem('currentUser')));


  }

  ngAfterViewInit() {
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listAttributeSet.length) {
          this.storeAttributeSet.dispatch(
            ProductAttributeListPageActions.loadProductAttributes({
              filter: {},
              page: 1,
              pageSize: 50,
            })
          );
          this.importForm.patchValue({inputSearch: ''});
        }
      }
    });
  }

  changeSelected(event) {

  }


  clearInputSearch() {
    this.importForm.patchValue({inputSearch: ''});

    this.getListAttributeSet();
  }

  getListAttributeSet() {
    this.storeAttributeSet.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: {},
        page: 1,
        pageSize: 50,
      })
    );
  }

  getListCategory() {
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

  expandDownload() {
    this.isExpand = true;
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(resul/t);

      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        const data = result.data;
        // console.log(data);

        data.map(res => {
          this.file = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          this.file.push(value);
          this.importForm.controls.fileImport.setValue(res.url);
        });
      }
    });
  }
  onRemoveFile(field: string, index: number) {
    this.importForm.controls.fileImport.setValue('');
    this.file = [];
  }
  onSelectionChange(event, item) {

  }

  onSubmitForm() {
    if (this.isLoading) { return; }
    // console.log(this.importForm.value);
    const object = {
      file: this.importForm.value.fileImport,
      params: {
        replace: this.importForm.value.replace ? 1 : 2,
        replace_business : this.importForm.value.replace_business ? 1 : 2,
      }
    };

    this.glnService.importGln(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
        (res: any) => {
          this.isLoading = false;
          this.isLoading = true;
          const success = res.data.totalSuccess;
          const fail = res.data.totalFail;
          if (success) {
            const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
            alertEvent(alert);
          }
          const click =  new ClickTekoDto(TekoValueDto.ENTERPRISE_IMPORT.REGION_NAME,
            TekoValueDto.ENTERPRISE_IMPORT.CONTENT_NAME,
            TekoValueDto.ENTERPRISE_IMPORT.TARGET, {
            totalEnterprise: +success + +fail,
            userId: this.currentUser.userId
          });
          clickTeko(click);
          this._snackBar.open(`Import thành công ${res.data.totalSuccess} doanh nghiệp và ${res.data.totalFail} thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close();
        },
      (err: any) => {
        console.log('errrrrrr');
        this.isLoading = false;
        this._snackBar.open(`${err}`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        // console.log(err);
      }
    );
  }

  changeInputAttributeSetSearch(event) {
    this.storeAttributeSet.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: { name: event.target.value },
        page: 1,
        pageSize: 50,
      })
    );
    // if (!this.searchChangeObserver) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserver = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       console.log(it)

    //     });
    // }
    // this.searchChangeObserver.next(event.target.value);


  }

  downloadTemplate() {
    // const linkDownload = 'http://ucontent.icheck.vn/file_many_type/20200819/import_enterprise_form.xlsx';
    // const link = document.createElement('a');
    // link.href = linkDownload;
    // link.download = 'template_' + new Date().getTime();
    // // this is necessary as link.click() does not work on the latest firefox
    // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    // setTimeout(function () {
    //     // For Firefox it is necessary to delay revoking the ObjectURL
    //     window.URL.revokeObjectURL(linkDownload);
    //     link.remove();
    // }, 100);

    // console.log(this.importForm.value.attributeSetId);
    // this.productService.downloadTemplate(this.importForm.value.attributeSetId);
    this.glnService.downloadFileImportGln()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe(x => {
          this.isLoading = false;
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
          const newBlob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'template_' + new Date().getTime();
            // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);

            // this.dialogRef.close();
        });
  }

  importFile(data: any) {
    // console.log(data);

    if (data) {
      this.importName = data.name;
      this.importForm.controls.fileImport.setValue(data);
    }
  }

  deleteFileImport() {
    this.importName = '';
    this.importForm.controls.fileImport.setValue(null);
    // console.log(this.inputUpload.nativeElement);

    this.inputUpload.nativeElement.value = '';
  }

  clearSelected(key) {
    this.importForm.patchValue({
      [key]: null
    });
    this.importForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
