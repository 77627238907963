import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { convertObjectToQueryParams } from 'src/app/shared/common';
import { environment } from '@src/environments/environment';
import {catchError} from "rxjs/operators";
import {ErrorLogDto} from "@src/app/shared/common/error-log.dto";
import {BaseService} from '@src/app/services/base.service';
declare  function errorTeko(info)

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};

const httpOptionsForm = {
  headers: new HttpHeaders({
    Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    // 'Content-Type' : 'multipart/form-data'
  })
};


@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService{
  listColumnSubject = new Subject<any>();
  subjectGetBarcode : BehaviorSubject<any>
  constructor(public http: HttpClient) {
    super(http);
  }

  public get currentBarcode(): any {
    return this.subjectGetBarcode?.value;
  }

  getListProduct(params) {
    // console.log(params);
    const objectPage = convertObjectToQueryParams(params);
    const objectFilter = convertObjectToQueryParams(params.filter);
    return this.get(`/product?${objectPage}&${objectFilter}`);
  }

  createProduct(product) {
    const objectParams = convertObjectToQueryParams(product.params);
    return this.post(`/product?${objectParams}`, product);
  }

  updateProduct(product) {
    const objectParams = convertObjectToQueryParams(product.params);
    return this.put(`/product/${product.id}?${objectParams}`, product);
  }

  getDetailProduct(id) {
    return this.get(`/product/${id}`);
  }

  addAlertForProduct(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.http.post(`/alert-product?${objectParams}`, object);
  }

  assignAlertForProduct(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.post(`/product/assign-alert?${objectParams}`, object)
  }

  assignEnterprise(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.post(`/product/assign-enterprise?${objectParams}`, object)


  }

  assignCategory(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.post(`/product/assign-category?${objectParams}`, object)
  }

  updateStatusProduct(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.put(`/product/scan-status?${objectParams}`, object)
  }

  searchBarcode(bar_code) {
    return this.get(`/product/search-barcode?barcode=${bar_code}&page=1&pageSize=50`);
  }


  downloadTemplate(id) {
    // const options = { responseType: 'blob' }; there is no use of this
        const uri = `/product/template-import?attribute_set_id=${id}`;
        // this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
        return this.get(uri, { responseType: 'blob' });
    // window.location.href=`/product/template-import?attribute_set_id=${id}`;
    // return this.http.get(`/product/template-import?attribute_set_id=${id}`);
  }

  importProduct(object , checkUpdate?: string) {
    const fd = new FormData();
    const payload = new FormData();
    // console.log(object);
    const objectParams = convertObjectToQueryParams(object.params);
    if (object.attributeSetId) {
      payload.append('attribute_set_id', object.attributeSetId);
    }
    payload.append('file', object.file);
    // return this.http.post(`/product/import-v2?${objectParams}`, payload);
    return checkUpdate ? this.post(`/product/import-update?${objectParams}`, payload) :
      this.post(`/product/import-v2?${objectParams}`, payload);
  }

  exportProduct(object) {
    const objectFilter = convertObjectToQueryParams(object.filter);
    const uri = `/product/export?fields=${object.fields}&ids=${object.ids}&${objectFilter}`;
    // @ts-ignore
    return this.post(uri, {}, { responseType: 'blob', headers: new HttpHeaders({
          Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
          'Content-Type' : 'application/json'
        }), params : object.queryParams }).pipe(catchError(err => {
      const error = new ErrorLogDto(err, `/product/import-v2`, object, err );
      errorTeko(error);
      return throwError(err);
    }));
  }

  removeInfoField(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.put(`/product/remove-attribute?${objectParams}`, object);
  }

  getListDistributorOfProduct(product_id, request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/product/${product_id}/distributor?${objectPage}&${objectFilter}`);
  }

  createDistributorOfProduct(product_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.post(`/product/${product_id}/distributor?${objectParams}`, body);
  }

  updateDistributorOfProduct(product_id, body) {
    const objectParam = convertObjectToQueryParams(body.params);
    return this.put(`/product/${product_id}/distributor?${objectParam}`, body)
  }

  updateProuductOfEnterprise(product_id, body) {
    return this.http.put(`/product/${product_id}/distributor`, body);
  }

  getDetailTitleOfRole(request) {
    const objectPage = convertObjectToQueryParams(request);
    return this.get(`/enterprise-product-title?${objectPage}`);
  }

  createNewDocument(body) {
    return this.post(`/documents`, body);
  }

  getListDocumentsOfProduct(product_id, request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/product/${product_id}/document?productId=${product_id}&${objectPage}&${objectFilter}`);
  }

  createDocumentOfProduct(body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.post(`/product/${body.productId}/document?${objectParams}`, body)
  }

  removeDocumentOfProduct(productId, ids, request?) {
    if (request?.params) {
      const objectParams = convertObjectToQueryParams(request.params);
      return this.delete(`/product/${productId}/document?ids=${ids}&${objectParams}`);
    }
    return this.delete(`/product/${productId}/document?ids=${ids}`);
  }

  toggleDocumentStatusOfProduct(productId, object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.put(`/product/${productId}/document/visible?ids=${object.ids}&&visible=${object.visible}&${objectParams}`,
      {});
  }

  appoveDocumentOfProduct(productId, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${productId}/document/approve?${objectParams}`, body);
  }

  unAppoveDocumentOfProduct(productId, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${productId}/document/unapproved?${objectParams}`, body);
  }


  appoveDistributorOfProduct(product_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${product_id}/distributor/approve?${objectParams}`, body);
  }

  unappoveDistributorOfProduct(product_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${product_id}/distributor/unapprove?${objectParams}`, body);
  }

  hiddenDistributorOfProduct(product_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${product_id}/distributor/hide?${objectParams}`, body);
  }

  showDistributorOfProduct(product_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${product_id}/distributor/visible?${objectParams}`, body);
  }

  removeDistributorOfProduct(product_id, body) {
    // console.log(body);
    const objectParams = convertObjectToQueryParams(body.params);
    return this.http.request('delete', `${this.baseUrl}/product/${product_id}/distributor?${objectParams}`, { body });
  }

  getListDocument(request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/documents?${objectPage}&${objectFilter}`);
  }

  getListDocumentDistributor(request) {
    const objectPage = convertObjectToQueryParams(request);
    const objectFilter = convertObjectToQueryParams(request.filter);
    return this.get(`/enterprise-product-document?${objectPage}&${objectFilter}`);
  }

  createDocumentDistributor(body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.post(`/enterprise-product-document?${objectParams}`, body);
  }

  removeDocumentDistributor(ids, body?) {
    // const objectParams = convertObjectToQueryParams(body.params);
    // return this.http.delete(`/enterprise-product-document?ids=${ids}&${objectParams}`);
    if (body?.params) {
      const objectParams = convertObjectToQueryParams(body.params);
      return this.delete(`/enterprise-product-document?ids=${ids}&${objectParams}`);
    }
    return this.delete(`/enterprise-product-document?ids=${ids}`);
  }

  toggleDocumentDistributor(object) {
    const objectParams = convertObjectToQueryParams(object.params);
    return this.put(`/enterprise-product-document?ids=${object.ids}&&visible=${object.visible}&${objectParams}`, {})
  }

  appoveDocumentDistributor(body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise-product-document/approve?${objectParams}`, body)
  }

  unAppoveDocumentDistributor(body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/enterprise-product-document/unapproved?${objectParams}`, body)
  }

  updateDocument(document_id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/documents/${document_id}?${objectParams}`, body);
  }

  searchEnterprise(params) {
    const objectPage = convertObjectToQueryParams(params);
    const objectFilter = convertObjectToQueryParams(params.filter);
    return this.get(`/enterprise/search?${objectPage}&${objectFilter}`);
  }

  updateProductImage(id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${id}/updateImage?${objectParams}`, body);
  }

  updateProductName(id, body) {
    const objectParams = convertObjectToQueryParams(body.params);
    return this.put(`/product/${id}/updateName?${objectParams}`, body)
  }

  checkBarCode(bar_code) {
    return this.get(`/product/checkbarcode?barcode=${bar_code}`);
  }

  getListEcomerce(query?: any): Observable<any> {
    return this.get(`/ecommerce`);
  }


}
