import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdcDrawerModule } from '@angular-mdc/web/drawer';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcIconButtonModule } from '@angular-mdc/web/icon-button';
import { MdcListModule } from '@angular-mdc/web/list';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcTopAppBarModule } from '@angular-mdc/web/top-app-bar';
import { TopAppBarComponent, TopAppBarUser } from './components/top-app-bar/top-app-bar.component';
import { ContentComponent } from './components/content/content.component';
import { AppComponent } from './components/app/app.component';
import { SidebarPanelComponent } from './components/sidebar-panel/sidebar-panel.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';


@NgModule({
  declarations: [TopAppBarComponent, TopAppBarUser, ContentComponent, AppComponent, SidebarPanelComponent, NotificationPanelComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MdcDrawerModule,
    MdcIconModule,
    MdcIconButtonModule,
    MdcListModule,
    MdcMenuModule,
    MdcTopAppBarModule,
    RouterModule
  ],
  exports: [
    MdcTopAppBarModule,
    AppComponent,
    TopAppBarComponent,
    ContentComponent,
    SidebarPanelComponent,
    NotificationPanelComponent
  ]
})
export class CoreModule { }
