import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Host, SkipSelf, Optional, NgZone, ChangeDetectorRef, ElementRef } from '@angular/core';
import { MdcCheckbox, MDC_CHECKBOX_CONTROL_VALUE_ACCESSOR } from '@angular-mdc/web/checkbox';
import { MdcRipple } from '@angular-mdc/web/ripple';
import { Platform } from '@angular/cdk/platform';
import { MdcFormField } from '@angular-mdc/web/form-field';
import { DashCellDirective } from '../table/cell.directive';
import { DashHeaderCellDirective } from '../table/header-cell.directive';
import { MDCDataTableHeaderCell, MDCDataTableCell } from '@angular-mdc/web/data-table';

@Component({
  selector: 'dash-checkbox',
  exportAs: 'dashCheckbox',
  template: `
  <input type="checkbox"
    #input
    class="mdc-checkbox__native-control"
    [id]="inputId"
    [attr.name]="name"
    [tabIndex]="tabIndex"
    [attr.aria-label]="ariaLabel || null"
    [attr.aria-labelledby]="ariaLabelledby"
    [disabled]="disabled"
    [checked]="checked"
    [attr.value]="value"
    [indeterminate]="indeterminate"
    (change)="_onInteraction($event)"
    (click)="_onInputClick($event)"/>
  <div class="mdc-checkbox__background">
    <svg
      class="mdc-checkbox__checkmark"
      viewBox="0 0 24 24"
      focusable="false">
      <path class="mdc-checkbox__checkmark-path"
        fill="none"
        d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
    </svg>
    <div class="mdc-checkbox__mixedmark"></div>
  </div>
  <div *ngIf="!disableRipple && !disabled" class="mdc-checkbox__ripple"></div>
  `,
  host: {
    '[class.mdc-data-table__header-row-checkbox]': '!!_parentTableHeaderCell',
    '[class.mdc-data-table__row-checkbox]': '!!_parentTableCell'
  },
  providers: [
    MDC_CHECKBOX_CONTROL_VALUE_ACCESSOR,
    MdcRipple,
    { provide: MdcCheckbox, useExisting: DashCheckboxComponent }
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashCheckboxComponent extends MdcCheckbox {
  constructor(
    _platform: Platform,
    _ngZone: NgZone,
    _changeDetectorRef: ChangeDetectorRef,
    public elementRef: ElementRef<HTMLElement>,
    public ripple: MdcRipple,
    @Optional() _parentFormField: MdcFormField,
    @Optional() private _parentTableHeaderCell: MDCDataTableHeaderCell,
    @Optional() private _parentTableCell: MDCDataTableCell) {
    super(_platform, _ngZone, _changeDetectorRef, elementRef, ripple, _parentFormField);
  }
}
