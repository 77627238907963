import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  Inject
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashFilter, FilterBarComponent } from '@dash/filter-bar/filter-bar.component';
import { Observable } from 'rxjs/internal/Observable';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromAttributeProduct from '../../reducers';
import {
  AttributeProductActions, AttributeListPageActions, AttributePageActions
} from '../../actions';
import { SelectionModel } from '@angular/cdk/collections';
import { AttributeProductModalComponent } from '../attribute-product-modal/attribute-product-modal.component';
import { AlertConfirmComponent } from '../alert-confirm/alert-confirm.component';
import { MdcDialog, MdcDialogConfig, MDCDataTable, MDCDataTableRowSelectionChangedEvent } from '@angular-mdc/web';
import { Subscription, Subject, merge } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { AttributeProductService } from '@attribute/services/attribute-product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@src/app/shared/common';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-attribute-product-list',
  templateUrl: './attribute-product-list.component.html',
  styleUrls: ['./attribute-product-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterAttribute();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class AttributeProductListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  pageSize: number;

  @Input()
  total: number;

  @Input()
  set rows(rows: any) {
    this.selection.clear();
    this._rows = rows;
  }
  get rows(): any{
    return this._rows;
  }
  private _rows: any = [];

  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }

  get displayedColumns(): string[] {
    const availableColumns = this.availableColumns.map(c => c.key);
    const columns = this._displayedColumns.map(column => {
      return availableColumns.indexOf(column) > -1 ? column : null;
    }).filter(filter => (filter));

    return columns;
  }

  _displayedColumns: string[];

  @Output()
  deleteItems = new EventEmitter<any[]>();

  @Output()
  load = new EventEmitter<any>();
  availableColumns = [
    { key: 'select', label: 'Select', order: 1 },
    { key: 'id', label: 'ID', order: 2 },
    { key: 'name', label: 'Name', order: 3 },
    { key: 'image', label: 'Image', order: 4 },
    { key: 'order_sort', label: 'Order Sort', order: 5 },
    { key: 'rating_attribute', label: 'Rating Attribute', order: 6 },
    { key: 'product_attribute', label: 'Product Attribute', order: 7 },
    { key: 'action', label: 'Action', order: 8 },
  ];

  get sortedAvailableColumns(): string[] {
    return this.availableColumns.sort((a, b) => a.order - b.order).map((c: any) => c.key);
  }

  changesSubscription: Subscription;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<any>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  attribute$: Observable<any[]>;
  destroy$ = new Subject();
  menuHeader: any = [];
  filter: any;


  @ViewChild(FilterBarNewComponent, {static: true}) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MDCDataTable, {static: true}) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private store: Store<fromAttributeProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private attributeService: AttributeProductService,
    @Inject('availableFilters') public availableFilters: [],
    private _snackBar: MatSnackBar) {
      this.page$ = store.pipe(select(fromAttributeProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromAttributeProduct.selectPageSize));
      this.filter$ = store.pipe(select(fromAttributeProduct.selectAttributeListPageFilter));
    }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    // console.log(this.filterBar);
    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.page = it;
    });
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.pageSize = it
    });
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.filterBar.patchValueForm(item);
      this.filter = item
    })
    const filterChange = this.filterBar?.filterChange;
    const pageChange = merge(
      this.paginator.page,
      this.paginator.initialized
    );
    filterChange
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const object = {
          ...this.filterBar.filterForm.value,
          create_to_date: this.filterBar.filterForm.value.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
          create_from_date: this.filterBar.filterForm.value.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
        }
        console.log(object);

        this.load.emit([
          this.paginator,
          object
        ]);
      });
      this._changeDetectorRef.detectChanges();
  }

  getAttributes(attribute) {
    // console.log(attribute);
    if (attribute) {
      this.menuHeader.push({ label: attribute.name, id: attribute.id });
      // console.log('hasMultiMenuLabel');
      this.store.dispatch(AttributeListPageActions.loadAttributes({filter: {}, page: 1,  pageSize: 10 }));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // this.selectionChangedEvent = event;
    // this.desserts[event.index].checked = event.selected;
    // console.log(event);
    // this.selection.toggle(dessert);
  }

  onSelectedAll(): void {
    // this.desserts.forEach(_ => _.checked = true);
  }

  onUnselectedAll(): void {
    // this.desserts.forEach(_ => _.checked = false);
  }

  desserts = [
    {checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty'},
    {checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more'},
    {checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor'}
  ];


  loadAttribute(page, pageSize, filter?) {
    // console.log(this.limit, this.offset);
    this.store.dispatch(
      AttributeListPageActions.loadAttributes({
        filter: filter || {},
        page: page,
        pageSize: pageSize,
      })
    );
  }

  createAttribute() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    // dialogConfig.height = '34rem';
    dialogConfig.data = {
      label: 'Tạo thuộc tính sản phẩm',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AttributeProductModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);
        this.filterBar.resetForm();
        this.loadAttribute(1, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  updateAttribute(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    // dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Sửa thuộc tính đánh giá sản phẩm',
      action: 'update',
      element
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AttributeProductModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== close) {
        console.log(this.filter);

        this.loadAttribute(this.page, this.pageSize, this.filter);
      }
      console.log('The dialog was closed');
    });
  }

  deleteAttributeProduct(id) {
    // console.log('iddddd', id);
    const dialogRef = this.dialog2.open(AlertConfirmComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // this.store.dispatch(AttributeProductActions.deleteAttributeProduct({id}));
        this.attributeService.deleteAttribute(id).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Xóa tính sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadAttribute(this.page, this.pageSize, this.filter);
          }, 500);
        }, error => {
          this._snackBar.open(error, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })

      }
      console.log('The dialog was closed');
    });
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const object = {
        offset: event.pageIndex * event.pageSize,
        limit: event.pageSize,
    }
    // this.store.dispatch(AttributeActions.loadAttribute({filter: object}));
  }

  filterData(event) {
    // console.log(event)
    let objectSearch = {};
    event.forEach(item => {
        objectSearch[item.key] = item.value.value;
    });
    const object = {
      offset: 0,
      limit: 5,
      ...objectSearch,
    }
    // console.log(object);
    this.store.dispatch(AttributeProductActions.searchAttributeProduct({objectSearch: object}));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.rows.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}
