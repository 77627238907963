<mat-toolbar class="header-dialog">
    <label class="header-text">{{data.label}}</label>
</mat-toolbar>
<form [formGroup]="attributeSetForm" (ngSubmit)="onFormSubmit()">
<div style="padding: 1em;" class="form-group">
    <div class="label-form">Tên bộ thuộc tính (*)</div>
    <mat-form-field style="width: 100%;" appearance="outline">
        <input matInput from formControlName="name" maxLength="255">
    </mat-form-field>
</div>

<div class="label">
    <label style="padding-left: 2em;">Cài đặt các nhóm thuộc tính (*)</label>
    <label style="padding-left: 2em;">Thuộc tính chưa được gắn cho nhóm</label> <br>
</div>

<div class="content content-modal" cdkDropListGroup>
    <div class="config">
        <div *ngFor="let item of groupListAtttribute; let i = index">
          <div  class="label-group" *ngIf="groupListAtttribute.length">
            <span>
              <mat-icon *ngIf="arrIndexExpand.indexOf(i) === -1" (click) = "expandGroup(i)">arrow_right</mat-icon>
              <mat-icon *ngIf="arrIndexExpand.indexOf(i) !== -1" (click) = "collapseGroup(i)">arrow_drop_down</mat-icon>
            </span>
            <div class="label-attribute-set" (mouseover)="activeEdit(i)"
            (mouseout)="inActiveEdit(i)">
              <span class="title-attribute-set" contenteditable="enableEdit">{{item.groupName}}</span>
              <mat-icon class="icon-edit-group"  [ngClass]="{ active: arrIndexEnableEdit.indexOf(i) !== -1 }" (click)="editting(i)">edit</mat-icon>
              <mat-icon class="icon-delete-group" [ngClass]="{ active: arrIndexEnableEdit.indexOf(i) !== -1 }" (click)="removeGroup(i)">delete</mat-icon>
              <form class="edit-form" (keyup)="submitEdit($event, i)" *ngIf="arrIndexEditting.indexOf(i) !== -1" >
                <input type="text" name="editGroup" [value]="item.groupName" (blur)="blurInput()"/>
              </form>
            </div>
          </div>
          
          <div
            cdkDropList
            [cdkDropListData]="item.attributeValues"
            (cdkDropListDropped)="drop($event, i, 'left')"
            class="example-list left-chirldren"
            *ngIf="arrIndexExpand.indexOf(i) !== -1"
            >
              <div *ngFor="let it of item.attributeValues" cdkDrag  class="example-box">
                {{it.attributeLabel}}
            </div>
          </div>
          
        </div>
        <div class="list-attribute">
          <div class="form-group" *ngIf="isAddGroupAttribute">
            <mat-form-field appearance="outline" class="form-field" style="width: 100%; height: 60px;" (keydown)="addGroup($event)" type="button">
              <input matInput placeholder="Nhâp tên nhóm thuộc tính" (blur)="isAddGroupAttribute = false">
            </mat-form-field>
          </div>
          <a class="add-group-attribute" type="button" (click)="addGroupAttribute()">
            <mat-icon class="icon_add">add_cirle</mat-icon> Thêm mới nhóm thuộc tính
          </a>
        </div>
    </div>
    <div class="attribute">
        <div class="form-group">
            <mat-form-field style="width: 100%; height: 60px;" appearance="outline">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="Tìm kiếm tên thuộc tính" (input)= "changeInputSearch($event)">
            </mat-form-field>
        </div>

        <div style="margin-top: 4px">
            <label style="margin-right: 20px;">{{ countlistAttributeIgnore$ | async}} thuộc tính</label>
        </div>
        <div
          cdkDropList
          [cdkDropListData]="listAttributeIgnore"
          (cdkDropListDropped)="drop($event, listAttributeIgnore, 'right')"
          class="example-list">
          <div *ngFor="let attribute of listAttributeIgnore" cdkDrag  class="example-box">
            {{attribute.attributeLabel}}
          </div>
        </div>
        
    </div>
</div>
<mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
    <button mat-button type="button" (click)="closeDialog()">Hủy</button>
    <button mat-raised-button type="submit" class="btn-create primary">Cập nhật</button>
    <!-- <button mat-raised-button type="submit" class="btn-create" *ngIf="data.action === 'update'">Sửa</button> -->
</mat-toolbar>
</form>