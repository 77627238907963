<dash-title [title]="title"></dash-title>
<div class="content-create-product">
  <mat-grid-list cols="2" class="grid-list">
    <form [formGroup]="productForm" (ngSubmit)="onFormSubmit()">
      <div style="height: 60rem;">
      <mat-grid-tile style="display: block;" class="mat-grid-tile">
        <div class="form-group">
          <div class="label-form">Tên sản phẩm (*)</div>
          <mat-form-field appearance="outline" class="form-field">
            <input matInput placeholder="Nhập tên sản phẩm" formControlName="name">
          </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Mã sản phẩm (*)</div>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập mã sản phẩm" formControlName="bar_code">
            </mat-form-field>
            <mat-error *ngIf="productForm.controls.bar_code.value && !productForm.controls.bar_code.valid">
              Barcode sai định dạng
            </mat-error>
            <div class="checkbox">
              <mat-checkbox formControlName="checkCode">Không check định dạng mã</mat-checkbox>
            </div>

        </div>

        <div class="form-group">
            <div class="label-form">Giá sản phẩm</div>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Nhập giá sản phẩm" formControlName="price">
            </mat-form-field>
          </div>
        <div class="form-group">
          <div class="label-form">Trạng thái quét (*)</div>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Trạng thái quét</mat-label>
            <mat-select formControlName="scan_status">
              <mat-option value="accept_scan">Cho quét</mat-option>
              <mat-option value="not_accept_scan">Không cho quét</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Chọn danh mục</div>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Chọn danh mục</mat-label>
                <mat-select formControlName="category_id" (closed)="onPanelCloseCategory()">

                  <mat-form-field appearance="outline" class="form-field-search">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput
                        [formControl]="filterControlCategory"
                        type="text"
                        name="filter-options"
                        id="filter-options"
                        placeholder="Search"
                        (input)= "changeInputSearch($event)">
                  </mat-form-field>

                  <mat-option *ngFor="let category of categories$ | async" [value]="category.id">
                    {{category.category_level_label}} {{category.name}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Cảnh báo</div>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Chọn cảnh báo</mat-label>
              <mat-select formControlName="alert_id">
                <mat-option *ngFor="let alert of alert$ | async" [value]="alert.id">
                  {{alert.title}}
                </mat-option>
              </mat-select>

            </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Doanh nghiệp sở hữu (*)</div>
            <mat-form-field appearance="outline" class="form-field">
              <mat-label>Nhập tên hoặc mã GLN để tìm kiếm</mat-label>
              <!-- <mat-select formControlName="enterprise_list">
                <mat-option [value]="gln.id" *ngFor="let gln of glns$ | async">{{gln.name}}</mat-option>
              </mat-select> -->
              <mat-select formControlName="enterprise_id" (closed)="onPanelCloseEnterprise()" msInfiniteScroll (infiniteScroll)="getListCategory()" [complete]="offset === 10
              ">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput
                        [formControl]="filterControlEnterprise"
                        type="text"
                        name="filter-options"
                        id="filter-options"
                        placeholder="Search">
                  </mat-form-field>

                  <mat-option *ngFor="let enterprise of filteredEnterprises | async" [value]="enterprise.id">
                    {{enterprise.name}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="checkbox"><mat-checkbox formControlName="notCheckPrefix">Không check Prefix</mat-checkbox></div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile class="mat-grid-tile">
        <div class="form-group">
            <div class="label-form">Ảnh sản phẩm</div>
            <mat-form-field appearance="outline" class="form-field">
              <input matInput placeholder="Thêm link ảnh" formControlName="product_image_list">
            </mat-form-field>
            <!-- <button mat-button class="primary" type="button">Thêm ảnh</button> -->
        </div>
        <div class="form-group">
            <div class="label-form">Video sản phẩm</div>
            <button mat-button class="primary" type="button" (click)="addLinkVideo()">Thêm link</button>
        </div>
        <div class="form-group" *ngFor="let item of numberOfLinkVideo">
          <mat-form-field appearance="outline" class="form-field">
            <input matInput formControlName="video_list" placeholder="Thêm link video">
          </mat-form-field>
          <mat-icon (click)="deleteLinkVideo()">delete</mat-icon>
        </div>
        <div class="form-group">
          <div class="label-form">Thông tin sản phẩm</div>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Thông tin sản phẩm</mat-label>
            <textarea matInput formControlName="product_info"></textarea>
          </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Thông tin doanh nghiệp sở hữu</div>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Thông tin doanh nghiệp sở hữu</mat-label>
            <textarea matInput formControlName="enterprise_info"></textarea>
          </mat-form-field>
        </div>
        <div class="form-group">
          <div class="label-form">Thông tin phân biệt thật giả</div>
          <mat-form-field appearance="outline" class="form-field">
            <mat-label>Thông tin phân biệt thật giả</mat-label>
            <textarea matInput formControlName="certification_content"></textarea>
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </div>
    <div class="list-button">
      <button mat-raised-button class="primary" type="submit" *ngIf="!this.isUpdate; else updateButton" [disabled]="!productForm.valid">Thêm mới</button>
      <ng-template #updateButton><button mat-raised-button class="primary" type="submit" [disabled]="!productForm.valid">Cập nhật</button></ng-template>
      <button mat-raised-button type="button" (click) = "goToListProduct()">Hủy bỏ</button>
    </div>
  </form>
  </mat-grid-list>

</div>

