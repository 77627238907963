import { ProductService } from './../../services/product.services';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ic-product-toggle-row',
  templateUrl: './product-toggle-row.component.html',
  styleUrls: ['./product-toggle-row.component.scss']
})
export class ProductToggleRowComponent implements OnInit {

  check = [];
  isOpenPopupSelect: boolean = false;
  @Input() displayedColumnsInput;
  constructor(
    private service: ProductService
  ) { }

  ngOnInit(): void {
  }

  togglePopupSelect() {
    this.isOpenPopupSelect = !this.isOpenPopupSelect;
  }

  changeDisplayColumn() {
    this.service.listColumnSubject.next(this.check);
  }

}
