
<div>
  <form [formGroup]="distributorForm" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="description" *ngIf="!isUpdateProduct">
            <div class="title">Doanh nghiệp</div>
            <div class="content">{{data?.row?.enterpriseName}}</div>
          </div>
          <div class="description" *ngIf="!isUpdateProduct">
              <div class="title">Sản phẩm</div>
              <div class="content">{{data?.row?.productName}}</div>
          </div>
          <div class="form-group">
            <div class="label-form">Chọn vai trò (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="type" placeholder="Chọn vai trò" >
                <mat-option [value]="2">Doanh nghiệp sản xuất</mat-option>
                <mat-option [value]="4">Doanh nghiệp phân phối độc quyền</mat-option>
                <mat-option [value]="3">Nhà phân phối</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <div class="label-form">Chọn tiêu đề (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="title" placeholder="Chọn tiêu đề" msInfiniteScroll (infiniteScroll)="lazyTitleOfRole()">
                <mat-option [value]="tittle.id" *ngFor="let tittle of listTitle">{{tittle.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!distributorForm.valid">Cập nhật</button>
      </mat-toolbar>