import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromProductAttribute from '../../reducers';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductAttributeListPageActions, ProductAttributePageActions } from '../../actions';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import { MatSelect } from '@angular/material/select';
import { ProductAttributeService } from '@attribute-set/services/product-attribute.service';

@Component({
  selector: 'ic-product-attribute-group-create',
  templateUrl: './product-attribute-group-create.component.html',
  styleUrls: ['./product-attribute-group-create.component.scss']
})
export class ProductAttributeGroupCreateComponent implements OnInit {
  data: any;
  attributeSetForm : any;
  productAttributes$: Observable<any>;
  searchChangeObserver;
  listAttributeSet = [];
  page = 1;
  filter = {};
  
  @ViewChild(MatSelect) child: MatSelect;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ProductAttributeGroupCreateComponent>,
    private formBuilder: FormBuilder,
    private store: Store<fromProductAttribute.State>,
    private productAttributeService: ProductAttributeService
  ) {
    this.data = data;
    this.productAttributes$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
   }

  ngOnInit(): void {
    this.attributeSetForm = this.formBuilder.group({
      name: ['', [Validators.required, noWhitespaceValidator]],
      parentId: [null, [Validators.required]],
      inputSearch: [''],
    });

    this.loadListAttributeSet();
  
  }


  loadListAttributeSet() {
    this.productAttributeService.getListAttributeSet({
      filter: this.filter,
      page: this.page,
      pageSize: 10,
    }).subscribe((res: any) => {
      this.listAttributeSet = [...this.listAttributeSet,...res.data.items];
    })
  }

  lazyLoadAttributeSet() {
    this.page += 1;
    this.loadListAttributeSet();
  }

  ngAfterViewInit() { 
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened && !this.listAttributeSet.length) {
        this.page = 1;
        this.filter = {};
        this.listAttributeSet = [];
        this.loadListAttributeSet();
        this.attributeSetForm.patchValue({inputSearch: ''})
      }
    })
  }

  

  changeInputSearch(event) {
    // this.store.dispatch(
    //   ProductAttributeListPageActions.loadProductAttributes({
    //     filter: {name: event.target.value},
    //     page: 1,
    //     pageSize: 20
    //   })
    // );
    this.page = 1;
        this.filter = { name: event.target.value };
        this.listAttributeSet = [];
        this.loadListAttributeSet();
    // if (!this.searchChangeObserver) {
    //   Observable.create(observer => {
    //     // console.log(observer);
        
    //     this.searchChangeObserver = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)
            
    //     });
    // }
    // this.searchChangeObserver.next(event.target.value);    
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

  onFormSubmit() {
    // console.log(this.attributeSetForm.value);
    const object = {
      ...this.attributeSetForm.value,
      name: this.attributeSetForm.value.name.trim()
    }
    this.store.dispatch(ProductAttributePageActions.addProductAttribute({ productAttributeObject: object }));
    this.dialogRef.close('accept');
  }

  clearInputSearch() {
    this.attributeSetForm.patchValue({inputSearch: ''})
    this.page = 1;
    this.filter = { };
    this.listAttributeSet = [];
    this.loadListAttributeSet();
    // this.store.dispatch(
    //   ProductAttributeListPageActions.loadProductAttributes({
    //     filter: {},
    //     page: 1,
    //     pageSize: 20
    //   })
    // );
  }

  clearSelected(key) {
    this.attributeSetForm.patchValue({
      [key]: null
    })
    this.attributeSetForm.updateValueAndValidity();
  }

}
