
<div>
  <form [formGroup]="importForm" (ngSubmit)="onSubmitForm()">

    <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
    </mat-toolbar>
    <div class="content-modal">
      <div class="form-group">
        <div class="label-form">Import file excel (*)</div>
        <!-- <ic-file-list style="display: inline-flex;"  [files]="file" (addFile)="openUploadDialog('logo')"
        (removeFile)="onRemoveFile('logo', $event)"></ic-file-list> -->

        <div class="div-import">
          <div class="btn-upload">
            <label class="typeLable">
              <mat-icon style="margin-right: 0.5rem;">cloud_upload</mat-icon>
              <span>Click to upload</span>
              <input type="file" #inputUpload (input)="importFile($event.target.files[0])">
            </label>
          </div>
          <div *ngIf="importName" class="file-import">
            <!-- <mat-icon class="icon-link">link</mat-icon> -->
            <!-- <mat-icon class="icon-link"> attach_file</mat-icon> -->
            <span class="file-label" [title]="importName">{{importName}}</span>
            <mat-icon class="icon-delete" (click) = "deleteFileImport()">clear</mat-icon>
            <!-- <mat-icon class="icon-delete" (click) = "deleteFileImport()">delete_outline</mat-icon> -->
          </div>
        </div>

        <div *ngIf="!checkUpdate">
          <mat-checkbox #addNew (click)="clickAdd(!addNew.checked)" [checked]="true" >Thêm mới sản phẩm</mat-checkbox>
          <div style="margin-left: 40px;padding-top: 5px" *ngIf="isAddNewProductImport">
            <mat-checkbox formControlName="allow_invalid_code1">Áp dụng mã sai định dạng</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_invalid_prefix1">Áp dụng mã sai Prefix</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_empty_gln1">Áp dụng cho mã bỏ trống GLN</mat-checkbox>

          </div>
        </div>
        <div *ngIf="!checkUpdate" >
          <mat-checkbox #update (click)="clickUpdate(!update.checked)" [checked]="true"> Cập nhật sản phẩm</mat-checkbox>

          <div style="margin-left: 40px;padding-top: 5px" *ngIf="isUpdateProductImport">
            <mat-checkbox formControlName="allow_invalid_code2">Áp dụng mã sai định dạng</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_invalid_prefix2">Áp dụng mã sai Prefix</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_managed_business">Áp dụng cho những mã đã được quản lý bởi Business</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_not_contribute">Áp dụng cho mã đang chặn không cho người dùng đóng góp</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_has_info">Áp dụng cho mã đã có thông tin (tên và ảnh)</mat-checkbox>
            <br>
            <mat-checkbox formControlName="allow_empty_gln2">Áp dụng cho mã bỏ trống GLN</mat-checkbox>
          </div>
        </div>
        <div *ngIf="checkUpdate">
          <mat-checkbox formControlName="checkPrefix">Áp dụng mã sai Prefix</mat-checkbox>
        </div>

        <div *ngIf="checkUpdate" >
          <mat-checkbox formControlName="isSkipBusiness">Áp dụng mã đã được quản lý bởi Business</mat-checkbox>
        </div>

        <div *ngIf="checkUpdate" >
          <mat-checkbox formControlName="isOnlyPropertiesExcel" [(ngModel)]="checkTruePropertyExcel" disabled="true">Chỉ cập nhật những trường có dữ liệu trong excel</mat-checkbox>
        </div>


        <div *ngIf="!isExpand" style="margin-top: 1rem;">
          <div>Chọn bộ thuộc tính để tải file mẫu</div>
          <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
            <mat-icon matSuffix class="icon-remove" *ngIf="importForm.value.attributeSetId" (click)="clearSelected('attributeSetId')">cancel</mat-icon>
            <mat-select placeholder="Chọn bộ thuộc tính" formControlName="attributeSetId" (selectionChange)="changeSelected($event)" #matSelect>
              <mat-form-field appearance="outline" class="form-field-search">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput
                       type="text"
                       name="filter-options"
                       id="filter-options"
                       placeholder="Search"
                       (keyup)= "changeInputAttributeSetSearch($event)"
                       (keydown)="$event.stopPropagation()"
                       formControlName="inputSearch">
                <mat-icon matSurfix *ngIf="this.importForm.value.inputSearch" (click) = "clearInputSearch()">clear</mat-icon>
              </mat-form-field>

              <mat-option *ngFor="let attributeSet of listAttributeSet" [value]="attributeSet.id" (onSelectionChange)="onSelectionChange($event, attributeSet)">
                {{attributeSet.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button type="button" [disabled]="!importForm.get('attributeSetId').value" class="btn-download" (click)="downloadTemplate()">Tải xuống file mẫu</button>
        </div>
      </div>
    </div>
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" [disabled]="!importForm.valid || (!isAddNewProductImport && !isUpdateProductImport)" class="btn-stop"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
      </mat-spinner></mat-icon>Thực hiện</button>
    </mat-toolbar>
  </form>
</div>
