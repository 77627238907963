import { Component, OnInit, Input } from '@angular/core';
import { DateSettingComponent } from './date-setting.component';

@Component({
  selector: 'ic-date-display',
  templateUrl: './date-display.component.html',
  styleUrls: ['./date-display.component.scss']
})
export class DateDisplayComponent implements OnInit {

  @Input()
  data: DateSettingComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
