<div class="main">
  <div class="header-label">
    <i class="text-alert" *ngIf="!currentGln?.id">Sản phẩm chưa được gán GLN</i>
  </div>
  <div *ngIf="currentGln?.id">
    <div class="main-description">
        <div class="description-alert">
            <div class="title">Tên doanh nghiệp</div>
            <div class="content">{{currentGln.name}}</div>
        </div>
        <div class="description-alert">
            <div class="title">Mã số thuế</div>
            <div class="content">{{currentGln.taxCode}}</div>
        </div>
        <div class="description-alert">
            <div class="title">Prefix</div>
            <div class="content">{{currentGln.prefixCode}}</div>
        </div>
        <div class="description-alert">
            <div class="title">GLN</div>
            <div class="content">{{currentGln.glnCode}}</div>
        </div>
        <div class="description-alert">
          <div class="title">Loại mã</div>
          <div class="content">
            <span *ngIf = "currentGln.glnType === 'enterprise_internal_code'">Mã nội bộ doanh nghiệp</span>
              <span *ngIf = "currentGln.glnType === 'gs1_official_code'">Mã GS1 chính thống</span>
              <span *ngIf = "currentGln.glnType === 'gs1_unofficial_code'">Mã GS1 không chính thống</span>
              <span *ngIf = "currentGln.glnType === 'icheck_internal_code'">Mã nội bộ iCheck</span>
          </div>
        </div>
        <div class="description-alert">
          <div class="title">Trạng thái xác thực</div>
          <div class="content">
            <span *ngIf="currentGln.refStatus === 1">Đã xác thực</span>
            <span *ngIf="currentGln.refStatus === 3">Chưa xác thực</span>
          </div>
        </div>
        <div class="description-alert">
          <div class="title">Trạng thái ẩn/hiện</div>
          <div class="content">
            <span *ngIf="currentGln.refVisible === 1">Hiện</span>
            <span *ngIf="currentGln.refVisible === 3">Ẩn</span>
          </div>
        </div>
        <div class="description-alert">
          <div class="title">Địa chỉ</div>
          <div class="content">{{currentGln?.address}}</div>
        </div>
        <div class="description-alert">
          <div class="title">Điện thoại</div>
          <div class="content">{{currentGln.phone}}</div>
        </div>
        <div class="description-alert">
          <div class="title">Email</div>
          <div class="content">{{currentGln.email}}</div>
        </div>
        <div class="description-alert">
          <div class="title">Website</div>
          <div class="content">{{currentGln.website}}</div>
        </div>
        <div class="description-alert">
          <div class="title">Ảnh đại diện</div>
          <div class="content">
            <img onError="src='/assets/stran.png'" class="image" [src]="currentGln?.logo" />
          </div>
        </div>
  
        <div class="description-alert">
          <div class="title">Ảnh bìa</div>
          <div class="content"> <img onError="src='/assets/stran.png'"  class="image" [src]="currentGln?.backgroundImage" /></div>
        </div>
  
    </div>
  </div>
</div>
