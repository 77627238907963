
<form [formGroup]="productForm" (ngSubmit)="onFormSubmit()">
  <div class="content-page">
    <div class="form-group">
      <div class="label-form">Mã sản phẩm (*)</div>

      <mat-form-field appearance="outline" class="form-field">
          <input matInput placeholder="Nhập mã sản phẩm" formControlName="barcode" (input)= "changeInputBarCode($event)" maxLength="255">
          <mat-list *ngIf="listSearchBarcode.length" class="div-list" #divList>
            <mat-list-item *ngFor="let item of listSearchBarcode"> {{item}} </mat-list-item>
           </mat-list>
      </mat-form-field>
    </div>
    <div class="form-group" style="display: none">
      <div class="label-form">alert Id</div>
      <mat-form-field appearance="outline" class="form-field">
        <input matInput placeholder="Nhập mã cảnh báo" formControlName="alertId" maxLength="255" >
      </mat-form-field>
    </div>
    <div class="label-warning" *ngIf="isExitsBarCode  && !isUpdate">
        <i class="no-exist" *ngIf="!isExitsBarCode">Mã sản phẩm chưa có trên hệ thống!</i>
        <i class="already-exist" *ngIf="isExitsBarCode">Mã sản phẩm đã tồn tại trên hệ thống!</i>
    </div>

    <i class="format-error" *ngIf="!isUpdate && productForm.get('barcode').value && !validFormatBarCode">Barcode sai định dạng</i>
    <i class="format-error" *ngIf="checkPrefixBarCode">Barcode không chứa prefix của GLN</i>

    <div class="form-group">
      <div class="label-form">Chọn GLN (*)</div>
      <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
        <mat-icon matSuffix class="icon-remove" *ngIf="this.productForm.value.enterpriseId" (click)="clearSelectedControl('enterpriseId')">cancel</mat-icon>
        <mat-select formControlName="enterpriseId" msInfiniteScroll (infiniteScroll)="lazyLoadEnterprise()" placeholder="Chọn GLN" #matSelectGln>
          <mat-form-field appearance="outline" class="form-field-search" >
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                type="text"
                name="filter-options"
                id="filter-options"
                placeholder="Search"
                (keyup)= "changeInputGlnSearch($event)"
                (keydown)="$event.stopPropagation()"
                formControlName="inputSearchGln">
                <mat-icon matSurfix *ngIf="this.productForm.value.inputSearchGln" (click) = "clearInputSearchGln()">clear</mat-icon>
          </mat-form-field>
          <mat-option *ngFor="let gln of listGln" [value]="gln.id" title='{{gln.name}} (Prefix: {{gln.prefix}}, GLN: {{gln.glnCode}})'>
              {{gln.name}} (Prefix: {{gln.prefix}}, GLN: {{gln.glnCode}})
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="checkbox" *ngIf="!isUpdate">
      <mat-checkbox formControlName="checkPrefix">Check Prefix</mat-checkbox>
    </div>

    <div class="form-group">
      <div class="label-form">Chọn trạng thái quét (*)</div>
      <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
        <mat-select formControlName="scanStatus" placeholder="Chọn trạng thái quét">
          <mat-option [value]="0">No data</mat-option>
          <mat-option [value]="1">Cho quét</mat-option>
          <mat-option [value]="3">Không cho quét</mat-option>
          <mat-option [value]="2">Đã ẩn bởi DNSH</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group" *ngIf="isExitsBarCode === false">
      <div class="label-form">Chọn danh mục</div>
      <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
        <mat-icon matSuffix class="icon-remove" *ngIf="this.productForm.value.categoryId" (click)="clearSelectedControl('categoryId')">cancel</mat-icon>
        <mat-select placeholder="Chọn danh mục" msInfiniteScroll (infiniteScroll)="lazyLoadCategory()" formControlName="categoryId" #matSelectCategory>
          <mat-form-field appearance="outline" class="form-field-search" >
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                type="text"
                name="filter-options"
                id="filter-options"
                placeholder="Search"
                (keyup)= "changeInputCategorySearch($event)"
                (keydown)="$event.stopPropagation()"
                formControlName="inputSearchCategory">
                <mat-icon matSurfix *ngIf="this.productForm.value.inputSearchCategory" (click) = "clearInputSearchCategory()">clear</mat-icon>
          </mat-form-field>

          <mat-option *ngFor="let category of listCategory" [value]="category.id" (click)="onOptionClick($event)" title="{{category.display_name}}">
            {{category.display_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="form-group" *ngIf="isExitsBarCode === false">
      <div class="label-form">Chọn bộ thuộc tính (*)</div>
      <mat-form-field appearance="outline" style=" margin-bottom: -10px">
        <mat-icon matSuffix class="icon-remove" *ngIf="this.productForm.value.customAttributeSetId" (click)="clearSelectedControl('customAttributeSetId')">cancel</mat-icon>
        <mat-select placeholder="Chọn bộ thuộc tính" #matSelectAttributeSet msInfiniteScroll (infiniteScroll)="lazyLoadAttributeSet()" formControlName="customAttributeSetId">
          <mat-form-field appearance="outline" class="form-field-search">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput
                type="text"
                name="filter-options"
                id="filter-options"
                placeholder="Search"
                (keyup)= "changeInputAttributeSetSearch($event)"
                (keydown)="$event.stopPropagation()"
                formControlName="inputSearchAttributeSet">
            <mat-icon matSurfix *ngIf="this.productForm.value.inputSearchAttributeSet" (click) = "clearInputSearchAttributeSet()">clear</mat-icon>
          </mat-form-field>
          <mat-option *ngFor="let attributeSet of listAttributeSet" [value]="attributeSet.id">
            {{attributeSet.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="attributeSet?.id">
      <div class="content-group" formGroupName="attributes">
        <div *ngFor="let group of attributeSet.groupAttribute" class="group-attribute">
          <div  class="label-group">
            <div class="label-attribute-set">
              <span class="title-attribute-set">{{group.groupName}}</span>
            </div>
          </div>

          <div class="list-attribute">
            <div *ngFor="let item of group.attributeValues" class="attribute-item" [ngClass]="{'html': item.type === 'htmleditor', 'group': item.type === 'group'}" style="display: flex;">
              <span class="attribute-label">{{item.attributeLabel}}
                <span *ngIf="item.isRequired">(*)</span>
              </span>
              <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'text'">
                <input matInput [placeholder]="item.name" [formControlName]="item.attributeId" [type]="item.type" maxLength="500"/>
              </mat-form-field>
              <ic-file-list style="display: inline-flex;"  *ngIf="item?.type === 'image' || item?.type === 'image-single'"  [files]="this.productForm.get(['attributes', item.attributeId])?.value" (addFile)="openUploadDialog(item.attributeId, item)" (removeFile)="onRemoveFile(item.attributeId, $event)"></ic-file-list>
              <!-- </mat-form-field> -->
              <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'number'" >
                <input matInput  [placeholder]="0" [formControlName]="item.attributeId" type="number"  [value] = "item?.value">
              </mat-form-field>
              <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'integer'" >
                <input matInput  [placeholder]="0" [formControlName]="item.attributeId" type="number"  (keypress)="numberOnly($event)" [value] = "item?.value">
              </mat-form-field>
              <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'textarea'">
                <textarea matInput  [placeholder]="item.name" [formControlName]="item.attributeId" [value] = "item?.value" type="text"   ></textarea>
              </mat-form-field>
                <!-- <textarea matInput  [placeholder]="item.name" [formControlName]="item.attributeId" [value] = "item?.value" type="text"   ></textarea> -->
                <div class="form-field" style="margin-top: 1rem;" ngClass="htmleditor{{item.attributeId}}" *ngIf="item.type === 'htmleditor'">
                  <input [ngxSummernote]="config" type="text" [formControlName]="item.attributeId" />
                </div>
              <mat-form-field *ngIf="item.type === 'date'" appearance="outline">
                <mat-label>Choose a date</mat-label>
                <input matInput readonly (click)="picker.open()" [matDatepicker]="picker" [formControlName]="item.attributeId"   [value] = "item?.value | date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <mat-radio-group  *ngIf="item.type === 'boolean'" [formControlName]="item.attributeId"     [value] = "item?.value | date:'dd/MM/yyyy'">
                <mat-radio-button [value]="true">Có</mat-radio-button>
                <mat-radio-button [value]="false">Không</mat-radio-button>
              </mat-radio-group>
                <mat-form-field *ngIf="item.type === 'select'" appearance="outline">
                  <mat-label>{{item.name}}</mat-label>
                  <mat-icon matSuffix class="icon-remove" *ngIf="this.productForm.get(['attributes', item.attributeId]).value" (click)="clearSelected(item.attributeId)">cancel</mat-icon>
                  <mat-select [formControlName]="item.attributeId">
                    <mat-option [value]="it.id" *ngFor="let it of item?.dataSelects">
                      {{it.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="item.type === 'multiselect'" appearance="outline">
                  <mat-label>{{item.name}}</mat-label>
                  <mat-icon matSuffix class="icon-remove" *ngIf="this.productForm.get(['attributes', item.attributeId]).value" (click)="clearSelected(item.attributeId)">cancel</mat-icon>
                  <mat-select multiple #select  [formControlName]="item.attributeId">
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSelected"
                                      [ngModelOptions]="{standalone: true}"
                                      (change)="toggleAllSelection()">Select All</mat-checkbox>
                    </div>
                    <mat-select-trigger>
                      {{this.productForm.get(['attributes', item.attributeId]).value.length ? generateLabel(item) : ''}}
                    </mat-select-trigger>
                    <mat-option [value]="it.id" *ngFor="let it of item?.dataSelects" (click)="optionClick()">
                      {{it.value}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="item.type === 'group'" class="attr-group">
                  <mat-form-field appearance="outline" class="form-field" style="margin-bottom: 1rem;">
                    <textarea matInput [placeholder]="item.name" formControlName="text-{{item.attributeId}}" type="text" maxLength="500"></textarea>
                  </mat-form-field>
                  <ic-file-list style="display: inline-flex;margin-bottom: 0.6rem;"  [files]="this.productForm.get(['attributes', item.attributeId])?.value" (addFile)="openUploadDialog(item.attributeId, item)" (removeFile)="onRemoveFile(item.attributeId, $event)"></ic-file-list>
                  <input [ngxSummernote]="config" type="text" formControlName="textarea-{{item.attributeId}}" />
                </div>
                <div *ngIf="item.type === 'ecommerce'" class="attr-ecommerce" style="display: inline-block;">
                  <div formArrayName="ecommerceList" style="margin-bottom: 5px;">
                    <div
                      class="d-flex justify-between mb-15"
                      *ngFor="
                        let item of productForm.get('attributes')['controls'].ecommerceList['controls'];
                        index as i
                      "
                    >
                      <ng-container [formGroupName]="i">
                        <div class="ecom ecom-avatar" >
                          <img [src]="productForm.get('attributes')['controls'].ecommerceList['controls'][i].value.avatar" width="50" height="50"/>
                        </div>
                        <div class="ecom ecom-list">
                          <mat-form-field appearance="outline">
                            <mat-label>Sàn thương mại (*)</mat-label>
                            <mat-select formControlName="ecommerceId" (ngModelChange)="changeEcommerce($event, i)">
                              <mat-option *ngFor="let ecom of listEcommerce" [value]="ecom.id">
                                {{ecom.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="ecom ecom-link">
                          <mat-form-field appearance="outline">
                            <mat-label>Link tương ứng (*)</mat-label>
                            <input matInput placeholder="Link tương ứng" formControlName="link" (input)="changeInput($event.target.value, i)">
                          </mat-form-field>
                        </div>
                        <div class="ecom ecom-price">
                          <mat-form-field appearance="outline">
                            <mat-label>Giá niêm yết (*)</mat-label>
                            <input matInput placeholder="Giá niêm yết" type="number" formControlName="price" (input)="changeInput($event.target.value, i)">
                          </mat-form-field>
                        </div>
                        <div class="ecom ecom-promotion-price">
                          <mat-form-field appearance="outline">
                            <mat-label>Giá khuyến mãi</mat-label>
                            <input matInput placeholder="Giá khuyến mãi" type="number" formControlName="promotionPrice" (input)="changeInput($event.target.value, i)">
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <button mdcIconButton class="btn-delete" (click)="removeFormEcommerceItem(i)">
                        <mdc-icon>delete</mdc-icon>
                      </button>
                    </div>
                  </div>
                  <button mat-raised-button class="primary btn-create-ecommerce" type="button" (click)="addFormEcommerceItem()">Thêm</button>

                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list-button">
      <button mat-raised-button class="primary btn-create" type="submit" *ngIf="!this.isUpdate; else updateButton" [disabled] = "!productForm.valid">Thêm mới</button>


      <ng-template #updateButton><button mat-raised-button class="primary btn-update" type="submit" >Cập nhật</button></ng-template>
      <button mat-raised-button type="button" class="btn-cancel" (click)="backToListProduct()">Hủy bỏ</button>
    </div>
    <div>

    </div>
</div></form>


