import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {  DashAppliedFilter } from './dash/filter-bar';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare function methodChecking();
@Component({
  selector: 'ic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit, OnDestroy , OnInit{
  title = 'icheck-cms';
  path_url_root;
  path_url_first;
  @ViewChild('sidebarPanel') sidebarPanel: ElementRef;
  destroy$ = new Subject();

  appliedFilters = [
    {
      key: 'date',
      value: {
        startDate: new Date,
        endDate: new Date,
      }
    },
  ]

  desserts = [
    {checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty'},
    {checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more'},
    {checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor'}
  ];

  destinations = [
    { label: 'Inbox', icon: 'inbox', activated: true },
    { label: 'Star', icon: 'star', activated: false },
    { label: 'Sent Mail', icon: 'send', activated: false },
    { label: 'Drafts', icon: 'drafts', activated: false }
  ];

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private _snackBar: MatSnackBar) {
      this.router.events.pipe(
        takeUntil(this.destroy$)
      ).subscribe(val => {

        let url = this.location.path().split('/');
        this.path_url_root = url[1] || '';
        this.path_url_first = this.location.path();
      });

  }

  ngOnInit() {
    methodChecking();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.authService.logout();

  }
  ngAfterViewInit() {
  }

  onFilterChanged(event: DashAppliedFilter<any>[]) {
  }
}
