import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-gln-detail-page',
  template: `
    <mat-card style="margin:1rem;">
      <ic-gln-detail></ic-gln-detail>
    </mat-card>
  `,
  styles: [
    `
      h4 {
        padding: 1rem;
      }
    `
  ]
})
export class GlnDetailPageComponent implements AfterViewInit, OnInit {
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
