import { Component, OnInit, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmPasswordComponent } from '../confirm-password/confirm-password.component';

@Component({
  selector: 'ic-confirm-remove',
  templateUrl: './confirm-remove.component.html',
  styleUrls: ['./confirm-remove.component.scss']
})
export class ConfirmRemoveComponent implements OnInit {


  data : any
  constructor(public dialogRef: MdcDialogRef<ConfirmRemoveComponent>,
    private dialog: MatDialog,
    @Inject(MDC_DIALOG_DATA) data) {
      this.data = data;
    }

  ngOnInit(): void {
    // console.log(this.data);

  }

  openDialogConfirmPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Xác nhận mật khẩu',
      action: 'create',
      data: this.data
    };
    dialogConfig.disableClose = true;
    console.log('data', this.data)
    const dialogRef = this.dialog.open(ConfirmPasswordComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.dialogRef.close();
      } else {
        this.dialogRef.close('close');
      }
      console.log('The dialog was closed');
    });
  }
}
