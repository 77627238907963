import {Injectable} from '@angular/core';
import {environment} from '@src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {ErrorLogDto} from '@src/app/shared/common/error-log.dto';
import {throwError} from 'rxjs';
declare function errorTeko(info);
@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public baseUrl = environment.baseCoreUrl;

  constructor(protected http: HttpClient) {}

  get<T>(
    endpoint: string,
    options?: {}
  ): Observable<T> {
    return this.http.get<T>(this.buildUrl(endpoint), options).pipe(catchError(err => {
      const error = new ErrorLogDto(err, this.buildUrl(endpoint), '', err);
      errorTeko(error);
      return throwError(err);
    }) );
  }

  post<T>(
    endpoint: string,
    body?: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
    } = {}
  ): Observable<T> {
    return this.http.post<T>(this.buildUrl(endpoint), body, options).pipe(catchError(err => {
      const error = new ErrorLogDto(err, this.buildUrl(endpoint), body, err);      errorTeko(error);
      return throwError(err);
    }) );
  }

  patch<T>(
    endpoint: string,
    body?: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
    } = {}
  ): Observable<T> {
    return this.http.patch<T>(this.buildUrl(endpoint), body, options);
  }

  put<T>(
    endpoint: string,
    body?: any | null,
    options: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
    } = {}
  ): Observable<T> {
    return this.http.put<T>(this.buildUrl(endpoint), body, options).pipe(catchError(err => {
      const error = new ErrorLogDto(err, this.buildUrl(endpoint), body, err);
      errorTeko(error);
      return throwError(err);
    }) );
  }

  delete<T>(
    endpoint: string,
    options: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
    } = {}
  ): Observable<T> {
    return this.http.delete<T>(this.buildUrl(endpoint), options).pipe(catchError(err => {
      const error = new ErrorLogDto(err, this.buildUrl(endpoint), '', err);
      errorTeko(error);
      return throwError(err);
    }) );
  }

  protected buildUrl(url: string): string {
    return `${this.baseUrl}/${url.replace(/^\/+/g, '')}`;
  }
}
