import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { GlnModalComponent } from '../gln-modal/gln-modal.component';
import { Store, select } from '@ngrx/store';
import * as fromGln from '@gln/reducers';
import { GlnListPageActions, GlnPageActions } from '@gln/actions';
import * as fromProducts from '@products/reducers';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProductPageActions } from '@products/actions';
import { buildImageUrls } from '@src/app/shared/common';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
declare function enterScreenView (screenName, contentType)

@Component({
  selector: 'ic-gln-tab',
  templateUrl: './gln-tab.component.html',
  styleUrls: ['./gln-tab.component.scss']
})
export class GlnTabComponent extends ModalScroll implements OnInit {

  productCurrent$: Observable<any>;
  currentProduct;
  currentGln$: Observable<any>;
  currentGln;
  productId;

  // @ts-ignore
  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private storeProduct: Store<fromProducts.State>,
              private storeGln: Store<fromGln.State>) {
      super();
      this.productCurrent$ = storeProduct.pipe(select(fromProducts.selectProductCurrent));
      this.currentGln$ = storeGln.pipe(select(fromGln.selectCurrentGln));
    }

  ngOnInit(): void {
    enterScreenView ('product_owner', 'product')
    this.productId = this.route.snapshot.paramMap.get('id');
    if (this.productId) {
      this.storeProduct.dispatch(ProductPageActions.getProduct({ id: this.productId }));
    }
    this.productCurrent$.subscribe(it => {
      // console.log(it);
      if (it?.enterprise?.id) {
        this.currentGln = {
          ...it.enterprise,
          backgroundImage: buildImageUrls(it.enterprise.backgroundImage).original,
          logo: buildImageUrls(it.enterprise.logo).original,
        };
        console.log(this.currentGln);

      } else {
        this.currentGln = {};
      }
    });
  }

  openDialogAlertProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '20rem';
    dialogConfig.data = {
      label: 'Cập nhật DNSH cho sản phẩm',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(GlnModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const id = this.route.snapshot.paramMap.get('id');

        setTimeout(() => {
          this.storeProduct.dispatch(ProductPageActions.getProduct({ id }));
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

}
