import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-toolbars',
  templateUrl: './toolbars.component.html',
  styleUrls: ['./toolbars.component.scss']
})
export class ToolbarsComponent implements OnInit {
  isExpanRowButton
  constructor() { }

  ngOnInit(): void {
  }

  expanRowButton() {
    this.isExpanRowButton = true;
  }

  collapseRowButton() {
    this.isExpanRowButton = false;
  }

}
