import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  CategoryActions, CategoryPageActions, CategoryListPageActions, CategoriesAPIActions,
} from '../actions';

import { CategoryService } from '../services/category.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {nameDescent} from '@src/app/shared/common/denial.helper';

@Injectable()
export class CategoryEffects {
  constructor(
    private actions$: Actions,
    private categoryService: CategoryService,
    private store: Store<any>,
    private _snackBar: MatSnackBar,
  ) {}





  searchCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryListPageActions.searchCategory),
      switchMap(action => {
        // console.log(action);
        return this.categoryService.searchCategory(action).pipe(
          map((res: any) => {
            const newData = res.data.items.map(nameDescent);
            return CategoryListPageActions.searchCategorySuccess({ rows: newData  });
          }),
          catchError(error =>
            of(CategoryListPageActions.searchCategoryFailure({ error }))
          )
        )
          })
    )
  });

  loadListCategoryByParent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryListPageActions.loadListCategoryByParent),
      switchMap(action => {
        return this.categoryService.getListCategory(action).pipe(
          switchMap((res: any) => [
            CategoriesAPIActions.loadCategoryByParentSuccess({ data: res.data.items }),
            CategoryListPageActions.loadListCategoryByParentSuccess({
              total: res.data.totalElement,
              // rows: res.data.items,
              ids: res.data.items.map(item => item.id)
            })
          ]),
          catchError(error => [
            CategoriesAPIActions.loadCategoryByParentFailure({ error }),
            CategoryListPageActions.loadListCategoryByParentFailure({ error })
          ])
        );
      })
    )
  });


  loadCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryActions.loadCategory),
      switchMap(action => {
        // console.log(action);
        return this.categoryService.getCategories(action.filter).pipe(
          map((categories: any) => {
            // console.log(categories);
            return CategoryActions.loadCategoriesSuccess({ categories: categories.list, total: categories.total  })
          }),
          catchError(error =>
            of(CategoryActions.loadCategoriesFailure({ error }))
          )
        )
          })
    )
  });

  assignAttributeSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CategoryPageActions.assignAttributeSet),
      switchMap(action => {
        // console.log(action);
        return this.categoryService.assignAttributeSet(action.object).pipe(
          map((object: any) => {
            this._snackBar.open('Gán bộ thuộc tính thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            // console.log(categories);
            return CategoryPageActions.assignAttributeSetSuccess({ object  })
          }),
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(CategoryPageActions.assignAttributeSetFailure({ error }))
          }
          )
        )
          })
    )
  });

  updateCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryPageActions.updateCategory),
      switchMap((actions) =>
      {
        return this.categoryService.updateCategory(actions.categoryObject).pipe(
          map((category: any) =>
           {
            this._snackBar.open('Cập nhật danh mục thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return CategoryPageActions.updateCategorySuccess({ category })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(CategoryPageActions.updateCategoryFailure({ error }))
          }
          )
        )
          })
    )
  );

  addCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryPageActions.addCategory),
      switchMap((actions) =>
      {
        return this.categoryService.createCategory(actions.categoryObject).pipe(
          map((category: any) =>
           {
            this._snackBar.open('Thêm mới danh mục thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return CategoryPageActions.addCategorySuccess({ category })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(CategoryPageActions.addCategoryFailure({ error }))
          }
          )
        )
          })
    )
  );

  getDetailCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryPageActions.getDetailCategory),
      switchMap((actions) =>
      {
        return this.categoryService.getDetailCategory(actions.id).pipe(
          map((category : any) =>
           {
            //  console.log(category);
            const categoryCurrent = {
              ...category.data,
              display_name: category.data.ancestors.length ? category.data.ancestors.map(item => item.name).join(' > ') + ' > ' + category.data.name : category.data.name
            }
            // console.log(categoryCurrent);

            return CategoryPageActions.getDetailCategorySuccess({ category: categoryCurrent })
           }
            )
           ,
          catchError(error => {
            return of(CategoryPageActions.getDetailCategoryFailure({ error }))
          }
          )
        )
          })
    )
  );


  getListAttributeByCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryPageActions.getAttributeSetByCategory),
      switchMap((actions) =>
      {
        return this.categoryService.getDetailAttributeSetByCategory(actions.id).pipe(
          map((listAttribute) =>
           {
            return CategoryPageActions.getAttributeSetByCategorySuccess({ listAttribute })
           }
            )
           ,
          catchError(error => {
            return of(CategoryPageActions.getAttributeSetByCategoryFailure({ error }))
          }
          )
        )
          })
    )
  );
//   getDetailCategory$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(CategoryPageActions.getAttributeSetByCategory),
//     switchMap((actions) =>
//     {
//       return this.categoryService.getDetailCategoty(actions.id).pipe(
//         map((category) =>
//          {
//           return CategoryPageActions.getDetailCategorySuccess({ category })
//          }
//           )
//          ,
//         catchError(error => {
//           return of(CategoryPageActions.getDetailCategoryFailure({ error }))
//         }
//         )
//       )
//         })
//   )
// );
//   updateListCategory$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(CategoryActions.updateListCategory),
//       switchMap((actions) =>
//       {
//         return this.categoryService.updateListCategory(actions.categories, actions.category).pipe(
//           map((category) =>
//            {
//             return CategoryActions.updateListCategorySuccess({ category })
//            }
//             )
//            ,
//           catchError(error =>
//             of(CategoryActions.updateListCategoryFailure({ error }))
//           )
//         )
//           })
//     )
//   );
}
