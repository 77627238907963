import { checkBarCodeExist } from '../actions/user-page.actions';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { convertStringToDate, convertDateGrpcToFormatDate, convertObjectToQueryParams } from 'src/app/shared/common';
import { environment } from '@src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};


@Injectable({
  providedIn: "root"
})
export class UserService {
  listColumnSubject = new Subject<any>();

  private baseUrl = environment.baseCoreUrl;

  constructor(public http: HttpClient) {}

  getListRole(object) {
    // console.log(object);
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage, objectFilter);
    
    return this.http.get(`${this.baseUrl}/role?${objectPage}&${objectFilter}`);
  }

  getListUser(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.http.get(`${this.baseUrl}/users?${objectPage}&${objectFilter}`);
  }

  updateUser(object) {
    return this.http.put(`${this.baseUrl}/users/${object.id}`, object);
  }

  createUser(object) {
    return this.http.post(`${this.baseUrl}/users`, object);
  }

  deleteUser(id) {
    // console.log(id);
    return this.http.delete(`${this.baseUrl}/users/${id}`);
  }

  getListIcheckId(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.http.get(`${this.baseUrl}/users/search?${objectPage}&${objectFilter}`);
  }

  // getDetailUser(id) {
  //   return this.http.get(`${this.baseUrl}/users/${id}`);
  // }

  getUsers(params) {
    // console.log(params);
    // return from(this.datahub.getListUser(params)).pipe(
    //   map(({ rows, total }) => {
    //     // console.log(rows, total);
    //     const data = {
    //       total,
    //       rows: rows.map((row, index) => {
    //         let category_list_name = [];
    //         let category_list_id = [];
    //         if (row.getCategoriesList().length) {
    //           console.log(row.getCategoriesList());
              
    //           category_list_name = row.getCategoriesList()[0].getItemsList().map(item => item.getName().getValue());
    //           category_list_id = row.getCategoriesList()[0].getItemsList().map(item => item.getId());
    //         }
    //         return {
    //         id: row.getId() ? row.getId() : '',
    //         image: row.getImage() ? row.getImage().getValue() : '',
    //         name: row.getUserName() ? row.getUserName().getValue() : '',
    //         bar_code: row.getBarcode() ? row.getBarcode().getValue() : '',
    //         price: row.getPrice() ? row.getPrice().getValue() : '',
    //         is_verify: row.getIsVerify() ? row.getIsVerify() : '',
    //         scan_count: row.getScanCount() ? row.getScanCount() : 0,
    //         review_count: row.getReviewCount() ? row.getReviewCount() : 0,
    //         question_count: row.getQuestionCount() ? row.getQuestionCount() : 0,
    //         category_id: category_list_id || [],
    //         category_name: category_list_name || [],
    //         created_at: row.getCreatedAt() ? row.getCreatedAt() : '',
    //         updated_at: row.getUpdatedAt() ? row.getUpdatedAt().getValue() : '',
    //         gln_name: row.getEnterprise() ? row.getEnterprise().getName() : [],
    //         gln_id: row.getEnterprise() ? row.getEnterprise().getId() : [],
    //         gln_type: row.getEnterprise() ? row.getEnterprise().getType().getValue() : [],
    //         gln_code: row.getEnterprise() ? row.getEnterprise().getGln().getValue() : ''
    //         // description: 
    //       }})
    //     };
    //     console.log(data);
        
    //     return data;
    //   }),
    // );
  }



  getUser(id) { 
    // console.log(id);
    
    // return from(this.datahub.getUserById(id)).pipe(
    //   map(({ user }) => {
    //     console.log(user);
    //     // let user_image_list = [];
    //     // if (user.getUserImagesList()) {
    //     //   user_image_list = user.getUserImagesList().map(item => item.getValue())
    //     // }
    //     // const list_category_id = user.getCategoriesList().map(it => it.getItemsList().map(item => item.getId()))[0];
    //     // console.log(list_category_id);
    //     let group_attribute_list = [];
    //     if (user.getGroupAttributeList()) {
    //       group_attribute_list = user.getGroupAttributeList().map((item, index) => {
    //         console.log(item);
            
    //         return {
    //           name: item.getGroupName() ? item.getGroupName() : '',
    //           // id: it.getId() ? it.getId(): '',
    //           children: item.getAttributeValuesList().map(it => {
    //             console.log(it.getBoolValue());
    //             let value;
    //             if (it.getType() && it.getType() === 'number') {
    //                 value = it.getDecimalValue() ? it.getDecimalValue().getValue() : ''
    //               } else if (it.getType() === 'image') {

    //                 console.log(it.getGalleryList().length);
    //                 console.log(it.getGalleryList());
    //                 value =  it.getGalleryList() ? it.getGalleryList().map(value => {
    //                   if (value.getValue()) {
    //                     return {
    //                       url: value.getValue()
    //                     }
    //                   }
    //                 }) : [];
    //                 console.log(value);
                    
    //                 if (!value[0]) {
    //                   console.log('aaaaaaa');
                      
    //                   value = []
    //                 }
    //               } else if (it.getType() === 'boolean') {
    //                 console.log(it.getBoolValue().getValue());
                    
    //                 value = it.getBoolValue() ? it.getBoolValue().getValue() : false;
    //                 console.log(value);
                    
    //               } else if (it.getType() === 'date') {
    //                 console.log(it.getStringValue().getValue());
                    
    //                 value = it.getStringValue() ? convertDateGrpcToFormatDate(it.getStringValue().getValue()) : '';
    //                 console.log(value);
    //               } else if (it.getType() === 'select' || it.getType() === 'multiselect') {
    //                 if (it.getSelectValueList().length) {
    //                   console.log(it.getSelectValueList());
                      
    //                   value = it.getSelectValueList().map(val => {
    //                     console.log(val.getIntVal());
                        
    //                     return {
    //                       name: val.getValue() ? val.getValue() : '',
    //                       id: val.getIntVal() ? val.getIntVal() : ''
    //                     }
    //                   })
    //                 }
                    
    //               } else {
    //                 value = it.getStringValue() ? it.getStringValue().getValue() : ''
    //               }
    //             return {
    //               name: it.getAttributeLabel() ? it.getAttributeLabel().getValue() : '',
    //               id: it.getId() ? it.getId(): '',
    //               type: it.getType() ? it.getType() : '',
    //               code: it.getCode() ? it.getCode() : '',
    //               value,
    //               is_required: it.getIsRequired()
    //             }
    //           }) || [],
    //         }
    //       })
    //     }
    //     console.log(user.getCategoryList());
    //     let alert_item = {};
    //     if (user.getAlerts()) {
    //       alert_item = {
    //         id: user.getAlerts().getId() || '',
    //         description: user.getAlerts().getAlertDescription().getValue() || '',
    //         title: user.getAlerts().getTitle().getValue() || '',
    //         content: user.getAlerts().getContent().getValue() || '',
    //         list_document: user.getAlerts().getDocumentsList() || [],
    //         created_at: user.getCreatedAt() || '',
    //         updated_at: user.getUpdatedAt().getValue() || ''
    //       }
    //     }
    //     const data = {
    //       id: user.getId() ? user.getId() : '',
    //       bar_code: user.getBarcode() ? user.getBarcode() : '',
    //       attribute_set_id: user.getCustomAttributeSetId() ? user.getCustomAttributeSetId().getValue() : '',
    //       category_id: user.getCategoryList() ? user.getCategoryList()[user.getCategoryList().length - 1].getId() : '',
    //       group_attribute_list,
    //       gln_id: user.getEnterprise() ? user.getEnterprise().getId() : '',
    //       alert_item
    //     };
    //     console.log(data);
        
    //     return data;
    //   }),
    // );
  }

  checkBarCode(bar_code) {
    // return from(this.datahub.checkBarCodeExits(bar_code));
  }

  assignEnterprise(object) {
    // return from(this.datahub.assignEnterprise(object))
  }

  assignCategory(object) {
    // return from(this.datahub.assignCategory(object))
  }
}
