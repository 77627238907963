
    <div class="com-action-table">
      <mat-form-field class="select-action">
        <mat-label>Hành động</mat-label>
        <mat-select>
          <mat-option value="accept_scans">Cho quét</mat-option>
          <mat-option value="not_accept_scans">Không cho quét</mat-option>
          <mat-option value="hidden_in_icheck">Đã ẩn bởi DNSH</mat-option>
          <mat-option value="assign_intensity">Gán thuộc tính đánh giá</mat-option>
          <mat-option value="export_excel">Xuất excel</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button class="primary btn-submit">Thực hiện</button>
    </div>
