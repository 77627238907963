<div>
    <form [formGroup]="attributeForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group inline-1">
                    <div class="label-form">Tên thuộc tính (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Tên thuộc tính" formControlName="name" maxlength="255">
                    </mat-form-field>
            </div>
            <div class="form-group inline-2">
                <div class="label-form">Mã phân biệt thuộc tính (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                <input matInput placeholder="Mã thuộc tính" formControlName="code" maxlength="40">
                </mat-form-field>
                <div class="label-warning" *ngIf="!this.attributeForm.get('code').valid && this.attributeForm.get('code').value && data.action === 'create'">
                  <i class="error-label">Mã thuộc tính sai định dạng</i>
                </div>
            </div>
            
            <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="true"
                labelPosition="before"
                style="margin-right: 10rem;"
                formControlName="isUniqueVal"
                >
                
                Trạng thái duy nhất
            </mat-slide-toggle>
            <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="true"
                labelPosition="before"
                formControlName="isRequired"
                >
                
                Trạng thái bắt buộc
            </mat-slide-toggle>
            <div class="example-margin">Loại giá trị (*)</div>
            <mat-radio-group class="group-radio" formControlName="valType" [disabled]="isAssignProduct">
                <div class="group-radio-row">
                  <mat-radio-button class="example-margin" value="text">Dòng văn bản</mat-radio-button>
                  <mat-radio-button class="example-margin" value="textarea">Khung văn bản</mat-radio-button>
                  <mat-radio-button class="example-margin" value="htmleditor">Trình soạn thảo văn bản</mat-radio-button>
                  <mat-radio-button class="example-margin" value="image">Hình ảnh</mat-radio-button>  
                  <mat-radio-button class="example-margin" value="group">Nhóm thông tin</mat-radio-button> 
                  <mat-radio-button class="example-margin" value="date">Ngày giờ</mat-radio-button>    
                </div>    
                <div class="group-radio-row" style="padding-left: 70px;">
                    <mat-radio-button class="example-margin" value="number">Số</mat-radio-button>
                    <mat-radio-button class="example-margin" value="integer">Số nguyên dương</mat-radio-button>    
                    <mat-radio-button class="example-margin" value="boolean">Đúng/Sai</mat-radio-button>
                    <mat-radio-button class="example-margin" value="select">Lựa chọn đơn</mat-radio-button>
                    <mat-radio-button class="example-margin" value="multiselect">Lựa chọn nhiều </mat-radio-button>   
                </div>    
                    
            </mat-radio-group>
            <div *ngIf="attributeForm.value.valType === 'select' || attributeForm.value.valType === 'multiselect'">
              <mat-form-field class="example-chip-list" appearance="outline" style="width: 100%;font-size: 14px;">
                <mat-chip-list #chipList aria-label="Fruit selection">
                <mat-chip *ngFor="let attribute of this.attributeForm.get('dataSelect').value" [selectable]="true" [removable]="true" (removed)="removeAttribute(attribute)">
                  {{attribute.value}}
                <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input placeholder="Nhập giá trị để lựa chọn"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addAttribute($event)">
                </mat-chip-list>
              </mat-form-field>
              <div class="label-warning" >
                <i class="error-label" *ngIf="!this.attributeForm.get('dataSelect').valid">Giá trị để lựa chọn phải từ 2 giá trị trở lên</i>
              </div>
            </div>
            
            <mat-slide-toggle
                class="example-margin"
                color="primary"
                labelPosition="before"
                style="margin-right: 9rem;"
                formControlName="addCustomVal"
                *ngIf="attributeForm.value.valType === 'select' || attributeForm.value.valType === 'multiselect'">
                
                Được thêm giá trị tùy biến
            </mat-slide-toggle>
            <div class="form-control">
              <div class="example-margin">Mô tả</div>
              <mat-form-field class="example-full-width" appearance="outline" style="width:100%">           
                  <textarea matInput formControlName="desc" maxLength="255"></textarea>                
              </mat-form-field>  
          </div>
          <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="false"
                labelPosition="before"
                style="margin-right: 10rem;"
                formControlName="contributable"
                >
                
                Cho phép đóng góp
            </mat-slide-toggle>
                
            
            
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 0px 30px;" class="footer-dialog">
        <button mat-button type="button" (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-create" [disabled]="!attributeForm.valid" *ngIf="data.action === 'create'">Tạo mới</button>
        <button mat-raised-button type="submit" class="btn-create" [disabled]="!attributeForm.valid" *ngIf="data.action === 'update'">Sửa</button>
        </mat-toolbar>
    </form>
</div>
