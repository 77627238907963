import { createAction, props } from '@ngrx/store';


export const loadAttributes = createAction(
  '[Attribute Page] Load Attributes',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadAttributesSuccess = createAction(
  '[Attribute Page] Load Attributes Success',
  props<{ total: number, rows: any }>()
);

export const loadAttributesFailure = createAction(
  '[Attribute Page] Load Attributes Failure',
  props<{ error: any }>()
);

export const addAttribute = createAction(
  '[Attribute Page] Add Attribute',
  props<{ attributeObject: any }>()
);

export const addAttributeSuccess = createAction(
  '[Attribute Page] Add Attribute Success',
  props<{ attribute: any }>()
);

export const addAttributeFailure = createAction(
  '[Attribute Page] Add Attribute Failure',
  props<{ error: any }>()
);


export const updateAttribute = createAction(
  '[Attribute Page] Update Attribute',
  props<{ attributeObject: any }>()
);

export const updateAttributeSuccess = createAction(
  '[Attribute Page] Update Attribute Success',
  props<{ attribute: any }>()
);

export const updateAttributeFailure = createAction(
  '[Attribute Page] Update Attribute Failure',
  props<{ error: any }>()
);

export const getDetailAttribute = createAction(
  '[AttributeProduct Page] Get Detail Attribute',
  props<{ id: any }>()
);

export const getDetailAttributeSuccess = createAction(
  '[AttributeProduct Page] Get Detail Attribute Success',
  props<{ attribute: any }>()
);

export const getDetailAttributeFailure = createAction(
  '[AttributeProduct Page] Get Detail Attribute Failure',
  props<{ error: any }>()
);

