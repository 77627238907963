
<dash-filter-bar (filterChange) = "filterData($event)" [availableFilters]="availableFilters"></dash-filter-bar>
<div class="row-button">
    <button mat-raised-button class="green btn btn-create" (click) = "createWarningProduct()">Thêm mới</button>
    <button mat-raised-button style="color: white; background: #FF0000;" (click) = "skipWarning()">Bỏ cảnh báo</button>
</div>
<mat-card style=" margin: 0; padding: 0">

  <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select" sticky>
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="id" sticky>
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef> Ảnh đại diện </th>
          <td mat-cell *matCellDef="let element"> <img onError="src='/assets/stran.png'" [src]="element?.image" /> </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Tên sản phẩm </th>
          <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
        </ng-container>
    
        <ng-container matColumnDef="gln">
          <th mat-header-cell *matHeaderCellDef> Doanh nghiệp sở hữu </th>
          <td mat-cell *matCellDef="let element"> {{ element.gln }} </td>
        </ng-container>

        <ng-container matColumnDef="warning_description">
          <th mat-header-cell *matHeaderCellDef> Nội dung cảnh báo </th>
          <td mat-cell *matCellDef="let element"> {{ element.warning_description }} </td>
        </ng-container>

        <ng-container matColumnDef="paper">
          <th mat-header-cell *matHeaderCellDef> Giấy tờ </th>
          <td mat-cell *matCellDef="let element"> <a (click)="openDetailPaper(element)">Chi tiết</a> </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef> Ngày tạo </th>
          <td mat-cell *matCellDef="let element"> {{ element.created_at }} </td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef> Thời gian cập nhật gần nhất</th>
          <td mat-cell *matCellDef="let element">{{ element.updated_at }}</td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>Hành động</th>
          <td mat-cell *matCellDef="let element" class="row-action">
            <mat-icon style="color: #0000ff;" (click) = "updateWarningProduct(element)">edit</mat-icon>
            <mat-icon style="color: #fe1c1c;" (click) = "removeWarningProduct(element)">delete_outline</mat-icon>
          </td>

        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
      
    </div>
    <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        (page)="handlePage($event)"
        [length]="totalSize"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
      ></mat-paginator>
  </mat-card>
  