import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromGln from '../../reducers';
import {
  GlnActions
} from '../../actions';
import { Observable } from 'rxjs';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import { UploadComponent } from 'src/app/shared/upload/upload.component';

@Component({
  selector: 'ic-gln-modal',
  templateUrl: './gln-modal.component.html',
  styleUrls: ['./gln-modal.component.scss']
})
export class GlnModalComponent implements OnInit, AfterViewInit {

  glnForm : any;
  data: any;
  nations$: Observable<any>;
  cities$: Observable<any>;
  districts$: Observable<any>;
  page$: Observable<any>;
  pageSize$: Observable<any>;
  page: number;
  pageSize: number;
  avatar = [];
  backgroundImage = [];


  constructor(private formBuilder: FormBuilder,
    // public dialogRef: MatDialogRef<GlnModalComponent>,
    private store: Store<fromGln.State>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog) { 
      this.data = data;
      this.nations$ = store.pipe(select(fromGln.selectGlnListNation));
      this.cities$ = store.pipe(select(fromGln.selectGlnListCity));
      this.districts$ = store.pipe(select(fromGln.selectGlnListDistrict));
      this.page$ = store.pipe(select(fromGln.selectPage));
      this.pageSize$ = store.pipe(select(fromGln.selectPageSize));
    }

  ngOnInit(): void {
    // console.log(this.data.element);
    
    this.glnForm = this.formBuilder.group({
      name: [this.data?.element?.name || '', [Validators.required, noWhitespaceValidator]],
      prefix_code: [this.data?.element?.prefix_code || ''],
      gln_code: [ this.data?.element?.gln_code || '', [Validators.required, noWhitespaceValidator]],
      gln_type: [ this.data?.element?.gln_type || '', Validators.required],
      tax_code: [ this.data?.element?.tax_code || '', [Validators.required, noWhitespaceValidator]],
      nation: [ this.data?.element?.nation_code || '', Validators.required],
      address: [this.data?.element?.address || '', [Validators.required, noWhitespaceValidator]],
      city: [ this.data?.element?.city_code || '', Validators.required],
      district: [ this.data?.element?.district_id || '', Validators.required],
      phone: [this.data?.element?.phone || null, Validators.pattern('[0-9]*')],
      email: [ this.data?.element?.email || '',Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')],
      website: [this.data?.element?.website || ''],
      avatar: [this.data?.element?.avatar || []],
      background_image: [this.data?.element?.background_image || []],
    })

    if (this.data.action === 'update') {
      // console.log(this.data);
      if (this.data.element.avatar) {
        const avatar  = this.data.element.avatar;
        const avatarObject = {
          type: '',
          url: avatar,
        };

        this.glnForm.controls.avatar.setValue(avatar);
        this.avatar.push(avatarObject);
      } else {
        this.glnForm.controls.avatar.setValue([]);
        this.avatar = [];
      }
      if (this.data.element.background_image) {
        const backgroundImage  = this.data.element.background_image;
        const backgroundObject = {
          type: '',
          url: backgroundImage,
        };
        this.glnForm.controls.background_image.setValue(backgroundImage);
        this.backgroundImage.push(backgroundObject);
      } else {
        this.glnForm.controls.background_image.setValue([]);
        this.backgroundImage = [];
      }
    }

    // console.log(this.glnForm.value);
    if(this.glnForm.value.nation) {
      this.loadCity(this.glnForm.value.nation);
      this.loadDistrict(null);
    }
    if(this.glnForm.value.city) {
      this.loadDistrict(this.glnForm.value.city)      
    }
    this.checkNation();
    this.loadNation();

  }

  ngAfterViewInit() {
    
  }

  checkNation() {
    this.glnForm.get("nation").valueChanges.subscribe(selectedNation => {
      const districtControl = this.glnForm.get('district');
      const cityControl = this.glnForm.get('city');
      if (selectedNation !== 241) {
        console.log('1312323');
        districtControl.setValidators(null);
        cityControl.setValidators(null);
        return;
      }
      districtControl.setValidators(Validators.required);
      cityControl.setValidators(Validators.required);
    })
  }

  changeNation(event) {
    //  console.log('nation');
    this.loadCity(event.value)
    this.loadDistrict(null)
    this.glnForm.controls.city.setValue(null);
    this.glnForm.controls.district.setValue(null);
  }

  changeCity(event) {
    // console.log('city',event.value);
    this.loadDistrict(event.value)
  }

  loadNation() {
    this.store.dispatch(GlnActions.loadNation({request: {
      type: 0
    }}))
  }

  loadCity(parent_code) {
    this.store.dispatch(GlnActions.loadCity({request: {
      type: 1,
      parent_code,
    }}));
  }

  loadDistrict(parent_code) {
    this.store.dispatch(GlnActions.loadDistrict({request: {
      type: 2,
      parent_code,
    }}));
  }

  closeDialogClick() {
    // this.dialogRef.close();
  }

  onFormSubmit() {
    // console.log(this.glnForm.value)
    if (this.data.action === 'create') {
      this.store.dispatch(GlnActions.addGln({glnObject: this.glnForm.value}));
      // this.dialogRef.close('accept');
    } else {
      this.glnForm.value.id = this.data.element.id;
      // console.log(this.glnForm.value);
      this.store.dispatch(GlnActions.updateGln({glnObject: this.glnForm.value}));
      // this.dialogRef.close('accept');
    }
    
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        if (event === 'avatar') {
          const data = result.data;
          data.map(res => {
          this.avatar = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);
          
          this.avatar.push(value);
          this.glnForm.controls.avatar.setValue(res.url);
        });
        } else {
          const data = result.data;
          data.map(res => {
          this.backgroundImage = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);
          
          this.backgroundImage.push(value);
          this.glnForm.controls.background_image.setValue(res.url);
        })
      }
    };
  })
  }

  onRemoveAvatar(field: string, index: number) {
    this.glnForm.controls.avatar.setValue('');
    this.avatar = [];
  }

  onRemoveBackground(field: string, index: number) {
    this.glnForm.controls.background_image.setValue('');
    this.backgroundImage = [];
  }
}
