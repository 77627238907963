import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  AfterViewInit,
  Inject
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { Observable, Subscription, Subject, merge } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { GlnActions, GlnListPageActions, GlnPageActions } from '../../actions';
import * as fromGln from '../../reducers';
import { GlnImportModalComponent } from '../gln-import-modal/gln-import-modal.component';
import { formatDate, buildImageUrls } from '../../../shared/common';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog, MDCDataTable, MDCDataTableRowSelectionChangedEvent } from '@angular-mdc/web';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router} from '@angular/router';
import { GlnService } from '@gln/services/gln.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { ImportGlnComponent } from '../import-gln/import-gln.component';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';

declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
declare function searchTeko(info)

@Component({
  selector: 'ic-gln-list',
  templateUrl: './gln-list.component.html',
  styleUrls: ['./gln-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterAttributeGln();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class GlnListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  set rows(rows: any) {
    this._rows = rows.map(z => {
      if (z.logo) {
        return {...z , logo : buildImageUrls(z.logo), };
      }
      return z;
    });
  }
  get rows(): any {
    return this._rows;
  }

  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }

  get displayedColumns(): string[] {
    const availableColumns = this.availableColumns.map(c => c.key);
    return this._displayedColumns.map(column => {
      return availableColumns.indexOf(column) > -1 ? column : null;
    }).filter(item => item);
  }

  get sortedAvailableColumns(): string[] {
    return this.availableColumns.sort((a, b) => a.order - b.order).map((c: any) => c.key);
  }


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private store: Store<fromGln.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private glnService: GlnService,
    private _snackBar: MatSnackBar,
    @Inject('availableFilters') public availableFilters: [],
    private location: Location) {
      this.glns$ = store.pipe(select(fromGln.selectGlnListPageResults));
      this.page$ = store.pipe(select(fromGln.selectPage));
      this.pageSize$ = store.pipe(select(fromGln.selectPageSize));
      this.nations$ = store.pipe(select(fromGln.selectGlnListNation));
      this.filter$ = store.pipe(select(fromGln.selectGlnListPageFilter));
    }

  @Input()
  pageSize: number;

  @Input()
  total: number;
  private _rows: any = [];
  _displayedColumns: string[];

  @Output()
  deleteItems = new EventEmitter<any>();

  @Output()
  load = new EventEmitter<any>();
  availableColumns = [
    { key: 'select', label: 'Select', order: 1 },
    { key: 'id', label: 'ID', order: 2 },
    { key: 'name', label: 'Name', order: 3 },
    { key: 'image', label: 'Image', order: 4 },
    { key: 'order_sort', label: 'Order Sort', order: 5 },
    { key: 'rating_attribute', label: 'Rating Attribute', order: 6 },
    { key: 'product_attribute', label: 'Product Attribute', order: 7 },
    { key: 'action', label: 'Action', order: 8 },
  ];

  changesSubscription: Subscription;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page = 0;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  glns$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  enterpriseSelected = [];
  filter: any = {};
  disableButtonExport = true;
  isLoading = false;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;

  @ViewChild(FilterBarNewComponent, {static: true}) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MDCDataTable, {static: true}) table: MDCDataTable;

  desserts = [
    {checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty'},
    {checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more'},
    {checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor'}
  ];

  ngOnInit(): void {
    enterScreenView(TekoValueDto.ENTERPRISE_LIST.SCREEN_NAME, TekoValueDto.ENTERPRISE_LIST.CONTENT_TYPE);
    this.selection.changed.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      // console.log(item);
      if (this.selection.selected.length) {
        this.disableButtonExport = false;
      } else {
        this.disableButtonExport = true;
      }

    });
    this.filterBar?.filterChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      // console.log(it);
      this.filter = it;
      if (it.length) {
        this.disableButtonExport = false;
      } else {
        this.disableButtonExport = true;
      }
    });
    this.glns$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      if (item.length && !this.isFirstPage) {
        this.checkStatus(item);
      }
    });
    this.selection.changed.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.selected = this.selection.selected;

    });
  }

  ngAfterViewInit() {
    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      console.log('page', item);

      this.page = item;
    });
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      console.log('pageSize', item);
      this.pageSize = item;
    });
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      // this.filterBar.patchValueForm(item);
    });
    // console.log(this.filterBar.filterForm.value);
    this.paginator.pageIndex = this.page - 1;
    this.paginator.pageSize = 10;
    // console.log(this.page, this.pageSize);
    // console.log(this.paginator);

    const filterChange = this.filterBar.filterChange;
    const pageChange = merge(
      this.paginator.page,
      this.paginator.initialized,
    );
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((res: any) => {
        const object = {
          ...this.filterBar.filterForm?.value,
          create_to_date: this.filterBar.filterForm?.value?.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
          create_from_date: this.filterBar.filterForm?.value?.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
          update_to_date: this.filterBar.filterForm?.value?.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
          update_from_date: this.filterBar.filterForm?.value?.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
        };
        // console.log(object);
        searchTeko({...this.filter,      page: this.page + 1, pageSize: this.pageSize,})

        this.load.emit([
          this.paginator,
          object,
          {
            page: this.page,
            pageSize: this.pageSize
          }
        ]);
      });
    this._changeDetectorRef.detectChanges();
  }

  changePage(event: Event) {
    this.isFirstPage = false;
    // console.log(this.rows);
  }
  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.id).indexOf(el.id) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    // console.log(rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      if (rows) {
        if (res.length < rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      } else {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.id === row.id);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.id === row.id);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter(item => item.id !== row.id);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
    });
    // console.log(this.selected);
    // console.log(this.rows);
    // console.log(res);

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }



  importGln() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Import doanh nghiệp',
      action: 'create',

    };
    dialogConfig.disableClose = true;
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_LIST.REGION_NAME, TekoValueDto.ENTERPRISE_LIST.CONTENT_NAME.IMPORT, '', {});
    clickTeko(click);
    const dialogRef = this.dialog.open(ImportGlnComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // const id = this.route.snapshot.paramMap.get('id');
        setTimeout(() => {
          this.loadGln(1, 10);
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // this.selectionChangedEvent = event;
    // this.desserts[event.index].checked = event.selected;
    // console.log(event);
    // this.selection.toggle(dessert);
  }

  onSelectedAll(): void {
    // this.desserts.forEach(_ => _.checked = true);
  }

  onUnselectedAll(): void {
    // this.desserts.forEach(_ => _.checked = false);
  }


  loadGln(page, pageSize) {
    // console.log(page, pageSize);

    this.store.dispatch(
      GlnListPageActions.loadGlns({
        page,
        pageSize,
        filter: {}
      })
    );
  }

  updateGln(element) {

    this.router.navigate(['/gln/update', element.id]);
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const object = {
        offset: event.pageIndex * event.pageSize,
        limit: event.pageSize,
    };
    // console.log(event)
    // this.store.dispatch(GlnActions.loadGln({filter: object}));
  }

  filterData(event) {
    // console.log(event)
    const objectSearch = {};
    event.forEach(item => {
        objectSearch[item.key] = item.value.value;
    });
    const object = {
      offset: 0,
      limit: 5,
      ...objectSearch,
    };
    // console.log(object);
    // this.store.dispatch(GlnListPageActions.loadListGlnByParent({filter: {},limit: 10,  offset: 0, parent_id: category.id}));
    this.store.dispatch(GlnActions.searchGln({objectSearch: object}));
  }

  removeGln(element) {
    // console.log(element);
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có đồng ý muốn xóa GLN này không?'
    });
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_LIST.REGION_NAME, TekoValueDto.ENTERPRISE_LIST.CONTENT_NAME.DELETE, '', {});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.glnService.deleteEnterprise(element.id).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmRemoveGln(element.id);
          } else {
            this._snackBar.open('Xóa GLN thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.loadGln(this.page, this.pageSize);
            }, 500);
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });

  }

  confirmRemoveGln(id) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = {
      params: {
          requestType: 1
        }
    };
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.glnService.deleteEnterprise(id, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Xóa GLN thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadGln(this.page, this.pageSize);
          }, 500);
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
      }
      console.log('The dialog was closed');
    });
  }

  exportExcel(): void {
    if (this.isLoading) { return; }
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Tổng số bản ghi có thể export được là 10000 bản ghi.Bạn có muốn tiếp tục export không?'
    });
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_LIST.REGION_NAME, TekoValueDto.ENTERPRISE_LIST.CONTENT_NAME.EXPORT, '', {});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const ids = this.selected.map(item => item.id).join(',');
        // console.log(this.filter);
        const filter = {
          ...this.filter,
          create_to_date: this.filter.create_to_date ? formatDate(this.filter.create_to_date) : '',
          create_from_date: this.filter.create_from_date ? formatDate(this.filter.create_from_date) : '',
          update_to_date: this.filter.update_to_date ? formatDate(this.filter.update_to_date) : '',
          update_from_date: this.filter.update_from_date ? formatDate(this.filter.update_from_date) : '',
        };
        this.isLoading = true;
        this.glnService.getPDF({filter, ids})
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(x => {
              this.isLoading = false;
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
              const newBlob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              const link = document.createElement('a');
              link.href = data;
              link.download = 'enterprise_export_' + new Date().getTime();
                // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

              setTimeout(function() {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            }, err => {
              console.log(err);
              this._snackBar.open('Thao tác không hợp lệ', 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              this.isLoading = false;
            });
        this.selected = [];
        this.isSelectedAll = false;
        this.isIndeterminate = false;
      }
    });

  }
  importExcel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Import mã GLN bằng file excel',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(GlnImportModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== close) {
        this.loadGln(1, 10);
      }
      console.log('The dialog was closed');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
