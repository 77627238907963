
<!-- Start Feature bar -->
<dash-feature-bar>
  <dash-feature-bar-row>
    <dash-feature-bar-section>
      <h1 dashFeatureBarTitle>Quản lý doanh nghiệp</h1>
    </dash-feature-bar-section>
  </dash-feature-bar-row>
</dash-feature-bar>
<!-- End Feature bar -->
<!-- Start Filter bar -->
<ic-filter-bar (resetFormFilter)="resetSelected()" [availableFilters]="availableFilters"></ic-filter-bar>

<!-- End Filter bar -->



<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button mdc-button raised class="button-table" [routerLink]="['create']">Thêm mới</button>
        <button mdc-button raised class="button-table" (click)="importGln()">Import</button>
        <!-- <button mdc-button raised class="button-table" (click)="importExcel()">Import excel</button> -->
        <button mdc-button raised class="button-table" (click)="exportExcel()"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
        </mat-spinner></mat-icon>Export</button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell numeric name="image">Ảnh đại diện</th>
            <th mdcDataTableHeaderCell name="name">Tên doanh nghiệp sở hữu</th>
            <th mdcDataTableHeaderCell name="status" [width]="500">Trạng thái xác thực</th>
            <th mdcDataTableHeaderCell name="nation">Quốc gia</th>
            <th mdcDataTableHeaderCell name="city">Tỉnh thành</th>
            <th mdcDataTableHeaderCell name="district">Quận huyện</th>
            <th mdcDataTableHeaderCell name="district">Phường xã</th>
            <th mdcDataTableHeaderCell name="address">Địa chỉ</th>
            <th mdcDataTableHeaderCell name="info">Thông tin liên hệ</th>
            <th mdcDataTableHeaderCell name="type">Loại GLN</th>
            <th mdcDataTableHeaderCell name="user_created">Người tạo</th>
            <th mdcDataTableHeaderCell name="user_updated">Người cập nhật</th>
            <th mdcDataTableHeaderCell name="updated_at">Xác thực bởi</th>
            <th mdcDataTableHeaderCell name="created_at">Thời gian tạo</th>
            <th mdcDataTableHeaderCell name="updated_at">Thời gian cập nhật gần nhất</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)">
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id" (dblclick)="updateGln(row)">{{row.id}}</td>
            <td mdcDataTableCell name="image" (dblclick)="updateGln(row)"><img onError="src='/assets/stran.png'" [src]="row?.logo" /></td>
            <td mdcDataTableCell name="name" (dblclick)="updateGln(row)"  >
              <div style="white-space: pre-line;" class="text-break" [title]="row.name">{{row.name}}</div>
              <div style="color: #0000FF;">GLN : {{row.glnCode}}</div>
              <p style="width: 400px">Prefix : <span [title]="row.prefixCode">{{row.prefixCode}}</span></p>

            </td>
            <td mdcDataTableCell name="status" (dblclick)="updateGln(row)">
              <span *ngIf="row.verifyStatus === 1" class="verified"> Đã Xác thực</span>
              <span *ngIf="row.verifyStatus === 2" class="not_verified">Chưa xác thực</span>
            </td>
            <td mdcDataTableCell name="nation" (dblclick)="updateGln(row)">{{row.nationalName}}</td>
            <td mdcDataTableCell name="area" (dblclick)="updateGln(row)">{{row.provinceName}}</td>
            <td mdcDataTableCell name="area" (dblclick)="updateGln(row)">{{row.districtName}}</td>
            <td mdcDataTableCell name="area" (dblclick)="updateGln(row)">{{row.wardName}}</td>
            <td mdcDataTableCell name="address" (dblclick)="updateGln(row)">
              <span class="text-break" [title]="row.address">{{row.address}}</span>
            </td>
            <td mdcDataTableCell name="info" (dblclick)="updateGln(row)">
              <div>MST: {{ row.taxCode }} </div>
              <div>Phone: {{ row.phone }} </div>
              <div>Email: {{ row.email }} </div>
              <div>Website: {{ row.website }} </div>
            </td>
            <td mdcDataTableCell name="type" (dblclick)="updateGln(row)">
              <span *ngIf = "row.glnType === 'enterprise_internal_code'">Mã nội bộ doanh nghiệp</span>
              <span *ngIf = "row.glnType === 'gs1_official_code'">Mã GS1 chính thống</span>
              <span *ngIf = "row.glnType === 'gs1_unofficial_code'">Mã GS1 không chính thống</span>
              <span *ngIf = "row.glnType === 'icheck_internal_code'">Mã nội bộ iCheck</span>
            </td>
            <td mdcDataTableCell name="created_by" (dblclick)="updateGln(row)">{{row?.createdBy?.username}} <span *ngIf="row?.createdBy?.username && row?.createdBy?.phone">-</span> {{row?.createdBy?.phone}}</td>
            <td mdcDataTableCell name="updated_by" (dblclick)="updateGln(row)">{{row?.updatedBy?.username}} <span *ngIf="row?.updatedBy?.username && row?.updatedBy?.phone">-</span> {{row?.updatedBy?.phone}}</td>
            <td mdcDataTableCell name="updated_at" (dblclick)="updateGln(row)">{{row?.source}}</td>
            <td mdcDataTableCell name="created_at" (dblclick)="updateGln(row)">{{row.createdAt}}</td>
            <td mdcDataTableCell name="updated_at" (dblclick)="updateGln(row)">{{row.updatedAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)="updateGln(row)" class="btn-edit">
                <mdc-icon>edit</mdc-icon>
              </button>
              <button mdcIconButton (click) = "removeGln(row)"class="btn-delete">
                <mdc-icon>delete</mdc-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
                   (page)="changePage($event)"
    ></mat-paginator>
  </mdc-card>
</div>
