import { createReducer, on, Action } from '@ngrx/store';
import { AlertList } from './../models/alert-list.model';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AlertActions, AlertPageActions, AlertApiActions } from '../actions';

export const alertFeatureKey = 'alerts';

export interface State extends EntityState<AlertList> {
  selectedAlertId: string | null;
  total: number;
}

export const adapter: EntityAdapter<AlertList> = createEntityAdapter<AlertList>({
  selectId: (alert: any) => {
    return alert.id;
  }
})

export const initialState: State = adapter.getInitialState({
  selectedAlertId: null,
  total: 0
})

export const alertReducer = createReducer(
  initialState,
  on(
    AlertActions.loadActionSuccess,
    (state, { alert }) => {
      return adapter.upsertMany(alert, state)
    }
  ),
  // on(AlertAction.updateAlert, (state, { categoryObject }) => {
  //   console.log(categoryObject);

  //   return adapter.updateOne({ id: categoryObject.id, changes: categoryObject }, state);
  // }),
  // on(AlertAction.updateAlertFailure, (state, { error }) => {
  //   return {
  //     ...state,
  //     error
  //   };
  // }),
  on(AlertActions.addAlert, (state, { alertObject }) => {
    // console.log(alertObject);
    return adapter.addOne(alertObject, state);
  }),
  on(AlertActions.addAlertFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(AlertActions.deleteAlert, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(AlertActions.deleteAlertFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(AlertActions.updateAlert, (state, { alertObject }) => {
    // console.log(alertObject);
    
    return adapter.updateOne({id: alertObject.id, changes: alertObject}, state);
  }),
  on(AlertActions.updateAlertFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(AlertPageActions.loadAlertsSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  on(AlertApiActions.loadAlertsSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),
  on(AlertPageActions.loadListAlertByParentSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  on(AlertApiActions.loadAlertByParentSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),
  // on(AlertAction.searchAlertSuccess, (state, { categories }) => {
  //   console.log(categories);

  //   return adapter.upsertMany(categories.list, state);
  // }),
  // on(AlertAction.searchAlertFailure, (state, { error }) => {
  //   return {
  //     ...state,
  //     error
  //   };
  // })
);

export function reducer(state: State | undefined, action: Action) {
  return alertReducer(state, action);
}

export const getSelectedAlertId = (state: State) => state.selectedAlertId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectAlertIds = selectIds;

// select the dictionary of user entities
export const selectAlertEntities = selectEntities;

// select the array of users
export const selectAllAlerts = selectAll;

// select the total user count
export const selectAlertTotal = selectTotal;