import {Injectable} from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';

@Injectable()
export class FilterFormFactory {
  constructor() {
  }
  createFormGroup(availableFilters) {
    let group = {};
    availableFilters.forEach(({key}) => {
      switch (key) {
        case 'scan':
          group = {...group, scan: new FormControl(), scan_to : new FormControl(), scan_from : new FormControl()  };
          break;
        case 'review':
          group = {...group, review_to: new FormControl(), review_from : new FormControl()  };
          break;
        case 'price':
          group = {...group, price_to: new FormControl(), price_from : new FormControl()  };
          break;
        case 'question_count':
          group = {...group, question_count_to: new FormControl(), question_count_from : new FormControl()  };
          break;
        case 'finish_at':
          group = {...group, finish_at_to_date: new FormControl(), finish_at_from_date : new FormControl()  };
          break;
        case 'create':
          group = {...group, create_to_date: new FormControl(), create_from_date : new FormControl()  };
          break;
        case 'update':
          group = {...group, update_to_date : new FormControl(), update_from_date : new FormControl()  };

          break;
        case 'category_id':
          group = {...group, category_id : new FormControl(),  inputSearch : new FormControl(), };
          break;
        case 'date_by_user':
          group = {...group, update_to_date_by_user : new FormControl(), update_from_date_by_user : new FormControl()  };
          break;
        case 'scan_review_updated':
          group = {...group, scan_review_updated_to_date : new FormControl(), scan_review_updated_from_date : new FormControl()  };
          break;
        default:
          // @ts-ignore
          group[key] = new FormControl('');
          break;
      }
    });
    return  new FormGroup(group);
  }


}

export function cleanFilterInput(availableFilters , object) {
  const listKeys = Object.keys(object);
  return   availableFilters.map(z => {
    let result = {};
    if (listKeys.indexOf('scan') !== -1 && z.key === 'scan') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.scan_from, object.scan_to)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('review') !== -1 && z.key === 'review') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.review_from, object.review_to)}`;
      result = {...z, ...result, show, displayName};
    }

    if (listKeys.indexOf('price') !== -1 && z.key === 'price') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.price_from, object.price_to)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('question_count') !== -1 && z.key === 'question_count') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.question_count_from, object.question_count_to)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('finish_at') !== -1 && z.key === 'finish_at') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.finish_at_from_date, object.finish_at_to_date)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('create') !== -1 && z.key === 'create') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.create_from_date, object.finish_at_to_date)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('update') !== -1 && z.key === 'update') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.update_from_date, object.update_to_date)}`;
      result = {...z, ...result, show, displayName};
    }
    if (listKeys.indexOf('expire_at') !== -1 && z.key === 'expire_at') {
      const show = true;
      const displayName = `${z.name} ${this.getDisplayName(object.expire_at_from_date, object.expire_at_to_date)}`;
      result = {...z, ...result, show, displayName};
    }

    return {...z, ...result};
    // if ( listKeys.indexOf('productId') !== -1) {
    //   this.productService.getDetailProduct(object.productId)
    //       .subscribe((res: any) => this.listProduct = [res.data, ...this.listProduct]);
    // }
    // if (listKeys.indexOf('enterpriseId') !== -1) {
    //   this.glnService.getDetailEnterprise(object.enterpriseId)
    //       .subscribe((res: any) => this.listGln = [res.data, ...this.listGln]);

  });
}
function getDisplayName(from: string , to: string) {
  const fromParam = from ? ` từ ${from}` : '';
  const toParam = to ? ` tới ${to}` : '';
  return `${fromParam + toParam}`;
}
