import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { CategoryListPageActions } from '@category/actions';
import * as fromCategory from '@category/reducers';
import { select, Store } from '@ngrx/store';
import { ProductActions, ProductListPageActions } from '@products/actions';
import * as fromProduct from '@products/reducers';
import { onlyUnique } from '@src/app/shared/common';
import { checkFormatBarcode } from '@src/app/shared/validate/validate';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {ModalScroll} from "@src/app/shared/common/modal-scroll";

@Component({
  selector: 'ic-product-document-modal',
  templateUrl: './product-document-modal.component.html',
  styleUrls: ['./product-document-modal.component.scss']
})
export class ProductDocumentModalComponent extends ModalScroll implements OnInit {

  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory:any;
  currentUser: any;

  @ViewChild(MatSelect) child: MatSelect;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
  ) {
    super()
    this.data = data;
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
    this.formGroup = this.formBuilder.group({
      // barcodes: '',
      categoryId: ['', Validators.required],
      barcodes: ['', Validators.required],
      inputSearch: [''],
      allowUpdate: [false]
    });

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );

    this.categories$.subscribe(item => {
      this.listCategory = item;
    })
  }

  ngAfterViewInit() {
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listCategory.length) {
          this.storeCategory.dispatch(
            CategoryListPageActions.searchCategory({
              filter: {},
              page: 1,
              pageSize: 50
            })
          );
          this.formGroup.patchValue({inputSearch: ''})
        }
      }
    })
  }

  onFormSubmit() {
    const object = {
      categoryId: this.formGroup.value.categoryId,
      barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique),
      updateBusinessManager: this.formGroup.value.allowUpdate ? 1 : 0,
    }
    // console.log(object);
    this.store.dispatch(ProductActions.assignCategory({object}));
    this.dialogRef.close();
    // setTimeout(() => {
    //    this.loadListProduct();
    // }, 700);
  }

  clearInputSearch() {
    this.formGroup.patchValue({inputSearch: ''})

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }

  loadListProduct() {
    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: {},
        page: 1,
        pageSize: 10,
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  changeInputSearch(event) {

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event.target.value },
        page: 1,
        pageSize: 50
      })
    );
  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    })
    this.formGroup.updateValueAndValidity();
  }
}
