export class ErrorLogDto {
  errorSource: any;
  apiCall;
  apiPayload;
  responseJson;

  constructor(errorSource, apiCall, apiPayload, responseJson) {
    this.apiCall = apiCall;
    this.apiPayload = apiPayload;
    this.responseJson = responseJson;
    this.errorSource = errorSource;
  }
}
