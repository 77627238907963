import {Component, OnInit, ChangeDetectorRef, ViewChild, Inject} from '@angular/core';
import { ProductPageActions } from '@products/actions';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { MDCDataTableRowSelectionChangedEvent, MdcDialog, MDCDataTable } from '@angular-mdc/web';
import { CategoryListPageActions } from '@category/actions';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '@products/services/product.services';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import * as fromProduct from '@products/reducers';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import * as fromCategory from '@category/reducers';
import { GlnService } from '@gln/services/gln.service';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { CancelVerifyDistributorComponent } from '@products/components/product-distributor/cancel-verify-distributor/cancel-verify-distributor.component';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { clean, formatDate, buildImageUrls } from '@src/app/shared/common';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
declare function searchTeko(info)

@Component({
  selector: 'ic-gln-documnets',
  templateUrl: './gln-documnets.component.html',
  styleUrls: ['./gln-documnets.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductDocument();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class GlnDocumnetsComponent implements OnInit {

  rows: any = [];
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  total = 0;
  pageSize = 10;
  currentProduct;
  productCurrent$: Observable<any>;
  enterpriseId;

  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private storeCategory: Store<fromCategory.State>,
    private route: ActivatedRoute,
    private enterpriseService: GlnService,
    @Inject('availableFilters') public availableFilters : [],

  ) {
    this.productCurrent$ = store.pipe(select(fromProduct.selectProductCurrent));
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
    // this.glns$ = store.pipe(select(fromProduct.selectProductListResults));
    // this.length$ = store.pipe(select(fromProduct.selectTotalProduct));
    // this.filter$ = store.pipe(select(fromProduct.selectObjectFilterProduct));
    // this.loading$ = store.pipe(select(fromProduct.selectLoadingDataProduct));
    this.page$ = store.pipe(select(fromProduct.selectPage));
    this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    this.enterpriseId = this.route.snapshot.paramMap.get('id');
    // console.log(this.rows);
    this.getListDocumentOfEnterprise();
    enterScreenView(TekoValueDto.ENTERPRISE_DOCUMENT.SCREEN_NAME, TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_TYPE)
  }

  ngAfterViewInit() {
    const filterChange = this.filterBar.filterChange;
    let pageChange;
      pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
    .subscribe(() => {
    });
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);

        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const object = {
          ...this.filterBar.filterForm.value,
          expire_at_from_date: this.filterBar.filterForm.value.expire_at_from_date ? formatDate(this.filterBar.filterForm.value.expire_at_from_date) : '',
          expire_at_to_date: this.filterBar.filterForm.value.expire_at_to_date ? formatDate(this.filterBar.filterForm.value.expire_at_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
        }
        // console.log(object)
        const request = {
          filter: clean(object),
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        }
        searchTeko({... request.filter, page: request.page, pageSize: request.pageSize})

        this.getListDocumentOfEnterprise(request);
      });
  }

  getListDocumentOfEnterprise(request?){
    const object = request || {}
    this.enterpriseService.getListDocumentsOfEnterprise(this.enterpriseId, object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.rows = res.data.items;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeInputSeach(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.enterpriseDocumentId).indexOf(el.enterpriseDocumentId) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.enterpriseDocumentId).indexOf(el.enterpriseDocumentId) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      // if (rows) {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      // }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.enterpriseDocumentId === row.enterpriseDocumentId);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.enterpriseDocumentId === row.enterpriseDocumentId);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row)
    } else {
      this.selected = this.selected.filter(item => item.enterpriseDocumentId !== row.enterpriseDocumentId);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.enterpriseDocumentId).indexOf(el.enterpriseDocumentId) < 0;
    });
    // console.log(this.selected);
    // console.log(this.rows);
    // console.log(res);

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    this.router.navigate(['/products/update', row.id]);
  }


  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo mới giấy tờ',
      action: 'create',
      enterpriseId: this.enterpriseId,
      type : 'createDocumentEnterprise'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.getListDocumentOfEnterprise();

      }

      if(result === 'close'){
        return
      }
      const alert = new AlertTekoDto(ALERT.SUCCESS , ALERT.SUCCESS);
      alertEvent(alert);
      // console.log('The dialog was closed');
    });
  }

  assignDocumentFromPackage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Gán giấy tờ từ kho',
      action: 'assign',
      enterpriseId: this.enterpriseId
    };
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME,
      TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.ADD, '', {});
    clickTeko(click);
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        this.getListDocumentOfEnterprise();
      }
      if(result === 'close'){
        return;
      }
      const alert = new AlertTekoDto(ALERT.SUCCESS , ALERT.SUCCESS);
      alertEvent(alert);
    });
  }

  verifyProductDocument(row?) {

    const body = {
      enterpriseDocumentIds: row ? [row.enterpriseDocumentId] : this.selected.map(item => item.enterpriseDocumentId)
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME,
      TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.VERIFY, '', {});
    clickTeko(click);
    this.enterpriseService.appoveDocumentOfProduct(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionVerify(body)
      } else {
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        this._snackBar.open('Xác thực giấy tờ của nhà phân phối thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.getListDocumentOfEnterprise();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
      alertEvent(alert);
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionVerify(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.appoveDocumentOfProduct(this.enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {


            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);


          this._snackBar.open('Xác thực giấy tờ của nhà phân phối thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.getListDocumentOfEnterprise();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  cancelVerifyProductDocument(row?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực giấy tờ',
      question: 'Bạn có chắc chắn muốn hủy xác thực giấy tờ của doanh nghiệp này không ?',
      action: 'create',
    };
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME,
      TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.UNVERIFY, '', {});
    clickTeko(click);
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resulttttttttt', result);


      if (result !== 'close') {
        const body = {
          enterpriseDocumentIds: row ? [row.enterpriseDocumentId] : this.selected.map(item => item.enterpriseDocumentId),
          reason: result.reason
        }
        this.enterpriseService.unAppoveDocumentOfProduct(this.enterpriseId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUnVerify(this.enterpriseId,body)
          } else {
            const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
            alertEvent(alert)
            this._snackBar.open('Hủy xác thưc giấy tờ của doanh nghiệp thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.getListDocumentOfEnterprise();
            }, 500);

            this.resetSelected();
          }
        }, err => {
          const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert)
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      }
    })
  }

  confirmActionUnVerify(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.unAppoveDocumentOfProduct(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Hủy xác thực giấy tờ của nhà phân phối thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.getListDocumentOfEnterprise();
          }, 500);

          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  hiddenDocument() {
    const body = {
      ids: this.selected.map(item => item.enterpriseDocumentId).join(','),
      visible: 3
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME,
      TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.HIDE, '', {});
    clickTeko(click);
    this.enterpriseService.toggleDocumentStatusOfEnterprise(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionHidden(body)

      } else {
        this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        this.getListDocumentOfEnterprise();
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionHidden(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.toggleDocumentStatusOfEnterprise(this.enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocumentOfEnterprise();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }



  confirmActionShow(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.toggleDocumentStatusOfEnterprise(this.enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this.getListDocumentOfEnterprise();
          this.resetSelected();
          this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocumentOfEnterprise();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  showDocument() {
    const body = {
      ids: this.selected.map(item => item.enterpriseDocumentId).join(','),
      visible: 1
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME,
      TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.SHOW, '', {});
    clickTeko(click);
    this.enterpriseService.toggleDocumentStatusOfEnterprise(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionShow(body)
      } else {
        this.getListDocumentOfEnterprise();
        this.resetSelected();
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.getListDocumentOfEnterprise();
        this.resetSelected();

      }
    }, err => {
      const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
      alertEvent(alert);
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  removeDocument(row?) {
    console.log(row);

    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa giấy tờ khỏi doanh nghiệp này không ??'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const ids = row ? row.enterpriseDocumentId : this.selected.map(item => item.enterpriseDocumentId).join(',');
        let request = {
          params: {
            ids
          }
        };
        this.enterpriseService.removeDocumentOfEnterprise(this.enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionRemove(ids)
          } else {
            this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            this.getListDocumentOfEnterprise();
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionRemove(ids) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request: any = {};
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1,
          ids
        };
        this.enterpriseService.removeDocumentOfEnterprise(this.enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  updateDocument(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật giấy tờ',
      action: 'update',
      row
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.getListDocumentOfEnterprise();
        }, 500);
      }
      // console.log('The dialog was closed');
    });
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original
      if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

}
