import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ic-select-search-box-display',
  templateUrl: './select-search-box-display.component.html',
  styleUrls: ['./select-search-box-display.component.scss']
})
export class SelectSearchBoxDisplayComponent implements OnInit {

  @Input()
  data: any;

  constructor() { }

  ngOnInit(): void {}

}
