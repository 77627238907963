


<div>
  <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="content-modal">
    <!-- <ic-filter-bar [availableFilters]="availableFilters" (inputSearchChange) ="changeInputSeach($event)"></ic-filter-bar> -->
    <!-- End Filter bar -->
    
    <div style="margin: 24px auto; max-width: 100%;">
      <mdc-card class="demo-card">
        <div class="ic-data-table-toolbar" style="position: inherit;">
          <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
            <button mdc-button raised class="btn" (click)="assignDocumentFromPackage()">Gán giấy tờ trong kho</button>
            <button mdc-button raised class="btn" (click)="createNewDocument()">Thêm mới</button>
            <button mdc-button raised class="btn btn-hidden" (click)="hiddenDocument()" [disabled]="!this.selected.length">Ẩn </button>
            <button mdc-button raised class="btn btn-show" (click)="showDocument()" [disabled]="!this.selected.length">Hiện </button>
            <button mdc-button raised class="btn btn-remove" (click)="removeDocument()" [disabled]="!this.selected.length">Xóa </button>
            <button mdc-button raised class="btn btn-verify" (click)="verifyDocument()" [disabled]="!this.selected.length">Xác thực </button>
            <button mdc-button raised class="btn btn-cancel-verify" (click)="cancelVerifyDocument()" [disabled]="!this.selected.length">Hủy xác thực </button>
          </div>
        </div>
        <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
          (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
          <table mdcDataTableTable>
            <thead>
              <tr mdcDataTableHeaderRow name="select">
                <th mdcDataTableHeaderCell name="select">
                  <dash-checkbox (change)="$event ? masterToggle() : null"
                                 [checked]="isSelectedAll"
                                 [indeterminate]="isIndeterminate">
                  </dash-checkbox>
                </th>
                <th mdcDataTableHeaderCell name="id">ID</th>
                <th mdcDataTableHeaderCell name="id">Tên giấy tờ</th>
                <th mdcDataTableHeaderCell name="image">File giấy tờ</th>
                <th mdcDataTableHeaderCell name="image">Ngày hết hạn</th>
                <th mdcDataTableHeaderCell name="image">Tình trạng</th>
                <th mdcDataTableHeaderCell name="image">Xác thực bởi</th>
                <th mdcDataTableHeaderCell name="image">Trạng thái xác thực</th>
                <th mdcDataTableHeaderCell name="image">Trạng thái ẩn/ hiện</th>
                <th mdcDataTableHeaderCell name="action">Hành động</th>
              </tr>
            </thead>
            <tbody mdcDataTableContent>
              <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)" >
                <td mdcDataTableCell name="select">
                  <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                                 [checked]="checkSelected(row)"
                                 [aria-label]="checkboxLabel(row)"
                                 >
                  </dash-checkbox>
                </td>
                <td mdcDataTableCell name="id" >{{row.documentId}}</td>
                
                <td mdcDataTableCell name="id" >
                  <span [title]="row.title" class="text-break">{{row.title}}</span>
                </td>
                <td mdcDataTableCell>
                  <div *ngFor="let item of row.values; index as i" class="div-file">
                    <span class="file-label text-break" [title]="item.url" (click)="openNewTab(item.url)">{{item.url}}</span>
                  </div>
                </td>
                <td mdcDataTableCell name="image" >{{row.expireAt}}</td>
                <td mdcDataTableCell name="image" >
                  <span *ngIf="row.expire === 3">Còn hạn</span>
                  <span *ngIf="row.expire === 1">Hết hạn</span>
                </td>
                <td mdcDataTableCell name="authentication_sattus" >{{row.verifyBy.join(',')}}</td>
                <td mdcDataTableCell name="image" >
                  <span *ngIf="row.status === 1" class="verified">Xác thực</span>
                  <span *ngIf="row.status === 3" class="not-verified">Chưa xác thực</span>
                </td>
                <td mdcDataTableCell name="image" >
                  <span *ngIf="row.visible === 1">Hiện</span>
                  <span *ngIf="row.visible === 3">Ẩn</span>
                </td>
                <td mdcDataTableCell name="action">
                  <button mdcIconButton (click)="updateDocument(row)">
                    <mdc-icon class="btn-edit">edit</mdc-icon>
                  </button>
                  <button mdcIconButton (click) = "removeDocument(row)">
                    <mdc-icon class="btn-delete">delete</mdc-icon>
                  </button>
                  <button mdcIconButton (click) = "cancelVerifyDocument(row)" *ngIf="row.status === 1">
                    <mat-icon class="btn-close">close</mat-icon>
                  </button>
                  <button mdcIconButton (click) = "verifyDocument(row)" *ngIf="row.status === 3">
                    <mat-icon class="btn-check">check_circle</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </mdc-data-table>
        <mat-paginator showFirstLastButtons
                       [length]="total"
                       [pageSize]="pageSize"
        ></mat-paginator>
      </mdc-card>
    </div>
  </div>  
  <!-- <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
  <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
  <button mat-raised-button type="submit" class="btn-stop">Thêm</button>
  </mat-toolbar> -->
