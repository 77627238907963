import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { CategoryListPageActions } from '@category/actions';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { checkFormatBarcode } from '@src/app/shared/validate/validate';
import { ProductListPageActions } from '@products/actions';
import { Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromProduct from '@products/reducers';
import * as fromCategory from '@category/reducers';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { ProductService } from '@products/services/product.services';
import { onlyUnique, uniqueObjectInArray } from '@src/app/shared/common';
import { GlnService } from '@gln/services/gln.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {TekoValueDto} from '@src/app/shared/common/teko-value.dto';
declare function alertEvent(info);
declare function clickTeko(info);
@Component({
  selector: 'ic-assign-product-gln',
  templateUrl: './assign-product-gln.component.html',
  styleUrls: ['./assign-product-gln.component.scss']
})
export class AssignProductGlnComponent extends ModalScroll implements OnInit {

  @ViewChild('inputUpload', { read: ElementRef, static: false }) inputUpload: ElementRef;


  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory: any;
  currentUser: any;
  importName = [];
  listFileImport = [];
  listDocument: any = [];
  listDocumentName: any = [];
  listTitle: any = [];
  listIds: any = [];
  enterpriseId: any;
  action: any;
  destroy$ = new Subject();
  typeCurrent: any;
  pageTitle = 1;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AssignProductGlnComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
    private productService: ProductService,
    private enterpriseService: GlnService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog,
  ) {
    super();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.data = data;
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      type: ['', this.data.action === 'create' ? Validators.required : null],
      title: ['', this.data.action === 'create' ? Validators.required : null],
      documentIds: ['', this.data.action === 'create' ? Validators.required : null],
      barcodes: ['', Validators.required],
      allowUpdateProduct: [false],
    });
    this.enterpriseId = this.data.enterpriseId;
    console.log(this.enterpriseId);

    this.formGroup.get('type').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(x => {
      console.log(x);
      this.typeCurrent = x;
      this.getListTitleByType();
      this.formGroup.patchValue({
        title: ''
      });
      // this.productService.getDetailTitleOfRole(x).pipe(
      //   takeUntil(this.destroy$)
      // ).subscribe((res: any) => {
      //   this.listTitle = res.data.items;
      // })
   });

  }

  getListTitleByType() {
    console.log('11111111');

    this.productService.getDetailTitleOfRole({
      type: this.typeCurrent,
      page: this.pageTitle,
      pageSize: 10
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listTitle = [...this.listTitle, ...res.data.items];
    });
  }

  lazyListTitleByType() {
    this.pageTitle += 1;
    this.getListTitleByType();
  }


  onFormSubmit() {

    if (this.data.action === 'create') {
      const object = {
        ...this.formGroup.value,
        barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique)
      };

      this.enterpriseService.createProductDistributorOfEnterprise(this.data.enterpriseId, object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmActionSubmit(this.data.enterpriseId, object);
        } else {
          const success = res.data.success;
          const fail = res.data.fail;
          if (success) {
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }

      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    } else {
      const object = {
        enterpriseId: this.enterpriseId,
        barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique)
      };
      // console.log(object);
      // console.log(this.data);
      this.productService.assignEnterprise(object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmActionCreateOwnen(object);
        } else {

          const success = res.data.success;
          const fail = res.data.fail;
          if (success) {
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }

      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    }

    // this.dialogRef.close(this.formGroup.value);
  }

  confirmActionCreateOwnen(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: this.formGroup.value.allowUpdateProduct ? 1 : 2
        };
        this.productService.assignEnterprise(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {

          const success = res.data.success;
          const fail = res.data.fail;
          if (success) {
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionSubmit(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: this.formGroup.value.allowUpdateProduct ? 1 : 2
        };
        this.enterpriseService.createProductDistributorOfEnterprise(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const success = res.data.success;
          const fail = res.data.fail;
          if (success) {
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  clearInputSearch() {
    this.formGroup.patchValue({inputSearch: ''});

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }

  loadListProduct() {
    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: {},
        page: 1,
        pageSize: 10,
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    });
    this.formGroup.updateValueAndValidity();
  }

  createDocumentFromPackage() {

  }

  deleteDocument(i) {
    this.listDocument.splice(i, 1);
    this.listIds.splice(i, 1);
    this.formGroup.controls.documentIds.setValue(this.listIds);
  }


  assignDocumentFromPackage() {
    console.log(this.data);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Kho giấy tờ',
      action: 'select',
      productId: this.data.productId
    };
    dialogConfig.disableClose = true;

    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_DOCUMENT.REGION_NAME, TekoValueDto.ENTERPRISE_DOCUMENT.CONTENT_NAME.ADD, '', {});
    clickTeko(click)
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resultttttttttttt', result);
      if (result !== 'close' && result.length) {
        this.listDocument = uniqueObjectInArray(this.listDocument.concat(result));
        // console.log(this.listDocument);
        this.listIds = this.listIds.concat(result.map(item => item.id)).filter(onlyUnique);
        this.formGroup.controls.documentIds.setValue(this.listIds);
        // console.log(this.listDocument);

      }
    });
  }

  createNewDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm giấy tờ',
      action: 'create',
      type: 'get',
      productId: this.data.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(result => {
      // this.listFileImport.push(result.importFile);
      // this.importName.push(result.importFile.map(item => item.name));
      // this.formGroup.controls['fileImport'].setValue(this.listFileImport);

      // console.log('resultttttttttttt', result);

      if (result !== 'close') {
        this.listDocument.push(result);
        this.listIds.push(result.id);
        // console.log(this.listDocument)
        this.formGroup.controls.documentIds.setValue(this.listIds);
      }
    });
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
