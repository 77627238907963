import { Injectable } from '@angular/core';
import {BaseService} from "@src/app/services/base.service";
@Injectable({
  providedIn: 'root'
})
export class AllowOrDenyContributeService extends  BaseService {

  updateProductContribute(body) {
    return this.put('/product/updateContribute', body);
  }
}
