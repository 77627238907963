import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const loadAttributeProduct = createAction(
  '[AttributeProduct Page] Load AttributeProduct',
  props<{ filter: any }>()
);

export const loadAttributeProductsSuccess = createAction(
  '[Collection/API] Load AttributeProduct Success',
  props<{ attributeProducts: any, total: number }>()
);

export const loadAttributeProductsFailure = createAction(
  '[Collection/API] Load AttributeProducts Failure',
  props<{ error: any }>()
);

export const updateAttributeProduct = createAction(
  '[AttributeProduct Page] Update AttributeProduct',
  props<{ attributeProductObject: any }>()
);

export const updateAttributeProductSuccess = createAction(
  '[Collection/API] Update AttributeProduct Success',
  props<{ attributeProduct: any }>()
);

export const updateAttributeProductFailure = createAction(
  '[Collection/API] Update AttributeProduct Failure',
  props<{ error: any }>()
);

export const addAttributeProduct = createAction(
  '[AttributeProduct Page] Add AttributeProduct',
  props<{ attributeProductObject: any }>()
);

export const addAttributeProductSuccess = createAction(
  '[Collection/API] Add AttributeProduct Success',
  props<{ attributeProduct: any }>()
);

export const addAttributeProductFailure = createAction(
  '[Collection/API] Add AttributeProduct Failure',
  props<{ error: any }>()
);

export const updateListAttributeProduct = createAction(
  '[AttributeProduct Page] Update List AttributeProduct',
  props<{ attributeProducts: any, attributeProduct: any }>()
);

export const updateListAttributeProductSuccess = createAction(
  '[Collection/API] Update List AttributeProduct Success',
  props<{ attributeProducts: any }>()
);

export const updateListAttributeProductFailure = createAction(
  '[Collection/API] Update List AttributeProduct Failure',
  props<{ error: any }>()
);

export const searchAttributeProduct = createAction(
  '[AttributeProduct Page] Search AttributeProduct',
  props<{ objectSearch: any }>()
);

export const searchAttributeProductSuccess = createAction(
  '[Collection/API] Search Attribute Products Success',
  props<{ attributeProducts: any }>()
);

export const searchAttributeProductFailure = createAction(
  '[Collection/API] Search Attribute Products Failure',
  props<{ error: any }>()
);


export const deleteAttributeProduct = createAction(
  '[AttributeProduct Page] Delete AttributeProduct',
  props<{ id: any }>()
);

export const deleteAttributeProductSuccess = createAction(
  '[Collection/API] Delete Attribute Products Success',
  props<{ attributeProduct: any }>()
);

export const deleteAttributeProductFailure = createAction(
  '[Collection/API] Delete Attribute Products Failure',
  props<{ error: any }>()
);


