<ic-app *ngIf="path_url_first != '/auth/login' && path_url_first != '/auth/forgot-password'">
  <ic-top-app-bar (navIconClick)="sidebarPanel.open = !sidebarPanel.open">
    <mdc-top-app-bar-section align="end">
      <mdc-icon mdcTopAppBarActionItem style="color: white;">search</mdc-icon>
      <mdc-icon mdcTopAppBarActionItem style="color: white;" (click)="notificationPanel.open = !notificationPanel.open">notifications_none</mdc-icon>
      <div icTopAppBarUser (click)="profileMenu.open = !profileMenu.open">
        <img class="ic-top-app-bar__user-photo" src="https://dreambuilders.dk/wp-content/uploads/2015/03/myAvatar-1.png" />
      </div>
      <div mdcMenuSurfaceAnchor #menuAnchor>
        <mdc-menu #profileMenu [anchorElement]="menuAnchor" style="width: 180px;">
          <mdc-list>
            <mdc-list-item>Thông tin tài khoản</mdc-list-item>
            <mdc-list-divider></mdc-list-divider>
            <mdc-list-item (click)="logout()">Đăng xuất</mdc-list-item>
          </mdc-list>
        </mdc-menu>
      </div>
    </mdc-top-app-bar-section>
  </ic-top-app-bar>
  <ic-content style="background: #FBFCFD;">
    <ic-sidebar-panel (mouseenter)="sidebarPanel.open = true" open drawer="dismissible" #sidebarPanel></ic-sidebar-panel>
    <div class="mdc-drawer-app-content">
      <router-outlet></router-outlet>
    </div>
  </ic-content>
  <ic-notification-panel #notificationPanel></ic-notification-panel>
</ic-app>

<ic-app *ngIf="path_url_first == '/auth/login' || path_url_first == '/auth/forgot-password'">
  <router-outlet></router-outlet>
</ic-app>
