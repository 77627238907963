import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import {
  CategoryActions,
  CategoryPageActions,
  CategoryListPageActions
} from '../../actions';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '../../reducers';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import { ProductAttributeListPageActions } from '@attribute-set/actions';
import { MatSelect } from '@angular/material/select';
import { CategoryService } from '@category/services/category.service';
import { buildImageUrls } from '@src/app/shared/common';
import { ProductAttributeService } from '@attribute-set/services/product-attribute.service';
import * as _ from 'lodash'

@Component({
  selector: 'ic-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit {

  categoryForm : any;
  data: any;
  categories$: Observable<any>;
  searchChangeObserver;
  logo = [];
  listAttributeSet$: Observable<any>;
  searchAttributeSetObserver: any;
  listSearchCategory$: Observable<any>;
  listCategory: any = [];
  listAttributeSet = [];
  parent_id$: Observable<any>;
  currentParent: any = {};
  pageCategory = 1;
  filterCategory = {};
  pageAttributeSet = 1;
  filterAttributeSet = {};
  destroy$ = new Subject();

  @ViewChild('matSelect') child: MatSelect;

  @ViewChild('matSelectAttributeSet') matSelectAttributeSet: MatSelect;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CategoryModalComponent>,
    private store: Store<fromCategory.State>,
    @Inject(MAT_DIALOG_DATA) data,
    private dialog: MatDialog,
    private categoryService: CategoryService,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productAttributeService: ProductAttributeService) {
      this.data = data;
      this.categories$ = store.pipe(select(fromCategory.selectListCategory));
      this.listAttributeSet$ = storeAttributeSet.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
      this.listSearchCategory$ = store.pipe(select(fromCategory.selectListSearchCategory));
      this.parent_id$ = store.pipe(select(fromCategory.selectCategoryParentId));
    }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      name: [this.data?.element?.name || '', [Validators.required, noWhitespaceValidator]],
      image: [this.data?.element?.image || '', Validators.required],
      orderSort: [ this.data?.element?.orderSort || '', Validators.required],
      parentId: [ this.data?.element?.ancestors[0]?.id || ''],
      attributeSetId: [ this.data?.element?.attributeSetId || ''],
      inputSearch: [ ''],
      inputSearchAttributeSet: [ '']
    });

    if (this.data?.element?.attributeSetId) {
      this.productAttributeService.getDetailAttributeSet(this.data?.element?.attributeSetId).pipe(
        takeUntil(this.destroy$)
      )
        .subscribe((res: any) => this.listAttributeSet = _.uniqBy([res.data, ...this.listAttributeSet], 'id'))
    }
    if (this.data?.element?.ancestors?.length) {
      this.categoryService.getDetailCategory(this.data?.element?.ancestors[0].id).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        console.log(res);
        this.currentParent = res.data;
          const name_descent = this.currentParent.ancestors.map(it => it.name);
          // console.log('name_descent', name_descent);
          if (name_descent.length > 0) {
            this.currentParent.display_name = name_descent.join(' > ') + ' > '+ this.currentParent.name;
          } else {
            this.currentParent.display_name = this.currentParent.name;
          }
          // console.log(item.display_name);
        console.log(this.listCategory, this.currentParent);
        this.listCategory = _.uniqBy([
          this.currentParent,
          ...this.listCategory
        ], 'id')
        // console.log(this.listCategory);

        this.categoryForm.patchValue({
          parentId: res.data.id
        })
      })
      // this.productAttributeService.getDetailAttributeSet(this.data?.element?.attributeSetId).pipe(
      //   takeUntil(this.destroy$)
      // )
      //   .subscribe((res: any) => this.listAttributeSet = _.uniqBy([res.data, ...this.listAttributeSet], 'id'))
    }
    this.parent_id$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      if (item) {
        this.categoryService.getDetailCategory(item).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          console.log(res);
          this.currentParent = res.data;
            const name_descent = this.currentParent.ancestors.map(it => it.name);
            // console.log('name_descent', name_descent);
            if (name_descent.length > 0) {
              this.currentParent.display_name = name_descent.join(' > ') + ' > '+ this.currentParent.name;
            } else {
              this.currentParent.display_name = this.currentParent.name;
            }
            // console.log(item.display_name);
          console.log(this.listCategory, this.currentParent);
          this.listCategory = _.uniqBy([
            this.currentParent,
            ...this.listCategory
          ], 'id')
          // console.log(this.listCategory);

          this.categoryForm.patchValue({
            parentId: res.data.id
          })
        })
      }
    })

    if (this.data.action === 'update') {
      // console.log(this.data);
      const image  = this.data.element.image;
      const v = {
        type: '',
        url: buildImageUrls(image).original,
      };

      this.categoryForm.controls.image.setValue(image);
      this.logo.push(v);
    }
    this.getListCategory();
    this.getListAttributeSet();
  }

  ngAfterViewInit() {
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listCategory.length) {
          this.filterCategory = {};
          this.pageCategory = 1;
          this.listCategory = [];
          this.getListCategory();
          this.categoryForm.patchValue({inputSearch: ''})
        }
      }
    })
    this.matSelectAttributeSet.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listAttributeSet.length) {
          this.filterAttributeSet = {};
          this.pageAttributeSet = 1;
          this.listAttributeSet = [];
          this.getListAttributeSet();
          this.categoryForm.patchValue({inputSearchAttributeSet: ''})
        }
      }
    })
  }

  getListAttributeSet() {
    this.productAttributeService.getListAttributeSet({
      page: this.pageAttributeSet,
      pageSize: 10,
      filter: this.filterAttributeSet
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listAttributeSet = _.uniqBy([...this.listAttributeSet, ...res.data.items], 'id');
    })
  }

  lazyLoadAttributeSet() {
    this.pageAttributeSet += 1;
    this.getListAttributeSet();
  }

  getListCategory() {
    this.categoryService.searchCategory({
      page: this.pageCategory,
      pageSize: 10,
      filter: this.filterCategory
    }).pipe(
      map((item: any) => {
        const newData = item.data.items.map(item => {
          const name_descent = item.ancestors.map(it => it.name);
          // console.log('name_descent', name_descent);
          if (name_descent.length > 0) {
            item.display_name = name_descent.join(' > ') + ' > '+ item.name;
          } else {
            item.display_name = item.name;
          }
          // console.log(item.display_name);
          return item;
        })
        return newData;
      })
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      if (this.currentParent?.id) {
        this.listCategory = _.uniqBy([
          this.currentParent,
          ...this.listCategory,
          ...res
        ], 'id')
      } else {
        this.listCategory = _.uniqBy([
          ...this.listCategory,
          ...res
        ], 'id')
      }

      console.log(this.listCategory);

      console.log(res);
    })
  }

  lazyLoadCategory() {
    console.log('aaaaaaaaa');
    this.pageCategory += 1;
    this.getListCategory();
  }

  changeInputAttributeSetSearch(event) {
    if (!this.searchAttributeSetObserver) {
      Observable.create(observer => {
        // console.log(observer);

        this.searchAttributeSetObserver = observer;
      }).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        )
        .subscribe(it => {
          this.filterAttributeSet = { name: it };
          this.pageAttributeSet = 1;
          this.listAttributeSet = [];
          this.getListAttributeSet();
        });
    }
    this.searchAttributeSetObserver.next(event.target.value);
  }

  closeDialogClick() {
    this.dialogRef.close();
  }


  clearInputSearch() {
    this.categoryForm.patchValue({inputSearch: ''});
    this.filterCategory = {};
    this.pageCategory = 1;
    this.listCategory = [];
    this.getListCategory();
  }

  clearInputAttributeSetSearch() {
    this.categoryForm.patchValue({inputSearchAttributeSet: ''})
    this.filterAttributeSet = {};
    this.pageAttributeSet = 1;
    this.listAttributeSet = [];
    this.getListAttributeSet();
  }

  onFormSubmit() {
    // console.log(this.categoryForm.value)
    if (this.data.action === 'create') {
      const object = {
        ...this.categoryForm.value,
        name: this.categoryForm.value.name.trim()
      }
      this.store.dispatch(CategoryPageActions.addCategory({categoryObject: this.categoryForm.value}));
      this.dialogRef.close('accept');
    } else {
      const object = {
        ...this.categoryForm.value,
        name: this.categoryForm.value.name.trim(),
        id: this.data.element.id
      }
      // console.log(this.categoryForm.value);
      this.store.dispatch(CategoryPageActions.updateCategory({categoryObject: object}));
      this.dialogRef.close('accept');
    }
  }

  changeInputSearch(event) {
    this.filterCategory = { name: event.target.value };
    this.pageCategory = 1;
    this.listCategory = [];
    this.getListCategory();
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
      fileType : 'jpg,jpeg,png,gif,bmp'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        const data = result.data;
        data.map(res => {
          this.logo = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);

          this.logo.push(value);
          this.categoryForm.controls.image.setValue(res.url);
        });
      }
    });
  }
  onRemoveFile(field: string, index: number) {
    this.categoryForm.controls.image.setValue('');
    this.logo = [];
  }

  inputOrderChange(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      || e.keyCode == 8)) {
        return false;
    }
  }

  clearSelected(key) {
    this.categoryForm.patchValue({
      [key]: null
    })
    this.categoryForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
