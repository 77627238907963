import {Component, OnInit, ViewChild, ChangeDetectorRef, Inject, AfterViewInit, Output, EventEmitter} from '@angular/core';
import { Subscription, Observable, Subject, merge } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { MatPaginator } from '@angular/material/paginator';
import { MDCDataTable, MdcDialog, MDCDataTableRowSelectionChangedEvent } from '@angular-mdc/web';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryListPageActions } from '@category/actions';
import * as fromProduct from '@products/reducers';
import * as fromProductAttribute from '../../../../product-attribute-group/reducers';
import * as fromCategory from '@category/reducers';
import { CancelVerifyDistributorComponent } from '../cancel-verify-distributor/cancel-verify-distributor.component';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { UpdateDistributorComponent } from '../update-distributor/update-distributor.component';
import { VerifyDocumentListComponent } from '../verify-document-list/verify-document-list.component';
import { ProductDistributorCreateComponent } from '../product-distributor-create/product-distributor-create.component';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { formatDate, clean, buildImageUrls } from '@src/app/shared/common';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';

declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function searchTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-product-distributor-list',
  templateUrl: './product-distributor-list.component.html',
  styleUrls: ['./product-distributor-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductDistributor();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductDistributorListComponent implements OnInit , AfterViewInit {

  rows: any = [];
  total: any = 0;
  pageSize: any = 10;
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);
  @Output() load = new EventEmitter<any>();
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  productId: any;
  indexClickMore = [];
  barcode: string;
  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private storeCategory: Store<fromCategory.State>,
    private route: ActivatedRoute,
    @Inject('availableFilters') public availableFilters: [],

  ) {
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
      this.page$ = store.pipe(select(fromProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
      this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.productId = id;
      this.productService.getDetailProduct(id).subscribe((res : any) => {
        this.barcode = res.data.barcode;
      })
      enterScreenView('product_distributor', 'product');
      this.loadListDistributor();
    }
    // console.log(this.rows);
  }

  ngAfterViewInit() {
    const filterChange = this.filterBar.filterChange;
    let pageChange;
    pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$))
    .subscribe(() => {
    });
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);

        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const object = {
          ...this.filterBar.filterForm.value,
          create_to_date: this.filterBar.filterForm.value.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
          create_from_date: this.filterBar.filterForm.value.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
        };


        const request = {
          filter: clean(object),
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        };
        // console.log('requestttt', request);
        searchTeko({... request.filter, page: request.page, pageSize: request.pageSize})
        this.loadListDistributor(request);
      });
      // console.log(this.filterBar);
      // this._changeDetectorRef.detectChanges();
  }

  changeFilter(data) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;

    this.load.emit([
      this.paginator,
      data
    ]);
  }

  loadListDistributor(object?) {
    const request = object || {
      page: 1,
      pageSize: 10
    };
    this.productService.getListDistributorOfProduct(this.productId, request).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      const list = res.data.items.map(item => {
        // console.log('itemmmmm', item);
        const newObject = Object.assign({}, item);
        if (newObject.image) {
          newObject.image = buildImageUrls(newObject.image).original;
          item = newObject;
        }
        return item;
        //
      });
      this.rows = list;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeInputSearch(event) {

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );


  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.enterpriseId).indexOf(el.enterpriseId) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.enterpriseId).indexOf(el.enterpriseId) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.enterpriseId === row.enterpriseId);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.enterpriseId === row.enterpriseId);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter(item => item.enterpriseId !== row.enterpriseId);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.enterpriseId).indexOf(el.enterpriseId) < 0;
    });

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    this.router.navigate(['/products/update', row.id]);
  }




  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm nhà phân phối',
      action: 'create',
      productId: this.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDistributorCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.loadListDistributor();
        }, 500);
        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  hiddenEnteprise() {
    const body = {
      enterpriseId: this.selected.map(item => item.enterpriseId)
    };
    const click = new ClickTekoDto('product_distributor' , 'hide',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId, barcode: this.barcode});
    clickTeko(click);
    this.productService.hiddenDistributorOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionHidden(this.productId, body);
      } else {
        if (res.data.hasManager <= 0) {
          this._snackBar.open(`Ẩn nhà phân phối thành công`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          this._snackBar.open(`Ẩn ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} NPP được quản lý`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }
        setTimeout(() => {
          this.loadListDistributor();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionHidden(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.hiddenDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Ẩn nhà phân phối thành công`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Ẩn ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} NPP được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
          setTimeout(() => {
            this.loadListDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  showEnteprise(row?) {
    const body = {
      enterpriseId: this.selected.map(item => item.enterpriseId)
    };
    const click = new ClickTekoDto('product_distributor' , 'show',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId, barcode: this.barcode});
    clickTeko(click);
    this.productService.showDistributorOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionShow(this.productId, body);
      } else {
        if (res.data.hasManager <= 0) {
          this._snackBar.open(`Hiện nhà phân phối thành công`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          this._snackBar.open(`Hiện ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} NPP được quản lý`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }
        setTimeout(() => {
          this.loadListDistributor();
        }, 500);
        this.resetSelected();
      }

    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionShow(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    const click = new ClickTekoDto('product_distributor' , 'show',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.showDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Hiện nhà phân phối thành công`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Hiện ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} NPP được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
          setTimeout(() => {
            this.loadListDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  removeEnteprise(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa nhà phân phối khỏi sản phẩm này không?'
    });
    const click = new ClickTekoDto('product_distributor' , 'product_distributor_delete',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId, barcode: this.barcode});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const body = {
          enterpriseId: row ? [row.enterpriseId] : this.selected.map(item => item.enterpriseId)
        };
        this.productService.removeDistributorOfProduct(this.productId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionDelete(this.productId, body);
          } else {
            if (res.data.hasManager <= 0) {
              this._snackBar.open(`Xóa nhà phân phối thành công`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              if (this.selected.length) {
                this._snackBar.open(`Xóa ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              } else {
                this._snackBar.open(`Xóa ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            }
            setTimeout(() => {
              this.loadListDistributor();
            }, 500);
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionDelete(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;

    const click = new ClickTekoDto('product_distributor' , 'product_distributor_delete',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.removeDistributorOfProduct(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Xóa nhà phân phối thành công`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Xóa ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Xóa ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
          setTimeout(() => {
            this.loadListDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  updateEnteprise(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật vai trò và tiêu đề nhà phân phối',
      action: 'update-enteprise',
      enterpriseId: row.enterpriseId,
      productId: this.productId,
      row
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UpdateDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.loadListDistributor();
        }, 500);
      }
    });
  }

  verifyEnterprise(row?) {
    const click = new ClickTekoDto('product_distributor', 'product_distributor_active',
      'http://datahub.icheck.vn/products/update/ljOyWmyP' , {product_id: this.productId, barcode: this.barcode});
    clickTeko(click);

    const body = {
      enterpriseId: row ? [row.enterpriseId] : this.selected.map(item => item.enterpriseId)
    };
    this.productService.appoveDistributorOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionVerify(this.productId, body);
      } else {
        if (res.data.hasManager <= 0) {
          const alert = new AlertTekoDto(ALERT.SUCCESS , ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open(`Xác thực nhà phân phối thành công`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          if (this.selected.length) {
            this._snackBar.open(`Xác thực ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`,
              'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Xác thực ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`,
              'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        }
        setTimeout(() => {
          this.loadListDistributor();
        }, 500);

        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionVerify(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.appoveDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Xác thực nhà phân phối thành công`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Xác thực ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Xác thực ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
          setTimeout(() => {
            this.loadListDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }


  cancelVerifyEnterprise(row?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực nhà phân phối',
      question: 'Bạn có chác chắn muốn hủy xác thực nhà phân phối này không?',
      action: 'create',
      productId: this.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    const click = new ClickTekoDto('product_distributor' , 'product_distributor_inactive',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', { product_id: this.productId, barcode: this.barcode});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      console.log('resulttttttttt', result);


      if (result !== 'close') {
        const body = {
          enterpriseId: row ? [row.enterpriseId] : this.selected.map(item => item.enterpriseId),
          reason: result.reason
        };
        this.productService.unappoveDistributorOfProduct(this.productId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUnVerify(this.productId, body);
          } else {
            if (res.data.hasManager <= 0) {
              this._snackBar.open(`Hủy xác thực nhà phân phối thành công`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              if (this.selected.length) {
                this._snackBar.open(`Hủy xác thực ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              } else {
                this._snackBar.open(`Hủy xác thực ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            }
            setTimeout(() => {
              this.loadListDistributor();
            }, 500);
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
    });
  }

  confirmActionUnVerify(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.unappoveDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Hủy xác thực nhà phân phối thành công`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Hủy xác thực ${this.selected.length - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Hủy xác thực ${1 - res.data.hasManager} NPP thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
          setTimeout(() => {
            this.loadListDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }


  detailDocumentDistributor(row) {
    // console.log('rowwwwwwwww', row);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Duyệt giấy tờ',
      action: 'create',
      enterpriseId: row.enterpriseId,
      productId: this.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(VerifyDocumentListComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // setTimeout(() => {
        //   this.loadListDistributor();
        // }, 500);
      }
    });
  }

  clickMoreIndex(index) {
    this.indexClickMore.push(index);
  }

  closeMore(index) {
    this.indexClickMore = this.indexClickMore.filter(item => item !== index);
  }
}
