<div class="con-file-list__file" *ngFor="let file of files; let i = index;">
  <div class="con-file" (mouseover)="indexHover=i"
  (mouseleave)="indexHover=-1">
    <ng-container *ngIf="listType == 'image'; else fileTemplate;">
      <img  onError="this.onerror=null;this.src='../../../assets/stran.png'" src="{{ file.url }}"
             style="width: 100px; height:100px; border-radius:10px;cursor: pointer;"
             />
             <div class="div-shadow" *ngIf="indexHover === i"></div>
             <div class="div-icon" *ngIf="indexHover === i">
              <mat-icon (click) = "openNewTab(file.url)">visibility</mat-icon>
               <mat-icon (click)="removeFile.emit(i)">delete_outline</mat-icon>
            </div>
    </ng-container>
    <ng-template #fileTemplate>
      <img src="{{ file.url }}"
           style="width: 100px; cursor: pointer;    margin-right: 10px;"/>
    </ng-template>
  </div>
</div>
<button class="add-file" type="button"  (click)="addFile.emit()"></button>
