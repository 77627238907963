import { MDCDataTable, MDCDataTableRowSelectionChangedEvent, MdcDialog } from '@angular-mdc/web';
import { SelectionModel } from '@angular/cdk/collections';
import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as fromCategory from '@category/reducers';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { select, Store } from '@ngrx/store';
import * as fromProduct from '@products/reducers';
import { ProductService } from '@products/services/product.services';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import { CancelVerifyDistributorComponent } from '../product-distributor/cancel-verify-distributor/cancel-verify-distributor.component';
import { UpdateDistributorComponent } from '../product-distributor/update-distributor/update-distributor.component';
import { ProductDocumentModalComponent } from '../product-documents/product-document-modal/product-document-modal.component';
import { GlnService } from '@gln/services/gln.service';
import { clean, formatDate, buildImageUrls } from '@src/app/shared/common';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {BarcodeBase} from '@src/app/shared/common/barcode-base';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';
// @ts-ignore
import {DOC_PRODUCT_TYPE} from '@src/app/constants';
import {ModalProductTypeComponent} from '@products/components/product-documents-package/modal-product-type/modal-product-type.component';
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-product-documents-package',
  templateUrl: './product-documents-package.component.html',
  styleUrls: ['./product-documents-package.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductDocumentPackage();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductDocumentsPackageComponent extends BarcodeBase  implements OnInit, AfterViewInit {

  rows: any = [];
  total: any = 0;
  pageSize: any = 10;
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);
  docProductType = DOC_PRODUCT_TYPE;
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<any>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  data: any;
  createDocumentPackageForm: any;

  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    public productService: ProductService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    @Inject('availableFilters') public availableFilters: [],
    public dialogRef: MatDialogRef<ProductDocumentsPackageComponent>,
    public dialogRef2: MatDialogRef<ModalProductTypeComponent>,
    private enterpriseService: GlnService
    ) {
      super(productService, data.productId );
      this.data = data;
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
      this.page$ = store.pipe(select(fromProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
      this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  async ngOnInit() {

    // const click = new ClickTekoDto('product_document', 'product_document_link',
    //   'http://datahub.icheck.vn/products/update/ljOyWmyP', {product_id: this.data.productId, barcode});
    // clickTeko(click);
    this.getListAllDocument();
  }

  ngAfterViewInit() {
    const filterChange = this.filterBar.filterChange;
    let pageChange;
    pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
    .subscribe(() => {
    });
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);

        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const object = {
          ...this.filterBar.filterForm.value,
          create_to_date: this.filterBar.filterForm.value.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
          create_from_date: this.filterBar.filterForm.value.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
        };
        const request = {
          filter: clean(object),
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        };
        this.getListAllDocument(request);
      });
  }

  getListAllDocument(request?) {
    const object = request || {};
    this.productService.getListDocument(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.rows = res.data.items;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    });
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original;
    if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitSelected() {
    if (this.data.docProductType === 'docProductType') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '50rem';
      dialogConfig.height = 'auto';
      // dialogConfig.data = {
      //   label: 'Chọn loại giấy tờ sản phẩm',
        // action: 'assign',
        // productId: this.productId,
        // docProductType: 'docProductType'
      // };
      dialogConfig.disableClose = true;
      const dialogRef = this.dialog.open(ModalProductTypeComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);

        if (result !== 'close') {
          this.dataSubmit(result);
        }
        // console.log('The dialog was closed');
      });
      return;
    } else {
      this.dataSubmit();
    }
  }

  dataSubmit(docProductType?) {
    if (this.data?.action === 'assign' && this.data?.productId) {
      const body = {
        documentIds: this.selected.map(item => item.id),
        productId: this.data.productId
      } as any;
      if (docProductType) {
        body.docProductType = docProductType;
      }
      this.productService.createDocumentOfProduct(body).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {

        if (res.code === 1104) {
          this.confirmCreateProductDocument(body);
        } else {

          this._snackBar.open('Gán giấy tờ cho sản phẩm thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }
      }, err => {
        alertEvent(alert);
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    } else if (this.data?.action === 'assign' && this.data?.enterpriseId) {

      const body = {
        documentIds: this.selected.map(item => item.id),
        enterpriseId: this.data.enterpriseId
      } as any;
      if (docProductType) {
        body.docProductType = docProductType;
      }
      this.enterpriseService.createDocumentOfEnterprise(this.data.enterpriseId, body).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmCreateDocumentOfEnterprise(this.data.enterpriseId, body);
        } else {

          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }

      }, err => {
        const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
        alertEvent(alert);
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    } else if (this.data?.action === 'assign-document') {
      const body = {
        documentIds: this.selected.map(item => item.id),
        enterpriseId: this.data.enterpriseId,
        productId: this.data.productId
      } as any;
      if (docProductType) {
        body.docProductType = docProductType;
      }
      this.productService.createDocumentDistributor(body).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmCreateDocumentDistributor(body);
        } else {
          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }
      }, err => {

        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    } else {

      const res = this.selected.map(item => {
        return {
          name: item.title,
          id: item.id
        };
      });
      this.dialogRef.close(res);
    }
  }

  confirmCreateProductDocument(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createDocumentOfProduct(body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Gán giấy tờ cho sản phẩm thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmCreateDocumentOfEnterprise(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.enterpriseService.createDocumentOfEnterprise(enterpriseId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }
  confirmCreateDocumentDistributor(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createDocumentDistributor(body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }
  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.id).indexOf(el.id) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      // if (rows) {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      // }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.id === row.id);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.id === row.id);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter(item => item.id !== row.id);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
    });
    // console.log(this.selected);
    // console.log(this.rows);
    // console.log(res);

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    this.router.navigate(['/products/update', row.id]);
  }


  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo thuộc tính sản phẩm',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  hiddenDocument() {

  }

  showDocument() {

  }

  removeDocument(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa nhà phân phối khỏi sản phẩm này không?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // this.store.dispatch(GlnActions.deleteGln({id : element.id}));
        this.page$.pipe(
          takeUntil(this.destroy$)
        ).subscribe(it => {
          this.page = it;
        });
        this.pageSize$.pipe(
          takeUntil(this.destroy$)
        ).subscribe(it => {
          this.pageSize = it;
        });
        // console.log(this.page, this.pageSize);

        // this.success$.subscribe(it => {
        //   if (it == true) {
        //     console.log('123');
        //     this.loadGln();
        //   }

        // })
      }
      console.log('The dialog was closed');
    });
  }

  updateDocument(row) {

  }

  verifyDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật vai trò và tiêu đề nhà phân phối',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UpdateDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  cancelVerifyDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực nhà phân phối',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  cancelVerifyEnterprise(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực nhà phân phối',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  verifyEnterprise(row) {

  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

}
