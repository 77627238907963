import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import {
  ProductPageActions, ProductApiActions, ProductActions
} from '../actions';
import { Product } from '../models/product.model';

export const productsFeatureKey = 'products';

export interface State extends EntityState<Product> {
  selectedProductId: string | null;
}

export const adapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: (product: Product) => product.id,
  // sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedProductId: null,
});

export const productReducer = createReducer(
  initialState,
  on(
    ProductPageActions.loadProductsSuccess,
    (state, { rows, total }) => {
      return adapter.upsertMany(rows, state)
    }
  ),
  on(
    ProductPageActions.addProduct,
    (state, { productObject }) => {
      return adapter.addOne(productObject , state);
    }
  ),
  on(ProductPageActions.loadProductsSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  on(ProductApiActions.loadProductsSuccess, (state, { data }) => {
    // console.log(data);
    
    return adapter.upsertMany(data, state);
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return productReducer(state, action);
}

export const getSelectedProductId = (state: State) => state.selectedProductId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectProductIds = selectIds;

// select the dictionary of user entities
export const selectProductEntities = selectEntities;

// select the array of users
export const selectAllProducts = selectAll;

// select the total user count
export const selectProductTotal = selectTotal;
