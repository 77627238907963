import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'ic-category-page',
  template: `
  <div>
    <mat-card-title style="margin-bottom: 1rem;">Quản lý danh muc</mat-card-title>
    <ic-category-list></ic-category-list>
  </div>
  `,
  styles: []
})
export class CategoryPageComponent implements AfterViewInit, OnInit {
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
