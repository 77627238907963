
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group" *ngIf="this.data.action === 'create'">
            <div class="label-form">Chọn vai trò (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="type" placeholder="Chọn vai trò" >
                <mat-option [value]="2">Doanh nghiệp sản xuất</mat-option>
                <mat-option [value]="4">Doanh nghiệp phân phối độc quyền</mat-option>
                <mat-option [value]="3">Nhà phân phối</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="this.data.action === 'create'">
            <div class="label-form">Chọn tiêu đề (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="title" placeholder="Chọn tiêu đề" msInfiniteScroll (infiniteScroll)="lazyListTitleByType()">
                <mat-option [value]="tittle.id" *ngFor="let tittle of listTitle">{{tittle.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="distributor" *ngIf="this.data.action === 'create'">
            <div class="form-group">
              <div class="label-form">Giấy tờ (*)</div>
              <button mdc-button raised class="btn btn-assign-documnet" type="button" (click)="assignDocumentFromPackage()">Chọn từ kho</button>
              <button mdc-button raised class="btn btn-create-new-document" type="button" (click)="createNewDocument()">Tạo mới</button>
            </div>
            <div *ngFor="let item of listDocument; index as i" class="div-file">
              <span class="file-label">{{item.name}}</span>
              <mat-icon class="icon-delete" (click) = "deleteDocument(i)">clear</mat-icon>
            </div>
          </div>
          <div class="form-group">
            <div class="label-form">Nhập danh sách mã vạch (*) </div>
            <mat-form-field appearance="outline" class="form-field">
                <textarea matInput formControlName="barcodes" (keydown.space)="$event.preventDefault()" (keyup)="checkBarcode()"></textarea>
            </mat-form-field>
            <div class="comment-text">(Mỗi dòng là một barcode)</div>
            <div style="height: 40px; margin-bottom: 0.5rem;">
              <button mat-raised-button class="primary btn-delete-barcode" type="button" (click)="clearListBarCode()">Xóa mã lỗi</button>
            </div>
            <div *ngIf="formGroup.value.barcodes" style="margin-bottom: 1rem;">
              <div class="alertDangerQ" *ngIf="check_barcode.length > 0">
                <h3>Dữ liệu trên có chứa các mã vạch không đúng định dạng</h3>
                <p *ngFor="let item of check_barcode" style="margin-bottom: 5px;">(Dòng {{item.index}}) {{item.value}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="currentUser.role === 1 || currentUser.role === 4">
            <mat-checkbox formControlName="allowUpdateProduct">Áp dụng bao gồm các sản phẩm đã được quản lý bởi Business</mat-checkbox>
          </div>
        </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!formGroup.valid">Thêm mới</button>
      </mat-toolbar>
