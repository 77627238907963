
import {
    createSelector,
    createFeatureSelector,
    combineReducers,
    Action,
    ActionReducerMap,
  } from '@ngrx/store';
  import * as fromWarningProduct from './warning-product.reducer';
  import * as fromWarningProductList from './warning-product-list.reducer';
  
  
  import * as fromRoot from '../../reducers';
  
  export const warningProductFeatureKey = 'warningProduct';
  
  export interface WarningProductsState {
    [fromWarningProduct.warningProductsFeatureKey]: fromWarningProduct.State;
    [fromWarningProductList.warningProductListFeatureKey]: fromWarningProductList.State;
  }
  
  export interface State extends fromRoot.State {
    [warningProductFeatureKey]: WarningProductsState;
  }
  
  export function reducers(state: WarningProductsState | undefined, action: Action) {
    return combineReducers({
      [fromWarningProduct.warningProductsFeatureKey]: fromWarningProduct.reducer,
      [fromWarningProductList.warningProductListFeatureKey]: fromWarningProductList.reducer,
    })(state, action);
  }
  
  export const selectWarningProductState = createFeatureSelector<State, WarningProductsState>(
    warningProductFeatureKey
  );
  
  
  export const selectWarningProductEntitiesState = createSelector(
    selectWarningProductState,
    state => state.warningProducts
  );

  
  export const {
    selectIds: selectWarningProductIds,
    selectEntities: selectWarningProductEntities,
    selectAll: selectAllWarningProducts,
    selectTotal: selectTotalWarningProducts,
  } = fromWarningProduct.adapter.getSelectors(selectWarningProductEntitiesState);
  
  export const selectWarningProductListState = createSelector(
    selectWarningProductState,
    state => state.warningProductList
  );
  
  export const selectWarningProductListIds = createSelector(
    selectWarningProductListState,
    fromWarningProductList.getIds
  );
  
  export const selectTotalWarningProduct= createSelector(
    selectWarningProductListState,
    fromWarningProductList.getTotalWarningProduct
  );
  
  export const selectLoadingDataWarningProduct= createSelector(
    selectWarningProductListState,
    fromWarningProductList.getLoadingData
  );
  
  export const selectObjectFilterWarningProduct= createSelector(
    selectWarningProductListState,
    fromWarningProductList.getObjectFilter
  );

  export const selectSuccess= createSelector(
    selectWarningProductListState,
    fromWarningProductList.getSuccess
  );
  
  export const selectWarningProductListResults = createSelector(
    selectWarningProductEntities,
    selectWarningProductListIds,
    (warningProducts, ids) => {
      return ids
        .map(id => warningProducts[id])
        .filter((warningProduct): warningProduct is any => warningProduct != null);
    }
  );
  