import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dash-filter-bar-text-display',
  template: `
  {{data.operator}} {{data.value}}`
})
export class TextDisplayComponent implements OnInit {

  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
  }

}
