import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashFilter } from '@dash/filter-bar/filter-bar.component';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Store as NgxsStore } from '@ngxs/store';
import * as fromAttribute from '../reducers';
import {
 AttributeListPageActions
} from '../actions';
import { SelectionModel } from '@angular/cdk/collections';
import { MdcDialog } from '@angular-mdc/web/dialog';
import { DeleteAlertComponent } from '@category/components/delete-alert/delete-alert.component';
import { clean } from '@src/app/shared/common';

@Component({
  selector: 'ic-attribute-list-page',
  templateUrl: './attribute-list-page.component.html',
})
export class AttributeListPageComponent implements OnInit {

  displayedColumns: string[] = ['select', 'id', 'name', 'val_type', 'code', 'is_unque_val', 'is_required', 'created_at', 'updated_at', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  availableFilters: DashFilter[] = [];
  title = "Quản lý thuộc tính sản phẩm"

  selection = new SelectionModel<any>(true, []);

  filter$: Observable<number>;
  loading$: Observable<boolean>;
  total$: Observable<number>;
  attributes$: Observable<any[]>;
  displayedColumns$: Observable<string[]>;


  constructor(
    private dialog: MdcDialog,
    // private store2: NgxsStore,
    private store: Store<fromAttribute.State>) {
      this.attributes$ = store.pipe(select(fromAttribute.selectAttributeListPageResults));
      this.total$ = store.pipe(select(fromAttribute.selectAttributeListPageTotal));
      this.filter$ = store.pipe(select(fromAttribute.selectAttributeListPageFilter));
      this.loading$ = store.pipe(select(fromAttribute.selectAttributeListPageLoading));
      this.displayedColumns$ = store.pipe(select(fromAttribute.selectAttributeListPageDisplayedColumns));
    }

  ngOnInit(): void {
    this.attributes$.subscribe((data) => {
      // console.log('ok', data);
    });
    // setTimeout(() => {
    //   this.store2.dispatch(new LoadAttribute('aaaaa')).subscribe(() => {
    //     console.log('ok');
    //   });
    // }, 3000);
  }

  loadAttribute([pageData, filterData]: any) {
    const limit = pageData ? pageData.pageSize : this.pageSize;
    const offset = limit * (pageData ? pageData.pageIndex : 0);

    // this.store.dispatch(
    //   AttributeListPageActions.loadAttributes({
    //     filter,
    //     offset,
    //     limit
    //   })
    // );
    const filter = clean(filterData);
    this.store.dispatch(
      AttributeListPageActions.loadAttributes({
        filter,
        page : pageData.pageIndex + 1,
        pageSize: pageData.pageSize,
      })
    );
  }

  openDeleteAlert(items: any[]) {
    const dialogRef = this.dialog.open(DeleteAlertComponent, {
      escapeToClose: false,
      clickOutsideToClose: false,
      buttonsStacked: false
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });
  }
}
