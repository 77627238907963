import { ProductService } from '../../services/product.services';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  AfterContentInit,
  AfterViewChecked,
  ElementRef,
  Inject
} from '@angular/core';
import {Observable, Subscription, Subject, merge, } from 'rxjs';
import {map, takeUntil, debounceTime, distinctUntilChanged, } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as fromProduct from '../../reducers';
import {
ProductListPageActions,
} from '../../actions';
import { formatDate, buildImageUrls, testImage } from '../../../shared/common';
import {
   CategoryListPageActions
} from '../../../category/actions';
import * as fromCategory from '../../../category/reducers';
import { Router } from '@angular/router';
import { MDCDataTable, MdcDialog, MDCDataTableRowSelectionChangedEvent } from '@angular-mdc/web';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import { ProductAttributeListPageActions } from '@attribute-set/actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { UploadComponent } from '@src/app/shared/upload/upload.component';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {SCAN_STATE} from '@src/app/shared/FilterTable/filter-attribte.helper';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function searchTeko(params);
declare function clickTeko(infd)
@Component({
  selector: 'ic-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
          return factory.getFilterAttributeProduct();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  set rows(rows: any) {
    this._rows = rows.map(item => {
      let className  = '';
      let statusName = '';
      switch (item.scanStatus) {
        case SCAN_STATE.DO_NOT_SCAN: className = 'not-allow-scan'; statusName = 'Không cho quét' ; break;
        case SCAN_STATE.HIDDEN_BY_COOP: className = 'hidden'; statusName = 'Đã ẩn bởi DNSH'; break;
        case SCAN_STATE.NO_DATA: className = 'no-data'; statusName = 'No Data' ; break;
        case SCAN_STATE.DO_SCAN: className = 'allow-scan'; statusName = 'Cho quét'; break;
      }
      return  {... item , image: buildImageUrls(item.image).original, className , statusName};
    });
  }
  get rows(): any {
    return this._rows;
  }
  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }

  get displayedColumns(): string[] {
    const availableColumns = this.availableColumns.map(c => c.key);
    const columns = this._displayedColumns.map(column => {
      return availableColumns.indexOf(column) > -1 ? column : null;
    }).filter(filter => filter);

    return columns;
  }

  get sortedAvailableColumns(): string[] {
    return this.availableColumns.sort((a, b) => a.order - b.order).map((c: any) => c.key);
  }

  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private storeCategory: Store<fromCategory.State>,
    @Inject('availableFilters') public availableFilters: []
    ) {
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
      this.page$ = store.pipe(select(fromProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
      this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
      this.filter$ = store.pipe(select(fromProduct.selectProductListPageFilter));
  }

  @Input()
  pageSize: number;

  @Input()
  total: number;
  private _rows: any = [];

  _displayedColumns: string[];

  @Output()
  deleteItems = new EventEmitter<any>();
  @Output() load = new EventEmitter<any>();
  availableColumns = [
    { key: 'select', label: 'Select', order: 1 },
    { key: 'id', label: 'ID', order: 2 },
    { key: 'name', label: 'Name', order: 3 },
    { key: 'image', label: 'Image', order: 4 },
    { key: 'order_sort', label: 'Order Sort', order: 5 },
    { key: 'rating_attribute', label: 'Rating Attribute', order: 6 },
    { key: 'product_attribute', label: 'Product Attribute', order: 7 },
    { key: 'action', label: 'Action', order: 8 },
  ];

  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<any>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  groupListAtttribute = [];
  arrIndexEnableEdit = -1;
  arrIndexEditting = -1;
  isHovered = false;
  isEditing = false;
  indexHover = -1;
  currentUser: any;
  filter: any;
  isOpenPopupConfirm = false;

  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;
  @ViewChild('inputEdit', {static: true}) inputEdit: ElementRef;
  @ViewChild('container', {static: true}) container: ElementRef;

  desserts = [
    { checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty' },
    { checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more' },
    { checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor' }
  ];

  ngOnInit(): void {
    enterScreenView('product_list', 'product');
    this.storeAttributeSet.dispatch(ProductAttributeListPageActions.resetCurrentAttributeSet({action: 'reset'}));
    this.products$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      if (item.length && !this.isFirstPage) {
        this.checkStatus(item);
      }
    });
    this.selection.changed.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.selected = this.selection.selected;
    });
  }

  changeInputSearch(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  changePage(event) {
    this.load.emit([
      event,
      this.filterBar.filterForm.value
    ]);
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.id).indexOf(el.id) < 0;
        });
    }

    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      if (rows) {
        if (res.length < rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      } else {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      }
    }
  }

  checkSelected(row) {
    return this.selected.some(item => item.id === row.id);
  }
  toggleSelection(row) {
    const check = this.selected.some(item => item.id === row.id);
    check ? this.selected = this.selected.filter(item => item.id !== row.id) : this.selected.push(row);
    this.checkStatus();
  }
  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }
  loadProduct(page, pageSize, filter?) {
    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: filter || {},
        page,
        pageSize,
      })
    );
  }

  updateStatusProduct(element, status) {
    const object = {
      status,
      barcodes: [element.barcode]
    };
    this.productService.updateStatusProduct(object).pipe(
      takeUntil(this.destroy$)
    )
    .subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionUpdateStatusProduct(object);
      } else {
        this._snackBar.open('Cập nhật trạng thái sản phẩm thành công', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.loadProduct(this.page, this.pageSize);
        }, 700);
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionUpdateStatusProduct(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.updateStatusProduct(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Cập nhật trạng thái sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadProduct(this.page, this.pageSize);
          }, 700);
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
    });
    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    const click = new ClickTekoDto('product_list', 'product_update',
      'http://datahub.icheck.vn/products/update/' + row.id , {});
    clickTeko(click);
    this.router.navigate(['/products/update', row.id]);
  }


  activeEdit(index) {
    // console.log(this.groupListAtttribute[index])
      this.arrIndexEnableEdit = index;
  }

  editting(index) {
    this.arrIndexEditting = index;
  }

  inActiveEdit(index) {
    // const i = this.arrIndexEnableEdit.findIndex(it => it === index);
    this.arrIndexEnableEdit = -1;
  }

  submitEdit(event, index, row) {
    event.preventDefault(); // Prevent Form to submit by default
    if (event.target.value !== row.productName) {
      const object = {
        value: event.target.value.trim(),
      };
      this.productService.updateProductName(row.id, object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {

        if (res.code === 1104) {
          if (!this.isOpenPopupConfirm) {
            this.confirmActionUpdateName(row.id, object);
          }
        } else {
          this._snackBar.open('Cập nhật tên sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadProduct(this.page, this.pageSize, this.filter);
          }, 700);
          this.arrIndexEditting = -1;
          this.arrIndexEnableEdit = -1;
        }
      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.arrIndexEditting = -1;
        this.arrIndexEnableEdit = -1;
      });
     } else {
      this.arrIndexEditting = -1;
      this.arrIndexEnableEdit = -1;
      }
    }


    confirmActionUpdateName(id, object) {
      this.isOpenPopupConfirm = true;
      const dialogRef = this.dialog2.open(AlertConfirmComponent, {
        data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
      });
      const request = object;
      dialogRef.afterClosed().subscribe(result => {
        this.isOpenPopupConfirm = false;
        if (result !== 'close') {
          request.params = {
            requestType: 1
          };
          this.productService.updateProductName(id, request).pipe(
            takeUntil(this.destroy$)
          ).subscribe((res: any) => {
            this._snackBar.open('Cập nhật tên sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.loadProduct(this.page, this.pageSize, this.filter);
            }, 700);
            this.arrIndexEditting = -1;
            this.arrIndexEnableEdit = -1;
          }, err => {
            this._snackBar.open(err, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            this.arrIndexEditting = -1;
            this.arrIndexEnableEdit = -1;
          });
        } else {
          this.arrIndexEditting = -1;
          this.arrIndexEnableEdit = -1;
        }
        console.log('The dialog was closed');
      });
    }

    openNewTab(url) {
      const img = buildImageUrls(url).original;
      testImage(img, ( Url , result) => {
        if (result === 'success') {
          window.open(img, '_blank');
        } else {
          this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }
      });
    }

    openUploadDialog(id) {
      console.log('id----------' , id);
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '40rem';
      dialogConfig.height = 'auto';
      dialogConfig.data = {
        label: `Chỉnh sửa ảnh sản phẩm`,
        fileType : 'jpg,jpeg,png,gif,bmp'
      };
      dialogConfig.disableClose = true;
      const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        console.log('id----------2222' , result);
        if (result.data === 'close') {
          return;
        }
        if (result.type === 'uploaded') {

          const object = {
            value: result.data[0].url
          };
          this.productService.updateProductImage(id, object).pipe(
            takeUntil(this.destroy$)
          ).subscribe((res: any) => {
            if (res.code === 1104) {
              this.confirmActionUpdateImage(id, object);
            } else {
              this._snackBar.open('Cập nhật ảnh sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
              });
              setTimeout(() => {
                this.loadProduct(this.page, this.pageSize, this.filter);
              }, 300);
            }

          }, err => {
            this._snackBar.open(err, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          });
        }
      });
    }

    confirmActionUpdateImage(id, object) {
      const dialogRef = this.dialog2.open(AlertConfirmComponent, {
        data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
      });
      const request = object;
      dialogRef.afterClosed().subscribe(result => {
        if (result !== 'close') {
          request.params = {
            requestType: 1
          };
          this.productService.updateProductImage(id, request).pipe(
            takeUntil(this.destroy$)
          ).subscribe((res: any) => {
            this._snackBar.open('Cập nhật ảnh sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
              });
            setTimeout(() => {
                this.loadProduct(this.page, this.pageSize, this.filter);
              }, 300);
          }, err => {
            this._snackBar.open(err, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          });
        } else {
        }
        console.log('The dialog was closed');
      });
    }
  changeFilter(data) {
    console.log(data)

    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;

    this.load.emit([
          this.paginator,
          data
          ]);
  }

  ngAfterViewInit() {

        this.load.emit([
          this.paginator,
          this.filterBar.filterForm.value
        ]);
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
