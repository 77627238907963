import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-product-table-action',
  templateUrl: './product-table-action.component.html',
  styleUrls: ['./product-table-action.component.scss']
})
export class ProductTableActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
