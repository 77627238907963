import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromGln from '../../../gln/reducers';
import {
  GlnActions
} from '../../../gln/actions';

@Component({
  selector: 'ic-warning-product-modal',
  templateUrl: './warning-product-modal.component.html',
  styleUrls: ['./warning-product-modal.component.scss']
})
export class WarningProductModalComponent implements OnInit {

  warningProductForm : any;
  data: any;
  products = [
    {
      id: 1,
      name: 'Đậu rán'
    },
    {
      id: 2,
      name: 'Thịt rán'
    }
  ]


  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<WarningProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.data = data;
    }

  ngOnInit(): void {
    // console.log(this.data.element);
    
    this.warningProductForm = this.formBuilder.group({
      warning_content: ['', Validators.required],
      enterprise: ['', Validators.required],
      file_upload: ['', Validators.required],
      product: ['', Validators.required]
    })
  }


  closeDialogClick() {
    this.dialogRef.close();
  }

  onFormSubmit() {
    // console.log(this.warningProductForm.value)
  }

}
