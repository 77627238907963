<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>{{data.message}}</mdc-dialog-title>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close">Hủy</button>
        <button mdcDialogButton (click) = "openDialogConfirmPassword()">OK</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>