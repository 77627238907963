import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
@Component({
  selector: 'ic-product-create-page',
  template: `
    <mat-card style="margin:1rem;">
      <ic-product-create-update></ic-product-create-update>
    </mat-card>
  `,
  styles: [
    `
      h4 {
        padding: 1rem;
      }
    `
  ]
})
export class ProductCreatePageComponent implements AfterViewInit, OnInit {
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
