import {Component, OnInit, Inject, ElementRef, ViewChild, HostListener, AfterViewInit, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '@category/reducers';
import { CategoryListPageActions } from '@category/actions';
import { Observable, Subject } from 'rxjs';
import { ProductAttributeListPageActions } from '@attribute-set/actions';
import * as fromProductAttribute from '@attribute-set/reducers';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect } from '@angular/material/select';
import { AuthService } from '@src/app/auth/services/auth.service';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function clickTeko(info);
declare function enterScreenView(screenName, contentType );
declare function alertEvent(info );
@Component({
  selector: 'ic-import-excel-product',
  templateUrl: './import-excel-product.component.html',
  styleUrls: ['./import-excel-product.component.scss']
})
export class ImportExcelProductComponent implements OnInit, AfterViewInit , OnDestroy {
  checkTruePropertyExcel = true;
  data: any;
  importForm: FormGroup;
  file = [];
  importName = '';
  isExpand = false;
  categories$: Observable<any>;
  attributeSet$: Observable<any>;
  searchChangeObserver;
  isLoading = false;
  inputSearch = '';
  listAttributeSet: any;
  currentUser: any;
  destroy$ = new Subject();
  checkUpdate = '';
  checkEmptyInput = false;
  isAddNewProductImport = true;
  isUpdateProductImport = true;
  nameFile = '';
  @ViewChild('inputUpload', { read: ElementRef, static: false }) inputUpload: ElementRef;

  @ViewChild(MatSelect) child: MatSelect;

  constructor(public dialogRef: MatDialogRef<ImportExcelProductComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data,
              private fb: FormBuilder,
              private storeCategory: Store<fromCategory.State>,
              private storeAttributeSet: Store<fromProductAttribute.State>,
              private productService: ProductService,
              private _snackBar: MatSnackBar,

              private authService: AuthService) {
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListCategory));
    this.attributeSet$ = storeAttributeSet.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
    this.data = data;
  }

  ngOnInit(): void {
    this.checkUpdate = this.data.checkUpdate || '';
    this.importForm = this.fb.group({
      fileImport: this.fb.control(null, [Validators.required]),
      attributeSetId : this.fb.control(null),
      checkPrefix : this.fb.control(false),
      allow_managed_business : this.fb.control(false),
      allow_not_contribute : this.fb.control(false),
      allow_has_info : this.fb.control(false),
      allow_override_data_excel : this.fb.control(''),
      isSkipBusiness : this.fb.control(false),
      isOnlyPropertiesExcel : this.fb.control(false),
      allow_invalid_code1 : this.fb.control(false),
      allow_invalid_prefix1 : this.fb.control(false),
      allow_empty_gln1 : this.fb.control(false),
      allow_invalid_code2 : this.fb.control(false),
      allow_invalid_prefix2 : this.fb.control(false),
      allow_empty_gln2 : this.fb.control(false),
      inputSearch : this.fb.control(false),
    });

    // this.getListCategory();
    this.getListAttributeSet();

    this.attributeSet$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listAttributeSet = item;
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // console.log(JSON.parse(localStorage.getItem('currentUser')));


  }

  ngAfterViewInit() {
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listAttributeSet.length) {
          this.storeAttributeSet.dispatch(
            ProductAttributeListPageActions.loadProductAttributes({
              filter: {},
              page: 1,
              pageSize: 50,
            })
          );
          this.importForm.patchValue({inputSearch: ''});
        }
      }
    });
  }

  changeSelected(event) {

  }


  clearInputSearch() {
    this.importForm.patchValue({inputSearch: ''});

    this.getListAttributeSet();
  }

  getListAttributeSet() {
    this.storeAttributeSet.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: {},
        page: 1,
        pageSize: 50,
      })
    );
  }

  getListCategory() {
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

  expandDownload() {
    this.isExpand = true;
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        const data = result.data;
        // console.log(data);

        data.map(res => {
          this.file = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          this.file.push(value);
          this.importForm.controls.fileImport.setValue(res.url);
        });
      }
    });
  }
  onRemoveFile(field: string, index: number) {
    this.importForm.controls.fileImport.setValue('');
    this.file = [];

  }
  onSelectionChange(event, item) {
    this.nameFile = item.name;
  }

  onSubmitForm() {
    if (this.isLoading) { return; }
    // console.log(this.importForm.value);
    if ( !this.importForm.value.attributeSetId) { this.checkEmptyInput = true; return; }
    const params = {
      check_prefix: +this.importForm.value.checkPrefix || 0 ,
      allow_managed_business: +this.importForm.value.allow_managed_business || 0,
      allow_not_contribute: +this.importForm.value.allow_not_contribute || 0 ,
      allow_has_info: +this.importForm.value.allow_has_info || 0 ,
      allow_override_data_excel: +this.importForm.value.allow_override_data_excel || 0 ,
      is_skip_business: +!(+this.importForm.value.isSkipBusiness || 0),
      is_only_properties_excel: (+this.importForm.value.isOnlyPropertiesExcel || 0),
      allow_invalid_code: +( this.importForm.value.allow_invalid_code2) || 0,
      allow_invalid_prefix: +( this.importForm.value.allow_invalid_prefix2 ) || 0,
      allow_empty_gln: +( this.importForm.value.allow_empty_gln2)  || 0,
      allow_invalid_code_new: +( this.importForm.value.allow_invalid_code1)  || 0,
      allow_invalid_prefix_new: +( this.importForm.value.allow_invalid_prefix1)  || 0,
      allow_empty_gln_new: +( this.importForm.value.allow_empty_gln1)  || 0,
    };


    if (!this.isAddNewProductImport) {
      delete params.allow_invalid_code_new;
      delete params.allow_invalid_prefix_new;
      delete params.allow_empty_gln_new;

    }

    if (!this.isUpdateProductImport) {
      delete params.allow_managed_business;
      delete params.allow_not_contribute;
      delete params.allow_has_info;
      delete params.allow_override_data_excel;
      delete params.allow_invalid_code;
      delete params.allow_invalid_prefix;
      delete params.allow_empty_gln;
    }
    const object = {
      file: this.importForm.value.fileImport,
      attributeSetId: this.importForm.value.attributeSetId,
      params
    };

    this.isLoading = true;
    console.log(object);
    this.productService.importProduct(object, this.checkUpdate).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (res: any) => {
        console.log(res);
        if (res.code === 4 || res.code === 1234) {
          this._snackBar.open(`${res.message}`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close();
          return;
        }
        const userId = this.authService.currentUserValue.userId;
        let click = {};
        if (!this.checkUpdate) {
          click = new ClickTekoDto('product_import',  'import' , 'http://datahub.icheck.vn/products', {
            fileId: res.data.fileId,
            fileName: res.data.fileName,
            userId
          });
        } else {
          click = new ClickTekoDto('product_import_update',  'import_update' , 'http://datahub.icheck.vn/products', {
            fileId: res.data.fileId,
            fileName: res.data.fileName,
            userId
          });
        }
        clickTeko(click);
        const alert = new AlertTekoDto('success', 'success');
        alertEvent(alert);
        this.isLoading = false;
        this._snackBar.open(`Import thành công `, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close();
      },
      (err: any) => {
        this.isLoading = false;
        this._snackBar.open(`${err}`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        // console.log(err);
      }
    );
  }

  changeInputAttributeSetSearch(event) {
    this.storeAttributeSet.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: { name: event.target.value },
        page: 1,
        pageSize: 50,
      })
    );

  }

  clickAdd(event) {
    this.isAddNewProductImport = event;
  }
  clickUpdate(event) {
    this.isUpdateProductImport = event;
  }

  downloadTemplate() {
    // console.log(this.importForm.value.attributeSetId);
    // this.productService.downloadTemplate(this.importForm.value.attributeSetId);
    this.productService.downloadTemplate(this.importForm.value.attributeSetId)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((x: any) => {
      this.isLoading = false;
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      const link = document.createElement('a');
      link.href = data;
      link.download = this.nameFile;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
      this.dialogRef.close();
    });
  }

  importFile(data: any) {
    // console.log(data);

    if (data) {
      this.importName = data.name;
      this.importForm.controls.fileImport.setValue(data);
    }
  }

  deleteFileImport() {
    this.importName = '';
    this.importForm.controls.fileImport.setValue(null);
    // console.log(this.inputUpload.nativeElement);

    this.inputUpload.nativeElement.value = '';
  }

  clearSelected(key) {
    this.importForm.patchValue({
      [key]: null
    });
    this.importForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
