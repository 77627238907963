<div>
  <!-- <dash-title [title]="title"></dash-title> -->
  <ic-gln-list
    [pageSize]="pageSize"
    [total]="total$ | async"
    [rows]="glns$ | async"
    [displayedColumns]="displayedColumns$ | async"
    (load)="loadGln($event)">
  </ic-gln-list>
</div>
