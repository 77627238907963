import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UploadService} from '../../services';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'ic-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit , OnDestroy {
  data;
  listfiles = [];
  fileType = 'jpg,jpeg,png,gif,bmp,mp4,docs,xlsx,pdf,docx';
  loading = false;
  form = this.fb.group({});
  remoteUrlCtrl: FormControl = new FormControl(null);
  validUrl = false;
  url = new Subject();
  destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UploadComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _snackBar: MatSnackBar,
    private uploadService: UploadService
  ) {
    this.data = data;
    this.remoteUrlCtrl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(
      res => {
          this.loading = true;
          this.validUrl = false;
          this.remoteUrlCtrl.disable({emitEvent: true});
          return this.uploadService.uploadUrl({url: res}).pipe(
            takeUntil(this.destroy$)
          ).subscribe(
            result => {
              console.log('result', result);
              const response = {
                name : result.fileId,
                url: result.src,
                fileId : result.fileId,
              }
              this.loading = false;
              this.remoteUrlCtrl.enable({emitEvent: true});
              console.log('this.remoteUrlCtrl', this.remoteUrlCtrl)
              this.closeDialogClick('uploaded', [response]);
            },
            error => {
              this.loading = false;
              this.remoteUrlCtrl.enable({emitEvent: true});
              this.validUrl = true;
            }
          );
      }
    );
  }

  ngOnInit(): void {
  }

  tusUpload = (item: any) => {
    // console.log(item);
    const a = [];
    if (item) {
      // console.log(item[0].name);
      a.push(item[0]);
      // tslint:disable-next-line:new-parens
      const reader = new FileReader;
      const formData = new FormData();
      formData.append('content', item[0], item[0].name);
      reader.onload = (e) => {
        const fileData = (e.target as any).result;
        const type = item[0].type.indexOf('image');
        if (type === -1) {
          return this.uploadService.uploadPdf(formData).pipe(
            takeUntil(this.destroy$)
          ).subscribe(res =>
            (err) => {
              this._snackBar.open(err, 'Đóng', {
                duration: 2000,
                horizontalPosition: 'right',
                verticalPosition: 'bottom'
              });
            }
          );
        }
        return this.uploadService.createFlash(fileData).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
        },
          (err) => {
            this._snackBar.open(err, 'Đóng', {
              duration: 2000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom'
            });
          }
        );
      };
      reader.readAsArrayBuffer(a[0]);
    }
  }

  changeFile(files: FileList) {
    console.log('-------------------------------')
    const promise = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const type = file.name.split('.').pop().toLowerCase();
      if (this.data?.fileType) {
        if (!type || this.data.fileType.toLowerCase().indexOf(type) < 0) {
          this._snackBar.open('Upload chứa file không được hỗ trợ', 'Đóng', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
          this.closeDialogClick('uploaded', 'close');
          return;
        }
      } else {
        if (!type || this.fileType.toLowerCase().indexOf(type) < 0) {
          this._snackBar.open('Upload chứa file không được hỗ trợ', 'Đóng', {
            duration: 2000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom'
          });
          this.closeDialogClick('uploaded', 'close');
          return;
        }
      }

      if (file.size > 5000000) {
        this._snackBar.open('File không vượt quá 5MB', 'Đóng', {
          duration: 2000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom'
        });
        this.closeDialogClick('uploaded', 'close');
        return;
      }
      this.loading = true;
      this.listfiles.push(file);
      promise.push(
        new Promise(((resolve, reject) => {
          const myReader = new FileReader();
          myReader.onload = (e) => {
            const fileData = (e.target as any).result;
            const formData = new FormData();
            formData.append('my_field', 'file');
            formData.append('my_file', file, file.name);
            this.uploadService.uploadFile(formData).pipe(
              takeUntil(this.destroy$)
            ).subscribe(
              res => {
                const result = res.data;
                result.name = file.name;
                result.url = res.data.url;
                result.fileId = res.data.fileId
                resolve(result);
              },
              error => {
                      reject(error);
                    }
            );
          };
          myReader.readAsArrayBuffer(file);
        }))
      );
    }
    Promise.all(promise).then(
      response => {
        console.log('response', response)
        this.loading = false;
        this.closeDialogClick('uploaded', response);
      }
    ).catch(err => {
      this.listfiles = [];
      this.loading = false;
      this._snackBar.open('Upload file thất bại', 'Đóng', {
        duration: 2000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      });
    });
  }

  closeDialogClick(type, value) {
    const result = {
      type,
      data: value
    };
    // @ts-ignore
    console.log(result)
    this.dialogRef.close(result);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
