import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/product.module').then(m => m.ProductModule),
  },
  // {
  //   path: 'product',
  //   loadChildren: () =>
  //     import('./product/product.module').then(m => m.ProductModule),
  // },
  {
    path: 'product-warning',
    loadChildren: () =>
      import('./product-warning/product-warning.module').then(m => m.ProductWarningModule),
  },
  {
    path: 'national',
    loadChildren: () =>
      import('./national/national.module').then(m => m.NationalModule),
  },
  {
    path: 'category',
    loadChildren: () =>
      import('./category/category.module').then(m => m.CategoryModule),
  },
  {
    path: 'alert',
    loadChildren: () =>
      import('./alert/alert.module').then(m => m.AlertModule)
  },
  {
    path: 'attribute-group-product',
    loadChildren: () =>
      import('./product-attribute-group/product-attribute-group.module').then(m => m.ProductAttributeGroupModule)
  },
  {
    path: 'role-group',
    loadChildren: () =>
      import('./role-group/role-group.module').then(m => m.RoleGroupModule)
  },
  {
    path: 'role-list',
    loadChildren: () =>
      import('./role-list/role-list.module').then(m => m.RoleListModule)
  },
  {
    path: 'user-list-cms',
    loadChildren: () =>
      import('./user-list-cms/user-list-cms.module').then(m => m.UserListCmsModule)
  },
  {
    path: 'filter-bar',
    loadChildren: () =>
      import('./dash/filter-bar/filter-bar.module').then(m => m.DashFilterBarModule),
  },
  {
    path: 'attribute',
    loadChildren: () =>
      import('./attribute/attribute.module').then(m => m.AttributeModule),
  },
  {
    path: 'gln',
    loadChildren: () =>
      import('./gln/gln.module').then(m => m.GlnModule),
  },
  {
    path: 'warning-product',
    loadChildren: () =>
      import('./warning-product/warning-product.module').then(m => m.WarningProductModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'log-import',
    loadChildren: () =>
      import('./log-import/log-import.module').then(m => m.LogImportModule),
  },
  {
    path: 'setting-configuration',
    loadChildren: () =>
      import('./setting-configuration/setting-configuration.module').then(m => m.SettingConfigurationModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
