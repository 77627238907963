export const TekoValueDto = {
    PRODUCT_UPDATE_STATUS_SCAN : {
      SCREEN_NAME: 'product_update_status_scan',
      CONTENT_TYPE: 'product',
      REGION_NAME: 'product_update_status_scan',
      CONTENT_NAME: 'update',
      TARGET: 'http://datahub.icheck.vn/products/',
      ALERT_TYPE: {
        SUCCESS : 'success',
        FAILED: 'failed',
        TIMEOUT: 'timeout'
      },
      ALERT_MESSAGE: {
        SUCCESS : 'success',
        FAILED: 'failed',
        TIMEOUT: 'timeout'
      }
     },
  PRODUCT_UPDATE_STATUS_CONTRIBUTE : {
    SCREEN_NAME: 'product_update_status_contribute',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'product_update_status_contribute',
    CONTENT_NAME: 'update',
    TARGET: 'http://datahub.icheck.vn/products',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  PRODUCT_DELETE_ATTRIBUTE : {
    SCREEN_NAME: 'product_delete_attribute',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'product_delete_attribute',
    CONTENT_NAME: 'update',
    TARGET: 'http://datahub.icheck.vn/products',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  PRODUCT_EXPORT: {
    SCREEN_NAME: 'product_export',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'product_export',
    CONTENT_NAME: 'export',
    TARGET: 'http://datahub.icheck.vn/products',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_LIST: {
    SCREEN_NAME: 'enterprise_list',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_list',
    CONTENT_NAME: {
      CREATE: 'create',
      IMPORT: 'import',
      EXPORT: 'export',
      UPDATE: 'update',
      DELETE: 'delete',
    },
    TARGET: {
      CREATE: 'http://datahub.icheck.vn/gln/create',
      UPDATE: 'http://datahub.icheck.vn/gln/update/'
    },
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_CREATE : {
    SCREEN_NAME: 'enterprise_create',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_list',
    CONTENT_NAME: 'create',
    TARGET: 'http://datahub.icheck.vn/gln',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_IMPORT: {
    SCREEN_NAME: 'enterprise_import',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_import',
    CONTENT_NAME: 'import',
    TARGET: 'http://datahub.icheck.vn/gln',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_DETAIL: {
    SCREEN_NAME: 'enterprise_detail',
    CONTENT_TYPE: 'enterprise',
    },
  ENTERPRISE_PRODUCT_OWNER : {
    SCREEN_NAME: 'enterprise_product_owner',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_product_owner',
    CONTENT_NAME: {
      ADD_PRODUCT: 'add_product',
      HIDE: 'hide',
      SHOW: 'show',
      VERIFY: 'verify',
      UNVERIFY: 'unverify',
    },
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_PRODUCT_DISTRIBUTOR: {
    SCREEN_NAME: 'enterprise_product_distributor',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_product_distributor',
    CONTENT_NAME: {
      ADD_PRODUCT: 'add_product',
      HIDE: 'hide',
      SHOW: 'show',
      VERIFY: 'verify',
      UNVERIFY: 'unverify',
    },
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ENTERPRISE_DOCUMENT : {
    SCREEN_NAME: 'enterprise_document',
    CONTENT_TYPE: 'enterprise',
    REGION_NAME: 'enterprise_document',
    CONTENT_NAME: {
      ADD: 'add',
      CREATE: 'create',
      HIDE: 'hide',
      SHOW: 'show',
      VERIFY: 'verify',
      UNVERIFY: 'unverify',
    },
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ALERT_LIST: {
    SCREEN_NAME: 'alert_list',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'enterprise_document',
    CONTENT_NAME: 'create',
  },
  ALERT_CREATE: {
    SCREEN_NAME: 'alert_create',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'alert_create',
    CONTENT_NAME: {
      CREATE: 'create',
      CANCEL : 'cancel'
    }
  },
  ALERT_UPDATE: {
    SCREEN_NAME: 'alert_update',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'alert_update',
    CONTENT_NAME: {
      UPDATE: 'update',
      CANCEL : 'cancel'
    },
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  ALERT_DELETE : {
    SCREEN_NAME: 'alert_delete',
    CONTENT_TYPE: 'product',
    CONTENT_NAME: 'delete',
    REGION_NAME: 'alert_delete',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  CATEGORY_LIST: {
    SCREEN_NAME: 'category_list',
    CONTENT_TYPE: 'product',
    REGION_NAME: 'category_list',
    CONTENT_NAME: 'create',
    TARGET: 'http://datahub.icheck.vn/category',
  },
  CATEGORY_CREATE: {
    SCREEN_NAME: 'category_create',
    CONTENT_TYPE: {
      SAVE: 'save',
      CANCEL: 'cancel'
    },
    REGION_NAME: 'category_create',
    CONTENT_NAME: 'create',
    TARGET: 'http://datahub.icheck.vn/category',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  CATEGORY_UPDATE: {
    SCREEN_NAME: 'category_update',
    CONTENT_TYPE: {
      SAVE: 'save',
      CANCEL: 'cancel'
    },
    REGION_NAME: 'category_update',
    CONTENT_NAME: 'create',
    TARGET: 'http://datahub.icheck.vn/category',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  WARNING_PRODUCT_LIST: {
    SCREEN_NAME: 'warning_product_list',
    CONTENT_TYPE: 'delete',
    REGION_NAME: 'warning_product_list',
    TARGET: 'http://datahub.icheck.vn/product-warning',
    ALERT_TYPE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    },
    ALERT_MESSAGE: {
      SUCCESS : 'success',
      FAILED: 'failed',
      TIMEOUT: 'timeout'
    }
  },
  IMPORT_PRODUCT_LOG: {
    SCREEN_NAME: 'import_product_log',
    CONTENT_TYPE: 'product',
  },
  IMPORT_ENTERPRISE_LOG: {
    SCREEN_NAME: 'import_enterprise_log',
    CONTENT_TYPE: 'enterprise',
  }
}

export const ALERT = {
    SUCCESS : 'success',
    FAILED: 'failed',

}
