import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureBarComponent } from './feature-bar.component';
import { RowComponent } from './row.component';
import { SectionComponent } from './section.component';
import { TitleDirective } from './title.directive';


@NgModule({
  declarations: [
    FeatureBarComponent,
    RowComponent,
    SectionComponent,
    TitleDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FeatureBarComponent,
    RowComponent,
    SectionComponent,
    TitleDirective
  ]
})
export class FeatureBarModule { }
