import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const loadWarningProducts = createAction(
  '[Warning Product Page] Load Warning Products',
  props<{ filter: any }>()
);

export const loadWarningProductsSuccess = createAction(
  '[Warning Product Page] Load Warning Products Success',
  props<{ rows: any, total: number }>()
);

export const loadWarningProductsFailure = createAction(
  '[Warning Product Page] Load Warning Products Failure',
  props<{ error: any }>()
);

export const updateWarningProduct = createAction(
  '[Warning Product Page] Update Warning Product',
  props<{ warningProductObject: any }>()
);

export const updateWarningProductSuccess = createAction(
  '[Warning Product Page] Update Warning Product Success',
  props<{ warningProduct: any }>()
);

export const updateWarningProductFailure = createAction(
  '[Warning Product Page] Update Warning Product Failure',
  props<{ error: any }>()
);

export const addWarningProduct = createAction(
  '[WarningProduct Page] Add Warning Product',
  props<{ warningProductObject: any }>()
);

export const addWarningProductSuccess = createAction(
  '[Warning Product Page] Add Warning Product Success',
  props<{ warningProduct: any }>()
);

export const addWarningProductFailure = createAction(
  '[Warning Product Page] Add Warning Product Failure',
  props<{ error: any }>()
);

export const updateListWarningProduct = createAction(
  '[Warning Product Page] Update List Warning Product',
  props<{ warningProducts: any, warningProduct: any }>()
);

export const updateListWarningProductSuccess = createAction(
  '[Warning Product Page] Update List Warning Product Success',
  props<{ warningProduct: any }>()
);

export const updateListWarningProductFailure = createAction(
  '[Warning Product Page] Update List Warning Product Failure',
  props<{ error: any }>()
);

export const searchWarningProduct = createAction(
  '[Warning Product Page] Search Warning Product',
  props<{ objectSearch: any }>()
);

export const searchWarningProductSuccess = createAction(
  '[Warning Product Page] Search Warning Products Success',
  props<{ rows: any, total: number }>()
);

export const searchWarningProductFailure = createAction(
  '[Warning Product Page] Search Warning Products Failure',
  props<{ error: any }>()
);

export const deleteWarningProduct = createAction(
  '[WarningProduct Page] Delete Warning Product',
  props<{ id: number }>()
);

export const deleteWarningProductSuccess = createAction(
  '[Warning Product Page] Delete Warning Products Success',
  props<{ id: number }>()
);

export const deleteWarningProductFailure = createAction(
  '[Warning Product Page] Delete Warning Products Failure',
  props<{ error: any }>()
);
