import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Directive, OnDestroy, ElementRef, Output, EventEmitter } from '@angular/core';
import { MdcTopAppBar } from '@angular-mdc/web/top-app-bar';
import { MdcRipple, MDCRippleCapableSurface } from '@angular-mdc/web/ripple';
import { MDCRippleAdapter, MDCRippleFoundation } from '@material/ripple';

@Directive({
  selector: '[icTopAppBarUser], ic-top-bar-user',
  exportAs: 'icTopAppBarUser',
  host: {
    'role': 'button',
    'class': 'ic-top-app-bar__user'
  },
  providers: [MdcRipple]
})
export class TopAppBarUser implements OnDestroy, MDCRippleCapableSurface {
  _root!: Element;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private _ripple: MdcRipple) {
    this._root = elementRef.nativeElement;
    _ripple = this._createRipple();
    _ripple.init();
  }

  ngOnDestroy(): void {
    this._ripple.destroy();
  }

  private _createRipple(): MdcRipple {
    const adapter: MDCRippleAdapter = {
      ...MdcRipple.createAdapter(this),
      isUnbounded: () => true
    };
    return new MdcRipple(this.elementRef, new MDCRippleFoundation(adapter));
  }
}

@Component({
  selector: 'ic-top-app-bar',
  exportAs: 'icTopAppBar',
  host: {
    class: 'ic-top-app-bar'
  },
  templateUrl: './top-app-bar.component.html',
  styleUrls: ['./top-app-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TopAppBarComponent extends MdcTopAppBar {

  @Output()
  navIconClick = new EventEmitter<void>();
}
