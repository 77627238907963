<mat-form-field appearance="outline" class="form-field" (click)="openSelectPopup()">
  <input matInput placeholder="Chọn danh mục"  [value]="categorySelected" [title]="categorySelected">
  <mat-icon matSuffix >arrow_drop_down</mat-icon>
</mat-form-field>
<div *ngIf="isSelectPopup" class="menu-select">
  <ul>
    <li *ngFor="let item of items;index as i" class="item" (click)="loadChildren(item, i)" [ngClass]="{'item-active': item?.id === currentItem?.id}">
      <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon>
      <span [ngClass]="{'item-not-has-children': !item.children.length}">{{item.name}}</span>
      <!-- <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon> -->
    </li>
  </ul>

  <ul *ngIf="listTreeGrantTwo.length">
    <li *ngFor="let it of listTreeGrantTwo;index as i" class="item" (click)="loadChildren(it, i)" [ngClass]="{'item-active': it?.id === currentItem?.id}">
      <mat-icon *ngIf="it.children.length">keyboard_arrow_right</mat-icon>
      <span [ngClass]="{'item-not-has-children': !it.children.length}">{{it.name}}</span>
      <!-- <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon> -->
    </li>
  </ul>
</div>
