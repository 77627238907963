import {AfterViewInit} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {checkFormatBarcode} from "@src/app/shared/validate/validate";

export class ModalScroll  implements AfterViewInit {
  ngAfterViewInit(): void {
    // @ts-ignore
    document.querySelector('.cdk-global-overlay-wrapper').style.overflowY = 'scroll';
    // @ts-ignore
    document.querySelector('.cdk-overlay-pane').style.overflowY = 'scroll';
  }


  formGroup : FormGroup
  check_barcode: any[] = [];
  checkBarcode() {
    this.check_barcode = [];
    const barcode = this.formGroup.value.barcodes.split(/\r?\n/);
    for (let i = 0; i < barcode.length; i++) {
      const check = checkFormatBarcode(barcode[i].substr(0, barcode[i].length - 1));
      // console.log(check);

      const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
      if (result === false) {
        const res = {
          index: i + 1,
          value: barcode[i]
        };
        this.check_barcode.push(res);
      }
    }
  }
  clearListBarCode() {
    // console.log(this.assignAlertForm.value.barcodes)
    const barcode = this.formGroup.value.barcodes.split(/\r?\n/);
    const barCodeValid = [];
    for (let i = 0; i < barcode.length; i++) {
      const check = checkFormatBarcode(barcode[i].substr(0, barcode[i].length - 1));
      // console.log(check);

      const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
      if (result) {
        barCodeValid.push(barcode[i]);
      }
    }

    this.formGroup.patchValue({
      barcodes: barCodeValid.join('\n')
    })
    this.checkBarcode();
    // console.log(barCodeValid);
  }
}
