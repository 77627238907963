import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from './../../material.module';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarsComponent } from './toolbars/toolbars.component';



@NgModule({
  declarations: [ToolbarsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MaterialModule,
    MatButtonModule,
  ],
  exports: [ToolbarsComponent]
})
export class DataTableToolbarsModule { }
