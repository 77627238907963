import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { WarningProductActions } from '../actions';


export const warningProductListFeatureKey = 'warningProductList';

export interface State {
  ids: number[];
  filter: any;
  total: number;
  loading: boolean;
  success: boolean;
}

export const initialState: State = {
  ids: [],
  filter: {},
  total: 0,
  loading: false,
  success: false,
};

export const warningProductListReducer = createReducer(
  initialState,
  on(
    WarningProductActions.loadWarningProducts,
    (state, { filter }) => {
      return {
        ...state,
        filter,
        loading: true,
      }
    }
  ),
  on(
    WarningProductActions.loadWarningProductsSuccess,
    (state, { rows, total }) => {
      return {
        ...state,
        ids: rows.map(item => item.id),
        total,
        loading: false,
      }
    }
  ),
  on(
    WarningProductActions.searchWarningProductSuccess,
    (state, { rows, total }) => {
      return {
        ...state,
        ids: rows.list.map(item => item.id),
        total,
        loading: false,
      }
    }
  ),
  on(
    WarningProductActions.deleteWarningProduct,
    (state, { id }) => {
      return {
        ...state,
        success: false,
      }
    }
  ),
  on(
    WarningProductActions.deleteWarningProductSuccess,
    (state, { id }) => {
      return {
        ...state,
        ids: state.ids.filter(item => item !== id),
        total: state.total,
        loading: false,
        success: true,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return warningProductListReducer(state, action);
}

export const getIds = (state: State) => state.ids;
export const getTotalWarningProduct = (state: State) => state.total;

export const getObjectFilter = (state: State) => state.filter;

export const getLoadingData = (state: State) => state.loading;

export const getSuccess = (state: State) => state.success;
