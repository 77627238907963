import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AllowOrDenyContributeService} from "@products/components/product-button/allow-or-deny-contribute/service/allow-or-deny-contribute.service";
import {NotificationService} from "@src/app/services/notification.service";
import {ModalScroll} from "@src/app/shared/common/modal-scroll";

@Component({
  selector: 'ic-allow-or-deny-contribute',
  templateUrl: './allow-or-deny-contribute.component.html',
  styleUrls: ['./allow-or-deny-contribute.component.scss']
})
export class AllowOrDenyContributeComponent  implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public  data,
              public dialogRef: MatDialogRef<AllowOrDenyContributeComponent>,
              public allowOrDeny: AllowOrDenyContributeService,
              public notification: NotificationService
  ) {

  }

  ngOnInit(): void {

  }
  allow(event: boolean) {
    const barcodes = this.data.selectedRows.map(z => z.barcode).reduce( (a, b) => {
      return a + ',' + b;
    } );
    const body =  {barcodes , accept: event , update_product_business: true};
    this.allowOrDeny.updateProductContribute(body).subscribe( z => {
        this.notification.getNotification();
        this.dialogRef.close();
    } , error => {
      this.notification.getNotification('Thất bại' , null, 'fail' );
      this.dialogRef.close();
    });
  }
}
