import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import { ProductAttributeListPageActions } from '@attribute-set/actions';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoryPageActions } from '@category/actions';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'ic-assign-product-modal',
  templateUrl: './assign-product-modal.component.html',
  styleUrls: ['./assign-product-modal.component.scss']
})
export class AssignProductModalComponent implements OnInit {

  data: any;
  attributeSets$: Observable<any>;
  searchAttributeSetObserver: any;
  attributeSetForm : any;
  listAttributeSet:any;
  destroy$ = new Subject();
  
  @ViewChild('matSelect') matSelect: MatSelect;
  
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AssignProductModalComponent>,
    private store: Store<fromProductAttribute.State>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.attributeSets$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
    this.data = data;
  }

  ngOnInit(): void {
    this.attributeSetForm = this.formBuilder.group({
      id: [ '', Validators.required],
      inputSearch: ['']
    });
    // console.log(this.data);
    this.store.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: {},
        page: 1,
        pageSize: 50,
      })
    );

    this.attributeSets$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listAttributeSet = item;
    })
  }

  ngAfterViewInit() {
    this.matSelect.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened) {
        if (!this.listAttributeSet.length) {
          this.store.dispatch(
            ProductAttributeListPageActions.loadProductAttributes({
              filter: { },
              page: 1,
              pageSize: 50,
            })
          );
          this.attributeSetForm.patchValue({inputSearch: ''})
        }
      }
    })
  }

  clearInputAttributeSetSearch() {
    this.attributeSetForm.patchValue({inputSearch: ''})
    this.store.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: { },
        page: 1,
        pageSize: 50,
      })
    );
  }

  changeInputSearch(event) {
    if (!this.searchAttributeSetObserver) {
      Observable.create(observer => {
        // console.log(observer);
        
        this.searchAttributeSetObserver = observer;
      }).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        ) 
        .subscribe(it => {
          // console.log(it)
          this.store.dispatch(
            ProductAttributeListPageActions.loadProductAttributes({
              filter: { name: it },
              page: 1,
              pageSize: 50,
            })
          );
        });
    }
    this.searchAttributeSetObserver.next(event.target.value);
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }


  onFormSubmit() {
    // console.log(this.attributeSetForm.value);
    const object = {
      attributeSetId: this.attributeSetForm.value.id,
      categoryIds: this.data.map(item => item.id)
    }
    this.store.dispatch(CategoryPageActions.assignAttributeSet({object}));
    this.dialogRef.close();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
