import { Action, createReducer, on } from '@ngrx/store';

import { AlertListPageActions } from '../actions';


export const alertListPageFeatureKey = 'alertListPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: number;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'order_sort', 'rating_attribute', 'product_attribute', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: null
};

const alertListPageReducer = createReducer(
  initialState,
  on(
    AlertListPageActions.loadAlerts,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    AlertListPageActions.loadAlertsSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false
      }
    }
  ),
  on(
    AlertListPageActions.loadAlertsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    AlertListPageActions.loadListAlertByParent,
    (state, { filter, limit, offset, parent_id }) => {
      return {
        ...state,
        filter,
        limit,
        offset,
        loading: true,
        parent_id
      }
    }
  ),
  on(
    AlertListPageActions.loadListAlertByParentSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false
      }
    }
  ),
  on(
    AlertListPageActions.loadListAlertByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return alertListPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getAlertParentId = (state: State) => state.parent_id;
