<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface style="width: 30rem;">
      <mat-toolbar class="header-dialog">
		    <mat-icon class="icon-add">add_circle</mat-icon>
		    <label class="header-text">Lọc theo {{ data.filter.name | lowercase}}</label>
	  	</mat-toolbar>
      <mdc-dialog-content>
        <form [formGroup]="form"  (ngSubmit)="onApply()" autocomplete="off" style="margin-top: 5px;">
          <mat-form-field class="example-full-width" appearance="outline" style="margin-right: 1rem;">
              <input matInput type="number" outline placeholder="Từ" [(ngModel)] = "fromNumber" formControlName="fromNumber" outlined [(ngModel)] = "fromNumber" min=0 >
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline">
              <input matInput type="number" outline placeholder="Đến" [(ngModel)] = "toNumber"  formControlName="toNumber" outlined [(ngModel)] = "toNumber" min=0>
            </mat-form-field>
        </form>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close" class="btn-cancel">Hủy</button>
        <button mdcDialogButton [disabled]="!form.valid" (click)="onApply()" class="btn-apply">Đồng ý</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
