import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import * as fromAlert from '../reducers';
declare var $: any;
import { AlertActions } from '../actions';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';

declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  files = [];
  alertForm: any;
  data: any;
  config = {
    placeholder: '',
    tabsize: 1,
    height: '120px',
    disableDragAndDrop: true,
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['link', 'video']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AlertModalComponent>,
    private store: Store<fromAlert.State>,
    private _snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    if (this.data.action === 'create') {
      enterScreenView(TekoValueDto.ALERT_CREATE.SCREEN_NAME, TekoValueDto.ALERT_CREATE.CONTENT_TYPE);
    } else {
      enterScreenView(TekoValueDto.ALERT_UPDATE.SCREEN_NAME, TekoValueDto.ALERT_UPDATE.CONTENT_TYPE);
    }
    const that = this;
    this.alertForm = this.formBuilder.group({
      title: [this.data?.data?.title || '', Validators.compose([Validators.required, noWhitespaceValidator])],
      description: [this.data?.data?.description || '', [Validators.required, noWhitespaceValidator]],
      content: [this.data?.data?.content || '', [Validators.required, noWhitespaceValidator]],
    });
    $(document).ready(() => {
      $('.note-insert').append('<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>');
      $('.content .uploadImg').click(() => {
        that.openUploadDialog('content');
      });
    });
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

  openUploadDialog(field: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${field}`,
      fileType : 'jpg,jpeg,png,gif,bmp'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        const description = [];
        let image = '';
        result.data.forEach(e => {
              image += `<div style="text-align: center"><img src="${e.url}" class='imgSummernote'></div><br>`;
            });
        description.push(image);
        this.alertForm.get('content').setValue(this.alertForm.get('content').value + description[0]);
          // console.log(this.alertForm.get('content').value);
          // console.log(description);
        this.alertForm.updateValueAndValidity();
      }
    });
  }

  onFormSubmit() {
    // console.log(this.alertForm.value);
    if (this.data.action === 'create') {
      const object = {
        ...this.alertForm.value,
        title: this.alertForm.value.title.trim(),
        description: this.alertForm.value.description.trim(),
      };
      const click = new ClickTekoDto(TekoValueDto.ALERT_CREATE.SCREEN_NAME, TekoValueDto.ALERT_CREATE.CONTENT_NAME.CREATE, '', {})
      clickTeko(click);
      this.store.dispatch(AlertActions.addAlert({ alertObject: object }));
    } else if (this.data.action === 'update') {
      const object = {
        id: this.data?.data?.id,
        ...this.alertForm.value,
        title: this.alertForm.value.title.trim(),
        description: this.alertForm.value.description.trim(),
      };
      const click = new ClickTekoDto(TekoValueDto.ALERT_UPDATE.SCREEN_NAME, TekoValueDto.ALERT_UPDATE.CONTENT_NAME.UPDATE, '', {})
      clickTeko(click);
      this.store.dispatch(AlertActions.updateAlert({ alertObject: object }));
    }
    const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
    alertEvent(alert);
    this.dialogRef.close('finish');
  }

}
