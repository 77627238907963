import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromProductAttribute from '../../reducers';
import { ProductAttributePageActions, ProductAttributeListPageActions } from '../../actions';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CategoryListPageActions } from '@category/actions';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ic-product-attribute-group-update',
  templateUrl: './product-attribute-group-update.component.html',
  styleUrls: ['./product-attribute-group-update.component.scss']
})
export class ProductAttributeGroupUpdateComponent implements OnInit {
  data;
  panelOpenState = false;
  currentAttributeSet$: Observable<any>;
  listAttributeIgnore$: Observable<any>;
  currentAttributeSet: any;
  attributeSetForm : any;
  treeControl = new NestedTreeControl<any>(node => node.children);
  dataSource = new MatTreeNestedDataSource<any>();
  countlistAttributeIgnore$: Observable<any>;
  listAttributeIgnore: any;
  isAddGroupAttribute = false;
  groupListAtttribute = []
  arrIndexExpand = [];
  arrIndexEnableEdit = [];
  arrIndexEditting = [];
  isHovered = false;
  isEditing = false;
  searchAttributeObserver;
  listCurrentAttributeIgnore = []
  destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private store: Store<fromProductAttribute.State>,
    public dialogRef: MatDialogRef<ProductAttributeGroupUpdateComponent>,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.data = data;
    this.currentAttributeSet$ = store.pipe(select(fromProductAttribute.selectCurrentAttributeSetListPageColumns));
    this.listAttributeIgnore$ = store.pipe(select(fromProductAttribute.selectListAttributeIgnoreListPageColumns));
    this.countlistAttributeIgnore$ = store.pipe(select(fromProductAttribute.selectListAtrributeIgnoreListPageColumns));
  }

  ngOnInit(): void {
    console.log('a11111111');
    
    this.currentAttributeSet$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      // console.log(it);
      
      if (it.hasOwnProperty('id')) {
        this.currentAttributeSet = it;
        this.dataSource.data = it.groupAttribute;
        this.groupListAtttribute = it.groupAttribute;
      }
      this.attributeSetForm = this.formBuilder.group({
        name: [it?.name || '', [Validators.required, noWhitespaceValidator]],
        groups: [ it?.groupAttribute || '', Validators.required],
      });
    })
    // console.log(this.currentAttributeSet);
    this.listAttributeIgnore$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      // console.log(it);
      this.listAttributeIgnore = it;
      this.listCurrentAttributeIgnore = it;
      
    })
    this.countlistAttributeIgnore$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      // console.log(it);
      
    });
    this.getListAttributeIgnore({
      filter: {},
      pageSize: 500,
      page: 1,
      attribute_set_id: this.data.element.id
    })
  }

  getListAttributeIgnore(object) {
    this.store.dispatch(ProductAttributePageActions.getListAttributeIgnoreSet({ 
      attribute_set_id: object.attribute_set_id,
      filter: object.filter,
      page: object.page,
      pageSize: object.pageSize,
    }))
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  onFormSubmit() {
    const object = Object.assign({}, this.currentAttributeSet);
    const newArr = [];
    this.groupListAtttribute.forEach(item => {
      const newObject = Object.assign({}, item);
      // arrAttribute.map(val => val.id);
      // console.log(arrAttribute);
      
      // newObject.attributeValues = arrAttribute;
      // item = newObject;
      // console.log(item);
      // console.log(this.currentAttributeSet);
      const arrAttribute  = item.attributeValues.map(val => val.attributeId);
      item.attributeValues
      const objectUpdate = {
          id: item.id,
          name: item.groupName,
          attributeIds: arrAttribute
      }
      
      newArr.push(objectUpdate)
    })

    // console.log(newArr);
    
    object.groups = newArr;
    object.name = this.attributeSetForm.value.name.trim();
    // console.log(object);
    this.store.dispatch(ProductAttributePageActions.updateProductAttribute({ productAttributeObject: object }));    
    this.dialogRef.close();
  }

  swapArrayElements = function(i, indexA, indexB) {
    const item = Object.assign({}, this.groupListAtttribute[i]);
    const arr = [...item.children];
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    item.children = arr;
    const newAtt = this.groupListAtttribute.slice()
    newAtt[i] = item;
    this.groupListAtttribute = newAtt;
    // console.log(this.groupListAtttribute);
    
  };

  clamp$1(value, max) {
    return Math.max(0, Math.min(max, value));
  }

  moveItemInArray(i, fromIndex, toIndex, position) {
    /** @type {?} */
    if (position === 'left') {
     this.swapAttributeGroup(i, fromIndex, toIndex);
    } else {
      this.swapAttributeIgnore(fromIndex, toIndex);
    }
  }

  transferArrayItem(currentArray, targetArray, currentIndex, targetIndex, i, position) {
    // console.log(currentArray);
    // console.log(targetArray);
    if (position === 'right') {
      this.tranferFromAttributeGroup(currentArray, currentIndex, targetIndex)
    } else {
      const arrIndexAttributeIgnore = this.listAttributeIgnore.map(item => item.attributeId);

      const index = currentArray.findIndex(item => {
        return arrIndexAttributeIgnore.indexOf(item.attributeId) !== -1;
      })
      if (index !== -1 ) {
        this.tranferFromAttributeIgnore(currentArray, targetArray, currentIndex, targetIndex, i)
      } else {
        this.tranferGroupAttribute(currentArray, targetArray, currentIndex, targetIndex)
      }
    }
    
  }

  drop(event: CdkDragDrop<any>, index: any, position) {
    // console.log(event, index, position);
    if (event.previousContainer === event.container) {
      // this.swapArrayElements(index, event.previousIndex, event.currentIndex);
      this.moveItemInArray(index, event.previousIndex, event.currentIndex, position);
      // console.log(this.listAttributeIgnore)
      // console.log(this.currentAttributeSet);
      
    } else {
      this.transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex,
                        index, position);
                        // console.log(this.listAttributeIgnore)
      // console.log(this.currentAttributeSet)
    }
  }

  swapAttributeGroup(i, fromIndex, toIndex) {
    console.log('trung111');

    const item = Object.assign({}, this.groupListAtttribute[i]);
    const arr = [...item.attributeValues];
    const from = this.clamp$1(fromIndex, arr.length - 1);
    /** @type {?} */
    const to = this.clamp$1(toIndex, arr.length - 1);
    if (from === to) {
        return;
    }
    /** @type {?} */
    const target = arr[from];
    /** @type {?} */
    const delta = to < from ? -1 : 1;
    for (let i = from; i !== to; i += delta) {
      arr[i] = arr[i + delta];
    }
    arr[to] = target;
    item.attributeValues = arr;
    const newAtt = this.groupListAtttribute.slice()
    newAtt[i] = item;
    this.groupListAtttribute = newAtt;
  }

  swapAttributeIgnore(fromIndex, toIndex) {
    const arr = [...this.listAttributeIgnore];
    const from = this.clamp$1(fromIndex, arr.length - 1);
    /** @type {?} */
    const to = this.clamp$1(toIndex, arr.length - 1);
    if (from === to) {
        return;
    }
    /** @type {?} */
    const target = arr[from];
    /** @type {?} */
    const delta = to < from ? -1 : 1;
    for (let i = from; i !== to; i += delta) {
      arr[i] = arr[i + delta];
    }
    arr[to] = target;
    this.listAttributeIgnore = arr;
  }

  tranferFromAttributeGroup(currentArray, currentIndex, targetIndex) {
    // console.log(currentIndex);
    // console.log(this.listAttributeIgnore);
    const indexSource = this.groupListAtttribute.findIndex(it => {
      return JSON.stringify(it.attributeValues) == JSON.stringify(currentArray)
    });
    const item = Object.assign({}, this.groupListAtttribute[indexSource]);
    // console.log('tttttttttt', item);
    if (item.baseAttributeSet) {
      this._snackBar.open('Không được thay đổi thuộc tính của bộ thuộc tính cơ bản', 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      return;
    }
    const arrTarget = [...this.listAttributeIgnore];
    const arrCurrent = [...item.attributeValues];
    const newAtt = this.groupListAtttribute.slice()
    /** @type {?} */
    const from = this.clamp$1(currentIndex, arrCurrent.length - 1);
    /** @type {?} */
    const to = this.clamp$1(targetIndex, arrTarget.length);
    // console.log(this.listCurrentAttributeIgnore);
    // console.log(arrCurrent[from]);
    this.listCurrentAttributeIgnore = this.listCurrentAttributeIgnore.concat(arrCurrent[from]);
    if (arrCurrent.length) {
      
      // console.log(arrTarget);
      arrTarget.splice(to, 0, arrCurrent.splice(from, 1)[0]);
      
    }
    // console.log(arrTarget);
    // console.log(arrCurrent);
    item.attributeValues = arrCurrent;
    newAtt[indexSource] = item;
    this.groupListAtttribute = newAtt;
    this.listAttributeIgnore = arrTarget;
    
    // 
    // console.log(this.listCurrentAttributeIgnore);
    // console.log(this.listAttributeIgnore);
  }

  tranferFromAttributeIgnore(currentArray, targetArray, currentIndex, targetIndex, indexGroup) {
    // console.log(this.groupListAtttribute);
    // console.log(targetArray);
    // console.log(currentArray, currentIndex);
    let result = [];
    this.groupListAtttribute.map(item => {
      const arrId = item.attributeValues.map(it => it.attributeId);
      // console.log('arrId', arrId);
      result = result.concat(arrId)
    })
    // console.log('resultttttttttt', result);

    if (result.indexOf(currentArray[currentIndex].attributeId) !== -1) {
      console.log('aaaaaaaaaaaaaaaa');
      this._snackBar.open('Thuộc tính đã tồn tại ở một nhóm thuộc tính khác', 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      return;
    }
    const indexSource = this.groupListAtttribute.findIndex(it => {
      return JSON.stringify(it.attributeValues) === JSON.stringify(targetArray)
    });
    const item = Object.assign({}, this.groupListAtttribute[indexGroup]);
    if (item.baseAttributeSet) {
      this._snackBar.open('Không được thay đổi các thuộc tính của bộ thuộc tính cơ bản', 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      return;
    }
    
    const arrCurrent = [...this.listAttributeIgnore];
    const arrTarget = [...item.attributeValues];
    
    const newAtt = this.groupListAtttribute.slice();
    /** @type {?} */
    const from = this.clamp$1(currentIndex, arrCurrent.length - 1);
    /** @type {?} */
    const to = this.clamp$1(targetIndex, arrTarget.length);
    // console.log(this.listCurrentAttributeIgnore, from);
    
    // const arrListCurrent = [...this.listCurrentAttributeIgnore];
    // arrListCurrent.splice(from, 1)[0];
    this.listCurrentAttributeIgnore = this.listCurrentAttributeIgnore.filter(item => item.attributeId !== arrCurrent[from].attributeId);
    // console.log(this.listCurrentAttributeIgnore);
    
    if (arrCurrent.length) {
      // console.log(arrTarget);
      arrTarget.splice(to, 0, arrCurrent.splice(from, 1)[0]);
      
    }
    // console.log(arrTarget);
    // console.log(arrCurrent);

    
    item.attributeValues = arrTarget;
    newAtt[indexGroup] = item;
    // console.log(indexGroup, newAtt);
    
    this.groupListAtttribute = newAtt;
    this.listAttributeIgnore = arrCurrent;
    // console.log(this.listCurrentAttributeIgnore);
    // console.log(this.listAttributeIgnore);
    // if (this.listAttributeIgnore.length - this.listCurrentAttributeIgnore.length === 1) {
    //   this.listCurrentAttributeIgnore = arrCurrent;
    // } else {
    //   const arrCurrent1 = [...this.listCurrentAttributeIgnore];
    //   arrCurrent1.splice(from, 1)[0];
    //   this.listCurrentAttributeIgnore = arrCurrent1;
    // }
    // this.listCurrentAttributeIgnore = arrCurrent;
    
  }

  tranferGroupAttribute(currentArray, targetArray, currentIndex, targetIndex) {
    // console.log(currentArray);
    // console.log(targetArray);
    // console.log(currentIndex, targetIndex);
    const indexDes = this.groupListAtttribute.findIndex(it => {
      return JSON.stringify(it.attributeValues) == JSON.stringify(targetArray)
    });
    const indexSource = this.groupListAtttribute.findIndex(it => {
      return JSON.stringify(it.attributeValues) == JSON.stringify(currentArray)
    });

    // console.log(indexSource, indexDes);
    
    const itemSource = Object.assign({}, this.groupListAtttribute[indexSource]);
    const itemDes = Object.assign({}, this.groupListAtttribute[indexDes]);

    // console.log(itemSource, itemDes);
    const arrCurrent = [...itemSource.attributeValues];
    const arrTarget = [...itemDes.attributeValues];
    const newAtt = this.groupListAtttribute.slice()
    // /** @type {?} */
    const from = this.clamp$1(currentIndex, arrCurrent.length - 1);
    // /** @type {?} */
    const to = this.clamp$1(targetIndex, arrTarget.length);
    if (arrCurrent.length) {
      
      // console.log(arrTarget);
      arrTarget.splice(to, 0, arrCurrent.splice(from, 1)[0]);
      
    }
    // console.log(arrTarget);
    // console.log(arrCurrent);
    itemDes.attributeValues = arrTarget;
    itemSource.attributeValues = arrCurrent;
    newAtt[indexSource] = itemSource;
    newAtt[indexDes] = itemDes;
    this.groupListAtttribute = newAtt;
    
  }

  addGroupAttribute() {
    this.isAddGroupAttribute = true;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addGroup(event) {
    // console.log(event);
    if (event.keyCode === 13 || event.which === 13) {

      if (event.target.value.replace(/^\s+|\s+$/g, '').length == 0) {
        console.log('111111111');
        this.isAddGroupAttribute = false;
      } else {
        if (event.target.value) {
        // console.log(event.target.value);
        
        const groupAttribute = {
          groupName: event.target.value,
          attributeValues: []
        }
        // console.log(this.groupListAtttribute);
        
        if (this.groupListAtttribute) {
          this.groupListAtttribute = [
            ...this.groupListAtttribute,
            groupAttribute
          ];
        } else {
          this.groupListAtttribute = [
            groupAttribute
          ];
        }
        
        this.isAddGroupAttribute = false;
        // console.log(this.groupListAtttribute);
        // console.log(groupAttribute);
      }
      }
      
    }
  }

  expandGroup(index) {
    this.arrIndexExpand.push(index);
  }

  collapseGroup(index) {
    const i = this.arrIndexExpand.findIndex(it => it === index);
    this.arrIndexExpand.splice(i, 1);
  }

  activeEdit(index) {
    // console.log(this.groupListAtttribute[index])
    if (!this.groupListAtttribute[index].baseAttributeSet) {
      this.arrIndexEnableEdit.push(index);
    }
  }

  editting(index) {
    this.arrIndexEditting.push(index);
  }

  inActiveEdit(index) {
    const i = this.arrIndexEnableEdit.findIndex(it => it === index);
    this.arrIndexEnableEdit.splice(i, 1);
  }

  blurInput() {
    this.arrIndexEditting = [];
      this.arrIndexEnableEdit = [];
  }

  submitEdit(event, index) {
    // console.log(event);
    
    const { keyCode } = event;
    event.preventDefault(); // Prevent Form to submit by default
    if (keyCode === 13) {
      const item = Object.assign({}, this.groupListAtttribute[index]);
      item.groupName = event.target.value;
      const newAtt = this.groupListAtttribute.slice()
      newAtt[index] = item;
      this.groupListAtttribute = newAtt;
      // this.isEditing = false;
      // const i = this.arrIndexEditting.findIndex(it => it === index);
      // this.arrIndexEditting.splice(i, 1);
      this.arrIndexEditting = [];
      this.arrIndexEnableEdit = [];
    }
  }

  removeGroup(index) {

    // console.log(index);
    let item = Object.assign({}, this.currentAttributeSet);
    const newGroup = [...this.groupListAtttribute];
    newGroup.splice(index, 1);
    item.group_list_attribute = newGroup;
    
    this.currentAttributeSet = item;
    this.groupListAtttribute = this.currentAttributeSet.group_list_attribute;
    this.arrIndexEditting = [];
    this.arrIndexEnableEdit = [];
    // console.log(this.currentAttributeSet);
    
  }

  changeInputSearch(event) {
    // console.log(event.target.value);
    // console.log(this.listCurrentAttributeIgnore);
    
    if (event.target.value) {
      this.listAttributeIgnore = this.listCurrentAttributeIgnore.filter(item => {
        // console.log(item.attributeLabel.toLowerCase().indexOf(event.target.value.toLowerCase()));
        
        return item.attributeLabel.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
      });
    } else {
      this.listAttributeIgnore = this.listCurrentAttributeIgnore;
    }
    // console.log(this.listAttributeIgnore);
    
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
