import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from "@angular/material/chips";
import { MatToolbarModule } from '@angular/material/toolbar';
import { PortalModule } from '@angular/cdk/portal';
import { MdcChipsModule } from '@angular-mdc/web/chips';
import { MdcDialogModule } from '@angular-mdc/web/dialog';
import { MdcFormFieldModule } from '@angular-mdc/web/form-field';
import {MdcTextFieldModule} from '@angular-mdc/web';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcListModule } from '@angular-mdc/web/list';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import {MdcSelectModule} from '@angular-mdc/web';
import { MdcRadioModule } from '@angular-mdc/web/radio';

import { FilterBarComponent } from './filter-bar.component';
import { FilterItemComponent } from './filter-item.component';
import { DateRangeSettingComponent } from './date-range-setting.component';
import { DateRangeDisplayComponent } from './date-range-display.component';
import { SelectBoxDisplayComponent, SelectBoxSettingComponent } from './select-box';
import { SelectSearchBoxDisplayComponent, SelectSearchBoxSettingComponent } from './select-search-box';

import { TextDisplayComponent, TextSettingComponent } from './text';
import { NumberRangeSettingComponent, NumberRangeDisplayComponent } from './number-range';
import { DateDisplayComponent } from './date/date-display.component';
import { DateSettingComponent } from './date/date-setting.component';

@NgModule({
  declarations: [
    FilterBarComponent, FilterItemComponent,
    DateRangeSettingComponent, DateRangeDisplayComponent,
    SelectBoxSettingComponent,
    SelectBoxDisplayComponent,
    SelectSearchBoxDisplayComponent,
    SelectSearchBoxSettingComponent,
    TextSettingComponent,
    TextDisplayComponent,
    NumberRangeSettingComponent,
    NumberRangeDisplayComponent,
    DateDisplayComponent,
    DateSettingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    MdcChipsModule,
    MdcSelectModule,
    MdcDialogModule,
    MdcFormFieldModule,
    MdcTextFieldModule,
    MdcIconModule,
    MdcListModule,
    MdcMenuModule,
    MdcRadioModule,
    PortalModule,
  ],
  exports: [FilterBarComponent]
})
export class DashFilterBarModule { }
