<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mat-toolbar class="header-dialog">
		    <mat-icon class="icon-add">add_circle</mat-icon>
		    <label class="header-text">{{ data.filter.name }}</label>
	  	</mat-toolbar>
      <mdc-dialog-content>
        <div style="margin-left: 30px;">
          <mat-form-field>
            <mat-label>Từ ngày</mat-label>
            <input matInput readonly (click)="picker1.open()" [(ngModel)]="date" [matDatepicker]="picker1" (click)="picker1.open()" (dateChange)="onDateChanged('date', $event)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close" class="btn-cancel">Hủy</button>
        <button mdcDialogButton [disabled]="!form.valid" (click)="onApply()" class="btn-apply">Đồng ý</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>