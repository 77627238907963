import { NgxSummernoteModule } from 'node_modules/ngx-summernote';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FeatureBarModule } from './../dash/feature-bar/feature-bar.module';
import { DashCheckboxModule } from './../dash/checkbox/checkbox.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatTableModule } from '@angular/material/table';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MaterialModule } from './../material.module';
import { AlertContainerComponent } from './containers/alert-container';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MDCDataTableModule, MdcCheckboxModule, MdcCard, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule } from '@angular-mdc/web';

import { AlertRoutingModule } from './alert-routing.module';
import { AlertListComponent } from './alert-list/alert-list.component';
import * as fromEffects from './reducers';
import { AlertEffects } from './effects/alert.effects';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { AlertConfirmModalComponent } from './alert-confirm-modal/alert-confirm-modal.component';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';

@NgModule({
  declarations: [AlertListComponent, AlertContainerComponent, AlertModalComponent, AlertConfirmModalComponent],
  imports: [
    CommonModule,
    AlertRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    // MaterialModule,
    MatTableModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatFormFieldModule,
        MatInputModule,
    DashFilterBarModule,
    MaterialModule,
    MDCDataTableModule, MdcCheckboxModule, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule,
    FeatureBarModule,
    DashFilterBarModule,
    DashCheckboxModule,
    NgxSummernoteModule,
    StoreModule.forFeature(fromEffects.alertFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([AlertEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: []
})
export class AlertModule { }
