import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductActions } from '../actions';


export const productListFeatureKey = 'productList';

export interface State {
  ids: number[];
  filter: any;
  total: number;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  filter: {},
  total: 0,
  loading: false
};

export const productListReducer = createReducer(
  initialState,
  // on(
  //   ProductActions.loadProduct,
  //   (state, { filter }) => {
  //     return {
  //       ...state,
  //       filter,
  //       loading: true,
  //     }
  //   }
  // ),
  // on(
  //   ProductActions.loadCategoriesSuccess,
  //   (state, { products, total }) => {
  //     return {
  //       ...state,
  //       ids: products.map(item => item.id),
  //       total: total,
  //       loading: false,
  //     }
  //   }
  // ),
  // on(
  //   ProductActions.searchProductSuccess,
  //   (state, { products }) => {
  //     return {
  //       ...state,
  //       ids: products.list.map(item => item.id),
  //       total: products.total,
  //       loading: false,
  //     }
  //   }
  // ),
);

export function reducer(state: State | undefined, action: Action) {
  return productListReducer(state, action);
}

export const getIds = (state: State) => state.ids;
export const getTotalProduct = (state: State) => state.total;

export const getObjectFilter = (state: State) => state.filter;

export const getLoadingData = (state: State) => state.loading;
