import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { CategoryActions, CategoryPageActions, CategoriesAPIActions } from '../actions';
import { Category } from '../models';

export const categoryFeatureKey = 'category';

export interface State extends EntityState<Category> {
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  selectId: (category: Category) => category.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
});

const categoryReducer = createReducer(
  initialState,
  on(CategoryPageActions.updateCategory, (state, { categoryObject }) => {
    // console.log(categoryObject);

    return adapter.updateOne({ id: categoryObject.id, changes: categoryObject }, state);
  }),
  on(CategoryPageActions.updateCategoryFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(CategoryPageActions.addCategory, (state, { categoryObject }) => {
    // console.log(categoryObject);

    return adapter.addOne(categoryObject , state);
  }),
  on(CategoryPageActions.addCategoryFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  // on(CategoryActions.updateListCategory, (state, { categories, category }) => {
  //   return adapter.upsertMany(categories, state);
  // }),
  // on(CategoryActions.updateListCategoryFailure, (state, { error }) => {
  //   return {
  //     ...state,
  //     error
  //   };
  // }),
  // on(CategoryActions.searchCategorySuccess, (state, { categories }) => {
  //   // console.log(categories);

  //   return adapter.upsertMany(categories.list, state);
  // }),
  // on(CategoryActions.searchCategoryFailure, (state, { error }) => {
  //   return {
  //     ...state,
  //     error
  //   };
  // }),
  // on(CategoryPageActions.loadCategoriesSuccess, (state, { rows, total }) => {
  //   return adapter.upsertMany(rows, state);
  // }),
  on(CategoriesAPIActions.loadCategoriesSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),
  on(CategoryPageActions.loadListCategoryByParentSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  on(CategoriesAPIActions.loadCategoryByParentSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return categoryReducer(state, action);
}
