export class ClickTekoDto {
  regionName: string;
  contentName: string;
  target: string;
  payload;
  constructor(regionName , contentName, target, payload: {}) {
    this.regionName = regionName;
    this.contentName = contentName;
    this.target = target;
    this.payload = payload;

  }
}
