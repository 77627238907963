import { createAction, props } from '@ngrx/store';



export const loadProducts = createAction(
  '[Product List Page] Load Products',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadProductsSuccess = createAction(
  '[Product List Page] Load Products Success',
  props<{ total: number, ids: number[] }>()
);

export const loadProductsFailure = createAction(
  '[Product List Page] Load Products Failure',
  props<{ error: any }>()
);


export const deleteProduct = createAction(
  '[Product List Page] Delete Product',
  props<{ id: any }>()
);

export const deleteProductSuccess = createAction(
  '[Product List Page] Delete Product Success',
  props<{ id: any }>()
);

export const deleteProductFailure = createAction(
  '[Product List Page] Delete Product Failure',
  props<{ error: any }>()
);
