<div>
    <form [formGroup]="warningProductForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                    <div class="label-form">Chọn nội dung cảnh báo (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-select formControlName="warning_content" placeholder="Chọn nội dung cảnh báo">
                            <mat-option value="1">Đình chỉ lưu hành</mat-option>
                            <mat-option value="2">Thu hồi sản phẩm không đạt tiêu chuẩn chất lượng</mat-option>
                        </mat-select>
                    </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Doanh nghiệp sở hữu (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-select formControlName="enterprise" placeholder="Chọn doanh nghiệp sở hữu">
                        <mat-option value="icheck">iCheck</mat-option>
                        <mat-option value="ifi">IFI Solution</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Chọn sản phẩm cảnh báo (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-select formControlName="product" multiple placeholder="Chọn sản phẩm cảnh báo">
                        <mat-option *ngFor="let product of products" [value]="product.id">{{product.name}}</mat-option>
                      </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group">
                <div class="label-form">Thêm giấy tờ (*)</div>
                <input type="file" class="inputfile" class="input-file">
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Upload bằng link" formControlName="file_upload">
                </mat-form-field>
            </div>
            
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'create'" >Thêm mới</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'update'">Cập nhật</button>
        </mat-toolbar>
    </form>
</div>
