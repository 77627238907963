import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ProductActions, ProductPageActions } from '@products/actions';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import * as fromProducts from '../../reducers';
import * as fromGln from '../../../gln/reducers';
import { GlnActions } from '../../../gln/actions';
import * as fromCategory from '../../../category/reducers';
import {
   CategoryListPageActions,
} from '../../../category/actions';
import { startWith, map, takeUntil } from 'rxjs/operators';
import * as fromAlert from '../../../alert/reducers';
import { AlertActions } from '../../../alert/actions';
import {  validateCheckDigit,  noWhitespaceValidator } from 'src/app/shared/validate/validate';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-product-create-update',
  templateUrl: './product-create-update.component.html',
  styleUrls: ['./product-create-update.component.scss']
})
export class ProductCreateUpdateComponent extends ModalScroll implements OnInit {

  hideRequiredControl = new FormControl(false);
  productForm: any;
  alert$: Observable<any>;
  numberOfLinkVideo = [];
  products$: Observable<any>;
  categories$: Observable<any>;
  glns$: Observable<any>;
  title = 'Thêm mới sản phẩm';
  productCurrent$: Observable<any>;
  productCurrent: any;
  isUpdate = false;
  filterControlCategory = new FormControl();
  filteredCategories: Observable<Array<any>>;
  filterControlEnterprise = new FormControl();
  filteredEnterprises: Observable<Array<any>>;
  limit = 10;
  offset = 0;
  data: any;
  searchChangeObserver;
  categoryLevel: any;
  destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder, private router: Router,
              private route: ActivatedRoute,
              private store: Store<fromProducts.State>,
              private storeGln: Store<fromGln.State>,
              private storeCategory: Store<fromCategory.State>,
              private storeAlert: Store<fromAlert.State>) {
    super();
    this.products$ = store.pipe(select(fromProducts.selectProductPageResults));
    this.categories$ = storeCategory.pipe(select(fromCategory.selectCategoryListPageResults));
    this.glns$ = storeGln.pipe(select(fromGln.selectGlnListResults));
    this.productCurrent$ = store.pipe(select(fromProducts.selectProductCurrent));
    this.alert$ = storeAlert.pipe(select(fromAlert.selectAlertListResults));
  }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.store.dispatch(ProductPageActions.getProduct({ id: +id }));
    }

    this.categories$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.data = it.length;
    });

    this.productCurrent$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      // console.log(item);
      let categoryId = '';
      if (item.id) {
        this.isUpdate = true;
        this.productCurrent = item;
        this.productForm.controls.bar_code.disable();
        this.title = 'Sửa sản phẩm';
        const click =  new ClickTekoDto('product_list', 'product_update', 'http://datahub.icheck.vn/products/create',
          {product_id: id , barcode: item.bar_code, product_name: item.name });
        clickTeko(click);
        if (item.list_category_id.length) {
          categoryId = item.list_category_id[item.list_category_id.length - 1];

        }
      }
      this.productForm = this.formBuilder.group({
          id: [item.id || ''],
          name: [item.name || '', [Validators.required, noWhitespaceValidator]],
          price: [item.price || ''],
          bar_code: [item.bar_code || '', [Validators.required, noWhitespaceValidator, validateCheckDigit]],
          alert_id: [item.alert_id || ''],
          category_id: [categoryId || ''],
          certification_image_list: [item.certification_image_list || ''],
          created_at: [item.created_at || ''],
          enterprise_info: [item.enterprise_info || ''],
          enterprise_id: [item.enterprise_id || '', Validators.required],
          is_verify: [item.is_verify || ''],
          is_visible: [item.is_visible || ''],
          product_info: [item.product_info || ''],
          product_label: [item.product_label || ''],
          product_type: [item.product_type || ''],
          scan_count: [item.scan_count || ''],
          review_count: [item.review_count || ''],
          updated_at: [item.updated_at || ''],
          video_list: [item.video_list || ''],
          certification_content: [item.certification_content || ''],
          product_image_list: [item.product_image_list || ''],
          scan_status: ['', Validators.required],
          notCheckPrefix: [ false ],
          checkCode: [ false ]
        });
      });

    this.glns$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.filteredEnterprises = this.filterControlEnterprise.valueChanges.pipe(
        startWith(''),
        map((value: string) => {
          // console.log('valueeeeeeeee', value);

          const data = item.filter(option => {
              return option.name
              .toLocaleLowerCase()
              .indexOf(value.toLowerCase()) !== -1;
          });
          return data;
        })
      );
    });
    this.getListCategory();
    this.getListAlert();
    this.getListGln();
    this.checkBarCode();
  }
  checkBarCode() {
    this.productForm.get('checkCode').valueChanges.subscribe(status => {
      const barCodeControl = this.productForm.get('bar_code');
      if (status === true) {
        barCodeControl.setValidators([Validators.required, noWhitespaceValidator]);
      } else {
        barCodeControl.setValidators([Validators.required, noWhitespaceValidator, validateCheckDigit]);
      }
      barCodeControl.updateValueAndValidity();
    });
  }

  checkPrefix() {
    this.productForm.get('enterprise_id').valueChanges.subscribe(value => {
      const barCodeControl = this.productForm.get('bar_code');
      if (value) {
        // console.log(value);

        barCodeControl.setValidators([Validators.required, noWhitespaceValidator, validateCheckDigit, this.checkPrefixEnterprise]);
      } else {
        barCodeControl.setValidators([Validators.required, noWhitespaceValidator, validateCheckDigit]);
      }
      barCodeControl.updateValueAndValidity();
    });
  }

  checkPrefixEnterprise(control: FormControl) {
    this.glns$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {

    });
    // return null;
  }


  getListCategory() {
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: {},
        page: 1,
        pageSize: 300
      })
    );
  }

  getListAlert() {
    this.storeAlert.dispatch(AlertActions.loadAction({ filter: {
      offset: 0,
      limit: 200
    }}));
  }

  getListGln() {
    this.storeGln.dispatch(GlnActions.loadGln({filter: {
      offset: 0,
      limit: 500
    }}));
  }

  changeInputSearch(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: {name: event.target.value},
        page: 1,
        pageSize: 20
      })
    );
  }

  onPanelCloseCategory() {
    this.filterControlCategory.setValue('');
  }

  onPanelCloseEnterprise() {
    this.filterControlEnterprise.setValue('');
  }

  onFormSubmit() {
    // console.log(this.productForm.value);
    if (this.isUpdate) {
      const click = new ClickTekoDto(
        'product_list',
        'product_update' ,
        'http://datahub.icheck.vn/products/update/id',
        this.productForm.value );
      clickTeko(click);
      this.store.dispatch(ProductPageActions.updateProduct({ productObject: this.productForm.value }));
    } else {

      this.store.dispatch(ProductPageActions.addProduct({ productObject: this.productForm.value }));
    }

    this.router.navigate(['/products']);
  }

  goToListProduct() {
    this.router.navigate(['/products']);
  }

  addLinkVideo() {
    this.numberOfLinkVideo.push(0);
  }

  deleteLinkVideo() {
    if (this.numberOfLinkVideo.length === 0) { return; }
    this.numberOfLinkVideo.shift();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
