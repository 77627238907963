import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ic-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {

  @Input() items: any;
  @Input() value: any;
  @Output() changeInputSearch = new EventEmitter();
  @Output() selectedValue = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    // console.log('items', this.items);
    
  }

  changeInput(event) {
    this.changeInputSearch.emit(event);
  }

  onChangeSelect(event) {
    // console.log(event);
    this.selectedValue.emit(event.value)
    
  }

}
