<div>
    <form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                    <div class="label-form">Tên danh mục (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập tên danh mục" formControlName="name">
                    </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Chọn danh mục cha</div>
                <mat-form-field appearance="outline" class="form-field">
                    <!-- <mat-label>Danh mục cha</mat-label> -->
                    <mat-icon matSuffix class="icon-remove" *ngIf="categoryForm.value.parentId" (click)="clearSelected('parentId')">cancel</mat-icon>
                    <mat-select placeholder="Chọn danh mục cha" formControlName="parentId" #matSelect  (infiniteScroll)="lazyLoadCategory()" msInfiniteScroll>
                        <mat-form-field appearance="outline" class="form-field-search">
                            <mat-icon matPrefix>search</mat-icon>
                            <input matInput
                                type="text"
                                name="filter-options"
                                id="filter-options"
                                placeholder="Search"
                                (keyup)= "changeInputSearch($event)"
                                (keydown)="$event.stopPropagation()"
                                formControlName="inputSearch">
                          <mat-icon matSurfix *ngIf="this.categoryForm.value.inputSearch" (click) = "clearInputSearch()">clear</mat-icon>
                        </mat-form-field>
                        <mat-option [value]="category.id" *ngFor="let category of listCategory" [title]="category.display_name">{{category.display_name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Thứ tự (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                <input matInput placeholder="Nhập thứ tự" type="number" min="0" (keydown)="inputOrderChange($event)" formControlName="orderSort">
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Ảnh danh mục (*)</div>
                <!-- <mat-form-field appearance="outline" class="form-field">
                <input matInput placeholder="Nhập ảnh danh mục" formControlName="image">
                </mat-form-field> -->
                <ic-file-list   [files]="logo" (addFile)="openUploadDialog('logo')"
                         (removeFile)="onRemoveFile('logo', $event)"></ic-file-list>
            </div>
            <div class="form-group">
                <div class="label-form">Bộ thuộc tính sản phẩm</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Bộ thuộc tính sản phẩm</mat-label>
                    <mat-icon matSuffix class="icon-remove" *ngIf="categoryForm.value.attributeSetId" (click)="clearSelected('attributeSetId')">cancel</mat-icon>
                    <mat-select formControlName="attributeSetId" #matSelectAttributeSet msInfiniteScroll (infiniteScroll)="lazyLoadAttributeSet()">
                      <mat-form-field appearance="outline" class="form-field-search">
                        <mat-icon matPrefix>search</mat-icon>
                        <input matInput
                            type="text"
                            name="filter-options"
                            id="filter-options"
                            placeholder="Search"
                            (keyup)= "changeInputAttributeSetSearch($event)"
                            (keydown)="$event.stopPropagation()"
                            formControlName="inputSearchAttributeSet">
                            <mat-icon matSurfix *ngIf="this.categoryForm.value.inputSearchAttributeSet" (click) = "clearInputAttributeSetSearch()">clear</mat-icon>
                      </mat-form-field>
                
                      <mat-option *ngFor="let attributeSet of listAttributeSet" [value]="attributeSet.id">
                        {{attributeSet.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'create'" [disabled]="!categoryForm.valid">Thêm</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'update'" [disabled]="!categoryForm.valid">Sửa</button>
        </mat-toolbar>
    </form>
</div>
