export const environment = {
  // baseCoreUrl: 'https://datahub-api.icheck.vn/datahub/admin/api/v1',
  // baseCoreUrl: 'https://api-datahub.dev.icheck.vn/datahub/admin/api/v1',
  baseCoreUrl: 'https://datahub-api.dev.icheck.vn/datahub/admin/api/v1',
  // baseCoreUrl: 'https://datahub-livetest-api.icheck.vn/datahub/admin/api/v1',
  baseBuildImageUrl: 'https://apis.icheck.vn/upload/image/v1',
  baseBuildPdfUrl: 'https://upload.icheck.vn/v1/file?uploadType=simple&responseType=json',
  uploadStream: "https://upload.icheck.com.vn/upload/stream",
  production: true,
};
