import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoryRoutingModule } from './category-routing.module';
import {
    CategoryListComponent,
    CategoryModalComponent,
    AssignProductModalComponent,
    AssignReviewModalComponent,
    CheckCategoryIdModalComponent,
 } from './components';
import { CategoryPageComponent, CategoryListPageComponent } from './containers';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTableModule} from '@angular/material/table';
import { MaterialModule } from '../shared/material.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';
import { ColumnSelectorModule } from '../dash/column-selector';
import { TitleModule } from '../dash/title';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { CategoryEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';

import { FeatureBarModule } from '@dash/feature-bar';
import { MDCDataTableModule, MdcCheckboxModule, MdcCard, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule } from '@angular-mdc/web';
import { DashCheckboxModule } from '@dash/checkbox';
import { DeleteAlertComponent } from './components/delete-alert/delete-alert.component';
import { AssignAttributeSetComponent } from './components/assign-attribute-set/assign-attribute-set.component';
import { DetailAttributeSetComponent } from './components/detail-attribute-set/detail-attribute-set.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

export const COMPONENTS = [
    CategoryListComponent,
    CategoryModalComponent,
    AssignProductModalComponent,
    AssignReviewModalComponent,
    CheckCategoryIdModalComponent,
    DeleteAlertComponent,
    AssignAttributeSetComponent,
    DetailAttributeSetComponent,
];

export const CONTAINERS = [
    CategoryPageComponent,
    CategoryListPageComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    CommonModule,
    CategoryRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    FeatureBarModule,
    DashFilterBarModule,
    DashCheckboxModule,
    MaterialModule,
    ColumnSelectorModule,
    TitleModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MDCDataTableModule,
    MdcCheckboxModule,
    MdcCardModule,
    MdcIconModule,
    MdcButtonModule,
    MdcDialogModule,
    MdcIconButtonModule,
    SharedModule,
    MatSelectInfiniteScrollModule,
    StoreModule.forFeature(fromEffects.categoryFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([CategoryEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: [],
})
export class CategoryModule { }
