import { createAction, props } from '@ngrx/store';


export const loadProductAttributes = createAction(
  '[ProductAttribute Page] Load ProductAttributes',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadProductAttributesSuccess = createAction(
  '[ProductAttribute Page] Load ProductAttributes Success',
  props<{ total: number, rows: any }>()
);

export const loadProductAttributesFailure = createAction(
  '[ProductAttribute Page] Load ProductAttributes Failure',
  props<{ error: any }>()
);

export const loadListProductAttributeByParent = createAction(
  '[ProductAttribute Page] Load List ProductAttribute Parent',
  props<{ filter: any, limit: number, offset: number, parent_id: number }>()
);

export const loadListProductAttributeByParentSuccess = createAction(
  '[ProductAttribute Page] Load List ProductAttribute Parent Success',
  props<{ total: number, rows: any }>()
);

export const loadListProductAttributeByParentFailure = createAction(
  '[ProductAttribute Page] Load List ProductAttribute Parent Failure',
  props<{ error: any }>()
);

export const addProductAttribute = createAction(
  '[ProductAttribute Page] Add ProductAttribute',
  props<{ productAttributeObject: any }>()
);

export const addProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Add ProductAttribute Success',
  props<{ productAttribute: any }>()
);

export const addProductAttributeFailure = createAction(
  '[ProductAttribute Page] Add ProductAttribute Failure',
  props<{ error: any }>()
);


export const updateProductAttribute = createAction(
  '[ProductAttribute Page] Update ProductAttribute',
  props<{ productAttributeObject: any }>()
);

export const updateProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Update ProductAttribute Success',
  props<{ productAttribute: any }>()
);

export const updateProductAttributeFailure = createAction(
  '[ProductAttribute Page] Update ProductAttribute Failure',
  props<{ error: any }>()
);


export const deleteProductAttribute = createAction(
  '[ProductAttribute Page] Delete ProductAttribute',
  props<{ id: any }>()
);

export const deleteProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Delete ProductAttribute Success',
  props<{ id: any }>()
);

export const deleteProductAttributeFailure = createAction(
  '[ProductAttribute Page] Delete ProductAttribute Failure',
  props<{ error: any }>()
);

export const getDetailAttributeSet = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet',
  props<{ id: any }>()
);

export const getDetailAttributeSetSuccess = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Success',
  props<{ productAttribute: any }>()
);

export const getDetailAttributeSetFailure = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Failure',
  props<{ error: any }>()
);

export const getListAttributeIgnoreSet = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet',
  props<{ filter: any, page: number, pageSize: number, attribute_set_id: number }>()
);

export const getListAttributeIgnoreSetSuccess = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet Success',
  props<{ rows: any, total: number }>()
);

export const getListAttributeIgnoreSetFailure = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet Failure',
  props<{ error: any }>()
);
