<div>
    <form [formGroup]="paperForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                <div class="label-form">Thêm giấy tờ (*)</div>
                <input type="file" class="inputfile" class="input-file">
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Upload bằng link" formControlName="file_upload">
                </mat-form-field>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
            <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
            <button mat-raised-button type="submit" class="btn-stop">Lưu</button>
        </mat-toolbar>
    </form>
</div>
