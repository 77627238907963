import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);

@Component({
  selector: 'ic-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})



export class LoginComponent implements OnInit , OnDestroy {

  loginForm: any;
  messageError: any;
  isShowPassword = false;
  destroy$ = new Subject();

  @ViewChild('inputPassword') inputPassword: ElementRef;

  constructor(private router: Router,
              private location: Location,
              private fb: FormBuilder,
              private authService: AuthService,
              private _snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    console.log(123123)
    enterScreenView('portal_login', 'home_page');
    this.loginForm = this.fb.group({
      username: [ '', Validators.required],
      password: [ '', Validators.required],
    });
  }

  toggleShow() {
    this.isShowPassword = !this.isShowPassword;
    if (this.isShowPassword) {
      this.inputPassword.nativeElement.setAttribute('type', 'text');
    } else {
      this.inputPassword.nativeElement.setAttribute('type', 'password');
    }
  }

  login() {
    // this.message = '';
    let phone = this.loginForm.get('username').value;
    let password = this.loginForm.get('password').value;
    if (!phone || !password) {
      return;
    }
    if (phone && password) {
      // this.disabled = true;
      phone = phone.trim();
      password = password.trim();
      const body = {
        username: phone,
        password
      };
      const  click = new ClickTekoDto('portal_login', 'login',
        'https://datahub.icheck.vn/packages',
        {userICheckId: '', phoneLogin: phone, userName: phone});
      clickTeko(click);
      this.authService.login(body).pipe(
        takeUntil(this.destroy$)
      ).subscribe(response => {
        // console.log(response);
        if (response.token) {
          this.router.navigate(['/products']);
          this._snackBar.open('Đăng nhập thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }
        }, error => {
          console.log(error);


          this.messageError = 'Tên đăng nhập hoặc mật khẩu không đúng';
        }
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
