import { Router } from '@angular/router';
import {Component, OnInit, Input, EventEmitter, Output, OnDestroy, HostListener} from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ImportExcelProductComponent } from '@products/components';
import { ExportExcelProductComponent } from '@products/components';
import { Store, select } from '@ngrx/store';
import * as fromProduct from '../../reducers';
import { ProductListPageActions } from '@products/actions';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { AssignWarningProductComponent } from '../assign-warning-product/assign-warning-product.component';
import { RemoveInfoProductComponent } from '../remove-info-product/remove-info-product.component';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { ProductAddGlnComponent } from '../product-add-gln/product-add-gln.component';
import { UpdateScanStatusComponent } from '../update-scan-status/update-scan-status.component';
import { takeUntil } from 'rxjs/operators';
import {AllowOrDenyContributeComponent} from '@products/components/product-button/allow-or-deny-contribute/allow-or-deny-contribute.component';
import {UpdateContributeComponent} from '@products/components/product-button/update-contribute/update-contribute.component';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
declare function clickTeko(info);
declare function enterScreenView(screenName, contentType);

@Component({
  selector: 'ic-product-button',
  templateUrl: './product-button.component.html',
  styleUrls: ['./product-button.component.scss']
})
export class ProductButtonComponent implements OnInit , OnDestroy {
  @Input() selectedRows;

  @Input() rows;

  @Output() submitSuccess = new EventEmitter();

  isExpanRowButton = false;
  dialogRef1;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page = 1;
  pageSize = 10;
  destroy$ = new Subject();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<fromProduct.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
  ) {
    this.page$ = store.pipe(select(fromProduct.selectPage));
    this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
  }

  ngOnInit(): void {


    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.page = item;
    });

    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.pageSize = item;
    });
  }
  expanRowButton() {
    this.isExpanRowButton = true;
  }
  collapseRowButton() {
    this.isExpanRowButton = false;
  }
  updateScanStatus() {
    const click = new ClickTekoDto('product_list', 'product_update_status_scan', null, null);
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Cập nhật trạng thái quét của sản phẩm',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UpdateScanStatusComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }

  assignGln() {
    const click = new ClickTekoDto('product_list', 'link_gln', null , null);
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Gán doanh nghiệp sở hữu cho sản phẩm',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductAddGlnComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }

  addCategory() {
    const click = new ClickTekoDto('product_list', 'link_cate', null, null);
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Gán danh mục cho sản phẩm',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AddCategoryComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }
  allowOrDenyContribute() {
    if (this.selectedRows.length === 0) {
      alert('bạn chưa chọn mã sản phẩm');
      return;
    }
    const  dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '10rem';
    dialogConfig.data = {
      label: 'Cho phép hoặc chặn đóng góp',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AllowOrDenyContributeComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }

  importExcel(checkUpdate?: string) {
    if (!checkUpdate) {
      enterScreenView('product_import', 'product')
      const click = new ClickTekoDto('product_list', 'import', null, null);
      clickTeko(click);
    } else {
      enterScreenView('product_import_update', 'others')
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '36rem';
    dialogConfig.data = {
      label: 'Import sản phẩm từ file excel',
      action: 'create',
      checkUpdate
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ImportExcelProductComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }

  removeInfoProduct() {
    const click = new ClickTekoDto('product_list', 'product_delete_attribute', null, null);
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    // console.log(this.selectedRows);x
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Xóa các trường thông tin sản phẩm',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(RemoveInfoProductComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }
  updateStatusContribute() {
    const dialogConfig = new MatDialogConfig();
    // console.log(this.selectedRows);x
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Cập nhật trạng thái đóng góp sản phẩm',
    };
    const click = new ClickTekoDto('product_list', 'product_update_status_contribute', null , null);
    clickTeko(click);
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UpdateContributeComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }
  assignWarningProduct() {
    const click = new ClickTekoDto('product_list', 'link_alert', null , null);
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.data = {
      label: 'Gán cảnh báo cho danh sách sản phẩm',
      action: 'create',
      selectedRows: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AssignWarningProductComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    this.doAfterCodeDialog(dialogRef);
  }

  exportExcel() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '30rem';
    dialogConfig.data = {
      label: 'Xuất excel danh sách sản phẩm',
      action: 'create',
      products: this.selectedRows
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ExportExcelProductComponent, dialogConfig);
    this.dialogRef1 = dialogRef;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.submitSuccess.emit(true);
      }
      console.log('The dialog was closed');
    });
  }

  loadProduct(page, pageSize) {

    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: {},
        page,
        pageSize,
      })
    );
  }
  callClickTeko() {

  }
  updateStatusProduct(status) {
    if (!this.selectedRows.length) { return; }
    // console.log(this.selectedRows);
    const object = {
      status,
      barcodes: this.selectedRows.map(item => item.barcode)
    };

    this.productService.updateStatusProduct(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 0) {
        this.submitSuccess.emit(true);
        setTimeout(() => {
          this._snackBar.open('Cập nhật trạng thái sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.loadProduct(this.page, this.pageSize);
        }, 4000);
      } else {
        this.submitSuccess.emit(false);
        this._snackBar.open('Cập nhật trạng thái sản phẩm thất bại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
    });

  }
  doAfterCodeDialog(dialogRef) {
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.store.dispatch(
            ProductListPageActions.loadProducts({
              filter: {},
              page: this.page,
              pageSize: this.pageSize,
            })
          );
        }, 500);
        this.submitSuccess.emit(true);
      }
      console.log('The dialog was closed');
    });
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code ===  'Escape') {
      this.dialogRef1.close();
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
