import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CancelVerifyDistributorComponent } from '../cancel-verify-distributor/cancel-verify-distributor.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UpdateDistributorComponent } from '../update-distributor/update-distributor.component';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { ProductDocumentModalComponent } from '@products/components/product-documents/product-document-modal/product-document-modal.component';
import { MDCDataTableRowSelectionChangedEvent, MdcDialog, MDCDataTable } from '@angular-mdc/web';
import { CategoryListPageActions } from '@category/actions';
import { select, Store } from '@ngrx/store';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '@products/services/product.services';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as fromCategory from '@category/reducers';
import * as fromProduct from '@products/reducers';
import * as fromProductAttribute from '../../../../product-attribute-group/reducers';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { ProductPageActions } from '@products/actions';
import { formatDate, clean, buildImageUrls } from '@src/app/shared/common';
import { takeUntil } from 'rxjs/operators';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-verify-document-list',
  templateUrl: './verify-document-list.component.html',
  styleUrls: ['./verify-document-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductDocumentPackage();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class VerifyDocumentListComponent implements OnInit {

  rows: any = [];
  total: any = 0;
  pageSize: any = 10;
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  data: any;

  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject('availableFilters') public availableFilters: [],
    private storeCategory: Store<fromCategory.State>,
    public dialogRef: MatDialogRef<VerifyDocumentListComponent>,
    ) {
      this.data = data;
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
      this.page$ = store.pipe(select(fromProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
      this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {

    this.loadDocumentDistributor();
  }


  ngAfterViewInit() {
    let pageChange;
      pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$))
    .subscribe(() => {
    });
    merge(pageChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const request = {
          filter: {},
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        }
        this.loadDocumentDistributor(request);
      });
      // console.log(this.filterBar);
      // this._changeDetectorRef.detectChanges();
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original
      if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

  loadDocumentDistributor(object?) {
    // console.log(this.data);

    const request = {
      ...object,
      productId: this.data?.productId,
      enterpriseId: this.data?.enterpriseId
    }
    this.productService.getListDocumentDistributor(request).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.rows = res.data.items;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeInputSeach(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.documentId).indexOf(el.documentId) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.documentId).indexOf(el.documentId) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      // if (rows) {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      // }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.documentId === row.documentId);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.documentId === row.documentId);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row)
    } else {
      this.selected = this.selected.filter(item => item.documentId !== row.documentId);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.documentId).indexOf(el.documentId) < 0;
    });
    // console.log(this.selected);
    // console.log(this.rows);
    // console.log(res);

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    this.router.navigate(['/products/update', row.id]);
  }


  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo thuộc tính sản phẩm',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {

        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  hiddenDocument() {
    const body = {
      ids: this.selected.map(item => item.enterpriseProductDocumentId).join(','),
      visible: 3
    }
    this.productService.toggleDocumentDistributor(body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionHidden(body)
      } else {
        this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionHidden( body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.productService.toggleDocumentDistributor(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadDocumentDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  showDocument() {
    const body = {
      ids: this.selected.map(item => item.enterpriseProductDocumentId).join(','),
      visible: 1
    }
    this.productService.toggleDocumentDistributor(body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionShow(body)
      } else {
        this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionShow(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.productService.toggleDocumentDistributor(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadDocumentDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  removeDocument(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa giấy tờ khỏi NPP này không?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const ids = row ? row.enterpriseProductDocumentId : this.selected.map(item => item.enterpriseProductDocumentId).join(',')
        this.productService.removeDocumentDistributor(ids).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionDelete(ids)
          } else {
            this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.loadDocumentDistributor();
            }, 500);
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionDelete(ids) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const request = {
          params :  {
          requestType: 1
        }};
        this.productService.removeDocumentOfProduct(ids, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadDocumentDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  updateDocument(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật giấy tờ',
      action: 'update',
      row
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);
      }
      // console.log('The dialog was closed');
    });
  }

  verifyDocument(row?) {
    const body = {
      enterpriseProductDocumentIds: row ? [row.enterpriseProductDocumentId] : this.selected.map(item => item.enterpriseProductDocumentId)
    }
    this.productService.appoveDocumentDistributor(body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionVerify(body);
      } else {
        this._snackBar.open('Xác thực giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);

        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionVerify(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.productService.appoveDocumentDistributor(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Xác thực giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadDocumentDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  cancelVerifyDocument(row?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực giấy tờ',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resulttttttttt', result);

      if (result !== 'close') {
        const body = {
          enterpriseProductDocumentIds: row ? [row.enterpriseProductDocumentId] : this.selected.map(item => item.enterpriseProductDocumentId),
          reason: result.reason
        }
        this.productService.unAppoveDocumentDistributor(body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUnVerify(body);
          } else {
            this._snackBar.open('Hủy xác thực giấy tờ thành công ', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.loadDocumentDistributor();
            }, 500);
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })

      }
    });
  }

  confirmActionUnVerify(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.productService.unAppoveDocumentDistributor(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Hủy xác thực giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadDocumentDistributor();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }


  closeDialogClick() {
    this.dialogRef.close(this.selected);
  }

  assignDocumentFromPackage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Gán giấy tờ trong kho',
      action: 'assign-document',
      enterpriseId: this.data.enterpriseId,
      productId: this.data.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);

      }
    });
  }

  createNewDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo mới giấy tờ',
      action: 'assign-document',
      enterpriseId: this.data.enterpriseId,
      productId: this.data.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.loadDocumentDistributor();
        }, 500);
        // console.log(this.limit, this.offset);

        // this.loadAttribute(this.page, this.pageSize);
      }
      // console.log('The dialog was closed');
    });
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

}
