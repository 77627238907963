import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UploadComponent } from '@src/app/shared/upload/upload.component';
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlnListPageActions, GlnActions, GlnPageActions } from '@gln/actions';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Validators, FormBuilder } from '@angular/forms';
import { noWhitespaceValidator } from '@src/app/shared/validate/validate';
import { select, Store } from '@ngrx/store';
import { GlnService } from '@gln/services/gln.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import * as fromGln from '@gln/reducers';

@Component({
  selector: 'ic-create-enterprise',
  templateUrl: './create-enterprise.component.html',
  styleUrls: ['./create-enterprise.component.scss']
})
export class CreateEnterpriseComponent implements OnInit {

  glnForm: any;
  data: any;
  nations$: Observable<any>;
  cities$: Observable<any>;
  districts$: Observable<any>;
  avatar = [];
  backgroundImage = [];
  isUpdate = false;
  currentGln$: Observable<any>;
  currentGln: any;
  wards$: Observable<any>;
  listWard: any;
  listNation: any;
  listCity: any;
  listDistrict: any;
  inputNation = '';
  inputCity = '';
  inputDistrict = '';
  inputWard = '';
  searchChangeObserverNation;
  searchChangeObserverCity;
  searchChangeObserverDistrict;
  searchChangeObserverWard;
  page$: Observable<any>;
  pageSize$: Observable<any>;
  page: number;
  pageSize: number;
  destroy$ = new Subject();
  isLoading = false;

  private matSelectNation: MatSelect;

  @ViewChild('matSelectNation', { static: false }) set contentNation(contentNation: MatSelect) {
      if (contentNation) { // initially setter gets called with undefined
          this.matSelectNation = contentNation;
          this.matSelectNation.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listNation.length) {
                this.loadNation();
                this.inputNation = '';
              }
            }
          });

      }
  }

  private matSelectCity: MatSelect;

  @ViewChild('matSelectCity', { static: false }) set contentCity(contentCity: MatSelect) {
      if (contentCity) { // initially setter gets called with undefined
          this.matSelectCity = contentCity;
          this.matSelectCity.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listCity.length) {
                this.loadCity(this.glnForm.value.nationalCode);
                this.inputCity = '';
              }
            }
          });

      }
  }

  private matSelectDistrict: MatSelect;

  @ViewChild('matSelectDistrict', { static: false }) set contentDistrict(contentDistrict: MatSelect) {
      if (contentDistrict) { // initially setter gets called with undefined
          this.matSelectDistrict = contentDistrict;
          this.matSelectDistrict.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listDistrict.length) {
                this.loadDistrict(this.glnForm.value.provinceCode);
                this.inputDistrict = '';
              }
            }
          });

      }
  }

  private matSelectWard: MatSelect;

  @ViewChild('matSelectWard', { static: false }) set contentWard(contentWard: MatSelect) {
      if (contentWard) { // initially setter gets called with undefined
          this.matSelectWard = contentWard;
          this.matSelectWard.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listWard.length) {
                this.loadWard(this.glnForm.value.districtId);
                this.inputWard = '';
              }
            }
          });

      }
  }

  constructor(private formBuilder: FormBuilder,
              private store: Store<fromGln.State>,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private glnService: GlnService,
              private _snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<CreateEnterpriseComponent>,
              @Inject(MAT_DIALOG_DATA) data,
      ) {
        this.nations$ = store.pipe(select(fromGln.selectGlnListNation));
        this.cities$ = store.pipe(select(fromGln.selectGlnListCity));
        this.districts$ = store.pipe(select(fromGln.selectGlnListDistrict));
        this.currentGln$ = store.pipe(select(fromGln.selectCurrentGln));
        this.wards$ = store.pipe(select(fromGln.selectGlnListWard));
        this.page$ = store.pipe(select(fromGln.selectPage));
        this.pageSize$ = store.pipe(select(fromGln.selectPageSize));
        this.data = data;
      }

  ngOnInit(): void {

    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => this.page = item);
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => this.pageSize = item);
    this.loadNation();
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.store.dispatch(GlnPageActions.getDetailGln({ id }));
      this.isUpdate = true;
    }

    this.currentGln$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.currentGln = item;
      this.glnForm = this.formBuilder.group({
        name: [ '', [Validators.required, noWhitespaceValidator]],
        prefixCode: [''],
        glnCode: [''],
        glnType: [''],
        taxCode: [''],
        nationalCode: [ '', Validators.required],
        address: ['', [Validators.required, noWhitespaceValidator]],
        provinceCode: ['', Validators.required],
        districtId: ['', Validators.required],
        wardId: [''],
        phone: ['', Validators.pattern('[0-9]*')],
        email: ['', Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')],
        website: [''],
        logo: [''],
        backgroundImage: [''],
      });
      if (this.currentGln.nationalCode && this.currentGln.nationalCode !== 'VN') {
        const districtControl = this.glnForm.get('districtId');
        const cityControl = this.glnForm.get('provinceCode');
        const wardControl = this.glnForm.get('wardId');
        districtControl.setValidators(null);
        cityControl.setValidators(null);
        wardControl.setValidators(null);
      }
      if (this.glnForm.value.nationalCode) {
            this.loadCity(this.glnForm.value.nationalCode);
            this.loadDistrict(null);
          }
      if (this.glnForm.value.provinceCode) {
            this.loadDistrict(this.glnForm.value.provinceCode);
          }
      if (this.glnForm.value.districtId) {
            this.loadWard(this.glnForm.value.districtId);
          }

    });

    this.wards$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listWard = item;
    });

    this.nations$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listNation = item;
    });

    this.cities$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listCity = item;
    });

    this.districts$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listDistrict = item;
    });

    this.checkNation();

  }

  checkNation() {
    this.glnForm.get('nationalCode').valueChanges.subscribe(selectedNation => {
      // console.log(selectedNation);
      const districtControl = this.glnForm.get('districtId');
      const cityControl = this.glnForm.get('provinceCode');
      const wardControl = this.glnForm.get('wardId');
      if (selectedNation !== 'VN') {
        console.log('1312323');
        districtControl.setValidators(null);
        cityControl.setValidators(null);
        wardControl.setValidators(null);
        districtControl.disable();
        cityControl.disable();
        wardControl.disable();

        return;
      }
      districtControl.setValidators(Validators.required);
      cityControl.setValidators(Validators.required);
      wardControl.setValidators(Validators.required);
      districtControl.enable();
      cityControl.enable();
      wardControl.enable();

      // console.log(this.glnForm);

    });
  }

  changeNation(event) {
    // console.log('nation', event.value);
    this.loadCity(event.value);
    this.loadDistrict(null);
    this.listWard = [];
    const districtControl = this.glnForm.get('districtId');
    const cityControl = this.glnForm.get('provinceCode');
    const wardControl = this.glnForm.get('wardId');
    if (event.value !== 'VN') {
      console.log('1312323');
      districtControl.setValidators(null);
      cityControl.setValidators(null);
      wardControl.setValidators(null);
      districtControl.disable();
      cityControl.disable();
      wardControl.disable();
      this.glnForm.controls.provinceCode.setValue(null);
      this.glnForm.controls.districtId.setValue(null);
      this.glnForm.controls.wardId.setValue(null);
      return;
    }
    districtControl.setValidators(Validators.required);
    cityControl.setValidators(Validators.required);
    wardControl.setValidators(Validators.required);
    districtControl.enable();
    cityControl.enable();
    wardControl.enable();
  }

  changeCity(event) {
    // console.log('city',event.value);
    this.loadDistrict(event.value);
    this.glnForm.patchValue({
      wardId: ''
    });
    this.listWard = [];
  }

  loadNation(search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadNation({request: {
        type: 0,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadNation({request: {
        type: 0,
      }}));
    }

  }

  loadCity(parent_code, search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadCity({request: {
        type: 1,
        parent_code,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadCity({request: {
        type: 1,
        parent_code
      }}));
    }

  }

  loadDistrict(parent_code, search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadDistrict({request: {
        type: 2,
        parent_code,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadDistrict({request: {
        type: 2,
        parent_code,
      }}));
    }

  }

  changeDistrict(event) {
    this.loadWard(event.value);
  }

  loadWard(parent_id, search?) {
    // console.log(parent_id);
    if (search) {
      this.store.dispatch(GlnActions.loadWard({request: {
        type: 3,
        parent_id,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadWard({request: {
        type: 3,
        parent_id,
      }}));
    }
    // if (parent_id) {

    // } else {
    //   this.store.dispatch(GlnActions.loadWard({request: {
    //     type: 3,
    //     parent_id: undefined,
    //   }}));
    // }

  }

  changeInputSearchNation(event) {
    this.loadNation(event.target.value);
    // if (!this.searchChangeObserverNation) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserverNation = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)

    //         // this.select.open();
    //     });
    // }
    // this.searchChangeObserverNation.next(event.target.value);
  }

  clearInputNationSearch() {
    this.inputNation = '';
    this.loadNation();
  }

  changeInputSearchCity(event) {
    this.loadCity(this.glnForm.value.nationalCode, event.target.value);
    // if (!this.searchChangeObserverCity) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserverCity = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)

    //         // this.select.open();
    //     });
    // }
    // this.searchChangeObserverCity.next(event.target.value);
  }

  clearInputCitySearch() {
    this.inputCity = '';
    this.loadCity(this.glnForm.value.nationalCode);
  }

  changeInputSearchDistrict(event) {
    this.loadDistrict(this.glnForm.value.provinceCode, event.target.value);
  }

  clearInputDistrictSearch() {
    this.inputDistrict = '';
    this.loadDistrict(this.glnForm.value.provinceCode);
  }

  changeInputSearchWard(event) {
    this.loadWard(this.glnForm.value.districtId, event.target.value);

  }

  clearInputWardSearch() {
    this.loadWard(this.glnForm.value.districtId);
    this.inputWard = '';
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  onFormSubmit() {
      const object = {
        ...this.glnForm.value,
        name: this.glnForm.value.name.trim(),
        glnCode: this.glnForm.value.glnCode.trim(),
        prefixCode: this.glnForm.value.prefixCode.trim(),
        address: this.glnForm.value.address.trim(),
        phone: this.glnForm.value.phone.trim(),
        email: this.glnForm.value.email.trim(),
        taxCode: this.glnForm.value.taxCode.trim(),
        website: this.glnForm.value.website.trim(),
      };
      this.isLoading = true;
      this.glnService.createEnterprise(object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        setTimeout(() => {
          this.isLoading = false;
          this._snackBar.open('Thêm mới GLN thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close(res.data.enterpriseId);
        }, 500);

      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        if (event === 'avatar') {
          const data = result.data;
          data.map(res => {
          this.avatar = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);

          this.avatar.push(value);
          this.glnForm.controls.logo.setValue(res.url);
        });
        } else {
          const data = result.data;
          data.map(res => {
          this.backgroundImage = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);

          this.backgroundImage.push(value);
          this.glnForm.controls.backgroundImage.setValue(res.url);
        });
      }
    }
  });
  }

  onRemoveAvatar(field: string, index: number) {
    this.glnForm.controls.logo.setValue('');
    this.avatar = [];
  }

  onRemoveBackground(field: string, index: number) {
    this.glnForm.controls.backgroundImage.setValue('');
    this.backgroundImage = [];
  }

  backToListProduct() {
    this.router.navigate(['/gln']);
    // this.store.dispatch(
    //   GlnListPageActions.loadGlns({
    //     page: this.page,
    //     pageSize: this.pageSize,
    //     filter: {}
    //   })
    // );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
