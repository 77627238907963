import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromAttributeProduct from '../../reducers';
import {
  AttributeProductActions, AttributeListPageActions, AttributePageActions
} from '../../actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { noWhitespaceValidator, checkSelectValueAttribute, hasWhiteSpace } from 'src/app/shared/validate/validate';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';

@Component({
  selector: 'ic-attribute-product-modal',
  templateUrl: './attribute-product-modal.component.html',
  styleUrls: ['./attribute-product-modal.component.scss']
})
export class AttributeProductModalComponent extends ModalScroll  implements OnInit {

  @ViewChild('atrributeName') atrributeName: ElementRef;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  attributeForm: any;
  data: any;
  action: string;
  currentAttribute$: Observable<any>;
  isAssignProduct = false;
  currentAttribute: any;
  destroy$ = new Subject();

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<AttributeProductModalComponent>,
              private store: Store<fromAttributeProduct.State>,
              private _snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) data) {
    super();
    this.data = data;
    this.currentAttribute$ = store.pipe(select(fromAttributeProduct.selectCurrentAttribute));
      // console.log(data);

    }

  ngOnInit(): void {

    // console.log(this.data);

    if (this.data?.element?.attributeId) {
      this.store.dispatch(
        AttributePageActions.getDetailAttribute({ id: this.data.element.attributeId })
      );
      this.currentAttribute$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(item => {
        this.currentAttribute = item;
        this.isAssignProduct = item.assignedProduct;
        this.attributeForm = this.formBuilder.group({
          name: [item?.attributeLabel || '', [Validators.required, noWhitespaceValidator]],
          code: [item?.attributeCode || '', [Validators.required, noWhitespaceValidator, hasWhiteSpace]],
          isUniqueVal: [item?.isUniqueVal || false],
          isRequired: [item?.isRequired || false],
          valType: [item?.type || 'text', Validators.required],
          desc: [item?.desc || ''],
          dataSelect: [item?.dataSelects || []],
          addCustomVal: [item?.addCustomVal || false],
          defaultVal: [''],
          contributable: [item?.contributable || false],
        });
        if (item?.isSysAttribute) {
          this.attributeForm.controls.code.disable();
        }
      });
    } else {
      this.attributeForm = this.formBuilder.group({
        name: [ '', [Validators.required, noWhitespaceValidator]],
        code: [ '', [Validators.required, noWhitespaceValidator, hasWhiteSpace]],
        isUniqueVal: [ false],
        isRequired: [ false],
        valType: ['text', Validators.required],
        desc: [ ''],
        dataSelect: [ []],
        addCustomVal: [ false],
        defaultVal: [''],
        contributable: [false],
      });
    }



    // console.log(this.attributeForm);
    this.onChangeForm();
  }

  onChangeForm() {
    this.attributeForm.get('valType').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(val => {
      if (val === 'select' || val === 'multiselect') {
        // console.log('aaaaaaaaaaa');
        const selectValue = this.attributeForm.get('dataSelect');
        selectValue.setValidators([Validators.required, checkSelectValueAttribute]);
        selectValue.updateValueAndValidity();
      }
    });
  }


  closeDialogClick() {
    this.dialogRef.close();
  }

  loadListAttribute() {
    // console.log(this.limit, this.offset);
    this.store.dispatch(
      AttributeListPageActions.loadAttributes({
        filter: {},
        page: 1,
        pageSize: 10,
      })
    );
  }
  onFormSubmit() {
    // console.log(this.attributeForm.value)

    if (this.data.action === 'create') {
      const object = {
        ...this.attributeForm.value,
        dataSelect: this.attributeForm.value.dataSelect.map(item => item.value),
        name : this.attributeForm.value.name.trim(),
        code : this.attributeForm.value.code.trim(),
        desc: this.attributeForm.value.desc.trim(),
      };
      this.store.dispatch(AttributeProductActions.addAttributeProduct({attributeProductObject: object}));

    } else {
      console.log(this.attributeForm.value);

      const object = {
        ...this.attributeForm.value,
        id: this.data.element.attributeId,
        dataSelect: this.attributeForm.value.dataSelect.map(item => item.value),
        name : this.attributeForm.value.name.trim(),
        code : this.attributeForm.value.code ? this.attributeForm.value.code.trim() : this.currentAttribute?.attributeCode,
        desc: this.attributeForm.value.desc.trim(),
      };
      this.store.dispatch(AttributeProductActions.updateAttributeProduct({attributeProductObject: object}));
    }

    setTimeout(() => {
      this.dialogRef.close();
    }, 500);
  }

  removeAttribute(attribute): void {
    const index = this.attributeForm.controls.dataSelect.value.indexOf(attribute);
    const arrAttribute = this.attributeForm.controls.dataSelect.value;
    if (index >= 0) {
      const newArr = [...arrAttribute];
      newArr.splice(index, 1);
      this.attributeForm.controls.dataSelect.setValue(newArr);
    }
  }

  addAttribute(event) {
    // console.log(event);
    const input = event.input;
    const value = event.value;
    const objectValue = {
      value
    };
    const arrAttribute = [...this.attributeForm.controls.dataSelect.value];
    if ((value || '').trim()) {
      arrAttribute.push(objectValue);
      // console.log(arrAttribute);
      this.attributeForm.controls.dataSelect.setValue(arrAttribute);
    }
    if (input) {
      input.value = '';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
