import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertConfirmComponent } from './alert-confirm.component';
import { MaterialModule } from '../../shared/material.module';
import { MdcDialogModule } from '@angular-mdc/web/dialog';




@NgModule({
  declarations: [AlertConfirmComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MdcDialogModule
  ],
  exports: [AlertConfirmComponent]
})
export class AlertConfirmModule { }
