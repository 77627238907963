import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductAttributeActions, ProductAttributePageActions, ProductAttributesAPIActions } from '../actions';

export const productAttributeFeatureKey = 'productAttribute';

export interface State extends EntityState<any> {
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (productAttribute: any) => productAttribute.id,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
});

const productAttributeReducer = createReducer(
  initialState,
  on(ProductAttributePageActions.updateProductAttribute, (state, { productAttributeObject }) => {
    // console.log(productAttributeObject);

    return adapter.updateOne({ id: productAttributeObject.id, changes: productAttributeObject }, state);
  }),
  on(ProductAttributePageActions.updateProductAttributeFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(ProductAttributePageActions.addProductAttribute, (state, { productAttributeObject }) => {
    // console.log(productAttributeObject);

    return adapter.addOne(productAttributeObject , state);
  }),
  on(ProductAttributePageActions.addProductAttributeFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(ProductAttributePageActions.deleteProductAttributeSuccess, (state, { id }) => {
    // console.log(id);
   return adapter.removeOne(id, state);
  }),

  on(ProductAttributePageActions.deleteProductAttributeFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(ProductAttributePageActions.loadProductAttributesSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),

  on(ProductAttributesAPIActions.loadProductAttributesSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),

  on(ProductAttributePageActions.loadListProductAttributeByParentSuccess, (state, { rows, total }) => {
    return adapter.upsertMany(rows, state);
  }),
  
);

export function reducer(state: State | undefined, action: Action) {
  return productAttributeReducer(state, action);
}
