import { Component, OnInit, Inject } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ProductAttributePageActions } from '@attribute-set/actions';
import { Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Observable, Subject } from 'rxjs';
import * as fromProductAttribute from '@attribute-set/reducers';
import { noWhitespaceValidator } from '@src/app/shared/validate/validate';
import { CategoryService } from '@category/services/category.service';
import { ProductAttributeGroupUpdateComponent } from '@attribute-set/components/product-attribute-group-update/product-attribute-group-update.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ic-detail-attribute-set',
  templateUrl: './detail-attribute-set.component.html',
  styleUrls: ['./detail-attribute-set.component.scss']
})
export class DetailAttributeSetComponent implements OnInit {
  data: any;
  currentAttributeSet: any;
  viewDetail = true;
  arrIndexExpand = [];
  arrIndexEnableEdit = [];
  arrIndexEditting = [];
  isHovered = false;
  isEditing = false;
  destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<DetailAttributeSetComponent>,
    private _snackBar: MatSnackBar,
    private categoryService: CategoryService,
    private dialog: MatDialog,
    private store: Store<fromProductAttribute.State>,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    // console.log(this.data);
    this.getCurrentAttributeSet();
   }

  getCurrentAttributeSet() {
    this.categoryService.getDetailAttributeSetByCategory(this.data.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      
      this.currentAttributeSet = res?.data
    })
  }

  closeDialog() { 
    this.dialogRef.close()
  }

  
  expandGroup(index) {
    this.arrIndexExpand.push(index);
  }

  collapseGroup(index) {
    const i = this.arrIndexExpand.findIndex(it => it === index);
    this.arrIndexExpand.splice(i, 1);
  }

  updateAttributeSet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật bộ thuộc tính sản phẩm',
      action: 'create',
      element: this.currentAttributeSet
    };
    dialogConfig.disableClose = true;
    this.store.dispatch(ProductAttributePageActions.getDetailAttributeSet({ id: this.currentAttributeSet.id}))
    const dialogRef = this.dialog.open(ProductAttributeGroupUpdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.getCurrentAttributeSet();
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
