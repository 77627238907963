import _ from 'lodash';
import * as crypto from "crypto";
// declare const Buffer;
import * as CryptoJS from 'crypto-js';
// import * as Buffer from 'buffer';
// var Buffer = require('buffer')
// declare const crypto
// declare const crypto: any;
// import { crypto } from 'crypto'

const IMAGE: any = {
  DOMAIN: "https://redirector.icheck.vn/",
  UPLOAD_DOMAIN: "http://upload.icheck-dev.private.huytq.com",
  KEY: "secret"
};


export function testImage(url, callback) {
  var timedOut = false, timer;
  var img = new Image();
  img.onerror = img.onabort = function() {
      if (!timedOut) {
          clearTimeout(timer);
          callback(url, "error");
          return false;
      }
  };
  img.onload = function() {
      if (!timedOut) {
          clearTimeout(timer);
          callback(url, "success");
          return true;
      }
  };
  img.src = url;
 /*  timer = setTimeout(function() {
      timedOut = true;
      callback(url, "timeout");
  }, timeout);  */
}
export function convertObjectToQueryParams(object) {
  // _.unset(object, "type");
  let str = [];
  let query = '';
  for (var p in object) {

    if (object.hasOwnProperty(p) && p !== 'filter') {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(object[p]));
    }
      query = str.join("&");
  }
  return query;
}

export function uniqueObjectInArray(array) {
  return _.uniqBy(array, 'id');
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function _delete(obj, prop) {
  if (obj[prop] && ! obj[prop].length) delete obj[prop];
  return obj;
}

export function convertArrayToObjectSearch(array) {
  const arr = array.map(item => {
    return { key: item.key, value: item.value.value }
  });
  let objectSearch = {};
  for (var i = 0; i < arr.length; i++) {
    objectSearch[arr[i].key] = arr[i].value;
  }
  return objectSearch;
}

export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function clean(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  if (obj.update_to_date_by_user) {
    obj.update_to_date_by_user = formatDate(obj.update_to_date_by_user);
  }
  if (obj.update_from_date_by_user) {
    obj.update_from_date_by_user = formatDate(obj.update_from_date_by_user);
  }
  if (obj.update_to_date) {
    obj.update_to_date = formatDate(obj.update_to_date);
  }
  if (obj.update_from_date) {
    obj.update_from_date = formatDate(obj.update_from_date);
  }
  if (obj.create_to_date) {
    obj.create_to_date = formatDate(obj.create_to_date);
  }
  if (obj.create_from_date) {
    obj.create_from_date = formatDate(obj.create_from_date);
  }
  if (obj.not_gln_list) {
    obj.not_gln_list = obj.not_gln_list.replace(/\s+/g, '');
  }
  if (obj.not_nationId_list) {
     obj.not_nationId_list.join(',');
  }

  return obj;
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}


export function formatNewDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('/');
}

export function formatDateGrpc(date) {
  return date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
  ("00" + date.getDate()).slice(-2) + " " +
  ("00" + date.getHours()).slice(-2) + ":" +
  ("00" + date.getMinutes()).slice(-2) + ":" +
  ("00" + date.getSeconds()).slice(-2)
}

export function padLeft(base,chr){
  var  len = (String(base || 10).length - String(this).length)+1;
  return len > 0? new Array(len).join(chr || '0')+this : this;
}

export function convertDateGrpcToFormatDate(date) {


  const arr = date.split(/-|\s|:/);// split string and create array.
  // console.log(arr);
  const newDate = new Date(arr[0], arr[1] -1, arr[2], arr[3], arr[4], arr[5]); // decrease month value by 1
  return newDate;
}

export function convertDateToFormatDate(date) {
  const arr = date.split(/-|\s|:/);// split string and create array.
  // console.log(arr);
  const newDate = new Date(arr[2], arr[1] -1, arr[0], arr[3], arr[4], arr[5]); // decrease month value by 1
  return newDate;
}

//QuangCode
export function removeAllSpacingBetweenWords(str: string) {
  return str.replace(/\s+/g, " ").trim();
}

export function removeSpecial(str: any) {
  return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
}

export function formatCurrency(data: any) {
  return new Intl.NumberFormat().format(removeSpecial(data));
}

export function formatCurrency2(data: any) {
  return data.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function getObjectArrayNotDuplicate(arr: any[], key: string) {
  return arr
    .map(e => e[key])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(obj => arr[obj])
    .map(e => arr[e]);
}

export function getArrayNotDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') return arr1.filter(e => !arr2.includes(e.id));
  return arr1.filter(e => !arr2.includes(e));
}
export function getArrayDuplicateAfterCompareTwoArray(arr1: any[], arr2: any[], type?: string) {
  if (type === 'id') return arr1.filter(e => arr2.includes(e.id));
  return arr1.filter(e => arr2.includes(e));
}

export function getArrayNotNull(arr: any[], type?: string) {
  if (type === 'gift_id') return arr.filter(e => e.gift_id);
  if (type === 'id') return arr.filter(e => e.id);
  return arr.filter(e => e);
}

export function operationArray(arr: any[]) {
  var subtract = arr[0];
  for (var i = 1; i < arr.length; i++) {
    subtract -= arr[i];
  }
  var max = Math.max(...arr);
  var min = Math.min(...arr);
  var sum = arr.reduce((a, b) => a + b, 0);
  var average = arr.reduce((a, b) => a + b, 0) / arr.length;
  return {
    max: max,
    min: min,
    subtract: subtract,
    sum: sum,
    average: average
  }
}

export function convertDateToStringQuery(time: any, type?: any) {
  var dd: any = time.getDate();
  var mm: any = time.getMonth() + 1;
  var yyyy = time.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (type) return [dd, mm, yyyy].join("-");
  return [dd, mm, yyyy].join("/");
}
//
export function convertStringToDate(time: any, type?: any) {
  var from = time.split(" ");
  if (type) {
    var date = from[0].split(type);
  } else {
    var date = from[0].split("/");
  }
  var time = from[1].split(":");
  return new Date(date[2], date[1] - 1, date[0], time[0], time[1], time[2]);
}

export function convertDateToString(time: any, value?: number, calculate?: any) {
  var year = time.getFullYear();
  var date;
  if (calculate === '+') {
    date = time.getDate() + (value ? value : 0);
  } else if (calculate === '-') {
    date = time.getDate() - (value ? value : 0);
  } else {
    date = time.getDate();
  }
  var month = time.getMonth() + 1;
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  if (date < 10) {
    date = '0' + date;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${date}\/${month}\/${year} ${hours}:${minutes}:${seconds}`;
}

export function addDay(date, days = 1) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function dateRange(start, end, range = []) {
  if (start > end) return range;
  const next = addDay(start, 1);
  return dateRange(next, end, [...range, start]);
};
//EndQuangCode

export function myDateParser(dateStr: string): string {
  // 2018-01-01T12:12:12.123456; - converting valid date format like this

  let date = dateStr.substring(0, 10);
  let time = dateStr.substring(11, 19);
  let millisecond = dateStr.substring(20)

  let validDate = date + 'T' + time + '.' + millisecond;
  // console.log(validDate)
  return validDate
}

export function sign(id: string, size: string, type: string) {
  var re = new RegExp("[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}");
  if (re.test(id)) {
      id = id.replace(new RegExp("-", "g"), "");
  };

  const timestamp = Math.floor(Date.now() / 1000) + 60 * 60;
  const data = `${id}|${type}|${size}|${timestamp}|jpg|fit|pdf`;

  // const hex = crypto
  //     .createHmac("sha1", IMAGE.KEY)
  //     .update(data)
  //     .digest("hex");
      const str =CryptoJS.HmacSHA1(data ,IMAGE.KEY);
      const hex = CryptoJS.enc.Hex.stringify(str);
  return Buffer.from(Buffer.from(hex + data).toString("hex"))
      .toString("base64")
      .replace(/\+/g, ".")
      .replace(/\//g, "_")
      .replace(/=/g, "-");
};

export function buildImageUrl(id: string, size?: string){
  if (!id) return null;

  if (id.includes("http")) {
      return id;
  }

  if (id.includes("-TheHulk")) {
      // return `https://static.icheck.com.vn/images/${size}/${Buffer.from(id).toString("base64")}`;
      return `http://icheckcdn.net/images/${size}/${id}.jpg`;
  }

  if (id.startsWith("fb:")) {
      id = id.replace("fb:", "");
      let pictureParam = "";
      if (size != "original") {
          const withHeight = size.split("x");
          pictureParam = `?width=${withHeight[0]}&height=${withHeight[1]}`;
      }
      return `http://graph.facebook.com/${id}/picture${pictureParam}`;
  }

  return IMAGE.DOMAIN + sign(id, size, "photo");
};

export function buildOriginalImageUrl(id: string) {
  return buildImageUrl(id, "original");
};

export function buildImageUrls(id: string) {
  return {
      original: buildImageUrl(id, "original"),
      square: buildImageUrl(id, "100x100"),
      thumbnail: buildImageUrl(id, "240x240"),
      small: buildImageUrl(id, "240x240"),
      medium: buildImageUrl(id, "375x375"),
  };
};
