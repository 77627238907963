<div>
  <form [formGroup]="form" >
    <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
    </mat-toolbar>
    <div class="content-modal">
      <mat-tab-group>
        <mat-tab label="Upload file">
          <div style="margin-top: 20px" *ngIf="listfiles.length">
            <span style="display: inline-block;font-size: 13px" *ngFor="let file of listfiles">{{file.name}}</span>
            <mat-progress-bar *ngIf="loading"  style="margin-top: 10px" mode="indeterminate"></mat-progress-bar>
          </div>
          <div *ngIf="!loading" class="upload-btn-wrapper">
            <button class="btn">Chọn file</button>
            <input (change)="changeFile($event.target.files)" type="file" multiple />
          </div>
        </mat-tab>
        <mat-tab label="Upload bằng link">
            <input  style="height: 25px;margin-top: 20px;width: 99%"  type="url"  [formControl]="remoteUrlCtrl" />
          <div style="margin-top: 5px">
            <mat-error *ngIf="validUrl">URL không hợp lệ</mat-error>
            <mat-progress-bar *ngIf="loading"  style="margin-top: 10px" mode="indeterminate"></mat-progress-bar>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button"  (click)="closeDialogClick('close',  '')">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" >Upload</button>
    </mat-toolbar>
  </form>
</div>
