import { createEffect, ofType, Actions } from '@ngrx/effects';
import { AlertService } from './../services/alert.service';
import { Injectable } from '@angular/core';
import { AlertActions, AlertListPageActions, AlertApiActions } from '../actions'
import { concatMap, switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class AlertEffects {
  constructor(
    private action$: Actions,
    private alertService: AlertService,
    private _snackBar: MatSnackBar
  ) { }
  // loadAlert$ = createEffect(() => {
  //   return this.action$.pipe(
  //     ofType(AlertActions.loadAction),
  //     switchMap(action => {
  //       return this.alertService.getAlertList(action.filter).pipe(
  //         map((alert: any) => {
  //           return AlertActions.loadActionSuccess({ alert: alert.list, total: alert.total })
  //         }),
  //         catchError(error =>
  //           of(AlertActions.loadActionFailure({ error }))
  //         )
  //       )
  //     })
  //   )
  // });

  
  loadAlerts$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertListPageActions.loadAlerts),
      switchMap(action => {
        return this.alertService.getListAlert(action).pipe(
          switchMap((res: any) => [
            AlertApiActions.loadAlertsSuccess({ data: res.data.items }),
            AlertListPageActions.loadAlertsSuccess({
              total: res.data.totalElement,
              ids: res.data.items.map(c => c.id)
            })
          ]),
          catchError(error => [
            AlertApiActions.loadAlertsFailure({ error }),
            AlertListPageActions.loadAlertsFailure({ error })
          ])
        );
      })
    )
  });

  getDetailAlert$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.getDetailAlert),
      switchMap(action => {
        return this.alertService.getDetailAlert(action.id).pipe(
          map((alert: any) => {
            return AlertActions.getDetailAlertSuccess({ alert: alert.data })
          }),
          catchError(error =>
            of(AlertActions.getDetailAlertFailure({ error }))
          )
        )
      })
    )
  });


  addAlertForProduct$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.addAlertForProduct),
      switchMap(action => {
        return this.alertService.addAlertForProduct(action.object).pipe(
          map((alert: any) => {
            this._snackBar.open('Cập nhật cảnh báo cho sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AlertActions.addAlertForProductSuccess({ alert })
          }),
          catchError(error => {
            this._snackBar.open('Cập nhật cảnh báo cho sản phẩm không thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AlertActions.addAlertForProductFailure({ error }))
          }

          )
        )
      })
    )
  });

  removeAlertForProduct$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.removeAlertForProduct),
      switchMap(action => {
        return this.alertService.removeAlertForProduct(action.object).pipe(
          map((alert: any) => {
            this._snackBar.open('Gỡ cảnh báo sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AlertActions.removeAlertForProductSuccess({ alert })
          }),
          catchError(error => {
            this._snackBar.open('Gỡ cảnh báo sản phẩm thất bại', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AlertActions.removeAlertForProductFailure({ error }))
          }

          )
        )
      })
    )
  });

  addAlert$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.addAlert),
      switchMap(action => {
        return this.alertService.createAlert(action.alertObject).pipe(
          map((alert: any) => {
            this._snackBar.open('Tạo cảnh báo thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AlertActions.addAlertSuccess({ alert: alert })
          }),
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AlertActions.addAlertFailure({ error }))
          }
            
          )
        )
      })
    )
  })

  deleteAlert$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.deleteAlert),
      concatMap(action => {
        return this.alertService.deleteAlert(action.id).pipe(
          map((alert: any) => {
            this._snackBar.open('Xóa cảnh báo thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AlertActions.deleteAlertSuccess({ alert: alert })
          }),
          catchError(error => {
            // console.log(error);
            
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return  of(AlertActions.deleteAlertFailure({ error }))
          }
          )
        )
      })
    )
  })

  updateAlert$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AlertActions.updateAlert),
      concatMap(action => {
        // console.log(action);
        
        return this.alertService.updateAlert(action.alertObject).pipe(
          map((alert) => {
            // console.log(alert);
            this._snackBar.open('Cập nhật cảnh báo thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return AlertActions.updateAlertSuccess({ alert })
          }),
          catchError(error => {
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(AlertActions.updateAlertFailure({ error }))
          })
        )
      })
    )
  })

  // searchAlert$ = createEffect(()=>{
  //     return this.action$.pipe(
  //         ofType(AlertActions.searchAlert),
  //         switchMap(action =>{
  //             return this.alertService.
  //         })
  //     )
  // })

}