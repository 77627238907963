import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { checkFormatBarcode } from '@src/app/shared/validate/validate';
import { CategoryListPageActions } from '@category/actions';
import { ProductListPageActions, ProductActions } from '@products/actions';
import { onlyUnique } from '@src/app/shared/common';
import { Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import * as fromProduct from '@products/reducers';
import * as fromProductAttribute from '../../../../product-attribute-group/reducers';
import * as fromCategory from '@category/reducers';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import * as _ from 'lodash'
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';
declare function alertEvent(info)
@Component({
  selector: 'ic-update-distributor',
  templateUrl: './update-distributor.component.html',
  styleUrls: ['./update-distributor.component.scss']
})
export class UpdateDistributorComponent implements OnInit {

  distributorForm;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory:any;
  currentUser: any;
  listTitle: any;
  isUpdateProduct = false;
  destroy$ = new Subject();
  currentRole : any;
  pageTitle = 1;

  @ViewChild(MatSelect) child: MatSelect;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateDistributorComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
    private dialog: MatDialog,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog,
  ) {
    this.data = data;
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    console.log(this.data)
    if (this.data?.action === "update-product") {
      this.isUpdateProduct = true;
    }
    if (this.data?.row?.type) {
      this.currentRole = this.data.row.type;
      this.productService.getDetailTitleOfRole({type: this.currentRole}).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        const currentTitle = [{ id: this.data?.row?.title, name: this.data?.row?.titleName}]
        this.listTitle = _.uniqBy([...currentTitle, ...res.data.items], 'id');
      })
    }
    this.distributorForm = this.formBuilder.group({
      // barcodes: '',
      type: [this.data?.row?.type || '', Validators.required],
      title: [this.data?.row?.title || '', Validators.required],
    });



    this.distributorForm.get("type").valueChanges.subscribe(x => {
      console.log(x)
      this.currentRole = x;
      this.getListTitleOfRole();
      this.distributorForm.patchValue({
        title: ''
      })
      this.distributorForm.updateValueAndValidity();
   })
  }

  getListTitleOfRole() {
    this.productService.getDetailTitleOfRole({type: this.currentRole, page: this.pageTitle, pageSize: 10}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listTitle = _.uniqBy([...this.listTitle, ...res.data.items], 'id');
    });
  }

  lazyTitleOfRole() {
    this.pageTitle += 1;
    this.getListTitleOfRole();
  }



  onFormSubmit() {
    // console.log(this.distributorForm.value)
    const object = {
      ...this.distributorForm.value,
      enterpriseId: this.data.enterpriseId
    }
    const productId = this.data.productId;

    // if (this.data.action === "update-enteprise") {
      this.productService.updateDistributorOfProduct(productId, object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmAction(productId, object);
          this.dialogRef.close('accept');
        } else {
          const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Cập nhật vai trò và tiêu đề thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }
      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      })
  }

  confirmAction(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.productService.updateDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Cập nhật vai trò và tiêu đề thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  clearInputSearch() {
    this.distributorForm.patchValue({inputSearch: ''})

    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: {},
        page: 1,
        pageSize: 50
      })
    );
  }


  closeDialogClick() {
    this.dialogRef.close('close');
  }


  clearSelected(key) {
    this.distributorForm.patchValue({
      [key]: null
    })
    this.distributorForm.updateValueAndValidity();
  }

  createDocumentFromPackage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Kho giấy tờ',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resultttttttttttt', result);

      if (result !== 'close') {

      }
    });
  }

  createNewDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm giấy tờ',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resultttttttttttt', result);

      if (result !== 'close') {

      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
