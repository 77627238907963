import { Component, OnInit, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '@category/reducers';
import { Observable } from 'rxjs';
import { CategoryPageActions } from '@category/actions';

@Component({
  selector: 'ic-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit {

  isSelectPopup = false;
  categorySelected = '';
  items$: Observable<any>;
  items = [];
  currentItem: any;
  listTreeGrantTwo = [];
  listTreeGrantThree = [];
  listTreeGrantFour = [];
  itemSelected: any;
  
  constructor(private storeCategory: Store<fromCategory.State>,
    private _eref: ElementRef) { 
      this.items$ = storeCategory.pipe(select(fromCategory.selectTreeCategory));
    }

  ngOnInit(): void {
    this.items$.subscribe(it => {
      // console.log(it);
        this.items = it.rows;
    })

    // console.log(this.items);
    
    this.storeCategory.dispatch(CategoryPageActions.getTreeCategory({ 
      filter: {},
      pageSize: 100,
      page: 1
     }))
  }

  openSelectPopup() {
    this.isSelectPopup = true;
  }

  loadChildren(item, index) {
    console.log('4234234');
    
    this.currentItem = item;
    this.itemSelected = item.name_display;
    // this.categorySelect.emit(category.id)
    if (item.level === 1) {
      this.listTreeGrantTwo = this.items[index].children;
      this.listTreeGrantThree = [];
      this.listTreeGrantFour = [];
    } else if (item.level === 2 && this.listTreeGrantTwo.length) {
      // console.log(this.listTreeGrantTwo);
      this.listTreeGrantThree = this.listTreeGrantTwo[index].children;
      this.listTreeGrantFour = [];
    } else {
      if (this.listTreeGrantThree.length) {
        this.listTreeGrantFour = this.listTreeGrantThree[index].children;
      }
    }
    
  }

}
