<div>
    <form [formGroup]="glnForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                    <div class="label-form">Tên doanh nghiệp (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                        <input matInput placeholder="Nhập tên doanh nghiệp" formControlName="name">
                    </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Prefix</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập prefix" formControlName="prefix_code">
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Mã địa chỉ toàn cầu GLN (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập Mã địa chỉ toàn cầu GLN" formControlName="gln_code">
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Loại mã (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Chọn loại mã</mat-label>
                    <mat-select formControlName="gln_type">
                        <mat-option value="gs1_official_code">Mã GS1 chính thống</mat-option>
                        <mat-option value="gs1_unofficial_code">Mã GS1 không chính thống</mat-option>
                        <mat-option value="icheck_internal_code">Mã nội bộ iCheck</mat-option>
                        <mat-option value="enterprise_internal_code">Mã nội bộ doanh nghiệp</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Mã số thuế (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập mã số thuế" formControlName="tax_code">
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Quốc gia (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-select formControlName="nation" (selectionChange)="changeNation($event)">
                        <mat-option [value]="nation.code" *ngFor="let nation of nations$ | async">
                            {{nation.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Địa chỉ (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập địa chỉ" formControlName="address">
                </mat-form-field>
            </div>
            <div class="form-group" style="display: flex;">
                <div class="form-city">
                    <div class="label-form">Tỉnh/Thành phố (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-select formControlName="city" (selectionChange)="changeCity($event)">
                            <mat-option [value]="city.code" *ngFor="let city of cities$ | async">{{city.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-district">
                    <div class="label-form">Quận/Huyện (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                        <mat-select formControlName="district">
                            <mat-option [value]="district.id" *ngFor="let district of districts$ | async">{{district.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group">
                <div class="label-form">Điện thoại</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập số điện thoại" type="text" formControlName="phone">
                </mat-form-field>
                <mat-error *ngIf="!glnForm.controls.phone.valid" class="label_error">
                    Số điện thoại sai định dạng
                </mat-error>
            </div>
            <div class="form-group">
                <div class="label-form">Email</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập số điện thoại" type="email" formControlName="email">
                </mat-form-field>
                <mat-error *ngIf="!glnForm.controls.email.valid" class="label_error">
                    Email sai định dạng
                </mat-error>
            </div>
            <div class="form-group">
                <div class="label-form">Website</div>
                <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập số điện thoại" formControlName="website">
                </mat-form-field>
            </div>
            <div class="form-group modal-avatar">
              <div class="label-form">Ảnh đại diện</div>
              <ic-file-list   [files]="avatar" (addFile)="openUploadDialog('avatar')"
                         (removeFile)="onRemoveAvatar('avatar', $event)"></ic-file-list>
            </div>
            <div class="form-group modal-background">
              <div class="label-form">Ảnh bìa</div>
              <ic-file-list   [files]="backgroundImage" (addFile)="openUploadDialog('backgroundImage')"
                         (removeFile)="onRemoveBackground('backgroundImage', $event)"></ic-file-list>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" *ngIf="data.action === 'create'" [disabled]="!glnForm.valid">Thêm mới</button>
        <button mat-raised-button [disabled]="!glnForm.valid" type="submit" class="btn-stop" *ngIf="data.action === 'update'">Cập nhật</button>
        </mat-toolbar>
    </form>
</div>