<div>
  <mat-toolbar class="header-dialog">
<!--    <mat-icon class="icon-add">add_circle</mat-icon>-->
    <label class="header-text">Chọn loại giấy tờ sản phẩm</label>
  </mat-toolbar>

  <div class="content-item">
    <form [formGroup]="createDocumentForm" (ngSubmit)="onFormSubmit()" class="form-control">
      <div class="title">Vui lòng chọn phân loại giấy tờ để tiếp tục gán giấy tờ này <span class="required">*</span></div>
      <mat-radio-group class="box-radio-group"  formControlName="docProductType">
        <mat-radio-button class="content" [value]="document.value" *ngFor="let document of listProductDocument">{{document.label}}</mat-radio-button>
      </mat-radio-group>
      <div class="btn-footer">
        <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" [disabled]="!createDocumentForm.valid">Lưu</button>
      </div>
    </form>
  </div>
</div>
