<dash-feature-bar>
  <dash-feature-bar-row>
    <dash-feature-bar-section>
      <h1 dashFeatureBarTitle>Quản lý sản phẩm</h1>
    </dash-feature-bar-section>
  </dash-feature-bar-row>
</dash-feature-bar>
<!-- End Feature bar -->
<!-- Start Filter bar -->
<ic-filter-bar [availableFilters]="availableFilters"  (filterChange)="changeFilter($event)"></ic-filter-bar>
<!-- End Filter bar -->

<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <ic-product-button [selectedRows]= "selected" [rows]="rows" (submitSuccess)="clearSelection($event)"></ic-product-button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="image">Ảnh đại diện</th>
            <th mdcDataTableHeaderCell name="name">Tên sản phẩm</th>
            <th mdcDataTableHeaderCell name="price">Giá sản phẩm</th>
            <th mdcDataTableHeaderCell name="owned_business">Doanh nghiệp sở hữu</th>
            <th mdcDataTableHeaderCell name="owned_business">Quốc gia</th>
            <th mdcDataTableHeaderCell name="owned_business">Trạng thái liên kết GLN</th>
            <th mdcDataTableHeaderCell name="business_management">Doanh nghiệp quản lý</th>
            <th mdcDataTableHeaderCell name="scan_sattus">Trạng thái quét</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Trạng thái xác thực</th>
            <th mdcDataTableHeaderCell name="number_of_scans">Lượt quét</th>
            <th mdcDataTableHeaderCell name="number_of_rate">Lượt đánh giá</th>
            <th mdcDataTableHeaderCell name="number_of_qa">Lượt hỏi đáp</th>
            <th mdcDataTableHeaderCell name="category">Danh mục</th>
            <th mdcDataTableHeaderCell name="category">Người tạo</th>
            <th mdcDataTableHeaderCell name="category">Người cập nhật</th>
            <th mdcDataTableHeaderCell name="category">Xác thực bởi</th>
            <th mdcDataTableHeaderCell name="created_time">Thời gian tạo</th>
            <th mdcDataTableHeaderCell name="created_time"> Thời gian cập nhật gần nhất</th>
            <th mdcDataTableHeaderCell name="last_updated_time"> Thời gian cập nhật thông tin</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows; index as i" [selected]="selection.isSelected(row)" >
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id" (dblclick)="updateProduct(row)">{{row.id}}</td>
            <td mdcDataTableCell name="image" (dblclick)="updateProduct(row)">
              <div (mouseover)="indexHover=i"
              (mouseleave)="indexHover=-1" class="image-product">
                <img onError="src='/assets/stran.png'" [src]="row?.image" />
                <div class="div-shadow" *ngIf="indexHover === i"></div>
                <div class="div-icon" *ngIf="indexHover === i">
                  <mat-icon class="icon-image" (click) = "openNewTab(row.image)" title="Xem hình ảnh">visibility</mat-icon>
                  <mat-icon class="icon-image" (click) = "openUploadDialog(row.id)" title="Sửa hình ảnh"> cloud_upload</mat-icon>
                </div>
              </div>

            </td>
            <td mdcDataTableCell name="name" (dblclick)="updateProduct(row)">
              <div class="" (mouseover)="activeEdit(i)"
              (mouseout)="inActiveEdit(i)" class="label-attribute-set" >
                <span class="title-attribute-set text-break" *ngIf="arrIndexEditting !== i" contenteditable="enableEdit"[title]="row.productName" >{{row.productName}}</span>
                <mat-icon class="icon-edit-group"  [ngClass]="{ active: arrIndexEnableEdit === i }" (click)="editting(i)">edit</mat-icon>
                <form class="edit-form" (keyup.enter)="submitEdit($event, i, row)" *ngIf="arrIndexEditting === i">
                  <input #inputEdit appFocus type="text" name="editGroup"  class="input-name" [value]="row.productName" (blur)="submitEdit($event, i , row)" maxLength="500"/>
                </form>
              </div>
              <div style="color: #0000ff">{{row.barcode}}</div>
            </td>
            <td mdcDataTableCell name="price" (dblclick)="updateProduct(row)">
              {{row.price}}
            </td>
            <td mdcDataTableCell name="owned_business" (dblclick)="updateProduct(row)">
              <div class="text-break" [title]="row?.enterprise?.name">{{row?.enterprise?.name}}</div>
              <div style="color: #0000ff">{{row?.enterprise?.gln}}</div>
              <div style="color: #999" *ngIf="row?.enterprise?.type === 'enterprise_internal_code'">Mã nội bộ doanh nghiệp</div>
              <div style="color: #999" *ngIf="row?.enterprise?.type === 'gs1_official_code'">Mã GS1 chính thống</div>
              <div style="color: #999" *ngIf="row?.enterprise?.type === 'gs1_unofficial_code'">Mã GS1 không chính thống</div>
              <div style="color: #999" *ngIf="row?.enterprise?.type === 'icheck_internal_code'">Mã nội bộ icheck</div>
            </td>
            <td mdcDataTableCell>{{row?.enterprise?.nationName}}</td>
            <td mdcDataTableCell><span [ngStyle]="{color:(row?.linkGtinGln == 0 && 'red') || (row?.linkGtinGln == 1 && 'green')
            || (row?.linkGtinGln == 2 && 'orange') || (row?.linkGtinGln == 3 && 'gray' ) }">
              {{ (row?.linkGtinGln == 0 && 'Chưa gán gln' ) || (row?.linkGtinGln == 1 && 'Duyệt' ) || (row?.linkGtinGln == 2 && 'Chờ duyệt' ) || (row?.linkGtinGln == 3 && 'Hủy duyệt' )}}</span></td>
            <td mdcDataTableCell name="business_management" (dblclick)="updateProduct(row)">{{row?.productMaster?.name}}</td>
            <td mdcDataTableCell name="scan_sattus" (dblclick)="updateProduct(row)">
              <span  [class]="row.className">{{row.statusName}}</span>

            </td>
            <td mdcDataTableCell name="authentication_sattus" (dblclick)="updateProduct(row)">
              <span *ngIf="row?.isVerify === 1" class="verified">Xác thực</span>
              <span *ngIf="row?.isVerify === 3" class="not-verified">Chưa xác thực</span>
            </td>
            <td mdcDataTableCell name="number_of_scans" (dblclick)="updateProduct(row)">
              {{row.scanCount}}
            </td>
            <td mdcDataTableCell name="number_of_rate" (dblclick)="updateProduct(row)">
              {{row.reviewCount}}
            </td>
            <td mdcDataTableCell name="number_of_qa" (dblclick)="updateProduct(row)">{{row.questionCount}}</td>
            <td mdcDataTableCell name="category" *ngIf="row.categories?.length" (dblclick)="updateProduct(row)"><span class="text-break">{{row?.categories.join(' > ')}}</span></td>
            <td mdcDataTableCell name="category" *ngIf="row.categories?.length <= 0" class="text-break" (dblclick)="updateProduct(row)"></td>
            <td mdcDataTableCell name="created_by" (dblclick)="updateProduct(row)">{{row?.createdBy?.username}} <span *ngIf="row?.createdBy?.username && row?.createdBy?.phone">-</span> {{row?.createdBy?.phone}}</td>
            <td mdcDataTableCell name="updated_by" (dblclick)="updateProduct(row)">{{row?.updatedBy?.username}} <span *ngIf="row?.updatedBy?.username && row?.updatedBy?.phone">-</span> {{row?.updatedBy?.phone}}</td>
            <td mdcDataTableCell name="created_time" (dblclick)="updateProduct(row)">{{row?.verifyBy?.join(', ')}}</td>
            <td mdcDataTableCell name="created_time" (dblclick)="updateProduct(row)">{{row.createdAt}}</td>
            <td mdcDataTableCell name="last_updated_time" (dblclick)="updateProduct(row)">{{row.updatedAt}}</td>
            <td mdcDataTableCell name="created_time" (dblclick)="updateProduct(row)">{{row.updatedAtByUser }}</td>
            <td mdcDataTableCell name="action">
                <a mat-button [matMenuTriggerFor]="beforeMenu"><mat-icon>more_vert</mat-icon></a>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click) = "updateProduct(row)">Cập nhật</button>
                <button mat-menu-item (click) = "updateStatusProduct(row, 0)">No Data</button>
                <button mat-menu-item (click) = "updateStatusProduct(row, 1)">Cho quét</button>
                <button mat-menu-item (click) = "updateStatusProduct(row, 3)">Không cho quét</button>
                <button mat-menu-item (click) = "updateStatusProduct(row, 2)">Đã ẩn bởi DNSH</button>
              </mat-menu>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
                   (page)="changePage($event)"
    ></mat-paginator>
  </mdc-card>
</div>
