export const DOC_PRODUCT_TYPE = {
  1: 'Giấy phép thành lập',
  2: 'Giấy tờ nhập khẩu',
  3: 'Giấy chứng nhận MSMV',
  4: 'Giấy tờ quảng cáo',
  5: 'Giấy tờ lưu hành',
  6: 'Giấy chứng nhận sản phẩm',
  7: 'Giấy tờ phân phối',
  8: 'Giấy tờ khác'
};

export const DOCUMENT_PRODUCT_LIST = [
  {
    label: 'Giấy phép thành lập',
    value: 1
  },
  {
    label: 'Giấy tờ nhập khẩu',
    value: 2
  },
  {
    label: 'Giấy chứng nhận MSMV',
    value: 3
  },
  {
    label: 'Giấy tờ quảng cáo',
    value: 4
  },
  {
    label: 'Giấy tờ lưu hành',
    value: 5
  },
  {
    label: 'Giấy chứng nhận sản phẩm',
    value: 6
  },
  {
    label: 'Giấy tờ phân phối',
    value: 7
  },
  {
    label: 'Giấy tờ khác',
    value: 8
  }
];
