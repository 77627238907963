<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mdc-dialog-title>Bạn có chắc chắn muốn xóa cảnh báo này không?</mdc-dialog-title>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close">Hủy</button>
        <button mdcDialogButton mdcDialogAction="accept">OK</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>