import {Component, OnInit, Inject, ViewChild, OnDestroy} from '@angular/core';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {  Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import * as fromCategory from '@category/reducers';
import * as fromProduct from '../../reducers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as fromAlert from '@alert/reducers';
import { AlertListPageActions } from '@alert/actions';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { onlyUnique } from '@src/app/shared/common';
import { MatSelect } from '@angular/material/select';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog } from '@angular-mdc/web';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-assign-warning-product',
  templateUrl: './assign-warning-product.component.html',
  styleUrls: ['./assign-warning-product.component.scss']
})

export class AssignWarningProductComponent extends ModalScroll implements OnInit , OnDestroy {

  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  barcodes: string;
  alerts$: Observable<any>;
  listAlert: any;
  currentUser: any;
  destroy$ = new Subject();
  isLoading = false;

  @ViewChild(MatSelect) child: MatSelect;

  constructor(
    private storeCategory: Store<fromCategory.State>,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<fromProduct.State>,
    public dialogRef: MatDialogRef<AssignWarningProductComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeAlert: Store<fromAlert.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog
  ) {
    super();
    this.data = data;
    this.alerts$ = storeAlert.pipe(select(fromAlert.selectAlertListPageResults));
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    enterScreenView('product_link_alert', 'product');

    this.storeAlert.dispatch(
      AlertListPageActions.loadAlerts({
        filter: {},
        page: 1,
        pageSize: 50,
      })
    );
    this.formGroup = this.formBuilder.group({
      // barcodes: '',
      alertId: ['', Validators.required],
      barcodes: ['', Validators.required],
      inputSearch: [''],
      allowUpdate: [false],
      allowUpdateProduct: [false]
    });
    if (this.data.selectedRows.length) {
      const barcodes = this.data.selectedRows.map(item => item.barcode).join('\n');
      this.formGroup.patchValue({
        barcodes
      });
      this.checkBarcode();
    }

    this.alerts$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listAlert = item;
    });
  }



  onFormSubmit() {
    const object = {
      alertId: this.formGroup.value.alertId,
      barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique),
      updateBusinessManager: this.formGroup.value.allowUpdate ? 1 : 0,
    };
    const click = new ClickTekoDto('product_link_alert', 'update', 'http://datahub.icheck.vn/products', {
      totalBarcode: object.barcodes,
      alertId: object.alertId,
      userId: this.currentUser.userId
    });
    clickTeko(click);
    this.isLoading = true;
    // console.log(object);
    this.productService.assignAlertForProduct(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      if (res.code === 1104) {
        this.confirmActionSubmit(object);
      } else {
        const success = res.data.success;
        const fail = res.data.fail;
        if(success){
          const alert = new AlertTekoDto('success' , 'success');
          alertEvent(alert);
        }
        if(fail){
          const alert = new AlertTekoDto('fail' , 'fail');
          alertEvent(alert);
        }
        this.isLoading = false;
        this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close('accept');
      }
    },
    error => {
      this._snackBar.open('Gán cảnh báo cho sản phẩm thất bại', 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionSubmit(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: this.formGroup.value.allowUpdateProduct ? 1 : 2
        };
        this.isLoading = true;
        this.productService.assignAlertForProduct(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this.isLoading = false;
          const success = res.data.success;
          const fail = res.data.fail;
          if(success){
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if(fail){
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close();
        }, err => {
          this.isLoading = false;
          this.dialogRef.close('close');
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        this.isLoading = false;
      }
      console.log('The dialog was closed');
    });
  }

  clearInputSearch() {
    this.formGroup.patchValue({inputSearch: ''});

    this.storeAlert.dispatch(
      AlertListPageActions.loadAlerts({
        filter: {},
        page: 1,
        pageSize: 50,
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }
  changeInputSearch(event) {
    this.storeAlert.dispatch(
      AlertListPageActions.loadAlerts({
        filter: { title: event.target.value },
        page: 1,
        pageSize: 50,
      })
    );

  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    });
    this.formGroup.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
