import { AlertList } from './../models/alert-list.model';
import { createAction, props } from '@ngrx/store';
export const loadAction = createAction(
  '[Alert Page] Load Page',
  props<{ filter: any }>()
);

export const loadActionSuccess = createAction(
  '[Collection/API] Load Page Success',
  props<{ alert: any, total: number }>()
)

export const loadActionFailure = createAction(
  '[Collection/API] Load Page Failure',
  props<{ error: any }>()
)

export const addAlert = createAction(
  '[Alert Page] Add Alert',
  props<{ alertObject: any }>()
);

export const addAlertSuccess = createAction(
  '[Collection/API] Add Alert Success',
  props<{ alert: AlertList }>()
);

export const addAlertFailure = createAction(
  '[Collection/API] Add Alert Failure',
  props<{ error: any }>()
);

export const updateAlert = createAction(
  '[Alert Page] Update Alert',
  props<{ alertObject: any }>()
);

export const updateAlertSuccess = createAction(
  '[Collection/API] Update Alert Success',
  props<{ alert: any }>()
);

export const updateAlertFailure = createAction(
  '[Collection/API] Update Alert Failure',
  props<{ error: any }>()
);

export const deleteAlert = createAction(
  '[Alert Page] Delete Alert',
  props<{ id: any }>()
);

export const deleteAlertSuccess = createAction(
  '[Collection/API] Delete Alert Success',
  props<{ alert: any }>()
);

export const deleteAlertFailure = createAction(
  '[Collection/API] Delete Alert Failure',
  props<{ error: any }>()
);

export const getDetailAlert = createAction(
  '[Alert Page] Get Detail Alert',
  props<{ id: any }>()
);

export const getDetailAlertSuccess = createAction(
  '[Alert Page] Get Detail Alert Success',
  props<{ alert: any }>()
);

export const getDetailAlertFailure = createAction(
  '[Alert Page] Get Detail Alert Failure',
  props<{ error: any }>()
);

export const addAlertForProduct = createAction(
  '[Alert Page] Add Alert For Product',
  props<{ object: any }>()
);

export const addAlertForProductSuccess = createAction(
  '[Alert Page] Add Alert For Product Success',
  props<{ alert: any }>()
);

export const addAlertForProductFailure = createAction(
  '[Alert Page] Add Alert For Product Failure',
  props<{ error: any }>()
);


export const removeAlertForProduct = createAction(
  '[Alert Page] Remove Alert For Product',
  props<{ object: any }>()
);

export const removeAlertForProductSuccess = createAction(
  '[Alert Page] Remove Alert For Product Success',
  props<{ alert: any }>()
);

export const removeAlertForProductFailure = createAction(
  '[Alert Page] Remove Alert For Product Failure',
  props<{ error: any }>()
);

// export const searchAlert = createAction(
//   '[Alert Page] Search Alert',
//   props<{ objectSearch: any }>()
// );

// export const searchAlertSuccess = createAction(
//   '[Collection/API] Search Alerts Success',
//   props<{ alerts: any }>()
// );

// export const searchAlertFailure = createAction(
//   '[Collection/API] Search Alerts Failure',
//   props<{ error: any }>()
// );