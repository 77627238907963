import { Action, createReducer, on } from '@ngrx/store';

import { ProductAttributeListPageActions } from '../actions';


export const productAttributeListPageFeatureKey = 'productAttributeListPage';

export interface State {
  filter: any;
  pageSize: number;
  page: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: number;
  currentAttributeSet: object;
  listAttributeIgnore: any;
  totalListAttributeIgnore: number;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'name', 'created_at','updated_at', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: null,
  currentAttributeSet: {},
  listAttributeIgnore: [],
  totalListAttributeIgnore: 0
};

const productAttributeListPageReducer = createReducer(
  initialState,
  on(
    ProductAttributeListPageActions.loadProductAttributes,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    ProductAttributeListPageActions.resetCurrentAttributeSet,
    (state, { action }) => {
      return {
        ...state,
        currentAttributeSet: {}
      }
    }
  ),
  on(
    ProductAttributeListPageActions.loadProductAttributesSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false,
        currentAttributeSet: {},
      }
    }
  ),
  on(
    ProductAttributeListPageActions.loadProductAttributesFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    ProductAttributeListPageActions.loadListProductAttributeByParent,
    (state, { filter, limit, offset, parent_id }) => {
      return {
        ...state,
        filter,
        limit,
        offset,
        loading: true,
        parent_id
      }
    }
  ),
  on(
    ProductAttributeListPageActions.loadListProductAttributeByParentSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false
      }
    }
  ),
  on(
    ProductAttributeListPageActions.loadListProductAttributeByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    ProductAttributeListPageActions.getDetailAttributeSetSuccess,
    (state, { productAttribute }) => {
      return {
        ...state,
        currentAttributeSet: productAttribute,
      }
    }
  ),
  on(
    ProductAttributeListPageActions.getDetailAttributeSetFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
  on(
    ProductAttributeListPageActions.getListAttributeIgnoreSetSuccess,
    (state, { rows, total }) => {
      return {
        ...state,
        listAttributeIgnore: rows,
        totalListAttributeIgnore: total
      }
    }
  ),
  on(
    ProductAttributeListPageActions.getListAttributeIgnoreSetFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productAttributeListPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getCurrentAttributeSet = (state: State) => state.currentAttributeSet;

export const getListAttributeIgnoreSet = (state: State) => state.listAttributeIgnore;

export const getTotalListAttributeIgnore = (state: State) => state.totalListAttributeIgnore;

