<div>


    <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
    </mat-toolbar>
    <div class="content-modal">
      <div class="form-group">
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">

          <button mat-button style="background: #0000FF; color: #fff ;margin-right: 10px" (click)="allow(true)">Đóng góp</button>
          <button mat-button style="background: orange; color: #fff; margin-right: 10px" (click)="allow(false)">Chặn đóng góp</button>
    </mat-toolbar>

</div>
