<div>
    <form [formGroup]="attributeSetForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        
        <div class="content-modal">
            <div class="form-group">
                    <div class="label-form">Tên bộ thuộc tính (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập tên danh mục" formControlName="name" maxlength="255">
                    </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Chọn bộ thuộc tính cơ sở (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                  <mat-icon matSuffix class="icon-remove" *ngIf="attributeSetForm.value.parentId" (click)="clearSelected('parentId')">cancel</mat-icon>

                    <mat-select formControlName="parentId" #matSelect msInfiniteScroll (infiniteScroll)="lazyLoadAttributeSet()">
                        <mat-form-field appearance="outline" class="form-field-search">
                            <mat-icon matPrefix>search</mat-icon>
                            <input matInput
                                type="text"
                                name="filter-options"
                                id="filter-options"
                                placeholder="Search"
                                (keyup)= "changeInputSearch($event)"
                                (keydown)="$event.stopPropagation()"
                                formControlName="inputSearch">
                                <mat-icon matSurfix *ngIf="this.attributeSetForm.value.inputSearch" (click) = "clearInputSearch()">clear</mat-icon>
                          </mat-form-field>
                        <mat-option [value]="attributeSet.id" *ngFor="let attributeSet of listAttributeSet">{{attributeSet.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
           
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" [disabled]="!attributeSetForm.valid" class="btn-stop">Thêm</button>
        </mat-toolbar>
    </form>
</div>
