
<!-- Start Feature bar -->
<dash-feature-bar>
  <dash-feature-bar-row>
    <dash-feature-bar-section>
      <!-- <h1 dashFeatureBarTitle>Quản lý danh mục</h1> -->
      <!-- <div> -->
        <!-- <ol class="breadcrumb"> -->
          <h1 dashFeatureBarTitle (click)="breadCrumbMain()"><span>Danh sách danh mục </span></h1>
          <h1 dashFeatureBarTitle *ngFor="let m of menuHeader; let indx = index" (click)="breadCrumb(menuHeader, indx)">
            <span>{{m.label}} </span>
          </h1>
        <!-- </ol> -->
      <!-- </div> -->
    </dash-feature-bar-section>
  </dash-feature-bar-row>
</dash-feature-bar>
<!-- End Feature bar -->
<!-- Start Filter bar -->
<ic-filter-bar (resetFormFilter)="resetSelected()" [availableFilters]="availableFilters"></ic-filter-bar>
<!-- End Filter bar -->

<!-- <div class="row-button">
    <button mat-raised-button class="green btn btn-create" (click) = "createCategory()">Thêm danh mục</button>
    <button mat-raised-button class="primary btn btn-create" (click) = "assignReviewAction()">Gán thuộc tính đánh giá</button>
    <button mat-raised-button class="primary btn btn-create" (click) = "assignProductAction()">Gán thuộc tính sản phẩm</button>
    <button mat-raised-button class="primary btn btn-create" (click) = "checkIdCategory()">Kiểm tra ID danh mục</button>
</div> -->

<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start" style="flex: inherit">
        <button mdc-button raised class="button-table" (click)="createCategory()">Thêm danh mục</button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button mdc-button raised class="button-table" (click)="assignAttributeSet()" [disabled]="!this.selected.length">Gán bộ thuộc tính</button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="image">Ảnh đại diện</th>
            <th mdcDataTableHeaderCell name="name">Tên danh mục</th>
            <th mdcDataTableHeaderCell name="order_sort">Thứ tự</th>
            <th mdcDataTableHeaderCell name="attr_product">Thuộc tính sản phẩm</th>
            <th mdcDataTableHeaderCell name="created_at">Ngày tạo</th>
            <th mdcDataTableHeaderCell name="updated_at">Thời gian cập nhật gần nhất</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)">
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id"><button mdc-button (click)="getCategoryByParent(row)">{{row.id}}</button></td>
            <td mdcDataTableCell name="image"><img onError="src='/assets/stran.png'" [src]="row?.image" /></td>
            <td mdcDataTableCell name="name">
              <span [title]="row.name" class="text-break">{{row.name}}</span>
            </td>
            <td mdcDataTableCell name="order_sort">{{row.orderSort}}</td>
            <td mdcDataTableCell name="attr_product"><button mdc-button (click)= "openModalDetailAttributeSet(row)">Chi tiết</button></td>
            <td mdcDataTableCell name="created_at">{{row.createdAt}}</td>
            <td mdcDataTableCell name="updated_at">{{row.updatedAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)="updateCategory(row)" class="btn-edit">
                <mdc-icon>edit</mdc-icon>
              </button>
              <button mdcIconButton (click)="deleteCategory(row)" class="btn-delete">
                <mdc-icon>delete</mdc-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
                   (page)="changePage($event)"
    ></mat-paginator>
  </mdc-card>
</div>

<!-- <div class="mat-elevation-z8">
    <table mat-table [dataSource]="rows">
      <ng-container matColumnDef="select" sticky>
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Ảnh đại diện </th>
        <td mat-cell *matCellDef="let element"> <img onError="src='/assets/stran.png'" [src]="element?.image" /> </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Tên danh mục </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="order_sort">
        <th mat-header-cell *matHeaderCellDef> Thứ tự </th>
        <td mat-cell *matCellDef="let element"> {{ element.order_sort }} </td>
      </ng-container>

      <ng-container matColumnDef="rating_attribute">
        <th mat-header-cell *matHeaderCellDef> Thuộc tính đánh giá </th>
        <td mat-cell *matCellDef="let element"> <a style="color: #169BD5">Chi tiết</a> </td>
      </ng-container>

      <ng-container matColumnDef="product_attribute">
        <th mat-header-cell *matHeaderCellDef> Thuộc tính sản phẩm </th>
        <td mat-cell *matCellDef="let element"> <a style="color: #169BD5">Chi tiết</a> </td>
      </ng-container>

      <ng-container matColumnDef="action" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>Hành động</th>
        <td mat-cell *matCellDef="let element" class="row-action">
          <mat-icon style="color: #0000ff;" (click) = "updateCategory(element)">edit</mat-icon>
          <mat-icon style="color: #fe1c1c;">delete_outline</mat-icon>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


  </div> -->
