import { createAction, props } from '@ngrx/store';


export const loadAlertsSuccess = createAction(
  '[Alerts Api] Load Alerts Success',
  props<{ data: any }>()
);

export const loadAlertsFailure = createAction(
  '[Alerts Api] Load Alerts Failure',
  props<{ error: any }>()
);


export const loadAlertByParentSuccess = createAction(
  '[Alerts Api] Load List Alert By Parent Success',
  props<{ data: any }>()
);

export const loadAlertByParentFailure = createAction(
  '[Alerts Api] Load List Alert By Parent Failure',
  props<{ error: any }>()
);
