import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ComponentFactoryResolver,
  Injector,
  ApplicationRef,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostBinding,
  ChangeDetectionStrategy,
  NgZone,
  ChangeDetectorRef,
  Optional,
  Inject
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

import { DashFilter, DashAppliedFilter } from './filter-bar.component';
import { MdcRipple } from '@angular-mdc/web/ripple';
import { MdcChip, MDC_CHIPSET_PARENT_COMPONENT, MdcChipSetParentComponent } from '@angular-mdc/web/chips';
import { Platform } from '@angular/cdk/platform';


@Component({
  selector: 'dash-filter-bar-item, [dashFilterBarItem]',
  exportAs: 'dashFilterBarItem',
  host: {
    'class': 'dash-filter-bar__item',
  },
  template: `
  <div class="mdc-chip__ripple"></div>
  <ng-content select="mdc-chip-icon[leading]"></ng-content>
  <mdc-chip-checkmark *ngIf="filter"></mdc-chip-checkmark>
  <span role="gridcell">
    <mdc-chip-primary-action>
      <div class="mdc-chip__touch" *ngIf="touch"></div>
      <mdc-chip-text *ngIf="label">{{label}}</mdc-chip-text>
      <mdc-chip-text class="dash-filter-bar__item-content">
        <div class="dash-filter-bar__name">{{ appliedFilter.definition.name }}:</div>
        <div class="dash-filter-bar__value" #displayContainer></div>
      </mdc-chip-text>
      <mdc-chip-icon trailing class="dash-filter-bar__trailing-action">cancel</mdc-chip-icon>
    </mdc-chip-primary-action>
  </span>
  <span role="gridcell" *ngIf="!!trailingIcon">
    <ng-content select="mdc-chip-icon[trailing]"></ng-content>
  </span>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MdcRipple,
    { provide: MdcChip, useExisting: FilterItemComponent }
  ],
  // providers: [MdcRipple]
})
// @Component({
//   selector: 'dash-filter-item',
//   host: {
//     'class': 'dash-filter-bar__item'
//   },
//   template: `
//   <div class="dash-filter__name">{{ filter.definition.name }} : </div>
//   <div class="dash-filter__value" #displayContainer></div>`,
//   changeDetection: ChangeDetectionStrategy.OnPush,
//   encapsulation: ViewEncapsulation.None
// })
export class FilterItemComponent extends MdcChip {

  // @HostBinding('class')
  // get classes(): string {
  //   return 'dash-filter__content';
  // }

  @ViewChild('displayContainer', {static: true})
  _displayContainer: ElementRef;

  @Input()
  appliedFilter: DashAppliedFilter<any>;

  // @Output()
  // remove = new EventEmitter<any>();

  private _portalOutlet: DomPortalOutlet;

  constructor(
    public platform: Platform,
    public ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
    public ripple: MdcRipple,
    public elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MDC_CHIPSET_PARENT_COMPONENT) public parent: MdcChipSetParentComponent,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    private _applicationRef: ApplicationRef) {
    super(platform, ngZone, changeDetectorRef, ripple, elementRef, parent);
  }

  // ngOnInit(): void {}

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._portalOutlet = new DomPortalOutlet(
      this._displayContainer.nativeElement,
      this._componentFactoryResolver,
      this._applicationRef,
      this._injector);
    const componentRef = this._portalOutlet.attachComponentPortal(
      new ComponentPortal<any>(this.appliedFilter.definition?.displayComponent, null, null));
    componentRef.instance.data = this.appliedFilter.value;
  }

  // ngOnDestroy(): void {
  //   this.portalOutlet.dispose();
  // }

  // onRemove(event: any) {
  //   this.remove.emit(event);
  // }

}
