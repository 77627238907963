import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';
import { MaterialModule } from '../shared/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';

import { SharedModule } from '../shared/shared.module';
import { ForgetAuthComponent } from './components/forget-auth/forget-auth.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';


export const COMPONENTS = [
];

export const CONTAINERS = [
  LoginComponent,
  ForgetAuthComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    SharedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
})
export class AuthModule { }
