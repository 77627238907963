
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group">
                  <div class="label-form">Nhập lý do (*)</div>
                  <mat-form-field appearance="outline" class="form-field">
                      <textarea matInput formControlName="reason" style="height: 60px;"></textarea>
                  </mat-form-field>
          </div>
      </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!formGroup.valid">Cập nhật</button>
      </mat-toolbar>
