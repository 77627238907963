import { createAction, props } from '@ngrx/store';

import { Product } from '../models/product.model';

export const loadProducts = createAction(
  '[Product Page] Load Products',
  props<{ filter: object }>()
);

export const loadProductsSuccess = createAction(
  '[Product Page] Load Products Success',
  props<{ total: number, rows: Product[] }>()
);

export const loadProductsFailure = createAction(
  '[Product Page] Load Products Failure',
  props<{ error: any }>()
);

export const addProduct = createAction(
  '[Product Page] Add Product',
  props<{ productObject: any }>()
);

export const addProductSuccess = createAction(
  '[Product Page] Add Product Success',
  props<{ product: any }>()
);

export const addProductFailure = createAction(
  '[Product Page] Add Product Failure',
  props<{ error: any }>()
);


export const updateProduct = createAction(
  '[Product Page] Update Product',
  props<{ productObject: any }>()
);

export const updateProductSuccess = createAction(
  '[Product Page] Update Product Success',
  props<{ product: any }>()
);

export const updateProductFailure = createAction(
  '[Product Page] Update Product Failure',
  props<{ error: any }>()
);


export const getProduct = createAction(
  '[Product Page] Get Product',
  props<{ id: any }>()
);

export const getProductSuccess = createAction(
  '[Product Page] Get Product Success',
  props<{ product: any }>()
);

export const getProductFailure = createAction(
  '[Product Page] Get Product Failure',
  props<{ error: any }>()
);

export const checkBarCodeExist = createAction(
  '[Product Page] Check BarCode',
  props<{ bar_code: string }>()
);

export const checkBarCodeExistSuccess = createAction(
  '[Product Page] Check BarCode Success',
  props<{ existed: any }>()
);

export const checkBarCodeExistFailure = createAction(
  '[Product Page] Check BarCode Failure',
  props<{ error: any }>()
);
