import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, HostBinding } from '@angular/core';
import { buildImageUrls, testImage } from '../common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'ic-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileListComponent implements OnInit {
  @HostBinding('class') class = 'con-file-list';
  @Input() files: any[] = [];
  @Input() listType = 'image';
  @Input() showAddButton = true;
  @Output() addFile = new EventEmitter<void>();
  @Output() removeFile = new EventEmitter<string>();
  indexHover = -1;
  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original
    testImage(img, (url, result) => {
      if (result === 'success') {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
    })
  }

}
