<div *ngIf="!currentAlert?.title">
    <button mat-raised-button class="primary" (click)="openDialogAlertProduct()" >Gán cảnh báo</button>
    <i class="text-alert" >Sản phẩm chưa được gán cảnh báo</i>
</div>
<div *ngIf="currentAlert?.id">
    <div class="button-group">
         <button mat-raised-button class="primary" (click)="updateAlertProduct()">Cập nhật cảnh báo</button>
        <button mat-raised-button class="error" (click)="deleteAlertProduct()">Gỡ cảnh báo</button>
    </div>
   
    <div class="main-description">
        <div class="description-alert">
            <div class="title">Tiêu đề</div>
            <div class="content">{{currentAlert.title}}</div>
        </div>
        <div class="description-alert">
            <div class="title">Mô tả</div>
            <div class="content">{{currentAlert.alertDescription}}</div>
        </div>
        <div class="description-alert">
            <div class="title">Nội dung cảnh báo</div>
            <div class="content" [innerHTML]="currentAlert.content"></div>
        </div>
        <div class="description-alert">
          <div class="title">Thời gian tạo</div>
          <div class="content">{{currentAlert.createdAt}}</div>
      </div>
        <div class="description-alert">
            <div class="title">Giấy tờ</div>
            <div class="content">
              <div style="cursor: pointer; color:#199ed8" *ngFor="let image of currentAlert.documents" (click)="showDocument(image)">
                <span class="maxLength2">{{ image }}</span>
              </div>
            </div>
        </div>

    </div>
</div>

