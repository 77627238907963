import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import _ from 'lodash';
import { ProductService } from '@products/services/product.services';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromProduct from '../../reducers';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog } from '@angular-mdc/web';
import { formatDate } from '@src/app/shared/common';
import { takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ModalScroll} from "@src/app/shared/common/modal-scroll";
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AuthService} from '@src/app/auth/services/auth.service';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-export-excel-product',
  templateUrl: './export-excel-product.component.html',
  styleUrls: ['./export-excel-product.component.scss']
})
export class ExportExcelProductComponent  implements OnInit {

  data: any;
  exportForm: FormGroup;
  file = [];
  importName = '';
  formValid = false;
  selectAll = false;
  arrFiled = [
    {
        id: 1,
        name: 'Barcode',
        code: 'barcode'
    },
    {
        id: 2,
        name: 'Tên sản phẩm',
        code: 'name'
    },
    {
        id: 3,
        name: 'Giá sản phẩm',
        code: 'price'
    },
    {
        id: 4,
        name: 'Ảnh sản phẩm',
        code: 'image'
    },
    {
        id: 5,
        name: 'Chi tiết sản phẩm',
        code: 'desc'
    },
    {
        id: 6,
        name: 'Video sản phẩm',
        code: 'video'
    },
    {
        id: 7,
        name: 'Doanh nghiệp sở hữu',
        code: 'ownerEnterprise'
    },
    {
        id: 8,
        name: 'Doanh nghiệp quản lý',
        code: 'managerEnterprise'
    },
    {
        id: 9,
        name: 'Trạng thái quét',
        code: 'scanStatus'
    },
    {
        id: 10,
        name: 'Trạng thái xác thực',
        code: 'verifyStatus'
    },
    {
        id: 11,
        name: 'Danh mục',
        code: 'category'
    },
    {
        id: 12,
        name: 'Lượt quét',
        code: 'scanCount'
    },
    {
        id: 13,
        name: 'Lượt đánh giá',
        code: 'reviewCount'
    },
    {
        id: 14,
        name: 'Lượt hỏi đáp',
        code: 'questionCount'
    },
    {
        id: 16,
        name: 'Thông tin phân biệt thật giả',
        code: 'verifiedInfo'
    },
    {
        id: 17,
        name: 'Thông tin giới thiệu Doanh nghiệp sở hữu',
        code: 'ownerInfo'
    }
  ];
  filter$: Observable<any>;
  filter: any = {};
  selected3 = [];
  destroy$ = new Subject();
  isValid = false;
  isLoading = false;

  constructor(public dialogRef: MatDialogRef<ExportExcelProductComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) data,
              private fb: FormBuilder,
              private productService: ProductService,
              private store: Store<fromProduct.State>,
              private dialog2: MdcDialog,
              private authService: AuthService,
              private _snackBar: MatSnackBar, ) {

    this.data = data;
    this.filter$ = store.pipe(select(fromProduct.selectProductListPageFilter));
  }

  ngOnInit(): void {
    enterScreenView(TekoValueDto.PRODUCT_EXPORT.SCREEN_NAME, TekoValueDto.PRODUCT_EXPORT.CONTENT_TYPE);
    this.exportForm = this.fb.group({
      barcode: this.fb.control(false),
      name: this.fb.control(false),
      price: this.fb.control(false),
      image: this.fb.control(false),
      desc: this.fb.control(false),
      video: this.fb.control(false),
      ownerEnterprise: this.fb.control(false),
      managerEnterprise: this.fb.control(false),
      scanStatus: this.fb.control(false),
      verifyStatus: this.fb.control(false),
      category: this.fb.control(false),
      scanCount: this.fb.control(false),
      reviewCount: this.fb.control(false),
      questionCount: this.fb.control(false),
      verifiedInfo: this.fb.control(false),
      ownerInfo: this.fb.control(false),
      export_from :  this.fb.control(false),
      export_to :  this.fb.control(false)
    });
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.filter = item;
    });
  }


  toggle(item, event: MatCheckboxChange) {
    if (event.checked) {
      this.selected3.push(item);
    } else {
      const index = this.selected3.indexOf(item);
      if (index >= 0) {
        this.selected3.splice(index, 1);
      }
    }
    this.isValid =  this.selected3.length  > 0;

 }

 exists(item) {
   return this.selected3.indexOf(item) > -1;
 }

 isIndeterminate() {
   return (this.selected3.length > 0 && !this.isChecked());
 }

 isChecked() {
   return this.selected3.length === this.arrFiled.length;
 }

 toggleAll(event: MatCheckboxChange) {
  this.isValid =  Object.values(this.exportForm.value).some(value => value === true);
  if ( event.checked ) {

      this.arrFiled.forEach(row => {
         this.selected3.push(row);
         });
   } else {
      this.selected3.length = 0 ;
   }
  this.isValid =  !!this.selected3.length;
}


  closeDialogClick() {
    this.dialogRef.close();
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        const data = result.data;
        data.map(res => {
          this.file = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          this.file.push(value);
          this.exportForm.controls.file_import.setValue(res.url);
        });
      }
    });
  }
  onRemoveFile(field: string, index: number) {
    this.exportForm.controls.file_import.setValue('');
    this.file = [];
  }

  onSubmitForm() {
    if (this.isLoading) { return; }
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Tổng số bản ghi có thể export được là 10000 bản ghi.Bạn có muốn tiếp tục export không?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.isLoading = true;
        const filter = {
          ...this.filter,
          create_to_date: this.filter.create_to_date ? formatDate(this.filter.create_to_date) : '',
          create_from_date: this.filter.create_from_date ? formatDate(this.filter.create_from_date) : '',
          update_to_date: this.filter.update_to_date ? formatDate(this.filter.update_to_date) : '',
          update_from_date: this.filter.update_from_date ? formatDate(this.filter.update_from_date) : '',
          export_from : this.exportForm.value.export_from,
          export_to: this.exportForm.value.export_to
        };
        const object = {
          fields: this.selected3.map(item => item.id).join(','),
          filter,
          ids: this.data.products.map(item => item.id).join(',')
        };
        const click = new ClickTekoDto(TekoValueDto.PRODUCT_EXPORT.REGION_NAME, TekoValueDto.PRODUCT_EXPORT.CONTENT_NAME,
          TekoValueDto.PRODUCT_EXPORT.TARGET , {
            userId: this.authService.currentUserValue.userId
        })
        clickTeko(click)
        console.log('object ===>' , object)
        this.productService.exportProduct(object)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((x: any) => {
          this.isLoading = false;
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
          const newBlob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
          const alert  = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS)
          alertEvent(alert);
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
          console.log(data);

          const link = document.createElement('a');
          link.href = data;
          link.download = 'product_export_' + new Date().getTime();
            // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);

          this.dialogRef.close();
        }, err => {
          console.log(err);
          const alert  = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert);
          this._snackBar.open('Thao tác không hợp lệ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.isLoading = false;
        });
      }
    });

  }

  importFile(data: any) {
    // console.log(data);

    if (data) {
      this.importName = data.name;
      this.exportForm.controls.file_import.setValue(data);
    }
  }

  deleteFileImport() {
    this.importName = '';
    this.exportForm.controls.file_import.setValue(null);
  }

  changeCheckbox() {
    // console.log(this.exportForm.value);
    this.formValid = false;
    for (const prop in this.exportForm.value) {
      if (this.exportForm.value[prop]) {
        this.formValid = true;
      }
      // console.log(this.exportForm.value);
    }
  }

  selectAllField(event) {
    // console.log(event);

    if (event) {
      for (const prop in this.exportForm.value) {
        // console.log(this.exportForm.value[prop]);
        this.exportForm.controls[prop].setValue(event.checked);
      }

      this.changeCheckbox();
    }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
