import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ProductRoutingModule } from './product-routing.module';
import {
  ProductButtonComponent,
  ProductToggleRowComponent,
  ProductFilterComponent,
  ProductTableActionComponent,
  ProductListComponent,
  ProductCreateUpdateComponent,
  ProductDetailComponent
} from './components';
import { ProductPageComponent, ProductCreatePageComponent, ProductDetailPageComponent } from './containers';
import { MaterialModule } from '../shared/material.module';
import { reducer } from './reducers/product.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { ProductEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TitleModule } from '../dash/title';
import { TreeSelectModule } from '../dash/tree-select';
import { TreeViewModule } from '../dash/tree-view';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { AlertProductComponent } from './components/alert-product/alert-product.component';
import { AlertProductsModalComponent } from './components/alert-products-modal/alert-products-modal.component';
import { ProductModalPageComponent } from './components/product-modal-page/product-modal-page.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ProductAddGlnComponent } from './components/product-add-gln/product-add-gln.component';
import { AddCategoryComponent } from './components/add-category/add-category.component';

import { FeatureBarModule } from '@dash/feature-bar';
import { MDCDataTableModule, MdcCheckboxModule, MdcCard, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule } from '@angular-mdc/web';
import { DashCheckboxModule } from '@dash/checkbox';
import {NgxTreeSelectModule, ExpandMode} from 'ngx-tree-select';
import { GlnTabComponent } from './components/gln-tab/gln-tab.component';
import { GlnModalComponent } from './components/gln-modal/gln-modal.component';
// import { Ng2BootstrapModule } from 'ngx-bootstrap';


import { NgxSummernoteModule } from 'node_modules/ngx-summernote';
import { ImportExcelProductComponent } from './components/product-button/import-excel-product/import-excel-product.component';
import { ExportExcelProductComponent } from './components/export-excel-product/export-excel-product.component';
import { SelectSearchComponent } from '../shared/components/select-search/select-search.component';
import { SelectSearchModule } from '../shared/components/select-search/select-search.module';
import { UpdateScanStatusComponent } from './components/update-scan-status/update-scan-status.component';
import { AssignWarningProductComponent } from './components/assign-warning-product/assign-warning-product.component';
import { RemoveInfoProductComponent } from './components/remove-info-product/remove-info-product.component';
import { ConfirmRemoveComponent } from './components/remove-info-product/confirm-remove/confirm-remove.component';
import { ConfirmPasswordComponent } from './components/remove-info-product/confirm-password/confirm-password.component';

import { MatSelectSearchModule } from 'src/app/shared/components/mat-select-search/mat-select-search.module';
import { ProductDocumentsComponent } from './components/product-documents/product-documents.component';
import { ProductDocumentModalComponent } from './components/product-documents/product-document-modal/product-document-modal.component';
import { ProductDistributorListComponent } from './components/product-distributor/product-distributor-list/product-distributor-list.component';
import { CancelVerifyDistributorComponent } from './components/product-distributor/cancel-verify-distributor/cancel-verify-distributor.component';
import { UpdateDistributorComponent } from './components/product-distributor/update-distributor/update-distributor.component';
import { CreateProductDocumentsComponent } from './components/create-product-documents/create-product-documents.component';
import { ProductDocumentsPackageComponent } from './components/product-documents-package/product-documents-package.component';
import { VerifyDocumentListComponent } from './components/product-distributor/verify-document-list/verify-document-list.component';
import { ProductDistributorCreateComponent } from './components/product-distributor/product-distributor-create/product-distributor-create.component'
import { CreateEnterpriseComponent } from './components/product-distributor/create-enterprise/create-enterprise.component';
import { FocusDirective } from '../focus.directive';
import {ClosePopupDirective} from "@src/app/shared/directive/close-popup.directive";
import { AllowOrDenyContributeComponent } from './components/product-button/allow-or-deny-contribute/allow-or-deny-contribute.component';
import { UpdateContributeComponent } from './components/product-button/update-contribute/update-contribute.component';
import {BrowserModule} from "@angular/platform-browser";
import {ModalProductTypeComponent} from '@products/components/product-documents-package/modal-product-type/modal-product-type.component';

export const COMPONENTS = [
  ProductButtonComponent,
  ProductToggleRowComponent,
  ProductFilterComponent,
  ProductTableActionComponent,
  ProductListComponent,
  ProductCreateUpdateComponent,
  ProductDetailComponent,
  AlertProductComponent,
  AlertProductsModalComponent,
  ProductModalPageComponent,
  ProductAddGlnComponent,
  GlnTabComponent,
  GlnModalComponent,
  ImportExcelProductComponent,
  ExportExcelProductComponent,
  UpdateScanStatusComponent,
  AssignWarningProductComponent,
  RemoveInfoProductComponent,
  ConfirmRemoveComponent,
  ConfirmPasswordComponent,
  ProductDocumentsComponent,
  ProductDocumentModalComponent,
  ProductDistributorListComponent,
  CancelVerifyDistributorComponent,
  UpdateDistributorComponent,
  CreateProductDocumentsComponent,
  ProductDocumentsPackageComponent,
  VerifyDocumentListComponent,
  ProductDistributorCreateComponent,
  CreateEnterpriseComponent,
  ModalProductTypeComponent,

];

export const CONTAINERS = [
  ProductPageComponent,
  ProductCreatePageComponent,
  ProductDetailPageComponent,
  AddCategoryComponent,
  AllowOrDenyContributeComponent,
  UpdateContributeComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    CommonModule,
    ProductRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TitleModule,
    SharedModule,
    MatInputModule,
    NgxSummernoteModule,
    MatSelectSearchModule,

    // NgxTreeSelectModule.forRoot(
    //   {
    //     allowFilter: true,
    //     filterPlaceholder: 'Type your filter here...',
    //     maxVisibleItemCount: 5,
    //     idField: 'id',
    //     textField: 'name',
    //     childrenField: 'children',
    //     allowParentSelection: true,
    //     expandMode: ExpandMode.Selection
    //   }),
    MatFormFieldModule,
    TreeSelectModule,
    MatSelectInfiniteScrollModule,
    MaterialModule,
    MDCDataTableModule,
    MdcCheckboxModule,
    MdcCardModule,
    MdcIconModule,
    MdcButtonModule,
    MdcIconButtonModule,
    DashCheckboxModule,
    FeatureBarModule,
    MdcDialogModule,
    TreeViewModule,
    SelectSearchModule,
    StoreModule.forFeature(fromEffects.productsFeatureKey, fromEffects.reducers),
    DashFilterBarModule,
    EffectsModule.forFeature([ProductEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  exports: [ProductToggleRowComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [  ],
  bootstrap: [ [CONTAINERS, COMPONENTS] ]
})
export class ProductModule { }
