import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, concatMap, withLatestFrom, debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  ProductAttributeActions, ProductAttributePageActions, ProductAttributeListPageActions, ProductAttributesAPIActions,
} from '../actions';

import { ProductAttributeService } from '../services/product-attribute.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fromAttributeSet from '../reducers';

@Injectable()
export class ProductAttributeEffects {
  constructor(
    private actions$: Actions,
    private productAttributeService: ProductAttributeService,
    private store: Store<any>,
    private _snackBar: MatSnackBar,
  ) {}

  loadProductAttributes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProductAttributeListPageActions.loadProductAttributes),
      switchMap(action => {
        return this.productAttributeService.getListAttributeSet(action).pipe(
          switchMap((res: any) => {
          return [
            ProductAttributesAPIActions.loadProductAttributesSuccess({ data: res.data.items }),
            ProductAttributeListPageActions.loadProductAttributesSuccess({
              total: res.data.totalElement,
              ids: res.data.items.map(c => c.id)
            }),
            ProductAttributePageActions.loadProductAttributesSuccess({
              total: res.data.totalElement,
              rows: res.data.items
            })
          ]}),
          catchError(error => [
            ProductAttributesAPIActions.loadProductAttributesFailure({ error }),
            ProductAttributeListPageActions.loadProductAttributesFailure({ error })
          ])
        );
      })
    )
  });

  getProductAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAttributePageActions.getDetailAttributeSet),
      switchMap((actions) =>
      {
        return this.productAttributeService.getDetailAttributeSet(actions.id).pipe(
          map((res: any) =>
           {
            //  console.log('1', res);
             
            return ProductAttributePageActions.getDetailAttributeSetSuccess({ productAttribute: res.data })
           }
            )
           ,
          catchError(error => {
            return of(ProductAttributePageActions.getDetailAttributeSetFailure({ error }))
          }
          )
        )
          })
    )
  );

  updateProductAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAttributePageActions.updateProductAttribute),
      switchMap((actions) =>
      {
        return this.productAttributeService.updateAttributeSet(actions.productAttributeObject).pipe(
          map((productAttribute: any) =>
           {
            this._snackBar.open('Cập nhật bộ thuộc tính thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductAttributePageActions.updateProductAttributeSuccess({ productAttribute })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open('Cập nhật bộ thuộc tính thất bại', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(ProductAttributePageActions.updateProductAttributeFailure({ error }))
          }
          )
        )
          })
    )
  );

  addProductAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAttributePageActions.addProductAttribute),
      switchMap((actions) =>
      {
        return this.productAttributeService.createAttributeSet(actions.productAttributeObject).pipe(
          map((productAttribute: any) =>
           {
            this._snackBar.open('Thêm mới bộ thuộc tính thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return ProductAttributePageActions.addProductAttributeSuccess({ productAttribute })
           }
            )
           ,
          catchError(error => {
            this._snackBar.open('Thêm mới bộ thuộc tính thất bại', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(ProductAttributePageActions.addProductAttributeFailure({ error }))
          }
          )
        )
          })
    )
  );

  deleteProductAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAttributePageActions.deleteProductAttribute),
      switchMap((actions) =>
      {
        return this.productAttributeService.deleteAttributeSet(actions.id).pipe(
          map((res: any) =>
           {
              this._snackBar.open('Xóa bộ thuộc tính thành công', 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
              return ProductAttributePageActions.deleteProductAttributeSuccess({ id: actions.id })
           }
            )
           ,
          catchError(error => {
            // console.log(error);
            
            this._snackBar.open(error, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            return of(ProductAttributePageActions.deleteProductAttributeFailure({ error }))
          }
          )
        )
          })
    )
  );

  getListAttributeIgnore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductAttributePageActions.getListAttributeIgnoreSet),
      switchMap((actions) =>
      {
        return this.productAttributeService.getListAttributeIgnore(actions).pipe(
          map((res: any) =>
           {
            return ProductAttributePageActions.getListAttributeIgnoreSetSuccess({ rows: res.items, total: res.totalElement })
           }
            )
           ,
          catchError(error => {
            return of(ProductAttributePageActions.getListAttributeIgnoreSetFailure({ error }))
          }
          )
        )
          })
    )
  );
}