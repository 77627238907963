
import {
    createSelector,
    createFeatureSelector,
    combineReducers,
    Action,
    ActionReducerMap,
  } from '@ngrx/store';
  import * as fromAttributeProduct from './attribute-product.reducer';
  import * as fromAttributeProductList from './attribute-product-list.reducer';

  import * as fromAttributeListPage from './attribute-list-page.reducer';

  import * as fromAttributePage from './attribute-page.reducer';

  
  import * as fromRoot from '../../reducers';
  
  export const attributeProductFeatureKey = 'attributeProduct';
  
  export interface AttributeProductsState {
    [fromAttributeProduct.attributeProductFeatureKey]: fromAttributeProduct.State;
    [fromAttributeProductList.attributeProductListFeatureKey]: fromAttributeProductList.State;
    [fromAttributeListPage.attributeListPageFeatureKey]: fromAttributeListPage.State;
    [fromAttributePage.attributePageFeatureKey]: fromAttributePage.State;
  }
  
  export interface State extends fromRoot.State {
    [attributeProductFeatureKey]: AttributeProductsState;
  }
  
  export function reducers(state: AttributeProductsState | undefined, action: Action) {
    return combineReducers({
      [fromAttributeProduct.attributeProductFeatureKey]: fromAttributeProduct.reducer,
      [fromAttributeProductList.attributeProductListFeatureKey]: fromAttributeProductList.reducer,
      [fromAttributeListPage.attributeListPageFeatureKey]: fromAttributeListPage.reducer,
      [fromAttributePage.attributePageFeatureKey]: fromAttributePage.reducer,
    })(state, action);
  }
  
  export const selectAttributeProductState = createFeatureSelector<State, AttributeProductsState>(
    attributeProductFeatureKey
  );
  
  
  export const selectAttributeProductEntitiesState = createSelector(
    selectAttributeProductState,
    state => state.attributeProducts
  );
  
  
  
  export const {
    selectIds: selectAttributeProductIds,
    selectEntities: selectAttributeProductEntities,
    selectAll: selectAllCategories,
    selectTotal: selectTotalCategories,
  } = fromAttributeProduct.adapter.getSelectors(selectAttributeProductEntitiesState);
  
  
  export const selectAttributeProductListState = createSelector(
    selectAttributeProductState,
    state => state.attributeProductList
  );
  
  export const selectAttributeProductListIds = createSelector(
    selectAttributeProductListState,
    fromAttributeProductList.getIds
  );
  
  export const selectTotalAttributeProduct= createSelector(
    selectAttributeProductListState,
    fromAttributeProductList.getTotalAttributeProduct
  );
  
  export const selectLoadingDataAttributeProduct= createSelector(
    selectAttributeProductListState,
    fromAttributeProductList.getLoadingData
  );
  
  export const selectObjectFilterAttributeProduct= createSelector(
    selectAttributeProductListState,
    fromAttributeProductList.getObjectFilter
  );
  
  export const selectAttributeProductListResults = createSelector(
    selectAttributeProductEntities,
    selectAttributeProductListIds,
    (attributeProducts, ids) => {
      return ids
        .map(id => attributeProducts[id])
        .filter((attributeProduct): attributeProduct is any => attributeProduct != null);
    }
  );


  
// Attribute page
export const selectAttributePageState = createSelector(
  selectAttributeProductState,
  state => state.attributePage
);

export const selectAttributePageFilter = createSelector(
  selectAttributePageState,
  fromAttributePage.getFilter
);

export const selectPage = createSelector(
  selectAttributePageState,
  fromAttributePage.getPage
);

export const selectPageSize = createSelector(
  selectAttributePageState,
  fromAttributePage.getPageSize
);

export const selectAttributePageLoading = createSelector(
  selectAttributePageState,
  fromAttributePage.getLoading
);

export const selectAttributePageTotal = createSelector(
  selectAttributePageState,
  fromAttributePage.getTotal
);

export const selectAttributePageIds = createSelector(
  selectAttributePageState,
  fromAttributePage.getIds
);

export const selectAttributePageDisplayedColumns = createSelector(
  selectAttributePageState,
  fromAttributePage.getDisplayedColumns
);

export const selectCurrentAttribute = createSelector(
  selectAttributePageState,
  fromAttributePage.getCurrentAttribute
);

export const selectAttributePageResults = createSelector(
  selectAttributeProductState,
  selectAttributePageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// Attribute page
export const selectAttributeListPageState = createSelector(
  selectAttributeProductState,
  state => state.attributeListPage
);

export const selectAttributeListPageFilter = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getFilter
);

export const selectPageListPage = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getPage
);

export const selectPageSizeListPage = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getPageSize
);

export const selectAttributeListPageLoading = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getLoading
);

export const selectAttributeListPageTotal = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getTotal
);

export const selectAttributeListPageIds = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getIds
);

export const selectAttributeListPageDisplayedColumns = createSelector(
  selectAttributeListPageState,
  fromAttributeListPage.getDisplayedColumns
);

export const selectAttributeListPageResults = createSelector(
  selectAttributeProductEntities,
  selectAttributeListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);
  