
    <div class="toggle-row-content">
      <a class="toggle-row" (click) = "togglePopupSelect()">
        <mat-icon class="icon-settings">settings</mat-icon>
        <span>Ẩn/hiện cột</span>
        <mat-icon>expand_more</mat-icon>
      </a>
      <div class="select-row-popup" *ngIf="isOpenPopupSelect" >
        <section class="example-section" *ngFor="let column of displayedColumnsInput; let i = index">
          <mat-checkbox class="example-margin" (change) = "changeDisplayColumn()" [(ngModel)]="check[i]">{{column}}</mat-checkbox>
        </section>
      </div>
    </div>
