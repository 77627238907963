import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const loadProductAttribute = createAction(
  '[ProductAttribute Page] Load ProductAttribute',
  props<{ filter: any }>()
);

export const loadproductAttributesSuccess = createAction(
  '[ProductAttribute Page] Load productAttributes Success',
  props<{ productAttributes: any, total: number }>()
);

export const loadproductAttributesFailure = createAction(
  '[ProductAttribute Page] Load productAttributes Failure',
  props<{ error: any }>()
);

export const updateProductAttribute = createAction(
  '[ProductAttribute Page] Update ProductAttribute',
  props<{ productAttributeObject: any }>()
);

export const updateProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Update ProductAttribute Success',
  props<{ productAttribute: any }>()
);

export const updateProductAttributeFailure = createAction(
  '[ProductAttribute Page] Update ProductAttribute Failure',
  props<{ error: any }>()
);

export const addProductAttribute = createAction(
  '[ProductAttribute Page] Add ProductAttribute',
  props<{ productAttributeObject: any }>()
);

export const addProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Add ProductAttribute Success',
  props<{ productAttribute: any }>()
);

export const addProductAttributeFailure = createAction(
  '[ProductAttribute Page] Add ProductAttribute Failure',
  props<{ error: any }>()
);

export const updateListProductAttribute = createAction(
  '[ProductAttribute Page] Update List ProductAttribute',
  props<{ productAttributes: any, ProductAttribute: any }>()
);

export const updateListProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Update List ProductAttribute Success',
  props<{ productAttribute: any }>()
);

export const updateListProductAttributeFailure = createAction(
  '[ProductAttribute Page] Update List ProductAttribute Failure',
  props<{ error: any }>()
);

export const searchProductAttribute = createAction(
  '[ProductAttribute Page] Search ProductAttribute',
  props<{ objectSearch: any }>()
);

export const searchProductAttributeSuccess = createAction(
  '[ProductAttribute Page] Search productAttributes Success',
  props<{ productAttributes: any }>()
);

export const searchProductAttributeFailure = createAction(
  '[ProductAttribute Page] Search productAttributes Failure',
  props<{ error: any }>()
);


export const getDetailAttributeSet = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet',
  props<{ id: any }>()
);

export const getDetailAttributeSetSuccess = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Success',
  props<{ productAttribute: any }>()
);

export const getDetailAttributeSetFailure = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Failure',
  props<{ error: any }>()
);
