import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeViewComponent } from './tree-view.component';
import { MaterialModule } from '../../shared/material.module';




@NgModule({
  declarations: [TreeViewComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [TreeViewComponent]
})
export class TreeViewModule { }
