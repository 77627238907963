import { createAction, props } from '@ngrx/store';



export const loadProductAttributesSuccess = createAction(
  '[ProductAttributes Api] Load ProductAttributes Success',
  props<{ data: any }>()
);

export const loadProductAttributesFailure = createAction(
  '[ProductAttributes Api] Load ProductAttributes Failure',
  props<{ error: any }>()
);
