import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashCheckboxComponent } from './checkbox.component';


@NgModule({
  declarations: [DashCheckboxComponent],
  imports: [
    CommonModule
  ],
  exports: [DashCheckboxComponent]
})
export class DashCheckboxModule { }
