<div>
  <form [formGroup]="exportForm" (ngSubmit)="onSubmitForm()">

  <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
  </mat-toolbar>
  <div class="content-modal">
    <div class="form-group">
      <div class="label-form">Lựa chọn các cột dữ liệu cần xuất excel</div>

        <div>
          <mat-checkbox aria-label="Select All"
          [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
           Select All
         </mat-checkbox>
       </div>
       <div class="demo-select-all-checkboxes" >
         <mat-checkbox (click)="$event.stopPropagation()"
                         (change)="$event ? toggle(item, $event) : null"
                         [checked]="exists(item)"
                         [formControlName]="item.code"
                         *ngFor="let item of arrFiled">
           {{ item.name }}
         </mat-checkbox>
       </div>

    </div>
    <div>
      <p style="color: red">Bạn muốn xuất danh sách mã nào trong danh sách mẫ đã lọc</p>
      <div style="display: flex;">
        <div style="padding-right: 20px">
          <input type="number" formControlName="export_from"  placeholder="Từ" width="150px" >

        </div>
        <div >
          <input type="number"  formControlName="export_to" placeholder="Đến">

        </div>

      </div>

    </div>
  </div>

  <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
  <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
  <button mat-raised-button type="submit" [disabled]="!isValid" class="btn-stop"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
  </mat-spinner></mat-icon>Thực hiện</button>
  </mat-toolbar>
  </form>
</div>
