<form [formGroup]="attributeSetForm" (ngSubmit)="onFormSubmit()">

    <!-- <form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()"> -->
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">Gán bộ thuộc tính sản phẩm cho danh mục</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                <div class="label-form">Chọn bộ thuộc tính sản phẩm</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Bộ thuộc tính sản phẩm</mat-label>
                    <mat-select formControlName="id" #matSelect>
                        <mat-form-field appearance="outline" class="form-field-search">
                          <mat-icon matPrefix>search</mat-icon>
                          <input matInput
                              type="text"
                              name="filter-options"
                              id="filter-options"
                              placeholder="Search"
                              (keyup)= "changeInputSearch($event)"
                              (keydown)="$event.stopPropagation()"
                              formControlName="inputSearch">
                              <mat-icon matSurfix *ngIf="this.attributeSetForm.value.inputSearch" (click) = "clearInputAttributeSetSearch()">clear</mat-icon>
                        </mat-form-field>
                        <mat-option [value]="attributeSet.id" *ngFor="let attributeSet of listAttributeSet" >{{attributeSet.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()" >Hủy</button>
        <button mat-raised-button type="submit" class="btn-update" [disabled]="!attributeSetForm.valid">Cập nhật</button>
        </mat-toolbar>
    <!-- </form> -->
</form>
