import { createAction, props } from '@ngrx/store';

import { Category } from '../models';

export const loadCategories = createAction(
  '[Category Page] Load Categories',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadCategoriesSuccess = createAction(
  '[Category Page] Load Categories Success',
  props<{ total: number, rows: Category[] }>()
);

export const loadCategoriesFailure = createAction(
  '[Category Page] Load Categories Failure',
  props<{ error: any }>()
);

export const loadListCategoryByParent = createAction(
  '[Category Page] Load List Category Parent',
  props<{ filter: any, page: number, pageSize: number, id: any }>()
);

export const loadListCategoryByParentSuccess = createAction(
  '[Category Page] Load List Category Parent Success',
  props<{ total: number, rows: Category[] }>()
);

export const loadListCategoryByParentFailure = createAction(
  '[Category Page] Load List Category Parent Failure',
  props<{ error: any }>()
);

export const addCategory = createAction(
  '[Category Page] Add Category',
  props<{ categoryObject: any }>()
);

export const addCategorySuccess = createAction(
  '[Category Page] Add Category Success',
  props<{ category: any }>()
);

export const addCategoryFailure = createAction(
  '[Category Page] Add Category Failure',
  props<{ error: any }>()
);


export const updateCategory = createAction(
  '[Category Page] Update Category',
  props<{ categoryObject: any }>()
);

export const updateCategorySuccess = createAction(
  '[Category Page] Update Category Success',
  props<{ category: any }>()
);

export const updateCategoryFailure = createAction(
  '[Category Page] Update Category Failure',
  props<{ error: any }>()
);

export const assignAttributeSet = createAction(
  '[Category Page] Assign Attribute Set',
  props<{ object: any }>()
);

export const assignAttributeSetSuccess = createAction(
  '[Category Page] Assign Attribute Set Success',
  props<{ object: any }>()
);

export const assignAttributeSetFailure = createAction(
  '[Category Page] Assign Attribute Set Failure',
  props<{ error: any }>()
);



export const getAttributeSetByCategory = createAction(
  '[Category Page] Get AttributeSet',
  props<{ id: any }>()
);

export const getAttributeSetByCategorySuccess = createAction(
  '[Category Page] Get AttributeSet Success',
  props<{ listAttribute: any }>()
);

export const getAttributeSetByCategoryFailure = createAction(
  '[Category Page] Get AttributeSet Failure',
  props<{ error: any }>()
);


export const getTreeCategory = createAction(
  '[Category Page] Get Tree Category',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const getTreeCategorySuccess = createAction(
  '[Category Page] Get Tree Category Success',
  props<{ treeCategory: object }>()
);

export const getTreeCategoryFailure = createAction(
  '[Category Page] Get Tree Category Failure',
  props<{ error: any }>()
);

export const getDetailCategory = createAction(
  '[Category Page] Get Detail Category',
  props<{ id: any }>()
);

export const getDetailCategorySuccess = createAction(
  '[Category Page] Get Detail Category Success',
  props<{ category: object }>()
);

export const getDetailCategoryFailure = createAction(
  '[Category Page] Get Detail Category Failure',
  props<{ error: any }>()
);
