import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ic-select-box-display',
  templateUrl: './select-box-display.component.html',
  styleUrls: ['./select-box-display.component.scss']
})
export class SelectBoxDisplayComponent implements OnInit {

  @Input()
  data: any;

  constructor() { }

  ngOnInit(): void {}

}
