
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group">
                  <div class="label-form">Nhập danh sách mã vạch (*) </div>
                  <mat-form-field appearance="outline" class="form-field">
                      <textarea matInput formControlName="barcodes" (keydown.space)="$event.preventDefault()" (keyup)="checkBarcode()"></textarea>
                  </mat-form-field>
                  <div class="comment-text">(Mỗi dòng là một barcode)</div>
                  <div style="height: 40px; margin-bottom: 0.5rem;">
                    <button mat-raised-button class="primary btn-delete-barcode" type="button" (click)="clearListBarCode()">Xóa mã lỗi</button>
                  </div>
                  <div *ngIf="formGroup.value.barcodes" style="margin-bottom: 1rem;">
                    <div class="alertDangerQ" *ngIf="check_barcode.length > 0">
                      <h3>Dữ liệu trên có chứa các mã vạch không đúng định dạng</h3>
                      <p *ngFor="let item of check_barcode" style="margin-bottom: 5px;">(Dòng {{item.index}}) {{item.value}}</p>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="label-form">Chọn các thuộc tính cần xóa (*) </div>
                    <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
                      <mat-icon matSuffix class="icon-remove" *ngIf="formGroup.value.attributeIds" (click)="clearSelected()">cancel</mat-icon>
                      <mat-select formControlName="attributeIds" placeholder="Chọn thuộc tính" multiple (selectionChange)="changeSelected($event)" #select>
                        <mat-select-search [formControl]="bankMultiFilterCtrl"></mat-select-search>
                        <div class="select-all">
                          <mat-checkbox [(ngModel)]="allSelected"
                                          [ngModelOptions]="{standalone: true}"
                                          (change)="toggleAllSelection()">Chọn tất cả</mat-checkbox>
                        </div>
                        <mat-select-trigger>
                          {{this.listSelected.length ? this.listSelected.join('; ') : ''}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let attribute of filteredAttribute | async" [value]="attribute.attributeId" (click)="optionClick()">
                          {{attribute.attributeLabel}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
          </div>
          <div *ngIf="currentUser.role === 1 || currentUser.role === 4" style="margin-top: 1rem;">
            <mat-checkbox formControlName="allowUpdateProduct">Áp dụng bao gồm các sản phẩm đã được quản lý bởi Business</mat-checkbox>
          </div>
      </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!formGroup.valid"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
      </mat-spinner></mat-icon>Thực hiện</button>
      </mat-toolbar>
