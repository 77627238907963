<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mat-toolbar class="header-dialog">
		    <mat-icon class="icon-add">add_circle</mat-icon>
		    <label class="header-text">{{ data.filter.name }}</label>
	  	</mat-toolbar>
      <mdc-dialog-content>
        <mdc-radio-group
          [(ngModel)]="selectedPredefinedRangeKey"
          (change)="onPredefinedRangeSelected($event)"
          style="display: flex;">
          <mdc-form-field *ngFor="let k of predefinedRangeKeys" class="radio-button">
            <mdc-radio [value]="k"></mdc-radio>
            <label>{{ predefinedRanges[k].label }}</label>
          </mdc-form-field>
          <mdc-form-field class="radio-button">
            <mdc-radio [value]="'custom'"></mdc-radio>
            <label>Tùy chỉnh</label>
          </mdc-form-field>
        </mdc-radio-group>
        <div style="margin-left: 30px;">
          <mat-form-field>
            <mat-label>Từ ngày</mat-label>
            <input matInput readonly (click)="picker1.open()"  [disabled]= "isNotCustom" [(ngModel)]="startDate" [matDatepicker]="picker1" (click)="picker1.open()" [matDatepickerFilter]="startDateFilter" (dateChange)="onDateChanged('startDate', $event)">
            <mat-datepicker-toggle [disabled]= "isNotCustom" matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Đến ngày</mat-label>
            <input matInput readonly [disabled]= "isNotCustom" [(ngModel)]="endDate" [matDatepicker]="picke2" (click)="picke2.open()" [matDatepickerFilter]="endDateFilter" (dateChange)="onDateChanged('endDate', $event)">
            <mat-datepicker-toggle [disabled]= "isNotCustom" matSuffix [for]="picke2"></mat-datepicker-toggle>
            <mat-datepicker #picke2></mat-datepicker>
          </mat-form-field>
        </div>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close" class="btn-cancel">Hủy</button>
        <button mdcDialogButton [disabled]="!form.valid" (click)="onApply()" class="btn-apply">Đồng ý</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
