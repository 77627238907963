import { Action, createReducer, on } from '@ngrx/store';

import { ProductListPageActions } from '../actions';


export const productListPageFeatureKey = 'productListPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'price', 'owned_business', 'business_management', 'scan_sattus', 'authentication_sattus', 'number_of_scans', 'number_of_rate', 'number_of_qa', 'category','created_time', 'last_updated_time', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
};

const productListPageReducer = createReducer(
  initialState,
  on(
    ProductListPageActions.loadProducts,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    ProductListPageActions.loadProductsSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false,
        currentAttributeSet: {},
      }
    }
  ),
  on(
    ProductListPageActions.loadProductsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productListPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;
