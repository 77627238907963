<mat-form-field appearance="outline" style=" margin-bottom: -10px">
<mat-select placeholder="Chọn bộ thuộc tính" (selectionChange)="onChangeSelect($event)" [(value)] = "value"> 
  <mat-form-field appearance="outline" class="form-field-search">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput
        type="text"
        name="filter-options"
        id="filter-options"
        placeholder="Search"
        (keydown)= "changeInput($event)">
  </mat-form-field>
  <mat-option *ngFor="let item of items" [value]="item.id">
    {{item.name}}
  </mat-option>
</mat-select>
</mat-form-field>

<!-- <div>{{items[0].name}}</div> -->