import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { CategoryListPageActions } from '@category/actions';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { checkFormatBarcode } from '@src/app/shared/validate/validate';
import { ProductListPageActions, ProductActions } from '@products/actions';
// tslint:disable-next-line:max-line-length
import { onlyUnique, formatDate, formatNewDate, convertDateGrpcToFormatDate, formatDateGrpc, convertDateToFormatDate, buildImageUrls } from '@src/app/shared/common';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { MatSelect } from '@angular/material/select';
import * as fromProduct from '@products/reducers';
import * as fromCategory from '@category/reducers';
import { UploadComponent } from '@src/app/shared/upload/upload.component';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlnService } from '@gln/services/gln.service';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {BarcodeBase} from '@src/app/shared/common/barcode-base';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';
// @ts-ignore
import {DOCUMENT_PRODUCT_LIST} from '@src/app/constants';
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-create-product-documents',
  templateUrl: './create-product-documents.component.html',
  styleUrls: ['./create-product-documents.component.scss']
})
export class CreateProductDocumentsComponent  implements OnInit {


  @ViewChild('inputUpload', { read: ElementRef, static: false }) inputUpload: ElementRef;
  listProductDocument = DOCUMENT_PRODUCT_LIST;

  createDocumentForm;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory: any;
  currentUser: any;
  importName = [];
  listFileImport = [];
  documents = [];
  destroy$ = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateProductDocumentsComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
    private dialog: MatDialog,
    public productService: ProductService,
    private enterpriseService: GlnService,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog,
  ) {
    this.data = data;
  }

  async ngOnInit() {
    const barcode = this.productService.currentBarcode;
    // console.log(this.data);
    console.log(this.data);
    const url = window.location.href;
    switch (this.data.type) {
      case 'get' : {
        if (url.includes('gln')) {
          const click = new ClickTekoDto('enterprise_document', 'create',
            null,
            {}
          );
          clickTeko(click);
        }
        if (url.includes('products')) {
          const click = new ClickTekoDto('product_document', 'product_document_add',
            'http://datahub.icheck.vn/products/update/' + this.data.productId,
            {product_id : this.data.productId, barcode}
          );
          clickTeko(click);
        }

      }            break;
      case 'createDocumentProduct' : {
        const click = new ClickTekoDto('product_document', 'product_document_add',
          'http://datahub.icheck.vn/products/update/' + this.data.productId,
          {product_id : this.data.productId, barcode}
        );
        clickTeko(click);
      }                             break;
      case 'createDocumentEnterprise' : {
        const click = new ClickTekoDto('enterprise_document', 'create',
          null,
          null
        );
        clickTeko(click);
      }
    }
    this.createDocumentForm = this.formBuilder.group({
      // barcodes: '',
      title: [this.data?.row?.title || '', Validators.required],
      // thêm document type
      docProductType: [this.data?.row?.docProductType || ''],
      expireAt: [this.data?.row?.expireAt ? convertDateToFormatDate(this.data?.row?.expireAt) : ''],
      values: [this.data?.row?.values || '', Validators.required],
    });

    if (this.data.type === 'createDocumentProduct' && this.data.action === 'create') {
      this.createDocumentForm.get('docProductType').setValidators([Validators.required]);
      this.createDocumentForm.get('docProductType').updateValueAndValidity();
    }
    if (this.data?.row) {
      this.listFileImport = [...this.data?.row?.values];
      this.importName = [...this.data?.row?.values.map(item => item.name)];
    }

  }


  onFormSubmit() {
    // console.log(this.createDocumentForm.value);
    const formValue = {...this.createDocumentForm.value};
    Object.keys(formValue).forEach(key => {
      if (formValue[key] == null || formValue[key] === '') {
        delete formValue[key];
      }
    });
    const object = {
      ...formValue,
      title: this.createDocumentForm.value.title.trim(),
      values : this.createDocumentForm.value.values.map(item => {
        return {
          name: item.name,
          url: item.url
        };
      }),
      expireAt: this.createDocumentForm.value.expireAt ? formatNewDate(this.createDocumentForm.value.expireAt) : ''
    };
    if (this.data.action === 'update') {
      const body = {...object};
      delete body.docProductType;
      this.productService.updateDocument(this.data.row.documentId, body).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmUpdateProductDocumnet(this.data.row.documentId, body);
        } else {

          this._snackBar.open('Cập nhật giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }
      }, err => {
        const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
        alertEvent(alert);
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
    } else {
      if (this.data.type === 'get') {
        this.productService.createNewDocument(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {

          const response = {
            name: object.title,
            id: res.data.id
          };
          this.dialogRef.close(response);
        });
      } else if (this.data.type === 'createDocumentProduct') {


        this.productService.createNewDocument(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const response = {
            name: object.title,
            id: res.data.id
          };
          if (res) {
            const body = {
              documentIds: [ res.data.id ],
              productId: this.data.productId
            };
            this.productService.createDocumentOfProduct(body).pipe(
              takeUntil(this.destroy$)
            ).subscribe((res: any) => {
              if (res.code === 1104) {
                this.confirmCreateProductDocumnet(body);
              } else {

                this._snackBar.open('Gán giấy tờ cho sản phẩm thành công ', 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.dialogRef.close('accept');
              }

            }, err => {
              const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
              alertEvent(alert);
              this._snackBar.open(err, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            });
          }

        });
      } else if (this.data.action === 'assign-document') {
        this.productService.createNewDocument(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res) {
            const body = {
              documentIds: [res.data.id],
              enterpriseId: this.data.enterpriseId,
              productId: this.data.productId
            };
            this.productService.createDocumentDistributor(body).pipe(
              takeUntil(this.destroy$)
            ).subscribe((res: any) => {
              if (res.code === 1104) {
                this.confirmCreateProductDistributor(body);
              } else {

                this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.dialogRef.close('accept');
              }
            }, err => {

              this._snackBar.open(err, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            });
          }
        });
      } else {
        this.productService.createNewDocument(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const response = {
            name: object.title,
            id: res.data.id
          };
          if (res) {
            const body = {
              documentIds: [ res.data.id ],
              enterpriseId: this.data.enterpriseId
            };
            this.enterpriseService.createDocumentOfEnterprise(this.data.enterpriseId, body).pipe(
              takeUntil(this.destroy$)
            ).subscribe((res: any) => {
              if (res.code === 1104) {
                this.confirmCreateDocumentEnterprise(this.data.enterpriseId, body);
                this.dialogRef.close('accept');
              } else {
                this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
                this.dialogRef.close('accept');
              }
            }, err => {

              this._snackBar.open(err, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            });
          }

        });
      }
    }

  }

  confirmCreateDocumentEnterprise(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.enterpriseService.createDocumentOfEnterprise(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const alert =  new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        }, err => {
          const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert);
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmCreateProductDistributor(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createDocumentDistributor(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const alert =  new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Gán giấy tờ cho doanh nghiệp thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert);
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmUpdateProductDocumnet(documnetId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.updateDocument(documnetId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const alert =  new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Cập nhật giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert);
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmCreateProductDocumnet(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createDocumentOfProduct(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          const alert =  new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Gán giấy tờ cho sản phẩm thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          const alert =  new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
          alertEvent(alert);
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  clearSelected(key) {
    this.createDocumentForm.patchValue({
      [key]: null
    });
    this.createDocumentForm.updateValueAndValidity();
  }

  createDocumentFromPackage() {

  }

  createNewDocument() {

  }

  importFile(data: any) {
    if (data) {
      this.listFileImport.push(data);
      this.importName.push(data.name);
      this.createDocumentForm.controls.values.setValue(this.listFileImport);
    }
  }


  deleteFileImport(i) {
    this.listFileImport.splice(i, 1);
    this.importName.splice(i, 1);
    this.createDocumentForm.controls.values.setValue(this.listFileImport);

  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload giấy tờ`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }

      if (result.type === 'uploaded') {
          const data = result.data;
          data.map(res => {
            this.listFileImport.push(res);
            this.importName.push(res.name);
            this.createDocumentForm.controls.values.setValue(this.listFileImport);
          });

    }
  });
  }

  onRemoveDocument(field: string, i: number) {

    this.listFileImport.splice(i, 1);
    this.importName.splice(i, 1);
    this.createDocumentForm.controls.values.setValue(this.listFileImport);

  }

  openNewTab(url) {
    const img = buildImageUrls(url).original;
    if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
