import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'dash-feature-bar-section, [dashFeatureBarSection]',
  exportAs: 'dashFeatureBarSection',
  host: {
    'role': 'toolbar',
    'class': 'dash-feature-bar__section',
    '[class.dash-feature-bar__section--align-start]': 'align === "start"',
    '[class.dash-feature-bar__section--align-end]': 'align === "end"'
  },
  template: `
  <ng-content></ng-content>
  <span class="dash-feature-bar__title" *ngIf="title">{{title}}</span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SectionComponent {

  @Input() title?: string;
  @Input() align?: string;

}
