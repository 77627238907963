<form [formGroup]="filterForm">
  <mdc-chip-set input class="dash-filter-bar__list">
  <div class="div-search">
    <div *ngFor="let item of availableFilters, index as i">

      <div>
        <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'select-search-box' && item.show">
          <mat-label>{{item.name}}</mat-label>
          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>
            <mat-select [formControlName]="item.key" #matSelectCategory msInfiniteScroll (infiniteScroll)="lazyLoadCategory()">
              <mat-form-field appearance="outline" class="form-field-search" style="max-width: 300px;">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput
                    type="text"
                    name="filter-options"
                    id="filter-options"
                    placeholder="Search"
                    (keyup)= "changeInputSearch($event)"
                    (keydown)="$event.stopPropagation()"
                    [(ngModel)]="inputSearch" [ngModelOptions]="{standalone: true}"
                    (paste)="changeInputSearch($event)">
                    <mat-icon matSurfix *ngIf="inputSearch" style="font-size: 18px;" (click) = "clearInputSearch()">clear</mat-icon>
              </mat-form-field>
              <mat-option [value]="it.id" *ngFor="let it of listCategory" [title]="it.displayName">{{it.displayName}}</mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.key === 'enterpriseId' && item.show">
        <mat-label>{{item.name}}</mat-label>
        <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key" #matSelectEnterprise msInfiniteScroll (infiniteScroll)="lazyLoadEnterprise()">
            <mat-form-field appearance="outline" class="form-field-search" style="max-width: 300px;">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput
                  type="text"
                  name="filter-options"
                  id="filter-options"
                  placeholder="Search"
                  (keyup)= "changeInputSearchEnterprise($event)"
                  (keydown)="$event.stopPropagation()"
                  [(ngModel)]="inputSearchEnterprise" [ngModelOptions]="{standalone: true}"
                  (paste)="changeInputSearchEnterprise($event)">
                  <mat-icon matSurfix *ngIf="inputSearchEnterprise" style="font-size: 18px;" (click) = "clearInputEnterpriseSearch()">clear</mat-icon>
            </mat-form-field>
            <mat-option [value]="enterprise.id" *ngFor="let enterprise of listGln" title="{{enterprise.name}} (Prefix: {{enterprise.prefixCode}}, enterprise: {{enterprise.enterpriseCode}})">{{enterprise.name}} (Prefix: {{enterprise.prefixCode}}, enterprise: {{enterprise.enterpriseCode}})</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.key === 'productId' && item.show">
        <mat-label>{{item.name}}</mat-label>
        <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key" #matSelectProduct msInfiniteScroll (infiniteScroll)="lazyLoadProduct()">
            <mat-form-field appearance="outline" class="form-field-search" style="max-width: 300px;">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput
                  type="text"
                  name="filter-options"
                  id="filter-options"
                  placeholder="Search"
                  (keyup)= "changeInputSearchProduct($event)"
                  (keydown)="$event.stopPropagation()"
                  [(ngModel)]="inputSearchProduct" [ngModelOptions]="{standalone: true}"
                  (paste)="changeInputSearchProduct($event)">
                  <mat-icon matSurfix *ngIf="inputSearchProduct" style="font-size: 18px;" (click) = "clearInputProductSearch()">clear</mat-icon>
            </mat-form-field>
            <mat-option [value]="product.id" *ngFor="let product of listProduct" title="{{product.productName}} (Barcode: {{product.barcode}})">{{product.productName}} (Barcode: {{product.barcode}})</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'multiselect' && item.show">
          <mat-label>{{item.name}}</mat-label>
          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key"  multiple>
            <mat-form-field appearance="outline" class="form-field-search" style="max-width: 300px;">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput
                     type="text"
                     name="filter-options"
                     id="filter-options"
                     placeholder="Search"
                     (keydown)="$event.stopPropagation()"
                     [(ngModel)]="inputSearchNation" [ngModelOptions]="{standalone: true}">
              <mat-icon matSurfix *ngIf="inputSearchNation" style="font-size: 18px;" (click) = "clearInputNationSearch()">clear</mat-icon>
            </mat-form-field>
            <mat-option [value]="nation.id" *ngFor="let nation of (listNation | async) | filter:inputSearchNation">{{nation.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'select-nation' && item.show">
        <mat-label>{{item.name}}</mat-label>
        <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key" #matSelectNation>
            <mat-form-field appearance="outline" class="form-field-search" style="max-width: 300px;">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput
                  type="text"
                  name="filter-options"
                  id="filter-options"
                  placeholder="Search"
                  (keydown)="$event.stopPropagation()"
                  [(ngModel)]="inputSearchNation" [ngModelOptions]="{standalone: true}"
                  (paste)="changeInputSearchNation()">
                  <mat-icon matSurfix *ngIf="inputSearchNation" style="font-size: 18px;" (click) = "clearInputNationSearch()">clear</mat-icon>
            </mat-form-field>
            <mat-option [value]="nation.id" *ngFor="let nation of (listNation | async) | filter:inputSearchNation">{{nation.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.key === 'searchType' && item.type === 'select-search' && item.show">
        <mat-label>{{item.name}}</mat-label>
          <!-- <span matSuffix style="margin-right: 8px;" ></span> -->
          <mat-icon matSuffix *ngIf="item.allowRemove" (click)= "removeInputSearch(i , item.key)" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key" (selectionChange)="changeSelection($event)">
              <mat-option [value]="it.value" *ngFor="let it of item.settingComponentConfig?.options">{{it.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'select-box' && item.show">
        <mat-label>{{item.name}}</mat-label>
          <!-- <span matSuffix style="margin-right: 8px;" ></span> -->
          <mat-icon matSuffix *ngIf="item.allowRemove" (click)= "removeInputSearch(i , item.key)" class="icon-remove">cancel</mat-icon>
          <mat-select [formControlName]="item.key" (ngModelChange)="changeSelectionBox($event)">
              <mat-option [value]="it.value" *ngFor="let it of item.settingComponentConfig?.options" >{{it.name}}</mat-option>
          </mat-select>
      </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'text' && item.show">
        <input matInput [placeholder]="item.name" [formControlName]="item.key">
        <mat-icon matSuffix *ngIf="item.allowRemove" (click)= "removeInputSearch(i , item.key)" class="icon-remove">cancel</mat-icon>
      </mat-form-field>
        <mat-form-field appearance="outline" class="form-field" *ngIf="item.type === 'number' && item.show">
          <input matInput [placeholder]="item.name" [formControlName]="item.key" type="number">
          <mat-icon matSuffix *ngIf="item.allowRemove" (click)= "removeInputSearch(i , item.key)" class="icon-remove">cancel</mat-icon>
        </mat-form-field>
      </div>
      <div style="position: relative;" >
        <div *ngIf="item.type === 'number-range' && item.show" class="input-number" [tabIndex]="i">
          <span style="flex-grow: 1;" (click)="saveIndexNumber(i)" class="span-number" #inputNumber>{{item.displayName || item.name }}</span>
          <mat-icon (click)="saveIndexNumber(i)" #iconNumber>arrow_drop_down</mat-icon>
          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" class="icon-remove" *ngIf="item.allowRemove">cancel</mat-icon>

          <div *ngIf="i === currentIndexNumber" class="popup-select-range-number" #divRangeNumber>
              <div class="div-number">
                <span class="label-range">Từ</span>
                <input outline formControlName="{{item.key}}_from" class="input-range-number" (ngModelChange)="updateRangeNumber(item, i)">
              </div>
              <div class="div-number">
                <span class="label-range">Đến</span>
                <input outline formControlName="{{item.key}}_to" class="input-range-number" (ngModelChange)="updateRangeNumber(item, i)">
              </div>
            <div class="div-button">
              <button class="btn-close" (click)="currentIndexNumber = -1">Close</button>
            </div>
          </div>
        </div>
      </div>
<!--      <div style="position: relative;">-->
<!--        <div *ngIf="item.type === 'daterange1' && item.show" class="input-number" [tabIndex]="i">-->
<!--&lt;!&ndash;          <span style="flex-grow: 1;" (click)="saveIndexPicker(i)" class="span-number" #inputDate>{{item.displayName || item.name}}</span>&ndash;&gt;-->
<!--          <mat-icon (click)="saveIndexPicker(i)" #iconDate>arrow_drop_down</mat-icon>-->
<!--          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>-->

<!--          <div *ngIf="i === currentIndexPicker" class="popup-select-range-number" #divRangeDate>-->
<!--            <span class="label-range">Từ ngày</span>-->
<!--            <mat-form-field appearance="outline">-->
<!--              <input matInput readonly [matDatepicker]="picker" (click)="picker.open()" (dateChange)="updateRangeDate(item,  i)" formControlName="{{item.key}}_at_from">-->
<!--              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--              <mat-datepicker #picker ></mat-datepicker>-->
<!--            </mat-form-field>-->
<!--            <span class="label-range">Đến ngày</span>-->
<!--            <mat-form-field appearance="outline">-->
<!--              <input matInput readonly [matDatepicker]="picker1" (click)="picker1.open()" (dateChange)="updateRangeDate(item, i)" formControlName="{{item.key}}_at_to">-->
<!--              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>-->
<!--              <mat-datepicker #picker1 (dateChange)="updateRangeDate(item, i)"></mat-datepicker>-->
<!--            </mat-form-field>-->
<!--            <div class="div-button">-->
<!--              <button class="btn-close" (click)="currentIndexPicker = -1">Close</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div style="position: relative;">
        <div *ngIf="item.type === 'daterange2' && item.show" class="input-number" [tabIndex]="i">
          <span style="flex-grow: 1;" (click)="saveIndexPicker(i)" class="span-number" #inputDate>{{((item.displayName1 || '') +( item.displayName2 || '')) || item.name}}</span>
          <mat-icon (click)="saveIndexPicker(i)" #iconDate>arrow_drop_down</mat-icon>
          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>

          <div *ngIf="i === currentIndexPicker" class="popup-select-range-number" #divRangeDate>
            <span class="label-range">Từ ngày</span>
            <mat-form-field appearance="outline">
              <input matInput readonly [matDatepicker]="picker" (click)="picker.open()" (dateChange)="updateRangeDate($event.target.value,item.key + '_to_date', i)" formControlName="update_from_{{item.key}}">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker ></mat-datepicker>
            </mat-form-field>
            <span class="label-range">Đến ngày</span>
            <mat-form-field appearance="outline">
              <input matInput readonly [matDatepicker]="picker1" (click)="picker1.open()" (dateChange)="updateRangeDate($event.target.value,item.key + '_from_date', i)"  formControlName="update_to_{{item.key}}">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <div class="div-button">
              <button class="btn-close" (click)="currentIndexPicker = -1">Close</button>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative;">
        <div *ngIf="item.type === 'daterange' && item.show" class="input-number" [tabIndex]="i">
          <span style="flex-grow: 1;" (click)="saveIndexPicker(i)" class="span-number" #inputDate>{{((item.displayName1 || '') +( item.displayName2 || ''))|| item.name}}</span>
          <mat-icon (click)="saveIndexPicker(i)" #iconDate>arrow_drop_down</mat-icon>
          <mat-icon matSuffix (click)= "removeInputSearch(i , item.key)" *ngIf="item.allowRemove" class="icon-remove">cancel</mat-icon>

          <div *ngIf="i === currentIndexPicker" class="popup-select-range-number" #divRangeDate>
              <span class="label-range">Từ ngày</span>
              <mat-form-field appearance="outline">
                <input matInput readonly [matDatepicker]="picker3" (click)="picker3.open()" (dateChange)="updateRangeDate($event.target.value,item.key + '_to_date', i)" formControlName="{{item.key}}_from_date">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3 ></mat-datepicker>
              </mat-form-field>
              <span class="label-range">Đến ngày</span>
              <mat-form-field appearance="outline">
                <input matInput readonly [matDatepicker]="picker4" (click)="picker4.open()" (dateChange)="updateRangeDate($event.target.value,item.key + '_from_date', i)" formControlName="{{item.key}}_to_date">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4 (dateChange)="updateRangeDate($event.target.value,item.key + '_from_date', i)"></mat-datepicker>
              </mat-form-field>
            <div class="div-button">
            <button class="btn-close" (click)="currentIndexPicker = -1">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-btn">
    <button class="btn-more" (click) = "openDialogMore()" #btnMore>More <mat-icon class="icon-more">keyboard_arrow_down</mat-icon></button>
    <button class="btn-refresh" (click) = "resetForm()">Làm mới</button>
    <div *ngIf="isOpenSelectSearch" class="div-more" #divMore>
            <div *ngFor="let item of availableFilters, index as i">
              <mat-checkbox *ngIf="!item.show" (change)="changeCheckbox(i)">
                {{item.name}}
              </mat-checkbox>
            </div>
    </div>
  </div>
</mdc-chip-set>
</form>
