import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BaseService} from "@src/app/services/base.service";
import {InputAddLocationDto} from "@src/app/services/DTO/input-add-location.dto";
import {InputUpdateLocationDto} from "@src/app/services/DTO/input-update-location.dto";

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {
  getCountries(): Observable<any> {
    return this.http.get<any>(`/countries`);
  }
  getLocation(): Observable<any> {
    return this.get<any>(`/location?type=0`);
  }
  getCities(query: any): Observable<any> {
    return this.get<any>(`/cities`, { params: query });
  }
  getLocationCities({ parent_code , keyword, type }) {
    return this.get<any>(`/location` , { params : { parent_code , keyword, type }});
  }
  getLocationDistrict({ parent_code , keyword, type , parent_id}) {
    return this.get<any>(`/location` , { params : { parent_code , keyword, type, parent_id }});
  }

  getLocationSubProvince({  keyword, type , parent_id}) {
    console.log(123)
    return this.get<any>(`/location` , { params : {  keyword, type, parent_id }});
  }
  getDistricts(query: any): Observable<any> {
    return this.get<any>(`/districts`, { params: query });
  }

  getWards(query: any): Observable<any> {
    return this.get<any>(`/wards`, { params: query });
  }
  addLocation(body: InputUpdateLocationDto) {
     return this.post('/location' , body);
  }
  updateLocation(body: InputUpdateLocationDto) {
    console.log(body)
      return this.put('/location', body);
  }
}
