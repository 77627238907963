<div>
    <form [formGroup]="importForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-control">
                <div class="label-form">Chọn file</div>
            </div>
            <div class="form-control">
                <input type="file" class="inputfile" formControlName="file_import">
            </div>
            <div class="form-control">
                <mat-checkbox class="example-margin" formControlName="allow_create">Chỉ tạo mới GLN chưa tồn tại</mat-checkbox>
            </div>
            <div class="form-control">
                <mat-checkbox class="example-margin" formControlName="allow_update">Ghi đè thông tin GLN đã tồn tại</mat-checkbox>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop">Bắt đầu</button>
        </mat-toolbar>
    </form>
</div>
