<mat-tab-group animationDuration="0ms" class="mat-tab-group">
    <mat-tab [label]="label">
      <ng-template matTabContent>
        <ic-product-modal-page
        [isUpdate]="isUpdate"></ic-product-modal-page>
      </ng-template>
      
    </mat-tab>
    <!-- <mat-tab label="Test">
      <ic-tree-view></ic-tree-view>
    </mat-tab> -->
    <mat-tab label="Doanh nghiệp sở hữu" *ngIf="isUpdate">
      <ng-template matTabContent>
        <ic-gln-tab></ic-gln-tab>
      </ng-template>
      
    </mat-tab>
    <mat-tab label="Nhà phân phối" *ngIf="isUpdate">
      <ng-template matTabContent>
        <ic-product-distributor-list></ic-product-distributor-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Cảnh báo" *ngIf="isUpdate">
      <ng-template matTabContent>
        <ic-alert-product></ic-alert-product>
      </ng-template>
    </mat-tab>
    <mat-tab label="Giấy tờ sản phẩm" *ngIf="isUpdate">
      <ng-template matTabContent>
        <ic-product-documents></ic-product-documents>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
