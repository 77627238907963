import {ProductService} from '@products/services/product.services';
import {FormGroup} from '@angular/forms';
import {checkFormatBarcode} from '@src/app/shared/validate/validate';
import {AfterViewInit, OnInit} from '@angular/core';

export class BarcodeBase implements OnInit, AfterViewInit {
   constructor(public productService: ProductService, public productId: string) {
     if(this.productId){
       this.productService.getDetailProduct(this.productId).subscribe((z: any) => {
         this.barcode = z.data.barcode;
       })
     }



  }
  async ngOnInit(){

   }
  check_barcode: any[] = [];
  formGroup: FormGroup;
  barcode: string;
  ngAfterViewInit(): void {
    // @ts-ignore
    document.querySelector('.cdk-global-overlay-wrapper').style.overflowY = 'scroll';
    // @ts-ignore
    document.querySelector('.cdk-overlay-pane').style.overflowY = 'scroll';
  }
  checkBarcode() {
    this.check_barcode = [];
    const barcode = this.formGroup.value.barcodes.split(/\r?\n/);
    for (let i = 0; i < barcode.length; i++) {
      const check = checkFormatBarcode(barcode[i].substr(0, barcode[i].length - 1));
      // console.log(check);

      const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
      if (result === false) {
        const res = {
          index: i + 1,
          value: barcode[i]
        };
        this.check_barcode.push(res);
      }
    }
  }
  clearListBarCode() {
    // console.log(this.assignAlertForm.value.barcodes)
    const barcode = this.formGroup.value.barcodes.split(/\r?\n/);
    const barCodeValid = [];
    for (let i = 0; i < barcode.length; i++) {
      const check = checkFormatBarcode(barcode[i].substr(0, barcode[i].length - 1));
      // console.log(check);

      const result = barcode[i].substr(barcode[i].length - 1) === ('' + check);
      if (result) {
        barCodeValid.push(barcode[i]);
      }
    }

    this.formGroup.patchValue({
      barcodes: barCodeValid.join('\n')
    });
    this.checkBarcode();
    // console.log(barCodeValid);
  }
}
