import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Product } from '../models/product.model';
import * as fromProducts from '../reducers';
import {
  ProductActions,
} from '../actions';

@Component({
  selector: 'ic-product-detail-page',
  template: `
    <mat-card style="margin:1rem;">
      <ic-product-detail></ic-product-detail>
    </mat-card>
  `,
  styles: [
    `
      h4 {
        padding: 1rem;
      }
    `
  ]
})
export class ProductDetailPageComponent implements AfterViewInit, OnInit {
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
