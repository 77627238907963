import { Action, createReducer, on } from '@ngrx/store';

import { GlnListPageActions } from '../actions';


export const glnListPageFeatureKey = 'glnListPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name',  'status', 'nation', 'area', 'address', 'info', 'type','enterprise_special','updated_at', 'action'],
  error: null,
  page: 1,
  pageSize: 10,
};

const glnListPageReducer = createReducer(
  initialState,
  on(
    GlnListPageActions.loadGlns,
    (state, { page, pageSize, filter }) => {
      return {
        ...state,
        page,
        pageSize,
        filter,
        loading: true
      }
    }
  ),
  on(
    GlnListPageActions.loadGlnsSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false
      }
    }
  ),
  on(
    GlnListPageActions.loadGlnsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return glnListPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

