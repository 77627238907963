<div>
    <form [formGroup]="alertForm" (ngSubmit)="onFormSubmit()">
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">{{data.label}}</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                    <div class="label-form">Tiêu đề (*)</div>
                    <mat-form-field appearance="outline" class="form-field">
                    <input matInput placeholder="Nhập tiêu đề" formControlName="title" type="text" maxlength="255">
                    </mat-form-field>
            </div>
            <div class="form-group">
                <div class="label-form">Mô tả (*)</div>
                <mat-form-field appearance="outline" class="form-field">
                <input matInput placeholder="Nhập mô tả" formControlName="description">
                </mat-form-field>
            </div>
            <div class="form-group">
              <div class="label-form">Nội dung (*)</div>
              <!-- <mat-form-field appearance="outline" class="form-field"> -->
              <!-- <input matInput placeholder="Nhập nội dung" formControlName="content"> -->
              <!-- <textarea matInput placeholder="Nhập nội dung" formControlName="content"></textarea> -->
              <!-- </mat-form-field> -->
              <div class="content">
                <input [ngxSummernote]="config" type="text" formControlName="content" />
              </div>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-stop" [disabled]="!alertForm.valid" *ngIf="data.action === 'create'">Thêm</button>
        <button mat-raised-button type="submit" class="btn-stop" [disabled]="!alertForm.valid" *ngIf="data.action === 'update'">Sửa</button>
        </mat-toolbar>
    </form>
</div>

