import { SelectionModel } from '@angular/cdk/collections';
import { AlertConfirmModalComponent } from './../alert-confirm-modal/alert-confirm-modal.component';
import { AlertModalComponent } from './../alert-modal/alert-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, Subject, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  Inject,
  OnDestroy
} from '@angular/core';
import * as fromAlert from '../reducers';
import { AlertListPageActions, AlertActions } from '../actions';
import { MDCDataTable, MdcDialog, MDCDataTableRowSelectionChangedEvent } from '@angular-mdc/web';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
declare function searchTeko(info);

@Component({
  selector: 'ic-alert-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterAlert();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class AlertListComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  set rows(rows: any) {
    this.selection.clear();
    this._rows = rows;
  }
  get rows(): any {
    return this._rows;
  }

  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private store: Store<fromAlert.State>,
    @Inject('availableFilters') public availableFilters: [],
    private _changeDetectorRef: ChangeDetectorRef) {
      this.filter$ = store.pipe(select(fromAlert.selectAlertListPageFilter));
      this.page$ = store.pipe(select(fromAlert.selectPage));
      this.pageSize$ = store.pipe(select(fromAlert.selectPageSize));
      this.parent_id$ = store.pipe(select(fromAlert.selectAlertParentId));
    }

  @Input()
  pageSize: number;

  @Input()
  total: number;
  private _rows: any = [];

  _displayedColumns: string[];

  @Output()
  deleteItems = new EventEmitter<any>();

  @Output()
  load = new EventEmitter<any>();


  changesSubscription: Subscription;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  parent_id$: Observable<number>;
  categories$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  filter: any;
  @ViewChild(FilterBarNewComponent, {static: true}) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MDCDataTable, {static: true}) table: MDCDataTable;

  desserts = [
    {checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty'},
    {checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more'},
    {checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor'}
  ];

  ngOnInit(): void {

    enterScreenView(TekoValueDto.ALERT_LIST.SCREEN_NAME, TekoValueDto.ALERT_LIST.CONTENT_TYPE);
  }

  ngAfterViewInit() {
    this.load.emit([
      this.paginator,
      this.filterBar.filterForm.value
    ]);

  }
  changePage(event) {
    searchTeko({ ...this.filterBar.filterForm.value , page: event.pageIndex +  1,  pageSize: event.pageSize});
    this.load.emit([
      this.paginator,
      this.filterBar.filterForm.value
    ]);
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  changeFilter(data) {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.load.emit([
      this.paginator,
      data
    ]);
  }
  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // this.selectionChangedEvent = event;
    // this.desserts[event.index].checked = event.selected;
    // console.log(event);
    // this.selection.toggle(dessert);
  }

  onSelectedAll(): void {
    // this.desserts.forEach(_ => _.checked = true);
  }

  onUnselectedAll(): void {
    // this.desserts.forEach(_ => _.checked = false);
  }


  loadAlert(page, pageSize, filter?) {
    // console.log(page, pageSize);
    alert(123);
    this.store.dispatch(AlertListPageActions.loadAlerts({filter: filter || {}, page, pageSize}));
  }

  createAlert() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '35rem';
    dialogConfig.data = {
      label: 'Thêm cảnh báo',
      action: 'create',
    };
    let click = new ClickTekoDto(TekoValueDto.ALERT_LIST.SCREEN_NAME, TekoValueDto.ALERT_CREATE.CONTENT_NAME.CREATE, null, null);
    clickTeko(click);
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
        if(result !== 'finish'){
          click = new ClickTekoDto(TekoValueDto.ALERT_CREATE.SCREEN_NAME, TekoValueDto.ALERT_CREATE.CONTENT_NAME.CANCEL, null, null);
          clickTeko(click);
        }
       if (result !== 'close') {

        // console.log(this.page, this.pageSize);
        // setTimeout(() => {
        //   this.filterBar.resetForm();
        //   this.loadAlert(1, this.pageSize);
        // }, 500);
      }
       console.log('The dialog was closed');
    });
  }

  updateAlert(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Sửa cảnh báo',
      action: 'update',
      data: element,
    };
    const dialogRef = this.dialog.open(AlertModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if(result !== 'finish'){
        const click = new ClickTekoDto(TekoValueDto.ALERT_CREATE.SCREEN_NAME, TekoValueDto.ALERT_CREATE.CONTENT_NAME.CANCEL, null, null);
        clickTeko(click);
      }
      console.log('The dialog was closed');
    });
  }

  deleteAlert(id) {
    const click = new ClickTekoDto(TekoValueDto.ALERT_DELETE.REGION_NAME, TekoValueDto.ALERT_DELETE.CONTENT_NAME, null, null);
    clickTeko(click);
    const dialogRef = this.dialog2.open(AlertConfirmModalComponent);
    dialogRef.afterClosed().subscribe(result => {
      const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
      alertEvent(alert);
      this.rows = this.rows.filter(z => z.id !== id);
      console.log('The dialog was closed');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.rows.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

}
