import { FormControl } from '@angular/forms';

export function calculateCheckDigit(number: string): number {
  return checkFormatBarcode(number);
}
export function validateCheckDigit(value) {
    const number = value;
    const checkDigit = calculateCheckDigit(number.substr(0, number.length - 1));
    // console.log(number.substr(number.length - 1) === ('' + checkDigit));

    return number.substr(number.length - 1) === ('' + checkDigit);
    // return number.substr(number.length - 1) === ('' + checkDigit) ? null : { 'isBarCode': true };
  }

export function checkFormatBarcode(number: string): number {
    if (isNaN(+number)) {
      return null;
    }
    const length = number.length;
    if ([7, 11, 12, 13, 16, 17].indexOf(length) === -1) {
      return null;
    }
    const num = length - 1;
    let isMultiplyBy3 = true;
    let sum = 0;
    for (let i = num; i >= 0; i--) {
      sum += (+number[i]) * (isMultiplyBy3 ? 3 : 1);
      isMultiplyBy3 =  !isMultiplyBy3;
    }
    const nextMultipleOfTen = Math.ceil(sum / 10) * 10;
    return nextMultipleOfTen - sum;

  }


export function checkPrefixBarCode(bar_code, prefix) {
    if (!bar_code || !prefix) { return false; }
    return bar_code.startsWith(prefix);
  }


export function noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

export function checkSelectValueAttribute(control: FormControl) {
    const selectValue = control.value;
    return selectValue.length >= 2 ? null : { invalid: true };
  }

export function hasWhiteSpace(s: FormControl) {
    return /\s/g.test(s.value) ? { invalid: true } : null;
  }

export function checkBarCodeExist(control: FormControl) {
    const isBarCodeExist = control.value;
    return isBarCodeExist ? null : { whitespace: true };
  }

export function checkBarcode() {
    this.check_barcode = [];
    const barcode = this.barcodes.split(/\r?\n/);
    for (let i = 0; i < barcode.length; i++) {
      const check = this.validateGtin(barcode[i]);
      if (check == false) {
        const obj = {
          i,
          value: barcode[i]
        };
        this.check_barcode.push(obj);
      }
    }
  }

export function validateGtin(barcode) {
    const code = barcode;
    const digits = () => /^\d{8,13}$/g.test(code);
    const validlengths = [8, 12, 13];
    if (!digits() || !validlengths.includes(code.length)) { return false; }

    let checksum = 0;
    const codelist = code.split('');
    const checkdigit = parseInt(codelist.pop(), 10);
    codelist.map((value, index) => {
      const digit = parseInt(value, 10);
      if (code.length % 2 === 1) { checksum += index % 2 ? digit * 3 : digit; }
      else { checksum += index % 2 ? digit : digit * 3; }
    });

    let check = checksum % 10;
    if (check !== 0) { check = 10 - check; }
    if (check === checkdigit) { return true; }
    return false;
  }
