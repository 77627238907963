import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashFilter } from '@dash/filter-bar/filter-bar.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable } from 'rxjs';
import { MdcDialog } from '@angular-mdc/web';
import { Store, select } from '@ngrx/store';
import * as fromAlert from '../reducers';
import { AlertListPageActions } from '@alert/actions';
import { clean } from '@src/app/shared/common';


@Component({
    selector: 'ic-alert',
    templateUrl: './alert-list-page.component.html',
    styles: []
})
export class AlertContainerComponent implements OnInit {
  displayedColumns: string[] = ['select', 'id', 'image', 'name',  'order_sort', 'rating_attribute', 'product_attribute', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  availableFilters: DashFilter[] = [];
  title = "Quản lý danh mục"

  selection = new SelectionModel<any>(true, []);

  filter$: Observable<number>;
  loading$: Observable<boolean>;
  total$: Observable<number>;
  alerts$: Observable<any>;
  displayedColumns$: Observable<string[]>;
  parent_id$: Observable<any>;
  parent_id = null;

  constructor(
    private dialog: MdcDialog,
    // private store2: NgxsStore,
    private store: Store<fromAlert.State>) {
      this.alerts$ = store.pipe(select(fromAlert.selectAlertListPageResults));
      this.total$ = store.pipe(select(fromAlert.selectAlertListPageTotal));
      this.filter$ = store.pipe(select(fromAlert.selectAlertListPageFilter));
      this.loading$ = store.pipe(select(fromAlert.selectAlertListPageLoading));
      this.displayedColumns$ = store.pipe(select(fromAlert.selectAlertListPageDisplayedColumns));
      this.parent_id$ = store.pipe(select(fromAlert.selectAlertParentId));
    }

  ngOnInit(): void {
    // this.category$.subscribe((data) => {
    //   console.log('ok', data);
    // });
    // setTimeout(() => {
    //   this.store2.dispatch(new LoadAlert('aaaaa')).subscribe(() => {
    //     console.log('ok');
    //   });
    // }, 3000);
    this.parent_id$.subscribe(it => {
      this.parent_id = it;
      
    })
  }

  loadAlert([pageData, filterData]: any) {
    // console.log(parent_id);
    
    const limit = pageData ? pageData.pageSize : this.pageSize;
    const offset = limit * (pageData ? pageData.pageIndex : 0);
    const filter = clean(filterData)
    console.log('34434', limit);
    console.log('34434', offset);
    
    // this.store.dispatch(
    //   AlertListPageActions.loadAlerts({
    //     filter,
    //     offset,
    //     limit
    //   })
    // );
      this.store.dispatch(
        AlertListPageActions.loadAlerts({
          filter,
          page: pageData.pageIndex + 1,
          pageSize: pageData.pageSize,
        })
      );
    // this.store.dispatch(
    //   AlertListPageActions.loadListAlertByParent({
    //     filter,
    //     offset,
    //     limit,
    //     parent_id: null
    //   })
    // );
  }
}
