import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'dash-feature-bar-row, [dashFeatureBarRow]',
  exportAs: 'dashFeatureBarRow',
  host: {
    'class': 'dash-feature-bar__row'
  },
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
