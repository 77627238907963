import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AttributeProductActions, AttributeApiActions } from '../actions';


export const attributeProductFeatureKey = 'attributeProducts';

export interface State extends EntityState<any> {
  selectedAttributeId: string | null;
  total: number;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (attribute: any) => {
    
    return attribute.attributeId;
  },
});

export const initialState: State = adapter.getInitialState({
  selectedAttributeId: null,
  total: 0,
});

export const attributeReducer = createReducer(
  initialState,
  on(
    AttributeProductActions.loadAttributeProductsSuccess,
    (state, { attributeProducts }) => {
      
      return adapter.addMany(attributeProducts, state)
    }
  ),
  on(AttributeProductActions.updateAttributeProduct, (state, { attributeProductObject }) => {
    // console.log(attributeProductObject);
    
    return adapter.updateOne({ id: attributeProductObject.id, changes: attributeProductObject }, state);
  }),
  on(AttributeProductActions.updateAttributeProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(AttributeProductActions.addAttributeProduct, (state, { attributeProductObject }) => {
    // console.log(attributeProductObject);
    
    return adapter.addOne(attributeProductObject , state);
  }),
  on(AttributeProductActions.addAttributeProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(AttributeProductActions.updateListAttributeProduct, (state, { attributeProducts, attributeProduct }) => {
    return adapter.upsertMany(attributeProducts, state);
  }),
  on(AttributeProductActions.updateListAttributeProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(AttributeProductActions.searchAttributeProductSuccess, (state, { attributeProducts }) => {
    // console.log(categories);
    
    return adapter.upsertMany(attributeProducts, state);
  }),
  on(AttributeProductActions.searchAttributeProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(AttributeProductActions.deleteAttributeProduct, (state, { id }) => {
    //  console.log(id);
    return adapter.removeOne(id, state);
  }),
  on(AttributeProductActions.deleteAttributeProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  
  on(AttributeApiActions.loadAttributesSuccess, (state, { data }) => {
    return adapter.upsertMany(data, state);
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return attributeReducer(state, action);
}

export const getSelectedAttributeId = (state: State) => state.selectedAttributeId;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectAttributeIds = selectIds;

// select the dictionary of user entities
export const selectAttributeEntities = selectEntities;

// select the array of users
export const selectAllAttributes = selectAll;

// select the total user count
export const selectAttributeTotal = selectTotal;
