<mat-toolbar class="header-dialog">
  <label class="header-text">Chi tiết bộ thuộc tính</label>
  <mat-icon (click)="closeDialog()">close</mat-icon>
</mat-toolbar>
<div class="content-page">
  <div class="tab">
    <div class="title">Tên danh mục : </div>
    <div class="content" style="width: 70%;">{{this.data.name}}</div>
  </div>

  <div class="tab">
    <div class="title">Bộ thuộc tính : </div>
    <div class="content">
      <span class="attribute-set-name text-break" [title]="this.currentAttributeSet?.name">{{this.currentAttributeSet?.name}}</span>
    </div>
    <button mat-raised-button class="green btn btn-create" (click) = "updateAttributeSet()">Cập nhật bộ thuộc tính</button>
  </div>
  <div class="tab">
    <div class="title-info">
      <div class="label-info">Thông tin của bộ thuộc tính</div>
      <span class="view-detail" (click)="viewDetail = !viewDetail">
        <span *ngIf="!viewDetail">Xem chi tiêt</span>
        <span *ngIf="viewDetail">Ẩn chi tiêt</span>
        <mat-icon *ngIf="!viewDetail">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="viewDetail">keyboard_arrow_up</mat-icon>
      </span>
    </div>
  </div>

  <div class="tab" *ngIf="viewDetail">
    <div *ngFor="let item of this.currentAttributeSet?.groupAttribute; let i = index">
      <div  class="label-group" *ngIf="this.currentAttributeSet?.groupAttribute.length">
        <span>
          <mat-icon *ngIf="arrIndexExpand.indexOf(i) === -1" (click) = "expandGroup(i)">arrow_right</mat-icon>
          <mat-icon *ngIf="arrIndexExpand.indexOf(i) !== -1" (click) = "collapseGroup(i)">arrow_drop_down</mat-icon>
        </span>
        <div class="label-attribute-set">
          <span class="title-attribute-set" contenteditable="enableEdit">{{item.groupName}}</span>
        </div>
      </div>
      
      <div
        class="example-list left-chirldren"
        *ngIf="arrIndexExpand.indexOf(i) !== -1"
        >
          <div *ngFor="let it of item.attributeValues"  class="example-box">
            {{it.attributeLabel}}
        </div>
      </div>
      
    </div>
  </div>
  
</div>
<!-- <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
  <button mat-button type="button" (click)="closeDialog()">Hủy</button> -->
  <!-- <button mat-raised-button type="submit" class="btn-create primary">Cập nhật</button> -->
  <!-- <button mat-raised-button type="submit" class="btn-create" *ngIf="data.action === 'update'">Sửa</button> -->
<!-- </mat-toolbar> -->