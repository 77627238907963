import { AlertModule } from './alert/alert.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdcCardModule } from '@angular-mdc/web/card';
import { MDCDataTableModule } from '@angular-mdc/web/data-table';
import { MdcDrawerModule } from '@angular-mdc/web/drawer';
import {
  MdcDialogModule
} from '@angular-mdc/web';
import { MdcIconModule } from '@angular-mdc/web/icon';
import { MdcListModule } from '@angular-mdc/web/list';
import { MdcMenuModule } from '@angular-mdc/web/menu';
import { MdcTopAppBarModule } from '@angular-mdc/web/top-app-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProductModule } from './products/product.module';
import { CategoryModule } from './category/category.module';
import { ProductAttributeGroupModule } from './product-attribute-group';
import { WarningProductModule } from './warning-product/warning-product.module';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductEffects } from './products/effects/product.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GlnModule } from './gln/gln.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AttributeModule } from '@attribute/attribute.module';
// import { ROOT_REDUCERS, metaReducers } from './reducers';

import { CoreModule } from './core/core.module';
import { environment } from '../environments/environment';
import { AppEffects } from './app.effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EntityDataModule } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { NgxSummernoteModule } from 'node_modules/ngx-summernote';
import { SelectSearchModule } from './shared/components/select-search/select-search.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';

@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MdcCardModule,
    MDCDataTableModule,
    MdcDrawerModule,
    MdcIconModule,
    MdcDialogModule,
    MdcListModule,
    MdcMenuModule,
    MdcTopAppBarModule,
    CoreModule,
    MdcIconModule,
    MdcListModule,
    MdcMenuModule,
    SelectSearchModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      name: 'iCheck CMS',
      maxAge: 25, logOnly: environment.production
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot(),
    EntityDataModule.forRoot(entityConfig),
    SharedModule,
    WarningProductModule,
    ProductModule,
    CategoryModule,
    AttributeModule,
    AlertModule,
    GlnModule,
    AuthModule,
    ProductAttributeGroupModule,
    NgxSummernoteModule,
    AppRoutingModule,
    MatSelectInfiniteScrollModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
