import { createAction, props } from '@ngrx/store';

import { Category } from '../models';


export const loadCategories = createAction(
  '[Category List Page] Load Categories',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadCategoriesSuccess = createAction(
  '[Category List Page] Load Categories Success',
  props<{ total: number, rows: any }>()
);

export const loadCategoriesFailure = createAction(
  '[Category List Page] Load Categories Failure',
  props<{ error: any }>()
);


export const loadListCategoryByParent = createAction(
  '[Category List Page] Load List Category Parent',
  props<{ filter: any, page: number, pageSize: number, id: any }>()
);

export const loadListCategoryByParentSuccess = createAction(
  '[Category List Page] Load List Category Parent Success',
  props<{ total: number, ids: number[] }>()
);

export const loadListCategoryByParentFailure = createAction(
  '[Category List Page] Load List Category Parent Failure',
  props<{ error: any }>()
);

export const getTreeCategory = createAction(
  '[Category List Page] Get Tree Category',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const getTreeCategorySuccess = createAction(
  '[Category List Page] Get Tree Category Success',
  props<{ treeCategory: object }>()
);

export const getTreeCategoryFailure = createAction(
  '[Category List Page] Get Tree Category Failure',
  props<{ error: any }>()
);

export const searchCategory = createAction(
  '[Category List Page] Search Category',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const searchCategorySuccess = createAction(
  '[Category List Page] Search Category Success',
  props<{ rows: any[] }>()
);

export const searchCategoryFailure = createAction(
  '[Category List Page] Search Category Failure',
  props<{ error: any }>()
);
