import { MatSnackBar } from '@angular/material/snack-bar';
import { validateCheckDigit, checkFormatBarcode } from 'src/app/shared/validate/validate';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { GlnListPageActions } from '@gln/actions';
import { ProductActions, ProductListPageActions } from '@products/actions';
import { Store, select } from '@ngrx/store';
import { FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import * as fromProduct from '../../reducers';
import * as fromGln from '../../../gln/reducers';
import { CategoryListPageActions } from '@category/actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as fromCategory from '@category/reducers';
import { onlyUnique } from '@src/app/shared/common';
import { MatSelect } from '@angular/material/select';
import { ProductService } from '@products/services/product.services';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';

@Component({
  selector: 'ic-product-add-gln',
  templateUrl: './product-add-gln.component.html',
  styleUrls: ['./product-add-gln.component.scss']
})
export class ProductAddGlnComponent extends ModalScroll implements OnInit {

  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  enterprises$: Observable<any>;
  enterpriseList = [];
  currentUser: any;
  destroy$ = new Subject();
  pageGln = 1;
  filterGln = {};
  isLoading = false;


  @ViewChild(MatSelect) child: MatSelect;


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductAddGlnComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeGln: Store<fromGln.State>,
    private store: Store<fromProduct.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog
  ) {
    super();
    this.data = data;
    this.enterprises$ = storeGln.pipe(select(fromGln.selectGlnListPageResults));
  }
  //
  // ngAfterViewInit() {
  //   // console.log(this.child);
  //   this.child.openedChange.subscribe(opened => {
  //     // console.log(opened)
  //     if (!opened) {
  //       if (!this.enterpriseList.length) {
  //         this.filterGln = { };
  //         this.pageGln = 1;
  //         this.enterpriseList = [];
  //         this.getListEnterprise();
  //         this.formGroup.patchValue({inputSearch: ''})
  //       }
  //     }
  //   })
  // }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.productService.searchEnterprise({page: 1, pageSize: 100}).pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe((res: any) => {
    //   // console.log(res);
    //   this.enterpriseList = res.data.items;
    // })
    this.formGroup = this.formBuilder.group({
      // barcodes: '',
      enterpriseId: ['', Validators.required],
      barcodes: ['', Validators.required],
      DontcheckPrefix: [false],
      inputSearch: [''],
      allowUpdate: [false],
      allowUpdateProduct: [false],
    });



    if (this.data.selectedRows.length) {
      const barcodes = this.data.selectedRows.map(item => item.barcode).join('\n');
      this.formGroup.patchValue({
        barcodes
      });
      this.checkBarcode();
    }

    // this.enterprises$.subscribe(item => {
    //   this.enterpriseList = item;
    // })
    this.getListEnterprise();
  }

  getListEnterprise() {
    this.productService.searchEnterprise({page: this.pageGln, pageSize: 10, filter: this.filterGln}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      this.enterpriseList = [...this.enterpriseList, ...res.data.items];
    });
  }


  lazyLoadEnterprise() {
    this.pageGln += 1;
    this.getListEnterprise();
  }


  onFormSubmit() {
    const object = {
      enterpriseId: this.formGroup.value.enterpriseId,
      barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique),
      updateBusinessManager: this.formGroup.value.allowUpdate ? 1 : 0,
    };

    if (!this.formGroup.value.DontcheckPrefix) {
      let prefix;
      let controlsErr = false;
      this.enterpriseList.forEach(element => {
        if (element.id === this.formGroup.value.enterpriseId) { prefix = element.prefix; }
      });
      prefix = prefix.split(',');
      this.formGroup.value.barcodes.split(/\r?\n/).forEach((code, i) => {
        controlsErr =  prefix.some(z => code.startsWith(z));
      });

      if (!controlsErr ) {

        this._snackBar.open('Prefix không hợp lệ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        return;
      }
    }

    this.isLoading = true;
    this.productService.assignEnterprise(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionSubmit(object);
      } else {
        this.isLoading = false;
        this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close('accept');
      }
    }, err => {
      this.isLoading = false;
      this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
    });
  }

  confirmActionSubmit(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: this.formGroup.value.allowUpdateProduct ? 1 : 2
        };

        this.isLoading = true;
        this.productService.assignEnterprise(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this.isLoading = false;
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close();
        }, err => {
          this.isLoading = false;
          this.dialogRef.close('close');
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        this.isLoading = false;
      }
      console.log('The dialog was closed');
    });
  }

  loadListProduct() {
    this.store.dispatch(
      ProductListPageActions.loadProducts({
        filter: {},
        page: 1,
        pageSize: 10,
      })
    );
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }
  changeInputSearch(event) {

    if (!this.searchChangeObserver) {
      Observable.create(observer => {
        this.searchChangeObserver = observer;
      })
      .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe(it => {
          // console.log(it)
          this.filterGln = { query: it };
          this.pageGln = 1;
          this.enterpriseList = [];
          this.getListEnterprise();
          // this.filterGln = { query: it };
          // this.pageGln = 2;
          // // this.getListEnterprise(1, { query: it })
          // this.productService.searchEnterprise({page: 1, pageSize: 10, query: it}).subscribe((res: any) => {
          //   // console.log(res);
          //   this.listGln = res.data.items;
          //   if (this.glnSelected?.hasOwnProperty('id')) {
          //     this.listGln.push(this.glnSelected)
          //   }
          // })
        });
    }
    this.searchChangeObserver.next(event.target.value);
    // if (!this.searchChangeObserver) {
    //   Observable.create(observer => {
    //     console.log(observer);

    //     this.searchChangeObserver = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       console.log(it)
    //         // this.storeAlert.dispatch(
    //         //   AlertAction.loadAction({
    //         //     filter: {name: it, offset: 0, limit: 20},
    //         //   })
    //         // );

    //     });
    // }
    // this.searchChangeObserver.next(event.target.value);
  }

  clearInputSearch() {
    this.formGroup.patchValue({inputSearch: ''});

    this.productService.searchEnterprise({page: 1, pageSize: 100}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      this.enterpriseList = res.data.items;
    });
  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    });
    this.formGroup.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
