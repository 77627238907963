<ic-filter-bar [availableFilters]="availableFilters" (inputSearchChange) ="changeInputSearch($event)"></ic-filter-bar>
<!-- End Filter bar -->

<div style="margin: 24px auto; max-width: 100%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button mdc-button raised class="btn btn-create" (click)="create()">Thêm mới</button>
        <button mdc-button raised class="btn btn-hidden" (click)="hiddenEnteprise()" [disabled]="!this.selected.length">Ẩn </button>
        <button mdc-button raised class="btn btn-show" (click)="showEnteprise()" [disabled]="!this.selected.length">Hiện </button>
        <button mdc-button raised class="btn btn-remove" (click)="removeEnteprise()" [disabled]="!this.selected.length">Xóa </button>
        <button mdc-button raised class="btn btn-verify" (click)="verifyEnterprise()" [disabled]="!this.selected.length">Xác thực </button>
        <button mdc-button raised class="btn btn-cancel-verify" (click)="cancelVerifyEnterprise()" [disabled]="!this.selected.length">Hủy xác thực </button>

      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="image">Tên doanh nghiệp</th>
            <th mdcDataTableHeaderCell name="name">Thông tin chi tiết</th>
            <th mdcDataTableHeaderCell name="price">Vai trò</th>
            <th mdcDataTableHeaderCell name="owned_business">Nhãn hiển thị</th>
            <th mdcDataTableHeaderCell name="business_management">Giấy tờ chứng minh</th>
            <th mdcDataTableHeaderCell name="scan_sattus">Trạng thái xác thực</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Xác thực bởi</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Trạng thái ẩn/ hiện</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Ngày tạo</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Ngày cập nhật</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows;index as i" [selected]="selection.isSelected(row)" >
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id" >{{row.enterpriseId}}</td>
            <td mdcDataTableCell name="image" >
              <span class="text-break">{{row.enterpriseName}}</span>
            </td>
            <td mdcDataTableCell name="name" >
              <div class="text-break">MST: {{row.tax}}</div>
              <div *ngIf="indexClickMore.indexOf(i) !== -1">
                <span class="text-break-word">Địa chỉ: {{row?.address}} - <span *ngIf="row?.wardName">{{row?.wardName}} - </span><span *ngIf="row?.districtName">{{row?.districtName}} - </span><span *ngIf="row?.provinceName">{{row?.provinceName}} - </span>{{row?.nationName}}</span>
              </div>
              <div *ngIf="indexClickMore.indexOf(i) !== -1">Điện thoại: {{row.phone}}</div>
              <div *ngIf="indexClickMore.indexOf(i) !== -1">Email: {{row.email}}</div>
              <div *ngIf="indexClickMore.indexOf(i) !== -1">Website: {{row.website}}</div>
              <div >GLN: {{row.gln}}</div>
              <div *ngIf="indexClickMore.indexOf(i) !== -1">Prefix: {{row.prefix}}</div>
              <a style="color: #1890ff;" *ngIf="indexClickMore.indexOf(i) === -1" (click)="clickMoreIndex(i)">Xem thêm</a>
              <a style="color: #1890ff;" *ngIf="indexClickMore.indexOf(i) !== -1" (click)="closeMore(i)">Ẩn đi</a>
            </td>
            <td mdcDataTableCell name="price" >
              <span *ngIf="row.type === 2">Doanh nghiệp sản xuất</span>
              <span *ngIf="row.type === 4">Doanh nghiệp phân phối độc quyền</span>
              <span *ngIf="row.type === 3">Nhà phân phối</span>
            </td>
            <td mdcDataTableCell name="owned_business" >{{row.titleName}}</td>
            <td mdcDataTableCell name="business_management" >
              <span style="color:#0000FF" (click)="detailDocumentDistributor(row)">Chi tiết</span>
            </td>
            <td mdcDataTableCell name="scan_sattus" >
              <span *ngIf="row.status === 1" class="verified">Xác thực</span>
              <span *ngIf="row.status === 3" class="not-verified">Chưa xác thực</span>
            </td>
            <td mdcDataTableCell name="authentication_sattus" >{{row.verifyBy}}</td>
            <td mdcDataTableCell name="authentication_sattus">
              <span *ngIf="row.visible === 1">Hiện</span>
              <span *ngIf="row.visible === 3">Ẩn</span>
            </td>
            <td mdcDataTableCell name="authentication_sattus">{{row.createdAt}}</td>
            <td mdcDataTableCell name="authentication_sattus">{{row.updateAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)="updateEnteprise(row)">
                <mdc-icon class="btn-edit">edit</mdc-icon>
              </button>
              <button mdcIconButton (click) = "removeEnteprise(row)">
                <mdc-icon class="btn-delete">delete</mdc-icon>
              </button>
              <button mdcIconButton (click) = "cancelVerifyEnterprise(row)" *ngIf="row.status === 1">
                <mat-icon class="btn-close">close</mat-icon>
              </button>
              <button mdcIconButton (click) = "verifyEnterprise(row)" *ngIf="row.status === 3">
                <mat-icon class="btn-check">check_circle</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
    ></mat-paginator>
  </mdc-card>
</div>
