<div>
  <form [formGroup]="glnForm" (ngSubmit)="onFormSubmit()">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group">
                  <div class="label-form">Chọn GLN (*)</div>
                  <mat-form-field appearance="outline" class="form-field">
                      <mat-select formControlName="enterpriseId" placeholder="Chọn GLN">
                          <mat-form-field appearance="outline" class="form-field-search">
                              <mat-icon matPrefix>search</mat-icon>
                              <input matInput
                                  type="text"
                                  name="filter-options"
                                  id="filter-options"
                                  placeholder="Search"
                                  (keyup)= "changeInputSearch($event)"
                                  (keydown)="$event.stopPropagation()">
                            </mat-form-field>
                          <mat-option *ngFor="let gln of glns$ | async" [value]="gln.id">
                              {{gln.name}} (Prefix: {{gln.prefixCode}}, GLN: {{gln.glnCode}})
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
          </div>
      </div>  
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop">Cập nhật</button>
      </mat-toolbar>
  </form>
</div>
