import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, NgZone, ChangeDetectorRef, Optional, Inject, ElementRef } from '@angular/core';
import { MdcDrawer } from '@angular-mdc/web/drawer';
import { MdcIconRegistry } from '@angular-mdc/web/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { FocusTrapFactory, FocusMonitor } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'ic-notification-panel',
  exportAs: 'icNotificationPanel',
  host: {
    class: 'ic-notification-panel',
    dir: 'rtl'
  },
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationPanelComponent extends MdcDrawer {

  constructor(
    platform: Platform,
    ngZone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
    focusTrapFactory: FocusTrapFactory,
    focusMonitor: FocusMonitor,
    @Optional() @Inject(DOCUMENT) document: any,
    elementRef: ElementRef<HTMLElement>,
    iconRegistry: MdcIconRegistry,
    sanitizer: DomSanitizer) {
    super(platform, ngZone, changeDetectorRef, focusTrapFactory, focusMonitor, document, elementRef);
    iconRegistry
      .addSvgIconInNamespace(
        'core',
        'empty-notification',
        sanitizer.bypassSecurityTrustResourceUrl('/assets/empty-notification-icon.svg'));
    this.autoFocus = false;
    this.restoreFocus = false;
    this.drawer = "modal";
  }
}
