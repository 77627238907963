import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { DashFilterSettingData } from '../filter-bar.component';

@Component({
  selector: 'ic-text-setting',
  templateUrl: './text-setting.component.html',
  styleUrls: ['./text-setting.component.scss']
})
export class TextSettingComponent implements OnInit {

  operators: any = [];
  form: FormGroup;

  constructor(
    public dialogRef: MdcDialogRef<TextSettingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<any>,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: this.fb.control(null, [Validators.required]),
    });
    if (this.data.filter.settingComponentConfig) {
      let config = this.data.filter.settingComponentConfig;
    }
    if (this.data.value) {
      this.form.get('value').setValue(this.data.value.value);
      this.form.updateValueAndValidity();
    }
  }

  onApply() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

}
