import { Component, OnInit, HostBinding, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import {FormGroup, FormBuilder, FormControl} from '@angular/forms';
import { formatDate, clean } from '../../common';
import * as fromCategory from '@category/reducers';
import * as fromProduct from '@products/reducers';
import * as fromGln from '@gln/reducers';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { UserService } from '@src/app/user-list-cms/services/user.services';
import {  GlnActions } from '@gln/actions';
import { GlnService } from '@gln/services/gln.service';
import { CategoryService } from '@category/services/category.service';
import { ProductService } from '@products/services/product.services';
import * as _ from 'lodash';
import {cleanFilterInput, FilterFormFactory} from '@src/app/shared/components/filter-bar/form-for-filter/filter-form-factory';
import {LocationService} from '@src/app/services';
declare function searchTeko(params);
@Component({
  selector: 'ic-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarNewComponent implements OnInit {

  @ViewChild('matSelectProduct', { static: false }) set contentProduct(contentProduct: MatSelect) {
      if (contentProduct) { // initially setter gets called with undefined
          this.matSelectProduct = contentProduct;
          this.matSelectProduct.openedChange.subscribe(opened => {
            if (!opened && !this.listProduct.length) {

                this.pageProduct = 1;
                this.filterProduct =  {};
                this.listProduct = [];
                this.getListProduct();
                this.inputSearchProduct = '';
              }
          });

      }
  }

  @ViewChild('matSelectUser', { static: false }) set contentUser(contentUser: MatSelect) {
      if (contentUser) { // initially setter gets called with undefined
          this.matSelectUser = contentUser;
          this.matSelectUser.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened && !this.listIcheckId.length) {
                this.pageUser = 1;
                this.filterUser =  {};
                this.listIcheckId = [];
                this.getListUser();
                this.inputSearchUser = '';
              }
          });

      }
  }

  @ViewChild('matSelectEnterprise', { static: false }) set contentEnterprise(content: MatSelect) {
      if (content) { // initially setter gets called with undefined
          this.matSelectEnterprise = content;
          this.matSelectEnterprise.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened && !this.listGln.length) {

                this.pageGln = 1;
                this.filterGln = {};
                this.listGln = [];
                this.getListEnterprise();
                this.inputSearchEnterprise = '';
            }
          });

      }
  }

  @ViewChild('matSelectCategory', { static: false }) set contentCategory(contentCategory: MatSelect) {
      if (contentCategory) { // initially setter gets called with undefined
          this.getListCategory();
          this.matSelectCategory = contentCategory;
          this.matSelectCategory.openedChange.subscribe(opened => {
            if (!opened && !this.listCategory.length) {
              console.log(opened);

              this.pageCategory = 1;
              this.filterCategory = {};
              this.listCategory = [];
              this.getListCategory();
              this.inputSearch = '';
            }
          });

      }
  }

  @HostBinding('class')
  get classes(): string {
    return 'dash-filter';
  }

  constructor(private storeCategory: Store<fromCategory.State>,
              private fb: FormBuilder,
              private storeGln: Store<fromGln.State>,
              private storeProduct: Store<fromProduct.State>,
              private userService: UserService,
              private glnService: GlnService,
              private categoryService: CategoryService,
              private filterFormFactory: FilterFormFactory,
              private locationService: LocationService,
              private productService: ProductService) {
    this.filter$ = storeProduct.pipe(select(fromProduct.selectProductListPageFilter));
    this.filterGln$ = storeGln.pipe(select(fromGln.selectGlnListPageFilter));
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
    this.listNation$ = storeGln.pipe(select(fromGln.selectGlnListNation));
    this.products$ = storeProduct.pipe(select(fromProduct.selectProductListPageResults));
    this.glns$ = storeGln.pipe(select(fromGln.selectGlnListPageResults));
  }


  @Input() availableFilters;

  @Output()
  filterChange = new EventEmitter<any>();

  @Output()
  inputSearchChange = new EventEmitter<any>();

  @Output()
  resetFormFilter = new EventEmitter<any>();

  @ViewChild('divRange', { read: ElementRef, static: false }) divRange: ElementRef;

  @ViewChild('divMore', { read: ElementRef, static: false }) divMore: ElementRef;

  @ViewChild('btnMore', { read: ElementRef, static: false }) btnMore: ElementRef;

  @ViewChild('divRangeDate', { read: ElementRef, static: false }) divRangeDate: ElementRef;

  @ViewChild('divRangeNumber', { read: ElementRef, static: false }) divRangeNumber: ElementRef;

  @ViewChild('inputNumber', { read: ElementRef, static: false }) inputNumber: ElementRef;

  @ViewChild('inputDate', { read: ElementRef, static: false }) inputDate: ElementRef;

  @ViewChild('iconDate', { read: ElementRef, static: false }) iconDate: ElementRef;

  @ViewChild('iconNumber', { read: ElementRef, static: false }) iconNumber: ElementRef;

  @ViewChild('picker', { read: ElementRef, static: false }) picker: ElementRef;


  @ViewChild('select') select: MatSelect;


  private matSelectNation: MatSelect;

  private matSelectProduct: MatSelect;

  private matSelectUser: MatSelect;

  private matSelectEnterprise: MatSelect;

  private matSelectCategory: MatSelect;
  toppings = new FormControl();
  toppingList = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  isOpenSelectSearch = false;

  currentIndexNumber: any = -1;
  currentIndexPicker: any = -1;

  filterForm: FormGroup;
  categories$: Observable<any>;
  listNation$: Observable<any>;
  searchChangeObserver;
  inputSearch = '';
  inputSearchNation = '';
  listCategory = [];
  listNation: Observable<any>;
  filter$: Observable<any>;
  inputSearchUser = '';
  listIcheckId = [];
  products$: Observable<any>;
  glns$: Observable<any>;
  listProduct = [];
  listGln = [];
  inputSearchEnterprise = '';
  inputSearchProduct = '';
  filterGln$: Observable<any>;
  pageGln = 1;
  filterGln = {};
  pageCategory = 1;
  filterCategory = {};
  pageProduct = 1;
  filterProduct = {};
  pageUser = 1;
  filterUser = {};

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (this.divMore && !this.divMore.nativeElement.contains(event.target) && !this.btnMore?.nativeElement.contains(event.target)) {
      this.isOpenSelectSearch = false;
    }
  }

 ngOnInit() {

    if (this.availableFilters.some(z => z.key === 'nationId') ) {
      this.listNation = this.locationService.getLocation().pipe(map(item => {
        return item.data.items;
      }));
    }
    this.filterForm = this.filterFormFactory.createFormGroup(this.availableFilters);
    this.onValueChanges();
  }

  getListEnterprise() {
    this.glnService.getListEnterprise({
      page: this.pageGln,
      pageSize: 10,
      filter: this.filterGln
    }).subscribe((res: any) => {
      this.listGln = _.uniqBy([...this.listGln, ...res.data.items], 'id');
    });
  }

  lazyLoadEnterprise() {
    this.pageGln += 1;
    this.getListEnterprise();
  }

  getListCategory() {
    this.categoryService.searchCategory({
      page: this.pageCategory,
      pageSize: 10,
      filter: this.filterCategory
    }).pipe(
      map((res: any) => {
        return  res.data.items.map(item => {
          const nameDescent = item.ancestors.map(it => it.name);
          // console.log('name_descent', name_descent);
          if (nameDescent.length > 0) {
            item.displayName = nameDescent.join(' > ') + ' > ' + item.name;
          } else {
            item.displayName = item.name;
          }
          return item;
        });
      })
    )
    .subscribe((res: any) => {
      console.log(res);

      this.listCategory = _.uniqBy([...this.listCategory, ...res], 'id');
    });
  }

  lazyLoadCategory() {
    this.pageCategory += 1;
    this.getListCategory();
  }

  getListProduct() {
    this.productService.getListProduct({
      page: this.pageProduct,
      pageSize: 10,
      filter: this.filterProduct
    }).subscribe((res: any) => this.listProduct = _.uniqBy([...this.listProduct, ...res.data.items], 'id'));
  }

  lazyLoadProduct() {
    this.pageProduct += 1;
    this.getListProduct();
  }

  getListUser() {
    this.userService.getListIcheckId({
      page: this.pageUser,
      pageSize: 10,
      filter: this.filterUser
    }).subscribe((item: any) => {
        this.listIcheckId = _.uniqBy([...this.listIcheckId, ...item.data.items], 'id');
    });
  }

  changeSelectionBox(event) {
    // variable check to know the key is has_price and status show or not
    const check = this.availableFilters.some(z => z.key ===  'has_price' && z.show === true);
    const item = this.availableFilters.filter(i => i.key === 'price')[0];
    if (check && event  === 1) {
      item.show = true;
    } else {
      item.show = false;
      this.filterForm.patchValue({
        price_to: '',
        price_from: ''
      });
    }
  }

  patchValueForm(object) {
    this.availableFilters =  cleanFilterInput(this.availableFilters, object);
  }
  changeSelection(event) {
    if (event.value === 1) {
      // console.log(this.availableFilters);
      const index = this.availableFilters.findIndex(item => item.key === 'productId');

      this.availableFilters[index].show = false;
    } else if (event.value === 2) {
      const index = this.availableFilters.findIndex(item => item.key === 'enterpriseId');
      // console.log(index);
      this.availableFilters[index].show = false;
    } else if (event.value === 4) {
      const indexEnterprie = this.availableFilters.findIndex(item => item.key === 'enterpriseId');
      const indexProduct = this.availableFilters.findIndex(item => item.key === 'productId');
      this.availableFilters[indexEnterprie].show = false;
      this.availableFilters[indexProduct].show = false;
    }
  }

  onValueChanges(): void {
    this.filterForm.valueChanges.subscribe(val => {
      const value = clean(val);
      searchTeko(value)
      this.filterChange.emit(value);
    });
  }

   removeInputSearch(index , key) {
    this.availableFilters[index].show = false;
    this.availableFilters[index].displayName1 = '';
    this.availableFilters[index].displayName2 = '';
    switch (this.availableFilters[index].type) {
       case 'daterange':  this.filterForm.get(`${key}_from_date`).setValue(''); this.filterForm.get(`${key}_to_date`).setValue(''); break;
       case 'daterange2':  this.filterForm.get(`update_from_${key}`).setValue('');
                           this.filterForm.get(`update_to_${key}`).setValue(''); break;
       case 'number-range':  this.filterForm.get(`${key}_from`).setValue(''); this.filterForm.get(`${key}_to`).setValue(''); break;
      default:
        this.filterForm.get(key).setValue('');
     }
    this.filterChange.emit(this.filterForm.value);
  }

  onFormSubmit() {

  }

  changeCheckbox(index) {

    this.availableFilters[index].show = true;
    this.availableFilters[index].allowRemove = true;
    this.isOpenSelectSearch = false;
    if (this.availableFilters[index].type === 'number-range') {
    }

    if (this.availableFilters[index].type === 'daterange') {
    }
  }

  updateRangeDate(value, key: string , index) {
  const formatValue = value ? formatDate(value) : '';
  const toDate = key.includes('to_date');
  const fromDate = key.includes('from_date');

  if (toDate ) {
      this.availableFilters[index].displayName1 = `từ ngày ${formatValue}`;
    }
  if (fromDate) {
      this.availableFilters[index].displayName2 = ` tới ngày ${formatValue}`;
    }
  }

  updateRangeNumber(item, index) {
    console.log(item, index);
    if (this.filterForm.value[`${item.key}_from`] && this.filterForm.value[`${item.key}_from`]  && this.filterForm.value[`${item.key}_to`]  && this.filterForm.value[`${item.key}_to`]) {
      this.availableFilters[index].displayName = `${item.name} từ ${this.filterForm.value[`${item.key}_from`]} tới ${this.filterForm.value[`${item.key}_to`]}`;
    } else if (this.filterForm.value[`${item.key}_from`] !== '' && this.filterForm.value[`${item.key}_from`] !== undefined) {
      this.availableFilters[index].displayName = `${item.name} từ ${this.filterForm.value[`${item.key}_from`]}`;
    } else if (this.filterForm.value[`${item.key}_to`] !== '' && this.filterForm.value[`${item.key}_to`] !== undefined) {
      this.availableFilters[index].displayName = `${item.name} đến ${this.filterForm.value[`${item.key}_to`]}`;
    } else {
      this.availableFilters[index].displayName = `${item.name}`;
    }

    const object = {
      ...this.filterForm.value,
      create_to_date: this.filterForm.value.create_to_date ? formatDate(this.filterForm.value.create_to_date) : '',
      create_from_date: this.filterForm.value.create_from_date ? formatDate(this.filterForm.value.create_from_date) : '',
      update_to_date: this.filterForm.value.update_to_date ? formatDate(this.filterForm.value.update_to_date) : '',
      update_from_date: this.filterForm.value.update_from_date ? formatDate(this.filterForm.value.update_from_date) : '',
    };
    this.filterChange.emit(object);
  }
  changeInputSearch(event) {
    this.pageCategory = 1;
    this.filterCategory = { name : event.target.value };
    this.listCategory = [];
    this.getListCategory();
  }

  clearInputSearch() {
    this.inputSearch = '';
    this.pageCategory = 1;
    this.filterCategory = {};
    this.listCategory = [];
    this.getListCategory();

  }

  changeInputSearchUser(event) {

    this.pageUser = 1;
    this.filterUser =  { keyword: event.target.value };
    this.listIcheckId = [];
    this.getListUser();
  }

  clearInputUserSearch() {
    this.inputSearchUser = '';
    this.pageUser = 1;
    this.filterUser =  {};
    this.listIcheckId = [];
    this.getListUser();

  }


  changeInputSearchEnterprise(event) {
    this.pageGln = 1;
    this.filterGln = { name: event.target.value };
    this.listGln = [];
    this.getListEnterprise();

  }

  clearInputEnterpriseSearch() {
    this.inputSearchEnterprise = '';
    this.pageGln = 1;
    this.filterGln = {};
    this.listGln = [];
    this.getListEnterprise();
  }

  changeInputSearchProduct(event) {
    // console.log(event);
    this.pageProduct = 1;
    this.filterProduct =  { name_code: event.target.value };
    this.listProduct = [];
    this.getListProduct();
  }

  clearInputProductSearch() {
    this.inputSearchProduct = '';

    this.pageProduct = 1;
    this.filterProduct =  {};
    this.listProduct = [];
    this.getListProduct();
  }

  changeInputSearchNation() {
    this.listNation = this.locationService.getLocation().pipe(map(item => {
      return item.data.items;
    }));
  }

  clearInputNationSearch() {
    this.inputSearchNation = '';
    this.storeGln.dispatch(GlnActions.loadNation({request: {
      type: 0
    }}));
  }

  saveIndexNumber(i) {
    this.currentIndexNumber = i;
  }

  saveIndexPicker(i) {
    this.currentIndexPicker = i;
  }


  openDialogMore() {
    const listHidden = this.availableFilters.filter(item => !item.show);
    if (listHidden.length) {
      this.isOpenSelectSearch = !this.isOpenSelectSearch;
    }
  }

  resetForm() {
    // this.resetFormFilter.emit('true');
    this.filterForm.reset();
    this.filterChange.emit(this.filterForm.value);
    // this.availableFilters.map(item => {
    //   if ((item.displayName1 || item.displayName2) && item.key !== 'type') {
    //     item.displayName1 = '';
    //     item.displayName2 = '';
    //   }
    //   return item;
    // });
  }
}
