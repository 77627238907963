import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { convertObjectToQueryParams } from '../../shared/common';
import { environment } from 'src/environments/environment';
import { of, from } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization':  'bearer 54f0d524-e5a1-4680-a351-bfc371dd8b86',
    'Content-Type' : 'application/json'
  })
};

@Injectable({
  providedIn: "root"
})
export class WarningProductService {
  constructor(public http: HttpClient) {}
}
