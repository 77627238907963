
import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action,
  ActionReducerMap,
} from '@ngrx/store';

import * as fromRoot from '../../reducers';
import * as fromCategory from './category.reducer';
import * as fromCategoryList from './category-list.reducer';
import * as fromCategoryPage from './category-page.reducer';
import * as fromCategoryListPage from './category-list-page.reducer';

export const categoryFeatureKey = 'category';

export interface CategoriesState {
  [fromCategory.categoryFeatureKey]: fromCategory.State;
  [fromCategoryList.categoryListFeatureKey]: fromCategoryList.State;
  [fromCategoryPage.categoryPageFeatureKey]: fromCategoryPage.State;
  [fromCategoryListPage.categoryListPageFeatureKey]: fromCategoryListPage.State;
}

export interface State extends fromRoot.State {
  [categoryFeatureKey]: CategoriesState;
}

export function reducers(state: CategoriesState | undefined, action: Action) {
  return combineReducers({
    [fromCategory.categoryFeatureKey]: fromCategory.reducer,
    [fromCategoryList.categoryListFeatureKey]: fromCategoryList.reducer,
    [fromCategoryPage.categoryPageFeatureKey]: fromCategoryPage.reducer,
    [fromCategoryListPage.categoryListPageFeatureKey]: fromCategoryListPage.reducer,
  })(state, action);
}

export const selectCategoryState = createFeatureSelector<State, CategoriesState>(
  categoryFeatureKey
);


export const selectCategoryEntitiesState = createSelector(
  selectCategoryState,
  state => state.category
);



export const {
  selectIds: selectCategoryIds,
  selectEntities: selectCategoryEntities,
  selectAll: selectAllCategories,
  selectTotal: selectTotalCategories,
} = fromCategory.adapter.getSelectors(selectCategoryEntitiesState);


export const selectCategoryListState = createSelector(
  selectCategoryState,
  state => state.categoryList
);

export const selectCategoryListIds = createSelector(
  selectCategoryListState,
  fromCategoryList.getIds
);

export const selectTotalCategory= createSelector(
  selectCategoryListState,
  fromCategoryList.getTotalCategory
);

export const selectLoadingDataCategory= createSelector(
  selectCategoryListState,
  fromCategoryList.getLoadingData
);

export const selectObjectFilterCategory= createSelector(
  selectCategoryListState,
  fromCategoryList.getObjectFilter
);

export const selectCategoryListResults = createSelector(
  selectCategoryEntities,
  selectCategoryListIds,
  (categories, ids) => {
    return ids
      .map(id => categories[id])
      .filter((category): category is any => category != null);
  }
);

// Category page
export const selectCategoryPageState = createSelector(
  selectCategoryState,
  state => state.categoryPage
);

export const selectCategoryPageFilter = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getFilter
);

export const selectPage = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getPage
);

export const selectPageSize = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getPageSize
);

export const selectCategoryPageLoading = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getLoading
);

export const selectCategoryPageTotal = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getTotal
);

export const selectCategoryPageIds = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getIds
);

export const selectCategoryPageDisplayedColumns = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getDisplayedColumns
);

export const selectListAttributeByCategory = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getAttributeList
);

export const selectTreeCategory = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getTreeCategory
);

export const selectDetailCategory = createSelector(
  selectCategoryPageState,
  fromCategoryPage.getDetailCategory
);

export const selectCategoryPageResults = createSelector(
  selectCategoryEntities,
  selectCategoryPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// Category list page
export const selectCategoryListPageState = createSelector(
  selectCategoryState,
  state => state.categoryListPage
);

export const selectCategoryListPageFilter = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getFilter
);

export const selectPageListPage = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getPage
);

export const selectPageSizeListPage = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getPageSize
);

export const selectCategoryListPageLoading = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getLoading
);

export const selectCategoryListPageTotal = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getTotal
);

export const selectCategoryListPageIds = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getIds
);

export const selectCategoryListPageDisplayedColumns = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getDisplayedColumns
);

export const selectCategoryParentId = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getCategoryParentId
);

export const selectListCategory = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getListCategory
);

export const selectListSearchCategory = createSelector(
  selectCategoryListPageState,
  fromCategoryListPage.getListSearchCategory
);

export const selectCategoryListPageResults = createSelector(
  selectCategoryEntities,
  selectCategoryListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);
