import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as fromGln from '../../reducers';
import {
  GlnActions
} from '../../actions';

@Component({
  selector: 'ic-gln-import-modal',
  templateUrl: './gln-import-modal.component.html',
  styleUrls: ['./gln-import-modal.component.scss']
})
export class GlnImportModalComponent implements OnInit {


  importForm : any;
  data: any;

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<GlnImportModalComponent>,
    private store: Store<fromGln.State>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.data = data;
    }

  ngOnInit(): void {
    this.importForm = this.formBuilder.group({
      file_import: [this.data?.element?.file_import || '', Validators.required],
      allow_create: [this.data?.element?.allow_create || false, Validators.required],
      allow_update: [ this.data?.element?.allow_update || false, Validators.required],
    })
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

  onFormSubmit() {
    // console.log(this.importForm.value);
    this.dialogRef.close();
    // if (this.data.action === 'create') {
    //   this.store.dispatch(CategoryActions.addCategory({categoryObject: this.categoryForm.value}));
    //   this.dialogRef.close();
    // } else {
    //   this.categoryForm.value.id = this.data.element.id;
    //   // console.log(this.categoryForm.value);
    //   this.store.dispatch(CategoryActions.updateCategory({categoryObject: this.categoryForm.value}));
    //   this.dialogRef.close();
    // }
    
  }

}
