export const VERIFY_PRODUCT = {
  BY_ADMIN : 1,
  BY_BUSINESS : 2,
  NOT_VERIFY: 3,
  BY_ADMIN_AND_BUSINESS: 4
};

export const SCAN_STATE = {
  NO_DATA: 0,
  DO_SCAN: 1,
  HIDDEN_BY_COOP: 2,
  DO_NOT_SCAN: 3
}

export const LINK_GTIN_GLN = {
  NOT_ASSIGN: 0,
  ASSIGN: 1,
  PENDING: 2,
  DENIAL: 3,
}

export const HAS_ATTRIBUTE_PRODUCT = {
  HAS_PRODUCT : 1 ,
  NOT_HAS_PRODUCT: 0
}

export const HAS_OTHER_ATTRIBUTE_PRODUCT = {
  HAS_PRODUCT : 1 ,
  NOT_HAS_PRODUCT: 0
}

export const ALLOW_PEOPLE_CONTRIBUTE  = {
  YES : 1,
  NO : 0
}
