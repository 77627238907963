import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromGln from '../../reducers';
import { noWhitespaceValidator } from 'src/app/shared/validate/validate';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { GlnActions, GlnPageActions, GlnListPageActions } from '@gln/actions';
import { Router, ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { GlnService } from '@gln/services/gln.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { buildImageUrl, buildImageUrls } from '@src/app/shared/common';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-gln-modal-page',
  templateUrl: './gln-modal-page.component.html',
  styleUrls: ['./gln-modal-page.component.scss']
})
export class GlnModalPageComponent implements OnInit {

  glnForm: any;
  data: any;
  nations$: Observable<any>;
  cities$: Observable<any>;
  districts$: Observable<any>;
  avatar = [];
  backgroundImage = [];
  isUpdate = false;
  currentGln$: Observable<any>;
  currentGln: any;
  wards$: Observable<any>;
  listWard: any;
  listNation: any;
  listCity: any;
  listDistrict: any;
  inputNation = '';
  inputCity = '';
  inputDistrict = '';
  inputWard = '';
  searchChangeObserverNation;
  searchChangeObserverCity;
  searchChangeObserverDistrict;
  searchChangeObserverWard;
  page$: Observable<any>;
  pageSize$: Observable<any>;
  page: number;
  pageSize: number;
  destroy$ = new Subject();
  isLoading = false;

  private matSelectNation: MatSelect;

  @ViewChild('matSelectNation', { static: false }) set contentNation(contentNation: MatSelect) {
      if (contentNation) { // initially setter gets called with undefined
          this.matSelectNation = contentNation;
          this.matSelectNation.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listNation.length) {
                this.loadNation();
                this.inputNation = '';
              }
            }
          });

      }
  }

  private matSelectCity: MatSelect;

  @ViewChild('matSelectCity', { static: false }) set contentCity(contentCity: MatSelect) {
      if (contentCity) { // initially setter gets called with undefined
          this.matSelectCity = contentCity;
          this.matSelectCity.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listCity.length) {
                this.loadCity(this.glnForm.value.nationalCode);
                this.inputCity = '';
              }
            }
          });

      }
  }

  private matSelectDistrict: MatSelect;

  @ViewChild('matSelectDistrict', { static: false }) set contentDistrict(contentDistrict: MatSelect) {
      if (contentDistrict) { // initially setter gets called with undefined
          this.matSelectDistrict = contentDistrict;
          this.matSelectDistrict.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listDistrict.length) {
                this.loadDistrict(this.glnForm.value.provinceCode);
                this.inputDistrict = '';
              }
            }
          });

      }
  }

  private matSelectWard: MatSelect;

  @ViewChild('matSelectWard', { static: false }) set contentWard(contentWard: MatSelect) {
      if (contentWard) { // initially setter gets called with undefined
          this.matSelectWard = contentWard;
          this.matSelectWard.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened) {

              if (!this.listWard.length) {
                this.loadWard(this.glnForm.value.districtId);
                this.inputWard = '';
              }
            }
          });

      }
  }

  constructor(private formBuilder: FormBuilder,
              private store: Store<fromGln.State>,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private glnService: GlnService,
              private _snackBar: MatSnackBar,
              private dialog2: MdcDialog,
    ) {
      this.nations$ = store.pipe(select(fromGln.selectGlnListNation));
      this.cities$ = store.pipe(select(fromGln.selectGlnListCity));
      this.districts$ = store.pipe(select(fromGln.selectGlnListDistrict));
      this.currentGln$ = store.pipe(select(fromGln.selectCurrentGln));
      this.wards$ = store.pipe(select(fromGln.selectGlnListWard));
      this.page$ = store.pipe(select(fromGln.selectPage));
      this.pageSize$ = store.pipe(select(fromGln.selectPageSize));
    }

  ngOnInit(): void {
    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => this.page = item);
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => this.pageSize = item);
    this.loadNation();
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.store.dispatch(GlnPageActions.getDetailGln({ id }));
      this.isUpdate = true;
    }

    this.currentGln$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.currentGln = item;
      this.glnForm = this.formBuilder.group({
        name: [this.currentGln?.name || '', [Validators.required, noWhitespaceValidator]],
        prefixCode: [this.currentGln?.prefixCode || ''],
        glnCode: [ this.currentGln?.glnCode || ''],
        glnType: [ this.currentGln?.glnType || ''],
        taxCode: [ this.currentGln?.taxCode || ''],
        nationalCode: [ this.currentGln?.nationalCode || '', Validators.required],
        address: [this.currentGln?.address || '', [Validators.required, noWhitespaceValidator]],
        provinceCode: [ this.currentGln?.provinceCode || '', Validators.required],
        districtId: [ this.currentGln?.districtId || '', Validators.required],
        wardId: [ this.currentGln?.wardId || '', Validators.required],
        phone: [this.currentGln?.phone || '', Validators.pattern('[0-9]*')],
        email: [ this.currentGln?.email || '', Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')],
        website: [this.currentGln?.website || ''],
        logo: [''],
        backgroundImage: [''],
      });
      if (this.currentGln.nationalCode && this.currentGln.nationalCode !== 'VN') {
        const districtControl = this.glnForm.get('districtId');
        const cityControl = this.glnForm.get('provinceCode');
        const wardControl = this.glnForm.get('wardId');
        districtControl.setValidators(null);
        cityControl.setValidators(null);
        wardControl.setValidators(null);
      }
      console.log(this.currentGln);

      if (this.currentGln.logo) {
        const avatar  = this.currentGln.logo;
        const avatarObject = {
          type: '',
          url: buildImageUrls(avatar).original,
        };
        // console.log(avatarObject);

        this.glnForm.controls.logo.setValue(avatar);
        this.glnForm.updateValueAndValidity();
        this.avatar = [avatarObject];
      } else {
        this.glnForm.controls.logo.setValue('');
        this.avatar = [];
        this.glnForm.updateValueAndValidity();
      }
      if (this.currentGln.backgroundImage) {
        const backgroundImage  = this.currentGln.backgroundImage;
        const backgroundObject = {
          type: '',
          url: buildImageUrls(backgroundImage).original,
        };
        this.glnForm.controls.backgroundImage.setValue(backgroundImage);
        this.backgroundImage = [backgroundObject];
        this.glnForm.updateValueAndValidity();
      } else {
        this.glnForm.controls.backgroundImage.setValue('');
        this.backgroundImage = [];
        this.glnForm.updateValueAndValidity();
      }
      if (this.glnForm.value.nationalCode) {
            this.loadCity(this.glnForm.value.nationalCode);
            this.loadDistrict(null);
          }
      if (this.glnForm.value.provinceCode) {
            this.loadDistrict(this.glnForm.value.provinceCode);
          }
      if (this.glnForm.value.districtId) {
            this.loadWard(this.glnForm.value.districtId);
          }

    });

    this.wards$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listWard = item;
    });

    this.nations$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listNation = item;
    });

    this.cities$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listCity = item;
    });

    this.districts$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.listDistrict = item;
    });

    this.checkNation();

  }

  checkNation() {
    this.glnForm.get('nationalCode').valueChanges.subscribe(selectedNation => {
      // console.log(selectedNation);
      const districtControl = this.glnForm.get('districtId');
      const cityControl = this.glnForm.get('provinceCode');
      const wardControl = this.glnForm.get('wardId');
      if (selectedNation !== 'VN') {
        console.log('1312323');
        districtControl.setValidators(null);
        cityControl.setValidators(null);
        wardControl.setValidators(null);
        districtControl.disable();
        cityControl.disable();
        wardControl.disable();

        return;
      }
      districtControl.setValidators(Validators.required);
      cityControl.setValidators(Validators.required);
      wardControl.setValidators(Validators.required);
      districtControl.enable();
      cityControl.enable();
      wardControl.enable();

      // console.log(this.glnForm);

    });
  }

  changeNation(event) {
    // console.log('nation', event.value);
    this.loadCity(event.value);
    this.loadDistrict(null);
    this.listWard = [];
    const districtControl = this.glnForm.get('districtId');
    const cityControl = this.glnForm.get('provinceCode');
    const wardControl = this.glnForm.get('wardId');
    if (event.value !== 'VN') {
      console.log('1312323');
      districtControl.setValidators(null);
      cityControl.setValidators(null);
      wardControl.setValidators(null);
      districtControl.disable();
      cityControl.disable();
      wardControl.disable();
      this.glnForm.controls.provinceCode.setValue(null);
      this.glnForm.controls.districtId.setValue(null);
      this.glnForm.controls.wardId.setValue(null);
      return;
    }
    districtControl.setValidators(Validators.required);
    cityControl.setValidators(Validators.required);
    wardControl.setValidators(Validators.required);
    districtControl.enable();
    cityControl.enable();
    wardControl.enable();
  }

  changeCity(event) {
    // console.log('city',event.value);
    this.loadDistrict(event.value);
    this.glnForm.patchValue({
      wardId: '',
      districtId: ''
    });
    this.listWard = [];
  }

  loadNation(search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadNation({request: {
        type: 0,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadNation({request: {
        type: 0,
      }}));
    }

  }

  loadCity(parent_code, search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadCity({request: {
        type: 1,
        parent_code,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadCity({request: {
        type: 1,
        parent_code
      }}));
    }

  }

  loadDistrict(parent_code, search?) {
    if (search) {
      this.store.dispatch(GlnActions.loadDistrict({request: {
        type: 2,
        parent_code,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadDistrict({request: {
        type: 2,
        parent_code,
      }}));
    }

  }

  changeDistrict(event) {
    this.glnForm.patchValue({
      wardId: '',
    });
    this.loadWard(event.value);
  }

  loadWard(parent_id, search?) {
    // console.log(parent_code);
    if (search) {
      this.store.dispatch(GlnActions.loadWard({request: {
        type: 3,
        parent_id,
        keyword: search
      }}));
    } else {
      this.store.dispatch(GlnActions.loadWard({request: {
        type: 3,
        parent_id,
      }}));
    }
    // if (parent_code) {

    // } else {
    //   this.store.dispatch(GlnActions.loadWard({request: {
    //     type: 3,
    //     parent_code: undefined,
    //   }}));
    // }

  }

  changeInputSearchNation(event) {
    this.loadNation(event.target.value);
    // if (!this.searchChangeObserverNation) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserverNation = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)

    //         // this.select.open();
    //     });
    // }
    // this.searchChangeObserverNation.next(event.target.value);
  }

  clearInputNationSearch() {
    this.inputNation = '';
    this.loadNation();
  }

  changeInputSearchCity(event) {
    this.loadCity(this.glnForm.value.nationalCode, event.target.value);
    // if (!this.searchChangeObserverCity) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserverCity = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)

    //         // this.select.open();
    //     });
    // }
    // this.searchChangeObserverCity.next(event.target.value);
  }

  clearInputCitySearch() {
    this.inputCity = '';
    this.loadCity(this.glnForm.value.nationalCode);
  }

  changeInputSearchDistrict(event) {
    this.loadDistrict(this.glnForm.value.provinceCode, event.target.value);

  }

  clearInputDistrictSearch() {
    this.inputDistrict = '';
    this.loadDistrict(this.glnForm.value.provinceCode);
  }

  changeInputSearchWard(event) {
    this.loadWard(this.glnForm.value.districtId, event.target.value);

  }

  clearInputWardSearch() {
    this.loadWard(this.glnForm.value.districtId);
    this.inputWard = '';
  }

  closeDialogClick() {
    // this.dialogRef.close();
  }

  onFormSubmit() {
    // console.log(this.glnForm.value)\
    this.isLoading = true;
    if (!this.isUpdate) {
      const object = {
        ...this.glnForm.value,
        name: this.glnForm.value.name.trim(),
        prefixCode: this.glnForm.value.prefixCode.trim(),
        address: this.glnForm.value.address.trim(),
        phone: this.glnForm.value.phone.trim(),
        email: this.glnForm.value.email.trim(),
        taxCode: this.glnForm.value.taxCode.trim(),
        website: this.glnForm.value.website.trim(),
        glnCode: this.glnForm.value.glnCode ? this.glnForm.value.glnCode.trim() : ''
      };
      const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_CREATE.SCREEN_NAME,
        TekoValueDto.ENTERPRISE_CREATE.CONTENT_NAME, TekoValueDto.ENTERPRISE_CREATE.TARGET,
        {
          prefix: object.prefixCode,
          glnCode: object.glnCode,
          glnType: object.glnType,
          country: object.nationalCode,
          city: object.provinceCode
        }
        );
      clickTeko(click);
      this.glnService.createEnterprise(object).pipe(
            takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Thêm mới GLN thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          const alert = new AlertTekoDto(ALERT.SUCCESS,  ALERT.SUCCESS);
          alertEvent(alert);
          setTimeout(() => {

            this.store.dispatch(
                GlnListPageActions.loadGlns({
                  page: 1,
                  pageSize: 10,
                  filter: {}
                })
            );
            this.currentGln = {};
            this.backToListProduct();
          }, 500);
        }, err => {
        const alert = new AlertTekoDto(ALERT.FAILED,  ALERT.FAILED);
        alertEvent(alert);
        this.isLoading = false;
        this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });


    } else {
      const object = {
        ...this.glnForm.value,
        id: this.currentGln.id,
        name: this.glnForm.value.name.trim(),
        glnCode: this.glnForm.value.glnCode.trim(),
        prefixCode: this.glnForm.value.prefixCode.trim(),
        address: this.glnForm.value.address.trim(),
        phone: this.glnForm.value.phone.trim(),
        email: this.glnForm.value.email.trim(),
        taxCode: this.glnForm.value.taxCode.trim(),
        website: this.glnForm.value.website.trim(),
      };
      this.isLoading = true;
      // console.log(this.glnForm.value);
      this.glnService.updateEnterprise(object).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmUpdateGln(object);
        } else {
          this._snackBar.open('Chỉnh sửa GLN thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });

          setTimeout(() => {
            this.isLoading = false;
            this.store.dispatch(
              GlnListPageActions.loadGlns({
                page: 1,
                pageSize: 10,
                filter: {}
              })
            );
            this.currentGln = {};
            this.backToListProduct();
          }, 500);
        }
      }, err => {
        this.isLoading = false;
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
      // this.dialogRef.close('accept');
    }

  }

  confirmUpdateGln(body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.glnService.updateEnterprise(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Chỉnh sửa GLN thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });

          setTimeout(() => {
            this.isLoading = false;
            this.store.dispatch(
              GlnListPageActions.loadGlns({
                page: 1,
                pageSize: 10,
                filter: {}
              })
            );
            this.currentGln = {};
            this.backToListProduct();
          }, 500);
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        this.isLoading = false;
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  openUploadDialog(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
      fileType : 'jpg,jpeg,png,gif,bmp'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      if (result.type === 'uploaded') {
        if (event === 'avatar') {
          const data = result.data;
          data.map(res => {
          this.avatar = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);

          this.avatar.push(value);
          this.glnForm.controls.logo.setValue(res.url);
        });
        } else {
          const data = result.data;
          data.map(res => {
          this.backgroundImage = [];
          const value = {
            name: res.name,
            type: '',
            url: res.url,
          };
          // console.log(value);

          this.backgroundImage.push(value);
          this.glnForm.controls.backgroundImage.setValue(res.url);
        });
      }
    }
  });
  }

  onRemoveAvatar(field: string, index: number) {
    this.glnForm.controls.logo.setValue('');
    this.avatar = [];
  }

  onRemoveBackground(field: string, index: number) {
    this.glnForm.controls.backgroundImage.setValue('');
    this.backgroundImage = [];
  }

  backToListProduct() {

    this.router.navigate(['/gln']);

  }

  clearGlnType() {
    this.glnForm.patchValue({
      glnType: ''
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
