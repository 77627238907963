<div>
  <form [formGroup]="importForm" (ngSubmit)="onSubmitForm()">

  <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
  </mat-toolbar>
  <div class="content-modal">
    <div class="form-group">
      <div class="label-form">Import file excel (*)</div>
      <!-- <ic-file-list style="display: inline-flex;"  [files]="file" (addFile)="openUploadDialog('logo')"
      (removeFile)="onRemoveFile('logo', $event)"></ic-file-list> -->

      <div class="div-import">
        <div class="btn-upload">
          <label class="typeLable">
            <mat-icon style="margin-right: 0.5rem;">cloud_upload</mat-icon>
            <span>Click to upload</span>
            <input type="file" #inputUpload (input)="importFile($event.target.files[0])">
          </label>
        </div>
        <div *ngIf="importName" class="file-import">
          <!-- <mat-icon class="icon-link">link</mat-icon> -->
          <!-- <mat-icon class="icon-link"> attach_file</mat-icon> -->
          <span class="file-label" [title]="importName">{{importName}}</span>
          <mat-icon class="icon-delete" (click) = "deleteFileImport()">clear</mat-icon>
          <!-- <mat-icon class="icon-delete" (click) = "deleteFileImport()">delete_outline</mat-icon> -->
        </div>
      </div>
      <div style="margin-bottom: 1rem;">
        <span>Nếu bạn chưa có file mẫu ? <a class="link-download" (click)="downloadTemplate()">Tải file mẫu</a></span>
        <!-- <button mat-raised-button type="button" class="btn-download" (click)="downloadTemplate()"><mat-icon>cloud_download</mat-icon>Tải file mẫu</button> -->
      </div>
      
      <div><mat-checkbox formControlName="replace">Nếu doanh nghiệp đã tồn tại thì ghi đè toàn bộ thông tin cũ</mat-checkbox></div>
      <div><mat-checkbox formControlName="replace_business">Nếu doanh nghiệp đã được quản lý trên hệ thống business thì ghi đè toàn bộ thông tin cũ</mat-checkbox></div>
    </div>
  </div>  
  <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
  <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
  <button mat-raised-button type="submit" [disabled]="!importForm.valid" class="btn-stop"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
  </mat-spinner></mat-icon>Thực hiện</button>
  </mat-toolbar>
  </form>
</div>