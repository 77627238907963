import { Action, createReducer, on } from '@ngrx/store';

import { ProductAttributePageActions } from '../actions';

export const productAttributePageFeatureKey = 'productAttributePage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: number;
  currentAttributeSet: object;
  listAttributeIgnore: any,
  totalListAttributeIgnore: number,
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'name', 'created_at','updated_at', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: null,
  currentAttributeSet: {},
  listAttributeIgnore: [],
  totalListAttributeIgnore: 0,
};

const productAttributePageReducer = createReducer(
  initialState,
  on(
    ProductAttributePageActions.loadProductAttributes,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    ProductAttributePageActions.loadProductAttributesSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false,
        currentAttributeSet: {},
      }
    }
  ),
  on(
    ProductAttributePageActions.loadProductAttributesFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    ProductAttributePageActions.loadListProductAttributeByParent,
    (state, { filter, limit, offset, parent_id }) => {
      return {
        ...state,
        filter,
        limit,
        offset,
        parent_id,
        loading: true
      }
    }
  ),
  on(
    ProductAttributePageActions.loadListProductAttributeByParentSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    ProductAttributePageActions.loadListProductAttributeByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    ProductAttributePageActions.deleteProductAttributeSuccess,
    (state, { id }) => {
      return {
        ...state,
        ids: state.ids.filter(it => it !== id),
      }
    }
  ),
  on(
    ProductAttributePageActions.deleteProductAttributeFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    ProductAttributePageActions.getDetailAttributeSetSuccess,
    (state, { productAttribute }) => {
      // console.log('11111111', productAttribute);
      
      return {
        ...state,
        currentAttributeSet: productAttribute,
      }
    }
  ),
  on(
    ProductAttributePageActions.getDetailAttributeSetFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
  on(
    ProductAttributePageActions.getListAttributeIgnoreSetSuccess,
    (state, { rows, total }) => {
      return {
        ...state,
        listAttributeIgnore: rows,
        totalListAttributeIgnore: total,
      }
    }
  ),
  on(
    ProductAttributePageActions.getListAttributeIgnoreSetFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productAttributePageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getCurrentAttributeSet = (state: State) => state.currentAttributeSet;

export const getTotalListAttributeIgnore = (state: State) => state.totalListAttributeIgnore;
