
<!-- <input matInput class="mat-select-search-input mat-select-search-hidden"/> -->

<div class="mat-select-search-inner"
     >
      <mat-icon matPrefix style="margin-left: 15px;">search</mat-icon>
      <input matInput
            class="mat-select-search-input"
            #searchSelectInput
            (keyup)= "onInputChange($event.target.value)"
            (keydown)="$event.stopPropagation()"
            (blur)="onBlur($event.target.value)"
            [placeholder]="placeholderLabel"
      />
          <button mat-button *ngIf="value"
          mat-icon-button
          aria-label="Clear"
          (click)="_reset(true)"
          class="mat-select-search-clear">
        <mat-icon>close</mat-icon>
        </button>
</div>

<div *ngIf="noEntriesFoundLabel && value && _options?.length === 0"
     class="mat-select-search-no-entries-found">
  {{noEntriesFoundLabel}}
</div>
