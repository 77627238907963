
import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action,
  ActionReducerMap,
} from '@ngrx/store';

import * as fromRoot from '../../reducers';
import * as fromProductAttribute from './product-attribute.reducer';
import * as fromProductAttributeList from './product-attribute-list.reducer';
import * as fromProductAttributePage from './product-attribute-page.reducer';
import * as fromProductAttributeListPage from './product-attribute-list-page.reducer';

export const productAttributeFeatureKey = 'productAttribute';

export interface ProductAttributesState {
  [fromProductAttribute.productAttributeFeatureKey]: fromProductAttribute.State;
  [fromProductAttributeList.productAttributeListFeatureKey]: fromProductAttributeList.State;
  [fromProductAttributePage.productAttributePageFeatureKey]: fromProductAttributePage.State;
  [fromProductAttributeListPage.productAttributeListPageFeatureKey]: fromProductAttributePage.State;
}

export interface State extends fromRoot.State {
  [productAttributeFeatureKey]: ProductAttributesState;
}

export function reducers(state: ProductAttributesState | undefined, action: Action) {
  return combineReducers({
    [fromProductAttribute.productAttributeFeatureKey]: fromProductAttribute.reducer,
    [fromProductAttributeList.productAttributeListFeatureKey]: fromProductAttributeList.reducer,
    [fromProductAttributePage.productAttributePageFeatureKey]: fromProductAttributePage.reducer,
    [fromProductAttributeListPage.productAttributeListPageFeatureKey]: fromProductAttributeListPage.reducer,
  })(state, action);
}

export const selectProductAttributeState = createFeatureSelector<State, ProductAttributesState>(
  productAttributeFeatureKey
);


export const selectProductAttributeEntitiesState = createSelector(
  selectProductAttributeState,
  state => state.productAttribute
);



export const {
  selectIds: selectProductAttributeIds,
  selectEntities: selectProductAttributeEntities,
  selectAll: selectAllProductAttributes,
  selectTotal: selectTotalProductAttributes,
} = fromProductAttribute.adapter.getSelectors(selectProductAttributeEntitiesState);


export const selectProductAttributeListState = createSelector(
  selectProductAttributeState,
  state => state.productAttributeList
);

export const selectProductAttributeListIds = createSelector(
  selectProductAttributeListState,
  fromProductAttributeList.getIds
);

export const selectTotalProductAttribute= createSelector(
  selectProductAttributeListState,
  fromProductAttributeList.getTotalProductAttribute
);

export const selectLoadingDataProductAttribute= createSelector(
  selectProductAttributeListState,
  fromProductAttributeList.getLoadingData
);

export const selectObjectFilterProductAttribute= createSelector(
  selectProductAttributeListState,
  fromProductAttributeList.getObjectFilter
);

export const selectProductAttributeListResults = createSelector(
  selectProductAttributeEntities,
  selectProductAttributeListIds,
  (productAttributes, ids) => {
    return ids
      .map(id => productAttributes[id])
      .filter((productAttribute): productAttribute is any => productAttribute != null);
  }
);

// ProductAttribute page
export const selectProductAttributePageState = createSelector(
  selectProductAttributeState,
  state => state.productAttributePage
);

export const selectProductAttributePageFilter = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getFilter
);

export const selectPage = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getPage
);

export const selectPageSize = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getPageSize
);

export const selectProductAttributePageLoading = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getLoading
);

export const selectProductAttributePageTotal = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getTotal
);

export const selectProductAttributePageIds = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getIds
);

export const selectProductAttributePageDisplayedColumns = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getDisplayedColumns
);

export const selectCurrentAttributeSetColumns = createSelector(
  selectProductAttributePageState,
  fromProductAttributePage.getCurrentAttributeSet
);

// export const selectTotalProductAttribute = createSelector(
//   selectProductAttributeListState,
//   fromProductAttributeList.getTotalProductAttribute
// );

export const selectProductAttributePageResults = createSelector(
  selectProductAttributeEntities,
  selectProductAttributePageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// ProductAttribute page
export const selectProductAttributeListPageState = createSelector(
  selectProductAttributeState,
  state => state.productAttributeListPage
);

export const selectProductAttributeListPageFilter = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getFilter
);

export const selectPageListPage = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getPage
);

export const selectPageSizeListPage = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getPageSize
);

export const selectProductAttributeListPageLoading = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getLoading
);

export const selectProductAttributeListPageTotal = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getTotal
);

export const selectProductAttributeListPageIds = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getIds
);

export const selectProductAttributeListPageDisplayedColumns = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getDisplayedColumns
);


export const selectCurrentAttributeSetListPageColumns = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getCurrentAttributeSet
);

export const selectListAttributeIgnoreListPageColumns = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getListAttributeIgnoreSet
);

export const selectListAtrributeIgnoreListPageColumns = createSelector(
  selectProductAttributeListPageState,
  fromProductAttributeListPage.getTotalListAttributeIgnore
);


export const selectProductAttributeListPageResults = createSelector(
  selectProductAttributeEntities,
  selectProductAttributeListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);
