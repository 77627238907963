import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';


export const loadCategory = createAction(
  '[Category Page] Load Category',
  props<{ filter: any }>()
);

export const loadCategoriesSuccess = createAction(
  '[Collection/API] Load Categories Success',
  props<{ categories: any, total: number }>()
);

export const loadCategoriesFailure = createAction(
  '[Collection/API] Load Categories Failure',
  props<{ error: any }>()
);

export const updateCategory = createAction(
  '[Category Page] Update Category',
  props<{ categoryObject: any }>()
);

export const updateCategorySuccess = createAction(
  '[Collection/API] Update Category Success',
  props<{ category: any }>()
);

export const updateCategoryFailure = createAction(
  '[Collection/API] Update Category Failure',
  props<{ error: any }>()
);

export const addCategory = createAction(
  '[Category Page] Add Category',
  props<{ categoryObject: any }>()
);

export const addCategorySuccess = createAction(
  '[Collection/API] Add Category Success',
  props<{ category: any }>()
);

export const addCategoryFailure = createAction(
  '[Collection/API] Add Category Failure',
  props<{ error: any }>()
);

export const updateListCategory = createAction(
  '[Category Page] Update List Category',
  props<{ categories: any, category: any }>()
);

export const updateListCategorySuccess = createAction(
  '[Collection/API] Update List Category Success',
  props<{ category: any }>()
);

export const updateListCategoryFailure = createAction(
  '[Collection/API] Update List Category Failure',
  props<{ error: any }>()
);

export const searchCategory = createAction(
  '[Category Page] Search Category',
  props<{ objectSearch: any }>()
);

export const searchCategorySuccess = createAction(
  '[Collection/API] Search Categories Success',
  props<{ categories: any }>()
);

export const searchCategoryFailure = createAction(
  '[Collection/API] Search Categories Failure',
  props<{ error: any }>()
);
