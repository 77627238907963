import { createAction, props } from '@ngrx/store';


export const loadAlerts = createAction(
  '[Alert Page] Load Alerts',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadAlertsSuccess = createAction(
  '[Alert Page] Load Alerts Success',
  props<{ total: number, rows: any }>()
);

export const loadAlertsFailure = createAction(
  '[Alert Page] Load Alerts Failure',
  props<{ error: any }>()
);

export const loadListAlertByParent = createAction(
  '[Alert Page] Load List Alert Parent',
  props<{ filter: any, limit: number, offset: number, parent_id: number }>()
);

export const loadListAlertByParentSuccess = createAction(
  '[Alert Page] Load List Alert Parent Success',
  props<{ total: number, rows: any }>()
);

export const loadListAlertByParentFailure = createAction(
  '[Alert Page] Load List Alert Parent Failure',
  props<{ error: any }>()
);

export const addAlert = createAction(
  '[Alert Page] Add Alert',
  props<{ alertObject: any }>()
);

export const addAlertSuccess = createAction(
  '[Alert Page] Add Alert Success',
  props<{ alert: any }>()
);

export const addAlertFailure = createAction(
  '[Alert Page] Add Alert Failure',
  props<{ error: any }>()
);


export const updateAlert = createAction(
  '[Alert Page] Update Alert',
  props<{ alertObject: any }>()
);

export const updateAlertSuccess = createAction(
  '[Alert Page] Update Alert Success',
  props<{ alert: any }>()
);

export const updateAlertFailure = createAction(
  '[Alert Page] Update Alert Failure',
  props<{ error: any }>()
);



export const getAttributeSetByAlert = createAction(
  '[Alert Page] Get AttributeSet',
  props<{ id: any }>()
);

export const getAttributeSetByAlertSuccess = createAction(
  '[Alert Page] Get AttributeSet Success',
  props<{ listAttribute: any }>()
);

export const getAttributeSetByAlertFailure = createAction(
  '[Alert Page] Get AttributeSet Failure',
  props<{ error: any }>()
);


export const getTreeAlert = createAction(
  '[Alert Page] Get Tree Alert',
  props<{ filter: any, limit: number, offset: number }>()
);

export const getTreeAlertSuccess = createAction(
  '[Alert Page] Get Tree Alert Success',
  props<{ treeAlert: object }>()
);

export const getTreeAlertFailure = createAction(
  '[Alert Page] Get Tree Alert Failure',
  props<{ error: any }>()
);

export const getDetailAlert = createAction(
  '[Alert Page] Get Detail Alert',
  props<{ id: any }>()
);

export const getDetailAlertSuccess = createAction(
  '[Alert Page] Get Detail Alert Success',
  props<{ alert: object }>()
);

export const getDetailAlertFailure = createAction(
  '[Alert Page] Get Detail Alert Failure',
  props<{ error: any }>()
);
