import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AttributeProductActions } from '../actions';


export const attributeProductListFeatureKey = 'attributeProductList';

export interface State {
  ids: number[];
  filter: any;
  total: number;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  filter: {},
  total: 0,
  loading: false
};

export const attributeProductListReducer = createReducer(
  initialState,
  on(
    AttributeProductActions.loadAttributeProduct,
    (state, { filter }) => {
      return {
        ...state,
        filter,
        loading: true,
      }
    }
  ),
  on(
    AttributeProductActions.loadAttributeProductsSuccess,
    (state, { attributeProducts, total }) => {
      return {
        ...state,
        ids: attributeProducts.map(item => item.id),
        total: total,
        loading: false,
      }
    }
  ),
  on(
    AttributeProductActions.searchAttributeProductSuccess,
    (state, { attributeProducts }) => {
      return {
        ...state,
        ids: attributeProducts.list.map(item => item.id),
        total: attributeProducts.total,
        loading: false,
      }
    }
  ),
  on(
    AttributeProductActions.deleteAttributeProduct,
    (state, { id }) => {
      return {
        ...state,
        ids: state.ids.filter(it => it !== id),
        loading: true,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return attributeProductListReducer(state, action);
}

export const getIds = (state: State) => state.ids;
export const getTotalAttributeProduct = (state: State) => state.total;

export const getObjectFilter = (state: State) => state.filter;

export const getLoadingData = (state: State) => state.loading;
