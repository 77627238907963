import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WarningProductRoutingModule } from './warning-product-routing.module';
// import {
//     GlnListComponent,
//     GlnImportModalComponent,
//     GlnModalComponent,
//  } from './components';
import { WarningProductPageComponent } from '../warning-product/containers';
import { MaterialModule } from '../shared/material.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashFilterBarModule } from '@dash/filter-bar';
import { TitleModule } from '@dash/title';
import { WarningProductListComponent } from './components/warning-product-list/warning-product-list.component';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { WarningProductEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { WarningProductModalComponent } from './components/warning-product-modal/warning-product-modal.component';
import { DetailPaperModalComponent } from './components/detail-paper-modal/detail-paper-modal.component';

export const COMPONENTS = [
  WarningProductListComponent,
  WarningProductModalComponent,
  DetailPaperModalComponent
];

export const CONTAINERS = [
  WarningProductPageComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    CommonModule,
    WarningProductRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TitleModule,
    DashFilterBarModule,
    StoreModule.forFeature(fromEffects.warningProductFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([WarningProductEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: [],
})
export class WarningProductModule { }
