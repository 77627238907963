<dash-feature-bar>
    <dash-feature-bar-row>
      <dash-feature-bar-section>
        <h1 dashFeatureBarTitle>Quản lý bộ thuộc tính</h1>
      </dash-feature-bar-section>
    </dash-feature-bar-row>
  </dash-feature-bar>

<ic-filter-bar [availableFilters]="availableFilters"></ic-filter-bar>
<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button class="button-table" mdc-button raised (click)="create()">Thêm mới</button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <!-- <ic-toolbars></ic-toolbars> -->
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <!-- <th mdcDataTableHeaderCell>
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="selection.hasValue() && isAllSelected()"
                             [indeterminate]="selection.hasValue() && !isAllSelected()"
                             [aria-label]="checkboxLabel()">
              </dash-checkbox>
            </th> -->
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="name">Tên bộ thuộc tính</th>
            <th mdcDataTableHeaderCell name="created_at">Ngày tạo</th>
            <th mdcDataTableHeaderCell name="updated_at">Thời gian cập nhật gần nhất</th>

            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)">
            <!-- <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? selection.toggle(row) : null"
                             [checked]="selection.isSelected(row)"
                             [aria-label]="checkboxLabel(row)">
              </dash-checkbox>
            </td> -->
            <td mdcDataTableCell name="id">
              {{row.id}}
            </td>
            <td mdcDataTableCell name="name">
              <span class="text-break">{{row.name}}</span>
            </td>
            <td mdcDataTableCell name="created_at">{{row.createdAt}}</td>
            <td mdcDataTableCell name="updated_at">{{row.updatedAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)= "update(row)" class="btn-edit">
                <mdc-icon >edit</mdc-icon>
              </button>
              <button mdcIconButton (click)="deleteAttributeSet(row)" class="btn-delete">
                <mdc-icon >delete</mdc-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
    ></mat-paginator>
  </mdc-card>
</div>