<mdc-dialog>
  <mdc-dialog-container>
    <mdc-dialog-surface>
      <mat-toolbar class="header-dialog">
		    <mat-icon class="icon-add">add_circle</mat-icon>
		    <label class="header-text">Lọc theo {{ data.filter.name | lowercase }}</label>
	  	</mat-toolbar>
      <mdc-dialog-content>
        <form [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off" style="margin: 0.5em;">
          <mat-form-field  appearance="outline" style="width:100%" *ngIf="!isMutipleSelect; else mutipleSelect">
            <mat-label>{{data.filter.name}}</mat-label>
            <mat-select formControlName="value" [placeholder]="data.filter.name">
              <mat-form-field appearance="outline" class="form-field-search">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput
                    type="text"
                    name="filter-options"
                    id="filter-options"
                    placeholder="Search"
                    (keyup)= "changeInputSearch($event)"
                    (keydown)="$event.stopPropagation()">
              </mat-form-field>
              <mat-option *ngFor="let item of options" [value]="item.value">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ng-template #mutipleSelect>
            <mat-form-field appearance="outline">
            <mat-label>{{data.filter.name}}</mat-label>
            <mat-select formControlName="value" [placeholder]="data.filter.name" multiple>
              <mat-option *ngFor="let item of options" [value]="item.value">{{item.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          </ng-template>

        </form>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton mdcDialogAction="close" class="btn-cancel">Hủy</button>
        <button mdcDialogButton [disabled]="!form.valid" (click)="onApply()" class="btn-apply">Đồng ý</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </mdc-dialog-container>
</mdc-dialog>
