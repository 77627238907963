
<ic-filter-bar [availableFilters]="availableFilters" (inputSearchChange) ="changeInputSeach($event)"></ic-filter-bar>
<!-- End Filter bar -->

<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button mdc-button raised class="btn" (click)="assignProduct()">Gán sản phẩm</button>
        <button mdc-button raised class="btn btn-hidden" (click)="hiddenProduct()" [disabled]="!this.selected.length">Ẩn </button>
        <button mdc-button raised class="btn btn-show" (click)="showProduct()" [disabled]="!this.selected.length">Hiện </button>
        <button mdc-button raised class="btn btn-cancel-verify" (click)="cancelVerifyProduct()" [disabled]="!this.selected.length">Hủy xác thực </button>
        <button mdc-button raised class="btn btn-verify" (click)="verifyProduct()" [disabled]="!this.selected.length">Xác thực </button>
        <!-- <button mdc-button raised class="btn btn-remove" (click)="removeListProduct()" [disabled]="!this.selected.length">Xóa </button>
        <button mdc-button raised class="btn btn-cancel-verify" (click)="cancelVerifyProduct()" [disabled]="!this.selected.length">Hủy xác thực </button>
        <button mdc-button raised class="btn btn-verify" (click)="verifyProduct()" [disabled]="!this.selected.length">Xác thực </button> -->
        <!-- <button mdc-button raised class="button-table" [routerLink]="['create']">Thêm mới</button> -->
        <!-- <button mdc-button raised class="button-table" (click)="importExcel()">Import excel</button>
        <button mdc-button raised class="button-table" (click)="exportExcel()">Xuất excel</button> -->
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">Ảnh</th>
            <th mdcDataTableHeaderCell name="image">Tên sản phẩm</th>
            <th mdcDataTableHeaderCell name="name">Mã sản phẩm</th>
            <th mdcDataTableHeaderCell name="owned_business">Trạng thái xác thực</th>
            <th mdcDataTableHeaderCell name="business_management">Xác thực bởi</th>
            <th mdcDataTableHeaderCell name="scan_sattus">Trạng thái ẩn/hiện</th>
            <th mdcDataTableHeaderCell name="update_at">Ngày tạo</th>
            <th mdcDataTableHeaderCell name="update_at">Ngày cập nhật</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)" >
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id" ><img onError="src='/assets/stran.png'" [src]="row?.image" /></td>
            <td mdcDataTableCell name="image" >
              <span class="text-break">{{row.name}}</span>
            </td>
            <td mdcDataTableCell name="name" >{{row.barcode}}</td>
            <td mdcDataTableCell name="business_management" >
              <span *ngIf="row.status === 1" class="verified">Xác thực</span>
              <span *ngIf="row.status === 3" class="not-verified">Chưa xác thực</span>
            </td>
            <td mdcDataTableCell name="scan_sattus" >{{row.verifyBy}}</td>
            <td mdcDataTableCell name="authentication_sattus" >
              <span *ngIf="row.visible === 1">Hiện</span>
              <span *ngIf="row.visible === 3">Ẩn</span>
            </td>
            <td mdcDataTableCell name="authentication_sattus" >{{row.createdAt}}</td>
            <td mdcDataTableCell name="authentication_sattus" >{{row.updatedAt}}</td>

          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
    ></mat-paginator>
  </mdc-card>
</div>
