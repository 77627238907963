import {Component, OnInit, ChangeDetectorRef, ViewChild, Inject} from '@angular/core';
import { ProductPageActions } from '@products/actions';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { MDCDataTableRowSelectionChangedEvent, MdcDialog, MDCDataTable } from '@angular-mdc/web';
import { CategoryListPageActions } from '@category/actions';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '@products/services/product.services';
import { MatPaginator } from '@angular/material/paginator';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { Observable, Subject, Subscription, merge } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as fromProduct from '@products/reducers';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import * as fromCategory from '@category/reducers';
import { AssignProductGlnComponent } from '../assign-product-gln/assign-product-gln.component';
import { VerifyDocumentListComponent } from '@products/components/product-distributor/verify-document-list/verify-document-list.component';
import { GlnService } from '@gln/services/gln.service';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { CancelVerifyDistributorComponent } from '@products/components/product-distributor/cancel-verify-distributor/cancel-verify-distributor.component';
import { formatDate, clean, buildImageUrls } from '@src/app/shared/common';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UpdateDistributorComponent } from '@products/components/product-distributor/update-distributor/update-distributor.component';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function searchTeko(info)
declare function alertEvent(info);
@Component({
  selector: 'ic-gln-list-product',
  templateUrl: './gln-list-product.component.html',
  styleUrls: ['./gln-list-product.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductEnterprise();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class GlnListProductComponent implements OnInit {

  rows: any = [];
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  total = 0;
  pageSize = 10;
  currentProduct;
  productCurrent$: Observable<any>;
  enterpriseId;

  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private storeCategory: Store<fromCategory.State>,
    private route: ActivatedRoute,
    private enterpriseService: GlnService,
    @Inject('availableFilters') public availableFilters : [],


  ) {
    this.productCurrent$ = store.pipe(select(fromProduct.selectProductCurrent));
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
    // this.glns$ = store.pipe(select(fromProduct.selectProductListResults));
    // this.length$ = store.pipe(select(fromProduct.selectTotalProduct));
    // this.filter$ = store.pipe(select(fromProduct.selectObjectFilterProduct));
    // this.loading$ = store.pipe(select(fromProduct.selectLoadingDataProduct));
    this.page$ = store.pipe(select(fromProduct.selectPage));
    this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    this.enterpriseId = this.route.snapshot.paramMap.get('id');
    this.getListProductDistributorOfEnterprise();
    enterScreenView(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.SCREEN_NAME, TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_TYPE)
    // console.log(this.rows);
  }

  ngAfterViewInit() {
    const filterChange = this.filterBar.filterChange;
    let pageChange;
      pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$))
    .subscribe(() => {
    });
    filterChange
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        // console.log(res);

        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const object = {
          ...this.filterBar.filterForm.value,
          expire_at_from_date: this.filterBar.filterForm.value.expire_at_from_date ? formatDate(this.filterBar.filterForm.value.expire_at_from_date) : '',
          expire_at_to_date: this.filterBar.filterForm.value.expire_at_to_date ? formatDate(this.filterBar.filterForm.value.expire_at_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
        }
        console.log(this.paginator)
        const request = {
          filter: clean(object),
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        }

        searchTeko({... request.filter, page: request.page, pageSize: request.pageSize})

        this.getListProductDistributorOfEnterprise(request);
      });
  }

  getListProductDistributorOfEnterprise(request?){
    const object = request || {}
    this.enterpriseService.getListProductDistributorOfEnterprise(this.enterpriseId, object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      const list = res.data.items.map(item => {
        // console.log('itemmmmm', item);
        const newObject = Object.assign({}, item);
        if (newObject.image) {
          newObject.image = buildImageUrls(newObject.image).original;
          item = newObject
        }
        return item
        //
      });
      this.rows = list;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeInputSeach(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  checkStatus(rows?) {
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    let res = [];
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.id).indexOf(el.id) < 0;
        });
    }
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      // if (rows) {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        // }
      }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.id === row.id);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.id === row.id);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row)
    } else {
      this.selected = this.selected.filter(item => item.id !== row.id);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
    });
    // console.log(this.selected);
    // console.log(this.rows);
    // console.log(res);

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }



  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo mới giấy tờ',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.store.dispatch(ProductPageActions.getProduct({ id: this.enterpriseId }))
        }, 500);
      }
      // console.log('The dialog was closed');
    });
  }

  assignProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Gán sản phẩm',
      action: 'create',
      enterpriseId: this.enterpriseId
    };
    dialogConfig.disableClose = true;
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.REGION_NAME,
      TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_NAME.ADD_PRODUCT, '', {});
    clickTeko(click);
    const dialogRef = this.dialog.open(AssignProductGlnComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.getListProductDistributorOfEnterprise();
        }, 500);
      }
      // console.log('The dialog was closed');
    });
  }

  hiddenProduct() {
    const body = {
      productIds: this.selected.map(item => item.id)
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.REGION_NAME,
      TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_NAME.HIDE, '', {});
    clickTeko(click);
    this.enterpriseService.hiddenProductDistributorOfEnterprise(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionHidden(this.enterpriseId, body)
      } else {
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        if (res.data.hasManager <= 0) {
          this._snackBar.open(`Ẩn sản phẩm thành công `, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          if (this.selected.length) {
            this._snackBar.open(`Ẩn ${this.selected.length - res.data.hasManager} sản phẩm thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Ẩn ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        }
        this._snackBar.open(`Ẩn ${this.selected.length - res.data.hasManager} sản phẩm của thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.getListProductDistributorOfEnterprise();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })

  }

  confirmActionHidden(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.hiddenProductDistributorOfEnterprise(enterpriseId, request).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Ẩn sản phẩm của thành công `, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Ẩn ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Ẩn ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  showProduct() {
    const body = {
      productIds: this.selected.map(item => item.id)
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.REGION_NAME,
      TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_NAME.SHOW, '', {});
    clickTeko(click);
    this.enterpriseService.showProductDistributorOfEnterprise(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionShow(this.enterpriseId, body)
      } else {
        if (res.data.hasManager <= 0) {
          const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open(`Hiện sản phẩm của thành công `, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          if (this.selected.length) {
            this._snackBar.open(`Hiện ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Hiện ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        }
        setTimeout(() => {
          this.getListProductDistributorOfEnterprise();
        }, 500);
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionShow(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.showProductDistributorOfEnterprise(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Hiện sản phẩm của thành công `, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Hiện ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Hiện ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }



  removeProduct(row?) {

  }

  cancelVerifyProduct(row?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thực nhà phân phối',
      question: 'Bạn có chác chắn muốn hủy xác thực sản phẩm của phân phối này không?',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.REGION_NAME,
      TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_NAME.UNVERIFY, '', {});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      // console.log('resulttttttttt', result);


      if (result !== 'close') {
        const body = {
          productIds: row ? [row.id] : this.selected.map(item => item.id),
          reason: result.reason
        }
        this.enterpriseService.unappoveProductDistributorOfEnterprise(this.enterpriseId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUnVerify(this.enterpriseId, body);
            dialogRef.close('accept');
          } else {
            if (res.data.hasManager <= 0) {
              const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS)
              alertEvent(alert);
              this._snackBar.open(`Hủy xác thực sản phẩm của thành công `, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              if (this.selected.length) {
                const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED)
                alertEvent(alert);
                this._snackBar.open(`Hủy xác thực ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              } else {
                this._snackBar.open(`Hủy xác thực ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            }
            setTimeout(() => {
              this.getListProductDistributorOfEnterprise();
            }, 500);

            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      }
    })

  }

  confirmActionUnVerify(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.unappoveProductDistributorOfEnterprise(enterpriseId, request).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Hủy xác thực sản phẩm của thành công `, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Hủy xác thực ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Hủy xác thực ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }
          }
          setTimeout(() => {
            this.getListProductDistributorOfEnterprise();
          }, 500);

          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  verifyProduct(row?) {

    const body = {
      productIds: row ? [row.id] : this.selected.map(item => item.id)
    }
    const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.REGION_NAME,
      TekoValueDto.ENTERPRISE_PRODUCT_DISTRIBUTOR.CONTENT_NAME.VERIFY, '', {});
    clickTeko(click);
    this.enterpriseService.appoveProductDistributorOfEnterprise(this.enterpriseId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionVerify(this.enterpriseId, body)
      } else {
        if (res.data.hasManager <= 0) {
          const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open(`Xác thực sản phẩm của thành công `, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          if (this.selected.length) {
            const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
            alertEvent(alert);
            this._snackBar.open(`Xác thực ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            const alert = new AlertTekoDto(ALERT.FAILED, ALERT.FAILED);
            alertEvent(alert);
            this._snackBar.open(`Xác thực ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
        }
        setTimeout(() => {
          this.getListProductDistributorOfEnterprise();
        }, 500);

        this.resetSelected();
      }

    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })

  }

  confirmActionVerify(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.appoveProductDistributorOfEnterprise(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Xác thực sản phẩm của thành công `, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            this._snackBar.open(`Xủy xác thực ${this.selected.length - res.data.hasManager} sản phẩm của thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          }
          setTimeout(() => {
            this.getListProductDistributorOfEnterprise();
          }, 500);

          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  removeListProduct(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa sản phẩm  này không?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const body = {
          productIds: row ? [row.id] : this.selected.map(item => item.id),
        }
        this.enterpriseService.removeProductDistributorOfEnterprise(this.enterpriseId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res:any) => {

          if (res.code === 1104) {
            this.confirmActionDelete(this.enterpriseId, body)
          } else {
            if (res.data.hasManager <= 0) {
              this._snackBar.open(`Xóa sản phẩm  thành công `, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              if (this.selected.length) {
                this._snackBar.open(`Xóa ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              } else {
                this._snackBar.open(`Xóa ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                  duration: 2000,
                  panelClass: ['snackbar-success']
                });
              }
            }
            setTimeout(() => {
              this.getListProductDistributorOfEnterprise();
            }, 500);
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionDelete(enterpriseId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: 1
        };
        this.enterpriseService.removeProductDistributorOfEnterprise(enterpriseId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.data.hasManager <= 0) {
            this._snackBar.open(`Xóa sản phẩm  thành công `, 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
          } else {
            if (this.selected.length) {
              this._snackBar.open(`Xóa ${this.selected.length - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            } else {
              this._snackBar.open(`Xóa ${1 - res.data.hasManager} sản phẩm  thành công do có ${res.data.hasManager} sản phẩm được quản lý`, 'Cancel', {
                duration: 2000,
                panelClass: ['snackbar-success']
              });
            }

          }
          setTimeout(() => {
            this.getListProductDistributorOfEnterprise();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }



  updateProduct(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật sản phẩm của doanh nghiệp',
      action: 'update-product',
      productId: row.id,
      enterpriseId: this.enterpriseId,
      row
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UpdateDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        setTimeout(() => {
          this.getListProductDistributorOfEnterprise();
        }, 500);
      }
    });
  }

  detailDocumentProduct(row) {
    console.log(row);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Duyệt giấy tờ',
      action: 'create',
      enterpriseId: this.enterpriseId,
      productId: row.id
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(VerifyDocumentListComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        // setTimeout(() => {
        //   this.loadListDistributor();
        // }, 500);
      }
    });
  }

}
