<mdc-icon class="dash-filter-bar__icon" aria-hidden="false" aria-label="Filter icon">filter_list</mdc-icon>
<mdc-chip-set input class="dash-filter-bar__list">
  <dash-filter-bar-item
    class="dash-filter-bar__item"
    *ngFor="let filter of appliedFilters; index as i"
    (interactionEvent)="editFilterSetting(i)"
    (removalEvent)="onFilterRemoved(i)"
    [appliedFilter]="filter">
  </dash-filter-bar-item>
  <div *ngIf="addableFilters.length > 0" mdcMenuSurfaceAnchor #newFilterAnchor class="dash-filter-bar__menu-surface">
    <mdc-chip (click)="demo.open = !demo.open" class="dash-filter-bar__new" label="New">
      <mdc-chip-icon leading>add</mdc-chip-icon>
    </mdc-chip>
    <!-- <mdc-chip *ngIf="filter.length" class="btn-open" (click) = "removeAllFilter()" style="background-color: #8080807a;">
      <mdc-chip-icon leading style="color: white;">refresh</mdc-chip-icon>
      <mdc-chip-text>Reset</mdc-chip-text>
    </mdc-chip> -->
    <mdc-menu #demo [anchorElement]="newFilterAnchor" (selected)="openFilterSetting($event)" class="dash-filter-bar__menu">
      <mdc-list>
        <mdc-list-item *ngFor="let filter of addableFilters">{{ filter.name }}</mdc-list-item>
      </mdc-list>
    </mdc-menu>
  </div>
</mdc-chip-set>
