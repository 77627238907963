import { createAction, props } from '@ngrx/store';

import { Product } from '../models';

export const loadProductsSuccess = createAction(
  '[ProductsAPI] Load Products Success',
  props<{ data: Product[] }>()
);

export const loadProductsFailure = createAction(
  '[ProductsAPI] Load Products Failure',
  props<{ error: any }>()
);
