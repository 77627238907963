import {Component, OnInit, ChangeDetectorRef, ViewChild, EventEmitter, Output, Input, Inject, OnDestroy} from '@angular/core';
import { ProductListPageActions, ProductPageActions } from '@products/actions';
import { MDCDataTableRowSelectionChangedEvent, MdcDialog, MDCDataTable } from '@angular-mdc/web';
import { CategoryListPageActions } from '@category/actions';
import { formatDate, clean, buildImageUrls } from '@src/app/shared/common';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductService } from '@products/services/product.services';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import * as fromProduct from '../../reducers';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import * as fromCategory from '../../../category/reducers';

import { ProductDocumentsPackageComponent } from '../product-documents-package/product-documents-package.component';
import { CreateProductDocumentsComponent } from '../create-product-documents/create-product-documents.component';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { CancelVerifyDistributorComponent } from '../product-distributor/cancel-verify-distributor/cancel-verify-distributor.component';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';
import {BarcodeBase} from '@src/app/shared/common/barcode-base';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
// @ts-ignore
import {DOC_PRODUCT_TYPE} from '@src/app/constants';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
declare function searchTeko(info)

@Component({
  selector: 'ic-product-documents',
  templateUrl: './product-documents.component.html',
  styleUrls: ['./product-documents.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProductDocument();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductDocumentsComponent  implements OnInit , OnDestroy{

  rows: any = [];
  changesSubscription: Subscription;
  public selection = new SelectionModel<any>(true, []);

  docProductType = DOC_PRODUCT_TYPE;

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  products$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  nations$: Observable<any>;
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  categories$: Observable<any>;
  total = 0;
  pageSize = 10;
  currentProduct;
  productCurrent$: Observable<any>;
  productId;
  barcode = '';
  @ViewChild(FilterBarNewComponent, { static: true }) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MDCDataTable, { static: true }) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private router: Router,
    private store: Store<fromProduct.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private storeAttributeSet: Store<fromProductAttribute.State>,
    public productService: ProductService,
    private _snackBar: MatSnackBar,
    private storeCategory: Store<fromCategory.State>,
    private route: ActivatedRoute,
    @Inject('availableFilters') public availableFilters: [],

  ) {
    this.productCurrent$ = store.pipe(select(fromProduct.selectProductCurrent));
    this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));

    this.page$ = store.pipe(select(fromProduct.selectPage));
    this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
    this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
  }

  ngOnInit(): void {
    enterScreenView ('product_document', 'product');
    this.productId = this.route.snapshot.paramMap.get('id');
    this.productService.getDetailProduct(this.productId).subscribe((res: any) => {
      this.barcode = res.data.barcode;
    });
    this.getListDocument();
    // console.log(this.rows);
  }

  ngAfterViewInit() {
    const filterChange = this.filterBar.filterChange;
    let pageChange;
    pageChange = merge(
        this.paginator.page,
        this.paginator.initialized
      );
    pageChange.pipe(takeUntil(this.destroy$))
    .subscribe(() => {
    });
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // console.log(res);

        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        // console.log(res);
        // console.log(this.filterBar.filterForm.value);
        const object = {
          ...this.filterBar.filterForm.value,
          expire_at_from_date: this.filterBar.filterForm.value.expire_at_from_date
            ? formatDate(this.filterBar.filterForm.value.expire_at_from_date) : '',
          expire_at_to_date: this.filterBar.filterForm.value.expire_at_to_date
            ? formatDate(this.filterBar.filterForm.value.expire_at_to_date) : '',
        };
        const request = {
          filter: clean(object),
          page: this.paginator.pageIndex + 1,
          pageSize: this.paginator.pageSize,
        };
        searchTeko({...request.filter, page: request.page, pageSize: request.pageSize})
        this.getListDocument(request);
      });
  }

  getListDocument(request?) {
    const object = request || {};
    this.productService.getListDocumentsOfProduct(this.productId, object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.rows = res.data.items;
      this.total = res.data.totalElement;
      this.checkStatus(this.rows);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeInputSeach(event) {
    this.storeCategory.dispatch(
      CategoryListPageActions.searchCategory({
        filter: { name: event },
        page: 1,
        pageSize: 100
      })
    );
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
    // console.log(event);
  }

  onSelectedAll(): void {
  }

  onUnselectedAll(): void {
  }

  checkStatus(rows?) {

    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    let res = [];
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.productDocumentId).indexOf(el.productDocumentId) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.productDocumentId).indexOf(el.productDocumentId) < 0;
        });
    }
    // console.log(res);
    // console.log(this.selected);
    // console.log(rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      // if (rows) {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      // }

    }

  }
  checkSelected(row) {
    // console.log(this.selected);
    const index = this.selected.find(item => item.productDocumentId === row.productDocumentId);
    if (index) {
      return true;
    }

    // console.log(this.selection.isSelected(row));

    return false;
  }
  toggleSelection(row) {
    // console.log(row);

    // this.selection.toggle(row)
    const index = this.selected.findIndex(item => item.productDocumentId === row.productDocumentId);
    // console.log(index);

    if (index < 0) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter(item => item.productDocumentId !== row.productDocumentId);
    }

    // console.log(this.selected);

    this.checkStatus();
  }

  clearSelection(event) {
    if (event) {
      this.selected = [];
      this.isSelectedAll = false;
      this.isIndeterminate = false;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.productDocumentId).indexOf(el.productDocumentId) < 0;
    });

    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
  updateProduct(row) {
    this.router.navigate(['/products/update', row.id]);
  }

  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }


  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Tạo mới giấy tờ',
      action: 'create',
      productId: this.productId,
      type : 'createDocumentProduct'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resulttttt', result);
      if (result !== 'close') {
        this.getListDocument();
      }
      // console.log('The dialog was closed');
    });
  }

  assignDocumentFromPackage() {
    const barcode = this.productService?.currentBarcode;
    const click = new ClickTekoDto('product_document', 'product_document_link',
      'http://datahub.icheck.vn/products/update/' + this.productId, {product_id: this.productId, barcode});
    clickTeko(click);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Gán giấy tờ trong kho',
      action: 'assign',
      productId: this.productId,
      docProductType: 'docProductType'
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        this.getListDocument();
      }
      // console.log('The dialog was closed');
    });
  }

  hiddenDocument() {
    const body = {
      ids: this.selected.map(item => item.productDocumentId).join(','),
      visible: 3
    };
    const barcode = this.productService?.currentBarcode;
    const click = new ClickTekoDto('product_document', 'hide', 'http://datahub.icheck.vn/products/update/' + this.productId, {
      product_id: this.productId,
      barcode
    });
    clickTeko(click);
    this.productService.toggleDocumentStatusOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionHidden(this.productId, body);
      } else {
        this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.getListDocument();
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionHidden(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.toggleDocumentStatusOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Ẩn giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocument();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  showDocument() {
    const body = {
      ids: this.selected.map(item => item.productDocumentId).join(','),
      visible: 1
    };
    this.productService.toggleDocumentStatusOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionShow(this.productId, body);
      } else {
        const barcode = this.productService?.currentBarcode;
        const click = new ClickTekoDto('product_document', 'show', 'http://datahub.icheck.vn/products/update/' + this.productId, {
          product_id: this.productId,
          barcode
        })
        clickTeko(click)
        this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.getListDocument();
        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionShow(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.toggleDocumentStatusOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Hiện giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocument();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  removeDocument(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa nhà phân phối khỏi sản phẩm này không?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.productService.removeDocumentOfProduct(this.productId, row.productDocumentId).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocument();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });
  }

  removeListDocument(row?) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có chắc chắn muốn xóa giấy tờ khỏi sản phẩm này không ??'
    });
    const click = new ClickTekoDto('product_document' , 'product_document_delete' , 'http://datahub.icheck.vn/products/update/ljOyWmyP' ,
      {product_id: this.productId, barcode: this.barcode});
    clickTeko(click);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const ids = row ? row.productDocumentId : this.selected.map(item => item.productDocumentId).join(',');
        this.productService.removeDocumentOfProduct(this.productId, ids).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionDelete(this.productId, ids);
          } else {
            this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            this.getListDocument();
            this.resetSelected();
          }
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });

  }

  confirmActionDelete(enterpriseId, ids) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        const request = {
          params :  {
          requestType: 1
        }};
        this.productService.removeDocumentOfProduct(enterpriseId, ids, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Xóa giấy tờ thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.getListDocument();
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }


  verifyProductDocument(row?) {

    const body = {
      productDocumentIds: row ? [row.productDocumentId] : this.selected.map(item => item.productDocumentId)
    };
    this.productService.appoveDocumentOfProduct(this.productId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmActionVerify(this.productId, body);
      } else {
        const click = new ClickTekoDto('product_document', 'product_document_verify' , 'http://datahub.icheck.vn/products/update/ljOyWmyP',
          {product_id: this.productId, barcode: this.barcode});
        clickTeko(click);
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        this._snackBar.open('Xác thực giấy tờ của sản phẩm thành công ', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        setTimeout(() => {
          this.getListDocument();
        }, 500);

        this.resetSelected();
      }
    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmActionVerify(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.appoveDocumentOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {

          this._snackBar.open('Xác thực giấy tờ của sản phẩm thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          setTimeout(() => {
            this.getListDocument();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  cancelVerifyProductDocument(row?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Hủy xác thưc giấy tờ',
      question: 'Bạn có chắc chắn muốn hủy xác thực giấy tờ của sản phẩm này không ?',
      action: 'create',
      productId: this.productId,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CancelVerifyDistributorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('resulttttttttt', result);


      if (result !== 'close') {
        const body = {
          productDocumentIds: row ? [row.productDocumentId] : this.selected.map(item => item.productDocumentId),
          reason: result.reason
        };
        this.productService.unAppoveDocumentOfProduct(this.productId, body).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUnVerify(this.productId, body);
          } else {
            this._snackBar.open('Hủy xác thưc giấy tờ của sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            setTimeout(() => {
              this.getListDocument();
            }, 500);

            this.resetSelected();
          }

        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
    });
  }

  confirmActionUnVerify(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.unAppoveDocumentOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Hủy xác thực giấy tờ của nhà phân phối thành công ', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.getListDocument();
          }, 500);
          this.resetSelected();
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {

      }
      console.log('The dialog was closed');
    });
  }


  updateDocument(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật giấy tờ',
      action: 'update',
      row
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.getListDocument();
        }, 500);
      }
      // console.log('The dialog was closed');
    });
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original;
    if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }
}
