import { ProductAttributeGroupUpdateComponent } from './../product-attribute-group-update/product-attribute-group-update.component';
import { ProductAttributeGroupCreateComponent } from './../product-attribute-group-create/product-attribute-group-create.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DashFilter } from './../../../dash/filter-bar/filter-bar.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  Inject
} from '@angular/core';
import { Observable, Subscription, Subject, merge } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MDCDataTable, MdcDialog } from '@angular-mdc/web';
import { Store, select } from '@ngrx/store';
import * as fromProductAttribute from '../../reducers';
import { isNull } from 'util';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ProductAttributePageActions, ProductAttributeListPageActions } from '../../actions';
import { AlertDeleteAttributeSetComponent } from '../alert-delete-attribute-set/alert-delete-attribute-set.component';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { formatDate } from '@src/app/shared/common';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-product-attribute-group-list',
  templateUrl: './product-attribute-group-list.component.html',
  styleUrls: ['./product-attribute-group-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterGroupAttribute();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductAttributeGroupListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  pageSize: number;

  @Input()
  total: number;

  @Input()
  set rows(rows: any) {
    this.selection.clear();
    this._rows = rows;
  }
  get rows(): any {
    return this._rows;
  }
  private _rows: any = [];

  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }

  get displayedColumns(): string[] {
    const availableColumns = this.availableColumns.map(c => c.key);
    const columns = this._displayedColumns.map(column => {
      return availableColumns.indexOf(column) > -1 ? column : null;
    }).filter(filter => (filter));

    return columns;
  }

  _displayedColumns: string[];

  @Output()
  deleteItems = new EventEmitter<any>();

  @Output()
  load = new EventEmitter<any>();
  availableColumns = [
    { key: 'select', label: 'Select', order: 1 },
    { key: 'id', label: 'ID', order: 2 },
    { key: 'name', label: 'Name', order: 3 },
    { key: 'image', label: 'Image', order: 4 },
    { key: 'order_sort', label: 'Order Sort', order: 5 },
    { key: 'rating_attribute', label: 'Rating Attribute', order: 6 },
    { key: 'product_attribute', label: 'Product Attribute', order: 7 },
    { key: 'action', label: 'Action', order: 8 },
  ];

  get sortedAvailableColumns(): string[] {
    return this.availableColumns.sort((a, b) => a.order - b.order).map((c: any) => c.key);
  }

  changesSubscription: Subscription;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  productAttributes$: Observable<any>;
  destroy$ = new Subject();
  menuHeader: any = [];
  filter : any;



  @ViewChild(FilterBarNewComponent, {static: true}) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MDCDataTable, {static: true}) table: MDCDataTable;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromProductAttribute.State>,
    public dialog2: MdcDialog,
    private _changeDetectorRef: ChangeDetectorRef,
  @Inject('availableFilters') public availableFilters : [],
  ) {
    this.page$ = store.pipe(select(fromProductAttribute.selectPage));
    this.pageSize$ = store.pipe(select(fromProductAttribute.selectPageSize));
    this.filter$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageFilter));
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // console.log(this.filterBar);
    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.page = it;
    });
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.pageSize = it
    });
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.filterBar.patchValueForm(item);
      this.filter = item
    })
    const filterChange = this.filterBar?.filterChange;
    const pageChange = merge(
      this.paginator.page,
      this.paginator.initialized
    );
    filterChange
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const object = {
          ...this.filterBar.filterForm.value,
          create_to_date: this.filterBar.filterForm.value.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
          create_from_date: this.filterBar.filterForm.value.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
          update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
          update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
        }
        this.load.emit([
          this.paginator,
          object
        ]);
      });
      this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadProductAttribute(page, pageSize, filter?) {
    console.log(this.page, this.pageSize);
    this.store.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter: filter || {},
        page: page,
        pageSize: pageSize,
      })
    );
  }

  create() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '23rem';
    dialogConfig.data = {
      label: 'Tạo mới bộ thuộc tính sản phẩm',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductAttributeGroupCreateComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);

      if (result !== 'close') {
        setTimeout(() => {
          this.filterBar.resetForm();
          this.loadProductAttribute(1, this.pageSize);
        }, 1000)
      }
      console.log('The dialog was closed');
    });
  }

  update(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Cập nhật bộ thuộc tính sản phẩm',
      action: 'create',
      element
    };
    dialogConfig.disableClose = true;
    this.store.dispatch(ProductAttributePageActions.getDetailAttributeSet({ id: element.id}))
    const dialogRef = this.dialog.open(ProductAttributeGroupUpdateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        console.log(this.filter);

        setTimeout(() => {
          this.loadProductAttribute(this.page, this.pageSize, this.filter);
        }, 500);
      }
      console.log('The dialog was closed');
    });
  }

  deleteAttributeSet(element) {
    // console.log('iddddd', element.id);
    const dialogRef = this.dialog2.open(AlertDeleteAttributeSetComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.store.dispatch(ProductAttributePageActions.deleteProductAttribute({id: element.id}));
        setTimeout(() => {
          this.loadProductAttribute(this.page, this.pageSize, this.filter);
        }, 500);
      }
      console.log('The dialog was closed');
    });

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }


  onSelectionChanged($event) {}

  onSelectedAll() {}

  onUnselectedAll() {}

}
