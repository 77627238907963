import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeSelectComponent } from './tree-select.component';
import { MaterialModule } from '../../shared/material.module';




@NgModule({
  declarations: [TreeSelectComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [TreeSelectComponent]
})
export class TreeSelectModule { }
