import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ic-assign-review-modal',
  templateUrl: './assign-review-modal.component.html',
  styleUrls: ['./assign-review-modal.component.scss']
})
export class AssignReviewModalComponent implements OnInit {

  attribute_type = '1';

  constructor(public dialogRef: MatDialogRef<AssignReviewModalComponent>) { }

  ngOnInit(): void {
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

}
