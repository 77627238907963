import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'ic-product-page',
  template: `
  <div style="margin: 1rem">
    <dash-title [title]="title"></dash-title>
    <ic-attribute-product-list></ic-attribute-product-list>
  </div>
  `,
  styles: []
})
export class AttributeProductPageComponent implements AfterViewInit, OnInit {

  title = "Quản lý thuộc tính sản phẩm"
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
