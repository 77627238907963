
<div class="content">
  <div class="image-icheck"></div>
    <div class="logout">
      <form class="text-center border border-light p-5 form-login" [formGroup]="forgetForm">
        <p class="h4 mb-4 title-sign">Quên mật khẩu</p>
        <input type="email" id="defaultLoginFormEmail" class="form-control input-form mb-4" formControlName="phone_number" placeholder="Nhập số điện thoại">
        <button mat-raised-button mdbBtn color="info" block="true" class="my-4 btn-send-code" type="submit" [disabled]="!forgetForm.valid">Gửi mã</button>
        <div class="div-login">
          Bạn đã có tài khoản? <a href="" class="forgot-pass" [routerLink]="['/auth/login']" >Đăng nhập ngay</a>
        </div>
      </form>
    </div>
  
</div>
