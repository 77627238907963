import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog } from '@angular-mdc/web';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT} from '@src/app/shared/common/teko-value.dto';
declare function alertEvent(info)
@Component({
  selector: 'ic-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {

  data: any;
  confirmPasswordForm:any;
  isShowPassword = false;
  destroy$ = new Subject();
  isLoading = false;

  @ViewChild('inputPassword') inputPassword: ElementRef

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private dialog2: MdcDialog) {
      this.data = data;
    }

  ngOnInit(): void {
    this.confirmPasswordForm = this.formBuilder.group({
      // barcodes: '',
      password: ['', Validators.required],
    });
  }

  toggleShow() {
    this.isShowPassword = !this.isShowPassword;
    if (this.isShowPassword) {
      this.inputPassword.nativeElement.setAttribute('type', 'text');
    } else {
      this.inputPassword.nativeElement.setAttribute('type', 'password');
    }
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  onFormSubmit() {
    // console.log(this.confirmPasswordForm.value);
    // console.log(this.data);

    const object = {
      attributeIds: this.data.data.attributeIds,
      barcodes: this.data.data.barcodes,
      allowUpdateProduct: this.data.data.allowUpdateProduct,
      password: this.confirmPasswordForm.value.password,
      'select-all': this.data.data['select-all']
    }
    console.log('object', object)
    this.isLoading = true;
    this.productService.removeInfoField(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      if (res.code === 1104) {
        this.confirmActionSubmit(object);
      } else {
        this.isLoading = false;
        const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
        alertEvent(alert);
        this._snackBar.open('Xóa trường thông tin thành công', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close('accept');
      }
    }, error => {
      // console.log(error);
      this.isLoading = false;
      this.dialogRef.close('close');
      this._snackBar.open(error, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    })
  }

  confirmActionSubmit(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    let request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params= {
          requestType: this.data.data.allowUpdateProduct ? 1 : 2
        };
        this.isLoading = true;
        this.productService.removeInfoField(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this.isLoading = false;
          const alert = new AlertTekoDto(ALERT.SUCCESS, ALERT.SUCCESS);
          alertEvent(alert);
          this._snackBar.open('Xóa trường thông tin thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })
      } else {
        this.dialogRef.close('close');
      }
      console.log('The dialog was closed');
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
