import { Action, createReducer, on } from '@ngrx/store';

import { CategoryListPageActions } from '../actions';


export const categoryListPageFeatureKey = 'categoryListPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: any;
  listCategory: any;
  listSearchCategory: any;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'order_sort', 'rating_attribute', 'product_attribute', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: '',
  listCategory: [],
  listSearchCategory: []
};

const categoryListPageReducer = createReducer(
  initialState,
  on(
    CategoryListPageActions.loadCategories,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    CategoryListPageActions.loadCategoriesSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        listCategory: rows,
        loading: false
      }
    }
  ),
  on(
    CategoryListPageActions.loadCategoriesFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    CategoryListPageActions.loadListCategoryByParent,
    (state, { filter, page, pageSize, id }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true,
        parent_id: id
      }
    }
  ),
  on(
    CategoryListPageActions.loadListCategoryByParentSuccess,
    (state, { total, ids }) => {
      return {
        ...state,
        total,
        ids,
        loading: false
      }
    }
  ),
  on(
    CategoryListPageActions.loadListCategoryByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    CategoryListPageActions.searchCategorySuccess,
    (state, { rows }) => {
      return {
        ...state,
        listSearchCategory: rows
      }
    }
  ),
  on(
    CategoryListPageActions.searchCategoryFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return categoryListPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getCategoryParentId = (state: State) => state.parent_id;

export const getListCategory = (state: State) => state.listCategory;


export const getListSearchCategory = (state: State) => state.listSearchCategory;
