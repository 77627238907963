import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-confirm-export',
  templateUrl: './confirm-export.component.html',
  styleUrls: ['./confirm-export.component.scss']
})
export class ConfirmExportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
