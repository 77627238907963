
<div>
  <form [formGroup]="confirmPasswordForm" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
          <div class="form-group">
            <div class="label-form">Nhập mật khẩu (*) </div>
                  <mat-form-field appearance="outline" class="form-field">
                      <input matInput formControlName="password" type="password" #inputPassword/>
                      <mat-icon matSuffix *ngIf="!isShowPassword" (click)="toggleShow()">visibility</mat-icon>
                      <mat-icon matSuffix *ngIf="isShowPassword" (click)="toggleShow()">visibility_off</mat-icon>
                  </mat-form-field>
          </div>
      </div>  
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!confirmPasswordForm.valid"><mat-icon *ngIf="isLoading"><mat-spinner class="mat-spinner-color" diameter="20">
      </mat-spinner></mat-icon>Thực hiện</button>
      </mat-toolbar>