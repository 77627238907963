import {filterAttributeValue} from '@src/app/shared/FilterTable/filter-attribute-value';
export class FilterAttribute {

  public getFilterAttributeProduct() {
    filter = ['has_enterprise', 'has_image', 'has_name', 'name_code', 'update'];
    return filterAttributeValue.filter(z => listFilterKeyProduct.indexOf(z.key) !== -1).map(filterShow);
  }
  public getFilterAttributeGln() {
    filter = ['verified', 'update', 'name', 'gln_code'];
    return filterAttributeValue.filter(z => listFilterGln.indexOf(z.key) !== -1).map(filterShow);
  }
  public getFilterAlert() {
    filter = ['title'];
    return filterAttributeValue.filter(z => listFilterAlert.indexOf(z.key) !== -1).map(filterShow);
  }

  public getFilterCategory() {
    filter = ['name-category'];
    return filterAttributeValue.filter(z => listFilterCategory.indexOf(z.key) !== -1).map(q => {
      return filter.indexOf(q.key) !== -1 ?
        {...q, key: 'name', show: true} : {...q, key: 'name' , show: false};
    });
  }
  public getFilterAttribute() {
    filter = ['name-attribute'];
    return filterAttributeValue.filter(z => listFilterAttribute.indexOf(z.key) !== -1).map(q => {
      return filter.indexOf(q.key) !== -1 ?
        {...q, key: 'name', show: true} : {...q, key: 'name' , show: false};
    });
  }
  public getFilterAttributeUser() {
    filter = ['username', 'phone', 'email'];
    return filterAttributeValue.filter(z => listFilterUser.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterImport() {
    filter =  ['file_name' , 'created_by', 'create'];
    return filterAttributeValue.filter(z => listFilterImport.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterConfigDistributor() {
    filter = ['name-title' , 'type'];
    return filterAttributeValue.filter(z => listFilterConfigDistributor.indexOf(z.key) !== -1).map(q => {
      return filter.indexOf(q.key) !== -1 ?
        {...q, key: 'name', show: true} : {...q, key: 'name' , show: false};
    });
  }
  public getFilterGroupAttribute() {
    filter = ['name-group-attribute'];
    return filterAttributeValue.filter(z => listFilterGroupAttribute.indexOf(z.key) !== -1).map(q => {
      return filter.indexOf(q.key) !== -1 ?
        {...q, key: 'name', show: true} : {...q, key: 'name' , show: false};
    });
  }
  public getFilterProductDistributor() {
    filter = ['name-company', 'tax', 'type'];
    return filterAttributeValue.filter(z => listFilterProductDistributor.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterProductDocument() {
    filter = ['name-paper', 'expire', 'visible', 'expire_at'];
    return filterAttributeValue.filter(z => listFilterProductDocument.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterProductEnterprise() {
    filter = ['name', 'type', 'status', 'visible', 'update'];
    return filterAttributeValue.filter(z => listFilterProductEnterprise.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterProductDocumentPackage() {
    filter =  ['searchType', 'enterpriseId', 'productId'];
    return filterAttributeValue.filter(z => listFilterProductDocumentPackage.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterProgram() {
    filter =  ['name-program', 'type-game', 'status-active', 'date'];
    return filterAttributeValue.filter(z => listFilterProgram.indexOf(z.key) !== -1).map( filterShow);
  }
  public getFilterWarningProduct() {
    filter =  ['name_code', 'alert_title'];
    return filterAttributeValue.filter(z => listFilterWarningProduct.indexOf(z.key) !== -1).map( filterShow);
  }
}

const listFilterKeyProduct = ['has_enterprise', 'has_image',
    'has_name', 'name_code', 'update', 'has_price', 'enterprise_name_code',
    'type_gln', 'nationId', 'scan', 'review', 'question_count', 'price', 'has_manager',  'scan_state',
    'has_category', 'category_id', 'create', 'created_by' , 'updated_by', 'not_gln_list',
    'not_nationId_list', 'link_gtin_gln', 'has_attribute_info' , 'has_other_attribute' ,
  'is_contribute', 'is_verify', 'date_by_user',

];
const listFilterGln = ['verified', 'update', 'name', 'gln_code', 'gln_type',
                       'national_id', 'create', 'verify_party', 'created_by' , 'updated_by'];

const listFilterAlert = ['title'];
const listFilterCategory = ['name-category'];
const listFilterAttribute =  ['name-attribute'];
const listFilterUser = ['username', 'phone', 'email'];
const listFilterImport = ['file_name' , 'created_by', 'create'];
const listFilterConfigDistributor = ['name-title' , 'type'];
const listFilterGroupAttribute = ['name-group-attribute'];
const listFilterProductDistributor = ['name-company', 'tax', 'type'];
const listFilterProductDocument = ['name-paper', 'expire', 'visible', 'expire_at'];
const listFilterProductEnterprise = ['name', 'type', 'status', 'visible', 'update'];
const listFilterProductDocumentPackage = ['searchType', 'enterpriseId', 'productId'];
const listFilterProgram = ['name-program', 'type-game', 'status-active', 'date'];
const listFilterWarningProduct = ['name_code', 'alert_title'];
function filterShow(q) {
  return filter.indexOf(q.key) !== -1 ?
    {...q, show: true} : {...q, show: false};
}
let  filter = [];
