import { Action, createReducer, on } from '@ngrx/store';

import { CategoryPageActions } from '../actions';

export const categoryPageFeatureKey = 'categoryPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: number;
  listAttribute: any;
  treeCategory: object;
  currentCategory: object;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'order_sort', 'rating_attribute', 'product_attribute', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: null,
  listAttribute: [],
  treeCategory: {},
  currentCategory: {}
};

const categoryPageReducer = createReducer(
  initialState,
  on(
    CategoryPageActions.loadCategories,
    (state, { filter, page, pageSize  }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    CategoryPageActions.loadCategoriesSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    CategoryPageActions.loadCategoriesFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    CategoryPageActions.loadListCategoryByParent,
    (state, { filter, page, pageSize, id }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        id,
        loading: true
      }
    }
  ),
  on(
    CategoryPageActions.loadListCategoryByParentSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    CategoryPageActions.loadListCategoryByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    CategoryPageActions.getAttributeSetByCategorySuccess,
    (state, { listAttribute }) => {
      return {
        ...state,
        listAttribute
      }
    }
  ),
  on(
    CategoryPageActions.getAttributeSetByCategoryFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    CategoryPageActions.getTreeCategorySuccess,
    (state, { treeCategory }) => {
      return {
        ...state,
        treeCategory
      }
    }
  ),
  on(
    CategoryPageActions.getTreeCategoryFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
  on(
    CategoryPageActions.getDetailCategorySuccess,
    (state, { category }) => {
      return {
        ...state,
        currentCategory: category
      }
    }
  ),
  on(
    CategoryPageActions.getDetailCategoryFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),

  on(
    CategoryPageActions.assignAttributeSetSuccess,
    (state, { object }) => {
      return {
        ...state,
      }
    }
  ),
  on(
    CategoryPageActions.assignAttributeSetFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return categoryPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getAttributeList = (state: State) => state.listAttribute;

export const getTreeCategory = (state: State) => state.treeCategory;

export const getDetailCategory = (state: State) => state.currentCategory;
