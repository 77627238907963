import { Action, createReducer, on } from '@ngrx/store';

import { AlertPageActions } from '../actions';

export const alertPageFeatureKey = 'alertPage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  parent_id: number;
  listAttribute: any;
  treeAlert: object;
  currentAlert: object;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'order_sort', 'rating_attribute', 'product_attribute', 'action'],
  error: null,
  pageSize: 10,
  page: 1,
  parent_id: null,
  listAttribute: [],
  treeAlert: {},
  currentAlert: {}
};

const alertPageReducer = createReducer(
  initialState,
  on(
    AlertPageActions.loadAlerts,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    AlertPageActions.loadAlertsSuccess,
    (state, { total, rows }) => {
      // console.log(rows, total);
      
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    AlertPageActions.loadAlertsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    AlertPageActions.loadListAlertByParent,
    (state, { filter, limit, offset, parent_id }) => {
      return {
        ...state,
        filter,
        limit,
        offset,
        parent_id,
        loading: true
      }
    }
  ),
  on(
    AlertPageActions.loadListAlertByParentSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    AlertPageActions.loadListAlertByParentFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    AlertPageActions.getAttributeSetByAlertSuccess,
    (state, { listAttribute }) => {
      return {
        ...state,
        listAttribute
      }
    }
  ),
  on(
    AlertPageActions.getAttributeSetByAlertFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    AlertPageActions.getTreeAlertSuccess,
    (state, { treeAlert }) => {
      return {
        ...state,
        treeAlert
      }
    }
  ),
  on(
    AlertPageActions.getTreeAlertFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
  on(
    AlertPageActions.getDetailAlertSuccess,
    (state, { alert }) => {
      return {
        ...state,
        currentAlert: alert
      }
    }
  ),
  on(
    AlertPageActions.getDetailAlertFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return alertPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getAttributeList = (state: State) => state.listAttribute;

export const getTreeAlert = (state: State) => state.treeAlert;

export const getDetailAlert = (state: State) => state.currentAlert;
