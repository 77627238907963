import { Action, createReducer, on } from '@ngrx/store';

import { GlnPageActions, GlnActions } from '../actions';

export const glnPageFeatureKey = 'glnPage';

export interface State {
  filter: any;
  limit: number;
  offset: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  currentGln: any;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name',  'status', 'nation', 'area', 'address', 'info', 'type','enterprise_special','updated_at', 'action'],
  error: null,
  limit: 10,
  offset: 0,
  currentGln: {},
};

const glnPageReducer = createReducer(
  initialState,
  on(
    GlnPageActions.loadGlns,
    (state, { filter, limit, offset }) => {
      return {
        ...state,
        filter,
        limit,
        offset,
        loading: true
      }
    }
  ),
  on(
    GlnPageActions.loadGlnsSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false,
        currentGln: {}
      }
    }
  ),
  on(
    GlnPageActions.loadGlnsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    GlnActions.addGln,
    (state, { glnObject }) => {
      return {
        ...state,
        currentGln: {}
      }
    }
  ),
  on(
    GlnActions.updateGlnSuccess,
    (state, { gln }) => {
      return {
        ...state,
        currentGln: {}
      }
    }
  ),
  on(
    GlnActions.updateGlnFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        currentGln: {}
      }
    }
  ),
  on(
    GlnPageActions.getDetailGlnSuccess,
    (state, { gln }) => {
      return {
        ...state,
        currentGln: gln
      }
    }
  ),
  on(
    GlnPageActions.getDetailGlnFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return glnPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getLimit = (state: State) => state.limit;

export const getOffset = (state: State) => state.offset;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getCurrentGln = (state: State) => state.currentGln;
