import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { convertObjectToQueryParams, isEmpty } from '../../shared/common';
import { environment } from 'src/environments/environment';
import { of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { myDateParser } from '../../shared/common';
import {BaseService} from '@src/app/services/base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};

@Injectable({
  providedIn: "root"
})
export class ProductAttributeService extends BaseService{

  constructor(public http: HttpClient) {
    super(http)
  }

  getListAttributeSet(params) {
    // console.log(params);
    const objectPage = convertObjectToQueryParams(params);
    const objectFilter = convertObjectToQueryParams(params.filter);
    return this.get(`attribute-set?${objectPage}&${objectFilter}`);
  }

  createAttributeSet(object) {
    return this.post(`/attribute-set`, object);
  }

  updateAttributeSet(object) {
    return this.put(`/attribute-set/${object.id}`, object);
  }

  getDetailAttributeSet(id) {
    return this.get(`/attribute-set/detail?id=${id}`);
  }

  deleteAttributeSet(id) {
    return this.delete(`/attribute-set/${id}`);
  }

  getListAttributeIgnore(object) {
    // console.log(object);
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    return this.get(`/attribute/ignoreSet?${objectPage}&${objectFilter}`);
  }
}
