import { Action, createReducer, on } from '@ngrx/store';

import { AttributePageActions } from '../actions';

export const attributePageFeatureKey = 'attributePage';

export interface State {
  filter: any;
  page: number;
  pageSize: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  currentAttribute: any
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'order_sort', 'rating_attribute', 'product_attribute', 'action'],
  error: null,
  page: 1,
  pageSize: 10,
  currentAttribute: {}
};

const attributePageReducer = createReducer(
  initialState,
  on(
    AttributePageActions.loadAttributes,
    (state, { filter, page, pageSize }) => {
      return {
        ...state,
        filter,
        page,
        pageSize,
        loading: true
      }
    }
  ),
  on(
    AttributePageActions.loadAttributesSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false
      }
    }
  ),
  on(
    AttributePageActions.loadAttributesFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(
    AttributePageActions.getDetailAttributeSuccess,
    (state, { attribute }) => {
      return {
        ...state,
        currentAttribute: attribute
      }
    }
  ),
  on(
    AttributePageActions.getDetailAttributeFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return attributePageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getPage = (state: State) => state.page;

export const getPageSize = (state: State) => state.pageSize;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getCurrentAttribute = (state: State) => state.currentAttribute;
