<div>
    <!-- <form [formGroup]="categoryForm" (ngSubmit)="onFormSubmit()"> -->
        <mat-toolbar class="header-dialog">
            <mat-icon class="icon-add">add_circle</mat-icon>
            <label class="header-text">Kiểm tra ID danh mục</label>
        </mat-toolbar>
        <div class="content-modal">
            <div class="form-group">
                <div class="label-form">Tìm kiếm theo tên danh mục</div>
                <div class="form-search">
                    <!-- <mat-form-field appearance="outline" class="form-field" style="width: 400px;">
                        <input matInput placeholder="Nhập tên danh mục">
                    </mat-form-field>
                    <button mat-raised-button class="btn-update">Tìm kiếm</button> -->
                    <mat-form-field appearance="outline" class="form-field">
                        <input matInput  type="text" placeholder="Search">
                        
                      </mat-form-field><button mat-raised-button class="btn-update">
                            Tìm kiếm
                        </button>
                </div>
            </div>
            <div class="form-group">
                <div class="label-form">Kết quả : </div>
            </div>
        </div>  
        <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
        <button mat-button type="button"  (click)= "closeDialogClick()">Hủy</button>
        <button mat-raised-button type="submit" class="btn-update">Cập nhật</button>
        </mat-toolbar>
    <!-- </form> -->
</div>
