import {Component, OnInit} from '@angular/core';
// @ts-ignore
import {DOCUMENT_PRODUCT_LIST} from '@src/app/constants';
import {FormBuilder, Validators} from '@angular/forms';
import {convertDateToFormatDate} from '@src/app/shared/common';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-modal-product-type',
  templateUrl: './modal-product-type.component.html',
  styleUrls: ['./modal-product-type.component.scss']
})

export class ModalProductTypeComponent implements OnInit {
  data: any;
  listProductDocument = DOCUMENT_PRODUCT_LIST;

  createDocumentForm = this.formBuilder.group({
    docProductType: [null, [Validators.required]],
  });

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<ModalProductTypeComponent>) {
  }

  ngOnInit(): void {
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  onFormSubmit() {
    this.dialogRef.close(this.createDocumentForm.get('docProductType').value);
  }


}
