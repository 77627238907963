import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlnPageComponent } from './containers';
import { GlnModalComponent } from '@products/components/gln-modal/gln-modal.component';
import { GlnModalPageComponent } from './components/gln-modal-page/gln-modal-page.component';
import { GlnDetailPageComponent } from './containers/gln-detail-page-component';

const routes: Routes = [
  {
    path: '',
    component: GlnPageComponent,
  },
  {
    path: 'create',
    component: GlnDetailPageComponent,
  },
  {
    path: 'update/:id',
    component: GlnDetailPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GlnRoutingModule { }
