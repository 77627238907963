import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdcButtonModule } from '@angular-mdc/web/button';
import { MdcCardModule } from '@angular-mdc/web/card';
import { MdcCheckboxModule } from '@angular-mdc/web/checkbox';
import { MDCDataTableModule } from '@angular-mdc/web/data-table';
import { MdcIconButtonModule } from '@angular-mdc/web/icon-button';
import { MdcTypographyModule } from '@angular-mdc/web/typography';

const modules = [
  MdcButtonModule,
  MdcCardModule,
  MdcCheckboxModule,
  MDCDataTableModule,
  MdcIconButtonModule,
  MdcTypographyModule
];

@NgModule({
  imports: [
    CommonModule,
    ...modules
  ],
  exports: modules
})
export class MaterialModule { }
