<div>
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
        <ic-filter-bar [availableFilters]="availableFilters"></ic-filter-bar>
        <!-- End Filter bar -->
        <div style="max-width: 100%;">
          <mdc-card class="demo-card">
            <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
              (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
              <table mdcDataTableTable>
                <thead>
                  <tr mdcDataTableHeaderRow name="select">
                    <th mdcDataTableHeaderCell name="select">
                      <dash-checkbox (change)="$event ? masterToggle() : null"
                                     [checked]="isSelectedAll"
                                     [indeterminate]="isIndeterminate">
                      </dash-checkbox>
                    </th>
                    <th mdcDataTableHeaderCell name="id">ID</th>
                    <th mdcDataTableHeaderCell name="id">Tên giấy tờ</th>
                    <th mdcDataTableHeaderCell name="image">File</th>
                    <th mdcDataTableHeaderCell name="image">Loại giấy tờ sản phẩm</th>
                  </tr>
                </thead>
                <tbody mdcDataTableContent>
                  <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)" >
                    <td mdcDataTableCell name="select">
                      <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                                     [checked]="checkSelected(row)"
                                     [aria-label]="checkboxLabel(row)"
                                     >
                      </dash-checkbox>
                    </td>
                    <td mdcDataTableCell name="id" >{{row.id}}</td>
                    <td mdcDataTableCell name="id" >
                      <span class="text-break" [title]="row.title">{{row.title}}</span>
                    </td>
                    <td mdcDataTableCell name="name" >
                      <div *ngFor="let item of row.values; index as i" class="div-file">
                        <span class="file-label text-break" [title]="item.url" (click)="openNewTab(item.url)">{{item.url}}</span>
                      </div>
                    </td>
                    <td>{{docProductType[row.docProductType]}}</td>
                  </tr>
                </tbody>
              </table>
            </mdc-data-table>
            <mat-paginator showFirstLastButtons
                           [length]="total"
                           [pageSize]="pageSize"
            ></mat-paginator>
          </mdc-card>
        </div>
      </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!this.selected.length" (click) = "submitSelected()">Thêm</button>
      </mat-toolbar>
