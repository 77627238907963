import { createAction, props } from '@ngrx/store';

import { Category } from '../models';


export const loadCategoriesSuccess = createAction(
  '[Categories Api] Load Categories Success',
  props<{ data: Category[] }>()
);

export const loadCategoriesFailure = createAction(
  '[Categories Api] Load Categories Failure',
  props<{ error: any }>()
);


export const loadCategoryByParentSuccess = createAction(
  '[Categories Api] Load List Category By Parent Success',
  props<{ data: Category[] }>()
);

export const loadCategoryByParentFailure = createAction(
  '[Categories Api] Load List Category By Parent Failure',
  props<{ error: any }>()
);
