import { createAction, props } from '@ngrx/store';



export const loadAttributesSuccess = createAction(
  '[Attributes Api] Load Attributes Success',
  props<{ data: any }>()
);

export const loadAttributesFailure = createAction(
  '[Attributes Api] Load Attributes Failure',
  props<{ error: any }>()
);

