<mdc-dialog>
  <form mdcDialogContainer [formGroup]="form" (ngSubmit)="onApply()" autocomplete="off">
    <mdc-dialog-surface>
      <mdc-dialog-title>{{ data.filter.name }}</mdc-dialog-title>
      <mdc-dialog-content>
        <mdc-form-field fluid>
          <mdc-text-field class="input-field" formControlName="value" [label]="data.filter.name"></mdc-text-field>
        </mdc-form-field>
      </mdc-dialog-content>
      <mdc-dialog-actions>
        <button mdcDialogButton type="button" mdcDialogAction="close">Hủy</button>
        <button mdcDialogButton type="submit" [disabled]="!form.valid">Đồng ý</button>
      </mdc-dialog-actions>
    </mdc-dialog-surface>
  </form>
</mdc-dialog>
