export function nameDescent(item){
  const namDescent = item.ancestors.map(it => it.name);
  // console.log('name_descent', name_descent);
  if (namDescent.length > 0) {
    item.display_name = namDescent.join(' > ') + ' > ' + item.name;
  } else {
    item.display_name = item.name;
  }
  return item;
}
export const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
export const since14DaysBefore = new Date(new Date().setDate(new Date().getDate() - 14));
