import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { GlnActions } from '../actions';


export const glnListLocationFeatureKey = 'glnListLocation';

export interface State {
  listNation: any;
  listCity: any;
  listDistrict: any;
  listWard: any;
}

export const initialState: State = {
  listNation: [],
  listCity: [],
  listDistrict: [],
  listWard: []
};

export const glnListLocationReducer = createReducer(
  initialState,
  on(
    GlnActions.loadNation,
    (state, { request }) => {
      return {
        ...state,
      }
    }
  ),
  on(
    GlnActions.loadNationSuccess,
    (state, { rows }) => {
      return {
        ...state,
        listNation: rows,
      }
    }
  ),
  on(
    GlnActions.loadDistrictSuccess,
    (state, { rows }) => {
      return {
        ...state,
        listDistrict: rows,
      }
    }
  ),
  on(
    GlnActions.loadCitySuccess,
    (state, { rows }) => {
      return {
        ...state,
        listCity: rows,
      }
    }
  ),
  on(
    GlnActions.loadWardSuccess,
    (state, { rows }) => {
      return {
        ...state,
        listWard: rows,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return glnListLocationReducer(state, action);
}


export const getListNation = (state: State) => state.listNation;
export const getListCity = (state: State) => state.listCity;
export const getListDistrict = (state: State) => state.listDistrict;

export const getListWard = (state: State) => state.listWard;
