
<div class="content">
  <div class="image-icheck"></div>
  <div class="login"> 
    <form class="text-center border border-light p-5 form-login" [formGroup]="loginForm">
      <p class="h4 mb-4 title-sign">Đăng nhập</p>
        <input type="email" id="defaultLoginFormEmail" class="form-control input-form mb-4" placeholder="Số điện thoại" formControlName="username">
        <div class="div-pass">
           <input type="password" id="defaultLoginFormPassword" class="form-control input-form mb-4" placeholder="Mật khẩu" formControlName="password" #inputPassword>
          <mat-icon matSuffix *ngIf="!isShowPassword" (click)="toggleShow()" class="icon-show-pass">visibility</mat-icon>
          <mat-icon matSuffix *ngIf="isShowPassword" (click)="toggleShow()" class="icon-hidden-pass">visibility_off</mat-icon>
        </div>
      <mat-error *ngIf="messageError">{{messageError}}</mat-error>
      <div class="d-flex justify-content-around div-forgot">
        <div>
          <mat-checkbox class="checkbox-login">Duy trì đăng nhập</mat-checkbox>
        </div>
        <div>
          <a href="" class="forgot-pass" [routerLink]="['/auth/forgot-password']">Quên mật khẩu?</a>
        </div>
      </div>
      <button mat-raised-button mdbBtn color="info" block="true" class="my-4 btn-login" type="submit" (click)="login()" [disabled]="!loginForm.valid">Đăng nhập</button>
      <!-- <button mat-raised-button type="submit" class="my-4 btn-login" [disabled]="!loginForm.valid">Đăng nhập</button> -->
    </form>
  </div>
</div>
