import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import {ErrorLogDto} from '@src/app/shared/common/error-log.dto';

const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};
declare function errorTeko(error);
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private baseUrl = environment.baseCoreUrl;
  constructor(public http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(body: any) {
    return this.http.post<any>(`${this.baseUrl}/login`, body)
      .pipe(map(user => {
        console.log(user);
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      },    tap( // Log the result or error
        data => console.log('data'),
        error => console.log(error)
      )),  catchError((error: HttpErrorResponse) => {
        const erro = new ErrorLogDto(error, `${this.baseUrl}/login`, body , error);
        errorTeko(erro);
        return throwError(error);
      }));
  }

  logout() {
    if (this.currentUserValue != null) {
      // tslint:disable-next-line:variable-name
      const headerObject = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.currentUserValue.token
      });

      localStorage.removeItem('currentUser');
      // localStorage.setItem('action', 'logout');
      this.currentUserSubject.next(null);
      this.router.navigate(['auth/login']);
      // window.location.reload();
      return this.http.get<any>(`${this.baseUrl}/users/logout`, { headers : headerObject});
    } else {
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['auth/login']);
      // window.location.reload();
    }
  }

}
