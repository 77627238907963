import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnSelectorComponent } from './column-selector.component';
import { MaterialModule } from '../../shared/material.module';




@NgModule({
  declarations: [ColumnSelectorComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [ColumnSelectorComponent]
})
export class ColumnSelectorModule { }
