import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Category } from '../models/category.model';
import { convertObjectToQueryParams, isEmpty } from '../../shared/common';
import { environment } from 'src/environments/environment';
import { of, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {BaseService} from '@src/app/services/base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};

@Injectable({
  providedIn: "root"
})
export class CategoryService extends BaseService{


  constructor(public http: HttpClient) {
    super(http)
  }

  getListCategory(object) {
    // console.log(object);

    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.get(`/category/children?${objectPage}&${objectFilter}`);
  }

  getDetailCategory(id) {
    return this.get(`/category/${id}`);
  }

  createCategory(category) {
    return this.post(`/category`, category);
  }

  updateCategory(category) {
    return this.put(`/category/${category.id}`, category);
  }

  deleteCategory(id) {
    return this.delete(`/category/${id}`);
  }

  getCategories(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.get(`/category?${objectPage}&${objectFilter}`);
  }

  searchCategory(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.get(`/category/search?${objectPage}&${objectFilter}`);
  }

  assignAttributeSet(object) {
    return this.post(`/category/assign-attribute-set`, object);
  }

  getDetailAttributeSetByCategory(category_id) {
    return this.get(`/attribute-set/detail?category_id=${category_id}`);
  }


}
