import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {AllowOrDenyContributeService} from '@products/components/product-button/allow-or-deny-contribute/service/allow-or-deny-contribute.service';
import {onlyUnique} from '@src/app/shared/common';
import {NotificationService} from '@src/app/services/notification.service';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AuthService} from '@src/app/auth/services/auth.service';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-update-contribute',
  templateUrl: './update-contribute.component.html',
  styleUrls: ['./update-contribute.component.scss']
})
export class UpdateContributeComponent extends ModalScroll implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public  data, public dialogRef: MatDialogRef<UpdateContributeComponent>,
              private formBuilder: FormBuilder,
              private allowOrDenyContributeService: AllowOrDenyContributeService,
              private notificationService: NotificationService,
              private authService: AuthService
  ) {
    super();
  }
  selectOption = [{key: true , value: 'Có'}, {key: false , value: 'Không'}];
  isLoading = false;
  ngOnInit(): void {
    enterScreenView(TekoValueDto.PRODUCT_UPDATE_STATUS_CONTRIBUTE.SCREEN_NAME, TekoValueDto.PRODUCT_UPDATE_STATUS_CONTRIBUTE.CONTENT_TYPE);
    this.formGroup = this.formBuilder.group({
      barcodes : ['', Validators.required ],
      accept : ['', Validators.required ] ,
      update_product_business: [false, Validators.required  ]
    });
    console.log(this.selectOption);
  }

  onFormSubmit() {
     const numberCount = this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique).length;
     const validBarcodes = this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique).join(',');
     const body = {... this.formGroup.value, barcodes: validBarcodes};
     this.isLoading = true;
     const click = new ClickTekoDto(TekoValueDto.PRODUCT_UPDATE_STATUS_CONTRIBUTE.REGION_NAME,
       TekoValueDto.PRODUCT_UPDATE_STATUS_CONTRIBUTE.CONTENT_NAME, TekoValueDto.PRODUCT_UPDATE_STATUS_CONTRIBUTE.TARGET, {
       totalBarcode: validBarcodes,
         status: this.formGroup.value.accept,
         userId: this.authService.currentUserValue.userId
       });
     clickTeko(click);
     this.allowOrDenyContributeService.updateProductContribute(body).subscribe(res => {
          console.log('res' , res);
          // @ts-ignore
          const failNumber = res.fails.length;
          if((numberCount - failNumber) > 0) {
            const alert = new AlertTekoDto(ALERT.SUCCESS , ALERT.SUCCESS);
            alertEvent(alert);
          }
          if(failNumber) {
            const alert = new AlertTekoDto(ALERT.FAILED , ALERT.FAILED );
            alertEvent(alert);
          }
          this.notificationService.getNotification(`Gán ${numberCount - failNumber} barcode thành công và ${failNumber} barcode thất bại`);
          this.dialogRef.close();
          this.isLoading = false;
      }, error => {
       this.notificationService.getNotification('Thất bại', 'Cancel' , 'fail');
     });
  }

  clearSelected(key) {
    this.formGroup.patchValue({
      [key]: null
    });
    this.formGroup.updateValueAndValidity();
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }
}
