import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertProductsModalComponent } from '../alert-products-modal/alert-products-modal.component';
import { Store, select } from '@ngrx/store';
import { AlertActions } from '@alert/actions';
import * as fromAlert from '../../../alert/reducers';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as fromGln from '@gln/reducers';
import { GlnListPageActions, GlnPageActions } from '@gln/actions';
import * as fromProducts from '@products/reducers';
import { ProductActions } from '@products/actions';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';

@Component({
  selector: 'ic-gln-modal',
  templateUrl: './gln-modal.component.html',
  styleUrls: ['./gln-modal.component.scss']
})
export class GlnModalComponent extends ModalScroll implements OnInit {

  glnForm: any;
  data: any;
  glns$: Observable<any>;
  searchChangeObserver;
  productCurrent$: Observable<any>;
  currentProduct;

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<AlertProductsModalComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private storeProduct: Store<fromProducts.State>,
              private storeGln: Store<fromGln.State>,
    ) {
    super();
    this.data = data;
    this.glns$ = storeGln.pipe(select(fromGln.selectGlnListPageResults));
    this.productCurrent$ = storeProduct.pipe(select(fromProducts.selectProductCurrent));
    }

  ngOnInit(): void {
    // console.log(this.data.element);
    this.productCurrent$.subscribe(it => {
      this.currentProduct = it;
    });

    this.glnForm = this.formBuilder.group({
      enterpriseId: ['', Validators.required],
    });
    this.getListGln({ page: 1, pageSize: 50});
  }


  getListGln(object) {
    this.storeGln.dispatch(GlnListPageActions.loadGlns({
      page: object.page,
      pageSize: object.pageSize,
      filter: object.filter ? object.filter : {}
    }));

  }

  changeInputSearch(event) {
    this.getListGln({
      page: 1, pageSize: 50, filter: { name : event.target.value }
    });
    // if (!this.searchChangeObserver) {
    //   Observable.create(observer => {
    //     // console.log(observer);

    //     this.searchChangeObserver = observer;
    //   }).pipe(debounceTime(500)) // wait 300ms after the last event before emitting last event
    //     .pipe(distinctUntilChanged()) // only emit if value is different from previous value
    //     .subscribe(it => {
    //       // console.log(it)
    //         // this.storeAlert.dispatch(
    //         //   AlertAction.loadAction({
    //         //     filter: {name: it, offset: 0, limit: 20},
    //         //   })
    //         // );

    //     });
    // }
    // this.searchChangeObserver.next(event.target.value);
  }


  closeDialogClick() {
    this.dialogRef.close('ok');
  }

  onFormSubmit() {
    // console.log(this.currentProduct);

    // console.log(this.glnForm.value)
    const productObject = {
      ...this.glnForm.value,
      barcodes: [this.currentProduct.barcode]
    };
    // console.log(productObject);

    this.storeProduct.dispatch(ProductActions.assignEnterprise(productObject));
    this.closeDialogClick();
  }

}
