
import {
    createSelector,
    createFeatureSelector,
    combineReducers,
    Action,
    ActionReducerMap,
  } from '@ngrx/store';
import * as fromGln from './gln.reducer';
import * as fromGlnList from './gln-list.reducer';
import * as fromGlnListLocation from './location-list.reducer';
import * as fromGlnListPage from './gln-list-page.reducer';
import * as fromGlnPage from './gln-page.reducer';

import * as fromRoot from '../../reducers';

export const glnFeatureKey = 'gln';

export interface GlnsState {
    [fromGln.glnsFeatureKey]: fromGln.State;
    [fromGlnList.glnListFeatureKey]: fromGlnList.State;
    [fromGlnListLocation.glnListLocationFeatureKey]: fromGlnListLocation.State;
    [fromGlnListPage.glnListPageFeatureKey]: fromGlnListPage.State;
    [fromGlnPage.glnPageFeatureKey]: fromGlnPage.State;
  }

export interface State extends fromRoot.State {
    [glnFeatureKey]: GlnsState;
  }

export function reducers(state: GlnsState | undefined, action: Action) {
    return combineReducers({
      [fromGln.glnsFeatureKey]: fromGln.reducer,
      [fromGlnList.glnListFeatureKey]: fromGlnList.reducer,
      [fromGlnListLocation.glnListLocationFeatureKey] : fromGlnListLocation.reducer,
      [fromGlnListPage.glnListPageFeatureKey] : fromGlnListPage.reducer,
    [fromGlnPage.glnPageFeatureKey] : fromGlnPage.reducer,
    })(state, action);
  }
export const selectGlnState = createFeatureSelector<State, GlnsState>(
    glnFeatureKey
  );
export const selectGlnEntitiesState = createSelector(
    selectGlnState,
    state => state.glns
  );
export const {
    selectIds: selectGlnIds,
    selectEntities: selectGlnEntities,
    selectAll: selectAllGlns,
    selectTotal: selectTotalGlns,
  } = fromGln.adapter.getSelectors(selectGlnEntitiesState);

export const selectGlnListState = createSelector(
    selectGlnState,
    state => state.glnList
  );

export const selectGlnListLocationState = createSelector(
    selectGlnState,
    state => state.glnListLocation
  );

export const selectGlnListNation = createSelector(
    selectGlnListLocationState,
    fromGlnListLocation.getListNation
  );

export const selectGlnListCity = createSelector(
    selectGlnListLocationState,
    fromGlnListLocation.getListCity
  );
export const selectGlnListDistrict = createSelector(
    selectGlnListLocationState,
    fromGlnListLocation.getListDistrict
  );

export const selectGlnListWard = createSelector(
    selectGlnListLocationState,
    fromGlnListLocation.getListWard
  );

export const selectGlnListIds = createSelector(
    selectGlnListState,
    fromGlnList.getIds
  );

export const selectTotalGln = createSelector(
    selectGlnListState,
    fromGlnList.getTotalGln
  );

export const selectLoadingDataGln = createSelector(
    selectGlnListState,
    fromGlnList.getLoadingData
  );

export const selectObjectFilterGln = createSelector(
    selectGlnListState,
    fromGlnList.getObjectFilter
  );

export const selectSuccess = createSelector(
    selectGlnListState,
    fromGlnList.getSuccess
  );

export const selectGlnListResults = createSelector(
    selectGlnEntities,
    selectGlnListIds,
    (glns, ids) => {
      return ids
        .map(id => glns[id])
        .filter((gln): gln is any => gln != null);
    }
  );


// Gln page
export const selectGlnPageState = createSelector(
  selectGlnState,
  state => state.glnPage
);

export const selectGlnPageFilter = createSelector(
  selectGlnPageState,
  fromGlnPage.getFilter
);

export const selectGlnPageLimit = createSelector(
  selectGlnPageState,
  fromGlnPage.getLimit
);

export const selectGlnPageOffset = createSelector(
  selectGlnPageState,
  fromGlnPage.getOffset
);

export const selectGlnPageLoading = createSelector(
  selectGlnPageState,
  fromGlnPage.getLoading
);

export const selectGlnPageTotal = createSelector(
  selectGlnPageState,
  fromGlnPage.getTotal
);

export const selectGlnPageIds = createSelector(
  selectGlnPageState,
  fromGlnPage.getIds
);

export const selectGlnPageDisplayedColumns = createSelector(
  selectGlnPageState,
  fromGlnPage.getDisplayedColumns
);

export const selectCurrentGln = createSelector(
  selectGlnPageState,
  fromGlnPage.getCurrentGln
);


export const selectGlnPageResults = createSelector(
  selectGlnEntities,
  selectGlnPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// Gln page
export const selectGlnListPageState = createSelector(
  selectGlnState,
  state => state.glnListPage
);

export const selectGlnListPageFilter = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getFilter
);

export const selectPage = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getPage
);

export const selectPageSize = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getPageSize
);

export const selectGlnListPageLoading = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getLoading
);

export const selectGlnListPageTotal = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getTotal
);

export const selectGlnListPageIds = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getIds
);

export const selectGlnListPageDisplayedColumns = createSelector(
  selectGlnListPageState,
  fromGlnListPage.getDisplayedColumns
);

export const selectGlnListPageResults = createSelector(
  selectGlnEntities,
  selectGlnListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);
