import { from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { convertObjectToQueryParams } from 'src/app/shared/common';
import { environment } from '@src/environments/environment';
import {BaseService} from '@src/app/services/base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class AlertService extends BaseService{

  constructor(public http: HttpClient) {
    super(http)
  }

  getListAlert(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    // console.log(objectPage);
    // console.log(objectFilter);
    return this.get(`/alert?${objectPage}&${objectFilter}`);
  }

  getDetailAlert(id) {
    return this.get(`/alert/${id}`);
  }

  createAlert(alert) {
    return this.post(`/alert`, alert);
  }

  updateAlert(alert) {
    return this.put(`/alert/${alert.id}`, alert);
  }

  deleteAlert(id) {
    return this.delete(`/alert/${id}`);
  }


  addAlertForProduct(object) {
    return this.post(`/alert-product`, object);
  }

  removeAlertForProduct(object) {
    // console.log(object);
    const objectPage = convertObjectToQueryParams(object);
    return this.delete(`/alert-product?${objectPage}`);
  }







  // getAlertList(filter) {
  //   return from(this.datahub.listAlert(filter));
  // }

  // addAlert(alert) {
  //   return from(this.datahub.addAlert(alert))
  // }


  // deleteAlert(id) {
  //   return from(this.datahub.deleteAlert(id))
  // }

  // getAlertById(id) {
  //   return from(this.datahub.detailsAlert(id)).pipe(
  //     map(({ alert }) => {
  //       const data = {
  //         title: alert.getTitle() ? alert.getTitle() : '',
  //         description: alert.getDescription() ? alert.getDescription() : '',
  //         content: alert.getContent() ? alert.getContent() : '',
  //         created_at: alert.getCreatedAt() ? alert.getCreatedAt() : '',
  //         updated_at: alert.getUpdatedAt() ? alert.getUpdatedAt() : '',

  //       }
  //       // console.log(data);

  //       return data;
  //     }),
  //   );
  // }


  // addAlertForProduct(object) {
  //   return from(this.datahub.addAlertForProduct(object))
  // }

  // removeAlertForProduct(object) {
  //   return from(this.datahub.removeAlertForProduct(object))
  // }

  // updateAlert(object) {
  //   return from(this.datahub.updateAlert(object))
  // }

  // getAlertListProduct() {
  //   return from(this.datahub.listAlertProduct());
  // }
}
