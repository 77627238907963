
<div>
  <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" class="form-control">
    <mat-toolbar class="header-dialog">
      <mat-icon class="icon-add">add_circle</mat-icon>
      <label class="header-text">{{data.label}}</label>
    </mat-toolbar>
    <div class="content-modal">
      <div class="form-group">
        <div class="label-form">Nhập danh sách mã vạch (*) </div>
        <mat-form-field appearance="outline" class="form-field">
          <textarea matInput formControlName="barcodes" (keydown.space)="$event.preventDefault()" (keyup)="checkBarcode()"></textarea>
        </mat-form-field>
        <div class="comment-text">(Mỗi dòng là một barcode)</div>
        <div style="height: 40px; margin-bottom: 0.5rem;">
          <button mat-raised-button class="primary btn-delete-barcode" type="button" (click)="clearListBarCode()">Xóa mã lỗi</button>
        </div>
        <div *ngIf="formGroup.value.barcodes" style="margin-bottom: 1rem;">
          <div class="alertDangerQ" *ngIf="check_barcode.length > 0">
            <h3>Dữ liệu trên có chứa các mã vạch không đúng định dạng</h3>
            <p *ngFor="let item of check_barcode" style="margin-bottom: 5px;">(Dòng {{item.index}}) {{item.value}}</p>
          </div>
        </div>
        <div class="form-group">
          <div class="label-form">Chọn trạng thái đóng góp sản phẩm (*)</div>
          <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
            <mat-select formControlName="accept" placeholder="Chọn trạng thái đóng góp sản phẩm">
              <mat-option *ngFor="let select of selectOption" [value]="select.key">
                {{select.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div style="margin-top: 1rem;">
        <mat-checkbox [checked]="false" formControlName="update_product_business">Áp dụng bao gồm các sản phẩm đã được quản lý bởi Business</mat-checkbox>
      </div>
    </div>
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!formGroup.valid"><mat-icon  *ngIf="isLoading"><mat-spinner  class="mat-spinner-color" diameter="20">
      </mat-spinner></mat-icon>Cập nhật</button>
    </mat-toolbar>
