<!-- <mdc-drawer-header title="Admin"></mdc-drawer-header> -->
<mdc-drawer-content>
  <mdc-list-group *ngFor="let group of groups">
    <mdc-list>
      <mdc-list-item *ngFor="let item of group.items" [routerLink]="item.url" routerLinkActive #rla="routerLinkActive" [activated]="rla.isActive">
        <!-- <mdc-icon mdcListItemGraphic>{{item.icon}}</mdc-icon> -->
        <!-- <img *ngIf="!rla.isActive" [src]="item.backgroudImage" class="image">
        <img *ngIf="rla.isActive" [src]="item.backgroudImageActive" class="image"> -->
        <div [class]="item.class" class="image"></div>
        {{item.title}}
      </mdc-list-item>
    </mdc-list>
    <hr mdcListDivider>
  </mdc-list-group>
</mdc-drawer-content>
