import { MatExpansionModule } from '@angular/material/expansion';
import { ProductAttributeGroupContainer } from './containers/produc-attribute-group.container';
import { DataTableToolbarsModule } from './../dash/data-table-toolbars/data-table-toolbars.module';
import { MaterialModule } from './../material.module';
import { DashCheckboxModule } from './../dash/checkbox/checkbox.module';
import { FeatureBarModule } from './../dash/feature-bar/feature-bar.module';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import {MatTreeModule} from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductAttributeGroupRoutingModule } from './product-attribute-group-routing.module';
import { ProductAttributeGroupListComponent } from './components/product-attribute-group-list/product-attribute-group-list.component';
import { ProductAttributeGroupCreateComponent } from './components/product-attribute-group-create/product-attribute-group-create.component';
import { ProductAttributeGroupUpdateComponent } from './components/product-attribute-group-update/product-attribute-group-update.component';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { ProductAttributeEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { AlertDeleteAttributeSetComponent } from './components/alert-delete-attribute-set/alert-delete-attribute-set.component';
import {
  MdcDialogModule
} from '@angular-mdc/web';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

@NgModule({
  declarations: [ProductAttributeGroupListComponent, ProductAttributeGroupContainer, ProductAttributeGroupCreateComponent, ProductAttributeGroupUpdateComponent, AlertDeleteAttributeSetComponent],
  imports: [
    CommonModule,
    ProductAttributeGroupRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // MaterialModule,
    MatTableModule,
    DragDropModule,
    MatTreeModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatSelectModule,
    MatPaginatorModule,
    MdcDialogModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    DashFilterBarModule,
    StoreModule.forFeature(fromEffects.productAttributeFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([ProductAttributeEffects]),
    FeatureBarModule,
    DashFilterBarModule,
    DashCheckboxModule,
    MaterialModule,
    MatExpansionModule,
    MatSelectInfiniteScrollModule,
    DataTableToolbarsModule
  ]
})
export class ProductAttributeGroupModule { }
