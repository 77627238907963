import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ic-number-range-display',
  templateUrl: './number-range-display.component.html',
  styleUrls: ['./number-range-display.component.scss']
})
export class NumberRangeDisplayComponent implements OnInit {

  @Input() data:any;
  constructor() { }

  ngOnInit(): void {
  }

}
