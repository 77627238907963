<dash-feature-bar>
  <dash-feature-bar-row>
    <dash-feature-bar-section>
      <h1 dashFeatureBarTitle>Quản lý cảnh báo</h1>
    </dash-feature-bar-section>
  </dash-feature-bar-row>
</dash-feature-bar>
<ic-filter-bar [availableFilters]="availableFilters"  (filterChange)="changeFilter($event)"></ic-filter-bar>
              <div style="margin: 24px auto; max-width: 90%;">
                <mdc-card class="demo-card">
                  <div class="ic-data-table-toolbar">
                    <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
                      <button mdc-button class="button-table" raised (click)="createAlert()">Thêm mới</button>
                    </div>
                    <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
                      <button mdc-button>
                        Columns
                      </button>
                    </div>
                  </div>
                  <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
                    (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
                    <table mdcDataTableTable>
                      <thead>
                        <tr mdcDataTableHeaderRow >
                          <!-- <th mdcDataTableHeaderCell name="select">
                            <dash-checkbox (change)="$event ? masterToggle() : null"
                                           [checked]="selection.hasValue() && isAllSelected()"
                                           [indeterminate]="selection.hasValue() && !isAllSelected()"
                                           [aria-label]="checkboxLabel()">
                            </dash-checkbox>
                          </th> -->
                          <th mdcDataTableHeaderCell name="order">ID</th>
                          <th mdcDataTableHeaderCell name="title">Tiêu đề</th>
                          <th mdcDataTableHeaderCell name="content">Mô tả</th>
                          <th mdcDataTableHeaderCell name="created_at">Thời gian tạo</th>
                          <th mdcDataTableHeaderCell name="updated_at">Thời gian cập nhật gần nhất</th>
                          <th mdcDataTableHeaderCell name="action">Hành động</th>
                        </tr>
                      </thead>
                      <tbody mdcDataTableContent>
                        <tr mdcDataTableRow *ngFor="let row of rows; let i = index" [selected]="selection.isSelected(row)">
                          <!-- <td mdcDataTableCell name="select">
                            <dash-checkbox (change)="$event ? selection.toggle(row) : null"
                                           [checked]="selection.isSelected(row)"
                                           [aria-label]="checkboxLabel(row)">
                            </dash-checkbox>
                          </td> -->
                          <td mdcDataTableCell name="order">{{row.id}}</td>
                          <td mdcDataTableCell name="title" [title]="row.title">
                            <span class="text-break">{{row.title}}</span>
                          </td>
                          <td mdcDataTableCell name="content"  [title]="row.description">
                            <span class="text-break">{{row.description}}</span>
                          </td>
                          <td mdcDataTableCell  name="created_at"  [title]="row.createdAt">{{row.createdAt}}</td>
                          <td mdcDataTableCell  name="updated_at"  [title]="row.updateAt">{{row.updatedAt}}</td>
                          <td mdcDataTableCell name="action">
                            <button (click)="updateAlert(row)" mdcIconButton class="btn-edit">
                              <mdc-icon>edit</mdc-icon>
                            </button>
                            <button mdcIconButton (click)="deleteAlert(row.id)" class="btn-delete">
                              <mdc-icon>delete</mdc-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </mdc-data-table>
                  <mat-paginator showFirstLastButtons
                                 [length]="total"
                                 [pageSize]="pageSize"
                                 (page)="changePage($event)"
                  ></mat-paginator>
                </mdc-card>
              </div>
