
import {
  createSelector,
  createFeatureSelector,
  combineReducers,
  Action,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromProduct from './product.reducers';
import * as fromProductPage from './product-page.reducer';
import * as fromProductListPage from './product-list-page.reducer';
import * as fromProductList from './product-list.reducer';
import { Product } from '../models/product.model';

import * as fromRoot from '../../reducers';

export const productsFeatureKey = 'products';

export interface ProductsState {
  [fromProduct.productsFeatureKey]: fromProduct.State;
  [fromProductPage.productPageFeatureKey]: fromProductPage.State;
  [fromProductList.productListFeatureKey]: fromProductList.State;
  [fromProductListPage.productListPageFeatureKey]: fromProductListPage.State;
}

export interface State extends fromRoot.State {
  [productsFeatureKey]: ProductsState;
}

export function reducers(state: ProductsState | undefined, action: Action) {
  return combineReducers({
    [fromProduct.productsFeatureKey]: fromProduct.reducer,
    [fromProductPage.productPageFeatureKey]: fromProductPage.reducer,
    [fromProductList.productListFeatureKey]: fromProductList.reducer,
    [fromProductListPage.productListPageFeatureKey]: fromProductListPage.reducer,
  })(state, action);
}

export const selectProductsState = createFeatureSelector<State, ProductsState>(
  productsFeatureKey
);

export const selectProductEntitiesState = createSelector(
  selectProductsState,
  state => state.products
);

export const {
  selectIds: selectProductIds,
  selectEntities: selectProductEntities,
  selectAll: selectAllProducts,
  selectTotal: selectTotalProducts,
} = fromProduct.adapter.getSelectors(selectProductEntitiesState);


// Product page
export const selectProductPageState = createSelector(
  selectProductsState,
  state => state.productPage
);

export const selectProductPageFilter = createSelector(
  selectProductPageState,
  fromProductPage.getFilter
);

export const selectProductCurrent = createSelector(
  selectProductPageState,
  fromProductPage.getProductCurrent
);

export const selectProductPageLimit = createSelector(
  selectProductPageState,
  fromProductPage.getLimit
);

export const selectProductPageOffset = createSelector(
  selectProductPageState,
  fromProductPage.getOffset
);

export const selectProductPageLoading = createSelector(
  selectProductPageState,
  fromProductPage.getLoading
);

export const selectProductPageTotal = createSelector(
  selectProductPageState,
  fromProductPage.getTotal
);

export const selectProductPageIds = createSelector(
  selectProductPageState,
  fromProductPage.getIds
);

export const selectProductPageDisplayedColumns = createSelector(
  selectProductPageState,
  fromProductPage.getDisplayedColumns
);


export const selectTotal= createSelector(
  selectProductPageState,
  fromProductPage.getTotal
);

export const selectLoadingData= createSelector(
  selectProductPageState,
  fromProductPage.getLoading
);

export const selectObjectFilter= createSelector(
  selectProductPageState,
  fromProductPage.getFilter
);

export const selectIsExistBarCode= createSelector(
  selectProductPageState,
  fromProductPage.getBarCodeExist
);


export const selectProductPageResults = createSelector(
  selectProductEntities,
  selectProductPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// Product page
export const selectProductListPageState = createSelector(
  selectProductsState,
  state => state.productListPage
);

export const selectProductListPageFilter = createSelector(
  selectProductListPageState,
  fromProductListPage.getFilter
);

export const selectPage = createSelector(
  selectProductListPageState,
  fromProductListPage.getPage
);

export const selectPageSize = createSelector(
  selectProductListPageState,
  fromProductListPage.getPageSize
);

export const selectProductListPageLoading = createSelector(
  selectProductListPageState,
  fromProductListPage.getLoading
);

export const selectProductListPageTotal = createSelector(
  selectProductListPageState,
  fromProductListPage.getTotal
);

export const selectProductListPageIds = createSelector(
  selectProductListPageState,
  fromProductListPage.getIds
);

export const selectProductListPageDisplayedColumns = createSelector(
  selectProductListPageState,
  fromProductListPage.getDisplayedColumns
);

export const selectProductListPageResults = createSelector(
  selectProductEntities,
  selectProductListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


