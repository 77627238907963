import { Component, OnInit, Inject } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';

@Component({
  selector: 'ic-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss']
})
export class AlertConfirmComponent implements OnInit {

  data : any
  constructor(public dialogRef: MdcDialogRef<AlertConfirmComponent>,
    @Inject(MDC_DIALOG_DATA) data) { 
      this.data = data;
    }

  ngOnInit(): void {
    // console.log(this.data);
    
  }

}
