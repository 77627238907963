<mat-tab-group animationDuration="0ms" class="mat-tab-group">
  <mat-tab [label]="label">
    <ng-template matTabContent>
      <ic-gln-modal-page></ic-gln-modal-page>
    </ng-template>
  </mat-tab>
  <!-- <mat-tab label="Test">
    <ic-tree-view></ic-tree-view>
  </mat-tab> -->
  <mat-tab label="Danh sách sản phẩm phân phối" *ngIf="isUpdate">
    <ng-template matTabContent>
      <ic-gln-list-product></ic-gln-list-product>
    </ng-template>
  </mat-tab>
  <mat-tab label="Danh sách sản phẩm sở hữu" *ngIf="isUpdate">
    <ng-template matTabContent>
      <ic-gln-list-product-owned></ic-gln-list-product-owned>
    </ng-template>
  </mat-tab>
  <mat-tab label="Quản lý giấy tờ" *ngIf="isUpdate">
    <ng-template matTabContent>
      <ic-gln-documnets></ic-gln-documnets>
    </ng-template>
  </mat-tab>
</mat-tab-group>
