import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import * as fromGln from '../../../gln/reducers';
import {
  GlnActions
} from '../../../gln/actions';

@Component({
  selector: 'ic-detail-paper-modal',
  templateUrl: './detail-paper-modal.component.html',
  styleUrls: ['./detail-paper-modal.component.scss']
})
export class DetailPaperModalComponent implements OnInit {

  paperForm : any;
  data: any;


  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DetailPaperModalComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.data = data;
    }

  ngOnInit(): void {
    // console.log(this.data.element);
    
    this.paperForm = this.formBuilder.group({
      file_upload: ['', Validators.required],
      
    })

  }

  onFormSubmit() {
    // console.log(this.paperForm.value)
    
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

}
