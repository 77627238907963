
import {
    createSelector,
    createFeatureSelector,
    combineReducers,
    Action,
    ActionReducerMap,
  } from '@ngrx/store';
  import * as fromAlert from '../reducers/alert.reducer';
  import * as fromAlertList from '../reducers/alert-list.reducer';
  import * as fromAlertListPage from '../reducers/alert-list-page.reducer';
  import * as fromAlertPage from '../reducers/alert-page.reducer';
  
  import * as fromRoot from '../../reducers';
  
  export const alertFeatureKey = 'alert';
  
  export interface AlertState {
    [fromAlert.alertFeatureKey]: fromAlert.State;
    [fromAlertList.alertListFeatureKey]: fromAlertList.State;
    [fromAlertListPage.alertListPageFeatureKey]: fromAlertListPage.State;
    [fromAlertPage.alertPageFeatureKey] : fromAlertPage.State;
  }
  
  export interface State extends fromRoot.State {
    [alertFeatureKey]: AlertState;
  }
  
  export function reducers(state: AlertState | undefined, action: Action) {
    return combineReducers({
      [fromAlert.alertFeatureKey]: fromAlert.reducer,
      [fromAlertList.alertListFeatureKey]: fromAlertList.reducer,
      [fromAlertListPage.alertListPageFeatureKey]: fromAlertListPage.reducer,
    [fromAlertPage.alertPageFeatureKey] : fromAlertPage.reducer,
    })(state, action);
  }
  
  export const selectAlertState = createFeatureSelector<State, AlertState>(
    alertFeatureKey
  );
  
  
  export const selectAlertEntitiesState = createSelector(
    selectAlertState,
    state => state.alerts
  );
  
  
  
  export const {
    selectIds: selectAlertIds,
    selectEntities: selectAlertEntities,
    selectAll: selectAllAlerts,
    selectTotal: selectTotalAlerts,
  } = fromAlert.adapter.getSelectors(selectAlertEntitiesState);
  
  
  export const selectAlertListState = createSelector(
    selectAlertState,
    state => state.alertList
  );
  
  export const selectAlertListIds = createSelector(
    selectAlertListState,
    fromAlertList.getIds
  );
  
  export const selectTotalAlert= createSelector(
    selectAlertListState,
    fromAlertList.getTotalAlert
  );
  
  export const selectLoadingDataAlert= createSelector(
    selectAlertListState,
    fromAlertList.getLoadingData
  );
  
  export const selectObjectFilterAlert= createSelector(
    selectAlertListState,
    fromAlertList.getObjectFilter
  );

  export const selectCurrentAlert= createSelector(
    selectAlertListState,
    fromAlertList.getCurrentAlert
  );
  
  export const selectAlertListResults = createSelector(
    selectAlertEntities,
    selectAlertListIds,
    (alerts, ids) => {
      return ids
        .map(id => alerts[id])
        .filter((alert): alert is any => alert != null);
    }
  );

  
// Alert page
export const selectAlertPageState = createSelector(
  selectAlertState,
  state => state.alertPage
);

export const selectAlertPageFilter = createSelector(
  selectAlertPageState,
  fromAlertPage.getFilter
);

export const selectPage = createSelector(
  selectAlertPageState,
  fromAlertPage.getPage
);

export const selectPageSize = createSelector(
  selectAlertPageState,
  fromAlertPage.getPageSize
);

export const selectAlertPageLoading = createSelector(
  selectAlertPageState,
  fromAlertPage.getLoading
);

export const selectAlertPageTotal = createSelector(
  selectAlertPageState,
  fromAlertPage.getTotal
);

export const selectAlertPageIds = createSelector(
  selectAlertPageState,
  fromAlertPage.getIds
);

export const selectAlertPageDisplayedColumns = createSelector(
  selectAlertPageState,
  fromAlertPage.getDisplayedColumns
);

export const selectListAttributeByAlert = createSelector(
  selectAlertPageState,
  fromAlertPage.getAttributeList
);

export const selectTreeAlert = createSelector(
  selectAlertPageState,
  fromAlertPage.getTreeAlert
);

export const selectDetailAlert = createSelector(
  selectAlertPageState,
  fromAlertPage.getDetailAlert
);

export const selectAlertPageResults = createSelector(
  selectAlertEntities,
  selectAlertPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);


// Alert list page
export const selectAlertListPageState = createSelector(
  selectAlertState,
  state => state.alertListPage
);

export const selectAlertListPageFilter = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getFilter
);

export const selectPageListPage = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getPage
);

export const selectPageSizeListPage = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getPageSize
);

export const selectAlertListPageLoading = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getLoading
);

export const selectAlertListPageTotal = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getTotal
);

export const selectAlertListPageIds = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getIds
);

export const selectAlertListPageDisplayedColumns = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getDisplayedColumns
);

export const selectAlertParentId = createSelector(
  selectAlertListPageState,
  fromAlertListPage.getAlertParentId
);

export const selectAlertListPageResults = createSelector(
  selectAlertEntities,
  selectAlertListPageIds,
  (rows, ids) => {
    return ids
      .map(id => rows[id])
      .filter((row): row is any => row != null);
  }
);
  