import {Component, OnInit, Inject, OnDestroy, AfterViewInit} from '@angular/core';

import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormArray, Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import * as fromProduct from '../../reducers';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlnListPageActions } from '@gln/actions';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fromGln from '@gln/reducers';
import { onlyUnique } from '@src/app/shared/common';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog } from '@angular-mdc/web';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-update-scan-status',
  templateUrl: './update-scan-status.component.html',
  styleUrls: ['./update-scan-status.component.scss']
})
export class UpdateScanStatusComponent extends ModalScroll implements OnInit , OnDestroy {

  listBarcode = [];

  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  currentUser: any;
  destroy$ = new Subject();
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateScanStatusComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeGln: Store<fromGln.State>,
    private store: Store<fromProduct.State>,
    private _snackBar: MatSnackBar,
    private productService: ProductService,
    private dialog2: MdcDialog
  ) {
    super();
    this.data = data;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    enterScreenView(TekoValueDto.PRODUCT_UPDATE_STATUS_SCAN.SCREEN_NAME, TekoValueDto.PRODUCT_UPDATE_STATUS_SCAN.CONTENT_TYPE);

    this.formGroup = this.formBuilder.group({
      status: ['', Validators.required],
      barcodes: ['', Validators.required],
      allowUpdate: [false],
      allowUpdateProduct: [false]
    });
    // console.log(this.data);
    if (this.data.selectedRows.length) {
      const barcodes = this.data.selectedRows.map(item => item.barcode).join('\n');
      this.formGroup.patchValue({
        barcodes
      });
      this.checkBarcode();
    }
  }

  onFormSubmit() {
    const object = {
      status: this.formGroup.value.status,
      barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique),
      updateBusinessManager: this.formGroup.value.allowUpdate ? 1 : 0,
    };
    this.isLoading = true;
    const click = new ClickTekoDto(TekoValueDto.PRODUCT_UPDATE_STATUS_SCAN.REGION_NAME,
      TekoValueDto.PRODUCT_UPDATE_STATUS_SCAN.CONTENT_NAME,
      TekoValueDto.PRODUCT_UPDATE_STATUS_SCAN.TARGET, {
      totalBarcode: object.barcodes,
      status: object.status,
      userId: this.currentUser.userId
    });
    clickTeko(click);
    this.productService.updateStatusProduct(object).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      if (res.code === 1104) {
        this.confirmActionSubmit(object);
      } else {
        const success = res.data.success;
        const fail = res.data.fail;
        if (success) {
          const alert = new AlertTekoDto(ALERT.SUCCESS , ALERT.SUCCESS);
          alertEvent(alert);
        }
        if (fail) {
          const alert = new AlertTekoDto(ALERT.FAILED , ALERT.FAILED );
          alertEvent(alert);
        }
        this.isLoading = false;
        this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close('accept');
      }
    }, err => {
      this.isLoading = false;
      this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
    });
  }

  confirmActionSubmit(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: this.formGroup.value.allowUpdateProduct ? 1 : 2
        };
        this.isLoading = true;
        this.productService.updateStatusProduct(object).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {

          const success = res.data.success;
          const fail = res.data.fail;
          if (success) {
            const alert = new AlertTekoDto('success' , 'success');
            alertEvent(alert);
          }
          if (fail) {
            const alert = new AlertTekoDto('fail' , 'fail');
            alertEvent(alert);
          }
          this.isLoading = false;
          this._snackBar.open(`Gán ${res.data.success} barcode thành công và ${res.data.fail} barcode thất bại`, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this.isLoading = false;
          this.dialogRef.close('close');
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        this.isLoading = false;
        // this.dialogRef.close('close');
      }
      console.log('The dialog was closed');
    });
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  changeInputSearch(event) {
    this.storeGln.dispatch(
      GlnListPageActions.loadGlns({
        filter: { name: event.target.value },
        page: 1,
        pageSize: 50,
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
