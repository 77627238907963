import {since14DaysBefore, yesterday} from '@src/app/shared/common/denial.helper';
import {
  ALLOW_PEOPLE_CONTRIBUTE,
  HAS_ATTRIBUTE_PRODUCT, HAS_OTHER_ATTRIBUTE_PRODUCT,
  LINK_GTIN_GLN,
  SCAN_STATE,
  VERIFY_PRODUCT
} from '@src/app/shared/FilterTable/filter-attribte.helper';

export const filterAttributeValue =  [
  {
    key: 'product_name_code',
    name: 'Tên hoặc mã sản phẩm',
    type: 'text',
    show: true,
  },
  {
    key: 'warning_content',
    name: 'Nội dung cảnh báo',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: '1',
          name: 'Đình chỉ lưu hành'
        },
        {
          name: '2',
          value: 'Thu hồi sản phẩm do k đạt chất lượng'
        }
      ]
    }
  },
  {
    key: 'created_at',
    name: 'Ngày tạo',
    type: 'daterange',
    show: true,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [yesterday, new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [since14DaysBefore, new Date()],
        }
      }
    }
  },
  {
    key: 'updated_at',
    name: 'Ngày cập nhật gần nhất',
    type: 'daterange',
    show: true,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [new Date(), new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [new Date(), new Date()],
        }
      }
    }
  },
  {
    key: 'username',
    name: 'Tên người dùng',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'phone',
    name: 'Số điện thoại',
    type: 'text',
    show: true,
  },
  {
    key: 'email',
    name: 'Email',
    type: 'text',
    show: true,
  },
  {
    key: 'enterprise_name_code',
    name: 'Tên hoặc mã GLN',
    type: 'text',
    show: true,
  },
  {
    key: 'has-enterprise-management',
    name: 'Trạng thái danh nghiệp quản lý',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có doanh nghiệp quản lý'
        },
        {
          name: 'Không có doanh nghiệp quản lý',
          value: 2
        }
      ]
    }
  },
  {
    key: 'name-program',
    name: 'Tên chương trình',
    type: 'text',
  },
  {
    key: 'type-game',
    name: 'Loại hình',
    type: 'select-box',
    settingComponentConfig: {
      options: [
        {
          value: 'receive_gift',
          name: 'Nhận quà'
        },
        {
          name: 'Mini game',
          value: 'mini_game'
        }
      ]
    }
  },
  {
    key: 'status-active',
    name: 'Trạng thái',
    type: 'select-box',
    settingComponentConfig: {
      options: [
        {
          value: 'active',
          name: 'Kích hoạt'
        },
        {
          value: 'waiting_payment',
          name: 'Chờ thanh toán'
        },
        {
          value: 'approved_donation',
          name: 'Duyệt tài trợ'
        },
        {
          value: 'refused_donation',
          name: 'Từ chối tài trợ'
        },
        {
          value: 'banned',
          name: 'Bị dừng'
        },
        {
          value: 'stopped',
          name: 'Đã dừng'
        },
      ]
    }
  },
  {
    key: 'date',
    name: 'Thời gian',
    type: 'daterange',
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [new Date(), new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [new Date(), new Date()],
        }
      }
    }
  },
  {
    key: 'has_enterprise',
    name: 'Trạng thái DNSH',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có doanh nghiệp sở hữu'
        },
        {
          value: 2,
          name: 'Không có doanh nghiệp sở hữu'
        }
      ]
    },
    showSaveFIlter: true
  },
  {
    key: 'has_image',
    name: 'Trạng thái ảnh',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có ảnh'
        },
        {
          name: 'Không có ảnh',
          value: 2
        }
      ]
    }
  },
  {
    key: 'has_name',
    name: 'Trạng thái tên',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có tên'
        },
        {
          value: 2,
          name: 'Không có tên'
        }
      ]
    }
  },
  {
    key: 'alert_title',
    name: 'Tiêu đề cảnh báo',
    type: 'text',
    show: true,
  },
  {
    key: 'name_code',
    name: 'Tên hoặc mã sản phẩm',
    type: 'text',
    show: true,
  },

  {
    key: 'has_price',
    name: 'Trạng thái giá',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có giá'
        },
        {
          value: 2,
          name: 'Không có giá'
        }
      ]
    }
  },
  {
    key: 'is_verify',
    name: 'Xác thực bởi',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: VERIFY_PRODUCT.BY_ADMIN,
          name: 'Xác thực bởi Admin'
        },

        {
          value: VERIFY_PRODUCT.BY_ADMIN_AND_BUSINESS,
          name: 'Xác thực bởi Admin và Business'
        },
        {
          value: VERIFY_PRODUCT.NOT_VERIFY,
          name: 'Chưa xác thực'
        }
      ]
    }
  },
  {
  key: 'has_attribute_info',
  name: 'Có thông tin sản phẩm',
  type: 'select-box',
  show: false,
  settingComponentConfig: {
  options: [
    {
      value: HAS_ATTRIBUTE_PRODUCT.HAS_PRODUCT,
      name: 'Có thông tin '
    },
    {
      value: HAS_ATTRIBUTE_PRODUCT.NOT_HAS_PRODUCT,
      name: 'Không có thông tin'
    },
  ]
}
},
  {
    key: 'has_other_attribute',
    name: 'Có thông tin thuộc tính khác',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: HAS_OTHER_ATTRIBUTE_PRODUCT.HAS_PRODUCT,
          name: 'Có'
        },
        {
          value: HAS_OTHER_ATTRIBUTE_PRODUCT.NOT_HAS_PRODUCT,
          name: 'Không'
        },
      ]
    }
  },
  {
    key: 'is_contribute',
    name: 'Cho phép người dùng đóng góp',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: ALLOW_PEOPLE_CONTRIBUTE.YES,
          name: 'Có'
        },
        {
          value: ALLOW_PEOPLE_CONTRIBUTE.NO,
          name: 'Không'
        },
      ]
    }
  },
  {
    key: 'scan_state',
    name: 'Trạng thái quét',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: SCAN_STATE.DO_SCAN,
          name: 'Cho quét'
        },
        {
          value: SCAN_STATE.NO_DATA,
          name: 'No data'
        },

        {
          value: SCAN_STATE.HIDDEN_BY_COOP,
          name: 'Ẩn bởi doanh nghiệp sở hữu'
        },
        {
          value: SCAN_STATE.DO_NOT_SCAN,
          name: 'Không cho quét'
        }
      ]
    }
  },
  {
    key: 'link_gtin_gln',
    name: 'Trạng thái liên kết GLN',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: LINK_GTIN_GLN.NOT_ASSIGN,
          name: 'Chưa gán gln'
        },
        {
          value: LINK_GTIN_GLN.ASSIGN,
          name: 'Duyệt'
        },
        {
          value: LINK_GTIN_GLN.PENDING,
          name: ' Chờ duyệt'
        },
        {
          value: LINK_GTIN_GLN.DENIAL,
          name: 'Hủy duyệt'
        },
      ]
    }
  },
  {
    key: 'verify_party',
    name: 'Trạng thái xác thực trên app',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 0,
          name: 'Chưa xác thực'
        },
        {
          value: 1,
          name: 'Đã xác thực'
        },
      ]
    }
  },
  {
    key: 'type_gln',
    name: 'Loại mã GLN',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 'gs1_official_code',
          name: 'Mã GS1 chính thống'
        },
        {
          value: 'gs1_unofficial_code',
          name: 'Mã GS1 không chính thống'
        },
        {
          value: 'icheck_internal_code',
          name: 'Mã nội bộ iCheck'
        },
        {
          value: 'enterprise_internal_code',
          name: 'Mã nội bộ doanh nghiệp'
        }
      ]
    }
  },
  {
    key: 'nationId',
    name: 'Quốc gia',
    type: 'select-nation',
    show: false,
    settingComponentConfig: {
      options: []
    }
  },

  {
    key: 'scan',
    name: 'Số lượt quét',
    type: 'number-range',
    show: false,
  },
  {
    key: 'review',
    name: 'Số lượt đánh giá',
    type: 'number-range',
    show: false,
  },
  {
    key: 'question_count',
    name: 'Số lượt hỏi đáp',
    type: 'number-range',
    show: false,
  },
  {
    key: 'price',
    name: 'Khoảng giá',
    type: 'number-range',
    show: false,
  },
  {
    key: 'has_manager',
    name: 'Trạng thái danh nghiệp quản lý',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có doanh nghiệp quản lý'
        },
        {
          name: 'Không có doanh nghiệp quản lý',
          value: 3
        }
      ]
    }
  },
  {
    key: 'has_category',
    name: 'Trạng thái danh mục',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Có danh mục'
        },
        {
          value: 2,
          name: 'Không có danh mục'
        }
      ]
    }
  },
  {
    key: 'category_id',
    name: 'Danh mục',
    type: 'select-search-box',
    show: false,
    settingComponentConfig: {
      options: []
    }
  },
  {
    key: 'create',
    name: 'Ngày tạo',
    type: 'daterange',
    show: false,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [yesterday, new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [since14DaysBefore, new Date()],
        }
      }
    }
  },
  {
    key: 'date_by_user',
    name: 'Thời gian update thông tin',
    type: 'daterange2',
    show: false,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [yesterday, new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [since14DaysBefore, new Date()],
        }
      }
    }
  },
  {
    key: 'name-paper',
    name: 'Tên giấy tờ',
    type: 'text',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'expire',
    name: 'Trạng thái hết hạn',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 3,
          name: 'Còn hạn'
        },
        {
          value: 1,
          name: 'Hết hạn'
        }
      ]
    }
  },
  {
    key: 'expire_at',
    name: 'Ngày hết hạn',
    type: 'daterange',
    show: true,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [yesterday, new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [since14DaysBefore, new Date()],
        }
      }
    }
  },
  {
    key: 'productId',
    name: 'Sản phẩm',
    type: 'product_id',
    show: true,
  },
  {
    key: 'enterpriseId',
    name: 'Doanh nghiệp',
    type: 'enterprise_id',
    show: true,
  },
  {
    key: 'name-company',
    name: 'Tên doanh nghiệp',
    type: 'text',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'searchType',
    name: 'Chọn loại search',
    type: 'select-search',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 2,
          name: 'Giấy tờ gán với sản phẩm'
        },
        {
          value: 1,
          name: 'Giấy tờ gán với NPP'
        },
        {
          value: 3,
          name: 'Giấy tờ gán với sản phẩm và NPP'
        },
        {
          value: 4,
          name: 'Giấy tờ chưa được gán'
        }
      ]
    }
  },
  {
    key: 'visible',
    name: 'Trạng thái ẩn hiện',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 3,
          name: 'Ẩn'
        },
        {
          value: 1,
          name: 'Hiện'
        }
      ]
    }
  },
  {
    key: 'tax',
    name: 'Mã số thuế',
    type: 'text',
    show: true,
  },
  {
    key: 'file_name',
    name: 'Tên file',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'created_by',
    name: 'Sđt người tạo',
    type: 'text',
    show: true,
    settingComponentConfig: {
      options: [
      ]
    }
  },
  {
    key: 'updated_by',
    name: 'Sđt người câp nhật',
    type: 'text',
    show: true,
    settingComponentConfig: {
      options: [
      ]
    }
  },
  {
    key: 'verified',
    name: 'Trạng thái xác thực',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Đã xác thực'
        },
        {
          value: 2,
          name: 'Chưa xác thực'
        }
      ]
    },
    showSaveFIlter: true
  },
  {
    key: 'update',
    name: 'Thời gian update gần nhất',
    type: 'daterange',
    show: true,
    settingComponentConfig: {
      predefinedRanges: {
        yesterday: {
          label: 'Ngày gần đây nhất',
          value: [new Date(), new Date()],
        },
        last7Days: {
          label: 'Từ 14 ngày trước',
          value: [new Date(), new Date()],
        }
      }
    }
  },
  {
    key: 'name-search-company',
    name: 'Tìm kiếm doanh nghiệp',
    type: 'text',
    show: true,
  },
  {
    key: 'gln_code',
    name: 'Tìm kiếm mã GLN',
    type: 'text',
    show: true,
  },
  {
    key: 'gln_type',
    name: 'Loại mã GLN',
    type: 'select-box',
    show: false,
    settingComponentConfig: {
      options: [
        {
          value: 'gs1_official_code',
          name: 'Mã GS1 chính thống'
        },
        {
          value: 'gs1_unofficial_code',
          name: 'Mã GS1 không chính thống'
        },
        {
          value: 'icheck_internal_code',
          name: 'Mã nội bộ iCheck'
        },
        {
          value: 'enterprise_internal_code',
          name: 'Mã nội bộ doanh nghiệp'
        }
      ]
    }
  },
  {
    key: 'national_id',
    name: 'Quốc gia',
    type: 'select-nation',
    show: false,
    settingComponentConfig: {
      options: []
    }
  },
  {
    key: 'name-title',
    name: 'Tên tiêu đề',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'type',
    name: 'Vai trò',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 3,
          name: 'Nhà phân phối'
        },
        {
          value: 4,
          name: 'Doanh nghiệp phân phối độc quyền'
        },
        {
          value: 2,
          name: 'Doanh nghiệp sản xuất'
        }
      ]
    }
  },
  {
    key: 'name-category',
    name: 'Tên danh mục',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'name-attribute',
    name: 'Tên thuộc tính',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'title',
    name: 'Tiêu đề cảnh báo',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'name-group-attribute',
    name: 'Tên bộ thuộc tính',
    type: 'text',
    show: true,
    showSaveFIlter: true
  },
  {
    key: 'name',
    name: 'Tên hoặc mã sản phẩm',
    type: 'text',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'not_gln_list',
    name: 'Bộ lọc loại trừ GLN',
    type: 'text',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'not_gln_list123123123',
    name: 'Tìm kiếm theo mã sản phẩm, tên sản phẩm',
    type: 'text',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'not_nationId_list',
    name: 'Bộ lọc loại trừ quốc gia',
    type: 'multiselect',
    show: true,
    showSaveFIlter: false
  },
  {
    key: 'status',
    name: 'Trạng thái xác thực',
    type: 'select-box',
    show: true,
    settingComponentConfig: {
      options: [
        {
          value: 1,
          name: 'Xác thực'
        },
        {
          value: 3,
          name: 'Chưa xác thực'
        }
      ]
    }
  },
];
