
<div>
  <form [formGroup]="createDocumentForm" (ngSubmit)="onFormSubmit()" class="form-control">
      <mat-toolbar class="header-dialog">
          <mat-icon class="icon-add">add_circle</mat-icon>
          <label class="header-text">{{data.label}}</label>
      </mat-toolbar>
      <div class="content-modal">
        <div class="form-group">
          <div class="label-form">Tìm kiếm nhà phân phối</div>
          <div class="div-search-enterprise">
            <mat-form-field appearance="outline">
              <mat-icon matSuffix class="icon-remove" *ngIf="this.createDocumentForm.value.enterpriseId" (click)="clearSelectedControl('enterpriseId')">cancel</mat-icon>
              <mat-select placeholder="Nhập tên nhà phân phối" #matSelectEnterprise formControlName="enterpriseId" (selectionChange)="changeSelected($event)" msInfiniteScroll (infiniteScroll)="lazyLoadEnterprise()">
                <mat-form-field appearance="outline" class="form-field-search">
                  <mat-icon matPrefix>search</mat-icon>
                  <input matInput
                      type="text"
                      name="filter-options"
                      id="filter-options"
                      placeholder="Search"
                      (keyup)= "changeInputDistributorSearch($event)"
                      (keydown)="$event.stopPropagation()"
                      formControlName="inputSearchDistributor">
                  <mat-icon matSurfix *ngIf="this.createDocumentForm.value.inputSearchDistributor" (click) = "clearInputDistributorSearch()">clear</mat-icon>
                </mat-form-field>
                <mat-option *ngFor="let enterprise of listEnterprise" [value]="enterprise.id" title="{{enterprise.name}} (Prefix: {{enterprise.prefixCode}}, GLN: {{enterprise.enterpriseCode}})">
                  {{enterprise.name}} (Prefix: {{enterprise.prefixCode}}, GLN: {{enterprise.glnCode}})
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mdc-button raised class="btn btn-create-enterprise" type="button" (click)="createEnterprise()">Thêm mới</button>
          </div>

        </div>

        <div *ngIf="currentEnterprise.id" style="margin-bottom: 1rem;">
          <div>{{currentEnterprise?.name}}</div>
          <div>Mã số thuế: {{currentEnterprise?.taxCode}}</div>
          <div>Địa chỉ: {{currentEnterprise?.address}} - <span *ngIf="currentEnterprise?.wardName">{{currentEnterprise?.wardName}} - </span><span *ngIf="currentEnterprise?.districtName">{{currentEnterprise?.districtName}} - </span><span *ngIf="currentEnterprise?.provinceName">{{currentEnterprise?.provinceName}} - </span>{{currentEnterprise?.nationalName}}</div>
          <div>Điện thoại: {{currentEnterprise?.phone}}</div>
          <div>Email: {{currentEnterprise?.email}}</div>
          <div>Website: {{currentEnterprise?.website}}</div>
        </div>
          <div class="form-group">
            <div class="label-form">Chọn vai trò (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="type" placeholder="Chọn vai trò" >
                <mat-option [value]="2">Doanh nghiệp sản xuất</mat-option>
                <mat-option [value]="4">Doanh nghiệp phân phối độc quyền</mat-option>
                <mat-option [value]="3">Nhà phân phối</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <div class="label-form">Chọn tiêu đề (*)</div>
            <mat-form-field appearance="outline" class="form-field" style=" margin-bottom: -10px">
              <mat-select formControlName="title" placeholder="Chọn tiêu đề" msInfiniteScroll (infiniteScroll)="lazyListTitleByType()">
                <mat-option [value]="tittle.id" *ngFor="let tittle of listTitle">{{tittle.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="distributor" *ngIf="currentEnterprise.id">
            <div class="form-group">
              <div class="label-form">Giấy tờ (*)</div>
              <button mdc-button raised class="btn btn-assign-documnet" type="button" (click)="assignDocumentFromPackage()">Chọn từ kho</button>
              <button mdc-button raised class="btn btn-create-new-document" type="button" (click)="createNewDocument()">Tạo mới</button>
            </div>
            <div *ngFor="let item of listDocument; index as i" class="div-file">
              <span class="file-label">{{item.name}}</span>
              <mat-icon class="icon-delete" (click) = "deleteDocument(i)">clear</mat-icon>
            </div>
          </div>
        </div>
      <mat-toolbar fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="padding: 16px;" class="footer-dialog">
      <button mat-button type="button" class="btn-cancel" (click)= "closeDialogClick()">Hủy</button>
      <button mat-raised-button type="submit" class="btn-stop" [disabled]="!createDocumentForm.valid">Thêm mới</button>
      </mat-toolbar>
  </form>
</div>
