import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { WarningProductActions } from '../actions';


export const warningProductsFeatureKey = 'warningProducts';

export interface State extends EntityState<any> {
  selectedWarningProductId: string | null;
  total: number;
  location: any;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (warningProduct: any) => {
    // console.log(warningProduct);
    
    return warningProduct.id;
  },
  // sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedWarningProductId: null,
  total: 0,
  location: []
});

export const warningProductReducer = createReducer(
  initialState,
  on(
    WarningProductActions.loadWarningProductsSuccess,
    (state, { rows }) => {
      // console.log(rows);
      return adapter.upsertMany(rows, state)
    }
  ),
  on(WarningProductActions.updateWarningProduct, (state, { warningProductObject }) => {
    // console.log(warningProductObject);
    
    return adapter.updateOne({ id: warningProductObject.id, changes: warningProductObject }, state);
  }),
  on(WarningProductActions.updateWarningProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(WarningProductActions.addWarningProduct, (state, { warningProductObject }) => {
    // console.log(warningProductObject);
    
    return adapter.addOne(warningProductObject , state);
  }),
  on(WarningProductActions.addWarningProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),

  on(WarningProductActions.updateListWarningProduct, (state, { warningProducts, warningProduct }) => {
    return adapter.upsertMany(warningProducts, state);
  }),
  on(WarningProductActions.updateListWarningProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(WarningProductActions.searchWarningProductSuccess, (state, { rows }) => {
    // console.log(warningProducts);
    
    return adapter.upsertMany(rows, state);
  }),
  on(WarningProductActions.searchWarningProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(WarningProductActions.deleteWarningProductSuccess, (state, { id }) => {
    // console.log(id);
    
    return adapter.removeOne(id, state);
  }),
  on(WarningProductActions.deleteWarningProductFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
);

export function reducer(state: State | undefined, action: Action) {
  return warningProductReducer(state, action);
}

export const getSelectedWarningProductId = (state: State) => state.selectedWarningProductId;

export const getLocation = (state: State) => state.location;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of user ids
export const selectWarningProductIds = selectIds;

// select the dictionary of user entities
export const selectWarningProductEntities = selectEntities;

// select the array of users
export const selectAllWarningProducts = selectAll;

// select the total user count
export const selectWarningProductTotal = selectTotal;
