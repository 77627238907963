
<ic-filter-bar [availableFilters]="availableFilters" (inputSearchChange) ="changeInputSeach($event)"></ic-filter-bar>
<!-- End Filter bar -->

<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button mdc-button raised class="btn" (click)="assignDocumentFromPackage()">Gán giấy tờ trong kho</button>
        <button mdc-button raised class="btn" (click)="create()">Tạo mới</button>
        <button mdc-button raised class="btn btn-hidden" (click)="hiddenDocument()" [disabled]="!this.selected.length">Ẩn </button>
        <button mdc-button raised class="btn btn-show" (click)="showDocument()" [disabled]="!this.selected.length">Hiện </button>
        <button mdc-button raised class="btn btn-remove" (click)="removeListDocument()" [disabled]="!this.selected.length">Xóa </button>
        <button mdc-button raised class="btn btn-verify" (click)="verifyProductDocument()" [disabled]="!this.selected.length">Xác thực </button>
        <button mdc-button raised class="btn btn-cancel-verify" (click)="cancelVerifyProductDocument()" [disabled]="!this.selected.length">Hủy xác thực </button>
        <!-- <button mdc-button raised class="button-table" [routerLink]="['create']">Thêm mới</button> -->
        <!-- <button mdc-button raised class="button-table" (click)="importExcel()">Import excel</button>
        <button mdc-button raised class="button-table" (click)="exportExcel()">Xuất excel</button> -->
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">
            <th mdcDataTableHeaderCell name="select">
              <dash-checkbox (change)="$event ? masterToggle() : null"
                             [checked]="isSelectedAll"
                             [indeterminate]="isIndeterminate">
              </dash-checkbox>
            </th>
            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="image">Tên giấy tờ</th>
            <th mdcDataTableHeaderCell name="name">File giấy tờ</th>
            <th mdcDataTableHeaderCell name="price">Ngày hết hạn</th>
            <th mdcDataTableHeaderCell name="owned_business">Trạng thái hết hạn</th>
            <th mdcDataTableHeaderCell name="docProductType">Loại giấy tờ sản phẩm</th>
            <th mdcDataTableHeaderCell name="business_management">Xác thực bởi</th>
            <th mdcDataTableHeaderCell name="scan_sattus">Trạng thái xác thực</th>
            <th mdcDataTableHeaderCell name="scan_sattus">Trạng thái ẩn/hiện</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Ngày tạo</th>
            <th mdcDataTableHeaderCell name="authentication_sattus">Ngày cập nhật</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)" >
            <td mdcDataTableCell name="select">
              <dash-checkbox (change)="$event ? toggleSelection(row) : null"
                             [checked]="checkSelected(row)"
                             [aria-label]="checkboxLabel(row)"
                             >
              </dash-checkbox>
            </td>
            <td mdcDataTableCell name="id" >{{row.documentId}}</td>
            <td mdcDataTableCell name="image" >
              <span class="text-break" [title]="row.title">{{row.title}}</span>
            </td>
            <td mdcDataTableCell name="name" >
              <div *ngFor="let item of row.values; index as i" class="div-file">
                <span class="file-label text-break" [title]="item.url" (click)="openNewTab(item.url)">{{item.url}}</span>
              </div>
            </td>
            <td mdcDataTableCell name="price" >
              {{row.expireAt}}
            </td>
            <td mdcDataTableCell name="owned_business" >
              <span *ngIf="row.expire === 3">Còn hạn</span>
              <span *ngIf="row.expire === 1">Hết hạn</span>
            </td>
            <td mdcDataTableCell name="docProductType">{{docProductType[row.docProductType]}}</td>
            <td mdcDataTableCell name="scan_sattus" >{{row.verifyBy?.join(',')}}</td>
            <td mdcDataTableCell name="scan_sattus" >
              <span *ngIf="row.status === 1" class="verified">Xác thực</span>
              <span *ngIf="row.status === 3" class="not-verified">Chưa xác thực</span>
            </td>
            <td mdcDataTableCell name="business_management" >
              <span *ngIf="row.visible === 3">Ẩn</span>
              <span *ngIf="row.visible === 1">Hiện</span>
            </td>
            <td mdcDataTableCell name="authentication_sattus" >{{row.createdAt}}</td>
            <td mdcDataTableCell name="authentication_sattus" >{{row.updatedAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)="updateDocument(row)">
                <mdc-icon class="btn-edit">edit</mdc-icon>
              </button>
              <button mdcIconButton (click) = "removeListDocument(row)">
                <mdc-icon class="btn-delete">delete</mdc-icon>
              </button>
              <button mdcIconButton (click) = "cancelVerifyProductDocument(row)" *ngIf="row.status === 1">
                <mat-icon class="btn-close">close</mat-icon>
              </button>
              <button mdcIconButton (click) = "verifyProductDocument(row)" *ngIf="row.status === 3">
                <mat-icon class="btn-check">check_circle</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
    ></mat-paginator>
  </mdc-card>
</div>
