import { AddCategoryComponent } from './components/add-category/add-category.component';
import { ProductAddGlnComponent } from './components/product-add-gln/product-add-gln.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductPageComponent, ProductCreatePageComponent, ProductDetailPageComponent } from './containers';
import { UpdateScanStatusComponent } from './components/update-scan-status/update-scan-status.component';
import { AssignWarningProductComponent } from './components/assign-warning-product/assign-warning-product.component';
import { RemoveInfoProductComponent } from './components/remove-info-product/remove-info-product.component';

const routes: Routes = [
  {
    path: '',
    component: ProductPageComponent,
  },
  {
    path: 'create',
    component: ProductDetailPageComponent,
  },
  {
    path: 'detail/:id',
    component: ProductDetailPageComponent,
  },
  {
    path: 'update/:id',
    component: ProductDetailPageComponent,
  },
  {
    path: 'add-gln',
    component: ProductAddGlnComponent,
  },
  {
    path: 'add-category',
    component: AddCategoryComponent,
  },
  {
    path: 'update-scan-status',
    component: UpdateScanStatusComponent,
  },
  {
    path: 'assign-warning',
    component: AssignWarningProductComponent,
  },
  {
    path: 'remove-info-product',
    component: RemoveInfoProductComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule { }
