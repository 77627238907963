import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { CategoryModalComponent } from '@category/components/category-modal/category-modal.component';
import { Observable, merge, of as observableOf, combineLatest, Subscription, Subject, timer } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '../../reducers';
import {
  CategoryActions,
  CategoryPageActions,
  CategoryListPageActions
} from '../../actions';
import { AssignProductModalComponent } from '../assign-product-modal/assign-product-modal.component';
import { AssignReviewModalComponent } from '../assign-review-modal/assign-review-modal.component';
import { CheckCategoryIdModalComponent } from '../check-category-id-modal/check-category-id-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isNull } from 'util';
import { MDCDataTableRowSelectionChangedEvent, MDCDataTable } from '@angular-mdc/web/data-table';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { MdcDialog } from '@angular-mdc/web';
import { FilterBarNewComponent } from '@src/app/shared/components/filter-bar/filter-bar.component';
import { CategoryService } from '@category/services/category.service';
import { AlertConfirmModalComponent } from '@alert/alert-confirm-modal/alert-confirm-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate, buildImageUrls } from '@src/app/shared/common';
import { DetailAttributeSetComponent } from '../detail-attribute-set/detail-attribute-set.component';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterCategory();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class CategoryListComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  pageSize: number;

  @Input()
  total: number;

  @Input()
  set rows(rows: any[]) {
    // this.selection.clear();
    this._rows = rows.map(item => {
      // console.log('itemmmmm', item);
      const newObject = Object.assign({}, item);
      if (newObject.image) {
        newObject.image = buildImageUrls(newObject.image).original;
        item = newObject
      }
      return item
      //
    });
  }
  get rows(): any[] {
    return this._rows;
  }
  private _rows: any[] = [];

  @Input()
  set displayedColumns(columns: string[]) {
    this._displayedColumns = columns;
  }
  _displayedColumns: string[];
  @Output()
  deleteItems = new EventEmitter<any[]>();

  @Output()
  load = new EventEmitter<any>();
  changesSubscription: Subscription;
  selection = new SelectionModel<any>(true, []);

  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  filter$: Observable<Object>;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;
  loading$: Observable<boolean>;
  length$: Observable<number>;
  parent_id$: Observable<number>;
  parent_id: number;
  categories$: Observable<any[]>;
  destroy$ = new Subject();
  menuHeader: any = [];
  selected = [];
  isSelectedAll = false;
  isIndeterminate = false;
  isFirstPage = true;
  filter: any;



  @ViewChild(FilterBarNewComponent, {static: true}) filterBar: FilterBarNewComponent;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MDCDataTable, {static: true}) table: MDCDataTable;


  constructor(
    private dialog: MatDialog,
    private dialog2: MdcDialog,
    private store: Store<fromCategory.State>,
    private _changeDetectorRef: ChangeDetectorRef,
    private categoryService: CategoryService,
    private _snackBar: MatSnackBar,
    @Inject('availableFilters') public availableFilters: [],
  ) {
      this.categories$ = store.pipe(select(fromCategory.selectCategoryListPageResults));
      this.filter$ = store.pipe(select(fromCategory.selectCategoryListPageFilter));
      this.page$ = store.pipe(select(fromCategory.selectPageListPage));
      this.pageSize$ = store.pipe(select(fromCategory.selectPageSizeListPage));
      this.parent_id$ = store.pipe(select(fromCategory.selectCategoryParentId));
    }

  ngOnInit(): void {
    this.selection.changed.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.selected = this.selection.selected
    });
    this.page$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.page = it;
    });
    this.pageSize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {

      this.pageSize = it
    });
    this.parent_id$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.parent_id = it
    });
    this.categories$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      if (item.length && !this.isFirstPage) {
        this.checkStatus(item);
      }
    })
  }

  ngAfterViewInit() {
    // console.log(this.filterBar);
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.filterBar.patchValueForm(item);
      this.filter = item
    })
    const filterChange = this.filterBar?.filterChange;
    const pageChange = merge(
      this.paginator.page,
      this.paginator.initialized
    );
    filterChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.paginator.pageIndex = 0;
      });
    merge(pageChange, filterChange)
      .pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
      .subscribe(() => {

        console.log();
        // if (this.parent_id) {
        let object = {
            ...this.filterBar.filterForm.value,
            create_to_date: this.filterBar.filterForm.value.create_to_date ? formatDate(this.filterBar.filterForm.value.create_to_date) : '',
            create_from_date: this.filterBar.filterForm.value.create_from_date ? formatDate(this.filterBar.filterForm.value.create_from_date) : '',
            update_to_date: this.filterBar.filterForm.value.update_to_date ? formatDate(this.filterBar.filterForm.value.update_to_date) : '',
            update_from_date: this.filterBar.filterForm.value.update_from_date ? formatDate(this.filterBar.filterForm.value.update_from_date) : '',
            id: this.parent_id
          };
        this.load.emit([
          this.paginator,
          object,
        ]);
      });
      this._changeDetectorRef.detectChanges();
  }
  breadCrumbMain() {
    console.log('menu');
    this.menuHeader = [];
    this.store.dispatch(CategoryListPageActions.loadListCategoryByParent({filter: {},pageSize: 10,  page: 1, id: ''}));
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  breadCrumb(menu, index) {
    console.log('sub breadCrumb');
    const currentMenuSelected = menu[index];
    this.store.dispatch(CategoryListPageActions.loadListCategoryByParent({filter: {},pageSize: 10,  page: 1, id: currentMenuSelected.id}));
    this.menuHeader.splice(index + 1, this.menuHeader.length - 1);
    this.isSelectedAll = false;
    this.isIndeterminate = false;
    console.log('222222222222');
  }

  getCategoryByParent(category) {
    // console.log(category);
    if (category) {
      this.filterBar.resetForm();
      this.menuHeader.push({ label: `${category.name} (cấp ${category.level})`, id: category.id });
      console.log('hasMultiMenuLabel');
      this.store.dispatch(CategoryListPageActions.loadListCategoryByParent({filter: {},pageSize: 10,  page: 1, id: category.id}));
      // this.paginator.firstPage();
    }
    this.checkStatus();
  }

  changePage(event: Event) {
    this.isFirstPage = false;
    // console.log(this.rows);
  }
  resetSelected() {
    this.selected = [];
    this.isSelectedAll = false;
    this.isIndeterminate = false;
  }

  checkStatus(rows?) {
    let res = [];
    if (!this.rows.length) {
      this.resetSelected();
      return;
    }
    if (rows?.length) {
      res = rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
      });
    } else {
      res = this.rows.filter(el => {
        return this.selected.map(item => item.id).indexOf(el.id) < 0;
        });
    }
    // console.log(rows);
    // console.log(res);
    // console.log(this.rows);
    if (!res.length) {
      this.isSelectedAll = true;
      this.isIndeterminate = false;
    } else {
      if (rows) {
        if (res.length < rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      } else {
        if (res.length < this.rows.length) {
          this.isIndeterminate = true;
          this.isSelectedAll = false;
        } else {
          this.isIndeterminate = false;
          this.isSelectedAll = false;
        }
      }
    }
  }
  checkSelected(row) {
   return  this.selected.some(item => item.id === row.id);

  }
  toggleSelection(row) {
    const index = this.selected.findIndex(item => item.id === row.id);
    if (index < 0) {
      this.selected.push(row)
    } else {
      this.selected = this.selected.filter(item => item.id !== row.id);
    }
    this.checkStatus();
  }

  masterToggle() {
    const res = this.rows.filter(el => {
      return this.selected.map(item => item.id).indexOf(el.id) < 0;
    });
    res.length ?
    res.forEach(row => this.toggleSelection(row)) :
    this.rows.forEach(row => this.toggleSelection(row));
    this.checkStatus();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  onSelectionChanged(event: MDCDataTableRowSelectionChangedEvent): void {
  }

  onSelectedAll(): void {
    // this.desserts.forEach(_ => _.checked = true);
  }

  onUnselectedAll(): void {
    // this.desserts.forEach(_ => _.checked = false);
  }

  desserts = [
    {checked: false, name: 'Frozen yogurt', calories: 159, carbs: 24, protein: 4, comment: 'Super tasty'},
    {checked: true, name: 'Ice cream sandwich', calories: 237, carbs: 37, protein: 4.3, comment: 'I like ice cream more'},
    {checked: false, name: 'Eclair', calories: 262, carbs: 16, protein: 6, comment: 'New filling flavor'}
  ];


  loadCategory(page, pageSize, parentId?, filter?) {
    this.store.dispatch(CategoryListPageActions.loadListCategoryByParent({filter: filter || {},page,  pageSize, id: parentId || ''}));
  }

  createCategory() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = '39rem';
    dialogConfig.data = {
      label: 'Thêm danh mục',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CategoryModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      if (result === 'accept') {
        console.log(this.page, this.pageSize, this.parent_id);
        setTimeout(() => {
          this.filterBar.resetForm();
          this.loadCategory(1, this.pageSize, this.parent_id, this.filter);
        }, 1000);
      }
      console.log('The dialog was closed');
    });
  }

  updateCategory(element) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Sửa danh mục',
      action: 'update',
      element
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CategoryModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'accept') {
        console.log(this.parent_id);

        setTimeout(() => {
          this.loadCategory(this.page, this.pageSize, this.parent_id, this.filter);
        }, 1000);

      }


      console.log('The dialog was closed');
    });
  }

  deleteCategory(category) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Bạn có đồng ý muốn xóa danh mục này không?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.categoryService.deleteCategory(category.id).subscribe(res => {
          this._snackBar.open('Xóa danh mục thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          setTimeout(() => {
            this.loadCategory(this.page, this.pageSize, this.parent_id, this.filter);
          }, 500);
        }, error => {
          this._snackBar.open(error, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        })


      }
      console.log('The dialog was closed');
    });
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    const object = {
        offset: event.pageIndex * event.pageSize,
        limit: event.pageSize,
    }

  }

  filterData(event) {
    // console.log(event)
    let objectSearch = {};
    event.forEach(item => {
        objectSearch[item.key] = item.value.value;
    });
    const object = {
      offset: 0,
      limit: 5,
      ...objectSearch,
    }
    // console.log(object);
    // this.store.dispatch(CategoryListPageActions.loadListCategoryByParent({filter: {},limit: 10,  offset: 0, parent_id: category.id}));
    this.store.dispatch(CategoryActions.searchCategory({objectSearch: object}));
  }

  assignReviewAction() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AssignReviewModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      console.log('The dialog was closed');
    });
  }

  assignAttributeSet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = this.selected;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(AssignProductModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        this.selected = [];
        this.isSelectedAll = false;
        this.isIndeterminate = false;
        console.log(this.parent_id);

        setTimeout(() => {
          this.loadCategory(1, 10, this.parent_id);
        }, 500);
      }

      console.log('The dialog was closed');
    });
  }

  openModalDetailAttributeSet(row){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = row;
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(DetailAttributeSetComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
      }

      console.log('The dialog was closed');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }



}
