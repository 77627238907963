
<!-- Start Feature bar -->
<dash-feature-bar>
  <dash-feature-bar-row>
    <dash-feature-bar-section>
      <h1 dashFeatureBarTitle>Quản lý thuộc tính sản phẩm</h1>
    </dash-feature-bar-section>
  </dash-feature-bar-row>
</dash-feature-bar>
<!-- End Feature bar -->
<!-- Start Filter bar -->
<ic-filter-bar [availableFilters]="availableFilters"></ic-filter-bar>
<!-- End Filter bar -->

<div style="margin: 24px auto; max-width: 90%;">
  <mdc-card class="demo-card">
    <div class="ic-data-table-toolbar">
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-start">
        <button class="button-table" mdc-button raised (click)="createAttribute()">Thêm mới</button>
      </div>
      <div class="ic-data-table-toolbar__section ic-data-table-toolbar__section--align-end">
        <button mdc-button>
          Columns
        </button>
      </div>
    </div>
    <mdc-data-table (selectionChanged)="onSelectionChanged($event)"
      (selectedAll)="onSelectedAll()" (unselectedAll)="onUnselectedAll()" class="ic-data-table">
      <table mdcDataTableTable>
        <thead>
          <tr mdcDataTableHeaderRow name="select">

            <th mdcDataTableHeaderCell name="id">ID</th>
            <th mdcDataTableHeaderCell name="name">Tên thuộc tính</th>
            <th mdcDataTableHeaderCell name="val_type">Loại thuộc tính</th>
            <th mdcDataTableHeaderCell name="code">Mã phân biệt thuộc tính</th>
            <th mdcDataTableHeaderCell name="code">Thuộc tính mặc định</th>
            <th mdcDataTableHeaderCell name="is_unique_val">Duy nhất</th>
            <th mdcDataTableHeaderCell name="is_required">Bắt buộc</th>
            <th mdcDataTableHeaderCell name="is_required">Cho phép đóng góp</th>
            <th mdcDataTableHeaderCell name="created_at">Thời gian tạo</th>
            <th mdcDataTableHeaderCell name="updated_at">Thời gian cập nhật</th>
            <th mdcDataTableHeaderCell name="action">Hành động</th>
          </tr>
        </thead>
        <tbody mdcDataTableContent>
          <tr mdcDataTableRow *ngFor="let row of rows" [selected]="selection.isSelected(row)">

            <td mdcDataTableCell name="id">{{row.attributeId}}</td>
            <td mdcDataTableCell name="name">
              <span class="text-break">{{row.attributeLabel}}</span>
            </td>
            <td mdcDataTableCell name="val_type">
              <span *ngIf="row.type === 'select'">Lựa chọn đơn</span>
              <span *ngIf="row.type === 'multiselect'">Lựa chọn nhiều</span>
              <span *ngIf="row.type === 'text'">Dòng văn bản</span>
              <span *ngIf="row.type === 'textarea'">Khung văn bản</span>
              <span *ngIf="row.type === 'htmleditor'">Trình soạn thảo</span>
              <span *ngIf="row.type === 'date'">Ngày giờ</span>
              <span *ngIf="row.type === 'boolean'">Đúng/Sai</span>
              <span *ngIf="row.type === 'image'">Hình ảnh</span>
              <span *ngIf="row.type === 'number'">Số</span>
              <span *ngIf="row.type === 'integer'">Số nguyên dương</span>
              <span *ngIf="row.type === 'group'">Nhóm thông tin</span>
            </td>
            <td mdcDataTableCell name="attributeCode">{{row.attributeCode}}</td>
            <td mdcDataTableCell name="is_unique_val">
              <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="row.isSysAttribute"
                disabled
                >
              </mat-slide-toggle>
            </td>
            <td mdcDataTableCell name="is_unique_val">
              <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="row.isUniqueVal"
                disabled
                >
              </mat-slide-toggle>
            </td>
            <td mdcDataTableCell name="is_required">
              <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="row.isRequired"
                disabled
                >
              </mat-slide-toggle>
            </td>
            <td mdcDataTableCell name="is_required">
              <mat-slide-toggle
                class="example-margin"
                color="primary"
                [checked]="row.contributable"
                disabled
                >
              </mat-slide-toggle>
            </td>
            <td mdcDataTableCell name="createAt">{{row.createAt}}</td>
            <td mdcDataTableCell name="updatedAt">{{row.updatedAt}}</td>
            <td mdcDataTableCell name="action">
              <button mdcIconButton (click)="updateAttribute(row)" class="btn-edit">
                <mdc-icon>edit</mdc-icon>
              </button>
              <button mdcIconButton (click)="deleteAttributeProduct(row.attributeId)" class="btn-delete">
                <mdc-icon>delete</mdc-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </mdc-data-table>
    <mat-paginator showFirstLastButtons
                   [length]="total"
                   [pageSize]="pageSize"
    ></mat-paginator>
  </mdc-card>
</div>
