import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlnRoutingModule } from './gln-routing.module';
import {
    GlnListComponent,
    GlnImportModalComponent,
    GlnModalComponent,
 } from './components';
import { GlnPageComponent } from './containers';
import { MaterialModule } from '../shared/material.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DashFilterBarModule } from 'src/app/shared/components/filter-bar/filter-bar.module';
import { TitleModule } from '../dash/title';
import { EffectsModule } from '@ngrx/effects';
import * as fromEffects from './reducers';
import { GlnEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../shared/shared.module';

import { FeatureBarModule } from '@dash/feature-bar';
import { MDCDataTableModule, MdcCheckboxModule, MdcCard, MdcCardModule, MdcIconModule, MdcButtonModule, MdcIconButtonModule, MdcDialogModule } from '@angular-mdc/web';
import { DashCheckboxModule } from '@dash/checkbox';
import { GlnModalPageComponent } from './components/gln-modal-page/gln-modal-page.component';
import { ConfirmExportComponent } from './components/confirm-export/confirm-export.component';
import { GlnDetailComponent } from './components/gln-detail/gln-detail.component';
import { GlnDetailPageComponent } from './containers/gln-detail-page-component';
import { GlnDocumnetsComponent } from './components/gln-documnets/gln-documnets.component';
import { GlnListProductComponent } from './components/gln-list-product/gln-list-product.component';
import { AssignProductGlnComponent } from './components/assign-product-gln/assign-product-gln.component';
import { GlnListProductOwnedComponent } from './components/gln-list-product-owned/gln-list-product-owned.component';
import { ImportGlnComponent } from './components/import-gln/import-gln.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';

export const COMPONENTS = [
  GlnListComponent,
  GlnImportModalComponent,
  GlnModalComponent,
  GlnModalPageComponent,
  ConfirmExportComponent,
  GlnDetailComponent,
  GlnDocumnetsComponent,
  GlnListProductComponent,
  AssignProductGlnComponent,
  GlnListProductOwnedComponent,
  ImportGlnComponent
];

export const CONTAINERS = [
    GlnPageComponent,
    GlnDetailPageComponent
];
@NgModule({
  declarations: [
    [CONTAINERS, COMPONENTS]
  ],
  imports: [
    CommonModule,
    GlnRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TitleModule,
    DashFilterBarModule,
    MDCDataTableModule,
    MdcCheckboxModule,
    MdcCardModule,
    MdcIconModule,
    MdcButtonModule,
    MdcIconButtonModule,
    DashCheckboxModule,
    FeatureBarModule,
    MdcDialogModule,
    MatSelectInfiniteScrollModule,
    SharedModule,
    StoreModule.forFeature(fromEffects.glnFeatureKey, fromEffects.reducers),
    EffectsModule.forFeature([GlnEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    })
  ],
  providers: [],
})
export class GlnModule { }
