import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'dash-column-selector',
  templateUrl: './column-selector.component.html',
  styleUrls: ['./column-selector.component.scss']
})
export class ColumnSelectorComponent implements OnInit {

  @Input()
  availableColumns;

  listColumnAvaiable= [];
  isOpenPopupSelect= false;
  columnAvaiableForm : any;
  id: true;
  name: true;
  order_sort: true;
  rating_attribute: true;
  product_attribute: true;
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.listColumnAvaiable = this.availableColumns.filter(item => item.key !== 'select' && item.key !== 'action');
    this.columnAvaiableForm = this.formBuilder.group({
      id: [true, Validators.required],
      name: [true, Validators.required],
      order_sort: [true, Validators.required],
      rating_attribute: [true, Validators.required],
      product_attribute: [true, Validators.required]
    });
  }

  changeDisplayColumn(event) {
    // console.log(this.columnAvaiableForm.value);
    
    
  }

  togglePopupSelect() {
    this.isOpenPopupSelect = !this.isOpenPopupSelect;
  }

  onFormSubmit() {

  }

}
