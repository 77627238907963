import { createAction, props } from '@ngrx/store';

export const loadGlns = createAction(
  '[Gln List Page] Load Glns',
  props<{ page: number, pageSize: number, filter: any }>()
);

export const loadGlnsSuccess = createAction(
  '[Gln List Page] Load Glns Success',
  props<{ total: number, ids: number[] }>()
);

export const loadGlnsFailure = createAction(
  '[Gln List Page] Load Glns Failure',
  props<{ error: any }>()
);
