import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';

declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
@Component({
  selector: 'ic-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  isUpdate = false;
  label = 'Thêm sản phẩm';

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    let click = null;
    console.log(id)
    if (id) {
      this.isUpdate = true;
      this.label = 'Sửa sản phẩm';
      enterScreenView('product_detail', 'product');
    } else {
      click =  new ClickTekoDto('product_list', 'Create', 'http://datahub.icheck.vn/products/create', null);
      enterScreenView('product_create', 'product');
      clickTeko(click);
    }

  }

}
