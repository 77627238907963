import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { convertObjectToQueryParams } from '../../shared/common';
import { environment } from 'src/environments/environment';
import { of, from } from 'rxjs';
import {BaseService} from '@src/app/services/base.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'bearer 9d740410-58af-45ce-8e3b-17c7fcd09120.wlAyEqkl',
    'Content-Type' : 'application/json'
  })
};

@Injectable({
  providedIn: "root"
})
export class AttributeProductService extends BaseService{



  constructor(public http: HttpClient) {
    super(http)
  }

  getListAttribute(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    return this.get(`/attribute?${objectPage}&${objectFilter}`, httpOptions);
  }

  getDetailAttribute(id) {
    return this.get(`/attribute/${id}`);
  }

  createAttribute(attribute) {
    return this.post(`/attribute`, attribute);
  }

  updateAttribute(attribute) {
    return this.put(`/attribute/${attribute.id}`, attribute);
  }

  deleteAttribute(id) {
    return this.delete(`/attribute/${id}`);
  }

  getListAllAttribute(object) {
    const objectPage = convertObjectToQueryParams(object);
    const objectFilter = convertObjectToQueryParams(object.filter);
    return this.get(`/attribute/all?${objectPage}&${objectFilter}`, httpOptions);
  }


}
