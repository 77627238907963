import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {TekoValueDto} from '@src/app/shared/common/teko-value.dto';
declare function clickTeko(info);
declare function enterScreenView(screenName, contentType);

@Component({
  selector: 'ic-gln-detail',
  templateUrl: './gln-detail.component.html',
  styleUrls: ['./gln-detail.component.scss']
})
export class GlnDetailComponent implements OnInit {

  isUpdate = false;
  label = 'Thêm mới doanh nghiệp';

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.isUpdate = true;
      this.label = 'Cập nhật doanh nghiệp';
      enterScreenView(TekoValueDto.ENTERPRISE_DETAIL.SCREEN_NAME, TekoValueDto.ENTERPRISE_DETAIL.CONTENT_TYPE);
      const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_LIST.REGION_NAME,
        TekoValueDto.ENTERPRISE_LIST.CONTENT_NAME.UPDATE, ' http://datahub.icheck.vn/gln/update/' + id,{});
      console.log('click ==>', click)
      clickTeko(click);
    } else {
      const click = new ClickTekoDto(TekoValueDto.ENTERPRISE_LIST.REGION_NAME,
        TekoValueDto.ENTERPRISE_LIST.CONTENT_NAME.CREATE, ' http://datahub.icheck.vn/gln/create',{});
      console.log('click ==>', click)
      clickTeko(click);
      enterScreenView(TekoValueDto.ENTERPRISE_CREATE.SCREEN_NAME, TekoValueDto.ENTERPRISE_CREATE.CONTENT_TYPE);

    }
  }
}
