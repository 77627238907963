import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileListComponent } from './file-list/file-list.component';
import { UploadComponent } from './upload/upload.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MaterialModule} from './material.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [FileListComponent, UploadComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    UploadComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    FileListComponent,
    UploadComponent
  ]
})
export class SharedModule { }
