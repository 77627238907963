import { createAction, props } from '@ngrx/store';
import { Product } from '../models/product.model';

/**
 * Load Collection Action
 */
export const loadProduct = createAction('[Product Page] Load Product');

export const loadProductsSuccess = createAction(
  '[Collection/API] Load Prouducts Success',
  props<{ products: Product[] }>()
);

export const loadProductsFailure = createAction(
  '[Collection/API] Load Prouducts Failure',
  props<{ error: any }>()
);

export const addProduct = createAction(
  '[Collection/API] Add Product',
  props<{ product: Product }>()
);

export const addProductSuccess = createAction(
  '[Collection/API] Add Product Success',
  props<{ product: Product }>()
);

export const addProductFailure = createAction(
  '[Collection/API] Add Product Failure',
  props<{ product: Product }>()
);

export const removeProduct = createAction(
  '[Selected Product Page] Remove Product',
  props<{ product: Product }>()
);

export const removeProductSuccess = createAction(
  '[Collection/API] Remove Product Success',
  props<{ product: Product }>()
);

export const removeProductFailure = createAction(
  '[Collection/API] Remove Product Failure',
  props<{ product: Product }>()
);

export const assignEnterprise = createAction(
  '[Collection/API] Assign enterprise',
  props<{ object: any}> ()
)

export const assignEnterpriseFailure = createAction(
  '[Collection/API] Assign enterprise failure',
  props<{ error: any}> ()
)

export const assignCategory = createAction(
  '[Collection/API] Assign category',
  props<{ object: any}> ()
)

export const assignCategoryFailure = createAction(
  '[Collection/API] Assign category failure',
  props<{ error: any}> ()
)


