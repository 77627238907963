import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { defer, of, Observable, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, concatMap, withLatestFrom, tap, take, takeUntil, debounceTime } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromWarningProduct from '../reducers';
import {
  WarningProductActions,
} from '../actions';

import { WarningProductService } from '../services/warning-product.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class WarningProductEffects {
  constructor(
    private actions$: Actions,
    private warningProductService: WarningProductService,
    private store: Store<fromWarningProduct.State>,
    private _snackBar: MatSnackBar,
  ) {}
//   loadWarningProduct$ = createEffect(() => {
//     return this.actions$.pipe(
//       ofType(WarningProductActions.loadWarningProducts),
//       switchMap(action => {
//         // console.log(action);
//         return this.warningProductService.getWarningProducts(action.filter).pipe(
//           map(({ rows, total }) => {
//             // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
//             return WarningProductActions.loadWarningProductsSuccess({ rows, total })
//           }),
//           catchError(error =>
//             of(WarningProductActions.loadWarningProductsFailure({ error }))
//           )
//         )
//           })
//     )
//   });
//   updateWarningProduct$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(WarningProductActions.updateWarningProduct),
//       switchMap((actions) =>
//       {
//         return this.warningProductService.updateWarningProduct(actions.warningProductObject).pipe(
//           map((warningProduct: EnterpriseData) =>
//            {
//             this._snackBar.open('Cập nhật thông tin GLN thành công', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return WarningProductActions.updateWarningProductSuccess({ warningProduct })
//            }
//             )
//            ,
//           catchError(error => {
//             this._snackBar.open('Cập nhật thông tin GLN thất bại', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return of(WarningProductActions.updateWarningProductFailure({ error }))
//           }
//           )
//         )
//           })
//     )
//   );

//   addWarningProduct$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(WarningProductActions.addWarningProduct),
//       switchMap((actions) =>
//       {
//         return this.warningProductService.addWarningProduct(actions.warningProductObject).pipe(
//           map((warningProduct: EnterpriseData) =>
//            {
//             this._snackBar.open('Thêm mới GLN thành công', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return WarningProductActions.addWarningProductSuccess({ warningProduct })
//            }
//             )
//            ,
//           catchError(error => {
//             this._snackBar.open('Thêm mới GLN thất bại', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return  of(WarningProductActions.addWarningProductFailure({ error }))
//           }
//           )
//         )
//           })
//     )
//   );

//   deleteAction$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(WarningProductActions.deleteWarningProduct),
//       switchMap((actions) =>
//       {
//         return this.warningProductService.deleteWarningProduct(actions.id).pipe(
//           map((success) =>
//            {
//             this._snackBar.open('Xóa GLN thành công', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return WarningProductActions.deleteWarningProductSuccess({ id: actions.id })
//            }
//             )
//            ,
//           catchError(error => {
//             this._snackBar.open('Xóa GLN thất bại', 'Cancel', {
//               duration: 2000,
//               panelClass: ['snackbar-success']
//             });
//             return of(WarningProductActions.deleteWarningProductFailure({ error }))
//           }
//           )
//         )
//           })
//     )
//   );

//   subscribeAction = createEffect(() =>
//     this.actions$.pipe(
//       ofType(WarningProductActions.deleteWarningProductSuccess, WarningProductActions.updateWarningProductSuccess, WarningProductActions.addWarningProductSuccess),
//       concatMap(action => of(action).pipe(
//         withLatestFrom(this.store.pipe(select(fromWarningProduct.selectObjectFilterWarningProduct)))
//       )),
//       debounceTime(200),
//       switchMap(([action, filters]) => {
//         console.log('aaaa');
        
//         return [WarningProductActions.loadWarningProduct({ filter: filters })];
//       })
//     /*
//     switchMap(([actions, filters]) =>
//     {
//       // console.log('aaaaaaaaaaaaaaaaaaa', this.store);
//       // const filter: Observable<Object> = this.store.pipe(select(fromWarningProduct.selectObjectFilterWarningProduct)).withLatestFrom(it => it);
//       const filter = {
//         limit: 5,
//         offset: 0
//       }
//       return this.warningProductService.getWarningProducts(filter).pipe(
//         map(({rows, total}) =>
//          {
//           return WarningProductActions.loadWarningProductsSuccess({ rows, total })
//          }
//           )
//          ,
//         catchError(error =>
//           of(WarningProductActions.loadWarningProductsFailure({ error }))
//         )
//       )
//     })
//     */
//   ),
//   { dispatch: true }
// );

// loadNation$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(WarningProductActions.loadNation),
//     switchMap(action => {
//       // console.log(action);
//       return this.warningProductService.getLocation(action.request).pipe(
//         map(({ rows }) => {
//           // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
//           return WarningProductActions.loadNationSuccess({ rows })
//         }),
//         catchError(error =>
//           of(WarningProductActions.loadNationFailure({ error }))
//         )
//       )
//         })
//   )
// });

// loadCity$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(WarningProductActions.loadCity),
//     switchMap(action => {
//       // console.log(action);
//       return this.warningProductService.getLocation(action.request).pipe(
//         map(({ rows }) => {
//           // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
//           return WarningProductActions.loadCitySuccess({ rows })
//         }),
//         catchError(error =>
//           of(WarningProductActions.loadCityFailure({ error }))
//         )
//       )
//         })
//   )
// });

// loadDistrict$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(WarningProductActions.loadDistrict),
//     switchMap(action => {
//       // console.log(action);
//       return this.warningProductService.getLocation(action.request).pipe(
//         map(({ rows }) => {
//           // console.log('cai nay neu khong thay doi la do loi thg Phi', rows);
//           return WarningProductActions.loadDistrictSuccess({ rows })
//         }),
//         catchError(error =>
//           of(WarningProductActions.loadDistrictFailure({ error }))
//         )
//       )
//         })
//   )
// });

//   updateListWarningProduct$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(WarningProductActions.updateListWarningProduct),
//       switchMap((actions) =>
//       {
//         return this.warningProductService.updateListWarningProduct(actions.warningProducts, actions.warningProduct).pipe(
//           map((warningProduct) =>
//            {
//             return WarningProductActions.updateListWarningProductSuccess({ warningProduct })
//            }
//             )
//            ,
//           catchError(error =>
//             of(WarningProductActions.updateListWarningProductFailure({ error }))
//           )
//         )
//           })
//     )
//   );

  // searchWarningProduct$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WarningProductActions.searchWarningProduct),
  //     switchMap((actions) => {
  //       // console.log(actions);
  //       return this.warningProductService.searchWarningProduct(actions.objectSearch).pipe(
  //         map((warningProducts) => {
  //           // console.log(warningProducts);
  //           return WarningProductActions.searchWarningProductSuccess({ warningProducts })
  //         }),
  //         catchError(error =>
  //           of(WarningProductActions.searchWarningProductFailure({ error }))
  //         )
  //       );
  //     })
  //   )
  // );
}
