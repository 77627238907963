import {Component, OnInit, Inject, ViewChild, AfterViewInit} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import * as fromAlert from '../../../alert/reducers';
import { AlertActions, AlertPageActions, AlertListPageActions } from '../../../alert/actions';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import { ActivatedRoute } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { buildImageUrls } from '@src/app/shared/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from '@alert/services/alert.service';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {BarcodeBase} from '@src/app/shared/common/barcode-base';
import {ProductService} from '@products/services/product.services';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';

declare function clickTeko(info)
@Component({
  selector: 'ic-alert-products-modal',
  templateUrl: './alert-products-modal.component.html',
  styleUrls: ['./alert-products-modal.component.scss']
})
export class AlertProductsModalComponent  implements OnInit , AfterViewInit {

  alertProductForm: any;
  data: any;
  alert$: Observable<any>;
  searchChangeObserver;
  files = [];
  documents = [];
  filter = {};
  page = 1;
  listAlert = [];
  @ViewChild('matSelect') child: MatSelect;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              public dialogRef: MatDialogRef<AlertProductsModalComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              private dialog: MatDialog,
              public productService: ProductService,
              private _snackBar: MatSnackBar,
              private storeAlert: Store<fromAlert.State>,
              private alertService: AlertService) {
    this.data = data;
    this.alert$ = storeAlert.pipe(select(fromAlert.selectAlertListPageResults));
    }

  async  ngOnInit() {
    // console.log(this.data);

    const click = new ClickTekoDto('product_alert', 'product_alert_add',
      'http://datahub.icheck.vn/products/update/ljOyWmyP', {product_id: this.data.productId, barcode: this.data.barcode} )
    clickTeko(click)
    this.alertProductForm = this.formBuilder.group({
      alertId: ['', Validators.required],
      documents: [[], Validators.required],
      inputSearch: ['']
    });
    this.getListAlert();
  }

  ngAfterViewInit() {
    // console.log(this.child);
    this.child.openedChange.subscribe(opened => {
      // console.log(opened)
      if (!opened && !this.listAlert.length) {
        this.filter = {};
        this.listAlert = [];
        this.page = 1;
        this.getListAlert();
        this.alertProductForm.patchValue({inputSearch: ''});
      }
    });
  }

  clearInputSearch() {
    this.alertProductForm.patchValue({inputSearch: ''});
    this.filter = {};
    this.listAlert = [];
    this.page = 1;
    this.getListAlert();
  }


  getListAlert() {
    this.alertService.getListAlert({
      filter: this.filter,
      page: this.page,
      pageSize: 10
    }).subscribe((res: any) => {
      this.listAlert = [...this.listAlert, ...res.data.items];
    });
    // this.storeAlert.dispatch(AlertListPageActions.loadAlerts({
    //   filter: {},
    //   pageSize: 50,
    //   page: 1
    // }))

  }

  lazyLoadAlerts() {
    this.page += 1;
    this.getListAlert();
  }

  changeInputSearch(event) {

    this.filter = {title: event.target.value};
    this.listAlert = [];
    this.page = 1;
    this.getListAlert();
  }


  closeDialogClick() {
    this.dialogRef.close('ok');
  }

  onFormSubmit() {
    const object = {
      ...this.alertProductForm.value,
      productId : this.data.productId,
      documents:  this.alertProductForm.controls.documents.value.map(item => item.url)
    };

    this.storeAlert.dispatch(AlertActions.addAlertForProduct({ object }));
    this.closeDialogClick();
  }



  openUploadDialog(event) {
    // console.log(event);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload ${event}`,
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }
      // console.log(result);

      if (result.type === 'uploaded') {
        const data = result.data;
        data.map(res => {
          // const value = {
          //   name: res.fileId,
          //   type: '',
          //   url: res.src,
          // };
          this.files.push(res);
          this.alertProductForm.controls.documents.setValue(this.files);
        });

      }
    });
  }


  onRemoveFile(field: string, index: number) {

    const arrLogo = this.alertProductForm.controls.documents.value;
    // console.log(arrLogo);
    const newArr = [...arrLogo];

    newArr.splice(index, 1);
    this.alertProductForm.controls.documents.setValue(newArr);
    this.files.splice(index, 1);
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original;
    if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

}
