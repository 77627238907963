import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { DashFilterSettingData } from '..';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '@category/reducers';
import {
  CategoryListPageActions
} from '@category/actions';

@Component({
  selector: 'ic-select-search-box-setting',
  templateUrl: './select-search-box-setting.component.html',
  styleUrls: ['./select-search-box-setting.component.scss']
})
export class SelectSearchBoxSettingComponent implements OnInit, AfterViewInit {

  options: any = [];
  form: FormGroup;
  // option: string;
  isMutipleSelect: boolean;
  value: any;
  searchChangeObserver;
  categories$: Observable<any>;
  destroy$ = new Subject();

  constructor(
    public dialogRef: MdcDialogRef<SelectSearchBoxSettingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<any>,
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    private storeCategory: Store<fromCategory.State>) {
      this.categories$ = storeCategory.pipe(select(fromCategory.selectCategoryListPageResults));
    }

  ngOnInit(): void {
    this.categories$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      this.options = item.map(it => ({ value: it.id, name: it.name }))
    })
    this.form = this.fb.group({
      value: this.fb.control(null, [Validators.required]),
    });
    if (this.data.value) {
      this.form.setValue({
        value: this.data.value.value.value,
      });
      this.form.updateValueAndValidity();
    }

    this.loadCategory({})
  }
  ngAfterViewInit() {
    if (this.data.filter) {
      let config = this.data.filter.settingComponentConfig;
      this.isMutipleSelect = this.data.filter.isMutipleSelect;
      this.options = config.options;
    }
    this.cdr.detectChanges();
  }

  
  changeInputSearch(event) {
    // console.log(event);
    this.loadCategory({
      filter: { name: event.target.value},
      limit: 20
    })
  }

  loadCategory(object) {
    this.storeCategory.dispatch(
      CategoryListPageActions.loadCategories({
        filter: object.filter || {},
        page: object.page || 1,
        pageSize: object.pageSize || 50
      })
    );
  }

  onApply() {
    if(this.form.valid) {
      this.dialogRef.close({
        value: this.form.value,
        label: this.options.filter(item => item.value === this.form.value.value).map(it => it.name).join('')
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
