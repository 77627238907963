import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  OnDestroy
} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '@category/reducers';
import {
   CategoryPageActions
} from '@category/actions';
import { ProductPageActions, ProductListPageActions } from '@products/actions';
import * as fromProducts from '@products/reducers';
import * as fromProductAttribute from '../../../product-attribute-group/reducers';
import {  ProductAttributePageActions } from '../../../product-attribute-group/actions';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { validateCheckDigit, checkPrefixBarCode } from 'src/app/shared/validate/validate';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { UploadComponent } from 'src/app/shared/upload/upload.component';
import * as fromGln from '@gln/reducers';
import {  GlnPageActions } from '@gln/actions';
import {  formatDateGrpc, convertDateGrpcToFormatDate, buildImageUrls } from 'src/app/shared/common';
import * as _ from 'lodash';
import { ProductService } from '@products/services/product.services';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import { MdcDialog } from '@angular-mdc/web';
import { CategoryService } from '@category/services/category.service';
import { ProductAttributeService } from '@attribute-set/services/product-attribute.service';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare var $: any;
declare function clickTeko(info);
declare function alertEvent(info)

@Component({
  selector: 'ic-product-modal-page',
  templateUrl: './product-modal-page.component.html',
  styleUrls: ['./product-modal-page.component.scss'],
})
export class ProductModalPageComponent  implements OnInit , OnDestroy {

  @ViewChild('matSelectCategory', { static: false }) set content(content: MatSelect) {
      if (content) { // initially setter gets called with undefined
          this.matSelectCategory = content;
          this.matSelectCategory.openedChange.subscribe(opened => {
            if (!opened) {
              if (!this.listCategory.length) {


                this.filterCategory = {  };
                this.pageCategory = 1;
                this.listCategory = [];
                this.getListCategory();
                this.productForm.patchValue({inputSearchCategory: ''});
              }
            }
          });

      }
  }

  @ViewChild('matSelectAttributeSet', { static: false }) set contentAttributeSet(contentAttributeSet: MatSelect) {
      if (contentAttributeSet) { // initially setter gets called with undefined
          this.matSelectAttributeSet = contentAttributeSet;
          this.matSelectAttributeSet.openedChange.subscribe(opened => {
            if (!opened) {

              if (!this.listAttributeSet.length) {
                this.filterAttributeSet = {  };
                this.pageAttributeSet = 1;
                this.listAttributeSet = [];
                this.getListAttributeSet();
                this.productForm.patchValue({inputSearchAttributeSet: ''});
              }
            }
          });

      }
  }

  constructor(private storeCategory: Store<fromCategory.State>,
              private dialog: MatDialog,
              private store: Store<fromProducts.State>,
              private storeAttributeSet: Store<fromProductAttribute.State>,
              private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private storeGln: Store<fromGln.State>,
              private productService: ProductService,
              private categoryService: CategoryService,
              private _snackBar: MatSnackBar,
              private dialog2: MdcDialog,
              private productAttributeService: ProductAttributeService) {
      this.categories$ = storeCategory.pipe(select(fromCategory.selectListSearchCategory));
      this.currentCategory$ = storeCategory.pipe(select(fromCategory.selectDetailCategory));
      this.listAttribute$ = storeCategory.pipe(select(fromCategory.selectListAttributeByCategory));
      this.listAttributeSet$ = storeAttributeSet.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
      this.currentAttributeSet$ = storeAttributeSet.pipe(select(fromProductAttribute.selectCurrentAttributeSetListPageColumns));
      this.productCurrent$ = store.pipe(select(fromProducts.selectProductCurrent));
      this.isExistBarCode$ = store.pipe(select(fromProducts.selectIsExistBarCode));
      this.treeCategory$ = storeCategory.pipe(select(fromCategory.selectTreeCategory));
      this.glns$ = storeGln.pipe(select(fromGln.selectGlnListPageResults));
      this.currentGln$ = storeGln.pipe(select(fromGln.selectCurrentGln));
      this.listTreeCategory$ = storeCategory.pipe(select(fromCategory.selectTreeCategory));
      this.filter$ = store.pipe(select(fromProducts.selectProductListPageFilter));
  }

  @Input() isUpdate: boolean;
  @ViewChild('divList', { read: ElementRef, static: false }) divList: ElementRef;

  @ViewChild('mySelectCategory', { read: ElementRef, static: false }) mySelectCategory: ElementRef;

  @ViewChild('matSelectGln') matSelectGln: MatSelect;

  @ViewChild('select') select: MatSelect;

  allSelected = false;

  private matSelectCategory: MatSelect;
  private matSelectAttributeSet: MatSelect;

  inputSearch: any;
  searchCategoryObserver;
  searchAttributeSetObserver;
  searchGlnObserver;
  inputBarCodeObserver;
  categories$: Observable<any>;
  categorySelected;
  listAttribute$: Observable<any>;
  groupListAtttribute = [];
  attributeSet;
  barCode;
  listAttributeSet$: Observable<any>;
  currentAttributeSet$: Observable<any>;
  floatLabelControl = new FormControl('true');
  productForm: FormGroup;
  attributes: any;
  productCurrent$: Observable<any>;
  treeCategory$: Observable<any>;
  productCurrent;
  productId: number;
  isExistBarCode$: Observable<any>;
  isExitsBarCode: any;
  logo = [];
  arrLogo = [];
  glns$: Observable<any>;
  listGln = [];
  checkPrefixBarCode: any;
  listCategory = [];
  currentCategory$: Observable<any>;
  currentGln$: Observable<any>;
  listAttributeSet = [];
  validFormatBarCode = false;
  attributeGroup: any;
  listTreeCategory$: Observable<any>;
  listTreeCategory: any;
  listSearchBarcode = [];
  glnSelected: any;
  fileType = 'jpg,jpeg,png,gif,bmp';
  filter$: Observable<any>;
  filterProduct: any;
  pageGln = 1;
  filterGln: any = {};
  pageCategory = 1;
  filterCategory: any = {};
  pageAttributeSet = 1;
  filterAttributeSet: any = {};
  destroy$ = new Subject();


  ecommerces = [
    {id: 1, name: 'A'},
    {id: 1, name: 'B'},
    {id: 1, name: 'C'}
  ];

  public config = {
    placeholder: '',
    disableDragAndDrop: true,
    tabsize: 1,
    height: '250px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['link', 'video']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  };
  listEcommerce = [];
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {

    if (this.divList && !this.divList?.nativeElement.contains(event.target)) {
      this.listSearchBarcode = [];
    }
  }

  ngOnInit(): void {


    const that = this;
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => this.filterProduct = item);

    this.getListEcommerce();
    this.getListAttributeSet();
    this.getListCategory();
    this.getListEnterprise();

    this.productForm = this.fb.group({
      customAttributeSetId: this.fb.control(null, [Validators.required]),
      barcode : this.fb.control(null, [Validators.required]),
      enterpriseId : this.fb.control(null, [Validators.required]),
      categoryId : this.fb.control(null),
      checkPrefix: this.fb.control(false),
      attributes: this.fb.group({}),
      scanStatus : this.fb.control(1, [Validators.required]),
      inputSearchGln: this.fb.control(''),
      inputSearchCategory: this.fb.control(''),
      inputSearchAttributeSet: this.fb.control(''),
      alertId: this.fb.control(''),
    });

    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.productService.getDetailProduct(id).subscribe((z: any) => {
        this.productService.subjectGetBarcode = new  BehaviorSubject<any>(z.data.barcode);
      });
      this.isExitsBarCode = false;
      this.productId = +id;
      this.productForm.controls.barcode.disable();
      this.store.dispatch(ProductPageActions.getProduct({ id }));
      this.productCurrent$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(item => {
        if (item.hasOwnProperty('category')) {

          this.productForm.patchValue({
            barcode: item.barcode,
            categoryId : item?.category ? item.category[item.category.length - 1].id : '',
            customAttributeSetId: item?.customAttributeSetId,
            enterpriseId: item?.enterprise?.id,
            scanStatus: item?.scanStatus,
            alertId : item.alert?.id
          });
          this.productForm.updateValueAndValidity();

        }
        if (item && item.groupAttribute) {
          this.productCurrent = item;
          if (item?.enterprise?.id) {
            this.getCurrentGln(item?.enterprise?.id);
          }
          if (item?.customAttributeSetId) {
            this.getCurrentAttributeSet(item?.customAttributeSetId);
          }
          this.currentAttributeSet$.pipe(
            takeUntil(this.destroy$)
          ).subscribe(item => {

            const newArr = [];
            if (item?.id) {
              newArr.push(item);
              this.listAttributeSet = _.uniqBy([...newArr, ...this.listAttributeSet], 'id');
            }
          });

          this.currentGln$.pipe(
            takeUntil(this.destroy$)
          ).subscribe(item => {
            const newArr = [];
            if (item?.id) {
              newArr.push({
                ...item,
                prefix: item.prefixCode
              });
              this.listGln = _.uniqBy([...newArr, ...this.listGln], 'id');
            }

          });

          this.currentCategory$.pipe(
            takeUntil(this.destroy$)
          ).subscribe(it => {

            const newArr = [];
            if (it?.id) {
              newArr.push(it);
              this.listCategory = _.uniqBy([...newArr, ...this.listCategory], 'id');
            }

          });
          this.groupListAtttribute = item.groupAttribute;
          const attributesGroup = this.fb.group({});

          item.groupAttribute.forEach(group => {
            group.attributeValues.forEach(attr => {
              const validators = [];
              if (attr.type === 'htmleditor') {
                $(document).ready(function() {
                  // console.log($(".note-insert"));
                  if (!$('button.uploadImg').length) {
                    $('.note-insert').append('<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>');
                  }

                  $(`htmleditor${attr.attributeId} .uploadImg`).unbind().click(() => {
                    that.openUploadDialog('htmleditor', attr.attributeId);
                  });
                });
              }

              if (attr.isRequired) {
                validators.push(Validators.required);
              }
              if (attr.type === 'group') {
                attributesGroup.addControl(`text-${attr.attributeId}`, this.fb.control('', validators));
                attributesGroup.addControl(`textarea-${attr.attributeId}`, this.fb.control('', validators));
                attributesGroup.addControl(attr.attributeId, this.fb.control('', validators));
              }

              if (attr.type === 'ecommerce') {
                console.log(3123123);

                attributesGroup.addControl('ecommerceList', this.fb.array([]));
              }

              attributesGroup.addControl(attr.attributeId, this.fb.control('', validators));
            });
          });


          this.productForm.setControl('attributes', attributesGroup);
          const attributes: any = this.productForm.get('attributes');
          item.groupAttribute.forEach(element => {
            element.attributeValues.forEach(it => {

              if (it.type === 'boolean') {
                attributes.controls[it.attributeId].value = it.boolValue;
              } else if (it.type === 'multiselect') {
                if (it.intValues?.length) {
                  attributes.controls[it.attributeId].value = it.intValues.map(val => val.id) || [];
                  if (it.intValues.length === it.dataSelects.length) {
                    this.allSelected = true;
                  }
                }
              } else if (it.type === 'number') {
                // console.log(it);
                  attributes.controls[it.attributeId].value = it?.decimalValue ? it.decimalValue : '';
              } else if (it.type === 'integer') {
                // console.log(it);
                  attributes.controls[it.attributeId].value = it?.integerValue ? it.integerValue : '';
              } else if (it.type === 'image') {
                  // console.log(it);
                  attributes.controls[it.attributeId].value = it.galleryRequest ? it.galleryRequest.map(item => {
                    return {
                      ...item,
                      fileId: item.value,
                      url: item.value ? buildImageUrls(item.value).original : ''
                    };
                  }) : [];
                  if (it.galleryRequest?.length) {
                    attributes.controls[it.attributeId].setErrors(null);
                  }
              } else if (it.type === 'select') {
                // console.log(it);
                if (it.intValues?.length) {
                  attributes.controls[it.attributeId].value = it.intValues[0].id || [];
                }
              } else if (it.type === 'htmleditor') {
                $(document).ready(function() {
                  if (!$('button.uploadImg').length) {
                    $('.note-insert').append('<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>');
                  }
                  $(`htmleditor${it.attributeId} .uploadImg`).unbind().click(() => {
                    that.openUploadDialog('htmleditor', it.id);
                  });
                });
                attributes.controls[it.attributeId].value = it?.stringValue ? it.stringValue : '';
              } else if (it.type === 'date') {
                attributes.controls[it.attributeId].value = it?.stringValue ? convertDateGrpcToFormatDate(it.stringValue) : '';
              } else if (it.type === 'image-single') {
                // attributes.controls[it.attributeId].value = [ 'aaaa']
                attributes.controls[it.attributeId].value = it?.stringValue ? [
                  {
                    url: buildImageUrls(it.stringValue).original,
                    fileId: it.stringValue
                  }
                ] : '';
                if (it?.stringValue) {
                  attributes.controls[it.attributeId].setErrors(null);
                }
                console.log(attributes.controls[it.attributeId].value);

              } else if (it.type === 'group') {
                attributes.controls[`text-${it.attributeId}`].value = it?.dataGroupRequest?.description ? it.dataGroupRequest.description : '';
                attributes.controls[`textarea-${it.attributeId}`].value = it?.dataGroupRequest?.detail ? it.dataGroupRequest.detail : '';
                attributes.controls[it.attributeId].value = it?.dataGroupRequest?.image ? [{url: it?.dataGroupRequest?.image}] : '';
              } else if (it.type === 'ecommerce' && it.ecommerceRequest?.ecommerceList?.length) {
                console.log(3123123, it);
                it.ecommerceRequest.ecommerceList.forEach(element => {
                  ((this.productForm.get('attributes') as any).controls.ecommerceList as FormArray).push(
                    this.updateFormEcommerceItem(element)
                );
                });
              } else {

                attributes.controls[it.attributeId].value = it?.stringValue ? it.stringValue : '';
              }
            });
        });
        }
      });
    } else {
    }
    this.attributeGroup = this.productForm.get('attributes');

    this.currentAttributeSet$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      // console.log('trung', data);
      if (data.id) {
        // console.log('trung', data);

        this.attributeSet = data;
        // console.log(data);
        const attributesGroup = this.fb.group({});
        data.groupAttribute.forEach(group => {
          group.attributeValues.forEach(attr => {
            const validators = [];
            if (attr.isRequired) {
              validators.push(Validators.required);
            }
            if (attr.type === 'htmleditor') {
              $(document).ready(function() {
                if (!$('button.uploadImg').length) {
                  $('.note-insert').append('<button type="button" class="note-btn uploadImg" ><i class="note-icon-picture"></i></button>');
                }
                $(`.htmleditor${attr.attributeId} .uploadImg`).unbind().click(event => {
                  that.openUploadDialog('htmleditor', attr.attributeId);
                });
              });
            }
            if (!this.isUpdate) {
              if (attr.type === 'group') {
                attributesGroup.addControl(`text-${attr.attributeId}`, this.fb.control('', validators));
                attributesGroup.addControl(`textarea-${attr.attributeId}`, this.fb.control('', validators));
                attributesGroup.addControl(attr.attributeId, this.fb.control('', validators));
              } else if (attr.type === 'ecommerce') {

                attributesGroup.addControl('ecommerceList', this.fb.array([]));
              } else {
              attributesGroup.addControl(attr.attributeId, this.fb.control('', validators));
              }
              this.productForm.setControl('attributes', attributesGroup);
              // this.productForm.updateValueAndValidity();
            } else {
              if (!this.productForm.get(['attributes', attr.attributeId])) {
                // console.log('zzzzzzzzzzz', this.productForm.get(['attributes', attr.attributeId]), attr.id);
                const attributes: any = this.productForm.get('attributes');
                attributes.addControl(attr.attributeId, this.fb.control('', validators));
                // this.productForm.setControl('attributes', attributesGroup);
                // this.productForm.updateValueAndValidity();
              }
            }
          });
        });
        if (this.checkPrefixBarCode) {
          this.productForm.controls.barcode.setErrors({incorrect: true});
        }

      }
    });

    // Lang nghe khi attribute set thay doi
    this.productForm.get('customAttributeSetId').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(id => {

      this.selectAttributeSet(id);
    });
    this.productForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(id => {

    });

    this.listenChangeCheckPrefix();

    this.listenChangeCategory();


  }
  getListEcommerce() {
    this.productService.getListEcomerce().subscribe(res => {
      console.log(res);
      this.listEcommerce = res.data;
    });
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initFormEcommerceItem() {
    console.log(1);

    return this.fb.group({
      avatar: ['../../../../assets/ic-ecomerce-default.png'],
      ecommerceId: ['', [Validators.required]],
      link: ['', [Validators.required]],
      price: ['', [Validators.required]],
      promotionPrice: [''],
    });
  }

  updateFormEcommerceItem(data: any) {
    return this.fb.group({
      avatar: [data.avatar || '../../../../assets/ic-ecomerce-default.png', [Validators.required]],
      ecommerceId: [data.ecommerceId, [Validators.required]],
      link: [data.link, [Validators.required]],
      price: [data.price || '', [Validators.required]],
      promotionPrice: [data.promotionPrice || ''],
    });
  }

  removeFormEcommerceItem(index: number) {
    ((this.productForm.get('attributes') as any).controls.ecommerceList as FormArray).removeAt(index);
  }

  addFormEcommerceItem() {
    console.log((this.productForm.get('attributes') as any).controls.ecommerceList);
    ((this.productForm.get('attributes') as any).controls.ecommerceList as FormArray).push(
        this.initFormEcommerceItem()
    );
    ((this.productForm.get('attributes') as any).controls.ecommerceList as any).controls.map((item, index) => {
      if (item.value.ecommerceId) {
        this.changeInput('change', index);
      }
    });
    console.log((this.productForm.get('attributes') as any).controls.ecommerceList);

  }

  getListEnterprise() {
    console.log('aaaa');
    this.productService.searchEnterprise({page: this.pageGln, pageSize: 10, filter: this.filterGln}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      this.listGln = [...this.listGln, ...res.data.items];
      if (this.glnSelected?.hasOwnProperty('id')) {
        this.listGln.push(this.glnSelected);
      }
    });

  }

  lazyLoadEnterprise() {
    this.pageGln += 1;
    this.getListEnterprise();
  }

  listenChangeCategory() {
    this.productForm.get('categoryId').valueChanges.subscribe(value => {
      console.log(value);
      if (value) {
        this.categorySelected = value;
        if (!this.productForm.controls.customAttributeSetId.value) {
          this.selectCategory(value);
        }
      }
    });

  }

  getCurrentAttributeSet(id) {
    this.storeAttributeSet.dispatch(ProductAttributePageActions.getDetailAttributeSet({ id }));
  }

  getCurrentCategory(id) {
    this.storeCategory.dispatch(CategoryPageActions.getDetailCategory({ id }));
  }

  getCurrentGln(id) {
    this.storeGln.dispatch(GlnPageActions.getDetailGln({ id }));
  }

  selectCategory(value) {
    console.log(value);
    this.storeCategory.dispatch(
      CategoryPageActions.getDetailCategory({ id : value })
    );

    this.storeCategory.dispatch(
      CategoryPageActions.getAttributeSetByCategory({ id : value })
    );

    this.listAttribute$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(item => {
      if (item?.data) {
        this.listAttributeSet = _.uniqBy([item?.data, ...this.listAttributeSet], 'id');
      }
      if (!this.productForm.controls.customAttributeSetId.value) {
        this.productForm.controls.customAttributeSetId.setValue(item?.data?.id);
      }
    });
  }


  listenChangeCheckPrefix() {
    this.productForm.get('checkPrefix').valueChanges.subscribe(value => {
      if (value) {
        if (this.productForm.get('enterpriseId').value && this.productForm.get('barcode').value && this.productForm.get('checkPrefix').value) {

        const enterpriseId = this.productForm.get('enterpriseId').value;
        const barcode = this.productForm.get('barcode').value;
        const glnSelected = this.listGln.find(it => it.id === enterpriseId);
        // console.log(glnSelected);
        // console.log(barcode);
        if (glnSelected.prefix) {
          const valid = checkPrefixBarCode(barcode, glnSelected.prefix);
          if (!valid) {
            this.productForm.controls.barcode.setErrors({incorrect: true});
          } else {
            this.productForm.controls.barcode.setErrors(null);
          }
          this.productForm.patchValue({
            checkPrefixBarCode: !valid
          });
          this.checkPrefixBarCode = !valid;
        }

      }

      } else {
        console.log('aaaaaaaaaaa');

        this.productForm.patchValue({
          checkPrefixBarCode: false
        });
        this.checkPrefixBarCode = false;
        this.productForm.controls.barcode.setErrors(null);
      }

    });

    this.productForm.get('enterpriseId').valueChanges.subscribe(value => {
      if (this.productForm.get('enterpriseId').value && this.productForm.get('barcode').value && this.productForm.get('checkPrefix').value) {
        const enterpriseId = this.productForm.get('enterpriseId').value;
        const barcode = this.productForm.get('barcode').value;
        const glnSelected = this.listGln.find(it => it.id === enterpriseId);
        if (glnSelected.prefix) {
          const valid = checkPrefixBarCode(barcode, glnSelected.prefix);
          if (!valid) {
            this.productForm.controls.barcode.setErrors({incorrect: true});
          } else {
            this.productForm.controls.barcode.setErrors(null);
          }
          // console.log(valid);
          this.productForm.patchValue({
            checkPrefixBarCode: !valid
          });
          this.checkPrefixBarCode = !valid;
        } else {
          this.productForm.controls.barcode.setErrors(null);
          this.checkPrefixBarCode = false;
        }

      }

    });

  }

  getListAttributeSet() {

    this.productAttributeService.getListAttributeSet({
      filter: this.filterAttributeSet,
      page: this.pageAttributeSet,
      pageSize: 10,
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listAttributeSet = [...this.listAttributeSet, ...res.data.items];
    });
  }

  lazyLoadAttributeSet() {
    this.pageAttributeSet += 1;
    this.getListAttributeSet();
  }

  getListCategory() {
    this.categoryService.getCategories({
      page: this.pageCategory, pageSize: 10, filter: this.filterCategory
    }).pipe(
      map((item: any) => {
        const data = item.data.items.map(it => {
          it.display_name = it.ancestors.map(anc => anc.name).length
          ? it.ancestors.map(anc => anc.name).join(' > ') + ' > ' + it.name
          : it.name;
          return it;
        });
        return data;
      })
    )
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listCategory = [...this.listCategory, ...res];
    });
  }

  lazyLoadCategory() {
    this.pageCategory += 1;
    this.getListCategory();
  }

  backToListProduct() {
    this.router.navigate(['/products']);
  }

  selectAttributeSet(value) {
    if (value) {
      this.storeAttributeSet.dispatch(
        ProductAttributePageActions.getDetailAttributeSet({ id: value})
      );
    }
  }

  selectedAttributeSet(event) {
    // console.log(event);

  }


  checkBarCode() {
    // console.log(this.productForm.valid)
    const barCode = this.productForm.get('barcode').value;

    if (barCode && !this.isUpdate) {
      this.store.dispatch(ProductPageActions.checkBarCodeExist({ bar_code: barCode.trim() }));
    }
    this.isExistBarCode$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.isExitsBarCode = it === 2 ? true : false;

    });
  }

  openUploadDialog(event, id?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: `Upload Image`,
      fileType: this.fileType
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(UploadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.data === 'close') {
        return;
      }

      if (result.type === 'uploaded') {
        const data = result.data;
        if (event === 'htmleditor') {
          const description = [];
          let image = '';
          result.data.forEach(e => {
              image += `<div style="text-align: center"><img src="${e.url}" class='imgSummernote'></div><br>`;
            });
          description.push(image);

          const attributesGroup: any = this.productForm.get('attributes');
          attributesGroup.controls[id].setValue(attributesGroup.controls[id].value + description);
          return;
        }

        if (id && (id?.type === 'image-single' || id?.type === 'group')) {
          const data = result.data;

          const image = [];

          const value = {
              name: data[0].name,
              type: '',
              url: data[0].url,
              fileId: data[0].fileId
            };
            // console.log(value);

          image.push(value);
          // });
          const attributesGroup: any = this.productForm.get('attributes');
          if (image.length ) {
            attributesGroup.controls[event].setErrors(null);
          }
          attributesGroup.controls[event].value = image;
          this.productForm.updateValueAndValidity();
          return;
        }

        this.productForm.get(['attributes', event]).value;
        const arrLogo  =  this.productForm.get(['attributes', event]).value;
        const newArr = [...arrLogo];
        data.forEach(res => {
          const value = {
            name: res.name,
            type: '',
            url: res.url,
            fileId: res.url
          };
          newArr.push(value);
        });
        const attributesGroup: any = this.productForm.get('attributes');
        if (newArr.length ) {
          attributesGroup.controls[event].setErrors(null);
        }
        attributesGroup.controls[event].value = newArr;
        this.productForm.updateValueAndValidity();
      }
    });
  }
  onRemoveFile(field: string, index: number) {
    const arrLogo = this.productForm.get(['attributes', field])?.value;
    const newArr = [...arrLogo];
    newArr.splice(index, 1);
    const is_required = this.productForm.get(['attributes', field]).validator;
    if (!newArr.length && is_required) {
      this.productForm.get(['attributes', field]).setErrors({incorrect: true});
    }
    const attributesGroup: any = this.productForm.get('attributes');
    attributesGroup.controls[field].value = newArr;
    this.logo.splice(index, 1);

  }

  onOptionClick(event) {
    // console.log(event);

  }
  changeInputBarCode(event) {
    if (!this.inputBarCodeObserver) {
      Observable.create(observer => {
        // console.log(observer);

        this.inputBarCodeObserver = observer;
      }).pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe(it => {
          this.validFormatBarCode = validateCheckDigit(it);
          if (this.productForm.get('enterpriseId').value && this.productForm.get('barcode').value && this.productForm.get('checkPrefix').value) {
            const enterpriseId = this.productForm.get('enterpriseId').value;
            const glnSelected = this.listGln.find(it => it.id === enterpriseId);
            const validPrefix = checkPrefixBarCode(it, glnSelected.prefix);
            if (!validPrefix) {
              this.productForm.controls.barcode.setErrors({incorrect: true});
            } else {
              this.productForm.controls.barcode.setErrors(null);
            }

            this.checkPrefixBarCode = !validPrefix;
          }
          if (it) {
            this.productService.searchBarcode(it).pipe(
              takeUntil(this.destroy$)
            ).subscribe((res: any) => {
              this.listSearchBarcode = res.data.items;
            });
          } else {
            this.listSearchBarcode = [];
          }

          const barCode = this.productForm.get('barcode').value;

          if (barCode && !this.isUpdate) {
            this.store.dispatch(ProductPageActions.checkBarCodeExist({ bar_code: it.trim() }));
          }
          this.isExistBarCode$.pipe(
            takeUntil(this.destroy$)
          ).subscribe(it => {
            this.isExitsBarCode = it;
          });
        });
    }
    this.inputBarCodeObserver.next(event.target.value);
  }

  changeInputCategorySearch(event) {
    // console.log(event);
    if (!this.searchCategoryObserver) {
      Observable.create(observer => {
        // console.log(observer);

        this.searchCategoryObserver = observer;
      }).pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe(it => {
          this.filterCategory = { name: it };
          this.pageCategory = 1;
          this.listCategory = [];
          this.getListCategory();
        });
    }
    this.searchCategoryObserver.next(event.target.value);
  }

  changeInputAttributeSetSearch(event) {
      // console.log(event);
      if (!this.searchAttributeSetObserver) {
        Observable.create(observer => {
          // console.log(observer);

          this.searchAttributeSetObserver = observer;
        }).pipe(debounceTime(500))
          .pipe(distinctUntilChanged())
          .subscribe(it => {


              this.filterAttributeSet = { name: it };
              this.pageAttributeSet = 1;
              this.listAttributeSet = [];
              this.getListAttributeSet();
          });
      }
      this.searchAttributeSetObserver.next(event.target.value);
    }

  changeInputGlnSearch(event) {
    if (!this.searchGlnObserver) {
      Observable.create(observer => {

        this.searchGlnObserver = observer;
      })
      .pipe(debounceTime(500))
        .pipe(distinctUntilChanged())
        .subscribe(it => {
          // console.log(it)
          this.filterGln = { query: it };
          this.pageGln = 1;
          this.listGln = [];
          this.getListEnterprise();

        });
    }
    this.searchGlnObserver.next(event.target.value);
  }

  changeEcommerce(event, index) {
    console.log(event);
    const img = this.listEcommerce.find(item => item.id === event);
    console.log(img);
    if (img?.avatar) {
      // @ts-ignore
      this.productForm.get('attributes').controls.ecommerceList.controls[index].value.avatar = img.avatar;
      // @ts-ignore
      console.log(this.productForm.get('attributes').controls.ecommerceList);

    }
  }


  changeInput(event, index) {
    console.log(event);
    // @ts-ignore
    const ecommerceId = this.productForm.get('attributes').controls.ecommerceList.controls[index].value.ecommerceId;
    if (ecommerceId) {
      const img = this.listEcommerce.find(item => item.id === ecommerceId);
      console.log(img);
      if (img?.avatar) {
        // @ts-ignore
        this.productForm.get('attributes').controls.ecommerceList.controls[index].value.avatar = img.avatar;
        // @ts-ignore
        console.log(this.productForm.get('attributes').controls.ecommerceList);
      }
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  generateLabel(item) {
    const listSelected = this.productForm.get(['attributes', item.attributeId]).value;
    return item.dataSelects.filter(item => listSelected.indexOf(item.id) !== -1).map(it => it.value).join('; ');
  }


  onFormSubmit() {

    const { controls } = this.productForm;
    console.log(this.productForm);
    const arrChildren = [];
    const attributes = this.attributeSet.groupAttribute.map(item => {
      const result = item.attributeValues.map(it => {
        const attribute = Object.assign({}, it);
        if (it.type === 'multiselect' || it.type === 'select') {

          let arrValue;
          if (it.type === 'select') {
            arrValue = this.productForm.get(['attributes', it.attributeId])?.value;
            // console.log(arrValue);
          } else {
            arrValue =  [...this.productForm.get(['attributes', it.attributeId])?.value];
          }


          const value = it.dataSelects.filter(val => {
              if (it.type === 'multiselect') {
                return arrValue.indexOf(val.id) !== -1;
              }
              return arrValue === val.id;
            }).map(val => ({
              id: val.id,
              stringValue: val.name
          }));

          attribute.intValues = value;
        } else if (it.type === 'number') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value;
          attribute.decimalValue = value;
        } else if (it.type === 'integer') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value;
          attribute.integerValue = value;
        } else if (it.type === 'image') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value.length ?  this.productForm.get(['attributes', it.attributeId])?.value.map((item, index) => {
            return {
              value: item.url,
              media_type: 'image',
              position: index
            };
          }) : [];

          attribute.galleryRequest = value;
        } else if (it.type === 'image-single') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value.length ?  this.productForm.get(['attributes', it.attributeId])?.value[0].url : '';
          attribute.stringValue = value;
        } else if (it.type === 'boolean') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value === false ||  this.productForm.get(['attributes', it.attributeId])?.value === true ?
          this.productForm.get(['attributes', it.attributeId])?.value : null;
          attribute.boolValue = value;
        } else if (it.type === 'date') {
          // console.log(this.productForm.get(['attributes', it.attributeId]));

          const value = this.productForm.get(['attributes', it.attributeId]).value ? formatDateGrpc(this.productForm.get(['attributes', it.attributeId])?.value) : '';
          attribute.stringValue = value;
        } else if (it.type === 'number') {
          const value = this.productForm.get(['attributes', it.attributeId])?.value;
          attribute.boolValue = value;
        } else if (it.type === 'group') {
          console.log(this.productForm.get(['attributes', `textarea-${it.attributeId}`])?.value.replace(/&nbsp;/g, ' ').trim());
          console.log(this.productForm.get(['attributes', `text-${it.attributeId}`])?.value);
          const value = {
            image: this.productForm.get(['attributes', it.attributeId])?.value[0]?.url ?
            this.productForm.get(['attributes', it.attributeId])?.value[0].url : '',
            description: this.productForm.get(['attributes', `text-${it.attributeId}`])?.value ?
            this.productForm.get(['attributes', `text-${it.attributeId}`])?.value.trim() : '',
            detail: this.productForm.get(['attributes', `textarea-${it.attributeId}`])?.value ?
            this.productForm.get(['attributes', `textarea-${it.attributeId}`])?.value.replace(/&nbsp;/g, ' ').trim() : ''
          };
          attribute.dataGroupRequest = value;
        } else if (it.type === 'ecommerce') {
          const ecommerceRequest = {
            ecommerceList: (this.productForm.get('attributes') as any).controls.ecommerceList.value
          };
          attribute.ecommerceRequest = ecommerceRequest;
        } else {
          attribute.stringValue = this.productForm.get(['attributes', it.attributeId])?.value ? this.productForm.get(['attributes', it.attributeId])?.value.trim() : '';
        }

        attribute.attributeCode = it.attributeCode;
        attribute.attributeId = it.attributeId;
        arrChildren.push(attribute);
      });
      const newObject = Object.assign({}, item);
      newObject.children = result;
      item = newObject;
      return item;
    });
    const newAttributeSet = Object.assign({}, this.attributeSet);
    newAttributeSet.group_list_attribute = attributes;
    this.attributeSet = newAttributeSet;
    const productObject = {
      attributeValues : arrChildren,
      customAttributeSetId: this.productForm.controls.customAttributeSetId.value,
      source: 'source',
      categoryId: this.categorySelected,
      barcode: this.productForm.controls.barcode.value.trim(),
      enterpriseId: this.productForm.controls.enterpriseId.value,
      scanStatus: this.productForm.controls.scanStatus.value,
      alertId: this.productForm.controls.alertId.value,
    };

    if (!this.isUpdate) {
      this.productService.createProduct(productObject).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any) => {
        if (res.code === 1104) {
          this.confirmActionCreate(productObject);
        } else {
          this._snackBar.open('Thêm mới sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          const newObject = Object.assign({}, this.filterProduct);
          for (const key in newObject) {
            newObject[key] = '';
          }
          const alert = new AlertTekoDto('success', 'success')
          alertEvent(alert);
          const click = new ClickTekoDto('product_create', 'create', 'http://datahub.icheck.vn/products', {barcode: res.data.barcode});
          clickTeko(click)
          this.store.dispatch(
            ProductListPageActions.loadProducts({
              filter: newObject,
              page: 1,
              pageSize: 10,
            })
          );
          setTimeout(() => {
            this.backToListProduct();
          }, 1000);
          this.attributeSet = {};
        }
      }, err => {
        this._snackBar.open(err, 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      });
      // this.store.dispatch(ProductPageActions.addProduct({ productObject }));
    } else {
      const productObject = {
        ...this.productCurrent,
        attributeValues : arrChildren,
        id: this.productCurrent.id,
        group_attribute_list : this.attributeSet.group_list_attribute,
        categoryId: this.categorySelected,
        barcode: this.productForm.controls.barcode.value,
        enterpriseId: this.productForm.controls.enterpriseId.value,
        customAttributeSetId: this.productForm.controls.customAttributeSetId.value,
        scanStatus: this.productForm.controls.scanStatus.value,
        alertId: this.productForm.controls.alertId.value,
      };
      const click = new ClickTekoDto('product_detail', 'product_update', 'http://datahub.icheck.vn/products',
        {product_id: productObject.id, barcode: productObject.barcode});
      clickTeko(click);
      this.productService.updateProduct(productObject).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          if (res.code === 1104) {
            this.confirmActionUpdate(productObject);
          } else {
            this._snackBar.open('Chỉnh sửa sản phẩm thành công', 'Cancel', {
              duration: 2000,
              panelClass: ['snackbar-success']
            });
            this.store.dispatch(
              ProductListPageActions.loadProducts({
                filter: this.filterProduct,
                page: 1,
                pageSize: 10,
              })
            );
            setTimeout(() => {
              this.backToListProduct();
            }, 1000);
            this.attributeSet = {};
          }

        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
    }

  }

  confirmActionCreate(object) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createProduct(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Thêm mới sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.store.dispatch(
            ProductListPageActions.loadProducts({
              filter: {},
              page: 1,
              pageSize: 10,
            })
          );
          setTimeout(() => {
            this.backToListProduct();
          }, 500);
          this.attributeSet = {};
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
        // request.params= {
        //   requestType: 3
        // };
      }
      console.log('The dialog was closed');
    });
  }

  confirmActionUpdate(object) {
    console.log(3123);

    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = object;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.updateProduct(request).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          this._snackBar.open('Cập nhật sản phẩm thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.store.dispatch(
            ProductListPageActions.loadProducts({
              filter: {},
              page: 1,
              pageSize: 10,
            })
          );
          setTimeout(() => {
            this.backToListProduct();
          }, 500);
          this.attributeSet = {};
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      } else {
      }
      console.log('The dialog was closed');
    });
  }

  clearInputSearchGln() {
    this.productForm.patchValue({inputSearchGln: ''});
    this.filterGln = {  };
    this.pageGln = 1;
    this.listGln = [];
    this.getListEnterprise();

  }

  clearInputSearchCategory() {
    console.log('aaaaaaaa');

    this.productForm.patchValue({
      inputSearchCategory: '',
      categoryId: ''
    });

    this.filterCategory = {  };
    this.pageCategory = 1;
    this.listCategory = [];
    this.getListCategory();

  }

  clearInputSearchAttributeSet() {
    this.productForm.patchValue({inputSearchAttributeSet: ''});

    this.filterAttributeSet = {  };
    this.pageAttributeSet = 1;
    this.listAttributeSet = [];
    this.getListAttributeSet();
  }

  clearSelected(id) {
    this.productForm.get('attributes').patchValue({
      [id]: null
    });
    this.allSelected = false;
    this.productForm.updateValueAndValidity();
  }

  clearSelectedControl(key) {
    this.productForm.get(key).setValue('');
    this.categorySelected = '';
    if (key === 'customAttributeSetId') {
      this.attributeSet = {};
    }
    this.productForm.updateValueAndValidity();

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
