import { createAction, props } from '@ngrx/store';



export const loadProductAttributes = createAction(
  '[ProductAttribute List Page] Load ProductAttributes',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadProductAttributesSuccess = createAction(
  '[ProductAttribute List Page] Load ProductAttributes Success',
  props<{ total: number, ids: number[] }>()
);

export const loadProductAttributesFailure = createAction(
  '[ProductAttribute List Page] Load ProductAttributes Failure',
  props<{ error: any }>()
);


export const loadListProductAttributeByParent = createAction(
  '[ProductAttribute List Page] Load List ProductAttribute Parent',
  props<{ filter: any, limit: number, offset: number, parent_id: number }>()
);

export const loadListProductAttributeByParentSuccess = createAction(
  '[ProductAttribute List Page] Load List ProductAttribute Parent Success',
  props<{ total: number, ids: number[] }>()
);

export const loadListProductAttributeByParentFailure = createAction(
  '[ProductAttribute List Page] Load List ProductAttribute Parent Failure',
  props<{ error: any }>()
);


export const deleteProductAttribute = createAction(
  '[ProductAttribute List Page] Delete ProductAttribute',
  props<{ id: any }>()
);

export const deleteProductAttributeSuccess = createAction(
  '[ProductAttribute List Page] Delete ProductAttribute Success',
  props<{ id: any }>()
);

export const deleteProductAttributeFailure = createAction(
  '[ProductAttribute List Page] Delete ProductAttribute Failure',
  props<{ error: any }>()
);

export const getDetailAttributeSet = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet',
  props<{ id: any }>()
);

export const getDetailAttributeSetSuccess = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Success',
  props<{ productAttribute: any }>()
);

export const getDetailAttributeSetFailure = createAction(
  '[ProductAttribute Page] Get DetailAttributeSet Failure',
  props<{ error: any }>()
);


export const getListAttributeIgnoreSet = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet',
  props<{ filter: any, limit: number, offset: number, id: number }>()
);

export const getListAttributeIgnoreSetSuccess = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet Success',
  props<{ rows: any, total: number }>()
);

export const getListAttributeIgnoreSetFailure = createAction(
  '[ProductAttribute Page] Get ListAttributeIgnoreSet Failure',
  props<{ error: any }>()
);

export const resetCurrentAttributeSet = createAction(
  '[ProductAttribute Page] Reset Current Attribute Set',
  props<{ action: any }>()
);
