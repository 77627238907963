import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ic-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  host: {
    class: 'ic-content'
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
