import { Store } from '@ngrx/store';
import {Component, Inject, OnInit} from '@angular/core';
import * as fromLoyalty from '../../reducers';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterProgram();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class ProductFilterComponent implements OnInit {


  constructor(private store: Store<fromLoyalty.State>,
              @Inject('availableFilters') public availableFilters: [],

  ) { }

  ngOnInit(): void {
  }



  filterData(event) {
    // console.log(event);

    let arr = [];
    let objectSearch = {};
    event.forEach(item => {
      if(item.key === 'date') {
        const fromDate = {
          key: 'from',
          value: item.value.startDate.getFullYear() + "-" + (item.value.startDate.getMonth() + 1) + "-" + item.value.startDate.getDate(),
        };
        const toDate = {
          key: 'to',
          value: item.value.endDate.getFullYear() + "-" + (item.value.endDate.getMonth() + 1) + "-" + item.value.endDate.getDate(),
        }
        arr.push.apply(arr, [fromDate, toDate])
      } else {
        arr.push({
        key: item.key, value: item.value.value
      })
      }1
    });
    for (var i = 0; i < arr.length; i++) {
      objectSearch[arr[i].key] = arr[i].value;
    }
  }

}
