import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Product } from '../models/product.model';
import * as fromProduct from '../reducers';
import {
  ProductActions, ProductListPageActions,
} from '../actions';
import { DashFilter } from '@dash/filter-bar/filter-bar.component';
import { MdcDialog } from '@angular-mdc/web';
import { formatDate, clean } from 'src/app/shared/common';
declare function searchTeko(info)
@Component({
  selector: 'ic-product-page',
  templateUrl: './product-list-page.component.html',
  styles: []
})
export class ProductPageComponent implements OnInit {
  displayedColumns: string[] = ['select', 'id', 'image', 'name', 'price', 'owned_business', 'business_management', 'scan_sattus', 'authentication_sattus', 'number_of_scans', 'number_of_rate', 'number_of_qa', 'category', 'created_time', 'last_updated_time', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex = 0;
  pageSize = 10;
  availableFilters: DashFilter[] = [];
  title = 'Quản lý sản phẩm';

  selection = new SelectionModel<any>(true, []);

  filter$: Observable<number>;
  loading$: Observable<boolean>;
  total$: Observable<number>;
  products$: Observable<any>;
  displayedColumns$: Observable<string[]>;
  selectedRows;
  page$: Observable<number>;
  pageSize$: Observable<number>;
  page: number;

  constructor(
    private dialog: MdcDialog,
    // private store2: NgxsStore,
    private store: Store<fromProduct.State>) {
      this.products$ = store.pipe(select(fromProduct.selectProductListPageResults));
      this.total$ = store.pipe(select(fromProduct.selectProductListPageTotal));
      this.filter$ = store.pipe(select(fromProduct.selectProductListPageFilter));
      this.loading$ = store.pipe(select(fromProduct.selectProductListPageLoading));
      this.displayedColumns$ = store.pipe(select(fromProduct.selectProductListPageDisplayedColumns));
      this.page$ = store.pipe(select(fromProduct.selectPage));
      this.pageSize$ = store.pipe(select(fromProduct.selectPageSize));
    }

  ngOnInit(): void {

  }

  loadProduct([pageData, filterData]: any) {
    const limit = pageData ? pageData.pageSize : this.pageSize;
    const offset = limit * (pageData ? pageData.pageIndex : 0);
    const filter = clean(filterData);
    this.page$.subscribe(item => {
      this.page = item;
    });
    this.pageSize$.subscribe(item => {
      this.pageSize = item;
    });
    searchTeko({...filter,      page: pageData.pageIndex + 1, pageSize: pageData.pageSize,})
    this.store.dispatch(
        ProductListPageActions.loadProducts({
          filter,
          page: pageData.pageIndex + 1,
          pageSize: pageData.pageSize,
        })
      );

  }
  onSelectedRows(e) {
    this.selectedRows = e;
  }
}
