import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductAttributeActions } from '../actions';


export const productAttributeListFeatureKey = 'productAttributeList';

export interface State {
  ids: number[];
  filter: any;
  total: number;
  loading: boolean;
}

export const initialState: State = {
  ids: [],
  filter: {},
  total: 0,
  loading: false
};

export const productAttributeListReducer = createReducer(
  initialState,
  // on(
  //   ProductAttributeActions.loadProductAttribute,
  //   (state, { filter }) => {
  //     return {
  //       ...state,
  //       filter,
  //       loading: true,
  //     }
  //   }
  // ),
  // on(
  //   ProductAttributeActions.loadCategoriesSuccess,
  //   (state, { productAttributes, total }) => {
  //     return {
  //       ...state,
  //       ids: productAttributes.map(item => item.id),
  //       total: total,
  //       loading: false,
  //     }
  //   }
  // ),
  // on(
  //   ProductAttributeActions.searchProductAttributeSuccess,
  //   (state, { productAttributes }) => {
  //     return {
  //       ...state,
  //       ids: productAttributes.list.map(item => item.id),
  //       total: productAttributes.total,
  //       loading: false,
  //     }
  //   }
  // ),
);

export function reducer(state: State | undefined, action: Action) {
  return productAttributeListReducer(state, action);
}

export const getIds = (state: State) => state.ids;
export const getTotalProductAttribute = (state: State) => state.total;

export const getObjectFilter = (state: State) => state.filter;

export const getLoadingData = (state: State) => state.loading;
