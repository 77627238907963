import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { CategoryListPageActions } from '@category/actions';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ProductPageActions, ProductListPageActions } from '@products/actions';
import { Validators, FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import * as fromProduct from '@products/reducers';
import * as fromCategory from '@category/reducers';

import * as fromGln from '@gln/reducers';
import { CreateProductDocumentsComponent } from '@products/components/create-product-documents/create-product-documents.component';
import { ProductDocumentsPackageComponent } from '@products/components/product-documents-package/product-documents-package.component';
import { GlnListPageActions } from '@gln/actions';
import { CreateEnterpriseComponent } from '../create-enterprise/create-enterprise.component';
import { ProductService } from '@products/services/product.services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlnService } from '@gln/services/gln.service';
import { uniqueObjectInArray, onlyUnique, buildImageUrls } from '@src/app/shared/common';
import { MatSelect } from '@angular/material/select';
import { MdcDialog } from '@angular-mdc/web';
import { AlertConfirmComponent } from '@dash/alert-confirm';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {ActivatedRoute, Router} from '@angular/router';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function clickTeko(info);
declare function alertEvent(info);
@Component({
  selector: 'ic-product-distributor-create',
  templateUrl: './product-distributor-create.component.html',
  styleUrls: ['./product-distributor-create.component.scss']
})
export class
ProductDistributorCreateComponent implements OnInit {

  @ViewChild('inputUpload', { read: ElementRef, static: false }) inputUpload: ElementRef;

  barcode: string
  createDocumentForm;
  listBarcode = [];
  enterprises$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listCategory: any;
  currentUser: any;
  listFileImport = [];
  listEnterprise = [];
  currentEnterprise: any = {};
  listDocument = [];
  listTitle = [];
  listIds = [];
  destroy$ = new Subject();
  pageTitle = 1;
  filterGln = {};
  pageGln = 1;
  typeCurrent: any;
  productCurrent$: Observable<any>;
  private matSelectEnterprise: MatSelect;

  @ViewChild('matSelectEnterprise', { static: false }) set contentEnterprise(contentEnterprise: MatSelect) {
      if (contentEnterprise) { // initially setter gets called with undefined
          this.matSelectEnterprise = contentEnterprise;
          this.matSelectEnterprise.openedChange.subscribe(opened => {
            // console.log(opened)
            if (!opened && !this.listEnterprise.length) {

              this.filterGln = {  };
              this.pageGln = 1;
              this.listEnterprise = [];
              this.getListEnterprise();

              this.createDocumentForm.patchValue({inputSearchDistributor: ''});
            }
          });

      }
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductDistributorCreateComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeCategory: Store<fromCategory.State>,
    private store: Store<fromProduct.State>,
    private dialog: MatDialog,
    private storeEnterprise: Store<fromGln.State>,
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private enterpriseService: GlnService,
    private dialog2: MdcDialog,

    private route: ActivatedRoute,

  ) {
    this.data = data;
    this.productService.getDetailProduct(this.data.productId).subscribe((res : any) => {
      this.barcode = res.data.barcode
      const click = new ClickTekoDto('product_distributor', 'product_distributor_add', null,
        {product_id: this.data.productId, barcode: this.barcode} );
      clickTeko(click);
    });

    this.enterprises$ = storeEnterprise.pipe(select(fromGln.selectGlnListPageResults));
  }

 async ngOnInit() {

    this.createDocumentForm = this.formBuilder.group({
      // barcodes: '',
      enterpriseId: ['', Validators.required],
      documentIds: ['', Validators.required],
      type: ['', Validators.required],
      title: ['', Validators.required],
      inputSearchDistributor: ['']
    });


    this.createDocumentForm.get('type').valueChanges.subscribe(x => {
      this.typeCurrent = x;
      this.getListTitleByType();
      this.createDocumentForm.patchValue({
        title: ''
      });
   });

    this.getListEnterprise();

    this.createDocumentForm.value;
  }

  getListEnterprise() {
    console.log('aaaa');
    this.enterpriseService.getListEnterprise({page: this.pageGln, pageSize: 10, filter: this.filterGln}).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      // console.log(res);
      this.listEnterprise = [...this.listEnterprise, ...res.data.items];
    });
  }

  lazyLoadEnterprise() {
    this.pageGln += 1;
    this.getListEnterprise();
  }

  getListTitleByType() {
    this.productService.getDetailTitleOfRole({
      type: this.typeCurrent,
      page: this.pageTitle,
      pageSize: 10
    }).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.listTitle = [...this.listTitle, ...res.data.items];
    });
  }

  lazyListTitleByType() {
    this.pageTitle += 1;
    this.getListTitleByType();
  }

  changeSelected(event) {
    // console.log(event);
    if (event.value) {
      this.currentEnterprise = this.listEnterprise.filter(item => item.id === event.value)[0];
      // console.log('currentEnterpriseeee', this.currentEnterprise);
    } else {
      this.currentEnterprise = {};
    }
  }

  clearSelectedControl(key) {
    this.createDocumentForm.patchValue({
      [key]: null
    });
    if (key === 'enterpriseId') {
      this.currentEnterprise = {};
    }
    this.createDocumentForm.updateValueAndValidity();
  }


  onFormSubmit() {
    // console.log(this.createDocumentForm.value);

    this.productService.createDistributorOfProduct(this.data.productId, this.createDocumentForm.value).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      if (res.code === 1104) {
        this.confirmAction(this.data.productId, this.createDocumentForm.value);
      } else {

        this._snackBar.open('Thêm mới nhà phân phối thành công', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        this.dialogRef.close('accept');
      }

    }, err => {
      this._snackBar.open(err, 'Cancel', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    });
  }

  confirmAction(productId, body) {
    const dialogRef = this.dialog2.open(AlertConfirmComponent, {
      data: 'Dữ liệu thay đổi sẽ đồng thời được cập nhật trên các hệ thống? Bạn có chắc chắc muốn thực hiện thao tác này không?'
    });
    const request = body;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
        request.params = {
          requestType: 1
        };
        this.productService.createDistributorOfProduct(productId, request).pipe(
          takeUntil(this.destroy$)
        ).subscribe(res => {
          this._snackBar.open('Thêm mới nhà phân phối thành công', 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
          this.dialogRef.close('accept');
        }, err => {
          this._snackBar.open(err, 'Cancel', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
      }
      console.log('The dialog was closed');
    });
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  clearSelected(key) {
    this.createDocumentForm.patchValue({
      [key]: null
    });
    this.createDocumentForm.updateValueAndValidity();
  }

  deleteDocument(i) {
    this.listDocument.splice(i, 1);
    this.listIds.splice(i, 1);
    this.createDocumentForm.controls.documentIds.setValue(this.listIds);
  }

  changeInputDistributorSearch(event) {
    if (!this.searchCategoryObserver) {
      Observable.create(observer => {
        // console.log(observer);

        this.searchCategoryObserver = observer;
      }).pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        )
        .subscribe(it => {

          this.filterGln = { name_code : it };
          this.pageGln = 1;
          this.listEnterprise = [];
          this.getListEnterprise();
        });
    }
    this.searchCategoryObserver.next(event.target.value);
  }



  clearInputDistributorSearch() {
    this.createDocumentForm.patchValue({inputSearchDistributor: ''});
    this.filterGln = {  };
    this.pageGln = 1;
    this.listEnterprise = [];
    this.getListEnterprise();
  }


  assignDocumentFromPackage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Kho giấy tờ',
      action: 'select',
      productId:  this.data.productId
    };
    const click = new ClickTekoDto('product_document', 'product_document_link', 'http://datahub.icheck.vn/products/update/ljOyWmyP', {
      product_id: this.data.productId,
      barcode: this.barcode
    });
    clickTeko(click)
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ProductDocumentsPackageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // console.log('resultttttttttttt', result);
      if (result !== 'close' && result.length) {
        this.listDocument = uniqueObjectInArray(this.listDocument.concat(result));
        // console.log(this.listDocument);

        this.listIds = this.listIds.concat(result.map(item => item.id)).filter(onlyUnique);
        this.createDocumentForm.controls.documentIds.setValue(this.listIds);
      }
    });
  }

  createNewDocument() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm giấy tờ',
      action: 'create',
      type: 'get',
      productId: this.data.productId
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateProductDocumentsComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(result => {


      if (result !== 'close') {
        this.listDocument.push(result);
        this.listIds.push(result.id);
        // console.log(this.listIds);
        // console.log(this.listDocument);
        this.createDocumentForm.controls.documentIds.setValue(this.listIds);
      }
    });
  }

  createEnterprise() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '60rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm nhà phân phối mới',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(CreateEnterpriseComponent, dialogConfig);


    dialogRef.afterClosed().subscribe(result => {


      if (result !== 'close') {
        this.enterpriseService.getDetailEnterprise(result).pipe(
          takeUntil(this.destroy$)
        ).subscribe((res: any) => {
          // console.log('resssss', res)
          this.currentEnterprise = res.data;
          this.listEnterprise.push(res.data);
          this.createDocumentForm.patchValue({
            enterpriseId: res.data.id
          });
        });
      }
    });
  }

  openNewTab(url) {
    const img = buildImageUrls(url).original;
    if (img) {
        window.open(img, '_blank');
      } else {
        this._snackBar.open('Ảnh không tồn tại', 'Cancel', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
