import {Component, OnInit, Inject, ViewChild, AfterViewInit} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as fromAttribute from '@attribute/reducers';
import { onlyUnique } from '@src/app/shared/common';
import { MdcDialog } from '@angular-mdc/web';
import { ConfirmRemoveComponent } from './confirm-remove/confirm-remove.component';
import { MatSelect } from '@angular/material/select';
import { AttributeProductService } from '@attribute/services/attribute-product.service';
import { MatOption } from '@angular/material/core';
import {ModalScroll} from '@src/app/shared/common/modal-scroll';
import {ALERT, TekoValueDto} from '@src/app/shared/common/teko-value.dto';
import {ClickTekoDto} from '@src/app/shared/common/click-teko.dto';
import {AlertTekoDto} from '@src/app/shared/common/alert-teko.dto';
declare function enterScreenView(screenName, contentType);
declare function clickTeko(info);
declare function alertEvent(info);

@Component({
  selector: 'ic-remove-info-product',
  templateUrl: './remove-info-product.component.html',
  styleUrls: ['./remove-info-product.component.scss']
})
export class RemoveInfoProductComponent extends ModalScroll implements OnInit , AfterViewInit {
  formGroup;
  listBarcode = [];
  // listBarcodeDisplay;
  // listCodeDisplay
  categories$: Observable<any>;
  searchChangeObserver;
  searchCategoryObserver;
  categoryInput = '';
  isValidButton = false;
  data: any;
  check_barcode: any[] = [];
  barcodes: string;
  attributes$: Observable<any>;
  listSelected = [];
  listDataSearch = [];
  listAttribute = [];
  inputSearch = '';
  allSelected = true;
  currentUser: any;
  pageAttribute = 1;
  filterAttribute = {};
  isLoading = false;

  @ViewChild('select') select: MatSelect;

   /** control for the MatSelect filter keyword multi-selection */
  public bankMultiFilterCtrl: FormControl = new FormControl();

  public filteredAttribute: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  private _onDestroy = new Subject<void>();

  @ViewChild(MatSelect) matSelect: MatSelect;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RemoveInfoProductComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private storeAttribute: Store<fromAttribute.State>,
    private dialog2: MdcDialog,
    private attributeService: AttributeProductService,
  ) {
    super();
    this.data = data;
    this.attributes$ = storeAttribute.pipe(select(fromAttribute.selectAttributeListPageResults));
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    enterScreenView(TekoValueDto.PRODUCT_DELETE_ATTRIBUTE.SCREEN_NAME, TekoValueDto.PRODUCT_DELETE_ATTRIBUTE.CONTENT_TYPE);

    this.formGroup = this.formBuilder.group({
      attributeIds: ['', Validators.required],
      barcodes: ['', Validators.required],
      inputSearch: [''],
      allowUpdate: [false],
      allowUpdateProduct: [false]
    });

    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanksMulti();
      });

    if (this.data.selectedRows.length) {
      const barcodes = this.data.selectedRows.map(item => item.barcode).join('\n');
      this.formGroup.patchValue({
        barcodes
      });
      this.checkBarcode();
    }
    this.getListAttribute();
  }

  getListAttribute() {
    this.attributeService.getListAllAttribute({
      page: this.pageAttribute, pageSize: 1000, filter: this.filterAttribute
    }).pipe(
      takeUntil(this._onDestroy)
    ).subscribe((res: any) => {
      this.listAttribute = [...this.listAttribute, ...res.data.items];
      this.listDataSearch = [...this.listDataSearch, ...res.data.items];
      this.filteredAttribute.next(this.listAttribute.slice());
    });
  }

  lazyLoadAttribute() {
    this.pageAttribute += 1;
    this.getListAttribute();
  }

  ngAfterViewInit() {
    // @ts-ignore
    document.querySelector('.cdk-global-overlay-wrapper').style.overflow = 'scroll';
    // @ts-ignore
    document.querySelector('.cdk-overlay-pane').style.overflow = 'scroll';
    this.matSelect.openedChange.subscribe(opened => {
      if (opened) {
       this.optionClick();
      }
    });
  }
  filterBanksMulti() {
    let search = this.bankMultiFilterCtrl.value;
    if (!search) {
      this.filteredAttribute.next(this.listAttribute.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.listDataSearch = this.listAttribute.filter(attribute => attribute.attributeLabel.toLowerCase().indexOf(search) > -1);
    this.filteredAttribute.next(
      this.listAttribute.filter(attribute => attribute.attributeLabel.toLowerCase().indexOf(search) > -1)
    );
    console.log(this.listAttribute);
  }
  changeSelected(event) {
    // console.log(event);
    // this.listSelected = this.listSelected.concat(event.value).filter( onlyUnique );
    // this.formGroup.get('attributeIds').setValue(this.listSelected);
    // console.log(this.listSelected);

  }
  onFormSubmit() {


    const object = {
      'select-all': +this.allSelected,
      attributeIds: this.formGroup.value.attributeIds,
      barcodes: this.formGroup.value.barcodes.split(/\r?\n/).filter(onlyUnique),
      updateBusinessManager: +this.formGroup.value.allowUpdate,
      allowUpdateProduct: this.formGroup.value.allowUpdateProduct
    };
    const  click = new ClickTekoDto(TekoValueDto.PRODUCT_DELETE_ATTRIBUTE.REGION_NAME,
      TekoValueDto.PRODUCT_DELETE_ATTRIBUTE.CONTENT_NAME,
      TekoValueDto.PRODUCT_DELETE_ATTRIBUTE.TARGET, {
      totalBarcode: object.barcodes,
      userId : this.currentUser.userId
    });
    clickTeko(click);

    this.openConfirmDialog(object);
    // console.log(object);
  }
  openConfirmDialog(data) {
    const dialogRef = this.dialog2.open(ConfirmRemoveComponent, {
      data: {
        ...data,
        message: 'Bạn có chắc chắn muốn xóa thông tin của các trường này không?'
      },
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result !== 'close') {
        this.dialogRef.close();
      }
      console.log('The dialog was closed');
    });
  }

  toggleAllSelection() {
    if (this.allSelected) {
        this.listSelected = [...this.listDataSearch.map(item => item.attributeLabel)];
        this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.listSelected = [];
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
    console.log(this.listSelected);

  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.listSelected = this.listAttribute.filter((item: any) => this.formGroup.value.attributeIds.indexOf(item.attributeId) !== -1).map(item => item.attributeLabel);
    console.log(this.listSelected);
    // console.log(newStatus);

    this.allSelected = newStatus;
  }

  closeDialogClick() {
    this.dialogRef.close('close');
  }

  changeInputSearch(event) {
    let search = this.formGroup.value.inputSearch;
    if (!search) {
      this.filteredAttribute.next(this.listAttribute.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredAttribute.next(
      this.listAttribute.filter(attribute => attribute.attributeLabel.toLowerCase().indexOf(search) > -1)
    );
  }

  clearSelected() {
    this.formGroup.patchValue({
      attributeIds: ''
    });
    this.allSelected = false;
  }

}
