import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { MdcDialog } from '@angular-mdc/web';
import { Store, select } from '@ngrx/store';
import * as fromProductAttribute from '../reducers';
import { DashFilter } from '@dash/filter-bar/filter-bar.component';
import { ProductAttributeListPageActions } from '../actions';
import { clean } from '@src/app/shared/common';

@Component({
  selector: 'product-attribute-container',
  template: `
  <div>
  <ic-product-attribute-group-list
    [pageSize]="pageSize"
    [total]="total$ | async"
    [rows]="productAttributes$ | async"
    [displayedColumns]="displayedColumns$ | async"
    (load)="loadProductAttribute($event)">
  </ic-product-attribute-group-list>
</div>
  `,
  styles: []
})
export class ProductAttributeGroupContainer implements AfterViewInit, OnInit {
  
  displayedColumns: string[] = ['select', 'id', 'image', 'name',  'order_sort', 'rating_attribute', 'product_attribute', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  pageSize: number = 10;
  availableFilters: DashFilter[] = [];
  title = "Quản lý danh mục"

  selection = new SelectionModel<any>(true, []);

  filter$: Observable<number>;
  loading$: Observable<boolean>;
  total$: Observable<number>;
  productAttributes$: Observable<any>;
  displayedColumns$: Observable<string[]>;

  constructor(
    private dialog: MdcDialog,
    // private store2: NgxsStore,
    private store: Store<fromProductAttribute.State>) {
      this.productAttributes$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageResults));
      this.total$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageTotal));
      this.filter$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageFilter));
      this.loading$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageLoading));
      this.displayedColumns$ = store.pipe(select(fromProductAttribute.selectProductAttributeListPageDisplayedColumns));
  }
  
  ngOnInit() {
  }
  ngAfterViewInit() {
  }

  loadProductAttribute([pageData, filterData]: any) {
    const limit = pageData ? pageData.pageSize : this.pageSize;
    const offset = limit * (pageData ? pageData.pageIndex : 0);
    const filter = clean(filterData)
    this.store.dispatch(
      ProductAttributeListPageActions.loadProductAttributes({
        filter,
        page: pageData.pageIndex + 1,
        pageSize: pageData.pageSize,
      })
    );
  }
}