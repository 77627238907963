import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';


@Component({
  selector: 'ic-warning-product-page',
  template: `
  <div>
    <dash-title [title]="title"></dash-title>
    <ic-warning-product-list></ic-warning-product-list>
  </div>
  `,
  styles: []
})
export class WarningProductPageComponent implements AfterViewInit, OnInit {
  title = "Cảnh báo sản phẩm"
  ngOnInit() {
  }
  ngAfterViewInit() {
  }
}
