import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { MdcDialog } from '@angular-mdc/web';
import { WarningProductModalComponent } from '../warning-product-modal/warning-product-modal.component';
import { DetailPaperModalComponent } from '../detail-paper-modal/detail-paper-modal.component';
import { formatDate } from 'src/app/shared/common';
import { GlnActions } from '../../../gln/actions';
import * as fromGln from '../../../gln/reducers';
import { takeUntil } from 'rxjs/operators';
import {FilterAttribute} from '@src/app/shared/FilterTable/filter-attribute';

@Component({
  selector: 'ic-warning-product-list',
  templateUrl: './warning-product-list.component.html',
  styleUrls: ['./warning-product-list.component.scss'],
  providers: [
    FilterAttribute,
    {
      provide: 'availableFilters',
      useFactory: ( factory: FilterAttribute ) => {
        return factory.getFilterWarningProduct();
      },
      deps: [FilterAttribute]
    }
  ]
})
export class WarningProductListComponent implements OnInit {

  displayedColumns: string[] = ['select', 'id', 'image', 'name',  'gln', 'warning_description', 'paper', 'created_at','updated_at', 'action'];
  dataSource = new MatTableDataSource<any>();
  totalSize: number;
  pageIndex: number = 0;
  pageSize: number = 5;
  filter$: Observable<Object>;
  loading$: Observable<boolean>;
  success$: Observable<boolean>;
  length$: Observable<number>;
  glns$: Observable<any>;
  nations$: Observable<any>;
  listGln = [];
  selection = new SelectionModel<any>(true, []);
  destroy$ = new Subject();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromGln.State>,
    @Inject('availableFilters') public availableFilters : [],
    public dialog2: MdcDialog) {
      this.glns$ = store.pipe(select(fromGln.selectGlnListResults));
      this.length$ = store.pipe(select(fromGln.selectTotalGln));
      this.filter$ = store.pipe(select(fromGln.selectObjectFilterGln));
      this.loading$ = store.pipe(select(fromGln.selectLoadingDataGln));
      this.success$ = store.pipe(select(fromGln.selectSuccess));
      this.nations$ = store.pipe(select(fromGln.selectGlnListNation));
    }

  ngOnInit(): void {
    this.glns$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.dataSource.data = [];
    })
    this.length$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      this.totalSize = it;
    });
    this.loadGln();
    this.store.dispatch(GlnActions.loadNation({request: {
      type: 0
    }}));
  }

  loadGln() {
    const object = {
      offset: 0,
      limit: 5,
  }
  this.store.dispatch(GlnActions.loadGln({filter: object}));
  }

  createWarningProduct() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Thêm sản phẩm cảnh báo',
      action: 'create',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(WarningProductModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
      }
      console.log('The dialog was closed');
    });
  }

  openDetailPaper(element){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Chi tiết giấy tờ',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(DetailPaperModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
      }
      console.log('The dialog was closed');
    });
  }

  updateWarningProduct(element) {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = '40rem';
    // dialogConfig.height = 'auto';
    // dialogConfig.data = {
    //   label: 'Sửa mã địa điểm toàn cầu GLN',
    //   action: 'update',
    //   element
    // };
    // const dialogRef = this.dialog.open(GlnModalComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result !== close) {
    //   }
    //   console.log('The dialog was closed');
    // });
  }

  removeWarningProduct(element) {
    // console.log(element);
    // const dialogRef = this.dialog2.open(AlertConfirmComponent, {
    //   data: 'Bạn có đồng ý muốn xóa GLN này không?'
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result !== 'close') {
    //     this.store.dispatch(GlnActions.deleteGln({id : element.id}));
    //     // this.success$.subscribe(it => {
    //     //   if (it == true) {
    //     //     console.log('123');
    //     //     this.loadGln();
    //     //   }

    //     // })
    //   }
    //   console.log('The dialog was closed');
    // });

  }

  handlePage(event: any) {
    let object = {};
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.filter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(it => {
      object = {
        ...it,
        offset: event.pageIndex * event.pageSize,
        limit: event.pageSize,
      }
    });
    this.store.dispatch(GlnActions.loadGln({filter: object}));
  }

  filterData(event) {
    // console.log(event)
    let objectSearch = {};
    event.forEach(item => {
      if (item.type === 'daterange') {
        objectSearch['from'] = formatDate(item.value.startDate);
        objectSearch['to'] = formatDate(item.value.endDate);
      } else if(item.type === 'select-box') {
        objectSearch[item.key] = item.value.value.value;
      } else {
        objectSearch[item.key] = item.value.value;
      }
    });
    const object = {
      offset: 0,
      limit: 5,
      ...objectSearch,
    }
    // console.log(object);
    this.store.dispatch(GlnActions.loadGln({filter: object}));
  }

  skipWarning() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '40rem';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      label: 'Chi tiết giấy tờ',
    };
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(DetailPaperModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'close') {
      }
      console.log('The dialog was closed');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
