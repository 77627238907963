import { Component, Input, OnInit } from '@angular/core';

import { DateRangeFilterValue } from './date-range-setting.component';

@Component({
  selector: 'dash-date-range-display',
  templateUrl: './date-range-display.component.html',
  styleUrls: ['./date-range-display.component.scss']
})
export class DateRangeDisplayComponent implements OnInit{

  @Input()
  data: DateRangeFilterValue;

  ngOnInit() {
  }

}
