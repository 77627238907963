<div>
        <div class="content-modal">
            <div class="form-group first-form-group">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="attribute_type">
                    <mat-radio-button value="1" >Chọn bộ thuộc tính có sẵn</mat-radio-button>
                    <mat-radio-button value="2" style="float: right;">Chọn danh sách thuộc tính</mat-radio-button>
                  </mat-radio-group>
            </div>
            <div class="form-group" *ngIf="attribute_type === '1'">
                <div class="label-form">Chọn bộ thuộc tính sản phẩm</div>
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>Chọn bộ thuộc tính</mat-label>
                    <mat-select>
                        <mat-option value="category1">Danh mục 1</mat-option>
                        <mat-option value="category2">Danh mục 2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group" *ngIf="attribute_type === '2'">
                <h4 class="label-form">Chọn danh sách thuộc tính</h4>
                <mat-form-field appearance="outline" class="form-field" style="width: 200px;">
                    <input matInput>
                    </mat-form-field>
                <section class="example-section">
                    <mat-checkbox class="example-margin">Thuộc tính 1</mat-checkbox>
                  </section>
                <section class="example-section">
                    <mat-checkbox class="example-margin" >Thuộc tính 2</mat-checkbox>
                </section>
                <!-- </mat-form-field> -->
            </div>
        </div>  
</div>
