import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ic-check-category-id-modal',
  templateUrl: './check-category-id-modal.component.html',
  styleUrls: ['./check-category-id-modal.component.scss']
})
export class CheckCategoryIdModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CheckCategoryIdModalComponent>) { }

  ngOnInit(): void {
  }

  closeDialogClick() {
    this.dialogRef.close();
  }

}
