import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'ic-forget-auth',
  templateUrl: './forget-auth.component.html',
  styleUrls: ['./forget-auth.component.scss']
})
export class ForgetAuthComponent implements OnInit {

  constructor(private router: Router,private fb: FormBuilder,) { }
  forgetForm: any;

  ngOnInit(): void {
    this.forgetForm = this.fb.group({
      phone_number: [ '', Validators.required],
    });
  }

}
