import { createAction, props } from '@ngrx/store';

export const loadGlns = createAction(
  '[Gln Page] Load Glns',
  props<{ filter: any, limit: number, offset: number }>()
);

export const loadGlnsSuccess = createAction(
  '[Gln Page] Load Glns Success',
  props<{ total: number, rows: any }>()
);

export const loadGlnsFailure = createAction(
  '[Gln Page] Load Glns Failure',
  props<{ error: any }>()
);

export const addGln = createAction(
  '[Gln Page] Add Gln',
  props<{ categoryObject: any }>()
);

export const addGlnSuccess = createAction(
  '[Gln Page] Add Gln Success',
  props<{ category: any }>()
);

export const addGlnFailure = createAction(
  '[Gln Page] Add Gln Failure',
  props<{ error: any }>()
);


export const updateGln = createAction(
  '[Gln Page] Update Gln',
  props<{ categoryObject: any }>()
);

export const updateGlnSuccess = createAction(
  '[Gln Page] Update Gln Success',
  props<{ category: any }>()
);

export const updateGlnFailure = createAction(
  '[Gln Page] Update Gln Failure',
  props<{ error: any }>()
);



export const getAttributeSetByGln = createAction(
  '[Gln Page] Get AttributeSet',
  props<{ id: any }>()
);

export const getAttributeSetByGlnSuccess = createAction(
  '[Gln Page] Get AttributeSet Success',
  props<{ listAttribute: any }>()
);

export const getAttributeSetByGlnFailure = createAction(
  '[Gln Page] Get AttributeSet Failure',
  props<{ error: any }>()
);


export const getDetailGln = createAction(
  '[Gln Page] Get Detail Gln',
  props<{ id: any }>()
);

export const getDetailGlnSuccess = createAction(
  '[Gln Page] Get Detail Gln Success',
  props<{ gln: any }>()
);

export const getDetailGlnFailure = createAction(
  '[Gln Page] Get Detail Gln Failure',
  props<{ error: any }>()
);
