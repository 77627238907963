import { createAction, props } from '@ngrx/store';



export const loadAlerts = createAction(
  '[Alert List Page] Load Alerts',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadAlertsSuccess = createAction(
  '[Alert List Page] Load Alerts Success',
  props<{ total: number, ids: number[] }>()
);

export const loadAlertsFailure = createAction(
  '[Alert List Page] Load Alerts Failure',
  props<{ error: any }>()
);


export const loadListAlertByParent = createAction(
  '[Alert List Page] Load List Alert Parent',
  props<{ filter: any, limit: number, offset: number, parent_id: number }>()
);

export const loadListAlertByParentSuccess = createAction(
  '[Alert List Page] Load List Alert Parent Success',
  props<{ total: number, ids: number[] }>()
);

export const loadListAlertByParentFailure = createAction(
  '[Alert List Page] Load List Alert Parent Failure',
  props<{ error: any }>()
);

export const getTreeAlert = createAction(
  '[Alert List Page] Get Tree Alert',
  props<{ filter: any, limit: number, offset: number }>()
);

export const getTreeAlertSuccess = createAction(
  '[Alert List Page] Get Tree Alert Success',
  props<{ treeAlert: object }>()
);

export const getTreeAlertFailure = createAction(
  '[Alert List Page] Get Tree Alert Failure',
  props<{ error: any }>()
);
