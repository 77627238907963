
 <!-- <form [formGroup]="columnAvaiableForm" (ngSubmit)="onFormSubmit()"> -->
 <div class="toggle-row-content">
        <a class="toggle-row" (click) = "togglePopupSelect()">
          <mat-icon class="icon-settings">settings</mat-icon>
          <span>Ẩn/hiện cột</span>
          <mat-icon>expand_more</mat-icon>
        </a>
        <div class="select-row-popup" *ngIf="isOpenPopupSelect" >
          <section class="example-section" *ngFor="let column of listColumnAvaiable">
            <mat-checkbox class="example-margin" (change) = "changeDisplayColumn($event)" formControlName="column.key" [value] = "column.key" [checked] = "column.key"
            >
              {{column.label}}
            </mat-checkbox>
          </section>
        </div>
      </div>
<!-- </form> -->
  
   <!-- <h1>Column selector</h1> -->