import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web';
import { DashFilterSettingData } from '..';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'ic-select-box-setting',
  templateUrl: './select-box-setting.component.html',
  styleUrls: ['./select-box-setting.component.scss']
})
export class SelectBoxSettingComponent implements OnInit, AfterViewInit {

  options: any = [];
  form: FormGroup;
  // option: string;
  isMutipleSelect: boolean;
  value: any;

  constructor(
    public dialogRef: MdcDialogRef<SelectBoxSettingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<any>,
    private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    }

  ngOnInit(): void {
    this.form = this.fb.group({
      value: this.fb.control(null, [Validators.required]),
    });
    if (this.data.value) {
      this.form.setValue({
        value: this.data.value.value.value,
      });
      this.form.updateValueAndValidity();
    }
  }
  ngAfterViewInit() {
    if (this.data.filter) {
      let config = this.data.filter.settingComponentConfig;
      this.isMutipleSelect = this.data.filter.isMutipleSelect;
      this.options = config.options;
    }
    this.cdr.detectChanges();
  }

  onApply() {
    if(this.form.valid) {
      this.dialogRef.close({
        value: this.form.value,
        label: this.options.filter(item => item.value === this.form.value.value).map(it => it.name).join('')
      });
    }
  }

}
