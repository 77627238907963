import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from './../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as fromAlert from '../reducers';
import { AlertActions } from '../actions';
import { MdcDialogRef } from '@angular-mdc/web';
@Component({
  selector: 'ic-alert-confirm-modal',
  templateUrl: './alert-confirm-modal.component.html',
  styleUrls: ['./alert-confirm-modal.component.scss']
})
export class AlertConfirmModalComponent implements OnInit {
  id
  constructor(
    public dialogRef: MdcDialogRef<AlertConfirmModalComponent>,
  ) {
  }

  ngOnInit(): void {

  }
}
