import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    constructor( public snackBar: MatSnackBar) {
    }
    getNotification(message: string = 'Thành công', action: string = 'Cancel', panel: 'success' | 'fail' = 'success') {
      this.snackBar.open(message , action, {
        duration: 2000,
        panelClass: [`snackbar-${panel}`]
      });
    }
}
