<mat-form-field appearance="outline" class="form-field" (click)="openSelectCategory()">
  <input matInput placeholder="Chọn danh mục"  [value]="categorySelected" [title]="categorySelected">
  <mat-icon matSuffix >arrow_drop_down</mat-icon>
</mat-form-field>

<!-- <input placeholder="Please select" (click)="openSelectCategory()" [value]="categorySelected"> -->
<div *ngIf="isSelectCategory" class="menu-select-category">
  <ul class="list-category-grant">
    <li *ngFor="let category of listTreeCategory;index as i" class="category-item" (click)="loadChildrenCategory(category, 'two', i)" [ngClass]="{'item-active': category?.id === currentCategory?.id}">
      <span>{{category.name}}</span>
      <mat-icon *ngIf="category.children.length">keyboard_arrow_right</mat-icon>
    </li>
  </ul>
  <ul *ngIf="listTreeGrantTwo.length" class="list-category-grant">
    <li *ngFor="let item of listTreeGrantTwo;index as i" class="category-item" (click)="loadChildrenCategory(item, 'three', i)" [ngClass]="{'item-active': item?.id === currentCategory?.id}">
      <span>{{item.name}}</span>
      <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon>
    </li>
  </ul>

  <ul *ngIf="listTreeGrantThree.length" class="list-category-grant">
    <li *ngFor="let item of listTreeGrantThree;index as i" class="category-item" (click)="loadChildrenCategory(item, 'four', i)" [ngClass]="{'item-active': item?.id === currentCategory?.id}">
      <span>{{item.name}}</span>
      <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon>
    </li>
  </ul>

  <ul *ngIf="listTreeGrantFour.length" class="list-category-grant">
    <li *ngFor="let item of listTreeGrantFour;index as i" class="category-item" (click)="loadChildrenCategory(item, 'five', i)" [ngClass]="{'item-active': item?.id === currentCategory?.id}">
      <span>{{item.name}}</span>
      <mat-icon *ngIf="item.children.length">keyboard_arrow_right</mat-icon>
    </li>
  </ul>
</div>

