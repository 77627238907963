import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MdcDialog, MdcDialogRef, MDC_DIALOG_DATA } from '@angular-mdc/web/dialog';
import { startOfToday, startOfYesterday, sub, isAfter, isBefore, isEqual } from 'date-fns';

import { DashFilter, DashAppliedFilter, DashFilterSettingData } from './filter-bar.component';

export type DateRangeFilterValue = {
  predefinedKey?: string,
  predefinedLabel?: string,
  startDate: Date,
  endDate: Date,
};

@Component({
  selector: 'dash-date-range-setting',
  templateUrl: './date-range-setting.component.html',
  styleUrls: ['./date-range-setting.component.scss']
})
export class DateRangeSettingComponent implements OnInit {

  predefinedRanges: any;
  predefinedRangeKeys: string[];
  selectedPredefinedRangeKey: string;
  today = startOfToday();
  startDate: Date;
  endDate: Date;
  form: FormGroup;
  isNotCustom = false;

  constructor(
    public dialogRef: MdcDialogRef<DateRangeSettingComponent>,
    @Inject(MDC_DIALOG_DATA) public data: DashFilterSettingData<DateRangeFilterValue>,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.predefinedRanges = {
      yesterday: {
        label: 'Most recent date',
        value: [startOfYesterday(), startOfYesterday()],
      },
      last7Days: {
        label: 'Last 7 days',
        value: [sub(this.today, { days: 7 }), startOfYesterday()],
      }
    };
    if (this.data.filter.settingComponentConfig) {
      this.predefinedRanges = this.data.filter.settingComponentConfig.predefinedRanges;
    }

    this.predefinedRangeKeys = Object.keys(this.predefinedRanges);
    this.form = this.fb.group({
      predefinedKey: this.fb.control(null),
      predefinedLabel: this.fb.control(null),
      startDate: this.fb.control(null, [Validators.required]),
      endDate: this.fb.control(null, [Validators.required]),
    });
    if (this.data.value) {
      this.startDate = this.data.value.startDate;
      this.endDate = this.data.value.endDate;
      this.selectedPredefinedRangeKey = this.data.value.predefinedKey || 'custom';
      const predefinedRange = this.predefinedRanges[this.selectedPredefinedRangeKey];
      this.form.setValue(Object.assign({}, this.data.value, {
        predefinedKey: this.selectedPredefinedRangeKey,
        predefinedLabel: predefinedRange ? predefinedRange.label : null
      }));
      this.form.updateValueAndValidity();
    }
  }

  onPredefinedRangeSelected(event: MatRadioChange) {
    if (this.predefinedRanges.hasOwnProperty(event.value)) {
      console.log('not custom');
      this.isNotCustom = true;
      const predefinedRange = this.predefinedRanges[event.value];
      this.form.setValue({
        predefinedKey: event.value,
        predefinedLabel: predefinedRange.label || null,
        startDate: predefinedRange.value[0],
        endDate: predefinedRange.value[1],
      });
      this.startDate = predefinedRange.value[0];
      this.endDate = predefinedRange.value[1];
    } else {
      console.log('custom');
      this.isNotCustom = false;
      this.selectedPredefinedRangeKey = 'custom';
      this.form.setValue({
        predefinedKey: null,
        predefinedLabel: null,
        startDate: null,
        endDate: null,
      });

      this.startDate = null;
      this.endDate = null;
    }

    this.form.updateValueAndValidity();
  }

  onDateChanged(date: string, event: MatDatepickerInputEvent<Date>) {
    if (this.selectedPredefinedRangeKey == 'custom') {
      this.form.get(date).setValue(event.value);
    }
  }

  onApply() {
    this.dialogRef.close(this.form.value);
  }

  dateFilter(d: Date) {
    return isBefore(d, this.today) || isEqual(d, this.today);
  }

  startDateFilter = (d: Date) => {
    const endDate = this.endDate;

    if (endDate && isAfter(d, endDate)) {
      return false;
    }

    return this.dateFilter(d);
  }

  endDateFilter = (d: Date) => {
    const startDate = this.startDate;

    if (startDate && isBefore(d, startDate)) {
      return false;
    }

    return this.dateFilter(d);
  }

}
