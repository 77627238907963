import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-delete-alert',
  template: `
  <mdc-dialog>
    <mdc-dialog-container>
      <mdc-dialog-surface>
        <mdc-dialog-title>Delete category?</mdc-dialog-title>
        <mdc-dialog-actions>
          <button mdcDialogButton mdcDialogAction="close">Cancel</button>
          <button mdcDialogButton mdcDialogAction="accept">Delete</button>
        </mdc-dialog-actions>
      </mdc-dialog-surface>
    </mdc-dialog-container>
  </mdc-dialog>`
})
export class DeleteAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
