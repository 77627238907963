import { Component, OnInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromCategory from '@category/reducers';
import {
   CategoryListPageActions,
   CategoryPageActions
} from '@category/actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'ic-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class TreeSelectComponent implements OnInit {

  @Input() items: any;

  @Output() categorySelect = new EventEmitter<any>();
  
  listTreeCategory$: Observable<any>;
  listTreeCategory: any;
  listTreeGrantTwo = [];
  listTreeGrantThree = [];
  listTreeGrantFour = [];
  isSelectCategory = false;
  categorySelected = '';
  currentCategory: any;

  constructor(private storeCategory: Store<fromCategory.State>,
    private _eref: ElementRef) { 
    this.listTreeCategory$ = storeCategory.pipe(select(fromCategory.selectTreeCategory));
  }

  ngOnInit(): void {
    this.listTreeCategory$.subscribe(it => {
      // console.log(it);
        this.listTreeCategory = it.rows;
    })

    // console.log(this.listTreeCategory);
    
    this.storeCategory.dispatch(CategoryPageActions.getTreeCategory({ 
      filter: {},
      pageSize: 100,
      page: 1
     }))
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) // or some similar check
      this.isSelectCategory = false;
  }

  openSelectCategory() {
    this.isSelectCategory = true;
  }

  selectedCategory(category) {
    this.currentCategory = category;
    this.categorySelected = category.name_display;
    this.categorySelect.emit(category.id)
  }

  loadChildrenCategory(category, grant, index) {
    // console.log(grant);
    this.currentCategory = category;
    this.categorySelected = category.name_display;
    this.categorySelect.emit(category.id)
    if (grant === 'two') {
      this.listTreeGrantTwo = this.listTreeCategory[index].children;
      this.listTreeGrantThree = [];
      this.listTreeGrantFour = [];
    } else if (grant === 'three' && this.listTreeGrantTwo.length) {
      // console.log(this.listTreeGrantTwo);
      this.listTreeGrantThree = this.listTreeGrantTwo[index].children;
      this.listTreeGrantFour = [];
    } else {
      if (this.listTreeGrantThree.length) {
        this.listTreeGrantFour = this.listTreeGrantThree[index].children;
      }
    }
    
  }

}
