<mdc-drawer-header class="ic-notification-panel__header" title="Notifications" subtitle="Subtitle">
  <button mdcIconButton class="ic-notification-panel__closer" (click)="open = false;">
    <mdc-icon>close</mdc-icon>
  </button>
</mdc-drawer-header>
<mdc-drawer-content>
  <div class="ic-notification-panel__empty">
    <mdc-icon svgIcon="core:empty-notification" class="ic-notification-panel__empty-icon"></mdc-icon>
    <div class="ic-notification-panel__empty-text">No notifications to display</div>
  </div>
</mdc-drawer-content>
