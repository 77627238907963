import { createAction, props } from '@ngrx/store';



export const loadAttributes = createAction(
  '[Attribute List Page] Load Attributes',
  props<{ filter: any, page: number, pageSize: number }>()
);

export const loadAttributesSuccess = createAction(
  '[Attribute List Page] Load Attributes Success',
  props<{ total: number, ids: number[] }>()
);

export const loadAttributesFailure = createAction(
  '[Attribute List Page] Load Attributes Failure',
  props<{ error: any }>()
);
