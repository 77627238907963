import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dash-feature-bar, [dashFeatureBar]',
  exportAs: 'dashFeatureBar',
  template: `<ng-content></ng-content>`,
  host: {
    'class': 'dash-feature-bar'
  },
  encapsulation: ViewEncapsulation.None
})
export class FeatureBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
