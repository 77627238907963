import { Action, createReducer, on } from '@ngrx/store';

import { ProductPageActions } from '../actions';

export const productPageFeatureKey = 'productPage';

export interface State {
  filter: any;
  limit: number;
  offset: number;
  loading: boolean;
  ids: number[];
  total: number;
  displayedColumns: string[];
  error: any;
  productCurrent: object;
  barCodeExist: number;
}

export const initialState: State = {
  filter: {},
  loading: false,
  ids: [],
  total: 0,
  displayedColumns: ['select', 'id', 'image', 'name', 'price', 'owned_business', 'business_management', 'scan_sattus', 'authentication_sattus', 'number_of_scans', 'number_of_rate', 'number_of_qa', 'category','created_time', 'last_updated_time', 'action'],
  error: null,
  limit: 5,
  offset: 0,
  productCurrent: {},
  barCodeExist: 0,
};

const productPageReducer = createReducer(
  initialState,
  on(
    ProductPageActions.loadProducts,
    (state, { filter }) => {
      // console.log('aaaaaaaa', filter);
      
      return {
        ...state,
        filter,
        loading: true,
        // productCurrent: {}
      }
    }
  ),
  on(
    ProductPageActions.loadProductsSuccess,
    (state, { total, rows }) => {
      return {
        ...state,
        total,
        ids: rows.map(item => item.id),
        loading: false,
        productCurrent: {},
        barCodeExist: 0
      }
    }
  ),
  on(
    ProductPageActions.loadProductsFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
        loading: false
      }
    }
  ),
  on(ProductPageActions.getProductSuccess,
    (state, { product }) => {
      // console.log('aaaaaaaa', product);
      
      return {
        ...state,
        productCurrent: product,
      }
    }
  ),
  on(
    ProductPageActions.getProductFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
  on(ProductPageActions.checkBarCodeExistSuccess,
    (state, { existed }) => {
      // console.log('aaaaaaaa', existed);
      
      return {
        ...state,
        barCodeExist: existed,
      }
    }
  ),
  on(
    ProductPageActions.checkBarCodeExistFailure,
    (state, { error }) => {
      return {
        ...state,
        error,
      }
    }
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return productPageReducer(state, action);
}

export const getIds = (state: State) => state.ids;

export const getLoading = (state: State) => state.loading;

export const getTotal = (state: State) => state.total;

export const getFilter = (state: State) => state.filter;

export const getLimit = (state: State) => state.limit;

export const getOffset = (state: State) => state.offset;

export const getDisplayedColumns = (state: State) => state.displayedColumns;

export const getProductCurrent = (state: State) => state.productCurrent;

export const getBarCodeExist = (state: State) => state.barCodeExist;
